import React from 'react';
import styled from 'styled-components';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Paper } from '@mui/material';

const BaseAutocomplete = ({ options, value, onChange, label, getOptionLabel, ...props }) => {
  return (
    <StyledWrapper>
      <div className="inputGroup">
        <MuiAutocomplete
          options={options}
          value={value}
          onChange={onChange}
          getOptionLabel={getOptionLabel}
          clearOnBlur={false}
          clearOnEscape={true}
          clearable={true}
          disableClearable={false}
          popupIcon={null}
          clearIcon={
            <CloseIcon 
              fontSize="small" 
              sx={{ 
                color: '#333',
                cursor: 'pointer',
                padding: '2px'
              }} 
            />
          }
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                {...params.inputProps}
                placeholder=" "
                autoComplete="off"
              />
              <label>{label}</label>
              {params.InputProps.endAdornment}
            </div>
          )}
          PaperComponent={({ children, ...props }) => (
            <Paper {...props}>
              <div style={{ maxHeight: '40vh', overflow: 'auto' }}>
                {children}
              </div>
            </Paper>
          )}
          {...props}
        />
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .inputGroup {
    font-family: 'Segoe UI', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 100%;
    position: relative;
    height: 45px;
  }

  .inputGroup input {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 8px;
    width: 100%;
    height: 45px;
    box-sizing: border-box;
  }

  .inputGroup label {
    font-size: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
  }

  .inputGroup input:focus ~ label,
  .inputGroup input:not(:placeholder-shown) ~ label {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    margin-left: 1.3em;
    padding: 0.4em;
    background-color: var(--background-primary);
    top: 0;
  }

  .inputGroup input:focus,
  .inputGroup input:not(:placeholder-shown) {
    border-color: rgb(150, 150, 200);
  }

  /* Override MUI Autocomplete styles */
  .MuiAutocomplete-root {
    width: 100%;
  }

  .MuiAutocomplete-popper {
    margin-top: 8px;
  }

  .MuiAutocomplete-paper {
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  }
`;

export default BaseAutocomplete; 
import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper,
  Divider
} from '@mui/material';
import { 
  Briefcase as JobsIcon,
  ChartLineUp as JobStatsIcon,
  Paperclip as EstimatesIcon,
  Barcode as ItemsIcon,
  Phone as CallIcon,
  ChartPie as SalesIcon,
  CreditCard as PaymentsIcon,
  FileText as InvoicesIcon,
  Globe as WebsiteIcon,
  ListChecks as TasksIcon,
  Coins as TipsIcon,
  Activity as ActivityIcon,
  ClockCounterClockwise as TimesheetsIcon,
  Percent as TaxIcon,
  HardDrives as EquipmentIcon
} from "@phosphor-icons/react";

const Reports = () => {
  const reportCategories = [
    { icon: <JobsIcon size={24} weight="light" />, title: 'Jobs', path: '/admin/reports/jobs', color: '#2196F3' },
    { icon: <JobStatsIcon size={24} weight="light" />, title: 'Job Statistics', path: '/admin/reports/job-statistics', color: '#1976D2' },
    { icon: <EstimatesIcon size={24} weight="light" />, title: 'Estimates', path: '/admin/reports/estimates', color: '#4CAF50' },
    { icon: <ItemsIcon size={24} weight="light" />, title: 'Items and services', path: '/admin/reports/items', color: '#388E3C' },
    { icon: <CallIcon size={24} weight="light" />, title: 'Call Tracking', path: '/admin/reports/calls', color: '#FF9800' },
    { icon: <SalesIcon size={24} weight="light" />, title: 'Sales', path: '/admin/reports/sales', color: '#F44336' },
    { icon: <PaymentsIcon size={24} weight="light" />, title: 'Payments', path: '/admin/reports/payments', color: '#E91E63' },
    { icon: <InvoicesIcon size={24} weight="light" />, title: 'Invoices', path: '/admin/reports/invoices', color: '#9C27B0' },
    { icon: <WebsiteIcon size={24} weight="light" />, title: 'Website requests', path: '/admin/reports/website', color: '#673AB7' },
    { icon: <TasksIcon size={24} weight="light" />, title: 'Tasks', path: '/admin/reports/tasks', color: '#3F51B5' },
    { icon: <TipsIcon size={24} weight="light" />, title: 'Tips', path: '/admin/reports/tips', color: '#009688' },
    { icon: <ActivityIcon size={24} weight="light" />, title: 'Activity', path: '/admin/reports/activity', color: '#00BCD4' },
    { icon: <TimesheetsIcon size={24} weight="light" />, title: 'Timesheets', path: '/admin/reports/timesheets', color: '#FFC107' },
    { icon: <TaxIcon size={24} weight="light" />, title: 'Tax', path: '/admin/reports/tax', color: '#FF5722' },
    { icon: <EquipmentIcon size={24} weight="light" />, title: 'Equipment', path: '/admin/reports/equipment', color: '#795548' },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography 
        variant="h5" 
        sx={{ 
          mb: 3, 
          fontFamily: 'Urbanist',
          fontWeight: 600,
          color: 'var(--text-primary)'
        }}
      >
        Reports
      </Typography>
      
      <Grid container spacing={2}>
        {reportCategories.map((category) => (
          <Grid item xs={12} sm={6} md={4} key={category.title}>
            <Paper
              sx={{
                p: 2,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                height: '72px',
                transition: 'all 0.2s ease-in-out',
                backgroundColor: 'var(--background-paper)',
                border: '1px solid var(--border-color)',
                borderLeft: `3px solid ${category.color}`,
                '&:hover': {
                  transform: 'translateY(-4px)',
                  backgroundColor: 'var(--hover-background)',
                  borderColor: category.color,
                }
              }}
              onClick={() => {/* Add navigation logic here */}}
            >
              <Box 
                sx={{ 
                  color: 'var(--text-secondary)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 40,
                  height: 40,
                  borderRadius: '8px',
                  backgroundColor: 'var(--background-default)',
                  flexShrink: 0,
                }}
              >
                {category.icon}
              </Box>
              <Typography 
                sx={{ 
                  fontFamily: 'Urbanist',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  fontSize: '0.95rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {category.title}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Reports; 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Paper, Grid, Button, CircularProgress, Box, Avatar, Tabs, Tab } from '@mui/material';
import { getInvoiceById, updateInvoice, generateInvoiceShareLink, getPublicInvoice } from '../services/api';
import DefaultInvoiceTemplate from '../templates/DefaultInvoiceTemplate';
import ModernInvoiceTemplate from '../templates/ModernInvoiceTemplate';
import ProfessionalInvoiceTemplate from '../templates/ProfessionalInvoiceTemplate';
import ClassicInvoiceTemplate from '../templates/ClassicInvoiceTemplate';
import MinimalistInvoiceTemplate from '../templates/MinimalistInvoiceTemplate';
import html2pdf from 'html2pdf.js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  EmailOutlined as EmailIcon,
  PhoneOutlined as PhoneIcon,
  BusinessOutlined as BusinessIcon,
  LocationOnOutlined as LocationOnIcon
} from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteIcon from '@mui/icons-material/Note';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './AdminInvoiceDetails.module.scss';
import PaymentIcon from '@mui/icons-material/Payment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareLinkButton from './ShareLinkButton';
import { Snackbar, Slide } from '@mui/material';
import TagsSection from './TagsSection';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider } from '@mui/material';
import PaymentModal from './PaymentModal';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import SmsIcon from '@mui/icons-material/Sms';
import { PencilSimple, Check, X, Receipt } from "@phosphor-icons/react";

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist, sans-serif',
    allVariants: {
      fontFamily: 'Urbanist, sans-serif',
    },
    button: {
      fontFamily: 'Urbanist, sans-serif',
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Urbanist, sans-serif',
        },
      },
    },
  },
});

const INVOICE_STATUSES = {
  PAID: 'paid',
  DUE: 'due',
  OVERDUE: 'overdue',
  AWAITING_PAYMENT: 'awaiting_payment'
};

const STATUS_STYLES = {
  paid: {
    color: '#00AB55',
    backgroundColor: 'rgba(0, 171, 85, 0.08)',
  },
  due: {
    color: '#FFC107',
    backgroundColor: 'rgba(255, 193, 7, 0.08)',
  },
  overdue: {
    color: '#FF4842',
    backgroundColor: 'rgba(255, 72, 66, 0.08)',
  },
  'awaiting payment': {
    color: '#2065D1',
    backgroundColor: 'rgba(32, 101, 209, 0.08)',
  },
  draft: {
    color: '#637381',
    backgroundColor: 'rgba(99, 115, 129, 0.08)',
  }
};

const AdminInvoiceDetails = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paymentAnchorEl, setPaymentAnchorEl] = useState(null);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [sharePassword, setSharePassword] = useState('');
  const [shareEmail, setShareEmail] = useState('');
  const [sharePhoneNumber, setSharePhoneNumber] = useState('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [editedNotes, setEditedNotes] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);

  const getInvoiceStatus = (invoice) => {
    if (!invoice || !invoice.due_date) return 'draft';
    
    // Check if invoice is already marked as paid first
    if (invoice.status?.toLowerCase() === 'paid') {
      return 'paid';
    }
    
    const today = new Date();
    const dueDate = new Date(invoice.due_date);
    
    // Only check dates if not paid
    if (dueDate < today) {
      return 'overdue';
    }
    
    // Check if it's due within the next 7 days
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(today.getDate() + 7);
    if (dueDate <= sevenDaysFromNow) {
      return 'due';
    }
    
    return 'awaiting payment';
  };

  const saveAsPDF = async () => {
    // First find the template element
    const element = document.querySelector('.default-template');
    
    if (!element) {
      console.error('Could not find template element');
      setSnackbar({
        open: true,
        message: 'Failed to generate PDF: Template not found',
        severity: 'error'
      });
      return;
    }

    // Hide elements we don't want in the PDF
    const actionsButtons = document.querySelectorAll('.no-print');
    actionsButtons.forEach(btn => btn.style.display = 'none');

    const opt = {
      margin: 10,
      filename: `Invoice-${invoice?.invoice_number || 'draft'}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        letterRendering: true,
        scrollX: 0,
        scrollY: 0,
        windowWidth: document.documentElement.clientWidth,
        windowHeight: document.documentElement.clientHeight,
        onclone: (doc) => {
          // Ensure the cloned element has proper styling
          const clonedContent = doc.querySelector('.default-template');
          if (clonedContent) {
            clonedContent.style.padding = '40px';
            clonedContent.style.backgroundColor = 'white';
            clonedContent.style.maxWidth = '1000px';
            clonedContent.style.margin = '0 auto';
            clonedContent.style.borderRadius = '8px';

            // Fix logo distortion
            const logo = clonedContent.querySelector('img');
            if (logo) {
              logo.style.objectFit = 'contain';
              logo.style.maxWidth = '200px'; // or whatever max width you prefer
              logo.style.height = 'auto';
              logo.style.maxHeight = '80px'; // or whatever max height you prefer
            }
          }
        }
      },
      jsPDF: { 
        unit: 'pt', 
        format: 'a4', 
        orientation: 'portrait',
      }
    };

    try {
      console.log('Starting PDF generation...');
      console.log('Element found:', element);
      await html2pdf().set(opt).from(element).save();
      console.log('PDF generation completed');
      setSnackbar({
        open: true,
        message: 'PDF downloaded successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error generating PDF:', error);
      setSnackbar({
        open: true,
        message: 'Failed to generate PDF: ' + error.message,
        severity: 'error'
      });
    } finally {
      // Restore hidden elements
      actionsButtons.forEach(btn => btn.style.display = '');
    }
  };

  const fetchInvoice = async () => {
    try {
      console.log('Fetching invoice with id:', id);
      const fetchedInvoice = await getInvoiceById(id);
      console.log('Fetched invoice:', fetchedInvoice);
      setInvoice(fetchedInvoice);
      setError(null); // Clear any previous errors
    } catch (err) {
      console.error('Error fetching invoice:', err);
      setError('Failed to fetch invoice details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, [id]);

  useEffect(() => {
    if (invoice) {
      // Pre-fill email and phone number from invoice data
      setShareEmail(invoice.customer_details?.customer_email || '');
      setSharePhoneNumber(invoice.customer_details?.customer_phone || '');
    }
  }, [invoice]);

  const renderInvoiceTemplate = () => {
    console.log('Rendering invoice template. Invoice:', invoice);
    if (!invoice || !invoice.business_details || !invoice.customer_details) {
      console.log('Invalid invoice data');
      return <Typography>Invalid invoice data</Typography>;
    }

    // Match the props structure that DefaultInvoiceTemplate expects
    const templateProps = {
      businessDetails: invoice.business_details,
      customerDetails: invoice.customer_details,
      customer_details: invoice.customer_details,
      invoice: {
        invoiceNumber: invoice.invoice_number,
        issueDate: new Date(invoice.issue_date),
        dueDate: new Date(invoice.due_date),
        items: invoice.items || [],
        tax_rate: invoice.tax_rate || 0,
        notes: invoice.notes,
        termsAndConditions: invoice.terms_and_conditions,
        includeNotes: invoice.include_notes,
        includeTerms: invoice.include_terms,
        logo: invoice.logo,
        logoSize: invoice.logo_size,
        service_address: invoice.service_address
      }
    };

    console.log('Template props:', templateProps);

    // Pass props separately as expected by the template
    switch (invoice.template) {
      case 'modern':
        return <ModernInvoiceTemplate {...templateProps} />;
      case 'professional':
        return <ProfessionalInvoiceTemplate {...templateProps} />;
      case 'classic':
        return <ClassicInvoiceTemplate {...templateProps} />;
      case 'minimalist':
        return <MinimalistInvoiceTemplate {...templateProps} />;
      default:
        return <DefaultInvoiceTemplate {...templateProps} />;
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handlePaymentClose = () => {
    setPaymentAnchorEl(null);
  };

  const menuItemStyle = {
    padding: '8px 20px',
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.08)'
    }
  };

  const handleCopySuccess = () => {
    setSnackbar({
      open: true,
      message: 'Link copied to clipboard!',
      severity: 'success'
    });
  };

  const updateInvoiceStatus = async (newStatus) => {
    try {
      await updateInvoice(invoice.id, { status: newStatus });
      setSnackbar({
        open: true,
        message: 'Invoice status updated successfully',
        severity: 'success'
      });
      fetchInvoice(); // Refresh the invoice data
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update invoice status',
        severity: 'error'
      });
    }
  };

  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    switch (status.toLowerCase()) {
      case INVOICE_STATUSES.PAID: return 'success';
      case INVOICE_STATUSES.DUE: return 'warning';
      case INVOICE_STATUSES.OVERDUE: return 'error';
      case INVOICE_STATUSES.AWAITING_PAYMENT: return 'info';
      default: return 'default';
    }
  };

  // Function to handle confirming payment in the modal
  const handleConfirmPayment = (paymentDetails) => {
    updateInvoiceStatus('paid');
    setPaymentModalOpen(false);
    setActionAnchorEl(null);
  };

  // Function to handle emailing the invoice
  const handleEmailInvoice = async () => {
    try {
      // Replace with your actual email sending logic
      // You might need to use an API or a library to send emails
      console.log('Emailing invoice...');
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
      setSnackbar({
        open: true,
        message: 'Invoice emailed successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error emailing invoice:', error);
      setSnackbar({
        open: true,
        message: 'Failed to email invoice',
        severity: 'error'
      });
    } finally {
      setActionAnchorEl(null);
    }
  };

  // Function to handle downloading the invoice as PDF
  const handleDownloadPdf = () => {
    saveAsPDF(); // Use your existing saveAsPDF function
    setActionAnchorEl(null);
  };

  // Function to handle viewing client details
  const handleViewClient = () => {
    console.log('Invoice:', invoice); // Debug log
    if (invoice && invoice.client_id) {
      navigate(`/admin/clients/${invoice.client_id}`);
    } else {
      setSnackbar({
        open: true,
        message: 'No client associated with this invoice',
        severity: 'warning'
      });
    }
    setActionAnchorEl(null);
  };

  // Function to handle viewing the client view of the invoice
  const handleViewClientView = () => {
    window.open(`/public/invoices/${id}`, '_blank'); // Assuming this is your public invoice route
    setActionAnchorEl(null);
  };

  // Function to handle editing the invoice
  const handleEditInvoice = () => {
    navigate(`/admin/invoices/edit/${id}`);
    setActionAnchorEl(null);
  };

  const handleShareClick = () => {
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
    setShareLink('');
    setIsPasswordProtected(false);
    setSharePassword('');
  };

  const handleGenerateShareLink = async () => {
    try {
      const response = await generateInvoiceShareLink(id, {
        is_password_protected: isPasswordProtected,
        password: sharePassword
      });

      if (response.success) {
        setShareLink(`${window.location.origin}/public/invoice/${response.share_uuid}`);
      } else {
        setSnackbar({
          open: true,
          message: response.message || 'Failed to generate share link',
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error generating share link:', error);
      setSnackbar({
        open: true,
        message: 'Failed to generate share link',
        severity: 'error'
      });
    }
  };

  const handleCopyShareLink = () => {
    navigator.clipboard.writeText(shareLink);
    setSnackbar({
      open: true,
      message: 'Share link copied to clipboard!',
      severity: 'success'
    });
  };

  const handleShareInvoice = async () => {
    try {
      const response = await axios.post(
        `/api/invoices/${id}/generate-share-link`,
        {
          is_password_protected: isPasswordProtected,
          password: sharePassword,
          email: shareEmail,
          phone_number: sharePhoneNumber,
        }
      );

      if (response.data.success) {
        setShareLink(`${window.location.origin}/public/invoice/${response.data.share_uuid}`);
      } else {
        setSnackbar({
          open: true,
          message: response.data.message || 'Failed to generate share link',
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error generating share link:', error);
      setSnackbar({
        open: true,
        message: 'Failed to generate share link',
        severity: 'error'
      });
    }
  };

  // Placeholder actions (will be handled by middleware later)
  const handleShareViaEmail = () => {
    console.log('Share via Email clicked. Email:', shareEmail);
    // Replace this with your middleware call to handle email sending
    alert(`Invoice will be shared via email to: ${shareEmail}`);
    setShareDialogOpen(false); // Close the dialog after sharing
  };

  const handleShareViaSMS = () => {
    console.log('Share via SMS clicked. Phone Number:', sharePhoneNumber);
    // Replace this with your middleware call to handle SMS sending
    alert(`Invoice will be shared via SMS to: ${sharePhoneNumber}`);
    setShareDialogOpen(false); // Close the dialog after sharing
  };

  // Helper function to get customer details (add this near the top of the file)
  const getCustomerDetail = (invoice, field) => {
    if (!invoice?.customer_details) return null;
    return invoice.customer_details[field] || invoice.customer_details[`customer_${field}`];
  };

  const calculateInvoiceAmount = (invoice) => {
    if (invoice.amount) {
      return Number(invoice.amount) || 0;
    }
    
    if (invoice.items && Array.isArray(invoice.items)) {
      return invoice.items.reduce((sum, item) => {
        return sum + (Number(item.total) || 0);
      }, 0);
    }
    
    return 0;
  };

  const handleSaveNotes = async () => {
    try {
      await updateInvoice(invoice.id, {
        ...invoice,
        notes: editedNotes
      });
      setIsEditingNotes(false);
      // Refresh invoice data
      fetchInvoice();
    } catch (error) {
      console.error('Failed to save notes:', error);
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const calculateSubtotal = (invoice) => {
    if (!invoice?.items) return 0;
    return invoice.items.reduce((sum, item) => {
      const price = Number(item.price || item.unit_price || 0);
      const quantity = Number(item.quantity || 0);
      return sum + (price * quantity);
    }, 0);
  };

  const calculateTax = (invoice) => {
    const subtotal = calculateSubtotal(invoice);
    return subtotal * (Number(invoice?.tax_rate || 0) / 100);
  };

  const calculateTotal = (invoice) => {
    return calculateSubtotal(invoice) + calculateTax(invoice);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!invoice) return <Typography>Invoice not found</Typography>;

  console.log('Full invoice:', invoice);
  console.log('Invoice items:', invoice?.items);

  console.log('Rendering AdminInvoiceDetails component');

  return (
    <ThemeProvider theme={theme}>
      <div style={{ 
        backgroundColor: 'var(--background-default)',
        minHeight: '100vh'
      }}>
        <Box sx={{ 
          margin: '0 auto',
        }}>
          <div className={styles.headerSection}>
            <Link 
              to="/admin/invoices" 
              className={styles.backLink}
              style={{
                color: 'var(--text-primary)',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <ArrowBackIcon />
              Back to Invoices
            </Link>
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              ml: 'auto',
              alignItems: 'center' 
            }}>
              <Button
                variant="contained"
                onClick={(event) => setActionAnchorEl(event.currentTarget)}
                disableRipple
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  backgroundColor: '#4a5df9',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#3c50fa'
                  },
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                Actions ⚡
              </Button>
            </Box>

            {/* Update Menu styles */}
            <Menu
              anchorEl={paymentAnchorEl}
              open={Boolean(paymentAnchorEl)}
              onClose={handlePaymentClose}
              PaperProps={{
                sx: {
                  backgroundColor: 'var(--background-paper)',
                  color: 'var(--text-primary)',
                  border: '1px solid var(--divider)',
                  mt: 1
                }
              }}
            >
              <MenuItem 
                onClick={handlePaymentClose}
                sx={{
                  padding: '8px 20px',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.08)'
                  }
                }}
              >
                <ListItemIcon>
                  <img src="/stripe-icon.png" alt="Stripe" style={{ width: 24 }} />
                </ListItemIcon>
                <ListItemText>Stripe Payment</ListItemText>
              </MenuItem>
            </Menu>

            {/* Actions Menu with same styling */}
            <Menu
              anchorEl={actionAnchorEl}
              open={Boolean(actionAnchorEl)}
              onClose={() => setActionAnchorEl(null)}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem 
                onClick={() => {
                  setPreviewOpen(true);
                  setActionAnchorEl(null);
                }}
                sx={menuItemStyle}
              >
                <ListItemIcon>
                  <VisibilityIcon sx={{ color: 'var(--primary)' }} />
                </ListItemIcon>
                <ListItemText>Preview Invoice</ListItemText>
              </MenuItem>

              <MenuItem 
                onClick={() => {
                  handleClose();
                  saveAsPDF();
                }}
                sx={menuItemStyle}
              >
                <ListItemIcon>
                  <PictureAsPdfIcon sx={{ color: 'var(--error)' }} />
                </ListItemIcon>
                <ListItemText>Download PDF</ListItemText>
              </MenuItem>

              <MenuItem 
                onClick={handleViewClient}
                sx={menuItemStyle}
              >
                <ListItemIcon>
                  <PersonOutlineIcon sx={{ color: 'var(--warning)' }} />
                </ListItemIcon>
                <ListItemText>View Client</ListItemText>
              </MenuItem>

              <MenuItem 
                onClick={handleEditInvoice}
                sx={menuItemStyle}
              >
                <ListItemIcon>
                  <EditIcon sx={{ color: 'var(--warning)' }} />
                </ListItemIcon>
                <ListItemText>Edit Invoice</ListItemText>
              </MenuItem>

              {/* Add a divider to separate the edit action */}
              <Divider sx={{ my: 0.5 }} />

              {/* Existing menu items */}
              <MenuItem 
                onClick={() => {
                  setPaymentModalOpen(true); // Open the modal
                  setActionAnchorEl(null);
                }}
                sx={{
                  padding: '8px 20px',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.08)'
                  }
                }}
              >
                <ListItemIcon>
                  <CheckCircleIcon/>
                </ListItemIcon>
                <ListItemText>Mark as Paid</ListItemText>
              </MenuItem>

              {/* Share Invoice MenuItem */}
              <MenuItem onClick={handleShareClick} sx={menuItemStyle}>
                <ListItemIcon>
                  <ShareIcon sx={{ color: 'var(--primary)' }} />
                </ListItemIcon>
                <ListItemText>Share Invoice</ListItemText>
              </MenuItem>
            </Menu>
          </div>
          <Grid container spacing={3} sx={{ p: 2,}}>
            {/* Left column - Invoice Items */}
            <Grid item xs={12} lg={9} md={8}>
              <Box sx={{ 
                mb: 3,
                borderRadius: '12px',
              }}>
                <Typography variant="h6" sx={{ 
                  color: '#2d3748',
                  mb: 3,
                  fontWeight: 600,
                  fontFamily: "'Noto Sans', sans-serif"
                }}>
                </Typography>

                {/* Headers */}
                <Grid container spacing={2} sx={{ mb: 2}}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ 
                      fontWeight: 600,
                      color: 'text.secondary',
                      fontFamily: "'Noto Sans', sans-serif",
                      textAlign: 'left'
                    }}>
                      Title/Description
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      gap: 5  // Adjusted gap between price and total
                    }}>
                      <Typography sx={{ 
                        fontWeight: 600,
                        color: 'text.secondary',
                        fontFamily: "'Noto Sans', sans-serif",
                        textAlign: 'left',
                        marginLeft: '25%'
                      }}>
                        Quantity × Price
                      </Typography>
                      <Typography sx={{ 
                        fontWeight: 600,
                        color: 'text.secondary',
                        fontFamily: "'Noto Sans', sans-serif",
                        minWidth: '80px',
                        textAlign: 'right'
                      }}>
                        Total
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                
                {invoice?.items?.map((item, index) => {
                  console.log('Processing item:', item);
                  const itemPrice = Number(item.price || item.unit_price || 0);
                  const itemQuantity = Number(item.quantity || 0);
                  const itemTotal = itemPrice * itemQuantity;
                  
                  return (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        borderRadius: '8px',
                        '&:not(:last-child)': {
                          borderBottom: '1px solid var(--divider)',
                          paddingBottom: '16px'
                        }
                      }}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1" sx={{ 
                            fontWeight: 500,
                            fontFamily: "'Noto Sans', sans-serif",
                            textAlign: 'left'
                          }}>
                            {item.title || item.name || item.description}
                          </Typography>
                          {item.description && item.description !== item.title && (
                            <Typography 
                              variant="body2" 
                              color="text.secondary" 
                              sx={{ 
                                mt: 0.5,
                                whiteSpace: 'pre-wrap',
                                paddingRight: '15px',
                                maxWidth: '600px',
                                lineHeight: '1.6',
                                fontFamily: "'Noto Sans', sans-serif !important"
                              }}
                            >
                              {item.description.split('\n').map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i !== item.description.split('\n').length - 1 && <br />}
                                </React.Fragment>
                              ))}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: 6  // Match the header gap
                          }}>
                            <Typography variant="body2" sx={{ 
                              color: 'text.secondary',
                              fontFamily: "'Noto Sans', sans-serif",
                              textAlign: 'left',
                              marginLeft: '25%'
                            }}>
                              ${itemPrice.toFixed(2)} × {itemQuantity}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ 
                              fontWeight: 600,
                              color: 'green',
                              fontFamily: "'Inter', sans-serif",
                              minWidth: '80px',
                              textAlign: 'right'
                            }}>
                              ${itemTotal.toFixed(2)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}

                {/* Total Section */}
                <Box sx={{ 
                  mt: 4,
                  pt: 3,
                  width: '100%'
                }}>
                  <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 4,
                    mb: 2
                  }}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 6
                    }}>
                      <Typography color="text.secondary" sx={{ fontFamily: "'Noto Sans', sans-serif" }}>
                        Subtotal
                      </Typography>
                      <Typography sx={{ fontWeight: 500, fontFamily: "'Noto Sans', sans-serif", color: 'green' }}>
                        ${calculateSubtotal(invoice).toFixed(2)}
                      </Typography>
                    </Box>
                    {invoice?.tax_rate > 0 && (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 2
                      }}>
                        <Typography color="text.secondary" sx={{ fontFamily: "'Noto Sans', sans-serif" }}>
                          Tax ({invoice.tax_rate}%)
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontFamily: "'Noto Sans', sans-serif", color: 'green' }}>
                          ${calculateTax(invoice).toFixed(2)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderTop: '2px solid var(--divider)',
                    pt: 2
                  }}>
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 7.5,
                    }}>
                      <Typography sx={{ 
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        fontFamily: "'Noto Sans', sans-serif"
                      }}>
                        Total
                      </Typography>
                      <Typography sx={{ 
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        color: 'green',
                        fontFamily: "'Noto Sans', sans-serif"
                      }}>
                        ${calculateTotal(invoice).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* Right column - Client Info */}
            <Grid item xs={12} lg={3} md={4}>
              <Box sx={{
                width: '100%', // Full width on mobile
                maxWidth: { lg: 320 }, // Max width on desktop
                p: 3,
                backgroundColor: '#fff',
                height: 'fit-content', // Adjust height to content
                position: { xs: 'static', md: 'sticky' }, // Sticky for desktop
                top: { md: 80 }, // Top offset for sticky
                borderLeft: { xs: 'none', md: '1px solid var(--divider)' }, // Border on desktop
                borderTop: { xs: '1px solid var(--divider)', md: 'none' }, // Border on mobile
                mt: { xs: 2, md: 0 }, // Margin top on mobile
                ml: { xs: 0, md: 0 }, // No margin left on mobile
                boxSizing: 'border-box',
                // Hide scrollbar
                '&::-webkit-scrollbar': {
                  width: '0px',
                  background: 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'transparent'
                },
                '&:hover::-webkit-scrollbar-thumb': {
                  background: 'transparent'
                }
              }}>
                {/* Header - Removed Avatar */}
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                  gap: 2
                }}>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={{
                        color: '#2c3e50',
                        fontWeight: 600,
                        mb: 0.5,
                        fontFamily: 'Urbanist, sans-serif'
                      }}>
                        {getCustomerDetail(invoice, 'name') || 'No Name'}
                      </Typography>
                      <div
                        style={{
                          ...STATUS_STYLES[invoice?.status?.toLowerCase() || 'draft']?.style,
                          padding: '4px 8px',
                          borderRadius: '6px',
                          fontSize: '0.75rem',
                          fontWeight: 700,
                          textTransform: 'uppercase',
                          whiteSpace: 'nowrap',
                          letterSpacing: '0.5px',
                          display: 'inline-block',
                          color: STATUS_STYLES[invoice?.status?.toLowerCase() || 'draft']?.color,
                          backgroundColor: STATUS_STYLES[invoice?.status?.toLowerCase() || 'draft']?.backgroundColor
                        }}
                      >
                        {getInvoiceStatus(invoice)?.toUpperCase() || 'DUE'}
                      </div>
                    </Box>
                    <Typography variant="body2" sx={{
                      color: 'text.secondary',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                      <ReceiptIcon sx={{ fontSize: '1rem' }} />
                      Invoice #{invoice?.invoice_number}
                    </Typography>
                  </Box>
                </Box>

                {/* Contact Details */}
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  '& > *': {
                    marginBottom: '8px'
                  },
                  '& > *:last-child': {
                    marginBottom: 0
                  }
                }}>
                  {/* Email */}
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 2,
                    borderRadius: '8px',
                  }}>
                    <EmailIcon sx={{ color: '#3498db' }} />
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Email
                      </Typography>
                      <Typography variant="body2">
                        {getCustomerDetail(invoice, 'email') || 'No email provided'}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Phone */}
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 2,
                    borderRadius: '8px',
                  }}>
                    <PhoneIcon sx={{ color: '#2ecc71' }} />
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Phone
                      </Typography>
                      <Typography variant="body2">
                        {getCustomerDetail(invoice, 'phone') || 'No phone provided'}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Company */}
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 2,
                    borderRadius: '8px',
                  }}>
                    <BusinessIcon sx={{ color: '#3498db' }} />
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Company
                      </Typography>
                      <Typography variant="body2">
                        {getCustomerDetail(invoice, 'company') || 'No company provided'}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Address */}
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2,
                    p: 2,
                    borderRadius: '8px',
                  }}>
                    <LocationOnIcon sx={{ color: '#2ecc71' }} />
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Address
                      </Typography>
                      <Typography variant="body2">
                        {[
                          getCustomerDetail(invoice, 'address'),
                          getCustomerDetail(invoice, 'city'),
                          getCustomerDetail(invoice, 'state'),
                          getCustomerDetail(invoice, 'zip_code')
                        ].filter(Boolean).join(', ') || 'No address provided'}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Tags Section */}
                  <TagsSection 
                    entityType="invoices"
                    entityId={invoice?.id}
                  />
                </Box>

                {/* Invoice History */}
                <Box sx={{ mt: 4 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 3
                  }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 600,
                        fontSize: '1rem',
                        color: 'var(--text-primary)'
                      }}
                    >
                      Invoice History
                    </Typography>
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: 1
                  }}>
                    {/* Single Invoice Entry */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        borderRadius: '8px',
                        bgcolor: 'var(--background-paper)',
                        border: '1px solid var(--divider)',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          bgcolor: 'var(--hover-overlay)',
                          cursor: 'pointer',
                          transform: 'translateY(-1px)',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                        }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box sx={{
                          width: 40,
                          height: 40,
                          borderRadius: '8px',
                          bgcolor: 'var(--background-secondary)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <Receipt 
                            size={20} 
                            weight="light"
                            style={{ color: 'var(--text-secondary)' }}
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ 
                            fontWeight: 500,
                            color: 'var(--text-primary)',
                            fontFamily: "'Inter', sans-serif",
                            fontSize: '0.875rem'
                          }}>
                            {invoice?.invoice_number}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              color: 'var(--text-secondary)',
                              fontFamily: "'Inter', sans-serif",
                              display: 'block'
                            }}
                          >
                            {invoice?.issue_date ? new Date(invoice.issue_date).toLocaleDateString() : '-'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ textAlign: 'right' }}>
                        <Typography sx={{ 
                          fontWeight: 600,
                          color: 'var(--text-primary)',
                          fontFamily: "'Inter', sans-serif",
                          fontSize: '0.875rem'
                        }}>
                          ${calculateInvoiceAmount(invoice).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </Typography>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: STATUS_STYLES[invoice?.status?.toLowerCase()]?.color,
                            backgroundColor: STATUS_STYLES[invoice?.status?.toLowerCase()]?.backgroundColor,
                            fontFamily: "'Inter', sans-serif",
                            fontWeight: 500,
                            display: 'inline-block',
                            px: 1.5,
                            py: 0.5,
                            borderRadius: '4px',
                            fontSize: '0.75rem'
                          }}
                        >
                          {invoice?.status?.charAt(0).toUpperCase() + invoice?.status?.slice(1).toLowerCase() || 'Draft'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Notes Section */}
                <Box sx={{ mt: 4 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 2 
                  }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 600,
                        fontSize: '1rem',
                        color: 'var(--text-primary)'
                      }}
                    >
                      Notes
                    </Typography>
                    {!isEditingNotes && (
                      <IconButton 
                        onClick={() => {
                          setEditedNotes(invoice?.notes || '');
                          setIsEditingNotes(true);
                        }}
                        sx={{ 
                          color: 'var(--text-secondary)',
                          '&:hover': { color: 'var(--primary-main)' }
                        }}
                      >
                        <PencilSimple size={18} />
                      </IconButton>
                    )}
                  </Box>
                  
                  <Box
                    sx={{
                      p: 3,
                      borderRadius: '8px',
                      bgcolor: 'var(--background-paper)',
                      border: '1px solid var(--divider)',
                      minHeight: '100px'
                    }}
                  >
                    {isEditingNotes ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                          multiline
                          fullWidth
                          minRows={4}
                          value={editedNotes}
                          onChange={(e) => setEditedNotes(e.target.value)}
                          variant="outlined"
                          placeholder="Enter notes here..."
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'var(--background-default)',
                              '& fieldset': {
                                borderColor: 'var(--divider)'
                              },
                              '&:hover fieldset': {
                                borderColor: 'var(--primary-main)'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'var(--primary-main)'
                              }
                            }
                          }}
                        />
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                          <IconButton
                            onClick={() => setIsEditingNotes(false)}
                            sx={{ color: 'var(--error)' }}
                          >
                            <X size={20} />
                          </IconButton>
                          <IconButton
                            onClick={handleSaveNotes}
                            sx={{ color: 'var(--success)' }}
                          >
                            <Check size={20} />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          color: invoice?.notes ? 'var(--text-primary)' : 'var(--text-secondary)',
                          fontStyle: invoice?.notes ? 'normal' : 'italic',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {invoice?.notes || 'No notes added yet'}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            variant="filled"
            sx={{ 
              minWidth: '200px',
              borderRadius: '8px',
              '& .MuiAlert-message': {
                fontSize: '0.95rem',
                fontWeight: 500
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <PaymentModal 
          open={paymentModalOpen} 
          onClose={() => setPaymentModalOpen(false)} 
          invoice={invoice}
          onConfirm={handleConfirmPayment}
        />

        {/* Share Invoice Dialog */}
        <Dialog open={shareDialogOpen} onClose={handleShareDialogClose}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Share Invoice
            <IconButton
              aria-label="close"
              onClick={handleShareDialogClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ px: 3 }}>
            <DialogContentText>
              Share this invoice via a public link, email, or SMS.
            </DialogContentText>

            {/* Email and Phone Number Input */}
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={shareEmail}
              onChange={(e) => setShareEmail(e.target.value)}
            />
            <TextField
              label="Phone Number"
              fullWidth
              margin="normal"
              value={sharePhoneNumber}
              onChange={(e) => setSharePhoneNumber(e.target.value)}
            />

            {/* Share via Email/SMS Buttons - Aligned with inputs */}
            <Box sx={{ 
              mt: 2, 
              display: 'flex', 
              gap: 1,
              mx: '2px' // Aligns with TextField default spacing
            }}>
              <IconButton
                onClick={handleShareViaEmail}
                aria-label="Share via Email"
                sx={{
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  padding: '12px',
                  '&:hover': {
                    backgroundColor: '#1565c0'
                  }
                }}
              >
                <EmailIcon />
              </IconButton>
              <IconButton
                onClick={handleShareViaSMS}
                aria-label="Share via SMS"
                sx={{
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  padding: '12px',
                  '&:hover': {
                    backgroundColor: '#1565c0'
                  }
                }}
              >
                <SmsIcon />
              </IconButton>
            </Box>

            {/* Share Link Display */}
            {shareLink && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">Share Link:</Typography>
                <TextField
                  fullWidth
                  value={shareLink}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton onClick={handleCopyShareLink}>
                        <FileCopyIcon />
                      </IconButton>
                    )
                  }}
                />
              </Box>
            )}
          </DialogContent>
          
          {/* Bottom Action Bar */}
          <DialogActions sx={{ 
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            padding: '16px 24px',
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          }}>
            <Button
              variant="contained"
              onClick={handleGenerateShareLink}
              disabled={isPasswordProtected && !sharePassword}
              sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                padding: '10px 24px',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                '&:hover': {
                  backgroundColor: '#1565c0',
                },
                '&:disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  color: 'rgba(0, 0, 0, 0.26)'
                }
              }}
            >
              Generate Link
            </Button>
          </DialogActions>
        </Dialog>

        {/* Share Invoice Modal */}
        <Dialog open={shareModalOpen} onClose={() => setShareModalOpen(false)}>
          <DialogTitle>Share Invoice</DialogTitle>
          <DialogContent>
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={shareEmail}
              onChange={(e) => setShareEmail(e.target.value)}
            />
            <TextField
              label="Phone Number"
              fullWidth
              margin="normal"
              value={sharePhoneNumber}
              onChange={(e) => setSharePhoneNumber(e.target.value)}
            />
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                onClick={handleShareViaEmail}
                sx={{ mr: 1 }}
              >
                Share via Email
              </Button>
              <Button
                variant="contained"
                onClick={handleShareViaSMS}
              >
                Share via SMS
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShareModalOpen(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* Preview Dialog */}
        <Dialog
          open={previewOpen}
          onClose={handlePreviewClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '12px',
              backgroundColor: 'var(--background-paper)',
            }
          }}
        >
          <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid var(--divider)',
            p: 2
          }}>
            <Typography variant="h6" sx={{ 
              fontFamily: "'Inter', sans-serif",
              fontWeight: 600 
            }}>
              Invoice Preview
            </Typography>
            <IconButton onClick={handlePreviewClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <Box sx={{ 
              backgroundColor: 'var(--background-paper)',
              p: 3
            }}>
              <div className="invoice-details__template">
                {renderInvoiceTemplate()}
              </div>
            </Box>
          </DialogContent>
          <DialogActions sx={{ 
            borderTop: '1px solid var(--divider)',
            p: 2,
            gap: 1
          }}>
            <Button 
              onClick={handlePreviewClose}
              variant="outlined"
              sx={{
                borderColor: 'var(--divider)',
                color: 'var(--text-primary)',
                '&:hover': {
                  borderColor: 'var(--text-primary)',
                  backgroundColor: 'var(--hover-overlay)'
                }
              }}
            >
              Close
            </Button>
            <Button
              onClick={saveAsPDF}
              variant="contained"
              startIcon={<PictureAsPdfIcon />}
              sx={{
                backgroundColor: '#4A5AF9',
                '&:hover': {
                  backgroundColor: '#3C50FA',
                }
              }}
            >
              Download PDF
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default AdminInvoiceDetails;



import React from 'react';
import styled from 'styled-components';

const BaseSelect = ({ label, value, onChange, name, children, options, ...props }) => {
  console.log('BaseSelect props:', { value, options });

  return (
    <StyledWrapper>
      <div className="inputGroup">
        <select
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          required
          {...props}
        >
          <option value="" disabled></option>
          {options?.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <label htmlFor={name}>{label}</label>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .inputGroup {
    font-family: 'Urbanist', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 100%;
    position: relative;
    height: 45px;
  }

  .inputGroup select {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 8px;
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    font-family: 'Urbanist', sans-serif;
    cursor: pointer;
    appearance: none;
    padding-right: 2em;
  }

  .inputGroup label {
    font-size: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
  }

  .inputGroup select:focus ~ label,
  .inputGroup select:not(:placeholder-shown) ~ label {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    margin-left: 1.3em;
    padding: 0.4em;
    background-color: var(--background-primary);
    top: 0;
  }

  .inputGroup select:focus,
  .inputGroup select:not(:placeholder-shown) {
    border-color: rgb(150, 150, 200);
  }

  .inputGroup::after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgb(100, 100, 100);
    pointer-events: none;
    }
`;

export default BaseSelect; 
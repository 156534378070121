import React from 'react';
import styled from 'styled-components';

const NewSwitch = ({ checked, onChange, ...props }) => {
  return (
    <StyledWrapper>
      <label className="switch">
        <input 
          type="checkbox" 
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <span className="slider" />
      </label>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .switch {
   --button-width: 3.5em;
   --button-height: 2em;
   --toggle-diameter: 1.5em;
   --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
   --toggle-wider: 3em;
   --color-grey: #cccccc;
   --color-green: #4a5df9;
  }

  .slider {
   display: inline-block;
   width: var(--button-width);
   height: var(--button-height);
   background-color: var(--color-grey);
   border-radius: calc(var(--button-height) / 2);
   position: relative;
   transition: background-color 0.3s ease-in-out;
  }

  .slider::after {
   content: "";
   display: inline-block;
   width: var(--toggle-diameter);
   height: var(--toggle-diameter);
   background-color: #fff;
   border-radius: calc(var(--toggle-diameter) / 2);
   position: absolute;
   top: var(--button-toggle-offset);
   transform: translateX(var(--button-toggle-offset));
   box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
   transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  .switch input[type="checkbox"]:checked + .slider {
   background-color: var(--color-green);
  }

  .switch input[type="checkbox"]:checked + .slider::after {
   transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
   box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  }

  .switch input[type="checkbox"] {
   display: none;
  }
`;

export default NewSwitch; 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Divider,
  Tabs,
  Tab,
} from '@mui/material';
import { 
  House, MapPin, Ruler, Calendar, Shower, Bed, Note, 
  ClockClockwise, Files, CaretLeft, Buildings,
  User as UserIcon,
  Envelope as EnvelopeIcon,
  Phone as PhoneIcon
} from '@phosphor-icons/react';
import { getPropertyById } from '../services/api';
import Estimates from './client/Estimates';
import Invoices from './client/Invoices';
import ClientDetailsProject from './client/ClientDetailsProject';

const PropertyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [slideDirection, setSlideDirection] = useState('left');
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Function to get Street View image URL
  const getStreetViewUrl = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://maps.googleapis.com/maps/api/streetview?size=800x400&location=${encodedAddress}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  };

  // Function to get Static Map URL
  const getStaticMapUrl = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://maps.googleapis.com/maps/api/staticmap?center=${encodedAddress}&zoom=16&size=800x400&markers=color:red|${encodedAddress}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  };

  const handleTabChange = (event, newValue) => {
    const oldIndex = activeTab;
    setSlideDirection(oldIndex < newValue ? 'left' : 'right');
    setIsTransitioning(true);
    setActiveTab(newValue);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  };

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        console.log('Fetching property details for ID:', id);
        const data = await getPropertyById(id);
        console.log('Received property data:', data);
        setProperty(data);
        setError(null);
      } catch (err) {
        console.error('Error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchPropertyDetails();
    }
  }, [id]);

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading property details...</Typography>
      </Box>
    );
  }

  if (!property) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Property not found</Typography>
      </Box>
    );
  }

  const fullAddress = `${property.address}, ${property.city}, ${property.state} ${property.zip}`;

  return (
    <Box sx={{ p: 3 }}>
      {/* Header with Back Button */}
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          variant="outlined"
          onClick={() => navigate(-1)}
          startIcon={<CaretLeft size={20} weight="light" />}
          sx={{
            textTransform: 'none',
            borderColor: 'var(--divider)',
            color: 'var(--text-secondary)',
            gap: 1,
            '&:hover': {
              borderColor: 'var(--text-secondary)',
            }
          }}
        >
          Back
        </Button>
      </Box>

      {/* Address Title */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ 
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <House size={24} weight="light" />
          {property.address}, {property.city}, {property.state} {property.zip}
        </Typography>
      </Box>

      {/* Images Container */}
      <Box sx={{ 
        mb: 3, 
        display: 'flex', 
        gap: 2,
        height: 300, // Reduced from 400
        width: '100%',
      }}>
        {/* Street View Image */}
        <Box sx={{ 
          flex: 1,
          overflow: 'hidden', 
          borderRadius: 1,
          border: '1px solid var(--divider)',
          minWidth: 0, // Add this to prevent overflow
        }}>
          <img 
            src={getStreetViewUrl(fullAddress)}
            alt="Street View"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            onError={(e) => {
              console.log('Street View image failed to load');
              e.target.style.display = 'none';
            }}
          />
        </Box>

        {/* Static Map */}
        <Box sx={{ 
          flex: 1,
          overflow: 'hidden', 
          borderRadius: 1,
          border: '1px solid var(--divider)',
          minWidth: 0, // Add this to prevent overflow
        }}>
          <img 
            src={getStaticMapUrl(fullAddress)}
            alt="Property Location"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>

      {/* Property Details and Tabs */}
      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            px: 2,
            '& .MuiTabs-indicator': {
              backgroundColor: '#7635dc',
              height: '2px',
              borderRadius: '3px 3px 0 0',
              transition: 'all 0.2s ease'
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              color: 'var(--text-secondary)',
              minHeight: 48,
              padding: '12px 24px',
              gap: '8px',
              position: 'relative',
              '& .MuiTouchRipple-root': {
                display: 'none'
              },
              '& svg': {
                marginBottom: '0 !important',
                marginRight: '0 !important'
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0,
                height: '2px',
                borderRadius: '3px 3px 0 0',
                backgroundColor: '#7635dc',
                opacity: 0.6,
                transition: 'all 0.2s ease',
                transform: 'translateX(-50%)',
              },
              '&:hover::after': {
                width: '80%',
              },
              '&.Mui-selected': {
                color: 'var(--primary-main)',
                fontWeight: 600,
                '&::after': {
                  opacity: 0,
                },
              },
              '&:hover': {
                color: 'var(--primary-main)',
              }
            },
          }}
        >
          <Tab 
            icon={<House size={20} weight="light" />}
            iconPosition="start"
            label="Details" 
            disableRipple
          />
          <Tab 
            icon={<Files size={20} weight="light" />}
            iconPosition="start"
            label="Estimates" 
            disableRipple
          />
          <Tab 
            icon={<Note size={20} weight="light" />}
            iconPosition="start"
            label="Invoices" 
            disableRipple
          />
          <Tab 
            icon={<ClockClockwise size={20} weight="light" />}
            iconPosition="start"
            label="Projects" 
            disableRipple
          />
        </Tabs>

        {/* Content Box */}
        <Box sx={{ 
          flex: 1,
          overflowY: 'auto',
          backgroundColor: 'var(--background-default)',
        }}>
          {[
            // Details Tab
            <Box sx={{ p: 3, mb: 3 }} key="details">
              <Grid container spacing={3}>
                {/* Property Details */}
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <House size={24} />
                    <Typography variant="h6">{property.address}</Typography>
                  </Box>
                  <Typography color="text.secondary" sx={{ ml: 4 }}>
                    {property.city}, {property.state} {property.zip}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {/* Property Specs */}
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Ruler size={20} />
                    <Typography>
                      <strong>Square Footage:</strong> {property.square_footage || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Bed size={20} />
                    <Typography>
                      <strong>Bedrooms:</strong> {property.bedrooms || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Shower size={20} />
                    <Typography>
                      <strong>Bathrooms:</strong> {property.bathrooms || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Calendar size={20} />
                    <Typography>
                      <strong>Year Built:</strong> {property.year_built || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                {property.lot_size && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <MapPin size={20} />
                      <Typography>
                        <strong>Lot Size:</strong> {property.lot_size} acres
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {property.price && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <MapPin size={20} />
                      <Typography>
                        <strong>Price:</strong> ${property.price.toLocaleString()}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {/* Client Details Section */}
                <Grid item xs={12}>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" sx={{ 
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <UserIcon size={24} weight="light" />
                    Client Information
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <UserIcon size={20} weight="light" />
                    <Typography>
                      <strong>Name:</strong> {property.client?.name || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EnvelopeIcon size={20} weight="light" />
                    <Typography>
                      <strong>Email:</strong> {property.client?.email || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PhoneIcon size={20} weight="light" />
                    <Typography>
                      <strong>Phone:</strong> {property.client?.phone || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>

                {property.client?.company && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Buildings size={20} weight="light" />
                      <Typography>
                        <strong>Company:</strong> {property.client.company}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {property.client?.billing_address && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <MapPin size={20} weight="light" />
                      <Typography>
                        <strong>Billing Address:</strong> {property.client.billing_address}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>,
            // Estimates Tab
            <Estimates 
              key="estimates"
              client={property.client} 
              filterAddress={fullAddress}
            />,
            // Invoices Tab
            <Invoices 
              key="invoices"
              client={property.client}
              filterAddress={fullAddress}
            />,
            // Projects Tab
            <ClientDetailsProject 
              key="projects"
              client={property.client}
              filterAddress={fullAddress}
            />
          ].map((content, index) => (
            <Box
              key={index}
              role="tabpanel"
              hidden={activeTab !== index}
              sx={{
                animation: isTransitioning
                  ? `${slideDirection === 'left' ? 'slideLeft' : 'slideRight'} 0.3s ease-in-out`
                  : 'none',
                height: '100%'
              }}
            >
              {activeTab === index && content}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyDetails;
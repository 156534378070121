import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isToday,
  getDay,
} from 'date-fns';
import styles from '../Calendar.module.scss';
import MonthEventCard from './MonthEventCard';

const MonthView = ({ selectedDate, events, onEventUpdated }) => {
  const monthStart = startOfMonth(selectedDate);
  const monthEnd = endOfMonth(selectedDate);
  const calendarStart = startOfWeek(monthStart);
  const calendarEnd = endOfWeek(monthEnd);

  const days = eachDayOfInterval({
    start: calendarStart,
    end: calendarEnd,
  });

  const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  const eventsByDay = {};
  days.forEach((day) => {
    eventsByDay[format(day, 'yyyy-MM-dd')] = [];
  });

  events.forEach((event) => {
    if (event.time_slots.length > 0) {
      const dayKey = format(new Date(event.time_slots[0].start_time), 'yyyy-MM-dd');
      if (eventsByDay[dayKey]) {
        eventsByDay[dayKey].push(event);
      }
    }
  });

  const maxEventsPerDay = Math.max(
    ...Object.values(eventsByDay).map((events) => events.length),
    0
  );

  const minDayCellHeight = 120;
  const eventCardHeight = 30;
  const eventPadding = 50;

  return (
    <Paper elevation={0} className={styles.monthView}>
      <div className={styles.weekDaysWrapper}>
        <div className={styles.weekDays}>
          {weekDays.map((day) => (
            <Typography
              key={day}
              className={`${styles.weekDayHeader} ${
                day === 'SUN' || day === 'SAT' ? styles.weekendHeader : ''
              }`}
              sx={{
                borderBottom: 'none',
                fontWeight: 600,
                color: 'var(--text-secondary)',
                padding: '12px 8px',
                fontSize: '0.75rem',
                letterSpacing: '0.5px'
              }}
            >
              {day}
            </Typography>
          ))}
        </div>
      </div>
      <div className={styles.daysGridWrapper}>
        <div
          className={styles.daysGrid}
          style={{
            gridTemplateRows: 'repeat(6, auto)',
            overflow: 'visible',
            gap: 0,
            backgroundColor: 'var(--background-paper)'
          }}
        >
          {days.map((day) => {
            const dayKey = format(day, 'yyyy-MM-dd');
            const dayEvents = eventsByDay[dayKey] || [];
            const cellHeight = dayEvents.length > 0 
              ? (dayEvents.length * eventCardHeight) + eventPadding
              : minDayCellHeight;

            const isWeekend = getDay(day) === 0 || getDay(day) === 6;
            const isCurrentDay = isToday(day);

            return (
              <div
                key={day.toString()}
                className={`${styles.dayCell}
                  ${!isSameMonth(day, selectedDate) ? styles.otherMonth : ''}
                  ${isCurrentDay ? styles.today : ''}
                  ${isWeekend ? styles.weekend : ''}`}
                style={{ 
                  height: `${cellHeight}px`,
                  overflow: 'visible',
                  minHeight: `${minDayCellHeight}px`,
                  padding: '8px',
                  border: '1px solid var(--divider)',
                  backgroundColor: 'var(--background-paper)',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: 'var(--background-default)'
                  }
                }}
              >
                <Box className={styles.dayHeader}>
                  <Typography
                    className={`${styles.dayNumber} ${
                      isCurrentDay ? styles.todayNumber : ''
                    }`}
                  >
                    {format(day, 'd')}
                  </Typography>
                </Box>
                <div className={styles.eventsList} style={{ 
                  overflow: 'visible',
                  gap: '4px',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  {dayEvents.map((event) => (
                    <MonthEventCard 
                      key={event.id} 
                      event={event}
                      onEventUpdated={onEventUpdated}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Paper>
  );
};

export default MonthView; 
import React, { useState, useCallback, useEffect } from 'react';
import {
  Typography, TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem,
  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Collapse,
  Checkbox, Snackbar, CircularProgress, Alert, Fade, Dialog, DialogContent, FormControlLabel, Switch,
  Stepper, Step, StepLabel, Slider, DialogTitle, DialogActions, Container, Autocomplete
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, CloudUpload as CloudUploadIcon, Edit as EditIcon, Person as PersonIcon, ArrowForward as ArrowForwardIcon, Save as SaveIcon, ArrowBack as ArrowBackIcon, AddCircleOutline as AddCircleOutlineIcon, FolderOpen as FolderOpenIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import {
  getBusinessDetails,
  getClients,
  getItems,
  getLogos,
  uploadLogo,
  deleteLogo,
  createItem,
  deleteItem,
  getPackages,
  getPricingRules,
  getPriceBookItems,
  getTermsTemplates,
  createClient,
  uploadEstimatePhoto
} from '../services/api';
import LogoUploader from './LogoUploader';
import ClientSelector from './ClientSelector';
import InvoiceItemsManager from '../components/InvoiceItemsManager';

import DefaultInvoiceTemplate from '../templates/DefaultInvoiceTemplate';
import ModernInvoiceTemplate from '../templates/ModernInvoiceTemplate';
import ClassicInvoiceTemplate from '../templates/ClassicInvoiceTemplate';
import MinimalistInvoiceTemplate from '../templates/MinimalistInvoiceTemplate';
import ProfessionalInvoiceTemplate from '../templates/ProfessionalInvoiceTemplate';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createInvoice } from '../services/api';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InvoiceSavedItems from './invoice/InvoiceSavedItems';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SavedItemsManager from './SavedItemsManager';
import ClientEstimateForm from './estimate/ClientEstimateForm.tsx';
import styles from './AdminCreateInvoice.module.scss';
import { ArrowBack, ArrowForward, Save } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Plus, FolderSimplePlus } from '@phosphor-icons/react';

const templateOptions = [
  { value: 'default', label: 'Default', component: DefaultInvoiceTemplate },
  { value: 'modern', label: 'Modern', component: ModernInvoiceTemplate },
  { value: 'classic', label: 'Classic', component: ClassicInvoiceTemplate },
];

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
};

const generateInvoiceNumber = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const randomNum = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
  return `${year}${month}${day}-${randomNum}`;
};

const initialInvoiceState = {
  invoiceNumber: generateInvoiceNumber(),
  issueDate: new Date(),
  dueDate: new Date(new Date().setDate(new Date().getDate() + 30)),
  items: [
    {
      title: '',
      description: '',
      quantity: 1,
      unit_price: 0,
      unitPrice: 0,
      total: 0,
      type: 'saved', // Or your default item type
      isNew: true,
      isEdited: false,
      fromAutocomplete: false,
      saved: false,
      hideLibraryBanner: false,
    },
  ],
  notes: '',
  termsAndConditions: '',
  template: 'default',
  logo: '',
  logoSize: 100,
  businessDetails: {},
  customerDetails: {
    name: '',
    company: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    email: '',
    phone: '',
    notes: ''
  },
  includeNotes: false,
  includeTerms: false,
  service_address: {
    address: '',
    city: '',
    state: '',
    zip: ''
  },
  use_client_address: true
};

const parseAddress = (fullAddress) => {
  if (!fullAddress) return { address: '', city: '', state: '', zip: '' };

  // Assuming format: "4250 Galt Ocean Dr, Fort Lauderdale, FL, 33308"
  const parts = fullAddress.split(',').map(part => part.trim());
  
  return {
    address: parts[0] || '', // Street address
    city: parts[1] || '',    // City
    state: parts[2] || '',   // State
    zip: parts[3] || ''      // ZIP
  };
};

const FloatingInput = styled.div`
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 56px;
    padding: 0 12px;
    font-size: 1rem;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    background: white;
    transition: all 0.2s;
    outline: none;
    color: #333;

    &:hover {
      border-color: #1976d2;
    }
    
    &:focus {
      border-color: #1976d2;
      border-width: 2px;
      box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.1);
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      transform: translate(8px, -24px) scale(0.75);
      background: white;
      padding: 0 4px;
      color: #1976d2;
    }
  }

  label {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    padding: 0 4px;
    color: #666;
    pointer-events: none;
    transition: all 0.2s;
    font-size: 1rem;
  }
`;

const AdminCreateInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [invoice, setInvoice] = useState(initialInvoiceState);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const [openClientModal, setOpenClientModal] = useState(false);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [openSavedItemsModal, setOpenSavedItemsModal] = useState(false);
  const [savedItems, setSavedItems] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [successDialog, setSuccessDialog] = useState(false);
  const [termsTemplates, setTermsTemplates] = useState([]);
  const [selectedTermsTemplate, setSelectedTermsTemplate] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [photoError, setPhotoError] = useState('');
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const [businessDetails, setBusinessDetails] = useState({});
  const [templateBusinessDetails, setTemplateBusinessDetails] = useState(null);
  const [invoiceCreationType, setInvoiceCreationType] = useState(
    location.state?.fromClientCreation ? 'new' : null
  );
  const [isNewClient, setIsNewClient] = useState(false);

  const steps = ['Client & Project', 'Items', 'Details', 'Preview'];

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  useEffect(() => {
    const fetchTermsTemplates = async () => {
      try {
        const response = await getTermsTemplates();
        setTermsTemplates(response.data);
      } catch (error) {
        console.error('Error fetching terms templates:', error);
        setSnackbar({ open: true, message: 'Failed to load terms templates', severity: 'error' });
      }
    };

    fetchTermsTemplates();
  }, []);

  const handleTermsTemplateChange = (event) => {
    const templateId = event.target.value;
    const selectedTemplate = termsTemplates.find(template => template.id === templateId);
    setSelectedTermsTemplate(selectedTemplate);
    setInvoice(prev => ({
      ...prev,
      termsAndConditions: selectedTemplate ? selectedTemplate.content : ''
    }));
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    await handleFileUpload(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleFileUpload = async (files) => {
    setIsLoadingPhotos(true);
    setPhotoError('');

    const uploadPromises = Array.from(files).map(async (file) => {
      try {
        const response = await uploadEstimatePhoto(invoice.invoiceNumber, file);
        if (response.data && response.data.url) {
          return {
            url: response.data.url,
            name: file.name
          };
        } else {
          throw new Error('File upload failed or URL missing');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setPhotoError(prevError => prevError ? `${prevError}, ${file.name} upload failed` : `${file.name} upload failed`);
        return null;
      }
    });

    try {
      const uploadedImages = await Promise.all(uploadPromises);
      const validImages = uploadedImages.filter(image => image !== null);

      setInvoice(prev => ({
        ...prev,
        project_images: prev.project_images ? [...prev.project_images, ...validImages] : validImages
      }));
    } catch (error) {
      console.error('Error processing file uploads:', error);
      setPhotoError('Failed to upload one or more images.');
    } finally {
      setIsLoadingPhotos(false);
    }
  };

  const handleRemoveImage = (index) => {
    setInvoice(prev => ({
      ...prev,
      project_images: prev.project_images.filter((_, i) => i !== index)
    }));
  };

  const handleClientSelect = (event, client) => {
    console.log('Selected client:', client);
    
    if (client) {
      setSelectedClient(client);
      
      // Parse the address if it's a combined string
      const parsedAddress = parseAddress(client.address);
      
      setInvoice(prev => {
        const updatedInvoice = {
          ...prev,
          customerDetails: {
            name: client.name || '',
            company: client.company || '',
            email: client.email || '',
            phone: client.phone || '',
            address: parsedAddress.address,
            city: parsedAddress.city || client.city || '',
            state: parsedAddress.state || client.state || '',
            zipCode: parsedAddress.zip || client.zip_code || '',
            notes: client.notes || ''
          }
        };

        // If use_client_address is true, copy the parsed address
        if (prev.use_client_address) {
          updatedInvoice.service_address = {
            address: parsedAddress.address,
            city: parsedAddress.city || client.city || '',
            state: parsedAddress.state || client.state || '',
            zip: parsedAddress.zip || client.zip_code || ''
          };
        }

        return updatedInvoice;
      });
    }
  };

  const handleAddItem = (item, index = null) => {
    setInvoice(prev => {
      const updatedItems = [...prev.items];
      const newItem = {
        title: item.title || '',
        description: item.description || '',
        quantity: Number(item.quantity) || 0,
        unit_price: Number(item.unitPrice || item.unit_price) || 0,
        unitPrice: Number(item.unitPrice || item.unit_price) || 0,
        total: (Number(item.quantity) || 0) * (Number(item.unitPrice || item.unit_price) || 0),
        // Keep ALL existing flags
        ...item,
        id: item.id,
        fromAutocomplete: item.fromAutocomplete,
        saved: item.saved,
        isNew: item.isNew,
        isEdited: item.isEdited,
        hideLibraryBanner: item.hideLibraryBanner,
        isFromLibrary: item.isFromLibrary,
        isSavedItem: item.isSavedItem
      };

      if (index !== null) {
        updatedItems[index] = newItem;
      } else {
        updatedItems.push(newItem);
      }

      return {
        ...prev,
        items: updatedItems
      };
    });
  };

  const handleRemoveItem = (index) => {
    setInvoice(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleInputChange = (field, value) => {
    if (field === 'customerDetails') {
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        customerDetails: {
          ...prevInvoice.customerDetails,
          ...value,
        },
      }));
    } else if (field === 'service_address') {
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        service_address: {
          ...prevInvoice.service_address,
          ...value,
        },
      }));
    } else {
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        [field]: value,
      }));
    }
  };

  const handleCheckboxChange = (field, checked) => {
    if (field === 'use_client_address') {
      setInvoice(prev => ({
        ...prev,
        use_client_address: checked,
        // When checkbox is checked, copy customer address to service address
        ...(checked && {
          service_address: {
            address: prev.customerDetails.address || '',
            city: prev.customerDetails.city || '',
            state: prev.customerDetails.state || '',
            zip: prev.customerDetails.zipCode || ''
          }
        })
      }));
    } else {
      setInvoice(prev => ({
        ...prev,
        [field]: checked
      }));
    }
  };

  const handleServiceAddressInputChange = (field, value) => {
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      service_address: {
        ...prevInvoice.service_address,
        [field]: value,
      },
    }));
  };

  const getFormattedServiceAddress = () => {
    if (invoice.use_client_address) {
      const { address, city, state, zipCode } = invoice.customerDetails;
      return [address, city, state, zipCode].filter(Boolean).join(', ');
    } else {
      const { address, city, state, zip } = invoice.service_address;
      return [address, city, state, zip].filter(Boolean).join(', ');
    }
  };

  const getCurrentStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom sx={{ mb: 5 }}>
                Client Selection
              </Typography>
              
              <Box sx={{ width: '100%', mb: 3 }}>
                <Autocomplete
                  options={clients}
                  getOptionLabel={(option) => option?.name || option?.customer_name || ''}
                  onChange={handleClientSelect}
                  value={selectedClient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Existing Client"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => {
                    const { key, ...otherProps } = props;
                    return (
                      <li key={key} {...otherProps}>
                        <Box>
                          <Typography variant="subtitle1">
                            {option.name || option.customer_name || 'Unnamed Client'}
                          </Typography>
                          {(option.company || option.customer_company) && (
                            <Typography variant="body2" color="text.secondary">
                              {option.company || option.customer_company}
                            </Typography>
                          )}
                        </Box>
                      </li>
                    );
                  }}
                  PaperComponent={({ children, ...props }) => {
                    const handleAddNewClick = (event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      
                      navigate('/admin/create-client', {
                        state: { from: 'invoice-creation' }
                      });
                    };

                    return (
                      <Paper {...props}>
                        <div style={{ maxHeight: '40vh', overflow: 'auto' }}>
                          {children}
                        </div>
                        <div
                          style={{
                            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                            padding: '8px',
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: '#fff',
                            zIndex: 1000
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                        >
                          <Button
                            fullWidth
                            startIcon={<AddIcon />}
                            onClick={handleAddNewClick}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            sx={{
                              color: 'primary.main',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: 'rgba(63, 81, 181, 0.04)'
                              }
                            }}
                          >
                            Add New Customer
                          </Button>
                        </div>
                      </Paper>
                    );
                  }}
                  ListboxProps={{
                    style: { maxHeight: '40vh' }
                  }}
                  noOptionsText="No clients found"
                  loading={!clients.length}
                  loadingText="Loading clients..."
                  sx={{
                    flexGrow: 1,
                    '& .MuiAutocomplete-popper': {
                      minWidth: '300px',
                      width: 'fit-content !important'
                    }
                  }}
                  onClose={(event, reason) => {
                    if (reason === 'toggleInput') {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                />
              </Box>

             

              {/* Service Address Section */}
              {selectedClient && (
                <Box sx={{ mt: 4 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={invoice.use_client_address}
                        onChange={(e) => handleCheckboxChange('use_client_address', e.target.checked)}
                      />
                    }
                    label="Use client address as service address"
                  />

                  {/* Only show inputs when checkbox is unchecked */}
                  {!invoice.use_client_address && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Service Address
                      </Typography>
                      
                      <TextField
                        fullWidth
                        label="Street Address"
                        variant="outlined"
                        value={invoice.service_address.address}
                        onChange={(e) => handleInputChange('service_address', { address: e.target.value })}
                        sx={{ 
                          mb: 2,
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid red !important',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderWidth: '1px !important',
                              borderColor: 'rgba(0, 0, 0, 0.23) !important',
                            },
                          }
                        }}
                      />

                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            label="City"
                            variant="outlined"
                            value={invoice.service_address.city}
                            onChange={(e) => handleInputChange('service_address', { city: e.target.value })}
                            sx={{ 
                              mb: 2,
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: '2px solid red !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderWidth: '1px !important',
                                  borderColor: 'rgba(0, 0, 0, 0.23) !important',
                                },
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="State"
                            variant="outlined"
                            value={invoice.service_address.state}
                            onChange={(e) => handleInputChange('service_address', { state: e.target.value })}
                            sx={{ 
                              mb: 2,
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: '2px solid red !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderWidth: '1px !important',
                                  borderColor: 'rgba(0, 0, 0, 0.23) !important',
                                },
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            label="ZIP"
                            variant="outlined"
                            value={invoice.service_address.zip}
                            onChange={(e) => handleInputChange('service_address', { zip: e.target.value })}
                            sx={{ 
                              mb: 2,
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: '2px solid red !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderWidth: '1px !important',
                                  borderColor: 'rgba(0, 0, 0, 0.23) !important',
                                },
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Paper>
        );
      case 1:
        return (
          <Paper
            elevation={2}
            sx={{
              p: 3,
              backgroundColor: 'var(--background-default)',
              borderRadius: '8px',
              border: '1px solid var(--border-color)',
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ mb: 2, color: 'var(--text-primary)' }}>
              Add Items to Invoice
            </Typography>
            <SavedItemsManager
              onAddToEstimate={handleAddItem}
              savedItems={savedItems}
              setSavedItems={setSavedItems}
              estimateItems={invoice.items}
              onRemoveItem={handleRemoveItem}
            />
          </Paper>
        );
      case 2:
        return (
          <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
            <Typography variant="h6" gutterBottom>
              Additional Details
            </Typography>
            
            <Box sx={{ mb: 5 }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  color: '#34495e',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    width: '4px',
                    height: '24px',
                    backgroundColor: '#3498db',
                    marginRight: '12px',
                    borderRadius: '4px'
                  }
                }}
              >
                Client Message
              </Typography>
              <ReactQuill
                value={invoice.notes}
                onChange={(content) => handleInputChange('notes', content)}
                placeholder="Message to the client..."
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['clean']
                  ]
                }}
              />
            </Box>
           
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  color: '#34495e',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    width: '4px',
                    height: '24px',
                    backgroundColor: '#3498db',
                    marginRight: '12px',
                    borderRadius: '4px'
                  }
                }}
              >
                Terms & Conditions
              </Typography>
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>Select Terms Template</InputLabel>
                <Select
                  value={selectedTermsTemplate ? selectedTermsTemplate.id : ''}
                  onChange={handleTermsTemplateChange}
                  label="Select Terms Template"
                >
                  {termsTemplates.map(template => (
                    <MenuItem key={template.id} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ReactQuill
                value={invoice.termsAndConditions}
                onChange={(content) => handleInputChange('termsAndConditions', content)}
                placeholder="Enter terms and conditions..."
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['clean']
                  ]
                }}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
           
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  color: '#34495e',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    width: '4px',
                    height: '24px',
                    backgroundColor: '#3498db',
                    marginRight: '12px',
                    borderRadius: '4px'
                  }
                }}
              >
                Photos
              </Typography>
              <Box 
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                sx={{
                  border: isDragging ? '2px dashed #3498db' : '2px dashed #ccc',
                  borderRadius: '8px',
                  p: 3,
                  textAlign: 'center',
                  cursor: 'pointer',
                  mb: 2
                }}
              >
                <Typography variant="body1">
                  Drag and drop images here, or click to select files
                </Typography>
                <input
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                  id="file-upload"
                />
                <label htmlFor="file-upload">
                  <Button variant="contained" component="span" sx={{ mt: 2 }}>
                    Select Files
                  </Button>
                </label>
              </Box>
              {isLoadingPhotos && <CircularProgress />}
              {photoError && <Typography color="error">{photoError}</Typography>}
             
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {invoice.project_images?.map((image, index) => (
                  <Box key={index} sx={{ position: 'relative', width: '150px', height: '150px' }}>
                    <img
                      src={image.url}
                      alt={`Uploaded ${index}`}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          </Paper>
        );
      case 3:
        return (
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Box sx={{
              width: '210mm',
              transform: 'scale(0.7)',
              transformOrigin: 'top center',
              '@media (max-width: 600px)': {
                transform: 'scale(0.5)',
              },
              '@media (max-width: 400px)': {
                transform: 'scale(0.4)',
              }
            }}>
              {renderInvoiceTemplate()}
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };
 
  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const details = await getBusinessDetails();
        console.log('Fetched business details:', details);
        if (details) {
          const formattedBusinessDetails = {
            company_name: details.company_name,
            owner_name: details.owner_name,
            address: details.address,
            city: details.city,
            state: details.state,
            zip_code: details.zip_code,
            phone: details.phone,
            email: details.email,
            logo: details.logo
          };
          setBusinessDetails(formattedBusinessDetails);
          setTemplateBusinessDetails(formattedBusinessDetails);
          setInvoice(prev => ({
            ...prev,
            businessDetails: formattedBusinessDetails
          }));
        }
      } catch (error) {
        console.error('Error fetching business details:', error);
      }
    };

    fetchBusinessDetails();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        console.log('Fetching clients...');
        const response = await getClients();
        console.log('Full API response:', response);
        
        if (response && response.clients) {
          // Access the clients array from the nested structure
          setClients(response.clients);
          console.log('Clients loaded:', response.clients.length);
          console.log('First client example:', response.clients[0]);
        } else {
          console.warn('No clients array in response');
          setClients([]);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClients([]);
      }
    };

    fetchClients();
  }, []);

  const renderInvoiceTemplate = () => {
    console.log("Rendering invoice template with:", { 
      invoice, 
      businessDetails, 
      customerDetails: invoice.customerDetails 
    });
    
    const TemplateComponent = templateOptions.find(t => t.value === invoice.template)?.component || DefaultInvoiceTemplate;
    
    return (
      <TemplateComponent
        invoice={invoice}
        businessDetails={businessDetails || templateBusinessDetails}
        customerDetails={invoice.customerDetails}
      />
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // Format customer details
    const customer_details = selectedClient && !invoice.customerDetails.isNewClient
      ? {
          customer_name: selectedClient.name,
          customer_company: selectedClient.company,
          customer_email: selectedClient.email,
          customer_phone: selectedClient.phone,
          customer_address: selectedClient.address,
          customer_city: selectedClient.city,
          customer_state: selectedClient.state,
          customer_zip: selectedClient.zipCode,
        }
      : {
          customer_name: invoice.customerDetails.name,
          customer_company: invoice.customerDetails.company,
          customer_email: invoice.customerDetails.email,
          customer_phone: invoice.customerDetails.phone,
          customer_address: invoice.customerDetails.address,
          customer_city: invoice.customerDetails.city,
          customer_state: invoice.customerDetails.state,
          customer_zip: invoice.customerDetails.zipCode,
        };

    // Construct invoice data
    const invoiceData = {
      invoice_number: invoice.invoiceNumber,
      issue_date: invoice.issueDate.toISOString(),
      due_date: invoice.dueDate.toISOString(),
      items: invoice.items,
      notes: invoice.notes,
      terms_and_conditions: invoice.termsAndConditions,
      template: invoice.template,
      logo: invoice.logo,
      logo_size: invoice.logoSize,
      business_details: businessDetails, // Use the state directly
      customer_details: customer_details,
      include_notes: invoice.includeNotes,
      include_terms: invoice.includeTerms,
      service_address: invoice.service_address,
      use_client_address: invoice.use_client_address,
      client_id: selectedClient?.id,
      status: 'draft',
    };

    console.log("Submitting invoice data:", invoiceData);

    try {
      const response = await createInvoice(invoiceData);
      console.log('Invoice created:', response);
      setSuccessDialog(true);
      setSnackbar({
        open: true,
        message: 'Invoice created successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error creating invoice:', error);
      setSnackbar({
        open: true,
        message: `Failed to create invoice: ${error.response?.data?.error || 'Unknown error'}`,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleInputChange('template', 'default');
  }, []);

  useEffect(() => {
    // Check for returning client data
    if (location.state?.newClient && location.state?.autoSelect) {
      console.log('New client returned:', location.state.newClient);
      const returnedClient = location.state.newClient;
      
      // Update the selected client
      setSelectedClient(returnedClient);
      
      // Update invoice with new client details
      setInvoice(prev => ({
        ...prev,
        customerDetails: {
          name: returnedClient.name || '',
          company: returnedClient.company_name || '',
          email: returnedClient.email || '',
          phone: returnedClient.phone || '',
          address: returnedClient.address || '',
          city: returnedClient.city || '',
          state: returnedClient.state || '',
          zipCode: returnedClient.zip_code || '',
        }
      }));

      // Clear the location state
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  // Add this handler function
  const goToCreateClient = () => {
    console.log("Add new customer clicked");
    navigate('/admin/create-client', {
      state: { from: 'invoice-creation' }
    });
  };

  // If no creation type is selected, show the selection screen
  if (!invoiceCreationType && !location.state?.fromClientCreation) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 600 }}>
          Create Invoice
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          gap: 3,
          justifyContent: 'center',
          mt: 20
        }}>
          <Paper
            sx={{
              p: 3,
              cursor: 'pointer',
              width: '300px',
              textAlign: 'center',
              transition: 'all 0.2s ease-in-out',
              border: '2px dashed #e0e0e0',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
                borderColor: '#2196f3',
                backgroundColor: 'rgba(33, 150, 243, 0.04)',
                transform: 'translateY(-4px)'
              }
            }}
            onClick={() => setInvoiceCreationType('new')}
          >
            <Plus 
              size={48} 
              weight="thin"
              style={{ 
                marginBottom: '16px',
                color: '#2196f3'
              }} 
            />
            <Typography variant="h6" gutterBottom>
              Create New Invoice
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Start with a blank invoice and add items manually
            </Typography>
          </Paper>

          <Paper
            sx={{
              p: 3,
              cursor: 'pointer',
              width: '300px',
              textAlign: 'center',
              transition: 'all 0.2s ease-in-out',
              border: '2px dashed #e0e0e0',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
                borderColor: '#2196f3',
                backgroundColor: 'rgba(33, 150, 243, 0.04)',
                transform: 'translateY(-4px)'
              }
            }}
            onClick={() => navigate('/admin/create-invoice-from-project')}
          >
            <FolderSimplePlus 
              size={48} 
              weight="thin"
              style={{ 
                marginBottom: '16px',
                color: '#2196f3'
              }} 
            />
            <Typography variant="h6" gutterBottom>
              Create from Project
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Generate an invoice based on an existing project
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  // If they selected 'new', show the existing invoice creation form
  if (invoiceCreationType === 'new') {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom className={styles.invoiceTitle}>
          Create Invoice
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleStepClick(index)} sx={{ cursor: 'pointer' }}>
              <StepLabel sx={{
                '& .MuiStepLabel-label': {
                  color: activeStep === index ? 'var(--text-primary)' : 'var(--text-secondary)',
                  fontWeight: activeStep === index ? 600 : 400
                }
              }}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {getCurrentStepContent()}
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                backgroundColor: 'var(--background-secondary)',
                borderRadius: '8px',
                border: '1px solid var(--border-color)',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, color: 'var(--text-primary)' }}>
                Invoice Details
              </Typography>

              <TextField
                label="Invoice Number"
                value={invoice.invoiceNumber}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="dense"
                variant="outlined"
                size="small"
              />

              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Issue Date"
                      value={invoice.issueDate}
                      onChange={(newValue) => handleInputChange('issueDate', newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          size="small"
                          sx={{ backgroundColor: 'var(--background-secondary)' }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Due Date"
                      value={invoice.dueDate}
                      onChange={(newValue) => handleInputChange('dueDate', newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          size="small"
                          sx={{ backgroundColor: 'var(--background-secondary)' }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={() => setActiveStep(activeStep - 1)}
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  sx={{
                    flexGrow: 1,
                    textTransform: 'none',
                    fontWeight: 600,
                    borderColor: 'var(--primary)',
                    color: 'var(--text-secondary)',
                    '&:hover': {
                      backgroundColor: 'rgba(52, 152, 219, 0.1)',
                      borderColor: 'var(--primary)',
                    },
                  }}
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    if (activeStep === steps.length - 1) {
                      handleSubmit();
                    } else {
                      setActiveStep(activeStep + 1);
                    }
                  }}
                  endIcon={activeStep === steps.length - 1 ? <Save /> : <ArrowForward />}
                  sx={{
                    flexGrow: 1,
                    textTransform: 'none',
                    fontWeight: 600,
                    backgroundColor: activeStep === steps.length - 1 ? 'var(--success)' : 'var(--primary)',
                    color: 'var(--text-secondary)',
                    '&:hover': {
                      backgroundColor: activeStep === steps.length - 1 ? 'var(--success-hover)' : 'var(--primary-hover)',
                    },
                  }}
                >
                  {activeStep === steps.length - 1 ? 'Save Invoice' : 'Next'}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Dialog open={successDialog} onClose={() => setSuccessDialog(false)}>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Invoice Created Successfully!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSuccessDialog(false);
                  navigate('/admin/invoices');
                }}
                sx={{ mt: 2 }}
              >
                View Invoices
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
};

export default AdminCreateInvoice;

import React, { useState, useEffect } from 'react';
import { 
  Typography,
  Box,
  IconButton,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { format, addDays, addWeeks, addMonths, startOfMonth, endOfMonth } from 'date-fns';
import WeekView from './calendar/WeekView';
import DayView from './calendar/DayView';
import MonthView from './calendar/MonthView';
import styles from './Calendar.module.scss';
import { getAllEvents } from '../services/api';
import EventCard from './calendar/EventCard';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: '#f4f4f4',
  border: '1px solid var(--divider)',
  borderRadius: '12px',
  padding: '4px',
  gap: '4px',
  position: 'relative',
  '& .MuiToggleButton-root': {
    minWidth: '90px',
    border: 'none',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '6px 16px',
    letterSpacing: '0.01em',
    color: 'var(--text-secondary)',
    transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'rgba(var(--primary-rgb), 0.08)',
    },
    '&.Mui-selected': {
      backgroundColor: '#ffffff',
      color: 'text.primary',
      fontWeight: 600,
      letterSpacing: '0.02em',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [view, setView] = useState('month');
  const [events, setEvents] = useState([]); 

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleDateChange = (amount) => {
    switch (view) {
      case 'day':
        setSelectedDate(prev => addDays(prev, amount));
        break;
      case 'week':
        setSelectedDate(prev => addWeeks(prev, amount));
        break;
      case 'month':
        setSelectedDate(prev => addMonths(prev, amount));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Fetch events for the entire selected month
        const startDate = startOfMonth(selectedDate);
        const endDate = endOfMonth(selectedDate);

        const fetchedEvents = await getAllEvents({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd')
        });

        console.log("Fetched events:", fetchedEvents);

        // Set default time slots if none are provided and log found time slots
        const eventsWithTimeSlots = fetchedEvents.map(event => {
          if (event.time_slots.length === 0) {
            console.log(`Event with ID ${event.id} has no time slots. Using start_time and end_time.`);

            // Handle invalid start_time or end_time
            const startTime = event.start_time ? new Date(event.start_time) : null;
            const endTime = event.end_time ? new Date(event.end_time) : null;

            if (startTime && !isNaN(startTime) && endTime && !isNaN(endTime)) {
              // Both start_time and end_time are valid
              return {
                ...event,
                time_slots: [{
                  start_time: startTime.toISOString(),
                  end_time: endTime.toISOString()
                }]
              };
            } else {
              // Handle cases where start_time or end_time is invalid
              console.error(`Event with ID ${event.id} has invalid start_time or end_time. Skipping event.`);
              return null; // Or handle it in another way, e.g., display a placeholder
            }
          } else {
            console.log(`Event with ID ${event.id} has time slots:`, event.time_slots);
            return event;
          }
        });

        // Remove null events if any
        const validEvents = eventsWithTimeSlots.filter(event => event !== null);
        setEvents(validEvents);

      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };

    fetchEvents();
  }, [selectedDate, view]); // Add view to the dependency array

  const handleEventUpdated = () => {
    // Refresh your events data
    getAllEvents(); // or however you're fetching events
  };

  return (
    <div className={styles.calendarContainer}>
      <Box className={styles.calendarHeader}>
        <Box className={styles.dateNavigation}>
          <IconButton onClick={() => handleDateChange(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h6" className={styles.currentDate}>
            {format(selectedDate, 'MMMM yyyy')}
          </Typography>
          <IconButton onClick={() => handleDateChange(1)}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
        
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          size="small"
        >
          <ToggleButton value="day" disableRipple>Day</ToggleButton>
          <ToggleButton value="week" disableRipple>Week</ToggleButton>
          <ToggleButton value="month" disableRipple>Month</ToggleButton>
        </StyledToggleButtonGroup>
      </Box>

      <div className={styles.calendarContent}>
        {view === 'week' && (
          <WeekView 
            events={events}
            selectedDate={selectedDate}
            onEventUpdated={handleEventUpdated}
          />
        )}
        {view === 'day' && (
          <DayView 
            events={events}
            selectedDate={selectedDate}
          />
        )}
        {view === 'month' && (
          <MonthView 
            events={events}
            selectedDate={selectedDate}
            onEventUpdated={handleEventUpdated}
          />
        )}
      </div>
    </div>
  );
};

export default Calendar;

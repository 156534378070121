import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
  Snackbar,
  Alert
} from '@mui/material';
import {
  X as CloseIcon,
  PencilSimple as EditIcon,
  Plus as PlusIcon
} from "@phosphor-icons/react";
import TriggerSelector from './TriggerSelector';
import ActionSelector from './ActionSelector';
import DoThisSelector from './DoThisSelector';
import RecipientSelector from './RecipientSelector';
import TypeSelector from './TypeSelector';
import TimeSelector from './TimeSelector';
import styled from 'styled-components';
import BaseInput from './shared/BaseInput';
import { createAutomation } from '../services/api';

const PROJECT_STATUSES = {
  PENDING_SCHEDULING: { id: 'pending_scheduling', label: 'Pending Scheduling' },
  SCHEDULED: { id: 'scheduled', label: 'Scheduled' },
  IN_PROGRESS: { id: 'in_progress', label: 'In Progress' },
  PENDING_INVOICING: { id: 'pending_invoicing', label: 'Pending Invoicing' },
  COMPLETED: { id: 'completed', label: 'Completed' }
};

const TimeDropdown = styled.div`
  .inputGroup {
    font-family: inherit;
    position: relative;
    margin-bottom: 8px;
  }

  select {
    font-size: 0.9rem;
    padding: 12px 16px;
    outline: none;
    border: 1px solid rgba(74, 93, 249, 0.2);
    background-color: var(--background-primary);
    border-radius: 8px;
    width: 240px;
    height: 45px;
    box-sizing: border-box;
    appearance: none;
    cursor: pointer;
    color: var(--text-primary);
    transition: all 0.2s ease-in-out;

    &:hover {
      border-color: rgba(74, 93, 249, 0.4);
      background-color: rgba(74, 93, 249, 0.03);
    }

    &:focus {
      border-color: #4a5df9;
      box-shadow: 0 0 0 2px rgba(74, 93, 249, 0.1);
    }
  }

  label {
    font-size: 0.8rem;
    position: absolute;
    left: 12px;
    top: -8px;
    background-color: var(--background-primary);
    color: rgba(26, 26, 26, 0.7);
    padding: 0 4px;
    pointer-events: none;
  }

  /* Custom dropdown arrow */
  select {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
    padding-right: 40px;
  }
`;

function CreateAutomation() {
  const navigate = useNavigate();
  const [automation, setAutomation] = useState({
    title: '',
    trigger: null,
    action: null
  });
  const [showTriggerSelector, setShowTriggerSelector] = useState(false);
  const [showStatusSelector, setShowStatusSelector] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(PROJECT_STATUSES.PENDING_SCHEDULING);
  const [showDoThisSelector, setShowDoThisSelector] = useState(false);
  const [showRecipientSelector, setShowRecipientSelector] = useState(false);
  const [showTypeSelector, setShowTypeSelector] = useState(false);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [currentOptionType, setCurrentOptionType] = useState(null);
  const [timeOption, setTimeOption] = useState('24-7');
  const [customStartTime, setCustomStartTime] = useState('09:00');
  const [customEndTime, setCustomEndTime] = useState('17:00');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isSuccess, setIsSuccess] = useState(false);

  // Modified time options generator to include AM/PM
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute of ['00', '30']) {
        const period = hour >= 12 ? 'PM' : 'AM';
        const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const time = `${hour.toString().padStart(2, '0')}:${minute}`; // Keep 24h format for value
        const label = `${displayHour}:${minute} ${period}`; // 12h format for display
        options.push({ value: time, label: label });
      }
    }
    return options;
  };

  const timeOptions = generateTimeOptions();

  const handleTriggerSelect = (selectedTrigger) => {
    console.log('TriggerSelector data:', selectedTrigger);
    if (selectedTrigger) {
      setAutomation(prev => ({
        ...prev,
        trigger: selectedTrigger
      }));
    }
    setShowTriggerSelector(false);
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
    setShowStatusSelector(false);
  };

  const handleActionSelect = (action) => {
    console.log('Action selected:', action);
    setSelectedAction(action);
    setShowDoThisSelector(false);

    // Clear recipient-related fields if it's a 'create' action
    if (action?.id === 'create') {
      setSelectedRecipient(null);
      setSelectedType(null);
      setSelectedTime(null);
    } else if (action?.id === 'send') {
      // Set defaults for 'send' action
      setSelectedRecipient({ id: 'someone', label: 'someone' });
      setSelectedType({ id: 'reminder', label: 'a reminder' });
      setSelectedTime({ id: 'defined', label: 'within a defined time' });
    }
  };

  const handleRecipientSelect = (recipient) => {
    setSelectedRecipient(recipient);
    setShowRecipientSelector(false);
  };

  const handleTypeSelect = (type) => {
    if (type) {
      setSelectedType(type);
    } else {
      setShowTypeSelector(false);
    }
  };

  const handleTimeSelect = (time) => {
    console.log('Time selected:', time); // Debug log
    setSelectedTime(time);
    setShowTimeSelector(false);
  };

  const handleCreateAutomation = async () => {
    try {
      console.log('Starting automation creation...');

      // Generate default title if none provided
      let automationTitle = automation.title;
      if (!automationTitle || automationTitle.trim() === '') {
        if (selectedAction?.id === 'send') {
          automationTitle = `Send ${selectedType?.label || 'reminder'} ${selectedTime?.displayText || ''}`;
        } else if (selectedAction?.id === 'create') {
          automationTitle = `Create invoice when ${automation.trigger?.label || 'event'} ${automation.trigger?.action?.label || 'changes'}`;
        } else {
          automationTitle = `${automation.trigger?.label || 'Event'} ${automation.trigger?.action?.label || 'Automation'}`;
        }
      }

      let actionData = {
        type: selectedAction?.id,
        schedule: {
          type: timeOption,
          start_time: timeOption === 'custom' ? customStartTime : null,
          end_time: timeOption === 'custom' ? customEndTime : null
        }
      };

      // Log action data
      console.log('Action data before conditional:', actionData);

      // Only include timing and recipient fields for 'send' actions
      if (selectedAction?.id === 'send') {
        actionData = {
          ...actionData,
          timing: selectedTime?.timing?.id || 'immediate',
          timing_value: selectedTime?.id,
          timing_type: selectedTime?.timing?.id === 'before_start' ? 'before' : 'after',
          recipient: selectedRecipient?.id,
          message_type: selectedType?.id
        };
      }

      // Log final action data
      console.log('Final action data:', actionData);

      const automationData = {
        title: automationTitle,  // Use the generated or user-provided title
        trigger: {
          type: automation.trigger?.id,
          action: automation.trigger?.action?.id
        },
        action: actionData
      };

      console.log('Final automation data:', automationData);

      const response = await createAutomation(automationData);
      console.log('Server response:', response);

      if (response.status === 201 || response?.automation) {
        console.log('Setting success state to true');
        setIsSuccess(true);
        
        setSnackbar({
          open: true,
          message: 'Automation created successfully! Redirecting...',
          severity: 'success'
        });

        setTimeout(() => {
          const container = document.querySelector('.automation-container');
          if (container) {
            container.style.animation = 'successFadeOut 1.5s ease-in-out forwards';
          }
          
          setTimeout(() => {
            console.log('Navigating to automations tab');
            navigate('/admin/settings', { 
              state: { 
                activeTab: 6,
                showSuccess: true,
                message: 'New automation created successfully!'
              },
              replace: true
            });
          }, 1500);
        }, 100);
      }
    } catch (error) {
      console.error('Error creating automation:', error);
      setSnackbar({
        open: true,
        message: 'Failed to create automation',
        severity: 'error'
      });
    }
  };

  const renderActionText = () => {
    if (!selectedAction) return 'do this';
    if (selectedAction.id === 'send') {
      return (
        <>
          send{' '}
          <Box
            component="span"
            onClick={(e) => {
              e.stopPropagation();
              setShowRecipientSelector(true);
              setShowTypeSelector(false);
              setShowTimeSelector(false);
            }}
            sx={{
              textDecoration: 'underline',
              color: '#4a5df9',
              cursor: 'pointer',
            }}
          >
            {selectedRecipient?.label || 'someone'}
          </Box>
          {' '}
          <Box
            component="span"
            onClick={(e) => {
              e.stopPropagation();
              setShowTypeSelector(true);
              setShowRecipientSelector(false);
              setShowTimeSelector(false);
            }}
            sx={{
              textDecoration: 'underline',
              color: '#4a5df9',
              cursor: 'pointer',
            }}
          >
            {selectedType?.label || 'a reminder'}
          </Box>
          {' '}
          <Box
            component="span"
            onClick={(e) => {
              e.stopPropagation();
              setShowTimeSelector(true);
              setShowRecipientSelector(false);
              setShowTypeSelector(false);
            }}
            sx={{
              textDecoration: 'underline',
              color: '#4a5df9',
              cursor: 'pointer',
            }}
          >
            {selectedTime?.displayText || 'within a defined time'}
          </Box>
        </>
      );
    }
    return selectedAction.label;
  };

  const resetAutomation = () => {
    setAutomation({
      title: '',
      trigger: null,
      action: null
    });
    setSelectedStatus(PROJECT_STATUSES.PENDING_SCHEDULING);
    setSelectedAction(null);
    setSelectedRecipient(null);
    setSelectedType(null);
    setSelectedTime(null);
    setTimeOption('24-7');
    
    // Close all selectors
    setShowTriggerSelector(false);
    setShowStatusSelector(false);
    setShowDoThisSelector(false);
    setShowRecipientSelector(false);
    setShowTypeSelector(false);
    setShowTimeSelector(false);
  };

  return (
    <Box
      className={`automation-container ${isSuccess ? 'success-animation' : ''}`}
      sx={{
        padding: { xs: 2, md: 4 },
        position: 'relative',
        transition: 'all 0.3s ease-in-out',
        '@global': {
          '@keyframes successFadeOut': {
            '0%': {
              opacity: 1,
              transform: 'scale(1)',
              backgroundColor: 'transparent',
            },
            '50%': {
              opacity: 0.8,
              transform: 'scale(1.02)',
              backgroundColor: 'rgba(74, 93, 249, 0.1)',
            },
            '100%': {
              opacity: 0,
              transform: 'scale(1.05)',
              backgroundColor: 'rgba(74, 93, 249, 0.2)',
            },
          },
        },
        '&.success-animation': {
          animation: 'successFadeOut 1.5s ease-in-out forwards !important',
          animationFillMode: 'forwards !important',
        },
      }}
    >
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 8,
        px: 4,
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        pl: { xs: '5%', md: '15%' },
        pr: { xs: '5%', md: '15%' },
        paddingBottom: 4
      }}>
        <Box sx={{ 
          width: '240px',
          display: 'flex',
          alignItems: 'center',
        }}>
          <BaseInput
            label="Rule title"
            value={automation.title}
            onChange={(e) => setAutomation({ ...automation, title: e.target.value })}
            name="ruleTitle"
            style={{
              margin: 0,
            }}
          />
        </Box>
      </Box>

      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 3, md: 4 },
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          pl: { xs: '5%', md: '15%' },
          pr: { xs: '5%', md: '15%' },
          position: 'relative',
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'flex-start', 
            gap: { xs: 3, md: 4 },
            position: 'relative',
          }}
        >
          <Box
            sx={{
              width: 44,
              height: 44,
              borderRadius: '50%',
              backgroundColor: 'rgba(74, 93, 249, 0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0,
              mt: 0.5
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: '#4a5df9'
              }}
            />
          </Box>
          
          <Box sx={{ position: 'relative' }}>
            <Typography
              sx={{
                color: '#1a1a1a',
                fontSize: '1.75rem',
                fontWeight: 500,
                letterSpacing: '-0.01em',
              }}
            >
              When{' '}
              <Box
                component="span"
                onClick={() => setShowTriggerSelector(true)}
                sx={{
                  textDecoration: 'underline',
                  color: '#4a5df9',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                {automation.trigger 
                  ? `${automation.trigger.label} ${automation.trigger.action.label}`
                  : 'this happens'}
              </Box>
              {automation.trigger?.action?.id === 'status' && (
                <>
                  {' '}
                  <Box
                    component="span"
                    onClick={() => setShowStatusSelector(true)}
                    sx={{
                      textDecoration: 'underline',
                      color: '#4a5df9',
                      cursor: 'pointer',
                    }}
                  >
                    {selectedStatus.label.toLowerCase()}
                  </Box>
                </>
              )}
            </Typography>

            {showTriggerSelector && (
              <Box sx={{ 
                position: 'absolute',
                top: 'calc(100% + 8px)',
                left: 0,
                zIndex: 10
              }}>
                <TriggerSelector onSelect={handleTriggerSelect} />
              </Box>
            )}

            {showStatusSelector && (
              <Box sx={{ 
                position: 'absolute',
                top: 'calc(100% + 8px)',
                left: '50%',
                zIndex: 10
              }}>
                <ActionSelector 
                  onSelect={handleStatusSelect}
                  showStatusSelector={true}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 3, md: 4 },
            ml: 2,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 22,
              top: -40,
              width: 2,
              height: 'calc(100% + 40px)',
              backgroundColor: 'rgba(74, 93, 249, 0.2)',
              zIndex: 0
            }
          }}
        >
          <Box
            sx={{
              width: 44,
              height: 44,
              borderRadius: '50%',
              backgroundColor: 'rgba(74, 93, 249, 0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              zIndex: 1
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: '#4a5df9'
              }}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Typography
              sx={{
                color: '#1a1a1a',
                fontSize: '1.75rem',
                fontWeight: 500,
                letterSpacing: '-0.01em',
              }}
            >
              <Box
                component="span"
                onClick={() => {
                  setShowDoThisSelector(true);
                  setShowRecipientSelector(false);
                  setShowTypeSelector(false);
                  setShowTimeSelector(false);
                }}
                sx={{
                  cursor: 'pointer',
                  color: selectedAction ? 'inherit' : '#4a5df9',
                  textDecoration: selectedAction ? 'none' : 'underline',
                }}
              >
                {renderActionText()}
              </Box>
            </Typography>

            {showDoThisSelector && (
              <Box sx={{ position: 'absolute', top: 'calc(100% + 8px)', left: 0, zIndex: 10 }}>
                <DoThisSelector 
                  onSelect={handleActionSelect}
                  triggerType={automation.trigger?.id}
                  triggerAction={automation.trigger?.action?.id}
                />
              </Box>
            )}

            {showRecipientSelector && (
              <Box sx={{ position: 'absolute', top: 'calc(100% + 8px)', left: '4.5em', zIndex: 10 }}>
                <RecipientSelector onSelect={handleRecipientSelect} />
              </Box>
            )}

            {showTypeSelector && (
              <Box sx={{ position: 'absolute', top: 'calc(100% + 8px)', left: '12em', zIndex: 10 }}>
                <TypeSelector onSelect={handleTypeSelect} />
              </Box>
            )}

            {showTimeSelector && (
              <Box sx={{ position: 'absolute', top: 'calc(100% + 8px)', left: '20em', zIndex: 10 }}>
                <TimeSelector 
                  onSelect={handleTimeSelect} 
                  includeBeforeStart={automation.trigger?.id === 'project'}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: { xs: 4, md: 6 },
          pr: { xs: '10%', md: '15%' },
          position: 'relative'
        }}>
          <Box>
            <TimeDropdown>
              <div className="inputGroup">
                <select
                  value={timeOption}
                  onChange={(e) => setTimeOption(e.target.value)}
                  name="timeSelector"
                >
                  <option value="24-7">Send 24/7</option>
                  <option value="9-5">Send during business hours (9-5)</option>
                  <option value="custom">Custom schedule</option>
                </select>
                <label>Sending Schedule</label>
              </div>
            </TimeDropdown>

            {timeOption === 'custom' && (
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                mt: 2,
                alignItems: 'center'
              }}>
                <TimeDropdown>
                  <div className="inputGroup">
                    <select
                      value={customStartTime}
                      onChange={(e) => setCustomStartTime(e.target.value)}
                      name="startTime"
                    >
                      {timeOptions.map((time) => (
                        <option key={`start-${time.value}`} value={time.value}>
                          {time.label}
                        </option>
                      ))}
                    </select>
                    <label>Start Time</label>
                  </div>
                </TimeDropdown>

                <Typography sx={{ color: 'rgba(26, 26, 26, 0.7)' }}>to</Typography>

                <TimeDropdown>
                  <div className="inputGroup">
                    <select
                      value={customEndTime}
                      onChange={(e) => setCustomEndTime(e.target.value)}
                      name="endTime"
                    >
                      {timeOptions.map((time) => (
                        <option key={`end-${time.value}`} value={time.value}>
                          {time.label}
                        </option>
                      ))}
                    </select>
                    <label>End Time</label>
                  </div>
                </TimeDropdown>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              variant="outlined"
              onClick={resetAutomation}
              sx={{
                borderColor: 'rgba(26, 26, 26, 0.2)',
                color: 'rgba(26, 26, 26, 0.7)',
                fontSize: '0.9rem',
                textTransform: 'none',
                borderRadius: '8px',
                padding: { xs: '8px 20px', md: '10px 24px' },
                minWidth: { xs: '120px', md: '120px' },
                '&:hover': {
                  backgroundColor: 'rgba(26, 26, 26, 0.05)',
                  borderColor: 'rgba(26, 26, 26, 0.4)',
                  color: 'rgba(26, 26, 26, 0.9)'
                },
                '&:active': {
                  backgroundColor: 'rgba(26, 26, 26, 0.1)',
                }
              }}
            >
              Reset
            </Button>

            <Button
              variant="contained"
              startIcon={<PlusIcon weight="bold" />}
              onClick={handleCreateAutomation}
              sx={{
                backgroundColor: '#4a5df9',
                color: 'white',
                borderRadius: '8px',
                padding: { xs: '8px 20px', md: '10px 24px' },
                textTransform: 'none',
                fontSize: '0.9rem',
                fontWeight: 500,
                minWidth: { xs: '120px', md: '120px' },
                '&:hover': {
                  backgroundColor: '#3544b0'
                }
              }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default CreateAutomation; 
 
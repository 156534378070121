import React, { useState } from 'react';
import { 
  Button, 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Fade,
  Zoom,
  Divider,
  Chip
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpdateIcon from '@mui/icons-material/Update';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateProjectStatus, getProjectStatus, createProjectInvoice, getBusinessDetails, getProjectEvents, deleteProject, updateEventStatus } from '../services/api';
import { useNavigate } from 'react-router-dom';

const PROJECT_STATUSES = {
  PENDING_SCHEDULING: 'pending_scheduling',
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  PENDING_INVOICING: 'pending_invoicing',
  COMPLETED: 'completed'
};

const PROJECT_STATUS_LABELS = {
  [PROJECT_STATUSES.PENDING_SCHEDULING]: 'Pending Scheduling',
  [PROJECT_STATUSES.SCHEDULED]: 'Scheduled',
  [PROJECT_STATUSES.IN_PROGRESS]: 'In Progress',
  [PROJECT_STATUSES.PENDING_INVOICING]: 'Pending Invoicing',
  [PROJECT_STATUSES.COMPLETED]: 'Completed'
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/_/g, ' ');
};

const ProjectActionButton = ({ project, onStatusUpdate }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(project.status || PROJECT_STATUSES.PENDING_SCHEDULING);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [showEventSelectionModal, setShowEventSelectionModal] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [projectEvents, setProjectEvents] = useState([]);
  const [invoicedEvents, setInvoicedEvents] = useState({});
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => setAnchorEl(null);

  const handleStatusChange = () => {
    setStatusDialogOpen(true);
    handleClose();
  };

  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
  };

  const handleStatusSubmit = async () => {
    try {
      await updateProjectStatus(project.id, selectedStatus);
      
      // Verify the status was updated
      const statusResponse = await getProjectStatus(project.id);
      console.log('New status verified:', statusResponse.status);
      
      if (onStatusUpdate) {
        await onStatusUpdate();
      }
      
      handleStatusDialogClose();
    } catch (error) {
      console.error('Failed to update project status:', error);
    }
  };

  const handleCreateInvoice = async () => {
    try {
      const events = await getProjectEvents(project.id);
      console.log('Fetched events:', events); // Debug log
      
      // Create a map of event IDs to their invoice IDs
      const eventsWithInvoices = events.reduce((acc, event) => {
        // Check if invoice_id exists and is not null
        if (event.invoice_id) {
          acc[event.id] = event.invoice_id;
          console.log(`Event ${event.id} is invoiced with invoice ${event.invoice_id}`); // Debug log
        }
        return acc;
      }, {});
      
      console.log('Events with invoices:', eventsWithInvoices); // Debug log
      setInvoicedEvents(eventsWithInvoices);
      setProjectEvents(events);
      setShowEventSelectionModal(true);
      handleClose();
    } catch (error) {
      console.error('Error fetching project events:', error);
    }
  };

  const handleEventSelection = (event) => {
    console.log('Event being selected/deselected:', event);
    const newSelectedEvents = selectedEvents.includes(event.id)
      ? selectedEvents.filter(id => id !== event.id)  // remove if already selected
      : [...selectedEvents, event.id];                // add if not selected
    
    console.log('Setting selectedEvents to:', newSelectedEvents);
    setSelectedEvents(newSelectedEvents);
  };

  const handleEventSelectionConfirm = async () => {
    try {
      setInvoiceLoading(true);
      console.log('Starting invoice creation...');
      
      // Get business details
      const businessProfileResponse = await getBusinessDetails();
      const businessDetails = businessProfileResponse;
      
      // Prepare the items and collect event IDs
      const selectedEventDetails = projectEvents.filter(event => 
        selectedEvents.includes(event.id)
      );
      
      const items = selectedEventDetails.map(event => ({
        event_id: event.id,
        title: event.title || 'Event',
        description: event.description || '',
        quantity: 1,
        unit_price: parseFloat(event.value || 0),
        total: parseFloat(event.value || 0)
      }));
      
      // Create request data
      const requestData = {
        business_details: businessDetails,
        items: items,
        event_ids: selectedEvents,
        project_name: project.name || project.estimate?.project_name || 'Untitled Project'
      };
      
      const response = await createProjectInvoice(project.id, requestData);
      console.log('Invoice creation response:', response);
      
      if (response.success) {
        // Update each event's status to 'invoiced'
        await Promise.all(selectedEvents.map(async (eventId) => {
          await updateEventStatus(eventId, 'invoiced');
        }));

        // Update local state
        const newInvoicedEvents = { ...invoicedEvents };
        selectedEvents.forEach(eventId => {
          newInvoicedEvents[eventId] = response.invoice.id;
        });
        setInvoicedEvents(newInvoicedEvents);
        
        const updatedProjectEvents = projectEvents.map(event => {
          if (selectedEvents.includes(event.id)) {
            return { ...event, status: 'invoiced', invoice_id: response.invoice.id };
          }
          return event;
        });
        setProjectEvents(updatedProjectEvents);
        
        setSelectedEvents([]);
        setShowEventSelectionModal(false);
        navigate(`/admin/invoices/${response.invoice.id}`);
      }
    } catch (error) {
      console.error('Error creating invoice:', error);
    } finally {
      setInvoiceLoading(false);
    }
  };

  const handleDuplicateProject = () => {
    // Implement duplicate logic
    console.log('Duplicate project:', project.id);
  };

  const handleCompleteProject = () => {
    setCompleteDialogOpen(true);
    handleClose();
  };

  const handleConfirmComplete = async () => {
    try {
      setIsCompleting(true);
      await updateProjectStatus(project.id, PROJECT_STATUSES.COMPLETED);
      
      // Verify the status was updated
      const statusResponse = await getProjectStatus(project.id);
      console.log('Project completed:', statusResponse.status);
      
      // Show success animation
      setShowSuccess(true);
      
      // Call the onStatusUpdate callback
      if (onStatusUpdate) {
        await onStatusUpdate();
      }
      
      // Wait for animation and close
      setTimeout(() => {
        setCompleteDialogOpen(false);
        setShowSuccess(false);
      }, 1500);
      
    } catch (error) {
      console.error('Failed to complete project:', error);
    } finally {
      setIsCompleting(false);
    }
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    handleClose();
  };

  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteProject(project.id);
      navigate('/admin/projects'); // Redirect to projects list
    } catch (error) {
      console.error('Failed to delete project:', error);
      // You might want to show an error message here
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
    }
  };

  const menuItemStyle = {
    padding: '8px 20px',
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.08)'
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, width: '100%' }}>
      <Button
        variant="contained"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        disableRipple
        sx={{
          backgroundColor: '#4a5df9',
          '&:hover': {
            backgroundColor: '#3c50fa'
          },
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '1rem'
        }}
      >
        Actions ⚡
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--background-paper)',
            borderColor: 'var(--divider)',
            boxShadow: 'var(--shadow-4)'
          },
          '& .MuiMenuItem-root': {
            color: 'var(--text-primary)',
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }
        }}
      >
        <MenuItem onClick={handleStatusChange} sx={menuItemStyle}>
          <ListItemIcon>
            <UpdateIcon sx={{ color: '#1976d2' }} />
          </ListItemIcon>
          <ListItemText primary="Update Status" />
        </MenuItem>
        <MenuItem 
          onClick={handleCreateInvoice} 
          sx={menuItemStyle}
          disabled={invoiceLoading}
        >
          <ListItemIcon>
            {invoiceLoading ? (
              <CircularProgress size={20} />
            ) : (
              <ReceiptIcon sx={{ color: '#2e7d32' }} />
            )}
          </ListItemIcon>
          <ListItemText>
            {invoiceLoading ? 'Creating Invoice...' : 'Create Invoice'}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicateProject} sx={menuItemStyle}>
          <ListItemIcon>
            <ContentCopyIcon sx={{ color: '#9c27b0' }} />
          </ListItemIcon>
          <ListItemText>Duplicate Project</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCompleteProject} sx={menuItemStyle}>
          <ListItemIcon>
            <CheckCircleIcon sx={{ color: '#00a152' }} />
          </ListItemIcon>
          <ListItemText>Complete Project</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem 
          onClick={handleDeleteClick} 
          sx={{
            color: 'error.main',
            '&:hover': {
              backgroundColor: 'error.lighter',
            }
          }}
        >
          <ListItemIcon>
            <DeleteIcon sx={{ color: '#d32f2f' }} />
          </ListItemIcon>
          <ListItemText>Delete Project</ListItemText>
        </MenuItem>
      </Menu>
      
      <Dialog
        open={statusDialogOpen}
        onClose={handleStatusDialogClose}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)'
          }
        }}
      >
        <DialogTitle sx={{ color: 'var(--text-primary)' }}>
          Update Project Status
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel sx={{ color: 'var(--text-primary)' }}>
              Status
            </InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              sx={{
                color: 'var(--text-primary)',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--divider)'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--primary-main)'
                },
                '& .MuiSvgIcon-root': {
                  color: 'var(--text-primary)'
                }
              }}
            >
              {Object.entries(PROJECT_STATUS_LABELS).map(([value, label]) => (
                <MenuItem 
                  key={value} 
                  value={value}
                  sx={{
                    color: 'var(--text-primary)',
                    '&:hover': {
                      backgroundColor: 'var(--hover-overlay)'
                    }
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleStatusDialogClose}
            sx={{ color: 'var(--text-primary)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleStatusSubmit} 
            variant="contained"
            sx={{
              backgroundColor: 'var(--primary-main)',
              '&:hover': {
                backgroundColor: 'var(--primary-dark)'
              }
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={showEventSelectionModal}
        onClose={() => setShowEventSelectionModal(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)'
          }
        }}
      >
        <DialogTitle>
          Select Events to Invoice - {project.name || project.estimate?.project_name || 'Project Events'}
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="warning.main" sx={{ mb: 2 }}>
            Note: Creating an invoice will automatically mark selected events as "invoiced".
          </Typography>
          <Box sx={{ mb: 2 }}>
            {projectEvents.map((event) => {
              const isInvoiced = Boolean(event.invoice_id);
              console.log(`Event ${event.id} invoice status:`, isInvoiced); // Debug log
              
              return (
                <Box 
                  key={event.id}
                  sx={{ 
                    mb: 2,
                    p: 2,
                    bgcolor: isInvoiced ? 'rgba(0, 200, 0, 0.05)' : 'rgba(0, 0, 0, 0.04)',
                    borderRadius: 1,
                    border: isInvoiced ? '1px solid rgba(0, 200, 0, 0.2)' : 'none',
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    sx={{ flex: 1, mr: 2 }}
                    control={
                      <Checkbox
                        checked={selectedEvents.includes(event.id)}
                        onChange={() => handleEventSelection(event)}
                        disabled={isInvoiced}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {event.title}
                          <Chip
                            label={capitalizeFirstLetter(event.status)}
                            size="small"
                            sx={{
                              backgroundColor: event.status === 'completed' ? '#E8F5E9' : 
                                             event.status === 'in_progress' ? '#E3F2FD' :
                                             event.status === 'pending' ? '#FFF3E0' : '#F5F5F5',
                              color: event.status === 'completed' ? '#2E7D32' :
                                     event.status === 'in_progress' ? '#1565C0' :
                                     event.status === 'pending' ? '#E65100' : '#757575',
                              height: '20px',
                              '& .MuiChip-label': {
                                px: 1,
                                fontSize: '0.75rem',
                                fontWeight: 500
                              }
                            }}
                          />
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {event.description}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Value: ${event.value || '0.00'}
                        </Typography>
                      </Box>
                    }
                  />
                  {isInvoiced && (
                    <Button
                      startIcon={<VisibilityIcon />}
                      onClick={() => navigate(`/admin/invoices/${event.invoice_id}`)}
                      size="small"
                      variant="outlined"
                      color="success"
                      sx={{ 
                        minWidth: 'auto',
                        borderRadius: 2,
                        textTransform: 'none',
                        px: 2,
                        height: 'fit-content'
                      }}
                    >
                      View
                    </Button>
                  )}
                </Box>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEventSelectionModal(false)}>Cancel</Button>
          <Button 
            onClick={handleEventSelectionConfirm}
            disabled={selectedEvents.length === 0 || invoiceLoading}
            variant="contained"
            sx={{
              backgroundColor: 'var(--primary-main)',
              '&:hover': {
                backgroundColor: 'var(--primary-dark)'
              },
              '&.Mui-disabled': {
                backgroundColor: 'var(--background-secondary)',
                color: 'var(--text-disabled)'
              }
            }}
          >
            {invoiceLoading ? (
              <CircularProgress size={24} sx={{ color: 'var(--text-primary)' }} />
            ) : (
              'Create Invoice'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={completeDialogOpen}
        onClose={() => !isCompleting && setCompleteDialogOpen(false)}
      >
        <DialogTitle>
          {showSuccess ? 'Project Completed!' : 'Complete Project'}
        </DialogTitle>
        <DialogContent>
          {showSuccess ? (
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                py: 3
              }}
            >
              <Zoom in={showSuccess}>
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    backgroundColor: 'success.main',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2
                  }}
                >
                  <CheckIcon sx={{ color: 'white', fontSize: 40 }} />
                </Box>
              </Zoom>
              <Typography variant="h6" color="success.main" sx={{ mt: 2 }}>
                Project Successfully Completed!
              </Typography>
            </Box>
          ) : (
            <>
              <Typography>
                Are you sure you want to mark this project as completed? This will:
              </Typography>
              <Box sx={{ mt: 2, ml: 2 }}>
                <Typography component="li" color="text.secondary">
                  • Change the project status to "Completed"
                </Typography>
                <Typography component="li" color="text.secondary">
                  • Move the project to completed projects list
                </Typography>
                <Typography component="li" color="text.secondary">
                  • Archive all associated events
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!showSuccess && (
            <>
              <Button 
                onClick={() => setCompleteDialogOpen(false)}
                color="inherit"
                disabled={isCompleting}
              >
                Cancel
              </Button>
              <Button 
                onClick={handleConfirmComplete}
                variant="contained"
                color="success"
                startIcon={isCompleting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckCircleIcon />
                )}
                disabled={isCompleting}
              >
                {isCompleting ? 'Completing...' : 'Complete Project'}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={deleteDialogOpen}
        onClose={() => !isDeleting && setDeleteDialogOpen(false)}
      >
        <DialogTitle sx={{ color: 'error.main' }}>
          Delete Project
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this project? This action cannot be undone and will:
          </Typography>
          <Box sx={{ mt: 2, ml: 2 }}>
            <Typography component="li" color="text.secondary">
              • Permanently delete all project data
            </Typography>
            <Typography component="li" color="text.secondary">
              • Remove all associated events and schedules
            </Typography>
            <Typography component="li" color="text.secondary">
              • Delete all project photos and files
            </Typography>
            <Typography component="li" color="text.secondary">
              • Remove all employee assignments
            </Typography>
          </Box>
          <Typography sx={{ mt: 2, color: 'error.main', fontWeight: 'bold' }}>
            This action is irreversible!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            color="inherit"
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            startIcon={isDeleting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <DeleteIcon />
            )}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete Project'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectActionButton; 
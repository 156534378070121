import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Paper, CircularProgress, Button, Avatar, Tooltip, Box, Tabs, Tab } from '@mui/material';
import { 
  Calendar as EventIcon,
  Clock as AccessTimeIcon,
  MapPin as LocationOnIcon,
  Users as PeopleIcon,
  CaretLeft as ArrowBackIcon,
  Images as PhotoLibraryIcon,
  ImageSquare as AddPhotoAlternateIcon,
  ClockClockwise as EventNoteIcon,
  Hourglass as HourglassEmptyIcon,
  CurrencyDollar as AttachMoneyIcon,
  CalendarCheck as CalendarTodayIcon,
  CheckCircle as CheckCircleOutlineIcon,
  NotePencil as NotesIcon,
  Timer,
  CaretDown
} from "@phosphor-icons/react";
import { getEmployeeEventDetails, getProjectPhotos, uploadProjectPhoto, getEmployeeTimesheets, clockInToEvent, clockOutFromEvent } from '../services/api';
import styles from './EmployeeEventDetails.module.scss';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CSSTransition } from 'react-transition-group';
import { formatDateTime } from '../utils/dateUtils';
import ProjectPhotos from './ProjectPhotos';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';

const avatarColors = ['#1976d2', '#388e3c', '#d32f2f', '#7b1fa2', '#c2185b', '#0288d1', '#00796b', '#fbc02d', '#f57c00', '#455a64'];

const formatTime = (seconds) => {
  if (isNaN(seconds) || seconds < 0) return '00:00:00';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

const EmployeeEventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [projectPhotos, setProjectPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [fadeIn, setFadeIn] = useState(true);
  const [projectPhotosLoading, setProjectPhotosLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [clockedIn, setClockedIn] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [clockInTime, setClockInTime] = useState(null);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (eventId) {
      const fetchEventDetails = async () => {
        try {
          setLoading(true);
          const [eventData, timesheetsData] = await Promise.all([
            getEmployeeEventDetails(eventId),
            getEmployeeTimesheets()
          ]);
          console.log('Event data:', eventData);
          console.log('Timesheets data:', timesheetsData);
          setEvent(eventData);
          if (eventData.project && eventData.project.id) {
            const photos = await getProjectPhotos(eventData.project.id);
            console.log('Project photos:', photos);
            setProjectPhotos(photos);
          }
          // Filter timesheets for the specific event
          const filteredTimesheets = timesheetsData.timesheets.filter(
            timesheet => timesheet.event.id === parseInt(eventId)
          );
          console.log('Filtered timesheets:', filteredTimesheets);
          setTimesheets(filteredTimesheets);
          setError(null);
        } catch (err) {
          console.error('Error fetching event details:', err);
          setError('Failed to fetch event details. Please try again later.');
        } finally {
          setLoading(false);
        }
      };

      fetchEventDetails();
    }
  }, [eventId]); // Only re-run the effect if eventId changes

  useEffect(() => {
    const storedClockInTime = localStorage.getItem('clockInTime');
    const storedEventId = localStorage.getItem('clockedInEventId');
    
    if (storedClockInTime && storedEventId) {
      const clockInTimeValue = parseInt(storedClockInTime, 10);
      if (!isNaN(clockInTimeValue)) {
        setClockInTime(clockInTimeValue);
        setClockedIn(true);
        setCurrentEventId(storedEventId);
      }
    }
  }, []);

  useEffect(() => {
    console.log('Timer effect triggered. Clocked in:', clockedIn, 'Clock-in time:', clockInTime);
    let intervalId;
    if (clockedIn && clockInTime) {
      console.log('Starting timer with clock-in time:', new Date(clockInTime).toISOString());
      intervalId = setInterval(() => {
        const now = Date.now();
        const elapsed = Math.floor((now - clockInTime) / 1000);
        console.log('Elapsed time:', elapsed, 'seconds');
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      console.log('Timer not started or stopped.');
    }
    return () => {
      if (intervalId) {
        console.log('Clearing timer interval');
        clearInterval(intervalId);
      }
    };
  }, [clockedIn, clockInTime]);

  const handleTabChange = (event, newValue) => {
    setFadeIn(false);
    setTimeout(() => {
      setTabValue(newValue);
      setFadeIn(true);
      if (isMobile) {
        setDrawerOpen(false);
      }
    }, 300);
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !event || !event.project) return;

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('photo', file);
      
      await uploadProjectPhoto(event.project.id, formData);
      
      // Refresh project photos
      const updatedPhotos = await getProjectPhotos(event.project.id);
      setProjectPhotos(updatedPhotos);
    } catch (error) {
      console.error('Error uploading photo:', error);
      // You might want to show an error message to the user here
    } finally {
      setUploading(false);
    }
  };

  const handleClockIn = async () => {
    try {
      const response = await clockInToEvent(eventId);
      console.log('Clock in response:', response);
      
      const currentTime = Date.now();
      localStorage.setItem('clockInTime', currentTime.toString());
      localStorage.setItem('clockedInEventId', eventId);

      setClockInTime(currentTime);
      setClockedIn(true);
      setCurrentEventId(eventId);
      setElapsedTime(0);
    } catch (err) {
      console.error('Error clocking in:', err);
    }
  };

  const handleClockOut = async () => {
    try {
      const response = await clockOutFromEvent();
      console.log('Clock out response:', response);

      localStorage.removeItem('clockInTime');
      localStorage.removeItem('clockedInEventId');
      
      setClockInTime(null);
      setClockedIn(false);
      setCurrentEventId(null);
      setElapsedTime(0);
    } catch (err) {
      console.error('Error clocking out:', err);
    }
  };

  const tabItems = [
    { icon: <EventIcon size={20} weight="light" />, label: 'Details' },
    { icon: <PhotoLibraryIcon size={20} weight="light" />, label: 'Photos' },
    { icon: <AccessTimeIcon size={20} weight="light" />, label: 'Time' },
    { icon: <NotesIcon size={20} weight="light" />, label: 'Internal Notes' }
  ];

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>{error}</Typography>
      </div>
    );
  }

  if (!event) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>Event not found.</Typography>
      </div>
    );
  }

  return (
    <div className={styles.eventDetailsContainer}>
      <div className={styles.headerSection}>
        <Button
          startIcon={<ArrowBackIcon size={20} weight="light" />}
          onClick={() => navigate(-1)}
          className={styles.backButton}
        >
          Back
        </Button>
        
        <div className={styles.actionButtons}>
          {event.addresses && event.addresses.length > 0 && (
            <Button
              variant="outlined"
              startIcon={<LocationOnIcon size={20} weight="light" />}
              onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.addresses[0])}`, '_blank')}
              className={styles.navigateButton}
            >
              Navigate
            </Button>
          )}
          
          {clockedIn && currentEventId === eventId ? (
            <Button
              variant="contained"
              startIcon={<AccessTimeIcon size={20} weight="light" />}
              onClick={handleClockOut}
              className={`${styles.clockOutButton} ${styles.button}`}
            >
              Clock Out
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<AccessTimeIcon size={20} weight="light" />}
              onClick={handleClockIn}
              className={`${styles.clockInButton} ${styles.button}`}
            >
              Clock In
            </Button>
          )}
        </div>
      </div>

      <div className={styles.titleSection}>
        <EventIcon size={24} weight="light" className={styles.titleIcon} />
        <Typography variant="h4" className={styles.eventTitle}>
          {event.title}
        </Typography>
      </div>

      {clockedIn && currentEventId === eventId && (
        <div className={styles.activeTimerContainer}>
          <h5>Active Timer</h5>
          <div className={styles.activeTimerContent}>
            <Timer size={24} weight="light" />
            <h4>{formatTime(elapsedTime)}</h4>
          </div>
        </div>
      )}
      
      {isMobile ? (
        <>
          <Button
            className={styles.currentTabButton}
            onClick={() => setDrawerOpen(true)}
            endIcon={<CaretDown size={16} weight="bold" />}
          >
            {tabItems[tabValue].label}
          </Button>

          <SwipeableDrawer
            anchor="bottom"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            onOpen={() => setDrawerOpen(true)}
            disableSwipeToOpen={false}
            className={styles.tabDrawer}
          >
            <div className={styles.drawerContent}>
              <div className={styles.drawerHandle} />
              {tabItems.map((tab, index) => (
                <button
                  key={index}
                  className={`${styles.drawerTab} ${index === tabValue ? styles.active : ''}`}
                  onClick={() => handleTabChange(null, index)}
                >
                  {tab.icon}
                  <span>{tab.label}</span>
                </button>
              ))}
            </div>
          </SwipeableDrawer>
        </>
      ) : (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            TabIndicatorProps={{
              children: <span className="MuiTabs-indicatorSpan" />,
            }}
            sx={{
              px: 2,
              '& .MuiTabs-indicator': {
                backgroundColor: '#7635dc',
                height: '2px',
                borderRadius: '3px 3px 0 0',
                transition: 'all 0.2s ease'
              },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '0.95rem',
                fontFamily: 'Urbanist',
                fontWeight: 500,
                color: 'var(--text-secondary)',
                minHeight: 48,
                padding: '12px 24px',
                gap: '8px',
                position: 'relative',
                '& .MuiTouchRipple-root': {
                  display: 'none'
                },
                '& svg': {
                  marginBottom: '0 !important',
                  marginRight: '0 !important'
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  width: 0,
                  height: '2px',
                  borderRadius: '3px 3px 0 0',
                  backgroundColor: '#7635dc',
                  opacity: 0.6,
                  transition: 'all 0.2s ease',
                  transform: 'translateX(-50%)',
                },
                '&:hover::after': {
                  width: '80%',
                },
                '&.Mui-selected': {
                  color: 'var(--primary-main)',
                  fontWeight: 600,
                  '&::after': {
                    opacity: 0,
                  },
                },
                '&:hover': {
                  color: 'var(--primary-main)',
                }
              },
            }}
          >
            {tabItems.map((tab, index) => (
              <Tab 
                key={index}
                icon={tab.icon}
                iconPosition="start"
                label={tab.label} 
                disableRipple
              />
            ))}
          </Tabs>
        </Box>
      )}

      <Box sx={{ 
        flex: 1,
        overflowY: 'auto',
        backgroundColor: 'var(--background-default)',
      }}>
        {[
          // Details tab
          <div className={styles.detailsContent}>
            <div className={styles.detailItem}>
              <AccessTimeIcon size={20} weight="light" className={`${styles.detailIcon} ${styles.timeIcon}`} />
              <Typography>
                {event.time_slots && event.time_slots.length > 0
                  ? `${moment(event.time_slots[0].start_time).format('MMMM D, YYYY h:mm A')} - ${moment(event.time_slots[0].end_time).format('h:mm A')}`
                  : 'Time not specified'}
              </Typography>
            </div>
            {event.addresses && event.addresses.length > 0 && (
              <div className={styles.detailItem}>
                <LocationOnIcon size={20} weight="light" className={`${styles.detailIcon} ${styles.locationIcon}`} />
                <div className={styles.addressContainer}>
                  {event.addresses.map((address, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Typography className={styles.addressText}>{address}</Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {event.employees && event.employees.length > 0 && (
              <div className={styles.detailItem}>
                <PeopleIcon size={20} weight="light" className={`${styles.detailIcon} ${styles.peopleIcon}`} />
                <div className={styles.employeesContainer}>
                  <Typography variant="subtitle1">Assigned Employees:</Typography>
                  <div className={styles.avatarGroup}>
                    {event.employees.map((employee, index) => (
                      <Tooltip key={employee.id} title={employee.name} arrow>
                        <Avatar 
                          className={styles.employeeAvatar}
                          style={{ 
                            backgroundColor: avatarColors[index % avatarColors.length]
                          }}
                        >
                          {employee.name.charAt(0)}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {event.description && (
              <div className={styles.descriptionContainer}>
                <Typography variant="h6">Description:</Typography>
                <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                  {event.description}
                </Typography>
              </div>
            )}
          </div>,
          // Photos tab
          <div className={styles.photosContent}>
            <ProjectPhotos 
              projectId={event.project.id} 
              onLoadComplete={() => {
                setProjectPhotosLoading(false);
                setFadeIn(true);
              }}
            />
          </div>,
          // Time tab
          <div className={styles.timeContent}>
            <div className={styles.timesheetsContainer} style={{ padding: '16px' }}>
              <Typography variant="h6">Timesheets for this Event</Typography>
              {timesheets.length > 0 ? (
                <div className={styles.tableWrapper}>
                  <TableContainer component={Paper} className={`${styles.tableWrapper} ${styles.tableContainer}`}>
                    <Table className={styles.table}>
                      <TableHead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                        <TableRow>
                          {[
                            { icon: <AccessTimeIcon />, label: 'Clock In', color: '#3498db' },
                            { icon: <EventNoteIcon />, label: 'Clock Out', color: '#e74c3c' },
                            { icon: <AttachMoneyIcon />, label: 'Payment Type', color: '#2ecc71' },
                            { icon: <CalendarTodayIcon />, label: 'Rate/Percentage', color: '#1abc9c' },
                            { icon: <HourglassEmptyIcon />, label: 'Hours Worked', color: '#f39c12' },
                            { icon: <CheckCircleOutlineIcon />, label: 'Calculated Payment', color: '#27ae60' },
                          ].map((cell, index) => (
                            <TableCell key={index} align="center" className={styles.tableCell}>
                              <div className={styles.headerContent}>
                                <div className={styles.headerIcon} style={{ backgroundColor: cell.color }}>
                                  {React.cloneElement(cell.icon, { style: { color: 'white' } })}
                                </div>
                                <span className={styles.headerLabel}>{cell.label}</span>
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timesheets.map((timesheet) => (
                          <TableRow key={timesheet.id}>
                            <TableCell className={styles.tableCell} align="center">{formatDateTime(timesheet.clock_in)}</TableCell>
                            <TableCell className={styles.tableCell} align="center">
                              {timesheet.clock_out ? formatDateTime(timesheet.clock_out) : 'Not clocked out'}
                            </TableCell>
                            <TableCell className={styles.tableCell} align="center">{timesheet.payment_type}</TableCell>
                            <TableCell className={styles.tableCell} align="center">
                              {timesheet.payment_type === 'fixed' 
                                ? `$${timesheet.rate || 0}`
                                : timesheet.percentage 
                                  ? `${timesheet.percentage}%` 
                                  : `$${timesheet.rate || 0}/hr`}
                            </TableCell>
                            <TableCell className={styles.tableCell} align="center">{timesheet.hours_worked.toFixed(2)}</TableCell>
                            <TableCell className={styles.tableCell} align="center">
                              ${timesheet.payment_type === 'fixed' 
                                ? (timesheet.rate || 0).toFixed(2)
                                : (timesheet.calculated_payment || 0).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className={styles.totalRow}>
                          <TableCell className={styles.tableCell} colSpan={4}></TableCell>
                          <TableCell className={styles.tableCell}>
                            <div className={styles.totalCell}>
                              <div className={styles.totalIconWrapper} style={{ backgroundColor: '#f39c12' }}>
                                <HourglassEmptyIcon size={20} weight="light" className={styles.totalIcon} />
                              </div>
                              <strong className={styles.totalText}>{timesheets.reduce((sum, t) => sum + t.hours_worked, 0).toFixed(2)}</strong>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div className={styles.totalCell}>
                              <div className={styles.totalIconWrapper} style={{ backgroundColor: '#27ae60' }}>
                                <AttachMoneyIcon size={20} weight="light" className={styles.totalIcon} />
                              </div>
                              <strong className={styles.totalText}>${timesheets.reduce((sum, t) => sum + t.calculated_payment, 0).toFixed(2)}</strong>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <Typography>No timesheets available for this event.</Typography>
              )}
            </div>
          </div>,
          // Notes tab
          <div className={styles.notesContent}>
            <Typography variant="h6" gutterBottom>
              Internal Notes
            </Typography>
            {event.project && event.project.scope_of_work ? (
              <Paper 
                elevation={0}
                sx={{ 
                  backgroundColor: '#f5f5f5',
                  padding: '16px',
                  borderRadius: '8px',
                  whiteSpace: 'pre-wrap'
                }}
              >
                <Typography>
                  {event.project.scope_of_work}
                </Typography>
              </Paper>
            ) : (
              <Typography color="textSecondary">
                No internal notes available.
              </Typography>
            )}
          </div>
        ].map((content, index) => (
          <Box
            key={index}
            sx={{
              display: tabValue === index ? 'block' : 'none',
              height: '100%',
              p: 3,
              animation: 'fadeIn 0.3s ease-out',
              opacity: fadeIn ? 1 : 0,
              transition: 'opacity 0.3s ease-out',
              '@keyframes fadeIn': {
                from: {
                  opacity: 0,
                  transform: 'translateY(10px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateY(0)',
                },
              },
            }}
          >
            {content}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default EmployeeEventDetails;

import React from 'react';
import { Box } from '@mui/material';
import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react";

const SearchContainer = ({ 
  value, 
  onChange, 
  placeholder = "Search...",
  width = "320px",
  onFocus,
  onBlur 
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'var(--background-paper)',
        border: '1px solid var(--divider)',
        borderRadius: '8px',
        padding: '8px 16px',
        width: '80%',
        maxWidth: width,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderColor: '#94a3b8'
        },
        '&:focus-within': {
          backgroundColor: '#fff',
          borderColor: '#4a5df9',
          boxShadow: '0 0 0 2px rgba(118, 53, 220, 0.1)'
        }
      }}
    >
      <MagnifyingGlassIcon 
        size={20} 
        weight="light" 
        style={{ 
          color: 'var(--text-secondary)',
          marginRight: '12px'
        }} 
      />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        style={{
          border: 'none',
          outline: 'none',
          width: '100%',
          background: 'transparent',
          fontSize: '0.875rem',
          fontFamily: 'Urbanist, sans-serif',
          color: 'var(--text-primary)',
          '&::placeholder': {
            color: 'var(--text-secondary)',
            opacity: 0.7
          }
        }}
      />
    </Box>
  );
};

export default SearchContainer; 
import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Avatar, Chip, Tabs, Tab, Divider, Modal, Radio, RadioGroup, FormControlLabel, FormControl, TextField, Button, Checkbox } from '@mui/material';
import { getEmployees } from '../services/api';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import dayjs from 'dayjs';
import CustomToggle from './common/CustomToggle';
import BaseInput from './shared/BaseInput';

const primaryColor = '#4a5df9';
const primaryLightColor = '#6b7cfa';

const formatDateTime = (date) => {
  if (!date) return 'Not scheduled';
  return dayjs(date).format('MMM D, YYYY h:mm A');
};

const formatRate = (rate) => {
  if (!rate || rate === 'undefined') return '';
  return `$${rate}`;
};

const RateModal = ({ open, onClose, employee, onConfirm, items }) => {
  const [rateType, setRateType] = useState('default');
  const [defaultRate, setDefaultRate] = useState(employee?.hourly_rate?.toString() || '');
  const [customRate, setCustomRate] = useState('');
  const [percentage, setPercentage] = useState('');
  const [selectedEvents, setSelectedEvents] = useState({});

  const rateOptions = [
    { value: 'default', label: 'Default Rate' },
    { value: 'fixed', label: 'Fixed Rate' },
    { value: 'percentage', label: 'Percentage' }
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="rate-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 460,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
      }}>
        <Box sx={{ p: 3, borderBottom: '1px solid rgba(0,0,0,0.08)' }}>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Assign {employee?.name}
          </Typography>
        </Box>

        <Box sx={{ p: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 500 }}>
            Rate Type
          </Typography>
          
          <Box sx={{ mb: 3 }}>
            <CustomToggle
              options={rateOptions}
              value={rateType}
              onChange={setRateType}
            />
          </Box>

          {rateType === 'default' && (
            <BaseInput
              label="Default Rate"
              type="number"
              name="defaultRate"
              value={defaultRate}
              onChange={(e) => setDefaultRate(e.target.value)}
            />
          )}

          {rateType === 'fixed' && (
            <BaseInput
              label="Fixed Rate"
              type="number"
              name="fixedRate"
              value={customRate}
              onChange={(e) => setCustomRate(e.target.value)}
            />
          )}

          {rateType === 'percentage' && (
            <BaseInput
              label="Percentage"
              type="number"
              name="percentage"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
            />
          )}

          <Typography variant="subtitle2" sx={{ mb: 2, mt: 3, fontWeight: 500 }}>
            Select Events
          </Typography>

          <Box sx={{ 
            mb: 2,
            maxHeight: 240,
            overflow: 'auto',
            border: '1px solid rgba(0,0,0,0.08)',
            borderRadius: 1,
          }}>
            {items.map((item, index) => (
              <Box 
                key={item.id}
                sx={{ 
                  p: 2,
                  borderBottom: index < items.length - 1 ? '1px solid rgba(0,0,0,0.08)' : 'none',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.02)' }
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedEvents[item.id] || false}
                      onChange={(e) => setSelectedEvents(prev => ({
                        ...prev,
                        [item.id]: e.target.checked
                      }))}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="body2">{item.title}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {formatDateTime(item.start_time)}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ 
          p: 2, 
          borderTop: '1px solid rgba(0,0,0,0.08)',
          display: 'flex', 
          justifyContent: 'flex-end', 
          gap: 1,
          bgcolor: 'rgba(0,0,0,0.02)'
        }}>
          <Button 
            onClick={onClose}
            variant="outlined"
            sx={{ minWidth: 100 }}
          >
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={() => {
              const rateData = {
                type: rateType,
                value: rateType === 'fixed' ? customRate : 
                       rateType === 'percentage' ? percentage : 
                       defaultRate
              };
              onConfirm(rateData, Object.keys(selectedEvents).filter(id => selectedEvents[id]));
            }}
            disabled={!Object.values(selectedEvents).some(Boolean)}
            sx={{
              minWidth: 100,
              bgcolor: primaryColor,
              '&:hover': {
                bgcolor: primaryLightColor
              }
            }}
          >
            Assign
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const ProjectEmployeeSelector = ({ selectedEmployees, onEmployeeSelect, project, eventTab }) => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [itemEmployees, setItemEmployees] = useState({});
  const [employeeRates, setEmployeeRates] = useState({});

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const data = await getEmployees();
        setEmployees(Array.isArray(data.employees) ? data.employees : []);
        console.log('Fetched employees:', data.employees);
      } catch (error) {
        console.error('Error fetching employees:', error);
        setEmployees([]);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleEmployeeClick = (employee, itemId) => {
    if (isEmployeeSelected(employee, itemId)) {
      // Remove employee
      setItemEmployees(prev => ({
        ...prev,
        [itemId]: (prev[itemId] || []).filter(e => e.id !== employee.id)
      }));
      setEmployeeRates(prev => {
        const newRates = { ...prev };
        delete newRates[`${itemId}-${employee.id}`];
        return newRates;
      });
    } else {
      // Add employee
      setSelectedEmployee(employee);
      setModalOpen(true);
    }
  };

  const handleRateConfirm = (rateData, selectedEventIds) => {
    selectedEventIds.forEach(itemId => {
      setItemEmployees(prev => ({
        ...prev,
        [itemId]: [...(prev[itemId] || []), selectedEmployee]
      }));
      
      setEmployeeRates(prev => ({
        ...prev,
        [`${itemId}-${selectedEmployee.id}`]: rateData
      }));
    });
    
    setModalOpen(false);
    setSelectedEmployee(null);
  };

  const isEmployeeSelected = (employee, itemId) => {
    return (itemEmployees[itemId] || []).some(e => e.id === employee.id);
  };

  const items = project.items?.filter(item => item.type !== 'inventory') || [];

  return (
    <Box sx={{ 
      mt: 2,
      width: '80%',
      margin: '0 auto'
    }}>
      <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 500 }}>
        Click on an employee to assign them to an event
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>          
          {employees.map((emp) => (
            <Box
              key={emp.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1.5,
                border: '1px solid var(--divider)',
                borderRadius: 1,
                cursor: 'pointer',
                '&:hover': {
                  borderColor: '#4a5df9',
                }
              }}
              onClick={() => {
                setSelectedEmployee(emp);
                setModalOpen(true);
              }}
            >
              <Typography>{emp.name}</Typography>
              <Typography sx={{ ml: 'auto', color: 'text.secondary' }}>
                {formatRate(emp.hourly_rate)}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} md={6}>
          {items.map(item => (
            <Box 
              key={item.id}
              sx={{ 
                p: 2, 
                mb: 2,
                border: '1px solid var(--divider)',
                borderRadius: 1,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'right' }}>
                  {formatDateTime(item.start_time)} - {formatDateTime(item.end_time)}
                </Typography>
              </Box>
              
              {(itemEmployees[item.id] || []).map(emp => (
                <Box
                  key={emp.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '30%',
                    p: 1,
                    mb: 0.5
                  }}
                >
                  <Typography>{emp.name}</Typography>
                  <Typography sx={{ ml: 'auto', mr: 1, color: 'text.secondary' }}>
                    {employeeRates[`${item.id}-${emp.id}`]?.type === 'default' ? 
                      formatRate(employeeRates[`${item.id}-${emp.id}`].value || emp.hourly_rate) :
                      employeeRates[`${item.id}-${emp.id}`]?.type === 'fixed' ?
                      formatRate(employeeRates[`${item.id}-${emp.id}`].value) :
                      `${employeeRates[`${item.id}-${emp.id}`]?.value}%`
                    }
                  </Typography>
                  <Button 
                    size="small" 
                    onClick={() => handleEmployeeClick(emp, item.id)}
                    sx={{ minWidth: 'auto', p: 0.5 }}
                  >
                    ✕
                  </Button>
                </Box>
              ))}
            </Box>
          ))}
        </Grid>
      </Grid>
      
      <RateModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        employee={selectedEmployee}
        onConfirm={handleRateConfirm}
        items={items}
      />
    </Box>
  );
};

export default ProjectEmployeeSelector; 
import React from 'react';
import { Outlet } from 'react-router-dom';
import EmployeeNavbar from './EmployeeNavbar';
import styles from './EmployeeLayout.module.scss';
import classNames from 'classnames';

const EmployeeLayout = () => {
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  return (
    <div className={classNames(styles.layout, {
      [styles.navOpen]: isNavOpen
    })}>
      <div className={styles.navbarWrapper}>
        <EmployeeNavbar onNavToggle={setIsNavOpen} />
      </div>
      <main className={styles.content}>
        <div className={styles.contentInner} style={{ padding: '16px' }}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default EmployeeLayout;

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  List, 
  ListItem, 
  IconButton, 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText
} from '@mui/material';
import { Plus as AddIcon, PencilSimple as Edit, Trash as Delete, MagnifyingGlass as SearchIcon } from "@phosphor-icons/react";
import { createItem, getItems, deleteItem } from '../../services/api';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from '../Projects.module.scss';
import { useTheme } from '../../ThemeContext';

const SavedItemsManagerSettings = () => {
  const { darkMode } = useTheme();
  const [items, setItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState({ title: '', description: '', unitPrice: '', quantity: '1' });
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchHovered, setIsSearchHovered] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [showHowToModal, setShowHowToModal] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [showHelpPrompt, setShowHelpPrompt] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHelpPrompt(true);
    }, 45000); // 45 seconds

    return () => clearTimeout(timer);
  }, []);

  const fetchItems = async () => {
    try {
      const fetchedItems = await getItems();
      setItems(fetchedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleSave = async () => {
    try {
      // Validate the data
      if (!currentItem.title || !currentItem.unitPrice) {
        alert('Title and price are required');
        return;
      }

      // Create the item object
      const itemData = {
        title: currentItem.title,
        description: currentItem.description || '',
        unitPrice: Number(currentItem.unitPrice) || 0,
        quantity: Number(currentItem.quantity) || 1
      };

      console.log('Sending to API:', itemData);

      const savedItem = await createItem(itemData);
      console.log('Response from API:', savedItem);

      if (savedItem) {
        setOpenDialog(false);
        setCurrentItem({ title: '', description: '', unitPrice: '', quantity: '1' });
        fetchItems();
        setShowSuccessModal(true);
        setTimeout(() => setShowSuccessModal(false), 2000);
      }
    } catch (error) {
      console.error('Error saving item:', error);
      alert('Failed to save item: ' + error.message);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      await deleteItem(itemId);
      const updatedItems = await getItems();
      setItems(updatedItems);
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('Failed to delete item');
    }
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setEditingItemId(item.id);
    setCurrentItem({
      title: item.title,
      description: item.description || '',
      unitPrice: item.unitPrice?.toString() || '',
      quantity: item.quantity?.toString() || '1'
    });
    setOpenDialog(true);
  };

  const filteredItems = items.filter(item =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const inputStyles = {
    '& .MuiInputLabel-root': {
      color: darkMode ? 'var(--text-secondary)' : 'inherit'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: darkMode ? 'rgba(30, 41, 47, 0.5)' : 'inherit',
      color: darkMode ? 'var(--text-primary)' : 'inherit',
      '& fieldset': {
        borderColor: darkMode ? 'rgba(145, 158, 171, 0.32)' : 'inherit'
      },
      '&:hover fieldset': {
        borderColor: darkMode ? 'var(--text-secondary)' : 'inherit'
      },
      '&.Mui-focused fieldset': {
        borderColor: darkMode ? '#90caf9' : '#1976d2'
      }
    }
  };

  return (
    <Box sx={{ 
      p: 3,
      backgroundColor: darkMode ? '#232B2F' : '#fff',  // Updated to exact color from screenshot
      minHeight: '100vh'
    }}>
      {/* Updated banner with conditional rendering */}
      {showBanner && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            p: 2,
            mb: 3,
            backgroundColor: darkMode ? 'rgba(30, 41, 47, 0.5)' : '#E3F2FD',
            borderRadius: '8px',
            border: `1px solid ${darkMode ? 'rgba(145, 158, 171, 0.32)' : '#90CAF9'}`,
            position: 'relative',
            flexDirection: { xs: 'column', sm: 'row' },
            textAlign: { xs: 'center', sm: 'left' },
            px: { xs: 4, sm: 2 },
          }}
        >
          <Typography sx={{ 
            color: darkMode ? '#90caf9' : '#1976D2',
            fontSize: { 
              xs: '13px',     // Mobile phones
              sm: '14px',     // Tablets
              md: '16px'      // Desktop
            },
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: { xs: 2, sm: 0 },
            textAlign: 'center',
            lineHeight: 1.4,
            position: 'relative',
          }}>
            🎉 Did you know your Saved Items / Services can be used on your estimates and invoices! ✨
            {showHelpPrompt && (
              <Box
                onClick={() => setShowHowToModal(true)}
                sx={{
                  position: 'absolute',
                  bottom: -30,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: '#1976D2',
                  color: 'white',
                  padding: '4px 12px',
                  borderRadius: '12px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  animation: 'fadeIn 0.3s ease-in',
                  '&:hover': {
                    backgroundColor: '#1565c0',
                  },
                  '@keyframes fadeIn': {
                    from: { opacity: 0, bottom: -25 },
                    to: { opacity: 1, bottom: -30 }
                  }
                }}
              >
                Click for help ↑
              </Box>
            )}
          </Typography>
          
          <IconButton
            onClick={() => setShowBanner(false)}
            sx={{
              position: 'absolute',
              right: { xs: 4, sm: 8 },
              top: { xs: 8, sm: '50%' },
              transform: { xs: 'none', sm: 'translateY(-50%)' },
              color: '#ef5350',
              p: 0.5,
              '&:hover': {
                backgroundColor: 'rgba(239, 83, 80, 0.04)'
              }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      {/* Add the How-To Modal */}
      <Dialog 
        open={showHowToModal} 
        onClose={() => setShowHowToModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          How to Use Saved Items
          <IconButton
            onClick={() => setShowHowToModal(false)}
            sx={{ color: '#ef5350' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography paragraph>
            Here's how to use your saved items in estimates and invoices:
          </Typography>
          <Box sx={{ 
            mt: 4,
            p: 3, 
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            textAlign: 'center'
          }}>
            <Typography sx={{ 
              fontSize: '24px',
              fontWeight: 600,
              color: '#666',
              textTransform: 'uppercase',
              letterSpacing: '1px'
            }}>
              Adding Videos In Next Update - Ian 
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowHowToModal(false)}
            variant="contained"
            sx={{ 
              borderRadius: '8px',
              textTransform: 'none'
            }}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>

      {/* Search and Add Button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        gap: 2
      }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'var(--background-paper)',
          borderRadius: '10px',
          border: '1px solid var(--divider)',
          padding: '8px 16px',
          flex: 1,
          maxWidth: '400px',
          transition: 'all 0.2s ease-in-out',
          '&:focus-within': {
            borderColor: 'var(--primary-main)',
            boxShadow: '0 0 0 2px rgba(var(--primary-rgb), 0.1)'
          }
        }}>
          <SearchIcon 
            size={20} 
            weight="light" 
            style={{ 
              color: 'var(--text-secondary)',
              marginRight: '12px'
            }} 
          />
          <input
            type="text"
            placeholder="Search items..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              color: 'var(--text-primary)',
              width: '100%',
              outline: 'none',
              fontSize: '0.875rem',
              fontFamily: 'inherit'
            }}
          />
        </Box>

        <Button
          variant="contained"
          startIcon={<AddIcon size={20} weight="light" />}
          onClick={() => {
            setIsEditing(false);
            setCurrentItem({});
            setOpenDialog(true);
          }}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            backgroundColor: '#4a5df9',
            fontFamily: 'Urbanist, sans-serif',
            fontWeight: 600,
            fontSize: '0.875rem',
            padding: '6px 16px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#3c50fa',
              boxShadow: 'none',
            }
          }}
        >
          Add Item
        </Button>
      </Box>

      {/* Header Section */}
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        mb: 3,
        px: 3,
        py: 2,
        borderBottom: darkMode ? '1px solid rgba(145, 158, 171, 0.32)' : '1px solid #e0e0e0',
        '& > div': {
          color: darkMode ? '#fff !important' : '#1a2027',  // Switch based on theme
          fontSize: '0.875rem',
          fontWeight: 600,
          textTransform: 'uppercase'
        }
      }}>
        <div style={{ flex: '1 1 50%' }}>Title/Description</div>
        <div style={{ width: '100px', textAlign: 'right' }}>Qty</div>
        <div style={{ width: '100px', textAlign: 'right' }}>Price</div>
        <div style={{ width: '100px', textAlign: 'right' }}>Total</div>
        <div style={{ width: '80px' }}></div>
      </Box>

      {/* Items List */}
      <List sx={{ mb: 3 }}>
        {filteredItems.map((item) => (
          <ListItem
            key={item.id}
            sx={{
              display: 'flex',
              gap: 2,
              p: 2,
              backgroundColor: 'var(--background-paper)',
              borderRadius: '10px',
              border: '1px solid var(--divider)',
              mb: 1.5,
              transition: 'all 0.2s ease-in-out',
              '&:hover': { 
                backgroundColor: 'var(--background-default)',
                transform: 'translateY(-1px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.05)'
              }
            }}
          >
            <Box sx={{ flex: '1 1 50%' }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: 'var(--text-primary)',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  mb: 0.5
                }}
              >
                {item.title}
              </Typography>
              {item.description && (
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.813rem',
                    lineHeight: 1.5
                  }}
                >
                  {item.description}
                </Typography>
              )}
            </Box>
            
            <Typography sx={{ 
              width: '100px', 
              textAlign: 'right',
              color: 'var(--text-primary)',
              fontSize: '0.875rem'
            }}>
              {item.quantity}
            </Typography>
            
            <Typography sx={{ 
              width: '100px', 
              textAlign: 'right',
              color: 'var(--text-primary)',
              fontSize: '0.875rem'
            }}>
              ${item.unitPrice?.toFixed(2)}
            </Typography>
            
            <Typography sx={{ 
              width: '100px', 
              textAlign: 'right',
              color: 'var(--text-primary)',
              fontWeight: 600,
              fontSize: '0.875rem'
            }}>
              ${(item.quantity * item.unitPrice).toFixed(2)}
            </Typography>
            
            <Box sx={{ width: '80px', display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <IconButton 
                onClick={() => handleEdit(item)}
                size="small"
                sx={{ 
                  color: '#4a5df9',
                  '&:hover': { backgroundColor: 'rgba(74, 93, 249, 0.08)' }
                }}
              >
                <Edit size={20} weight="light" />
              </IconButton>
              <IconButton 
                onClick={() => handleDelete(item.id)}
                size="small"
                sx={{ 
                  color: 'var(--error-main)',
                  '&:hover': { backgroundColor: 'rgba(var(--error-rgb), 0.08)' }
                }}
              >
                <Delete size={20} weight="light" />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>

      {/* Update the Dialog content to match ActiveItemsList style */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: darkMode ? 'var(--background-paper)' : '#fff',
            color: darkMode ? 'var(--text-primary)' : 'inherit'
          }
        }}
      >
        <DialogTitle>
          {isEditing ? 'Edit Item' : 'Add New Item'}
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box sx={{ 
            display: 'flex',
            gap: 2,
            alignItems: 'flex-start'
          }}>
            {/* Title/Description Column */}
            <Box sx={{ flex: '1 1 50%' }}>
              <Typography 
                sx={{ 
                  fontSize: '14px', 
                  fontWeight: 600, 
                  color: '#1a2027',
                  mb: 1,
                  textTransform: 'uppercase',
                  letterSpacing: '0.8px'
                }}
              >
                Title/Description
              </Typography>
              <Box sx={{ 
                border: '1px solid #E0E0E0',
                borderRadius: '4px',
                overflow: 'hidden'
              }}>
                <input
                  type="text"
                  placeholder="Name"
                  value={currentItem.title}
                  onChange={(e) => setCurrentItem({ ...currentItem, title: e.target.value })}
                  style={{
                    width: '100%',
                    padding: '8px 12px',
                    height: '40px',
                    fontSize: '14px',
                    border: 'none',
                    borderBottom: '1px solid #E0E0E0',
                  }}
                />
                <textarea
                  placeholder="Description"
                  value={currentItem.description}
                  onChange={(e) => setCurrentItem({ ...currentItem, description: e.target.value })}
                  style={{
                    width: '100%',
                    padding: '8px 12px',
                    fontSize: '14px',
                    border: 'none',
                    minHeight: '100px',
                    resize: 'vertical',
                    fontFamily: 'inherit'
                  }}
                />
              </Box>
            </Box>

            {/* Quantity/Price/Total Columns */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              {/* Quantity Column */}
              <Box sx={{ width: '80px' }}>
                <Typography 
                  sx={{ 
                    fontSize: '15px', 
                    fontWeight: 600, 
                    color: '#1a2027',
                    mb: 1,
                    textTransform: 'uppercase',
                    letterSpacing: '0.8px',
                    textAlign: 'center'
                  }}
                >
                  Qty
                </Typography>
                <input
                  type="number"
                  value={currentItem.quantity}
                  onChange={(e) => setCurrentItem({ ...currentItem, quantity: e.target.value })}
                  placeholder="1"
                  style={{
                    width: '60px',
                    margin: '0 auto',
                    display: 'block',
                    padding: '8px 6px',
                    height: '40px',
                    fontSize: '15px',
                    border: '1px solid #E0E0E0',
                    borderRadius: '4px',
                    textAlign: 'center'
                  }}
                />
              </Box>

              {/* Price Column */}
              <Box sx={{ width: '80px' }}>
                <Typography 
                  sx={{ 
                    fontSize: '15px', 
                    fontWeight: 600, 
                    color: '#1a2027',
                    mb: 1,
                    textTransform: 'uppercase',
                    letterSpacing: '0.8px',
                    textAlign: 'center'
                  }}
                >
                  Price
                </Typography>
                <Box sx={{ 
                  position: 'relative',
                  width: '60px',
                  margin: '0 auto'
                }}>
                  <input
                    type="number"
                    value={currentItem.unitPrice}
                    onChange={(e) => setCurrentItem({ ...currentItem, unitPrice: e.target.value })}
                    placeholder="0.00"
                    style={{
                      width: '100%',
                      padding: '8px 6px 8px 16px',
                      height: '40px',
                      fontSize: '15px',
                      border: '1px solid #E0E0E0',
                      borderRadius: '4px',
                      textAlign: 'right'
                    }}
                  />
                  <span style={{
                    position: 'absolute',
                    left: '6px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#666',
                    fontSize: '15px'
                  }}>$</span>
                </Box>
              </Box>

              {/* Total Column */}
              <Box sx={{ width: '80px' }}>
                <Typography 
                  sx={{ 
                    fontSize: '15px', 
                    fontWeight: 600, 
                    color: '#1a2027',
                    mb: 1,
                    textTransform: 'uppercase',
                    letterSpacing: '0.8px',
                    textAlign: 'center'
                  }}
                >
                  Total
                </Typography>
                <Box sx={{ 
                  width: '60px',
                  margin: '0 auto',
                  textAlign: 'right',
                  padding: '8px 6px',
                  height: '40px',
                  lineHeight: '24px',
                  color: '#666',
                  fontSize: '15px',
                  border: '1px solid #E0E0E0',
                  borderRadius: '4px',
                }}>
                  ${((currentItem.quantity || 0) * (currentItem.unitPrice || 0)).toFixed(2)}
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            variant="outlined"
            sx={{ 
              borderRadius: '8px',
              textTransform: 'none'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave}
            variant="contained"
            sx={{ 
              borderRadius: '8px',
              textTransform: 'none'
            }}
          >
            {isEditing ? 'Save Changes' : 'Add Item'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showSuccessModal} 
        onClose={() => setShowSuccessModal(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: darkMode ? 'var(--background-paper)' : '#fff'
          }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => setShowSuccessModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ 
            backgroundColor: '#4caf50', 
            color: 'white',
            textAlign: 'center',
            pb: 2
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 1
            }}>
              <CheckCircleOutlineIcon 
                sx={{ 
                  fontSize: '48px',
                  animation: 'checkmark 0.5s ease-in-out',
                  '@keyframes checkmark': {
                    '0%': {
                      transform: 'scale(0)',
                      opacity: 0
                    },
                    '50%': {
                      transform: 'scale(1.2)',
                    },
                    '100%': {
                      transform: 'scale(1)',
                      opacity: 1
                    }
                  }
                }} 
              />
              Success
            </Box>
          </DialogTitle>
          <DialogContent sx={{ 
            mt: 2, 
            textAlign: 'center',
            color: '#666'
          }}>
            Item has been saved successfully.
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
};

export default SavedItemsManagerSettings; 
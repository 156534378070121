import React from 'react';
import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';

const ScheduleItemHeader = ({ item, handleScheduleLaterChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="subtitle1" sx={{ 
            fontFamily: 'Urbanist',
            fontWeight: 500
          }}>
            {item.title}
          </Typography>
        </Box>
      </Box>

      <Box 
        onClick={(e) => e.stopPropagation()}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '4px'
          },
          p: 1
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(item.scheduleLater)}
              onChange={handleScheduleLaterChange}
              onClick={(e) => e.stopPropagation()}
            />
          }
          label="Schedule Later"
          labelPlacement="start"
          sx={{ 
            marginLeft: 0,
            '& .MuiFormControlLabel-label': {
              fontFamily: 'Urbanist',
              fontSize: '0.9rem',
              color: 'rgba(0, 0, 0, 0.6)'
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ScheduleItemHeader; 
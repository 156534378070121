import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, Box, Alert } from '@mui/material';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area } from 'recharts';
import { getFinancialMetrics } from '../services/api';
import CustomTooltip from './graphs/CustomTooltip';
import { styled } from '@mui/material/styles';
import CustomToggle from './common/CustomToggle';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

const slideAnimation = `
  @keyframes slideIn {
    0% {
      transform: translateX(-24px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(24px);
      opacity: 0;
    }
  }
`;

const metricColors = {
  estimates: {
    main: '#2563eb',
    light: 'rgba(37, 99, 235, 0.15)',
    lighter: 'rgba(37, 99, 235, 0.01)'
  },
  invoices: {
    main: '#16a34a',
    light: 'rgba(22, 163, 74, 0.15)',
    lighter: 'rgba(22, 163, 74, 0.01)'
  },
  projects: {
    main: '#9333ea',
    light: 'rgba(147, 51, 234, 0.15)',
    lighter: 'rgba(147, 51, 234, 0.01)'
  }
};

const ValueTypography = styled(Typography)`
  ${slideAnimation}
  animation: ${({ isExiting }) => isExiting ? 'slideOut' : 'slideIn'} 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
`;

const FinancialMetricsGraph = () => {
  const [financialData, setFinancialData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeMetric, setActiveMetric] = useState('estimates');
  const [isExiting, setIsExiting] = useState(false);
  const buttonRefs = useRef({});
  const containerRef = useRef(null);

  useEffect(() => {
    fetchFinancialData();
  }, []);

  useEffect(() => {
    const button = buttonRefs.current['estimates'];
    const container = containerRef.current?.querySelector('[role="group"]');
    
    if (button && container) {
      requestAnimationFrame(() => {
        const offset = button.offsetLeft;
        const width = button.offsetWidth;
        container.style.setProperty('--highlight-offset', `${offset}px`);
        container.style.setProperty('--highlight-width', `${width}px`);
      });
    }
  }, []);

  const handleMetricChange = useCallback((newMetric) => {
    if (!newMetric || newMetric === activeMetric) return;
    
    setActiveMetric(newMetric);
    setIsExiting(true);

    setTimeout(() => {
      setIsExiting(false);
    }, 300);
  }, [activeMetric]);

  const fetchFinancialData = async () => {
    try {
      const response = await getFinancialMetrics();
      const calculateCumulative = (data) => {
        if (!Array.isArray(data)) return [];
        
        let sum = 0;
        return data.map(item => {
          if ('total_value' in item) {
            const value = Number(item.total_value || 0);
            sum += value;
            return {
              date: item.start_time?.split('T')[0] || 
                    item.created_at?.split('T')[0] || 
                    new Date().toISOString().split('T')[0],
              value: sum
            };
          }
          
          if ('value' in item) {
            sum += Number(item.value || 0);
            return {
              date: item.date,
              value: sum
            };
          }
          
          const itemTotal = item.items?.reduce((total, lineItem) => {
            return total + (Number(lineItem.unitPrice || 0) * Number(lineItem.quantity || 1));
          }, 0) || 0;

          sum += itemTotal;
          return {
            date: item.issue_date?.split('T')[0] || item.date,
            value: sum
          };
        });
      };

      const transformedData = {
        estimates: calculateCumulative(response.estimates),
        invoices: calculateCumulative(response.invoices),
        projects: calculateCumulative(Array.isArray(response.projects) ? response.projects : [])
      };

      setFinancialData(transformedData);
      setError(null);
    } catch (error) {
      console.error('Error fetching financial data:', error);
      setError('Failed to load financial metrics');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return (
    <Box sx={{ p: 2 }}>
      <Typography>Loading financial metrics...</Typography>
    </Box>
  );
  
  if (error) return (
    <Box sx={{ p: 2 }}>
      <Alert severity="error">{error}</Alert>
    </Box>
  );

  const currentData = financialData?.[activeMetric] || [];
  const totalAmount = currentData.length > 0 ? currentData[currentData.length - 1]?.value || 0 : 0;
  const currentColor = metricColors[activeMetric];

  const metricOptions = [
    { value: 'estimates', label: 'Estimates' },
    { value: 'invoices', label: 'Invoices' },
    { value: 'projects', label: 'Projects' }
  ];

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'flex-start',
        mb: 3 
      }}>
        <Box>
          <Typography 
            variant="h6" 
            sx={{ 
              fontSize: '0.875rem',
              fontWeight: 500,
              color: 'text.secondary',
              mb: 0.5,
              letterSpacing: '0.02em',
              textTransform: 'uppercase'
            }}
          >
            {activeMetric.charAt(0).toUpperCase() + activeMetric.slice(1)} Overview
          </Typography>
          <ValueTypography 
            variant="h4" 
            key={totalAmount}
            isExiting={isExiting}
            sx={{ 
              fontSize: '2.5rem',
              fontWeight: 500,
              color: 'var(--text-primary)',
              letterSpacing: '-0.01em',
              mb: 0,
              lineHeight: 1.2
            }}
          >
            {formatCurrency(totalAmount)}
          </ValueTypography>
        </Box>
        
        <CustomToggle
          options={metricOptions}
          value={activeMetric}
          onChange={handleMetricChange}
        />
      </Box>
      
      <Box sx={{ height: 300, width: '100%' }}>
        <ResponsiveContainer>
          <ComposedChart data={currentData}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={currentColor.main} stopOpacity={0.15}/>
                <stop offset="95%" stopColor={currentColor.main} stopOpacity={0.01}/>
              </linearGradient>
            </defs>
            <CartesianGrid 
              strokeDasharray="3 3" 
              vertical={false} 
              stroke="var(--divider)"
              opacity={0.3}
            />
            <XAxis 
              dataKey="date" 
              hide={true}
            />
            <YAxis 
              tickFormatter={(value) => formatCurrency(value)}
              tickLine={false}
              axisLine={false}
              tick={{ 
                fill: 'var(--text-secondary)', 
                fontSize: 12,
                fontWeight: 500 
              }}
              tickMargin={12}
            />
            <Tooltip content={<CustomTooltip color={currentColor.main} />} />
            <Area
              type="monotone"
              dataKey="value"
              stroke={currentColor.main}
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorValue)"
            />
            <Line 
              type="monotone"
              dataKey="value"
              stroke={currentColor.main}
              strokeWidth={2}
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default FinancialMetricsGraph; 
import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Paper,
  Button,
  Grid,
  CircularProgress,
  Alert,
  Checkbox,
  FormControlLabel,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  getAllProjects, 
  getProjectEvents, 
  createProjectInvoice, 
  updateEventStatus,
  getBusinessDetails 
} from '../../services/api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventIcon from '@mui/icons-material/Event';

const formatStatus = (status) => {
  if (!status) return 'N/A';
  return status
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const CreateInvoiceFromProject = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedProjects, setExpandedProjects] = useState({});
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [projectEvents, setProjectEvents] = useState({});
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        console.log('Fetching all projects...');
        const response = await getAllProjects();
        console.log('Raw projects response:', response);
        
        if (Array.isArray(response)) {
          console.log('Setting projects array:', response);
          setProjects(response);
          
          // Initialize expanded state for all projects
          const expandedState = response.reduce((acc, project) => {
            acc[project.id] = false;
            return acc;
          }, {});
          setExpandedProjects(expandedState);
        } else {
          console.error('Unexpected projects response format:', response);
          setError('Invalid projects data format received');
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('Failed to load projects. Please try again.');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleProjectExpand = async (projectId) => {
    // Hide other projects when one is selected
    if (!expandedProjects[projectId]) {
      setProjects(prev => prev.filter(p => p.id === projectId));
    } else {
      // Show all projects again when collapsing
      const fetchAllProjects = async () => {
        try {
          const response = await getAllProjects();
          if (Array.isArray(response)) {
            setProjects(response);
          }
        } catch (error) {
          console.error('Error fetching all projects:', error);
        }
      };
      fetchAllProjects();
    }
    
    setExpandedProjects(prev => ({
      ...prev,
      [projectId]: !prev[projectId]
    }));

    // Fetch events if not already loaded and project is being expanded
    if (!projectEvents[projectId] && !expandedProjects[projectId]) {
      try {
        console.log('Fetching events for project:', projectId);
        const events = await getProjectEvents(projectId);
        console.log('Raw events response:', events);
        
        // Filter out already invoiced events
        const nonInvoicedEvents = events.filter(event => !event.invoice_id);
        
        setProjectEvents(prev => ({
          ...prev,
          [projectId]: nonInvoicedEvents
        }));
      } catch (error) {
        console.error('Error fetching project events:', error);
      }
    }
  };

  const handleEventSelection = (eventId) => {
    setSelectedEvents(prev => 
      prev.includes(eventId) 
        ? prev.filter(id => id !== eventId)
        : [...prev, eventId]
    );
  };

  const handleSelectAllProjectEvents = (projectId) => {
    const projectEventIds = projectEvents[projectId]?.map(event => event.id) || [];
    const allSelected = projectEventIds.every(id => selectedEvents.includes(id));

    if (allSelected) {
      setSelectedEvents(prev => prev.filter(id => !projectEventIds.includes(id)));
    } else {
      setSelectedEvents(prev => [...new Set([...prev, ...projectEventIds])]);
    }
  };

  const handleCreateInvoice = async () => {
    if (selectedEvents.length === 0) {
      setError('Please select at least one event');
      return;
    }

    try {
      setInvoiceLoading(true);
      
      // Get business details
      const businessDetails = await getBusinessDetails();
      console.log('Business details:', businessDetails);

      // Collect all selected events details
      const selectedEventDetails = Object.values(projectEvents)
        .flat()
        .filter(event => selectedEvents.includes(event.id));

      console.log('Selected events:', selectedEventDetails);

      const items = selectedEventDetails.map(event => ({
        event_id: event.id,
        title: event.title || 'Event',
        description: event.description || '',
        quantity: 1,
        unit_price: parseFloat(event.value || 0),
        total: parseFloat(event.value || 0)
      }));

      // Get the project of the first selected event
      const firstEvent = selectedEventDetails[0];
      const project = projects.find(p => p.id === firstEvent.project_id);

      // Get client details from the project
      const client = project.client;
      console.log('Client details:', client);

      const requestData = {
        business_details: businessDetails,
        items: items,
        event_ids: selectedEvents,
        project_name: project?.name,
        client_id: client?.id,
        customer_details: {
          name: client?.name || '',
          email: client?.email || '',
          phone: client?.phone || '',
          address: client?.address || '',
          city: client?.city || '',
          state: client?.state || '',
          zip_code: client?.zip_code || '',
          company: client?.company || ''
        }
      };

      console.log('Creating invoice with data:', requestData);
      const response = await createProjectInvoice(project.id, requestData);
      
      if (response.success) {
        // Update each event's status to 'invoiced'
        await Promise.all(selectedEvents.map(async (eventId) => {
          await updateEventStatus(eventId, 'invoiced');
        }));

        navigate(`/admin/invoices/${response.invoice.id}`);
      }
    } catch (error) {
      console.error('Error creating invoice:', error);
      setError('Failed to create invoice. Please try again.');
    } finally {
      setInvoiceLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
          Create Invoice from Project
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Select events from projects to create an invoice
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ p: 3 }}>
        <List>
          {projects.map((project) => (
            <Box key={project.id} sx={{ mb: 2 }}>
              <Paper 
                elevation={0}
                sx={{ 
                  border: '1px solid var(--border-color)',
                  borderRadius: 2,
                  overflow: 'hidden'
                }}
              >
                <ListItem 
                  button 
                  onClick={() => handleProjectExpand(project.id)}
                  sx={{ 
                    bgcolor: 'var(--background-paper)',
                    transition: 'all 0.2s ease',
                    p: 3,
                    '&:hover': {
                      bgcolor: 'var(--background-secondary)',
                      transform: 'translateY(-2px)',
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontWeight: 600,
                          color: 'var(--text-primary)',
                          mb: 1
                        }}
                      >
                        {project.name}
                      </Typography>
                    }
                    secondary={
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <BusinessIcon 
                              sx={{ 
                                mr: 1.5, 
                                color: 'blue',
                                fontSize: '2.2rem',
                                padding: '6px',
                                borderRadius: '50%',
                                border: '1px solid rgba(0, 0, 255, 0.5)',
                                backgroundColor: 'var(--background-secondary)'
                              }} 
                            />
                            <Typography variant="body1" color="text.secondary">
                              {project.client?.name || project.client?.company || 'No Client'}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LocationOnIcon 
                              sx={{ 
                                mr: 1.5, 
                                color: 'red',
                                fontSize: '2.2rem',
                                padding: '6px',
                                borderRadius: '50%',
                                border: '1px solid rgba(255, 0, 0, 0.5)',
                                backgroundColor: 'var(--background-secondary)'
                              }} 
                            />
                            <Typography variant="body1" color="text.secondary">
                              {project.address || 'No Address'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {project.total_value && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                              <AttachMoneyIcon 
                                sx={{ 
                                  mr: 1.5, 
                                  color: 'green',
                                  fontSize: '2.2rem',
                                  padding: '6px',
                                  borderRadius: '50%',
                                  border: '1px solid rgba(0, 128, 0, 0.5)',
                                  backgroundColor: 'var(--background-secondary)'
                                }} 
                              />
                              <Typography variant="body1" color="text.secondary">
                                ${project.total_value.toLocaleString()}
                              </Typography>
                            </Box>
                          )}
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <EventIcon 
                              sx={{ 
                                mr: 1.5, 
                                color: 'purple',
                                fontSize: '2.2rem',
                                padding: '6px',
                                borderRadius: '50%',
                                border: '1px solid rgba(128, 0, 128, 0.5)',
                                backgroundColor: 'var(--background-secondary)'
                              }} 
                            />
                            <Typography variant="body1" color="text.secondary">
                              Status: {formatStatus(project.status)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  />
                  {expandedProjects[project.id] ? (
                    <ExpandLess sx={{ 
                      color: 'var(--primary-main)',
                      fontSize: '2rem' 
                    }} />
                  ) : (
                    <ExpandMore sx={{ 
                      color: 'var(--primary-main)',
                      fontSize: '2rem'
                    }} />
                  )}
                </ListItem>

                <Collapse in={expandedProjects[project.id]} timeout="auto">
                  <Box sx={{ 
                    p: 2, 
                    bgcolor: 'var(--background-secondary)',
                    borderTop: '1px solid var(--border-color)'
                  }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            projectEvents[project.id]?.every(
                              event => selectedEvents.includes(event.id)
                            ) || false
                          }
                          onChange={() => handleSelectAllProjectEvents(project.id)}
                          sx={{
                            color: 'var(--primary-main)',
                            '&.Mui-checked': {
                              color: 'var(--primary-main)',
                            }
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontWeight: 500, color: 'var(--text-primary)' }}>
                          Select All Events
                        </Typography>
                      }
                    />

                    <List sx={{ mt: 1 }}>
                      {projectEvents[project.id]?.length > 0 ? (
                        projectEvents[project.id].map((event) => (
                          <ListItem 
                            key={event.id} 
                            sx={{ 
                              pl: 6,
                              borderRadius: 1,
                              mb: 1,
                              '&:hover': {
                                bgcolor: 'var(--background-paper)'
                              }
                            }}
                          >
                            <ListItemIcon>
                              <FiberManualRecordIcon 
                                sx={{ 
                                  fontSize: 8,
                                  color: 'var(--success-main)',
                                  border: '2px solid var(--success-main)',
                                  borderRadius: '50%',
                                  padding: '3px',
                                  backgroundColor: 'var(--success-light)'
                                }} 
                              />
                            </ListItemIcon>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedEvents.includes(event.id)}
                                  onChange={() => handleEventSelection(event.id)}
                                  sx={{
                                    color: 'var(--primary-main)',
                                    '&.Mui-checked': {
                                      color: 'var(--primary-main)',
                                    }
                                  }}
                                />
                              }
                              label={
                                <Box>
                                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                    {event.title}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    {event.description}
                                  </Typography>
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      color: 'var(--success-main)',
                                      fontWeight: 500 
                                    }}
                                  >
                                    Value: ${parseFloat(event.value || 0).toLocaleString()}
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem sx={{ pl: 6 }}>
                          <Typography color="text.secondary">
                            No uninvoiced events available for this project
                          </Typography>
                        </ListItem>
                      )}
                    </List>

                    <Box sx={{ 
                      mt: 3, 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      borderTop: '1px solid var(--border-color)',
                      pt: 2
                    }}>
                      <Button
                        variant="contained"
                        onClick={handleCreateInvoice}
                        disabled={selectedEvents.length === 0 || invoiceLoading}
                        sx={{
                          '&.Mui-disabled': {
                            backgroundColor: 'rgba(25, 118, 210, 0.12)',
                            color: 'rgba(0, 0, 0, 0.26)'
                          }
                        }}
                      >
                        {invoiceLoading ? (
                          <CircularProgress size={24} sx={{ color: 'inherit' }} />
                        ) : (
                          'Create Invoice'
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Collapse>
              </Paper>
            </Box>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default CreateInvoiceFromProject; 
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Typography
} from '@mui/material';
import {
  CheckCircle,
  AttachMoney,
  Payment,
  AccountBalance,
  CreditCard,
  MoreHoriz,
  Receipt
} from '@mui/icons-material';

const PaymentModal = ({ open, onClose, invoice, onConfirm }) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentNote, setPaymentNote] = useState('');

  console.log("Invoice received in PaymentModal:", invoice);

  const handleConfirm = () => {
    onConfirm({ paymentMethod, paymentNote });
  };

  // Calculate the total amount from invoice items
  const totalAmount = invoice?.items
    ? invoice.items.reduce((total, item) => {
        const quantity = parseFloat(item.quantity) || 0;
        const price = parseFloat(item.unit_price) || 0;
        return total + (quantity * price);
      }, 0).toFixed(2)
    : '0.00';

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          backgroundColor: 'var(--background-paper)'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid var(--divider)',
        p: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <CheckCircle sx={{ color: 'success.main', fontSize: 28 }} />
        <Typography variant="h6" component="span">
          Mark Invoice as Paid
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
            Invoice #{invoice?.invoice_number}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Total Amount: ${totalAmount}
          </Typography>
        </Box>

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Payment Method</InputLabel>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            label="Payment Method"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--divider)'
              }
            }}
          >
            <MenuItem value="cash">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AttachMoney sx={{ color: 'success.main' }} />
                Cash
              </Box>
            </MenuItem>
            <MenuItem value="check">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Receipt sx={{ color: 'info.main' }} />
                Check
              </Box>
            </MenuItem>
            <MenuItem value="credit_card">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Payment sx={{ color: 'primary.main' }} />
                Credit Card
              </Box>
            </MenuItem>
            <MenuItem value="bank_transfer">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccountBalance sx={{ color: 'secondary.main' }} />
                Bank Transfer
              </Box>
            </MenuItem>
            <MenuItem value="stripe">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CreditCard sx={{ color: 'purple' }} />
                Stripe
              </Box>
            </MenuItem>
            <MenuItem value="other">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <MoreHoriz />
                Other
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Payment Notes"
          value={paymentNote}
          onChange={(e) => setPaymentNote(e.target.value)}
          placeholder="Enter any additional payment details..."
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'var(--divider)'
              }
            }
          }}
        />
      </DialogContent>

      <DialogActions sx={{ 
        borderTop: '1px solid var(--divider)',
        p: 3,
        gap: 2
      }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{
            borderColor: 'var(--divider)',
            color: 'text.secondary',
            '&:hover': {
              borderColor: 'var(--primary)',
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="success"
          startIcon={<CheckCircle />}
          sx={{
            px: 3,
            '&:hover': {
              backgroundColor: 'success.dark'
            }
          }}
        >
          Confirm Payment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentModal; 
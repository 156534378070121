import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Switch, 
  Button,
  Alert,
  Snackbar
} from '@mui/material';
import { 
  Bell as NotificationsIcon,
  Envelope as EmailIcon,
  ProjectorScreen as ProjectIcon,
  Users as EmployeeIcon
} from "@phosphor-icons/react";
import api from '../services/api';

function AdminSettingsNotifications() {
  const [settings, setSettings] = useState({
    emailNotifications: false,
    projectUpdates: false,
    employeeClockIns: false
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const handleChange = (name) => (event) => {
    setSettings(prev => ({
      ...prev,
      [name]: event.target.checked
    }));
  };

  const testNotification = async () => {
    try {
      const response = await api.post('/notifications/test', {
        title: 'Test Notification',
        message: 'This is a test notification',
        type: 'info'
      });
      
      if (response.status === 200) {
        setAlertType('success');
        setAlertMessage('Test notification sent successfully!');
      } else {
        setAlertType('error');
        setAlertMessage('Failed to send test notification');
      }
      setShowAlert(true);
    } catch (error) {
      console.error('Error creating test notification:', error);
      setAlertType('error');
      setAlertMessage('Error sending test notification');
      setShowAlert(true);
    }
  };

  const notificationItems = [
    {
      name: 'emailNotifications',
      label: 'Email Notifications',
      description: 'Receive important updates via email',
      icon: <EmailIcon size={24} weight="light" />
    },
    {
      name: 'projectUpdates',
      label: 'Project Updates',
      description: 'Get notified about project changes and milestones',
      icon: <ProjectIcon size={24} weight="light" />
    },
    {
      name: 'employeeClockIns',
      label: 'Employee Clock In/Out',
      description: 'Receive alerts when employees clock in or out',
      icon: <EmployeeIcon size={24} weight="light" />
    }
  ];

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: 'var(--background-default)',
      p: 3,
      gap: 3
    }}>
      {/* Header */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'var(--background-paper)',
        p: 3,
        borderRadius: '12px',
        border: '1px solid var(--divider)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{
            width: 48,
            height: 48,
            borderRadius: '12px',
            backgroundColor: 'rgba(74, 93, 249, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <NotificationsIcon size={28} weight="light" color="#4a5df9" />
          </Box>
          <Box>
            <Typography variant="h6" sx={{ 
              fontFamily: 'Urbanist',
              fontWeight: 600,
              color: 'var(--text-primary)',
              mb: 0.5
            }}>
              Notification Settings
            </Typography>
            <Typography sx={{ 
              fontFamily: 'Urbanist',
              color: 'var(--text-secondary)',
              fontSize: '0.875rem'
            }}>
              Manage your notification preferences
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={testNotification}
          sx={{
            textTransform: 'none',
            fontFamily: 'Urbanist',
            backgroundColor: '#4a5df9',
            borderRadius: '8px',
            px: 3,
            '&:hover': {
              backgroundColor: '#3544b8'
            }
          }}
        >
          Send Test Notification
        </Button>
      </Box>

      {/* Notification Settings */}
      <Box sx={{
        backgroundColor: 'var(--background-paper)',
        borderRadius: '12px',
        border: '1px solid var(--divider)',
        flex: 1
      }}>
        <Box sx={{
          p: 3,
          borderBottom: '1px solid var(--divider)'
        }}>
          <Typography sx={{ 
            fontFamily: 'Urbanist',
            fontWeight: 600,
            color: 'var(--text-primary)',
          }}>
            Notification Preferences
          </Typography>
        </Box>

        <Box sx={{ p: 2 }}>
          {notificationItems.map((item, index) => (
            <Box
              key={item.name}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                borderBottom: index !== notificationItems.length - 1 ? '1px solid var(--divider)' : 'none'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '8px',
                  backgroundColor: 'rgba(74, 93, 249, 0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  {item.icon}
                </Box>
                <Box>
                  <Typography sx={{ 
                    fontFamily: 'Urbanist',
                    fontWeight: 600,
                    color: 'var(--text-primary)',
                    mb: 0.5
                  }}>
                    {item.label}
                  </Typography>
                  <Typography sx={{ 
                    fontFamily: 'Urbanist',
                    color: 'var(--text-secondary)',
                    fontSize: '0.875rem'
                  }}>
                    {item.description}
                  </Typography>
                </Box>
              </Box>
              <Switch
                checked={settings[item.name]}
                onChange={handleChange(item.name)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#4a5df9'
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#4a5df9'
                  }
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowAlert(false)} 
          severity={alertType}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AdminSettingsNotifications; 
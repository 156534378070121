import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import ScheduleItemHeader from './ScheduleItemHeader';
import ScheduleItemDetails from './ScheduleItemDetails';

const ScheduleItem = ({ item, index, items, setItems }) => {
  console.log('ScheduleItem render:', { item, index, items });

  const handleScheduleLaterChange = (e) => {
    e.stopPropagation();
    const checked = e.target.checked;
    
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      scheduleLater: checked,
      ...(checked ? {
        start_time: null,
        end_time: null
      } : {
        start_time: dayjs().hour(9).minute(0).second(0),
        end_time: dayjs().hour(17).minute(0).second(0)
      })
    };
    console.log('Updating items:', newItems);
    setItems(newItems);
  };

  const handleDateChange = (field, value) => {
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [field]: dayjs(value).isValid() ? value : null
    };
    console.log('Date change:', field, value, newItems);
    setItems(newItems);
  };

  const handleTimeChange = (field, timeStr) => {
    if (!timeStr) return;
    
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    
    let hour = parseInt(hours);
    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;
    
    const newItems = [...items];
    const currentDateTime = dayjs(newItems[index][field] || new Date());
    newItems[index] = {
      ...newItems[index],
      [field]: currentDateTime
        .hour(hour)
        .minute(parseInt(minutes))
        .second(0)
    };
    console.log('Time change:', field, timeStr, newItems);
    setItems(newItems);
  };

  return (
    <Box
      sx={{
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <ScheduleItemHeader 
        item={item}
        handleScheduleLaterChange={handleScheduleLaterChange}
      />
      
      {!item.scheduleLater && (
        <ScheduleItemDetails 
          item={item}
          index={index}
          estimateItems={items}
          setEstimateItems={setItems}
        />
      )}
    </Box>
  );
};

export default ScheduleItem; 
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
  Box,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
} from '@mui/material';
import {
  Add as AddIcon,
  CalendarTodayOutlined as CalendarTodayOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  AttachMoneyOutlined as AttachMoneyOutlinedIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ViewColumn as ViewColumnIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  ViewList as ViewListIcon,
  ViewKanban as ViewKanbanIcon,
} from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { Clock, Kanban, List } from "@phosphor-icons/react";
import CustomToggle from './common/CustomToggle';
import SearchContainer from './common/SearchContainer';

import { getAllProjects } from '../services/api';
import styles from './Projects.module.scss';
import ProjectPipelineView from './ProjectPipelineView';

const PROJECT_STATUSES = {
  PENDING_SCHEDULING: 'pending_scheduling',
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  PENDING_INVOICING: 'pending_invoicing',
  COMPLETED: 'completed'
};

const STATUS_STYLES = {
  [PROJECT_STATUSES.PENDING_SCHEDULING]: {
    color: '#E65100',
    backgroundColor: '#FFF3E0'
  },
  [PROJECT_STATUSES.SCHEDULED]: {
    color: '#1565C0',
    backgroundColor: '#E3F2FD'
  },
  [PROJECT_STATUSES.IN_PROGRESS]: {
    color: '#2E7D32',
    backgroundColor: '#E8F5E9'
  },
  [PROJECT_STATUSES.PENDING_INVOICING]: {
    color: '#7B1FA2',
    backgroundColor: '#F3E5F5'
  },
  [PROJECT_STATUSES.COMPLETED]: {
    color: '#303F9F',
    backgroundColor: '#E8EAF6'
  }
};

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [totalProjects, setTotalProjects] = useState(0);
  const [sortOrder, setSortOrder] = useState('newest');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [moreEmployeesAnchorEl, setMoreEmployeesAnchorEl] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [showPipelineView, setShowPipelineView] = useState(false);
  const filterChipsRef = useRef(null);
  const [viewMode, setViewMode] = useState('list');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [sortDirection, setSortDirection] = useState('desc');

  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: PROJECT_STATUSES.PENDING_SCHEDULING, label: 'Pending Scheduling' },
    { value: PROJECT_STATUSES.SCHEDULED, label: 'Scheduled' },
    { value: PROJECT_STATUSES.IN_PROGRESS, label: 'In Progress' },
    { value: PROJECT_STATUSES.PENDING_INVOICING, label: 'Pending Invoicing' },
    { value: PROJECT_STATUSES.COMPLETED, label: 'Completed' }
  ];

  const filteredProjects = useMemo(() => {
    return projects.filter(project => {
      const matchesSearch = project.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                           project.client?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                           project.client?.company?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = selectedStatus === 'all' || project.status === selectedStatus;
      
      return matchesSearch && matchesStatus;
    });
  }, [projects, searchTerm, selectedStatus]);

  const sortedProjects = useMemo(() => {
    const sorted = [...filteredProjects].sort((a, b) => {
      const dateA = new Date(a.start_time || 0);
      const dateB = new Date(b.start_time || 0);
      return sortDirection === 'desc' ? dateB - dateA : dateA - dateB;
    });

    return sorted.reduce((groups, project) => {
      if (!project.start_time) {
        if (!groups['Pending']) {
          groups['Pending'] = [];
        }
        groups['Pending'].push(project);
      } else {
        const monthYear = format(parseISO(project.start_time), 'MMMM yyyy');
        if (!groups[monthYear]) {
          groups[monthYear] = [];
        }
        groups[monthYear].push(project);
      }
      return groups;
    }, {});
  }, [filteredProjects, sortDirection]);

  const handleSortChange = () => {
    const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newSortDirection);
    setSortOrder(newSortDirection === 'desc' ? 'newest' : 'oldest');
  };

  const handleCreateProject = () => {
    navigate('/admin/projects/create');
  };

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const data = await getAllProjects();
        setProjects(data);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [refreshTrigger]);

  const checkScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  }, []);

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [checkScroll]);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = 200;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    try {
      return format(parseISO(timeString), 'h:mm a');
    } catch (error) {
      console.error('Error formatting time:', error);
      return '';
    }
  };

  const handleProjectClick = (projectId, event) => {
    if (event.target.closest('button') || event.target.closest('a')) {
      return;
    }
    navigate(`/admin/projects/${projectId}`);
  };

  return (
    <div className={styles.projectsContainer}>
      <div className={styles.headerWrapper}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          className={styles.headerContainer}
        >
          <Typography 
            variant="h4" 
            className={styles.title}
            sx={{ 
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '2rem',
              fontWeight: 700,
              color: '#212B36',
              letterSpacing: '0.5px'
            }}
          >
            Projects
          </Typography>
          <Button
            variant="contained"
            onClick={handleCreateProject}
            className={styles.createButton}
            startIcon={<AddIcon />}
            disableRipple
            sx={{
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#4a5df9 !important',
                boxShadow: 'none !important',
              },
              '&.MuiButton-contained:hover': {
                backgroundColor: '#3c50fa',
                boxShadow: 'none',
              },
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '0.875rem',
              padding: '6px 16px',
              borderRadius: '8px'
            }}
          >
            Create Project
          </Button>
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="flex-end">
            <div className={styles.sliderContainer}>
              {canScrollLeft && (
                <IconButton 
                  className={styles.scrollButton}
                  onClick={() => handleScroll('left')}
                  size="small"
                >
                  <ChevronLeftIcon />
                </IconButton>
              )}
              
              <div 
                ref={containerRef} 
                className={styles.toggleContainer}
                onScroll={checkScroll}
              >
                <CustomToggle
                  options={statusOptions}
                  value={selectedStatus}
                  onChange={(newStatus) => {
                    if (newStatus !== null) {
                      setSelectedStatus(newStatus);
                    }
                  }}
                />
              </div>

              {canScrollRight && (
                <IconButton 
                  className={styles.scrollButton}
                  onClick={() => handleScroll('right')}
                  size="small"
                >
                  <ChevronRightIcon />
                </IconButton>
              )}
            </div>
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <SearchContainer
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search projects..."
              width="320px"
            />

            <Box display="flex" gap={2}>
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleSortChange}
                sx={{
                  color: 'text.secondary',
                  textTransform: 'none',
                  borderRadius: '11px !important',
                  fontFamily: 'Urbanist, sans-serif',
                  border: '1px solid #E0E0E0',
                  fontSize: 'clamp(0.75rem, 1vw, 0.875rem)',
                  fontWeight: 500,
                  padding: 'clamp(4px, 0.5vw, 8px) clamp(8px, 1vw, 16px)',
                  minWidth: 'auto',
                  whiteSpace: 'nowrap',
                  '& .MuiButton-endIcon': {
                    marginLeft: 'clamp(2px, 0.5vw, 8px)'
                  }
                }}
              >
                {sortOrder === 'newest' ? 'Newest First' : 'Oldest First'}
              </Button>

              <Button
                variant="text"
                onClick={() => setViewMode(prev => prev === 'list' ? 'pipeline' : 'list')}
                startIcon={viewMode === 'list' 
                  ? <Kanban size={16} weight="light" /> 
                  : <List size={16} weight="light" />
                }
                sx={{
                  color: 'text.secondary',
                  borderRadius: '11px',
                  border: '1px solid #E0E0E0',
                  textTransform: 'none',
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: 'clamp(0.75rem, 1vw, 0.875rem)',
                  fontWeight: 500,
                  padding: 'clamp(4px, 0.5vw, 8px) clamp(8px, 1vw, 16px)',
                  minWidth: 'auto',
                  whiteSpace: 'nowrap',
                  '& .MuiButton-startIcon': {
                    marginRight: 'clamp(2px, 0.5vw, 8px)'
                  }
                }}
              >
                {viewMode === 'list' ? 'Pipeline View' : 'List View'}
              </Button>
            </Box>
          </Box>
        </Box>
      </div>

      <div className={styles.contentWrapper}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <CircularProgress />
          </div>
        ) : error ? (
          <div className={styles.errorContainer}>
            <Typography color="error">{error}</Typography>
          </div>
        ) : Object.keys(sortedProjects).length === 0 ? (
          <div className={styles.emptyContainer}>
            <Typography>No projects found</Typography>
          </div>
        ) : viewMode === 'pipeline' ? (
          <ProjectPipelineView projects={Object.values(sortedProjects).flat()} />
        ) : (
          <div className={styles.projectList}>
            {Object.entries(sortedProjects).map(([monthYear, monthProjects]) => (
              <div key={monthYear}>
                <Typography 
                  variant="h6"
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    mb: 2,
                    mt: 3,
                    px: 3,
                    fontFamily: 'Urbanist, sans-serif',
                  }}
                >
                  {monthYear}
                </Typography>
                {monthProjects.map((project) => (
                  <div 
                    key={project.id} 
                    className={styles.projectRow}
                    onClick={(e) => handleProjectClick(project.id, e)}
                    role="button"
                    tabIndex={0}
                  >
                    <div className={styles.cell}>
                      <div className={styles.dateInfo}>
                        <Typography className={styles.dayName}>
                          {project.start_time ? format(parseISO(project.start_time), 'EEE') : ''}
                        </Typography>
                        <Typography className={styles.dayNumber}>
                          {project.start_time ? format(parseISO(project.start_time), 'd') : ''}
                        </Typography>
                      </div>
                      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                      <div className={styles.cell} style={{ 
                        flex: 3, 
                      }}>
                        <div className={styles.timeLocation}>
                          <div className={styles.timeSlot}>
                            <Clock 
                              size={16} 
                              weight="light"
                              style={{ color: '#637381' }} 
                            />
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                paddingRight: 'clamp(8px, 1vw, 16px)',
                                fontSize: 'clamp(12px, 1.2vw, 14px)'
                              }}
                            >
                              {formatTime(project.start_time)} - {formatTime(project.end_time)}
                            </Typography>
                          </div>
                          <div className={styles.location}>
                            <LocationOnOutlinedIcon sx={{ 
                              fontSize: 'clamp(14px, 1.2vw, 16px)',
                              color: '#637381' 
                            }} />
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                paddingRight: 'clamp(12px, 1.5vw, 24px)',
                                flex: 1,
                                fontSize: 'clamp(12px, 1.2vw, 14px)'
                              }}
                            >
                              {project.address || 'No address'}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.cell}>
                      <div className={styles.projectInfo}>
                        <Typography className={styles.projectName} sx={{
                          fontSize: 'clamp(13px, 1.3vw, 16px)'
                        }}>
                          {project.name || 'Untitled Project'}
                        </Typography>
                        <Typography className={styles.clientName} sx={{
                          fontSize: 'clamp(12px, 1.2vw, 14px)'
                        }}>
                          {project.client?.company || project.client?.name}
                        </Typography>
                      </div>
                    </div>

                    <div className={styles.cell}>
                      {project.employees && project.employees.length > 0 && (
                        <div className={styles.employeeAvatars}>
                          {project.employees.slice(0, 3).map((employee) => (
                            <Tooltip key={employee.id} title={employee.name}>
                              <Avatar 
                                className={styles.employeeAvatar}
                                sx={{ 
                                  width: 'clamp(24px, 2.5vw, 32px)', 
                                  height: 'clamp(24px, 2.5vw, 32px)',
                                  fontSize: 'clamp(12px, 1.2vw, 14px)'
                                }}
                              >
                                {employee.name?.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          ))}
                          {project.employees.length > 3 && (
                            <Avatar className={styles.moreEmployees}>
                              +{project.employees.length - 3}
                            </Avatar>
                          )}
                        </div>
                      )}
                    </div>

                    <div className={styles.cell}>
                      <Typography className={styles.projectValue}>
                        ${project.total_value?.toLocaleString() || '0'}
                      </Typography>
                    </div>

                    <div className={styles.cell}>
                      <div className={styles.statusChip} data-status={project.status}>
                        {project.status?.replace(/_/g, ' ').toUpperCase() || 'PENDING'}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;

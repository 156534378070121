import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box
} from '@mui/material';
import { X as CloseIcon } from "@phosphor-icons/react";

const DAYS_OF_WEEK = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 }
];

const RecurringAvailabilityModal = ({ open, onClose, onSave, initialSelectedDays = [] }) => {
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);
  const [removedDays, setRemovedDays] = useState([]);

  useEffect(() => {
    if (open) {
      setSelectedDays(initialSelectedDays);
      setRemovedDays([]);
    }
  }, [open, initialSelectedDays]);

  const handleDayToggle = (dayValue) => {
    if (selectedDays.includes(dayValue)) {
      setSelectedDays(prev => prev.filter(d => d !== dayValue));
      setRemovedDays(prev => [...prev, dayValue]);
    } else {
      setSelectedDays(prev => [...prev, dayValue].sort((a, b) => a - b));
      setRemovedDays(prev => prev.filter(d => d !== dayValue));
    }
  };

  const handleSave = () => {
    const today = new Date();
    const endOfYear = new Date(today.getFullYear(), 11, 31);

    onSave({
      selectedDays,
      removedDays,
      startDate: today.toISOString(),
      endDate: endOfYear.toISOString()
    });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          backgroundColor: 'var(--background-paper)',
          backgroundImage: 'none'
        }
      }}
    >
      <DialogTitle sx={{ 
        p: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid var(--divider)',
        fontFamily: 'Urbanist',
        fontSize: '1.25rem',
        fontWeight: 600,
        color: 'var(--text-primary)'
      }}>
        Set Weekly Availability
        <CloseIcon 
          size={24} 
          weight="light" 
          onClick={onClose}
          style={{ cursor: 'pointer', color: 'var(--text-secondary)' }}
        />
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ my: 2 }}>
          <Typography 
            sx={{ 
              mb: 3,
              fontFamily: 'Urbanist',
              color: 'var(--text-secondary)',
              fontSize: '0.95rem'
            }}
          >
            Select the days of the week you'll be available.
          </Typography>
          <FormGroup>
            {DAYS_OF_WEEK.map((day) => (
              <FormControlLabel
                key={day.value}
                control={
                  <Checkbox
                    checked={selectedDays.includes(day.value)}
                    onChange={() => handleDayToggle(day.value)}
                    sx={{
                      color: 'var(--text-secondary)',
                      '&.Mui-checked': {
                        color: '#4a5df9',
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ 
                    fontFamily: 'Urbanist',
                    color: 'var(--text-primary)',
                    fontSize: '0.95rem'
                  }}>
                    {day.name}
                  </Typography>
                }
                sx={{ mb: 1 }}
              />
            ))}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions sx={{ 
        p: 3, 
        borderTop: '1px solid var(--divider)',
        gap: 2
      }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontFamily: 'Urbanist',
            color: 'var(--text-secondary)',
            borderColor: 'var(--divider)',
            '&:hover': {
              borderColor: 'var(--text-secondary)',
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSave} 
          variant="contained"
          sx={{
            textTransform: 'none',
            fontFamily: 'Urbanist',
            backgroundColor: '#4a5df9',
            '&:hover': {
              backgroundColor: '#3544b8'
            }
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecurringAvailabilityModal; 
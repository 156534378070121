import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getProjectPhotos, uploadProjectPhoto } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Upload as UploadIcon } from "@phosphor-icons/react";
import styles from './ProjectPhotos.module.scss';

const ProjectPhotos = ({ projectId }) => {
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const fileInputRef = useRef(null);

  const getPhotoUrl = useCallback((filename) => {
    if (!filename) return '';
    return `${process.env.REACT_APP_API_URL}${filename}`;
  }, []);

  useEffect(() => {
    fetchPhotos();
  }, [projectId]);

  const fetchPhotos = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getProjectPhotos(projectId);
      
      if (!response || !response.photos) {
        throw new Error('Invalid response format');
      }
      
      setPhotos(response.photos);
    } catch (err) {
      console.error('Error fetching project photos:', err);
      setError('Failed to load photos. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files || []).filter(file => file.type.startsWith('image/'));
    if (files.length > 0) {
      setSelectedFiles(files);
      // Automatically start upload
      await handlePhotoUpload(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files)
      .filter(file => file.type.startsWith('image/'));
    
    if (files.length > 0) {
      setSelectedFiles(files);
      // Automatically start upload
      await handlePhotoUpload(files);
    }
  };

  const handlePhotoUpload = async (filesToUpload = selectedFiles) => {
    if (filesToUpload.length === 0) return;

    for (const file of filesToUpload) {
      const formData = new FormData();
      formData.append('file', file);
      
      try {
        setUploadProgress(prev => ({...prev, [file.name]: 0}));
        
        await uploadProjectPhoto(projectId, formData, (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(prev => ({
            ...prev, 
            [file.name]: percentCompleted
          }));
        });

      } catch (err) {
        console.error(`Error uploading ${file.name}:`, err);
        setError(`Failed to upload ${file.name}`);
      }
    }

    // Refresh the photos list
    await fetchPhotos();
    // Clear selected files and progress
    setSelectedFiles([]);
    setUploadProgress({});
  };

  const removeSelectedFile = (fileName) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const openLightbox = (index) => {
    setCurrentPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setIsZoomed(false);
  };

  const nextPhoto = useCallback(() => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
    setIsZoomed(false);
  }, [photos.length]);

  const prevPhoto = useCallback(() => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    setIsZoomed(false);
  }, [photos.length]);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!lightboxOpen) return;
      switch (e.key) {
        case 'ArrowRight':
          nextPhoto();
          break;
        case 'ArrowLeft':
          prevPhoto();
          break;
        case 'Escape':
          closeLightbox();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [lightboxOpen, nextPhoto, prevPhoto]);

  const handleUploadClick = () => {
    console.log('Upload button clicked');
    console.log('Selected files:', selectedFiles);
    handlePhotoUpload();
  };

  if (loading) return <div>Loading photos...</div>;
  if (error) return <div>{error}</div>;

  const formatDateTime = (dateString) => {
    if (!dateString) {
      console.log('No date string provided');
      return '';
    }
    
    console.log('Raw date string:', dateString); // Debug log
    
    try {
      const date = new Date(dateString);
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        console.log('Invalid date object created');
        return dateString;
      }

      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }).format(date);
      
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography 
        variant="h6" 
        sx={{ 
          fontFamily: "'Noto Sans', sans-serif !important",
          fontWeight: 400,
          fontSize: '1rem',
          color: 'var(--text-primary)',
          mb: 2
        }}
      >
        Project Photos
      </Typography>

      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: 2,
        width: '100%'
      }}>
        {/* Upload Box */}
        <Box
          sx={{
            position: 'relative',
            paddingTop: '75%',
            borderRadius: '8px',
            border: '2px dashed #E2E8F0',
            backgroundColor: isDragging ? '#F8FAFC' : 'transparent',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: '#F8FAFC',
              borderColor: '#CBD5E1'
            }
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            multiple
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          <Box
            component="label"
            onClick={() => fileInputRef.current?.click()}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <UploadIcon 
              size={32} 
              weight="light" 
              color="#64748B"
            />
            <Typography
              sx={{
                color: '#64748B',
                fontSize: '0.875rem',
                mt: 1
              }}
            >
              Upload Photos
            </Typography>
          </Box>
        </Box>

        {/* Photo Grid */}
        {photos.map((photo, index) => (
          <Box
            key={photo.id || index}
            sx={{
              position: 'relative',
              paddingTop: '75%',
              borderRadius: '8px',
              overflow: 'hidden',
              backgroundColor: '#F8FAFC',
              border: '1px solid #E2E8F0',
              cursor: 'pointer'
            }}
            onClick={() => openLightbox(index)}
          >
            <img
              src={getPhotoUrl(photo.file_path)}
              alt={`Project photo ${index + 1}`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        ))}
      </Box>

      {lightboxOpen && (
        <div className={styles.lightbox} onClick={closeLightbox}>
          <div className={styles.lightboxContent} onClick={(e) => e.stopPropagation()}>
            <img
              src={getPhotoUrl(photos[currentPhotoIndex].file_path)}
              alt={`Project photo ${currentPhotoIndex + 1}`}
              className={`${styles.lightboxImage} ${isZoomed ? styles.zoomed : ''}`}
              onClick={toggleZoom}
            />
            <div className={styles.lightboxControls}>
              <button onClick={prevPhoto} className={styles.lightboxButton}>
                ←
              </button>
              <div className={styles.photoInfo}>
                <span>{currentPhotoIndex + 1} / {photos.length}</span>
                <span className={styles.timestamp}>
                  {formatDateTime(photos[currentPhotoIndex].uploaded_at)}
                </span>
              </div>
              <button onClick={nextPhoto} className={styles.lightboxButton}>
                →
              </button>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ProjectPhotos;

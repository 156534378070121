import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography
} from '@mui/material';
import { 
  X as CloseIcon,
  FloppyDisk as SaveIcon,
  PencilSimple as EditIcon,
  Trash as DeleteIcon,
  Plus as AddIcon
} from "@phosphor-icons/react";
import ReactQuill from 'react-quill';
import { getTermsTemplates, createTermsTemplate, updateTermsTemplate, deleteTermsTemplate } from '../../services/api';
import styles from './TermsTemplatesManager.module.scss';
import { useTheme } from '../../ThemeContext';

const TermsTemplatesManager = () => {
  const [templates, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState({ name: '', content: '' });
  const { darkMode } = useTheme();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await getTermsTemplates();
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleOpenDialog = (template = null) => {
    setEditingTemplate(template);
    setCurrentTemplate(template ? {
      name: template.name,
      content: template.content
    } : { name: '', content: '' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingTemplate(null);
    setCurrentTemplate({ name: '', content: '' });
  };

  const handleSave = async () => {
    try {
      if (editingTemplate) {
        await updateTermsTemplate(editingTemplate.id, currentTemplate);
      } else {
        await createTermsTemplate(currentTemplate);
      }
      fetchTemplates();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        await deleteTermsTemplate(id);
        fetchTemplates();
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }
  };

  return (
    <Box className={`${styles.container} ${darkMode ? styles.darkMode : ''}`} sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <Box className={styles.headerSection}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          sx={{
            textTransform: 'none',
            fontFamily: 'Urbanist, sans-serif',
            borderRadius: '8px',
            backgroundColor: '#4a5df9',
            padding: '8px 24px',
            fontWeight: 600,
            marginLeft: 'auto',
            '&:hover': {
              backgroundColor: '#3c50fa',
            }
          }}
        >
          Add Template
        </Button>
      </Box>

      <Paper 
        className={`${styles.templatesList} ${darkMode ? styles.darkMode : ''}`}
        elevation={0}
        sx={{
          backgroundColor: darkMode ? 'var(--background-paper)' : '#fff',
          color: darkMode ? 'var(--text-primary)' : 'inherit',
          border: '1px solid var(--divider)',
          borderRadius: '12px',
          flex: 1,
          minHeight: 0,
          overflow: 'auto'
        }}
      >
        <List sx={{ padding: '16px' }}>
          {templates.map((template) => (
            <ListItem 
              key={template.id} 
              className={darkMode ? styles.darkListItem : ''}
              sx={{
                backgroundColor: 'var(--background-paper)',
                border: '1px solid #e0e0e0 !important',
                borderRadius: '8px',
                padding: '16px 24px',
                marginBottom: '8px',
                '&:hover': {
                  borderColor: 'var(--primary-main)',
                },
                transition: 'border-color 0.2s ease',
                '&:last-child': {
                  marginBottom: 0
                }
              }}
            >
              <ListItemText
                primary={template.name}
                secondary={`Updated: ${new Date(template.updated_at).toLocaleDateString()}`}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: darkMode ? 'var(--text-primary)' : 'inherit',
                    fontFamily: 'Urbanist',
                    fontWeight: 600,
                    fontSize: '1rem',
                    marginBottom: '4px'
                  },
                  '& .MuiListItemText-secondary': {
                    color: 'var(--text-secondary)',
                    fontFamily: 'Urbanist',
                    fontSize: '0.875rem'
                  }
                }}
              />
              <ListItemSecondaryAction>
                <IconButton 
                  onClick={() => handleOpenDialog(template)}
                  sx={{ 
                    color: '#4a5df9',
                    marginRight: '8px',
                    padding: '12px',
                    '&:hover': {
                      backgroundColor: 'rgba(74, 93, 249, 0.08)'
                    }
                  }}
                >
                  <EditIcon size={22} weight="light" />
                </IconButton>
                <IconButton 
                  onClick={() => handleDelete(template.id)}
                  sx={{ 
                    color: '#FF4842',
                    padding: '12px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 72, 66, 0.08)'
                    }
                  }}
                >
                  <DeleteIcon size={22} weight="light" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        maxWidth="md" 
        fullWidth
        className={`${styles.dialog} ${darkMode ? styles.darkMode : ''}`}
        PaperProps={{
          sx: {
            backgroundColor: darkMode ? 'var(--background-primary)' : '#fff',
            color: darkMode ? 'var(--text-primary)' : 'inherit'
          }
        }}
      >
        <DialogTitle sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}>
          {editingTemplate ? 'Edit Template' : 'New Template'}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Template Name"
            value={currentTemplate.name}
            onChange={(e) => setCurrentTemplate(prev => ({ 
              ...prev, 
              name: e.target.value 
            }))}
            sx={{ 
              mb: 2, 
              mt: 1,
              '& .MuiInputLabel-root': {
                color: darkMode ? 'var(--text-secondary)' : 'inherit'
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: darkMode ? 'var(--input-background)' : 'inherit',
                color: darkMode ? 'var(--text-primary)' : 'inherit',
                '& fieldset': {
                  borderColor: darkMode ? 'var(--border-color)' : 'inherit'
                }
              }
            }}
          />
          <ReactQuill
            value={currentTemplate.content}
            onChange={(content) => setCurrentTemplate(prev => ({ 
              ...prev, 
              content 
            }))}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['clean']
              ]
            }}
            theme="snow"
            className={darkMode ? styles.darkQuill : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog}
            startIcon={<CloseIcon size={20} weight="light" />}
            sx={{ 
              color: darkMode ? 'var(--text-primary)' : 'inherit',
              textTransform: 'none',
              fontFamily: 'Urbanist'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave} 
            variant="contained"
            startIcon={<SaveIcon size={20} weight="light" />}
            disabled={!currentTemplate.name || !currentTemplate.content}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              borderRadius: '8px',
              backgroundColor: '#4a5df9',
              '&:hover': {
                backgroundColor: '#3c50fa',
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TermsTemplatesManager; 
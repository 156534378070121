import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import MobileMenu from './MobileMenu';
import CreateButton from './CreateButton';
import TopBar from './TopBar';
import styles from './Layout.module.scss';

const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/';

  return (
    <div className={styles.appContainer}>
      {!isLoginPage && (
        <>
          <TopBar />
          <Navbar />
          <div className={styles.mobileNav}>
            <MobileMenu />
          </div>
        </>
      )}
      <main className={`${styles.mainContent} ${isLoginPage ? styles.loginPage : ''}`}>
        {children}
      </main>
      {!isLoginPage && <CreateButton />}
    </div>
  );
};

export default Layout;

import React from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@mui/material';
import SingleDaySchedule from './SingleDaySchedule';
import MultiDaySchedule from './MultiDaySchedule';
import dayjs from 'dayjs';

const ScheduleTabs = ({ project, setProject, scheduleLater, setScheduleLater, eventTab, handleEventTabChange }) => {
  const handleDateChange = (field, value) => {
    const validDate = dayjs(value).isValid() ? value : null;
    setProject(prev => ({
      ...prev,
      [field]: validDate
    }));
  };

  const handleTimeChange = (field, timeStr) => {
    if (!timeStr) return;
    
    const date = dayjs(field === 'start_time' ? project.start_time : project.end_time || new Date());
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    
    let hour = parseInt(hours);
    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;
    
    const newDateTime = date.hour(hour).minute(parseInt(minutes)).second(0);
    
    setProject(prev => ({
      ...prev,
      [field]: newDateTime
    }));
  };

  const renderScheduleContent = () => {
    if (eventTab === 0) {
      return (
        <SingleDaySchedule
          project={project}
          setProject={(updatedProject) => {
            setProject(prev => ({
              ...prev,
              start_time: updatedProject.start_time,
              end_time: updatedProject.end_time,
              items: updatedProject.items
            }));
          }}
        />
      );
    } else if (eventTab === 1) {
      return (
        <MultiDaySchedule
          project={project}
          setProject={(updatedProject) => {
            console.log('MultiDaySchedule update:', updatedProject);
            setProject(prev => ({
              ...prev,
              items: updatedProject.items,
              start_time: updatedProject.start_time,
              end_time: updatedProject.end_time
            }));
          }}
        />
      );
    }
  };

  return (
    <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
      <Tabs 
        value={eventTab} 
        onChange={handleEventTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
        sx={{
          mb: 3,
          px: 2,
          '& .MuiTabs-indicator': {
            backgroundColor: '#7635dc',
            height: '2px',
            borderRadius: '3px 3px 0 0',
            transition: 'all 0.2s ease'
          },
          '& .MuiTab-root': {
            textTransform: 'none',
            fontSize: '0.95rem',
            fontFamily: 'Urbanist',
            fontWeight: 500,
            color: 'var(--text-secondary)',
            minHeight: 48,
            padding: '12px 24px',
            gap: '8px',
            position: 'relative',
            '& .MuiTouchRipple-root': {
              display: 'none'
            },
            '& svg': {
              marginBottom: '0 !important',
              marginRight: '0 !important'
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              width: 0,
              height: '2px',
              borderRadius: '3px 3px 0 0',
              backgroundColor: '#7635dc',
              opacity: 0.6,
              transition: 'all 0.2s ease',
              transform: 'translateX(-50%)',
            },
            '&:hover::after': {
              width: '80%',
            },
            '&.Mui-selected': {
              color: 'var(--primary-main)',
              fontWeight: 600,
              '&::after': {
                opacity: 0,
              },
            },
            '&:hover': {
              color: 'var(--primary-main)',
            }
          },
        }}
      >
        <Tab 
          label="Single Day" 
          disableRipple
        />
        <Tab 
          label="Multi-Day" 
          disableRipple
        />
      </Tabs>

      <Box sx={{ pt: 0 }}>
        {renderScheduleContent()}
      </Box>
    </Paper>
  );
};

export default ScheduleTabs; 
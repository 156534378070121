import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button, InputLabel, Snackbar, Chip, IconButton, Container, Menu
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  User as UserIcon,
  Files as FilesIcon,
  CurrencyDollar as CurrencyDollarIcon,
  Buildings as BuildingsIcon,
  Calendar as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Clock as ClockIcon,
  Article as ArticleIcon,
  Money as MoneyIcon,
  Timer as TimerIcon,
  CaretLeft as CaretLeftIcon,
  CaretRight as CaretRightIcon,
  CaretUp as CaretUpIcon,
  CaretDown as CaretDownIcon,
  MagnifyingGlass as SearchIcon,
  DotsThree as DotsThreeIcon,
  Plus as PlusIcon,
  FunnelSimple as FilterListIcon,
  DotsThreeVertical as MoreVertIcon
} from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';
import styles from './AdminInvoices.module.scss';
import { getInvoices } from '../services/api';
import styled from '@emotion/styled';
import CustomToggle from './common/CustomToggle';
import SearchContainer from './common/SearchContainer';

const capitalizeFirstLetter = (string) => {
  if (!string) return 'Draft';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const getCustomerDetail = (invoice, field) => {
  if (!invoice) return 'N/A';

  // Try both customerDetails and customer_details
  const details = invoice.customerDetails || invoice.customer_details;
  
  if (!details) return 'N/A';

  // For nested fields like 'name' or 'company'
  const value = details[field] || details[`customer_${field}`];
  
  return value || 'N/A'; // Return N/A as fallback instead of empty string
};

const STATUS_STYLES = {
  'paid': {
    color: '#2E7D32',  // Green
    backgroundColor: '#E8F5E9'
  },
  'due': {
    color: '#1565C0',  // Blue
    backgroundColor: '#E3F2FD'
  },
  'overdue': {
    color: '#E65100',  // Orange
    backgroundColor: '#FFF3E0'
  },
  'awaiting payment': {
    color: '#7B1FA2',  // Purple
    backgroundColor: '#F3E5F5'
  },
  'draft': {
    color: '#303F9F',  // Indigo
    backgroundColor: '#E8EAF6'
  }
};

const AdminInvoices = () => {
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientFilter, setClientFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [timeFilter, setTimeFilter] = useState('All Time');
  const [clients, setClients] = useState(['All']);
  const [statuses] = useState(['All', 'Paid', 'Due', 'Overdue', 'Awaiting Payment']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [totalInvoiceValue, setTotalInvoiceValue] = useState(0);
  const [dueInvoiceValue, setDueInvoiceValue] = useState(0);
  const [averagePaymentTime, setAveragePaymentTime] = useState(8);
  const [scheduledToday, setScheduledToday] = useState(5);
  const [draftedTotal, setDraftedTotal] = useState(0);
  const [unpaidTotal, setUnpaidTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('all');
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState('All Customers');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [activeFilter, setActiveFilter] = useState('all');
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [unsentInvoices, setUnsentInvoices] = useState([]);
  const [sort, setSort] = useState('newest');
  const [statusCounts, setStatusCounts] = useState({
    all: 0,
    draft: 0,
    paid: 0,
    due: 0,
    overdue: 0,
    'awaiting payment': 0
  });
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const containerRef = useRef(null);
  const [statusSort, setStatusSort] = useState(null);
  const buttonRefs = useRef({});

  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'draft', label: 'Draft' },
    { value: 'paid', label: 'Paid' },
    { value: 'due', label: 'Due' },
    { value: 'overdue', label: 'Overdue' }
  ];

  const checkScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  }, []);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = 200;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [checkScroll]);

  const determineInvoiceStatus = (invoice) => {
    if (invoice.status) {
      return capitalizeFirstLetter(invoice.status);
    }
    
    if (!invoice.due_date) return 'Draft';
    
    const now = new Date();
    const dueDate = new Date(invoice.due_date);
    
    if (invoice.status?.toLowerCase() === 'paid') return 'Paid';
    if (now > dueDate) return 'Overdue';
    if (now <= dueDate) return 'Due';
    return 'Awaiting Payment';
  };

  useEffect(() => {
    let filtered = invoices;

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(invoice => {
        const searchString = searchTerm.toLowerCase();
        const customerName = getCustomerDetail(invoice, 'name')?.toLowerCase() || '';
        const companyName = getCustomerDetail(invoice, 'company')?.toLowerCase() || '';
        const invoiceNumber = invoice.invoice_number?.toLowerCase() || '';
        const total = calculateTotal(invoice).toString();

        return (
          customerName.includes(searchString) ||
          companyName.includes(searchString) ||
          invoiceNumber.includes(searchString) ||
          total.includes(searchString)
        );
      });
    }

    // Apply status filter
    if (selectedStatus !== 'all') {
      filtered = filtered.filter(invoice => {
        const invoiceStatus = invoice.status?.toLowerCase() || 'awaiting';
        return invoiceStatus === selectedStatus;
      });
    }

    setFilteredInvoices(filtered);
  }, [searchTerm, selectedStatus, invoices]);

  useEffect(() => {
    const fetchInvoices = async () => {
      setLoading(true);
      try {
        const fetchedInvoices = await getInvoices();
        console.log('Fetched invoices:', fetchedInvoices);
        
        const processedInvoices = fetchedInvoices.map(invoice => ({
          ...invoice,
          status: capitalizeFirstLetter(invoice.status || determineInvoiceStatus(invoice))
        }));
        
        console.log('Processed invoices:', processedInvoices);
        setInvoices(processedInvoices);
        setFilteredInvoices(processedInvoices);
        
        const unpaid = processedInvoices.filter(inv => 
          ['due', 'overdue'].includes(inv.status?.toLowerCase())
        );
        const unsent = processedInvoices.filter(inv => 
          inv.status?.toLowerCase() === 'draft'
        );
        
        setUnpaidInvoices(unpaid);
        setUnsentInvoices(unsent);
        
        const unpaidTotal = unpaid.reduce((sum, inv) => sum + calculateTotal(inv), 0);
        setUnpaidTotal(unpaidTotal);

        const counts = processedInvoices.reduce((acc, invoice) => {
          const status = invoice.status || 'Draft';
          acc[status.toLowerCase()] = (acc[status.toLowerCase()] || 0) + 1;
          acc.all = processedInvoices.length;
          return acc;
        }, { all: 0, paid: 0, due: 0, overdue: 0, draft: 0, 'awaiting payment': 0 });
        
        setStatusCounts(counts);
        
      } catch (err) {
        console.error('Error fetching invoices:', err);
        setError('Failed to fetch invoices');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  useEffect(() => {
    if (invoices.length) {
      const unpaid = invoices.filter(inv => 
        ['due', 'overdue', 'awaiting payment'].includes(inv.status?.toLowerCase())
      );
      const unsent = invoices.filter(inv => !inv.status || inv.status === 'draft');
      setUnpaidInvoices(unpaid);
      setUnsentInvoices(unsent);
    }
  }, [invoices]);

  useEffect(() => {
    const counts = invoices.reduce((acc, invoice) => {
      const status = invoice.status?.toLowerCase() || 'awaiting payment';
      acc[status] = (acc[status] || 0) + 1;
      acc.all = invoices.length;
      return acc;
    }, { all: 0, paid: 0, due: 0, overdue: 0, 'awaiting payment': 0 });
    
    setStatusCounts(counts);
  }, [invoices]);

  const getFilteredInvoices = () => {
    console.log('Getting filtered invoices with:', {
      clientFilter,
      statusFilter,
      timeFilter,
      searchQuery
    });

    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
    const startOfYear = new Date(now.getFullYear(), 0, 1);

    return invoices.filter(invoice => {
      const clientMatch = clientFilter === 'All' || getCustomerDetail(invoice, 'name') === clientFilter;
      const statusMatch = statusFilter === 'All' || invoice.status === statusFilter;
      const invDate = new Date(invoice.issueDate);
      let dateMatch = true;

      switch (timeFilter) {
        case 'This Month':
          dateMatch = invDate >= startOfMonth;
          break;
        case 'This Quarter':
          dateMatch = invDate >= startOfQuarter;
          break;
        case 'This Year':
          dateMatch = invDate >= startOfYear;
          break;
        default:
          dateMatch = true;
      }

      const searchMatch = searchQuery === '' || (
        (invoice.invoice_number?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (getCustomerDetail(invoice, 'name')?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (getCustomerDetail(invoice, 'company')?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (calculateTotal(invoice).toString().includes(searchQuery))
      );

      return clientMatch && statusMatch && dateMatch && searchMatch;
    });
  };


  const formatCurrency = (amount) => {
    return Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const calculateTotal = (invoice) => {
    if (!invoice.items || !Array.isArray(invoice.items)) {
      return 0;
    }
    
    return invoice.items.reduce((sum, item) => {
      const itemTotal = Number(item.total) || 0;
      return sum + itemTotal;
    }, 0);
  };

  const calculateTotalInvoiceValue = (invoices) => {
    return invoices.reduce((sum, invoice) => sum + calculateTotal(invoice), 0);
  };

  const calculateDueInvoiceValue = (invoices) => {
    return invoices
      .filter(invoice => invoice.status === 'Pending' || invoice.status === 'Overdue')
      .reduce((sum, invoice) => sum + calculateTotal(invoice), 0);
  };

  const handleCreateInvoice = () => {
    navigate('/admin/invoices/create');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterMenuOpen(true);
  };

  const handleFilterClose = (value) => {
    if (value) {
      setFilter(value);
    }
    setAnchorEl(null);
  };

  const summaryCards = (
    <Grid container spacing={3} className={styles.summaryContainer}>
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#FF4842' }}>
              <ClockIcon size={24} weight="light" />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(dueInvoiceValue)}</h3>
              <p className={styles.cardLabel}>Overdue amount</p>
            </div>
          </div>
        </div>
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#54D62C' }}>
              <ArticleIcon size={24} weight="light" />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(draftedTotal)}</h3>
              <p className={styles.cardLabel}>Drafted totals</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#FFC107' }}>
              <MoneyIcon size={24} weight="light" />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(unpaidTotal)}</h3>
              <p className={styles.cardLabel}>Unpaid totals</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#00AB55' }}>
              <TimerIcon size={24} weight="light" />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>{averagePaymentTime} days</h3>
              <p className={styles.cardLabel}>Average paid time</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );

  const handleStatusSort = () => {
    setStatusSort(current => {
      if (!current) return 'asc';
      if (current === 'asc') return 'desc';
      return null;
    });
  };

  const headerCells = [
    { 
      label: 'CUSTOMER', 
      align: 'center',
      icon: <UserIcon size={20} weight="light" style={{ color: '#FF4842' }} />,
      sortable: true,
      onClick: () => setSort(sort === 'customerAsc' ? 'customerDesc' : 'customerAsc'),
      sortDirection: sort === 'customerAsc' ? 'asc' : sort === 'customerDesc' ? 'desc' : null
    },
    { 
      label: 'COMPANY', 
      align: 'center',
      icon: <BuildingsIcon size={20} weight="light" style={{ color: '#00AB55' }} />,
      sortable: true,
      onClick: () => {
        setSort(sort === 'companyAsc' ? 'companyDesc' : 'companyAsc');
      },
      sortDirection: sort === 'companyAsc' ? 'asc' : sort === 'companyDesc' ? 'desc' : null
    },
    { 
      label: 'NUMBER', 
      align: 'center',
      icon: <FilesIcon size={20} weight="light" style={{ color: '#2065D1' }} />
    },
    { 
      label: 'DATE', 
      align: 'center',
      icon: <CalendarIcon size={20} weight="light" style={{ color: '#FFC107' }} />,
      sortable: true,
      onClick: () => {
        setSort(sort === 'newest' ? 'oldest' : 'newest');
      },
      sortDirection: sort === 'newest' ? 'desc' : sort === 'oldest' ? 'asc' : null
    },
    { 
      label: 'TOTAL', 
      align: 'center',
      icon: <CurrencyDollarIcon size={20} weight="light" style={{ color: '#00AB55' }} />
    },
    { 
      label: 'STATUS', 
      align: 'center',
      icon: <CheckCircleIcon size={20} weight="light" style={{ color: '#00AB55' }} />,
      sortable: true,
      onClick: handleStatusSort,
      sortDirection: statusSort
    },
    { 
      label: '', 
      align: 'center',
      icon: null
    },
  ];

  const renderTableRows = () => {
    return sortedInvoices.map((invoice) => (
      <TableRow
        key={invoice.id}
        className={styles.tableRow}
        onClick={() => navigate(`/admin/invoices/${invoice.id}`)}
      >
        <TableCell align="center">{getCustomerDetail(invoice, 'name')}</TableCell>
        <TableCell align="center">{getCustomerDetail(invoice, 'company')}</TableCell>
        <TableCell align="center">#{invoice.invoice_number || 'N/A'}</TableCell>
        <TableCell align="center">
          {invoice.issue_date ? new Date(invoice.issue_date).toLocaleDateString() : 'N/A'}
        </TableCell>
        <TableCell align="center">
          ${formatCurrency(calculateTotal(invoice))}
        </TableCell>
        <TableCell align="center">
          <div 
            className={styles.statusChip}
            style={{
              color: STATUS_STYLES[invoice.status?.toLowerCase() || 'draft']?.color,
              backgroundColor: STATUS_STYLES[invoice.status?.toLowerCase() || 'draft']?.backgroundColor,
              padding: '4px 8px',
              borderRadius: '6px',
              fontSize: '0.75rem',
              fontWeight: 700,
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              letterSpacing: '0.5px',
              display: 'inline-block'
            }}
          >
            {capitalizeFirstLetter(invoice.status || 'awaiting payment')}
          </div>
        </TableCell>
        <TableCell align="center">
          <IconButton size="small">
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    switch (status.toLowerCase()) {
      case 'paid': return 'success';
      case 'due': return 'warning';
      case 'overdue': return 'error';
      case 'awaiting payment': return 'info';
      case 'draft': return 'default';
      default: return 'default';
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortedInvoices = [...filteredInvoices].sort((a, b) => {
    console.log('Sorting invoices with method:', sort);
    
    switch (sort) {
      case 'customerAsc':
        return (getCustomerDetail(a, 'name') || '').localeCompare(getCustomerDetail(b, 'name') || '');
      case 'customerDesc':
        return (getCustomerDetail(b, 'name') || '').localeCompare(getCustomerDetail(a, 'name') || '');
      case 'companyAsc':
        return (getCustomerDetail(a, 'company') || '').localeCompare(getCustomerDetail(b, 'company') || '');
      case 'companyDesc':
        return (getCustomerDetail(b, 'company') || '').localeCompare(getCustomerDetail(a, 'company') || '');
      case 'newest':
        return new Date(b.issue_date) - new Date(a.issue_date);
      case 'oldest':
        return new Date(a.issue_date) - new Date(b.issue_date);
      default:
        return 0;
    }
  });

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  useEffect(() => {
    const button = buttonRefs.current['all'];
    const container = containerRef.current?.querySelector('[role="group"]');
    
    if (button && container) {
      const offset = button.offsetLeft;
      const width = button.offsetWidth;
      
      container.style.setProperty('--highlight-offset', `${offset}px`);
      container.style.setProperty('--highlight-width', `${width}px`);
    }
  }, []);

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="xl" className={styles.mainContainer}>
        <div className={styles.invoicesContainer}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="h5" className={styles.title}>Invoices</Typography>
            <div className={styles.createButton}>
              <span className={styles.separator}></span>
              <Button
                variant="contained"
                color="primary"
                disableRipple
                startIcon={<PlusIcon size={20} weight="bold" />}
                onClick={handleCreateInvoice}
                className={styles.createButton}
                sx={{
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#3c50fa !important',
                    boxShadow: 'none !important',
                  },
                  '&.MuiButton-contained:hover': {
                    backgroundColor: '#3c50fa',
                    boxShadow: 'none',
                  }
                }}
              >
                Create Invoice
              </Button>
            </div>
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <div className={styles.leftSection}>
 
            </div>

            <div className={styles.rightSection}>
              <div className={styles.sliderContainer}>
                {canScrollLeft && (
                  <IconButton 
                    className={styles.scrollButton}
                    onClick={() => handleScroll('left')}
                    size="small"
                  >
                    <CaretLeftIcon size={20} weight="bold" />
                  </IconButton>
                )}
                
                <div 
                  ref={containerRef} 
                  className={styles.toggleContainer}
                  onScroll={checkScroll}
                >
                  <CustomToggle
                    options={statusOptions}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  />
                </div>

                {canScrollRight && (
                  <IconButton 
                    className={styles.scrollButton}
                    onClick={() => handleScroll('right')}
                    size="small"
                  >
                    <CaretRightIcon size={20} weight="bold" />
                  </IconButton>
                )}
              </div>
            </div>
          </Box>

          <Box mb={3}>
            <SearchContainer
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search invoices..."
              width="20%"
            />
          </Box>

          <TableContainer className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerCells.map((cell, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      className={styles.tableHeaderCell}
                      onClick={cell.onClick}
                      style={{ 
                        cursor: cell.sortable ? 'pointer' : 'default',
                        backgroundColor: 'var(--background-secondary)',
                        borderBottom: '1px solid #e0e0e0 !important',
                        fontFamily: 'Urbanist, sans-serif',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        color: 'var(--text-secondary)!important',
                        letterSpacing: '0.5px',
                        padding: '12px 16px',
                      }}
                    >
                      <Box display="flex" alignItems="center" justifyContent="center">
                        {cell.icon}
                        <Typography 
                          sx={{ 
                            ml: 1,
                            fontFamily: 'Urbanist, sans-serif',
                            fontSize: '0.75rem',
                            fontWeight: 600,
                            color: 'var(--text-secondary)!important',
                            letterSpacing: '0.5px',
                          }}
                        >
                          {cell.label}
                        </Typography>
                        {cell.sortable && (
                          <Box display="flex" flexDirection="column" ml={1} sx={{ lineHeight: 0 }}>
                            <CaretUpIcon 
                              size={12}
                              weight="bold"
                              style={{ 
                                color: cell.sortDirection === 'asc' 
                                  ? 'var(--primary-main)'
                                  : 'var(--text-disabled)'
                              }} 
                            />
                            <CaretDownIcon 
                              size={12}
                              weight="bold"
                              style={{ 
                                marginTop: '-4px',
                                color: cell.sortDirection === 'desc' 
                                  ? 'var(--primary-main)'
                                  : 'var(--text-disabled)'
                              }} 
                            />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableRows()}
              </TableBody>
            </Table>
          </TableContainer>
         </div>
      </Container>
    </LocalizationProvider>
  );
};

export default AdminInvoices;

import React, { useEffect, useRef } from 'react';
import { Typography, Paper } from '@mui/material';
import { format, isToday, isSameDay } from 'date-fns';
import styles from '../Calendar.module.scss';
import DayEventCard from './DayEventCard';

const DayView = ({ events, selectedDate, onEventUpdated }) => {
  // Create time slots using simple 24-hour format
  const timeSlots = [...Array(48)].map((_, i) => {
    const totalMinutes = i * 30; // Each slot is 30 minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return { hours, minutes, timeString };
  });
  
  const gridRef = useRef(null);

  useEffect(() => {
    const scrollToCurrentTime = () => {
      if (gridRef.current) {
        const now = new Date();

        // Get the current time in Eastern Time Zone (New York)
        const formatter = new Intl.DateTimeFormat('en-US', {
          timeZone: 'America/New_York',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        });
        const formattedTime = formatter.format(now).split(':');
        let hours = parseInt(formattedTime[0]);
        let minutes = parseInt(formattedTime[1]);

        // Calculate the scroll position in rem
        const positionInRem = hours * 4 + minutes / 15;

        // Convert rem to pixels (adjust the multiplier if 1rem is not equal to 16px)
        const positionInPixels = positionInRem * 16;

        // Center the current time in the viewport
        const offset = gridRef.current.clientHeight / 2;

        console.log('Scrolling to (ET):', `${hours}:${minutes}`);

        // Scroll to the calculated position
        gridRef.current.scrollTop = Math.max(0, positionInPixels - offset);
      }
    };

    if (isToday(selectedDate)) {
      scrollToCurrentTime();
    }
  }, [selectedDate]);

  const formatTimeSlot = (hours, minutes) => {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return format(date, 'h:mm a');
  };

  const filteredEvents = events.filter((event) => {
    if (event.time_slots && event.time_slots.length > 0) {
      return isSameDay(new Date(event.time_slots[0].start_time), selectedDate);
    }
    return false;
  });

  return (
    <Paper elevation={0} className={styles.dayViewContainer}>
      <div className={styles.dayViewHeader}>
        <div className={styles.timeGutterHeader}>
          <Typography variant="subtitle2">Time</Typography>
        </div>
        <div className={`${styles.dayHeaderContent} ${isToday(selectedDate) ? styles.today : ''}`}>
          <div className={styles.dateStack}>
            <Typography className={styles.dayName}>
              {format(selectedDate, 'EEEE')}
            </Typography>
            <Typography className={styles.dayNumber}>
              {format(selectedDate, 'd')}
            </Typography>
            <Typography className={styles.monthName}>
              {format(selectedDate, 'MMMM yyyy')}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.dayViewGrid} ref={gridRef}>
        <div className={styles.timeGutter}>
          {timeSlots.map((slot, index) => (
            <div key={index} className={styles.timeSlot}>
              <Typography className={styles.timeText}>
                {formatTimeSlot(slot.hours, slot.minutes)}
              </Typography>
            </div>
          ))}
        </div>
        <div className={styles.dayContent}>
          {timeSlots.map((slot, index) => {
            const slotEvents = filteredEvents
              .filter((event) => {
                if (event.time_slots && event.time_slots.length > 0) {
                  const eventStartTime = new Date(event.time_slots[0].start_time);
                  const slotStart = new Date(selectedDate);
                  slotStart.setHours(slot.hours, slot.minutes, 0, 0);
                  const slotEnd = new Date(slotStart);
                  slotEnd.setMinutes(slotStart.getMinutes() + 30);
                  return eventStartTime >= slotStart && eventStartTime < slotEnd;
                }
                return false;
              })
              .sort((a, b) => {
                const startA = new Date(a.time_slots[0].start_time);
                const startB = new Date(b.time_slots[0].start_time);
                return startA - startB;
              });

            return (
              <div key={index} className={styles.timeSlot}>
                <div className={styles.hourGuide} />
                {slot.minutes === 30 && <div className={styles.halfHourGuide} />}
                {slotEvents.map((event) => (
                  <DayEventCard 
                    key={event.id} 
                    event={event} 
                    onEventUpdated={onEventUpdated}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </Paper>
  );
};

export default DayView; 
import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, Avatar, Chip, Tooltip } from '@mui/material';

const ClockedInWidget = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Enhanced dummy data
  const clockedInEmployees = [
    {
      id: 1,
      name: "Sarah Johnson",
      client: "Oceanview Resort",
      project: "Pool Renovation",
      clockedInAt: new Date(new Date().setHours(8, 30, 0)),
    },
    {
      id: 2,
      name: "Mike Chen",
      client: "Sunset Marina",
      project: "Dock Repair",
      clockedInAt: new Date(new Date().setHours(9, 0, 0)),
    },
    {
      id: 3,
      name: "Alex Rodriguez",
      client: "Palm Beach Hotel",
      project: "Beach Access",
      clockedInAt: new Date(new Date().setHours(8, 45, 0)),
    },
    {
      id: 4,
      name: "Emma Wilson",
      client: "Coastal Condos",
      project: "Lobby Remodel",
      clockedInAt: new Date(new Date().setHours(8, 15, 0)),
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const formatTime = (diff) => {
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Paper
      elevation={0}
      sx={{
        background: 'var(--background-paper)',
        border: '1px solid var(--divider)',
        borderRadius: 2,
        p: 2,
        height: '100%'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Clocked In Employees</Typography>
        <Chip 
          label={clockedInEmployees.length}
          size="small"
          sx={{ 
            ml: 'auto',
            backgroundColor: 'var(--primary-lighter)',
            color: 'var(--primary-main)',
            fontWeight: 600
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {clockedInEmployees.map((employee, index) => (
          <Box
            key={employee.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              p: 1.5,
              borderRadius: 1,
              backgroundColor: 'var(--background-default)',
              border: '1px solid var(--divider)',
            }}
          >
            <Tooltip title={employee.name}>
              <Avatar
                sx={{
                  width: 36,
                  height: 36,
                  fontSize: '0.875rem',
                  bgcolor: `hsl(${(index * 90) % 360}, 70%, 50%)`,
                  border: '2px solid white',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.2s'
                  }
                }}
              >
                {getInitials(employee.name)}
              </Avatar>
            </Tooltip>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
                {employee.name}
              </Typography>
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                {employee.client} • {employee.project}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f4f4f4',
                borderRadius: 1.5,
                px: 2,
                py: 1,
                minWidth: '110px',
                border: '1px solid var(--primary-lighter)'
              }}
            >
              <Typography 
                sx={{ 
                  fontFamily: '"SF Mono", "Roboto Mono", monospace',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: 'var(--primary-main)',
                  letterSpacing: '0.5px',
                  textAlign: 'center',
                  lineHeight: 1,
                }}
              >
                {formatTime(currentTime - employee.clockedInAt)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ClockedInWidget; 
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { Box, Typography } from '@mui/material';

const TimesheetsGraph = ({ timesheets }) => {
  // Process timesheets data for the graph
  const processData = () => {
    // Group timesheets by date and calculate total hours
    const groupedData = timesheets.reduce((acc, timesheet) => {
      const date = new Date(timesheet.clock_in).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = {
          date,
          hours: 0,
          payment: 0
        };
      }
      acc[date].hours += timesheet.hours_worked || 0;
      acc[date].payment += timesheet.calculated_payment || 0;
      return acc;
    }, {});

    // Convert to array and sort by date
    return Object.values(groupedData).sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    );
  };

  const data = processData();

  return (
    <Box sx={{ 
      height: 500, 
      p: 3,
      backgroundColor: 'var(--background-paper)',
      borderRadius: '12px',
    }}>
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 3,
          fontFamily: 'Urbanist',
          fontWeight: 600,
          color: 'var(--text-primary)'
        }}
      >
        Timesheet Analytics
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis 
            dataKey="date" 
            stroke="var(--text-secondary)"
            style={{
              fontFamily: 'Urbanist',
              fontSize: '12px'
            }}
          />
          <YAxis 
            yAxisId="left"
            stroke="#4a5df9"
            style={{
              fontFamily: 'Urbanist',
              fontSize: '12px'
            }}
            label={{ 
              value: 'Hours', 
              angle: -90, 
              position: 'insideLeft',
              style: {
                fontFamily: 'Urbanist',
                fill: '#4a5df9'
              }
            }}
          />
          <YAxis 
            yAxisId="right"
            orientation="right"
            stroke="#7635dc"
            style={{
              fontFamily: 'Urbanist',
              fontSize: '12px'
            }}
            label={{ 
              value: 'Payment ($)', 
              angle: 90, 
              position: 'insideRight',
              style: {
                fontFamily: 'Urbanist',
                fill: '#7635dc'
              }
            }}
          />
          <Tooltip 
            contentStyle={{
              backgroundColor: 'var(--background-paper)',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              fontFamily: 'Urbanist'
            }}
          />
          <Legend 
            wrapperStyle={{
              fontFamily: 'Urbanist'
            }}
          />
          <Line 
            yAxisId="left"
            type="monotone" 
            dataKey="hours" 
            name="Hours Worked"
            stroke="#4a5df9" 
            strokeWidth={2}
            dot={{ r: 4 }}
            activeDot={{ r: 8 }}
          />
          <Line 
            yAxisId="right"
            type="monotone" 
            dataKey="payment" 
            name="Payment"
            stroke="#7635dc" 
            strokeWidth={2}
            dot={{ r: 4 }}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TimesheetsGraph; 
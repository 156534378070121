import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress, Button, FormControl, Select, MenuItem } from '@mui/material';
import { 
  Clock as AccessTimeIcon,
  ClockClockwise as EventNoteIcon,
  Hourglass as HourglassEmptyIcon,
  Briefcase as WorkOutlineIcon,
  CurrencyDollar as AttachMoneyIcon,
  CalendarCheck as CalendarTodayIcon,
  CheckCircle as CheckCircleOutlineIcon,
  CaretDown,
  FunnelSimple as FilterIcon,
  X as CloseIcon
} from "@phosphor-icons/react";
import { getEmployeeTimesheets } from '../services/api';
import styles from './EmployeeTimesheets.module.scss';
import { formatDateTime } from '../utils/dateUtils';
import useMediaQuery from '@mui/material/useMediaQuery';

const EmployeeTimesheets = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [projects, setProjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState('all');
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [showCustomDates, setShowCustomDates] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  useEffect(() => {
    fetchTimesheets();
  }, []);

  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      const response = await getEmployeeTimesheets();
      if (response.timesheets && Array.isArray(response.timesheets)) {
        setTimesheets(response.timesheets);
        const uniqueProjects = [...new Set(response.timesheets.map(ts => ts.event.project_name))];
        setProjects(['All', ...uniqueProjects]);
      } else {
        throw new Error('Invalid data received from server');
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching timesheets:', err);
      setError('Failed to fetch timesheets. Please try again later.');
      setTimesheets([]);
    } finally {
      setLoading(false);
      setTimeout(() => setIsLoaded(true), 100);
    }
  };

  const filteredTimesheets = timesheets.filter(timesheet => {
    const projectMatch = filter === 'All' || filter === '' || timesheet.event.project_name === filter;
    const dateMatch = (!startDate || new Date(timesheet.clock_in) >= new Date(startDate.setHours(0, 0, 0, 0))) &&
                     (!endDate || new Date(timesheet.clock_in) <= new Date(endDate.setHours(23, 59, 59, 999)));
    return projectMatch && dateMatch;
  });

  const dateRangeOptions = [
    { value: 'lastWeek', label: 'Last week' },
    { value: 'last30', label: 'Last 30 days' },
    { value: 'lastMonth', label: 'Last month' },
    { value: 'thisYear', label: 'This year' },
    { value: 'last12Months', label: 'Last 12 months' },
    { value: 'custom', label: 'Custom range' }
  ];

  const handleDateRangeChange = (event) => {
    const value = event.target.value;
    setDateRange(value);
    setShowCustomDates(value === 'custom');

    if (value !== 'custom') {
      const now = new Date();
      let start = null;
      let end = null;

      switch (value) {
        case 'lastWeek':
          start = new Date(now);
          start.setDate(now.getDate() - 7);
          break;
        case 'last30':
          start = new Date(now);
          start.setDate(now.getDate() - 30);
          break;
        case 'lastMonth':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 0);
          break;
        case 'thisYear':
          start = new Date(now.getFullYear(), 0, 1);
          break;
        case 'last12Months':
          start = new Date(now);
          start.setFullYear(now.getFullYear() - 1);
          break;
        default:
          start = null;
          end = null;
      }

      if (start) start.setHours(0, 0, 0, 0);
      if (!end) end = new Date(now.setHours(23, 59, 59, 999));
      
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handleCustomDateSubmit = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setDateRange('custom');
    }
    setFilterDrawerOpen(false);
  };

  const handleCustomDateCancel = () => {
    setTempStartDate(null);
    setTempEndDate(null);
    setFilterDrawerOpen(false);
    if (dateRange === 'custom' && !startDate) {
      setDateRange('all');
    }
  };

  const headerCells = [
    { icon: <AccessTimeIcon size={20} weight="light" style={{ color: '#FF4842' }} />, label: 'Clock In' },
    { icon: <EventNoteIcon size={20} weight="light" style={{ color: '#2065D1' }} />, label: 'Clock Out' },
    { icon: <HourglassEmptyIcon size={20} weight="light" style={{ color: '#FFC107' }} />, label: 'Hours' },
    { icon: <WorkOutlineIcon size={20} weight="light" style={{ color: '#00AB55' }} />, label: 'Project' },
    { icon: <AttachMoneyIcon size={20} weight="light" style={{ color: '#2ecc71' }} />, label: 'Payment' }
  ];

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;
  if (timesheets.length === 0) return <div className={styles.errorContainer}><Typography>No timesheets found.</Typography></div>;

  return (
    <div className={`${styles.timesheetsContainer} ${isLoaded ? styles.fadeIn : ''}`}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        gap: 2,
        paddingLeft: 3,
        paddingRight: 1,
        mb: 3,
        pb: 1,
        flexWrap: 'wrap',
        fontFamily: '"Noto Sans", sans-serif',
      }}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <FormControl sx={{ minWidth: { xs: '100%', sm: 150 } }}>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 1,
                color: 'var(--text-primary)',
                fontFamily: '"Noto Sans", sans-serif',
                fontSize: '0.75rem',
                fontWeight: 600,
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
              }}
            >
              Project
            </Typography>
            <Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              size="small"
              sx={{ 
                height: '40px',
                backgroundColor: 'var(--background-paper)',
                border: '1px solid var(--divider)',
                borderRadius: '8px',
                fontFamily: '"Noto Sans", sans-serif',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--divider)',
                  borderWidth: '1px',
                  borderStyle: 'solid'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--primary-main)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--primary-main)'
                },
                '& .MuiSelect-select': {
                  fontFamily: '"Noto Sans", sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  padding: '8px 14px'
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'var(--background-paper)',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                    '& .MuiMenuItem-root': {
                      fontFamily: '"Noto Sans", sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      }
                    }
                  }
                }
              }}
            >
              {projects.map((project) => (
                <MenuItem key={project} value={project}>{project}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <div className={styles.timesheetGrid}>
        {!isMobile && (
          <div className={styles.header}>
            {headerCells.map((cell, index) => (
              <div key={index} className={styles.headerCell}>
                {cell.icon}
                {cell.label}
              </div>
            ))}
          </div>
        )}

        {filteredTimesheets.map((timesheet) => (
          <div key={timesheet.id} className={styles.row}>
            <div className={styles.cell} data-label="Clock In">
              {formatDateTime(timesheet.clock_in)}
            </div>
            <div className={styles.cell} data-label="Clock Out">
              {timesheet.clock_out ? formatDateTime(timesheet.clock_out) : 'Not clocked out'}
            </div>
            <div className={styles.cell} data-label="Hours">
              {timesheet.hours_worked.toFixed(2)} hrs
            </div>
            <div className={styles.cell} data-label="Project">
              {timesheet.event.project_name || 'N/A'}
            </div>
            <div className={styles.cell} data-label="Payment">
              ${timesheet.calculated_payment.toFixed(2)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployeeTimesheets;

import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100vh' // Full height minus header/tabs
};

const center = {
  lat: 26.1224, // Fort Lauderdale coordinates
  lng: -80.1373
};

const mapOptions = {
  styles: [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [{"color": "#193341"}]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [{"color": "#2c5a71"}]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {"color": "#29768a"},
        {"lightness": -37}
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [{"color": "#406d80"}]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [{"color": "#406d80"}]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {"visibility": "on"},
        {"color": "#3e606f"},
        {"weight": 2}
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [{"color": "#ffffff"}]
    }
  ],
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  scrollwheel: true
};

const EmployeeMap = () => {
  return (
    <div style={{ padding: '20px' }}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          options={mapOptions}
        >
          {/* Child components, markers, info windows, etc. will be added here */}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default EmployeeMap; 
import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  CircularProgress, 
  Typography,
  Box,
  InputAdornment  // Add this import
} from '@mui/material';
import { formatDateTime } from '../utils/dateUtils';
import { getEventDetails, updateEvent } from '../services/api';
import EventEmployeeAssignment from './EventEmployeeAssignment';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Helper function to format date for datetime-local input
const formatForDateTimeLocal = (dateString) => {
  if (!dateString) return '';
  
  try {
    const date = new Date(dateString + 'Z');
    return date.toISOString().slice(0, 16); // Format as YYYY-MM-DDThh:mm
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

const EditEvent = ({ eventId, open, onClose, onEventUpdated }) => {
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      if (!eventId) return;
      setLoading(true);
      try {
        const details = await getEventDetails(eventId);
        console.log('Fetched event details:', details);
        console.log('Time slots:', details.time_slots);
        setEventDetails(details);
      } catch (err) {
        console.error('Error fetching event details:', err);
        setError('Failed to load event details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchEventDetails();
    }
  }, [eventId, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'value') {
      setEventDetails(prev => ({ ...prev, [name]: parseFloat(value) }));
    } else {
      setEventDetails(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleTimeSlotChange = (index, field, value) => {
    if (!value) return;

    const newTimeSlots = [...eventDetails.time_slots];
    const utcString = new Date(value).toISOString().slice(0, -8);
    
    console.log('Time conversion:', {
      input: value,
      localTime: value.toLocaleString(),
      utcString: utcString
    });
    
    newTimeSlots[index] = {
      ...newTimeSlots[index],
      [field]: utcString
    };
    
    setEventDetails(prev => ({
      ...prev,
      time_slots: newTimeSlots
    }));
  };

  const handleSubmit = async () => {
    try {
      // No need to convert times as they're already in UTC
      const updatedEventDetails = {
        ...eventDetails,
        value: parseFloat(eventDetails.value) || null
      };
      
      console.log('Submitting event with times:', {
        original: eventDetails.time_slots,
        submitting: updatedEventDetails.time_slots
      });

      await updateEvent(eventId, updatedEventDetails);
      onEventUpdated(updatedEventDetails);
      onClose();
    } catch (error) {
      console.error('Error updating event:', error);
      setError('Failed to update event. Please try again.');
    }
  };

  const handleAssignmentUpdate = (updatedAssignments) => {
    // Handle the updated assignments if needed
    console.log('Updated assignments:', updatedAssignments);
  };

  if (!open) return null;
  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          padding: '16px',
        }
      }}
    >
      <DialogTitle sx={{ 
        fontSize: '1.5rem', 
        fontWeight: 600,
        borderBottom: '1px solid #e0e0e0',
        pb: 2
      }}>
        Edit Event: {eventDetails?.title}
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2,
          '& .MuiTextField-root': {
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: '#e0e0e0',
                borderWidth: '2px',
              },
              '&:hover fieldset': {
                borderColor: '#1976d2',
              },
            },
          }
        }}>
          <TextField
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            value={eventDetails?.title || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={eventDetails?.description || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="emoji"
            label="Emoji"
            value={eventDetails?.emoji || ''}
            onChange={handleInputChange}
            style={{ width: '60px', marginRight: '8px' }}
          />
          {eventDetails?.addresses && eventDetails.addresses.map((addr, index) => (
            <TextField
              key={index}
              margin="dense"
              name={`addresses[${index}].address`}
              label={`Address ${index + 1}`}
              type="text"
              fullWidth
              value={addr.address}
              onChange={handleInputChange}
            />
          ))}
          {eventDetails?.time_slots && eventDetails.time_slots.map((slot, index) => (
            <React.Fragment key={index}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label={`Start Time ${index + 1}`}
                  value={new Date(slot.start_time)}
                  onChange={(newValue) => handleTimeSlotChange(index, 'start_time', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                  ampm={true}
                  format="MM/dd/yyyy, hh:mm a"
                />
                <DateTimePicker
                  label={`End Time ${index + 1}`}
                  value={new Date(slot.end_time)}
                  onChange={(newValue) => handleTimeSlotChange(index, 'end_time', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                  ampm={true}
                  format="MM/dd/yyyy, hh:mm a"
                />
              </LocalizationProvider>
            </React.Fragment>
          ))}
          <TextField
            label="Event Value"
            type="number"
            value={eventDetails.value || ''}
            onChange={(e) => setEventDetails({ ...eventDetails, value: parseFloat(e.target.value) })}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Box>
        
        <Typography variant="h6" gutterBottom>
          Assign Employees
        </Typography>
        <EventEmployeeAssignment 
          projectId={eventDetails?.project_id} 
          onAssignmentUpdate={handleAssignmentUpdate}
        />
      </DialogContent>
      <DialogActions sx={{ 
        borderTop: '1px solid #e0e0e0',
        padding: '16px'
      }}>
        <Button 
          onClick={onClose}
          sx={{ 
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 600
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained"
          sx={{ 
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 600,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            }
          }}
        >
          Update Event
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEvent;
import React, { useState } from 'react';
import { Box, Grid, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { 
  Gear as AppSettingsIcon,
  Clock as TimeIcon,
  Bell as NotificationIcon,
  PaintBrush as ThemeIcon,
  Phone as PhoneIcon,
  ChatText as SmsIcon,
  Users as UsersIcon,
  ArrowRight as ArrowRightIcon,
  Globe as GlobeIcon
} from "@phosphor-icons/react";
import { useTheme } from '../ThemeContext';
import styles from './AdminSettings.module.scss';

const timeZones = [
  'UTC-12:00', 'UTC-11:00', 'UTC-10:00', 'UTC-09:00', 'UTC-08:00', 'UTC-07:00', 'UTC-06:00', 'UTC-05:00', 
  'UTC-04:00', 'UTC-03:00', 'UTC-02:00', 'UTC-01:00', 'UTC+00:00', 'UTC+01:00', 'UTC+02:00', 'UTC+03:00', 
  'UTC+04:00', 'UTC+05:00', 'UTC+06:00', 'UTC+07:00', 'UTC+08:00', 'UTC+09:00', 'UTC+10:00', 'UTC+11:00', 
  'UTC+12:00', 'UTC+13:00', 'UTC+14:00'
];

function AdminSettingsAppSettings() {
  const { darkMode, toggleDarkMode } = useTheme();
  const [openNumbersModal, setOpenNumbersModal] = useState(false);
  
  const phoneStats = {
    callsThisMonth: 245,
    minutesUsed: 1234
  };
  
  const smsStats = {
    sentThisMonth: 1876,
    receivedThisMonth: 943
  };

  const dummyPhoneNumbers = [
    { number: '+1 (555) 123-4567', type: 'Local', price: '$1.00/month', area: 'New York, NY' },
    { number: '+1 (555) 234-5678', type: 'Toll-Free', price: '$2.00/month', area: 'USA & Canada' },
    { number: '+1 (555) 345-6789', type: 'Local', price: '$1.00/month', area: 'Los Angeles, CA' },
    { number: '+1 (555) 456-7890', type: 'Local', price: '$1.00/month', area: 'Chicago, IL' },
    { number: '+1 (555) 567-8901', type: 'Toll-Free', price: '$2.00/month', area: 'USA & Canada' },
  ];

  const handleOpenModal = () => setOpenNumbersModal(true);
  const handleCloseModal = () => setOpenNumbersModal(false);

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      p: 3
    }}>
      <Box sx={{
        width: '100%',
        p: 3,
        borderRadius: '12px',
        backgroundColor: 'var(--background-paper)',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 1 }}>
          <ThemeIcon size={22} weight="light" />
          <Typography variant="h6" sx={{ 
            fontFamily: 'Urbanist',
            fontWeight: 600,
            color: 'var(--text-primary)'
          }}>
            Theme Settings
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ 
            fontFamily: 'Urbanist',
            color: 'var(--text-secondary)',
            mb: 1,
            fontSize: '0.875rem'
          }}>
            Theme Mode
          </Typography>
          <FormControl sx={{ maxWidth: '300px' }}>
            <Select
              value={darkMode ? "dark" : "light"}
              onChange={(e) => toggleDarkMode(e.target.value === "dark")}
              size="small"
              sx={{ 
                height: '40px',
                backgroundColor: 'var(--background-paper)',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e0e0e0',
                  borderWidth: '1px',
                  borderStyle: 'solid'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--border-hover)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4a5df9'
                },
                '& .MuiSelect-select': {
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  padding: '8px 14px'
                },
                '& .MuiSelect-icon': {
                  color: 'var(--text-secondary)'
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'var(--background-paper)',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                    '& .MuiMenuItem-root': {
                      fontFamily: 'Urbanist, sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--background-secondary)'
                      }
                    }
                  }
                }
              }}
            >
              <MenuItem value="light">Light Mode</MenuItem>
              <MenuItem value="dark">Dark Mode</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Divider sx={{ 
        borderColor: 'var(--divider)',
        width: '100%',
        my: 1
      }} />

      <Box sx={{
        width: '100%',
        p: 3,
        borderRadius: '12px',
        backgroundColor: 'var(--background-paper)',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 1 }}>
          <TimeIcon size={22} weight="light" />
          <Typography variant="h6" sx={{ 
            fontFamily: 'Urbanist',
            fontWeight: 600,
            color: 'var(--text-primary)'
          }}>
            Time Zone Settings
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ 
              fontFamily: 'Urbanist',
              color: 'var(--text-secondary)',
              mb: 1,
              fontSize: '0.875rem'
            }}>
              Time Zone
            </Typography>
            <FormControl fullWidth>
              <Select
                defaultValue="UTC-05:00"
                size="small"
                sx={{ 
                  height: '40px',
                  backgroundColor: 'var(--background-paper)',
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e0e0e0',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--border-hover)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#4a5df9'
                  }
                }}
              >
                {timeZones.map((zone) => (
                  <MenuItem key={zone} value={zone}>{zone}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ 
        borderColor: 'var(--divider)',
        width: '100%',
        my: 1
      }} />

      <Box sx={{
        width: '100%',
        p: 3,
        borderRadius: '12px',
        backgroundColor: 'var(--background-paper)',
      }}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, gap: 1, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PhoneIcon size={22} weight="light" />
            <Typography variant="h6" sx={{ 
              fontFamily: 'Urbanist',
              fontWeight: 600,
              color: 'var(--text-primary)'
            }}>
              Phone Settings
            </Typography>
          </Box>
          <Button
            variant="outlined"
            onClick={handleOpenModal}
            startIcon={<UsersIcon size={20} weight="light" />}
            endIcon={<ArrowRightIcon size={20} weight="light" />}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              color: '#4a5df9',
              borderColor: '#4a5df9',
              '&:hover': {
                borderColor: '#3544b8',
                backgroundColor: 'rgba(74, 93, 249, 0.08)'
              }
            }}
          >
            Show Numbers
          </Button>
        </Box>

        {/* Stats Cards */}
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(2, 1fr)', 
          gap: 3,
          mb: 4 
        }}>
          <Box sx={{
            p: 3,
            borderRadius: '10px',
            backgroundColor: 'var(--background-default)',
            border: '1px solid var(--divider)',
          }}>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-secondary)',
              fontSize: '0.875rem',
              mb: 1 
            }}>
              Calls This Month
            </Typography>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-primary)',
              fontSize: '2rem',
              fontWeight: 600 
            }}>
              {phoneStats.callsThisMonth}
            </Typography>
          </Box>

          <Box sx={{
            p: 3,
            borderRadius: '10px',
            backgroundColor: 'var(--background-default)',
            border: '1px solid var(--divider)',
          }}>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-secondary)',
              fontSize: '0.875rem',
              mb: 1 
            }}>
              Minutes Used
            </Typography>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-primary)',
              fontSize: '2rem',
              fontWeight: 600 
            }}>
              {phoneStats.minutesUsed}
            </Typography>
          </Box>
        </Box>

        {/* Default Phone Number Selector */}
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ 
            fontFamily: 'Urbanist',
            color: 'var(--text-secondary)',
            mb: 2,
            fontSize: '0.875rem',
            fontWeight: 500
          }}>
            Default Phone Number
          </Typography>
          <FormControl fullWidth>
            <Select
              defaultValue="+1234567890"
              size="small"
              sx={{ 
                height: '45px',
                backgroundColor: 'var(--background-default)',
                border: '1px solid var(--divider)',
                borderRadius: '8px',
                transition: 'all 0.2s ease',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '&:hover': {
                  borderColor: '#4a5df9',
                  backgroundColor: 'rgba(74, 93, 249, 0.04)'
                },
                '&.Mui-focused': {
                  borderColor: '#4a5df9',
                  backgroundColor: 'rgba(74, 93, 249, 0.04)'
                },
                '& .MuiSelect-select': {
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  padding: '8px 14px'
                }
              }}
            >
              {dummyPhoneNumbers.map((phone) => (
                <MenuItem value={phone.number} key={phone.number}>
                  {phone.number} ({phone.type})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Divider sx={{ 
        borderColor: 'var(--divider)',
        width: '100%',
        my: 1
      }} />

      <Box sx={{
        width: '100%',
        p: 3,
        borderRadius: '12px',
        backgroundColor: 'var(--background-paper)',
      }}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, gap: 1, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SmsIcon size={22} weight="light" />
            <Typography variant="h6" sx={{ 
              fontFamily: 'Urbanist',
              fontWeight: 600,
              color: 'var(--text-primary)'
            }}>
              SMS Settings
            </Typography>
          </Box>
          <Button
            variant="outlined"
            startIcon={<UsersIcon size={20} weight="light" />}
            endIcon={<ArrowRightIcon size={20} weight="light" />}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              color: '#4a5df9',
              borderColor: '#4a5df9',
              '&:hover': {
                borderColor: '#3544b8',
                backgroundColor: 'rgba(74, 93, 249, 0.08)'
              }
            }}
          >
            Show Users
          </Button>
        </Box>

        {/* Stats Cards */}
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(2, 1fr)', 
          gap: 3,
          mb: 4 
        }}>
          <Box sx={{
            p: 3,
            borderRadius: '10px',
            backgroundColor: 'var(--background-default)',
            border: '1px solid var(--divider)',
          }}>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-secondary)',
              fontSize: '0.875rem',
              mb: 1 
            }}>
              Sent This Month
            </Typography>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-primary)',
              fontSize: '2rem',
              fontWeight: 600 
            }}>
              {smsStats.sentThisMonth}
            </Typography>
          </Box>

          <Box sx={{
            p: 3,
            borderRadius: '10px',
            backgroundColor: 'var(--background-default)',
            border: '1px solid var(--divider)',
          }}>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-secondary)',
              fontSize: '0.875rem',
              mb: 1 
            }}>
              Received This Month
            </Typography>
            <Typography sx={{ 
              fontFamily: 'Urbanist', 
              color: 'var(--text-primary)',
              fontSize: '2rem',
              fontWeight: 600 
            }}>
              {smsStats.receivedThisMonth}
            </Typography>
          </Box>
        </Box>

        {/* Default SMS Number Selector */}
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ 
            fontFamily: 'Urbanist',
            color: 'var(--text-secondary)',
            mb: 2,
            fontSize: '0.875rem',
            fontWeight: 500
          }}>
            Default SMS Number
          </Typography>
          <FormControl fullWidth>
            <Select
              defaultValue="+1234567890"
              size="small"
              sx={{ 
                height: '45px',
                backgroundColor: 'var(--background-default)',
                border: '1px solid var(--divider)',
                borderRadius: '8px',
                transition: 'all 0.2s ease',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '&:hover': {
                  borderColor: '#4a5df9',
                  backgroundColor: 'rgba(74, 93, 249, 0.04)'
                },
                '&.Mui-focused': {
                  borderColor: '#4a5df9',
                  backgroundColor: 'rgba(74, 93, 249, 0.04)'
                },
                '& .MuiSelect-select': {
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  padding: '8px 14px'
                }
              }}
            >
              <MenuItem value="+1234567890">+1 (234) 567-890</MenuItem>
              <MenuItem value="+1987654321">+1 (987) 654-321</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Divider sx={{ 
        borderColor: 'var(--divider)',
        width: '100%',
        my: 1
      }} />

    </Box>
  );
}

export default AdminSettingsAppSettings;
import React, { useState } from 'react';
import { Grid, Box, IconButton, Tooltip, Snackbar, Collapse } from '@mui/material';
import WelcomeMessage from './WelcomeMessage';
import TodayWidget from './dashboard/TodayWidget';
import FinancialMetricsGraph from './FinancialMetricsGraph';
import TimelineWidget from './dashboard/TimelineWidget';
import MapWidget from './dashboard/MapWidget';
import ClockedInWidget from './dashboard/ClockedInWidget';
import RecentActivitiesWidget from './dashboard/RecentActivitiesWidget';
import { Receipt, Calculator, Folder, ChartLine, Calendar, CaretDown, MapPin, Clock, ListBullets } from "@phosphor-icons/react";
import styles from './AdminDashboard.module.scss';

function AdminDashboard() {
  const [visibleWidgets, setVisibleWidgets] = useState({
    today: true,
    recentActivities: true,
    financials: true,
    timeline: true,
    map: true,
    clockedIn: true
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const actions = [
    { icon: <Calendar weight="light" />, name: 'today', label: 'Today' },
    { icon: <ListBullets weight="light" />, name: 'recentActivities', label: 'Recent Activities' },
    { icon: <ChartLine weight="light" />, name: 'financials', label: 'Financial Metrics' },
    { icon: <Calendar weight="light" />, name: 'timeline', label: 'Timeline' },
    { icon: <MapPin weight="light" />, name: 'map', label: 'Map' },
    { icon: <Clock weight="light" />, name: 'clockedIn', label: 'Clocked In' },
  ];

  const handleToggleWidget = (widgetName) => {
    setVisibleWidgets(prev => ({
      ...prev,
      [widgetName]: !prev[widgetName]
    }));
    setSnackbarMessage(`${widgetName.charAt(0).toUpperCase() + widgetName.slice(1)} widget ${!visibleWidgets[widgetName] ? 'shown' : 'hidden'}`);
    setSnackbarOpen(true);
  };

  return (
    <div className={styles.dashboardContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WelcomeMessage />
        </Grid>

        {/* Collapsible Widget Controls */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: -1 }}>
            <Tooltip title={isDrawerOpen ? "Hide controls" : "Show controls"} arrow>
              <IconButton 
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                sx={{
                  transform: isDrawerOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
              >
                <CaretDown weight="bold" size={16} />
              </IconButton>
            </Tooltip>
          </Box>
          <Collapse in={isDrawerOpen}>
            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                justifyContent: 'flex-end',
                py: 0.5,
              }}
            >
              {actions.map((action) => (
                <Tooltip key={action.name} title={`Toggle ${action.label}`} arrow>
                  <IconButton
                    onClick={() => handleToggleWidget(action.name)}
                    size="small"
                    sx={{
                      width: 28,
                      height: 28,
                      borderRadius: '6px',
                      transition: 'all 0.2s ease-in-out',
                      bgcolor: visibleWidgets[action.name] 
                        ? 'primary.main' 
                        : 'transparent',
                      color: visibleWidgets[action.name] 
                        ? 'white' 
                        : 'text.secondary',
                      '&:hover': {
                        bgcolor: visibleWidgets[action.name] 
                          ? 'primary.dark' 
                          : 'action.hover',
                        transform: 'translateY(-2px)',
                      },
                      '& svg': {
                        width: '14px',
                        height: '14px'
                      }
                    }}
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          </Collapse>
        </Grid>

        {/* Financial Metrics and Today Widget Row */}
        <Grid container item xs={12} spacing={2}>
          {visibleWidgets.financials && (
            <Grid 
              item 
              xs={12} 
              md={6} 
              order={{ xs: 1, md: 1 }}
              sx={{
                height: '100%',
                minHeight: 400,
                '& > *': { height: '100%' }
              }}
            >
              <FinancialMetricsGraph />
            </Grid>
          )}

          {visibleWidgets.today && (
            <Grid item xs={12} md={6} order={{ xs: 2, md: 2 }}>
              <TodayWidget />
            </Grid>
          )}
        </Grid>

        {/* Timeline Widget */}
        {visibleWidgets.timeline && (
          <Grid item xs={12}>
            <TimelineWidget />
          </Grid>
        )}

        {/* Recent Activities Widget - Half Width */}
        {visibleWidgets.recentActivities && (
          <Grid item xs={12} md={6}>
            <RecentActivitiesWidget />
          </Grid>
        )}

        {/* Map and Clocked In Widgets */}
        {visibleWidgets.map && (
          <Grid item xs={12} md={6}>
            <MapWidget />
          </Grid>
        )}

        {visibleWidgets.clockedIn && (
          <Grid item xs={12} md={6}>
            <ClockedInWidget />
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
}

export default AdminDashboard;

 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import SavedItemsManager from './SavedItemsManager';
import { getInvoiceById, updateInvoice, getItems } from '../services/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AdminEditInvoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(null);
  const [savedItems, setSavedItems] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('AdminEditInvoice rendered, ID:', id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log('Fetching invoice data...');
        const response = await getInvoiceById(id);
        console.log('API Response:', response);
        
        // Format the invoice data
        const formattedInvoice = {
          id: response.id,
          ...response,
          business_details: response.business_details || {},
          customer_details: response.customer_details || {},
          items: response.items || [],
          project_name: response.project_name || '',
        };

        console.log('Formatted invoice:', formattedInvoice);
        setInvoice(formattedInvoice);

        // Get saved items
        const items = await getItems();
        setSavedItems(items);
        
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load invoice data');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading invoice data...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
        <Button 
          onClick={() => navigate('/admin/invoices')}
          sx={{ mt: 2 }}
        >
          Back to Invoices
        </Button>
      </Box>
    );
  }

  if (!invoice) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>No invoice found</Typography>
      </Box>
    );
  }

  const handleAddToInvoice = (newItem, index = null) => {
    setInvoice(prev => ({
      ...prev,
      items: index !== null
        ? prev.items.map((item, i) => i === index ? newItem : item)
        : [...prev.items, newItem]
    }));
  };

  const handleRemoveItem = (index) => {
    setInvoice(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleSave = async () => {
    try {
      // Format the invoice data before sending
      const formattedInvoice = {
        ...invoice,
        invoice_number: invoice.invoice_number,
        issue_date: invoice.issue_date,
        due_date: invoice.due_date,
        project_name: invoice.project_name,
        customer_details: invoice.customer_details,
        business_details: invoice.business_details,
        items: invoice.items.map(item => ({
          ...item,
          unit_price: item.unit_price,
          quantity: Number(item.quantity)
        }))
      };

      console.log('Sending formatted invoice:', formattedInvoice);
      await updateInvoice(id, formattedInvoice);
      setShowSuccess(true);
      setTimeout(() => {
        navigate(`/admin/invoices/${id}`);
      }, 1500);
    } catch (err) {
      console.error('Error saving invoice:', err);
      setError('Failed to save changes');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        mb: 2,
        borderBottom: '1px solid #eee',
        pb: 2
      }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/admin/invoices/${id}`)}
          sx={{ 
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Back to Invoice
        </Button>
      </Box>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          mb: 3 
        }}>
          <Box sx={{ flex: 1, mr: 2 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>Edit Invoice</Typography>
            <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
              Invoice #{invoice.invoice_number}
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            onClick={handleSave}
            sx={{
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#1565c0'
              }
            }}
          >
            Save Changes
          </Button>
        </Box>

        <SavedItemsManager
          onAddToEstimate={handleAddToInvoice}
          savedItems={savedItems}
          setSavedItems={setSavedItems}
          estimateItems={invoice.items || []}
          onRemoveItem={handleRemoveItem}
        />
      </Paper>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success">
          Invoice updated successfully
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminEditInvoice; 
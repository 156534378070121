import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Box, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '../ThemeContext';
import '@fontsource/noto-sans';
import { keyframes } from '@emotion/react';
import NotificationBell from './NotificationBell';

// Phosphor Icons - Outlined variants
import {
  SquaresFour,
  Calendar,
  Users,
  MagnifyingGlass,
  Calculator,
  Hammer,
  Receipt,
  UsersThree,
  Wrench,
  ChartLine,
  Gear,
  SignOut,
  CaretLeft,
  Notebook
} from "@phosphor-icons/react";

const lineToArrowRight = keyframes`
  0% {
    height: 16px;
    width: 2px;
    transform: rotate(0deg);
    border: none;
  }
  50% {
    height: 16px;
    width: 2px;
    transform: rotate(0deg);
    border: none;
  }
  100% {
    height: 8px;
    width: 8px;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
  }
`;

const lineToArrowLeft = keyframes`
  0% {
    height: 16px;
    width: 2px;
    transform: rotate(0deg);
    border: none;
  }
  50% {
    height: 16px;
    width: 2px;
    transform: rotate(0deg);
    border: none;
  }
  100% {
    height: 8px;
    width: 8px;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: rotate(-135deg);
  }
`;

const arrowToLine = keyframes`
  0% {
    height: 8px;
    width: 8px;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
  }
  50% {
    height: 16px;
    width: 2px;
    transform: rotate(0deg);
    border: none;
  }
  100% {
    height: 16px;
    width: 2px;
    transform: rotate(0deg);
    border: none;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { darkMode } = useTheme();
  const [hover, setHover] = useState(false);
  const [notificationData, setNotificationData] = useState({
    notifications: [],
    unreadCount: 0,
    anchorEl: null
  });

  const handleNotificationsChange = React.useCallback(({ notifications, unreadCount }) => {
    setNotificationData(prev => ({
      ...prev,
      notifications,
      unreadCount
    }));
  }, []);

  const handleNotificationAnchor = React.useCallback((anchorEl) => {
    setNotificationData(prev => ({
      ...prev,
      anchorEl
    }));
  }, []);

  const tooltipTitle = React.useMemo(() => {
    return notificationData.notifications.length === 0 
      ? "No notifications" 
      : `${notificationData.unreadCount} unread notifications`;
  }, [notificationData.notifications.length, notificationData.unreadCount]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const iconProps = {
    size: 22,
    weight: "light",
    color: "#637381"
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const navItems = [
    { icon: <SquaresFour {...iconProps} />, text: 'Dashboard', path: '/admin' },
    { icon: <Calendar {...iconProps} />, text: 'Calendar', path: '/admin/calendar' },
    { icon: <Users {...iconProps} />, text: 'Team', path: '/admin/employees' },
    { type: 'divider' },
    { icon: <MagnifyingGlass {...iconProps} />, text: 'Leads', path: '/admin/leads' },
    { icon: <Calculator {...iconProps} />, text: 'Estimates', path: '/admin/estimates' },
    { icon: <Hammer {...iconProps} />, text: 'Projects', path: '/admin/projects' },
    { icon: <Receipt {...iconProps} />, text: 'Invoices', path: '/admin/invoices' },
    { icon: <UsersThree {...iconProps} />, text: 'Clients', path: '/admin/clients' },
    { icon: <Notebook {...iconProps} />, text: 'Price book', path: '/admin/price-book' },
    { type: 'divider' },
    { icon: <ChartLine {...iconProps} />, text: 'Reports', path: '/admin/reports' },
    { icon: <Gear {...iconProps} />, text: 'Settings', path: '/admin/settings' },
  ];

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: isOpen ? 240 : 50,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          bgcolor: darkMode ? 'var(--background-paper)' : '#fff',
          color: darkMode ? 'var(--text-primary)' : '#1a2027',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 0',
          fontFamily: 'Noto Sans, sans-serif',
          zIndex: 1105,
          background: darkMode ? 'rgba(45, 54, 60, 0.95)' : 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          position: 'fixed',
          left: 0,
          top: 0,
          borderRight: darkMode 
            ? '1px solid var(--border-color)' 
            : '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <List sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '4px',
          px: '8px',
        }}>
          {navItems.map((item) => (
            item.type === 'divider' ? (
              <Box 
                key={Math.random()} 
                sx={{ 
                  display: isOpen ? 'flex' : 'none',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    width: '80%',
                    height: '1px',
                    backgroundColor: darkMode ? 'var(--border-color)' : 'rgba(0, 0, 0, 0.1)',
                  }}
                />
              </Box>
            ) : (
              <Tooltip 
                key={item.text}
                title={!isOpen ? item.text : ""}
                placement="right"
                arrow
              >
                <ListItem
                  button
                  component={Link}
                  to={item.path}
                  sx={{
                    padding: '0.5rem',
                    paddingLeft: isOpen ? '1rem' : '0.5rem',
                    justifyContent: isOpen ? 'flex-start' : 'center',
                    minHeight: 40,
                    position: 'relative',
                    mx: 0,
                    color: 'var(--text-primary)',
                    borderRadius: '6px !important',
                    backgroundColor: isActive(item.path)
                      ? (darkMode ? 'rgba(144, 202, 249, 0.08)' : 'rgba(25, 118, 210, 0.08)')
                      : 'transparent',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                      backgroundColor: darkMode ? 'rgba(144, 202, 249, 0.08)' : 'rgba(25, 118, 210, 0.08)',
                    },
                  }}
                >
                  <ListItemIcon 
                    sx={{ 
                      minWidth: 0,
                      mr: isOpen ? 2 : 0,
                      justifyContent: 'center',
                      borderRadius: '10px',
                      backgroundColor: !isOpen && isActive(item.path)
                        ? (darkMode ? 'rgba(144, 202, 249, 0.08)' : 'rgba(25, 118, 210, 0.08)')
                        : 'transparent',
                      color: 'var(--text-primary)',
                      transition: 'all 0.3s ease',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {isOpen && (
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        sx: {
                          fontFamily: "Noto Sans, sans-serif",
                          fontWeight: 500,
                          fontSize: '0.875rem',
                          letterSpacing: '1px',
                          color: 'var(--text-primary)',
                        }
                      }}
                    />
                  )}
                </ListItem>
              </Tooltip>
            )
          ))}
        </List>

        <Box sx={{ px: '8px', mb: 1 }}>
          <ListItem
            button
            onClick={(e) => {
              const notificationBell = e.currentTarget.querySelector('.notification-bell');
              if (notificationBell) {
                const bellIcon = notificationBell.querySelector('[role="button"]');
                if (bellIcon) {
                  bellIcon.click();
                }
              }
            }}
            sx={{
              padding: '0.5rem',
              justifyContent: isOpen ? 'flex-start' : 'center',
              minHeight: 40,
              mx: 0,
              color: 'var(--text-primary)',
              borderRadius: '6px !important',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                backgroundColor: darkMode ? 'rgba(144, 202, 249, 0.08)' : 'rgba(25, 118, 210, 0.08)',
              },
            }}
          >
            <ListItemIcon 
              sx={{ 
                minWidth: 40,
                width: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: isOpen ? 2 : 0,
                borderRadius: '6px',
                color: 'var(--text-primary)',
              }}
            >
              <Box className="notification-bell">
                <NotificationBell 
                  onNotificationsChange={handleNotificationsChange}
                  onAnchorChange={handleNotificationAnchor}
                  isOpen={isOpen}
                />
              </Box>
            </ListItemIcon>
            {isOpen && (
              <ListItemText
                primary="Notifications"
                primaryTypographyProps={{
                  sx: {
                    fontFamily: "Noto Sans, sans-serif",
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    letterSpacing: '1px',
                    color: 'var(--text-primary)',
                  }
                }}
              />
            )}
          </ListItem>
        </Box>

        <Box sx={{ px: '8px' }}>
          <Tooltip title={!isOpen ? "Logout" : ""} placement="right" arrow>
            <ListItem
              button
              onClick={handleLogout}
              sx={{
                padding: '0.5rem',
                justifyContent: isOpen ? 'flex-start' : 'center',
                minHeight: 40,
                mx: 0,
                color: 'var(--text-primary)',
                borderRadius: '6px !important',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  backgroundColor: darkMode ? 'rgba(144, 202, 249, 0.08)' : 'rgba(25, 118, 210, 0.08)',
                },
              }}
            >
              <ListItemIcon 
                sx={{ 
                  minWidth: 40,
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: isOpen ? 2 : 0,
                  borderRadius: '6px',
                  color: 'var(--text-primary)',
                }}
              >
                <SignOut {...iconProps} />
              </ListItemIcon>
              {isOpen && (
                <ListItemText
                  primary="Logout"
                  primaryTypographyProps={{
                    sx: {
                      fontFamily: "Noto Sans, sans-serif",
                      fontWeight: 500,
                      fontSize: '0.875rem',
                      letterSpacing: '1px',
                      color: 'var(--text-primary)',
                    }
                  }}
                />
              )}
            </ListItem>
          </Tooltip>
        </Box>
      </Box>

      <Box
        sx={{
          width: isOpen ? 240 : 50,
          flexShrink: 0,
          transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      />

      <IconButton
        onClick={toggleDrawer}
        sx={{
          position: 'fixed',
          left: isOpen ? '240px' : '50px',
          top: '50%',
          transform: 'translate(0, -50%)',
          backgroundColor: 'transparent',
          border: 'none',
          width: 24,
          height: 32,
          padding: '4px 0',
          zIndex: 1106,
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&::before': {
            content: '""',
            position: 'absolute',
            height: '16px',
            width: '2px',
            backgroundColor: darkMode ? 'var(--text-primary)' : '#637381',
            left: '50%',
            transform: 'translateX(-50%)',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            '&::before': {
              animation: isOpen 
                ? `${lineToArrowLeft} 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards`
                : `${lineToArrowRight} 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
              borderColor: darkMode ? 'var(--text-primary)' : '#637381',
              backgroundColor: 'transparent',
              left: isOpen ? '75%' : '25%',
              transformOrigin: 'center',
            }
          },
          '&:not(:hover)': {
            backgroundColor: 'transparent',
            '&::before': {
              animation: `${arrowToLine} 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
            }
          }
        }}
      />
    </>
  );
};

export default Navbar;


import React, { useRef, useEffect } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { 
  Users as TeamIcon,
  UserCircle as ClientIcon,
  ShieldStar as AdminIcon
} from "@phosphor-icons/react";

function RecipientSelector({ onSelect }) {
  const selectorRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onSelect?.(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSelect]);

  const recipients = [
    { id: 'team', label: 'assigned team', icon: <TeamIcon size={24} /> },
    { id: 'client', label: 'the client', icon: <ClientIcon size={24} /> },
    { id: 'admins', label: 'admins', icon: <AdminIcon size={24} /> }
  ];

  return (
    <Paper
      ref={selectorRef}
      elevation={2}
      sx={{
        width: 280,
        backgroundColor: 'white',
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid rgba(74, 93, 249, 0.1)',
      }}
    >
      <List sx={{ py: 1 }}>
        {recipients.map((recipient) => (
          <ListItem
            key={recipient.id}
            button
            onClick={() => onSelect?.(recipient)}
            sx={{
              py: 1.5,
              px: 2,
              '&:hover': {
                backgroundColor: 'rgba(74, 93, 249, 0.04)',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40, color: '#4a5df9' }}>
              {recipient.icon}
            </ListItemIcon>
            <ListItemText
              primary={recipient.label}
              sx={{
                '& .MuiListItemText-primary': {
                  color: '#1a1a1a',
                  fontSize: '0.95rem',
                  fontWeight: 400,
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default RecipientSelector; 
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Container,
  Fade,
  Button,
  Paper,
  Zoom
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PaymentIcon from '@mui/icons-material/Payment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getPublicInvoice } from '../services/api';
import DefaultInvoiceTemplate from '../templates/DefaultInvoiceTemplate';
import html2pdf from 'html2pdf.js';

const PublicInvoiceView = () => {
  const { uuid } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    email: ''
  });
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const calculateTotal = (items) => {
    if (!Array.isArray(items)) return 0;
    
    const subtotal = items.reduce((sum, item) => {
      const quantity = parseFloat(item?.quantity) || 0;
      const unitPrice = parseFloat(item?.unit_price) || 0;
      return sum + (quantity * unitPrice);
    }, 0);

    const taxRate = parseFloat(invoice?.tax_rate) || 0;
    const tax = subtotal * (taxRate / 100);
    
    return subtotal + tax;
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('invoice-template');
    setLoading(true);
    
    const opt = {
      margin: 10,
      filename: `invoice-${invoice?.invoiceNumber || 'download'}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        logging: false,
        windowWidth: 1024,
        windowHeight: element.scrollHeight
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait' 
      }
    };

    html2pdf().from(element).set(opt).save()
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        console.error('PDF generation failed:', error);
        setLoading(false);
        alert('Failed to generate PDF. Please try again.');
      });
  };

  const fetchInvoice = async (password = null) => {
    try {
      setLoading(true);
      const response = await getPublicInvoice(uuid, password);
      console.log('Raw API Response:', response);
      
      if (response.data.success) {
        const transformedItems = response.data.data.items.map(item => {
          console.log('Raw item data:', item);
          const quantity = 1;
          const unit_price = parseFloat(item.total) || 0;
          
          const formattedDescription = item.title && item.description 
            ? `${item.title}\n${item.description}`  // If both exist, stack them
            : item.title || item.description || ''; // Otherwise use whichever exists
          
          return {
            description: formattedDescription,
            quantity: quantity,
            unit_price: unit_price,
            total: unit_price * quantity,
            date: item.date || '',
            start_time: item.start_time || '',
            end_time: item.end_time || '',
            descriptionStyle: {
              whiteSpace: 'pre-line', // This preserves the line breaks
              marginBottom: '4px'
            }
          };
        });
        
        console.log('Transformed items:', transformedItems);

        console.log('Customer Details from API:', response.data.data.customer_details);
        console.log('Business Details from API:', response.data.data.business_details);

        const transformedInvoice = {
          invoiceNumber: response.data.data.invoice_number || response.data.data.id,
          invoice_number: response.data.data.invoice_number || response.data.data.id,
          id: response.data.data.id,
          issueDate: response.data.data.issue_date,
          dueDate: response.data.data.due_date,
          status: response.data.data.status,
          
          businessDetails: {
            company_name: response.data.data.business_details?.company_name || '',
            address: response.data.data.business_details?.address || '',
            city: response.data.data.business_details?.city || '',
            state: response.data.data.business_details?.state || '',
            zip_code: response.data.data.business_details?.zip_code || '',
            phone: response.data.data.business_details?.phone || '',
            email: response.data.data.business_details?.email || '',
            logo: response.data.data.business_details?.logo || null,
          },
          
          customerDetails: {
            name: response.data.data.customer_details?.customer_name || '',
            email: response.data.data.customer_details?.customer_email || '',
            phone: response.data.data.customer_details?.customer_phone || '',
            address: response.data.data.customer_details?.customer_address || '',
            city: response.data.data.customer_details?.customer_city || '',
            state: response.data.data.customer_details?.customer_state || '',
            zip_code: response.data.data.customer_details?.customer_zip || '',
          },
          
          items: transformedItems,
          tax_rate: parseFloat(response.data.data.tax_rate) || 0,
          
          service_address: response.data.data.service_address || '',
          notes: response.data.data.notes || '',
          include_notes: response.data.data.include_notes || false,
          terms_and_conditions: response.data.data.terms_and_conditions || '',
          include_terms: response.data.data.include_terms || false,
          
          subtotal: transformedItems.reduce((sum, item) => sum + item.total, 0),
          total: transformedItems.reduce((sum, item) => sum + item.total, 0) * 
                 (1 + (parseFloat(response.data.data.tax_rate) || 0) / 100)
        };

        console.log('Final transformed invoice:', transformedInvoice);
        setInvoice(transformedInvoice);
        setRequiresPassword(false);
      } else if (response.data.requires_password) {
        setRequiresPassword(true);
        setError(null);
      } else {
        setError(response.data.message || 'Failed to load invoice.');
      }
    } catch (error) {
      console.error('Error fetching invoice:', error);
      if (error.response && error.response.status === 401) {
        setRequiresPassword(true);
        setPasswordError('Invalid password');
      } else {
        setError('Failed to load invoice. Please check the link and try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    setPasswordError(null);
    await fetchInvoice(password);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    setPaymentLoading(true);
    setPaymentError(null);

    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 2000));

    // Mock validation
    if (paymentDetails.cardNumber.length !== 16) {
      setPaymentError('Invalid card number');
      setPaymentLoading(false);
      return;
    }

    // Simulate success
    setPaymentSuccess(true);
    setPaymentLoading(false);
    
    // Close modal after success
    setTimeout(() => {
      setShowPaymentModal(false);
      setPaymentSuccess(false);
      setPaymentDetails({
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        email: ''
      });
    }, 3000);
  };

  useEffect(() => {
    fetchInvoice();
  }, [uuid]);

  const PaymentModal = () => (
    <Dialog 
      open={showPaymentModal} 
      onClose={() => !paymentLoading && setShowPaymentModal(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          padding: '0',
          minHeight: { xs: '100vh', sm: '600px' }, // Full height on mobile
          margin: { xs: 0, sm: 32 }, // No margin on mobile
          maxWidth: { xs: '100%', sm: '600px' }, // Full width on mobile
          backgroundColor: '#f2f2f2'
        }
      }}
    >
      <DialogTitle sx={{ 
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: '#fff',
        padding: '24px 32px',
        fontSize: { xs: '1.25rem', sm: '1.5rem' },
        fontWeight: 600
      }}>
        Pay Invoice #{invoice?.invoice_number || invoice?.id}
      </DialogTitle>
      
      <DialogContent sx={{ 
        padding: { xs: '24px 16px', sm: '32px' },
        backgroundColor: '#f2f2f2'
      }}>
        {paymentSuccess ? (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <CheckCircleOutlineIcon 
              sx={{ 
                fontSize: 80, 
                color: '#27ae60',
                animation: 'bounce 1s infinite',
                '@keyframes bounce': {
                  '0%, 100%': { transform: 'translateY(0)' },
                  '50%': { transform: 'translateY(-10px)' }
                }
              }} 
            />
            <Typography variant="h5" sx={{ mt: 3, color: '#27ae60', fontWeight: 600 }}>
              Payment Successful!
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
              Thank you for your payment. A receipt has been sent to your email.
            </Typography>
          </Box>
        ) : (
          <form onSubmit={handlePayment}>
            <Box sx={{ 
              mb: 4,
              backgroundColor: '#fff',
              padding: { xs: '20px', sm: '32px' },
              borderRadius: '12px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
            }}>
              <Typography variant="h6" sx={{ 
                mb: 3, 
                color: '#1a1a1a',
                fontSize: { xs: '1.1rem', sm: '1.25rem' }
              }}>
                Payment Details
              </Typography>
              
              <TextField
                label="Email Address"
                type="email"
                required
                fullWidth
                value={paymentDetails.email}
                onChange={(e) => setPaymentDetails({...paymentDetails, email: e.target.value})}
                sx={{ 
                  mb: 3,
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    height: '60px',
                    '&:hover fieldset': {
                      borderColor: '#27ae60',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#27ae60',
                    }
                  }
                }}
              />

              <TextField
                label="Card Number"
                required
                fullWidth
                value={paymentDetails.cardNumber}
                onChange={(e) => setPaymentDetails({
                  ...paymentDetails, 
                  cardNumber: e.target.value.replace(/\D/g, '').slice(0, 16)
                })}
                sx={{ 
                  mb: 3,
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    height: '60px',
                    '&:hover fieldset': {
                      borderColor: '#27ae60',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#27ae60',
                    }
                  }
                }}
                InputProps={{
                  inputProps: { 
                    maxLength: 16,
                    pattern: '[0-9]*'
                  }
                }}
              />

              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                gap: { xs: 2, sm: 3 },
                mb: 3
              }}>
                <TextField
                  label="Expiry Date"
                  required
                  placeholder="MM/YY"
                  value={paymentDetails.expiryDate}
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, '');
                    if (value.length >= 2) {
                      value = value.slice(0, 2) + '/' + value.slice(2, 4);
                    }
                    setPaymentDetails({...paymentDetails, expiryDate: value});
                  }}
                  sx={{
                    backgroundColor: '#fff',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '60px',
                      '&:hover fieldset': {
                        borderColor: '#27ae60',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#27ae60',
                      }
                    }
                  }}
                  InputProps={{
                    inputProps: { 
                      maxLength: 5,
                      placeholder: 'MM/YY'
                    }
                  }}
                />
                <TextField
                  label="CVV"
                  required
                  value={paymentDetails.cvv}
                  onChange={(e) => setPaymentDetails({
                    ...paymentDetails, 
                    cvv: e.target.value.replace(/\D/g, '').slice(0, 3)
                  })}
                  sx={{
                    backgroundColor: '#fff',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      height: '60px',
                      '&:hover fieldset': {
                        borderColor: '#27ae60',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#27ae60',
                      }
                    }
                  }}
                  InputProps={{
                    inputProps: { 
                      maxLength: 3,
                      pattern: '[0-9]*'
                    }
                  }}
                />
              </Box>
            </Box>

            {paymentError && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 3,
                  borderRadius: '12px'
                }}
              >
                {paymentError}
              </Alert>
            )}

            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between', 
              alignItems: 'center',
              backgroundColor: '#fff',
              padding: { xs: '20px', sm: '24px' },
              borderRadius: '12px',
              mt: 4,
              gap: { xs: 2, sm: 0 },
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
            }}>
              <Typography variant="h5" sx={{ 
                fontWeight: 600,
                fontSize: { xs: '1.25rem', sm: '1.5rem' }
              }}>
                Total: ${invoice?.total ? invoice.total.toFixed(2) : '0.00'}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                disabled={paymentLoading}
                startIcon={paymentLoading ? <CircularProgress size={20} /> : <PaymentIcon />}
                sx={{
                  backgroundColor: '#27ae60',
                  padding: { xs: '16px 24px', sm: '16px 48px' },
                  borderRadius: '12px',
                  fontSize: { xs: '1rem', sm: '1.1rem' },
                  width: { xs: '100%', sm: 'auto' },
                  '&:hover': { 
                    backgroundColor: '#219a52'
                  },
                  '&:disabled': { 
                    backgroundColor: '#4caf50'
                  }
                }}
              >
                {paymentLoading ? 'Processing...' : 'Pay Now'}
              </Button>
            </Box>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '2rem 1rem',
        position: 'relative',
      }}
    >
      {requiresPassword && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <form onSubmit={handlePasswordSubmit}>
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </form>
        </Box>
      )}

      {!requiresPassword && (
        <>
          <Box
            sx={{
              maxWidth: '1000px',
              margin: '0 auto',
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              overflow: 'hidden',
              marginBottom: '3rem'
            }}
          >
            {/* Dark Header with Invoice Number */}
            <Box
              sx={{
                backgroundColor: '#1a1a1a',
                color: 'white',
                padding: '1rem',
                textAlign: 'center',
                borderBottom: '1px solid rgba(255,255,255,0.1)'
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 500,
                  letterSpacing: '0.5px'
                }}
              >
                Invoice #{invoice?.invoice_number || invoice?.id}
              </Typography>
            </Box>

            {/* Contact Buttons */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                padding: '1rem',
                flexWrap: 'wrap',
                borderBottom: '1px solid #eee',
                backgroundColor: '#f8f9fa'
              }}
            >
              {invoice && invoice.businessDetails && invoice.businessDetails.phone && (
                <Button
                  variant="outlined"
                  startIcon={<PhoneIcon />}
                  href={`tel:${invoice.businessDetails.phone}`}
                  sx={{
                    borderRadius: '50px',
                    borderColor: '#e0e0e0',
                    color: '#424242',
                    '&:hover': {
                      borderColor: '#424242',
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  Call {invoice.businessDetails.company_name || 'Us'}
                </Button>
              )}

              {invoice && invoice.businessDetails && invoice.businessDetails.email && (
                <Button
                  variant="outlined"
                  startIcon={<EmailIcon />}
                  href={`mailto:${invoice.businessDetails.email}`}
                  sx={{
                    borderRadius: '50px',
                    borderColor: '#e0e0e0',
                    color: '#424242',
                    '&:hover': {
                      borderColor: '#424242',
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  Email {invoice.businessDetails.company_name || 'Us'}
                </Button>
              )}
            </Box>

            {/* Invoice Template */}
            <Box
              sx={{
                padding: '2rem',
                '@media (max-width: 600px)': {
                  padding: '1rem',
                },
              }}
            >
              <div id="invoice-template">
                {invoice && (
                  <DefaultInvoiceTemplate
                    invoice={invoice}
                    businessDetails={invoice.businessDetails}
                    customerDetails={invoice.customerDetails}
                  />
                )}
              </div>
            </Box>

            {/* Download Button */}
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '2rem',
                borderTop: '1px solid #eee',
                gap: 2,
                flexWrap: 'wrap'
              }}
            >
              <Zoom in={true}>
                <Button
                  variant="contained"
                  onClick={() => setShowPaymentModal(true)}
                  startIcon={<PaymentIcon />}
                  sx={{
                    backgroundColor: '#27ae60',
                    borderRadius: '50px',
                    padding: '12px 24px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#219a52',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                    }
                  }}
                >
                  Pay Invoice
                </Button>
              </Zoom>
              
              <Zoom in={true}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#424242',
                    borderRadius: '50px',
                    padding: '12px 24px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#616161',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                    }
                  }}
                  onClick={handleDownloadPDF}
                  startIcon={loading ? <CircularProgress size={20} /> : <FileDownloadIcon />}
                >
                  {loading ? 'Generating PDF...' : 'Download PDF'}
                </Button>
              </Zoom>
            </Box>
          </Box>

          {/* Add Payment Modal */}
          <PaymentModal />

          {/* Footer */}
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: '#f5f5f5',
              padding: '0.5rem',
              textAlign: 'center',
              borderTop: '1px solid #e0e0e0'
            }}
          >
            <Typography 
              variant="body2" 
              sx={{ 
                color: '#666',
                fontSize: '0.875rem',
                '& a': {
                  color: '#424242',
                  textDecoration: 'none',
                  fontWeight: 500,
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }
              }}
            >
              Powered by <a href="https://servtrackr.com" target="_blank" rel="noopener noreferrer">ServTrackr</a>
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PublicInvoiceView; 
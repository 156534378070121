import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import { CaretUp, CaretDown } from "@phosphor-icons/react";
import { getClientInvoices } from '../../services/api';

const Invoices = ({ client, onOverdueCountChange }) => {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState('newest');

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        if (client?.id) {
          console.log('Fetching invoices for client ID:', client.id);
          const data = await getClientInvoices(client.id);
          console.log('Received invoice data:', data);
          
          setInvoices(data);
          
          const overdueCount = data.filter(invoice => 
            invoice.status?.toLowerCase() !== 'paid' && isOverdue(invoice.due_date)
          ).length;
          console.log('Overdue count:', overdueCount);
          
          onOverdueCountChange(overdueCount);
        }
      } catch (error) {
        console.error('Error fetching invoices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [client, onOverdueCountChange]);

  const calculateTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    console.log('Calculating total for items:', items);
    
    const total = items.reduce((sum, item) => {
      const itemTotal = Number(item.total) || 0;
      console.log('Item total:', { itemTotal });
      return sum + itemTotal;
    }, 0);
    
    console.log('Invoice total calculated:', total);
    return total;
  };

  const isOverdue = (dueDate) => {
    if (!dueDate) return false;
    const today = new Date();
    const due = new Date(dueDate);
    return due < today;
  };

  const getStatusColor = (status, dueDate) => {
    if (status?.toLowerCase() !== 'paid' && isOverdue(dueDate)) {
      return '#EF4444';
    }

    switch (status?.toLowerCase()) {
      case 'paid':
        return '#10B981';
      case 'pending':
        return '#F59E0B';
      default:
        return '#9CA3AF';
    }
  };

  const formatStatus = (status, dueDate) => {
    if (status?.toLowerCase() !== 'paid' && isOverdue(dueDate)) {
      return 'Overdue';
    }
    if (!status) return 'Pending';
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const handleRowClick = (invoiceId) => {
    navigate(`/admin/invoices/${invoiceId}`);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-default)' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>
                Invoice #
              </TableCell>
              <TableCell 
                sx={{ 
                  color: 'var(--text-primary)', 
                  borderBottom: '1px solid var(--divider)',
                  cursor: 'pointer',
                  fontFamily: "'Urbanist', sans-serif",
                  fontWeight: 500
                }}
                onClick={() => setSort(sort === 'newest' ? 'oldest' : 'newest')}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Box component="span">Date</Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, lineHeight: 0 }}>
                    <CaretUp 
                      size={12}
                      weight="bold"
                      style={{ 
                        color: sort === 'oldest' ? 'var(--primary-main)' : 'var(--text-disabled)'
                      }} 
                    />
                    <CaretDown 
                      size={12}
                      weight="bold"
                      style={{ 
                        marginTop: '-4px',
                        color: sort === 'newest' ? 'var(--primary-main)' : 'var(--text-disabled)'
                      }} 
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>Due Date</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }} align="right">Amount</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500,
                paddingLeft: 4,
                textAlign: 'center'
              }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>Loading...</TableCell>
              </TableRow>
            ) : invoices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>No invoices found</TableCell>
              </TableRow>
            ) : (
              [...invoices]
                .sort((a, b) => {
                  const dateA = new Date(a.issue_date);
                  const dateB = new Date(b.issue_date);
                  return sort === 'newest' ? dateB - dateA : dateA - dateB;
                })
                .map((invoice) => (
                  <TableRow 
                    key={invoice.id}
                    hover
                    onClick={() => handleRowClick(invoice.id)}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      },
                      '& .MuiTableCell-root': {
                        color: 'var(--text-primary)',
                        borderBottom: '1px solid var(--divider)',
                        fontFamily: "'Urbanist', sans-serif"
                      }
                    }}
                  >
                    <TableCell>{invoice.invoice_number}</TableCell>
                    <TableCell>
                      {invoice.issue_date ? new Date(invoice.issue_date).toLocaleDateString() : '-'}
                    </TableCell>
                    <TableCell>
                      {invoice.due_date ? new Date(invoice.due_date).toLocaleDateString() : '-'}
                    </TableCell>
                    <TableCell align="right">
                      ${calculateTotal(invoice.items).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                    <TableCell sx={{ 
                      paddingLeft: 4,
                      textAlign: 'center'
                    }}>
                      <Chip 
                        label={formatStatus(invoice.status, invoice.due_date)}
                        sx={{
                          backgroundColor: `${getStatusColor(invoice.status, invoice.due_date)}20`,
                          color: getStatusColor(invoice.status, invoice.due_date),
                          fontWeight: 500,
                          fontSize: '0.75rem',
                          fontFamily: "'Urbanist', sans-serif",
                          minWidth: '100px'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Invoices; 
import React from 'react';
import styled from 'styled-components';

const CustomSwitch = ({ checked, onChange, ...props }) => {
  return (
    <StyledWrapper>
      <label className="switch">
        <input 
          type="checkbox" 
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <span className="slider">
          <svg className="slider-icon" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation"><path fill="none" d="m4 16.5 8 8 16-16" /></svg> 
        </span>
      </label>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .switch {
    font-size: 14px;
    position: relative;
    display: inline-block;
    width: 2.5em;
    height: 1.4em;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #B0B0B0;
    border: 1px solid #B0B0B0;
    transition: .4s;
    border-radius: 32px;
    outline: none;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 50%;
    outline: 2px solid #B0B0B0;
    left: -1px;
    bottom: -1px;
    background-color: #fff;
    transition: transform .25s ease-in-out 0s;
  }

  .slider-icon {
    opacity: 0;
    height: 10px;
    width: 10px;
    stroke-width: 8;
    position: absolute;
    right: 50%;
    stroke: #4a5df9;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease-in-out .3s, opacity ease-in-out .15s;
  }

  input:checked + .slider {
    background-color: #4a5df9;
  }

  input:checked + .slider .slider-icon {
    opacity: 1;
    right: 14%;
  }

  input:checked + .slider:before {
    transform: translateX(1em);
    outline-color: #4a5df9;
  }
`;

export default CustomSwitch; 
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Fade,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton
} from '@mui/material';
import { Estimate, BusinessDetails } from './types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFTemplate from '../../templates/PDFTemplate';
import { PictureAsPdf as PdfIcon, Print as PrintIcon } from '@mui/icons-material';
import DefaultTemplate from '../../templates/DefaultTemplate';
import ModernTemplate from '../../templates/ModernTemplate';
import ClassicTemplate from '../../templates/ClassicTemplate';
import { getBusinessDetails } from '../../services/api';
import BoltIcon from '@mui/icons-material/Bolt';

interface Props {
  styles: {
    paper: string;
    sectionTitle: string;
    reviewSection: string;
    imageContainer: string;
  };
  estimate: Estimate;
}

const isHtmlEmpty = (html: string | null | undefined): boolean => {
  if (!html) return true;
  // Remove HTML tags, whitespace, and common empty editor content
  const cleanContent = html.replace(/<[^>]*>/g, '')
                          .replace(/\s/g, '')
                          .replace(/^<p><br><\/p>$/, '')
                          .trim();
  return !cleanContent;
};

const EstimateReview: React.FC<Props> = ({ styles, estimate }) => {
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        setIsLoading(true);
        const data = await getBusinessDetails();
        setBusinessDetails(data);
      } catch (err) {
        console.error('Error fetching business details:', err);
        setError('Failed to load business details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBusinessDetails();
  }, []);

  const getTemplateComponent = () => {
    switch (estimate.template) {
      case 'modern':
        return ModernTemplate;
      case 'classic':
        return ClassicTemplate;
      case 'default':
      default:
        return DefaultTemplate;
    }
  };

  const exportToPDF = () => {
    return (
      <PDFDownloadLink
        document={<PDFTemplate estimate={estimateWithBusinessDetails} />}
        fileName={`estimate-${estimate?.estimateNumber || 'draft'}.pdf`}
      >
        {({ loading }) => (
          loading ? 'Loading document...' : 'Download PDF'
        )}
      </PDFDownloadLink>
    );
  };

  const SelectedTemplate = getTemplateComponent();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const estimateWithBusinessDetails = {
    ...estimate,
    businessDetails: businessDetails || estimate.businessDetails,
    projectImages: estimate.project_images || [],
    scopeOfWork: estimate.scope_of_work || '',
    notes: estimate.notes || '',
    terms: estimate.terms || '',
    showAdditionalInfo: true,
    customer_details: {
      ...estimate.customer_details,
      zip: estimate.customer_details?.zipCode || estimate.customer_details?.zip || '',
    },
    items: estimate.items?.map(item => ({
      title: item.title || '',
      description: item.description || '',
      quantity: Number(item.quantity) || 0,
      unitPrice: Number(item.unit_price || item.unitPrice) || 0,
      tax: Number(item.tax) || 0
    }))
  };

  const ServiceAddressSection = () => (
    <Box className={styles.reviewSection}>
      <Typography variant="h6">Service Address</Typography>
      {estimate.use_client_address ? (
        <Typography>Same as client address</Typography>
      ) : (
        <>
          <Typography>{estimate.service_address}</Typography>
          <Typography>
            {estimate.service_city}, {estimate.service_state} {estimate.service_zip}
          </Typography>
        </>
      )}
    </Box>
  );

  return (
    <Fade in={true}>
      <Box>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Review Estimate
          </Typography>
          <Box>
            <IconButton
              onClick={handleClick}
              color="primary"
              sx={{ 
                border: '1px solid',
                borderColor: 'primary.main',
                borderRadius: 2,
                p: 1,
                '&:hover': {
                  backgroundColor: 'rgba(33, 150, 243, 0.04)'
                }
              }}
            >
              <Typography sx={{ mr: 1 }}>⚡</Typography>
              <Typography variant="button">Actions</Typography>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem 
                onClick={handleClose}
                component="div"
              >
                <PdfIcon sx={{ mr: 1 }} />
                {exportToPDF()}
              </MenuItem>
              <MenuItem 
                onClick={() => {
                  window.print();
                  handleClose();
                }}
              >
                <PrintIcon sx={{ mr: 1 }} /> Print
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <div id="estimate-content">
          <SelectedTemplate estimate={estimateWithBusinessDetails} />
        </div>
      </Box>
    </Fade>
  );
};

export default EstimateReview;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  CheckCircle as ApproveIcon,
  Cancel as RejectIcon,
  AccessTime as PendingIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { getEmployeeRequests, updateRequestStatus } from '../services/api';
import { 
  User as UserIcon,
  CalendarCheck as CalendarIcon,
  ClockClockwise as RequestIcon,
  TextAlignLeft as DetailsIcon,
  CircleWavyCheck as StatusIcon,
  Gear as ActionsIcon,
  Clock
} from "@phosphor-icons/react";

const formatRequestType = (type) => {
  if (!type) return 'N/A';
  
  return type
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const EmployeeRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [responseNote, setResponseNote] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const data = await getEmployeeRequests();
      setRequests(data.requests);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch requests');
      setLoading(false);
      setSnackbar({
        open: true,
        message: 'Failed to fetch requests',
        severity: 'error'
      });
    }
  };

  const handleApprove = async (requestId) => {
    try {
      await updateRequestStatus(requestId, 'approved', responseNote);
      await fetchRequests(); // Refresh the list
      setSelectedRequest(null);
      setResponseNote('');
      setSnackbar({
        open: true,
        message: 'Request approved successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to approve request',
        severity: 'error'
      });
    }
  };

  const handleReject = async (requestId) => {
    try {
      await updateRequestStatus(requestId, 'rejected', responseNote);
      await fetchRequests(); // Refresh the list
      setSelectedRequest(null);
      setResponseNote('');
      setSnackbar({
        open: true,
        message: 'Request rejected successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to reject request',
        severity: 'error'
      });
    }
  };

  const getStatusChip = (status) => {
    const statusStyles = {
      pending: {
        color: '#E65100',
        backgroundColor: '#FFF3E0'
      },
      approved: {
        color: '#2E7D32',
        backgroundColor: '#E8F5E9'
      },
      rejected: {
        color: '#C62828',
        backgroundColor: '#FFEBEE'
      }
    };

    const style = statusStyles[status] || statusStyles.pending;

    return (
      <Typography
        sx={{
          px: 2.5,
          borderRadius: '8px',
          fontSize: '0.75rem',
          fontWeight: 700,
          textTransform: 'uppercase',
          letterSpacing: '0.5px',
          fontFamily: 'Urbanist, sans-serif',
          display: 'inline-block',
          ...style
        }}
      >
        {status?.replace(/_/g, ' ')}
      </Typography>
    );
  };

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
  };

  const handleCloseDetails = () => {
    setSelectedRequest(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const RequestDetailsModal = ({ request, onClose }) => {
    if (!request) return null;

    return (
      <Dialog 
        open={!!request} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            backgroundColor: 'var(--background-primary)',
            backgroundImage: 'none',
            position: 'relative'
          }
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          {/* Close Button Row */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            p: 1,
            '&::after': {
              content: '""',
              display: 'block',
              width: '95%',
              margin: '0 auto',
              borderBottom: '1px solid #e0e0e0',
              position: 'absolute',
              bottom: 0,
              left: '2.5%'
            }
          }}>
            <IconButton
              onClick={onClose}
              sx={{
                color: '#ef5350',
                '&:hover': {
                  backgroundColor: 'rgba(239, 83, 80, 0.08)'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Header with Status */}
          <Box sx={{ 
            px: 3,
            py: 2,
            backgroundColor: 'var(--background-paper)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            position: 'relative',
            '&::after': {
              content: '""',
              display: 'block',
              width: '95%',
              margin: '0 auto',
              borderBottom: '1px solid #e0e0e0',
              position: 'absolute',
              bottom: 0,
              left: '2.5%'
            }
          }}>
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  color: 'var(--text-primary)',
                  mb: 0.5
                }}
              >
                Request Details
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'var(--text-secondary)',
                  fontWeight: 500
                }}
              >
                {new Date(selectedRequest?.requestDate).toLocaleDateString()}
              </Typography>
            </Box>
            {getStatusChip(selectedRequest?.status)}
          </Box>

          {/* Content */}
          <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                <Box sx={{ 
                  p: 1,
                  backgroundColor: 'var(--background-paper)',
                  borderRadius: '12px',
                }}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" sx={{ mb: 2, color: 'var(--text-secondary)', fontWeight: 600 }}>
                      Employee
                    </Typography>
                    <Box sx={{ 
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      borderRadius: '8px',
                    }}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {request.employeeName}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 2, color: 'var(--text-secondary)', fontWeight: 600 }}>
                      Request Type
                    </Typography>
                    <Box sx={{ 
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      borderRadius: '8px',
                    }}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {formatRequestType(request.type)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} md={6}>
                <Box sx={{ 
                  p: 1,
                  backgroundColor: 'var(--background-paper)',
                  borderRadius: '12px',
                  height: '100%'
                }}>
                  <Typography variant="subtitle2" sx={{ mb: 2, color: 'var(--text-secondary)', fontWeight: 600 }}>
                    Details
                  </Typography>
                  <Box sx={{ 
                    p: 2,
                    backgroundColor: 'var(--background-default)',
                    borderRadius: '8px',
                    minHeight: '150px'
                  }}>
                    <Typography variant="body1" sx={{ fontWeight: 500, lineHeight: 1.6 }}>
                      {request.details}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Response Section - Keeping borders here */}
              {selectedRequest?.status === 'pending' && (
                <Grid item xs={12}>
                  <Box sx={{ 
                    p: 2.5,
                    backgroundColor: 'var(--background-paper)',
                    borderRadius: '12px',
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      display: 'block',
                      width: '95%',
                      margin: '0 auto',
                      borderBottom: '1px solid #e0e0e0',
                      position: 'absolute',
                      top: -16,
                      left: '2.5%'
                    }
                  }}>
                    <Typography variant="subtitle2" sx={{ mb: 2, color: 'var(--text-secondary)', fontWeight: 600 }}>
                      Response
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={responseNote}
                      onChange={(e) => setResponseNote(e.target.value)}
                      placeholder="Add a note (optional)"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'var(--background-default)',
                          borderRadius: '8px',
                          border: '1px solid #e0e0e0',
                          '& fieldset': { border: 'none' }
                        }
                      }}
                    />
                    <Box sx={{ display: 'flex', gap: 2, mt: 3, justifyContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<ApproveIcon />}
                        onClick={() => handleApprove(selectedRequest.id)}
                        sx={{ 
                          textTransform: 'none',
                          borderRadius: '8px',
                          fontWeight: 600,
                          boxShadow: 'none',
                          px: 3
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<RejectIcon />}
                        onClick={() => handleReject(selectedRequest.id)}
                        sx={{ 
                          textTransform: 'none',
                          borderRadius: '8px',
                          fontWeight: 600,
                          boxShadow: 'none',
                          px: 3
                        }}
                      >
                        Reject
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  const headerCells = [
    { icon: <UserIcon size={20} weight="light" style={{ color: '#FF4842' }} />, label: 'EMPLOYEE' },
    { icon: <RequestIcon size={20} weight="light" style={{ color: '#2065D1' }} />, label: 'TYPE' },
    { icon: <CalendarIcon size={20} weight="light" style={{ color: '#FFC107' }} />, label: 'REQUEST DATE' },
    { icon: <DetailsIcon size={20} weight="light" style={{ color: '#00AB55' }} />, label: 'DETAILS' },
    { icon: <StatusIcon size={20} weight="light" style={{ color: '#7635DC' }} />, label: 'STATUS' },
    { icon: <ActionsIcon size={20} weight="light" style={{ color: '#16a085' }} />, label: 'ACTIONS' }
  ];

  if (loading) return <Box p={3} sx={{ color: 'var(--text-primary)' }}>Loading...</Box>;
  if (error) return <Box p={3} sx={{ color: 'var(--text-primary)' }}>{error}</Box>;

  return (
    <Box sx={{ 
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <TableContainer 
        component={Paper} 
        sx={{ 
          borderRadius: '12px',
          backgroundColor: 'var(--background-paper)',
          boxShadow: 'none',
          border: '1px solid var(--border)',
          overflow: 'auto',
          flex: 1,
          minHeight: 0, // Important for flex container
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'var(--scrollbar)',
            borderRadius: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'var(--scrollbar-bg)'
          }
        }}
      >
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'var(--background-secondary)' }}>
              {headerCells.map((cell, index) => (
                <TableCell 
                  key={index}
                  align="center"
                  sx={{ 
                    backgroundColor: 'var(--background-secondary)',
                    borderBottom: '1px solid var(--border)',
                    padding: '16px',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    whiteSpace: 'nowrap'
                  }}
                >
                  <Box 
                    display="flex" 
                    alignItems="center" 
                    justifyContent="center"
                    gap={1}
                  >
                    {cell.icon}
                    <Typography 
                      sx={{ 
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        letterSpacing: '0.5px',
                        textTransform: 'uppercase',
                        color: 'var(--text-secondary)',
                        fontFamily: 'Urbanist, sans-serif'
                      }}
                    >
                      {cell.label}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow 
                key={request.id}
                sx={{ 
                  '&:hover': { 
                    backgroundColor: 'var(--background-hover)'
                  },
                  '& td': {
                    borderBottom: '1px solid var(--border)',
                    padding: '16px',
                    whiteSpace: 'nowrap'
                  }
                }}
              >
                <TableCell 
                  align="center"
                  sx={{
                    color: 'var(--text-primary)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: {
                      xs: '120px',
                      sm: '150px',
                      md: '200px',
                    }
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.875rem' },
                      fontFamily: 'Urbanist, sans-serif',
                      color: 'var(--text-primary)'
                    }}
                  >
                    {request.employeeName}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography noWrap sx={{ color: 'var(--text-primary)' }}>
                    {formatRequestType(request.type)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography noWrap sx={{ color: 'var(--text-primary)' }}>
                    {new Date(request.requestDate).toLocaleDateString()}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography 
                    noWrap 
                    sx={{ 
                      color: 'var(--text-primary)',
                      maxWidth: { xs: '120px', sm: '200px', md: '300px' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      margin: '0 auto'
                    }}
                  >
                    {request.details}
                  </Typography>
                </TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    padding: { xs: '8px', sm: '12px 16px' },
                    minWidth: { xs: '100px', sm: '120px' }
                  }}
                >
                  {getStatusChip(request.status)}
                </TableCell>
                <TableCell align="center">
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                    <Tooltip title="View Details">
                      <IconButton
                        onClick={() => handleViewDetails(request)}
                        size="small"
                        sx={{ 
                          color: 'var(--text-secondary)',
                          '&:hover': { 
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            color: 'var(--text-primary)'
                          }
                        }}
                      >
                        <InfoIcon size={20} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <RequestDetailsModal 
        request={selectedRequest} 
        onClose={() => setSelectedRequest(null)}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            borderRadius: '8px',
            fontFamily: 'Urbanist, sans-serif',
            fontWeight: 500
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeRequests; 
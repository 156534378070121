import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { 
  User as PersonIcon,
  Envelope as EmailIcon,
  Briefcase as WorkIcon,
  Phone as PhoneIcon,
  House as HomeIcon,
  LockKey as LockResetIcon,
  CheckCircle as CheckCircleIcon
} from "@phosphor-icons/react";
import { getEmployeeProfile, resetPassword } from '../services/api';
import styles from './EmployeeProfile.module.scss';

const ProfileItem = ({ icon, label, value }) => (
  <div className={styles.profileItem}>
    <div className={styles.iconWrapper}>
      {icon}
    </div>
    <div className={styles.itemContent}>
      <Typography variant="subtitle2" className={styles.itemLabel}>
        {label}
      </Typography>
      <Typography variant="body1" className={styles.itemValue}>
        {value}
      </Typography>
    </div>
  </div>
);

const EmployeeProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetError, setResetError] = useState('');
  const [showResetSuccess, setShowResetSuccess] = useState(false);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const data = await getEmployeeProfile();
      setProfile(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching profile:', err);
      setError('Failed to fetch profile. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setResetError('Passwords do not match');
      return;
    }
    try {
      await resetPassword(newPassword);
      setOpenResetPassword(false);
      setNewPassword('');
      setConfirmPassword('');
      setResetError('');
      setShowResetSuccess(true);
      setTimeout(() => setShowResetSuccess(false), 3000);
    } catch (error) {
      setResetError(error.message || 'Failed to reset password. Please try again.');
    }
  };

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  return (
    <div className={styles.profileContainer}>
      {profile && (
        <>
          <div className={styles.profileHeader}>
            <div className={styles.avatar}>
              {profile.name.split(' ').map(n => n[0]).join('').toUpperCase()}
            </div>
            <Typography variant="h4" className={styles.profileName}>
              {profile.name}
            </Typography>
            <Typography variant="subtitle1" className={styles.profilePosition}>
              {profile.position}
            </Typography>
          </div>
          <div className={styles.divider} />
          <div className={styles.profileDetails}>
            <ProfileItem 
              icon={<EmailIcon size={24} weight="light" color="#1976d2" />} 
              label="Email" 
              value={profile.email} 
            />
            <ProfileItem 
              icon={<WorkIcon size={24} weight="light" color="#2e7d32" />} 
              label="Position" 
              value={profile.position} 
            />
            <ProfileItem 
              icon={<PhoneIcon size={24} weight="light" color="#ed6c02" />} 
              label="Phone" 
              value={profile.phone} 
            />
            <ProfileItem 
              icon={<HomeIcon size={24} weight="light" color="#9c27b0" />} 
              label="Address" 
              value={profile.address} 
            />
          </div>
          <Button
            variant="contained"
            onClick={() => setOpenResetPassword(true)}
            className={styles.resetPasswordButton}
            startIcon={<LockResetIcon size={20} weight="light" />}
            sx={{
              boxShadow: 'none',
              backgroundColor: '#4a5df9',
              '&:hover': {
                backgroundColor: '#3c50fa',
                boxShadow: 'none',
              },
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '0.875rem',
              padding: '6px 16px',
              borderRadius: '8px'
            }}
          >
            Reset Password
          </Button>

          <Dialog 
            open={openResetPassword} 
            onClose={() => setOpenResetPassword(false)}
            className={styles.dialog}
          >
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="New Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="outlined"
                className={styles.textField}
              />
              <TextField
                margin="dense"
                label="Confirm New Password"
                type="password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
                className={styles.textField}
              />
              {resetError && <p className={styles.errorMessage}>{resetError}</p>}
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <Button onClick={() => setOpenResetPassword(false)}>Cancel</Button>
              <Button onClick={handleResetPassword} variant="contained">
                Reset Password
              </Button>
            </DialogActions>
          </Dialog>
          
          {showResetSuccess && (
            <div className={`${styles.resetSuccessOverlay} ${styles.show}`}>
              <div className={styles.resetSuccessContent}>
                <CheckCircleIcon size={48} weight="fill" className={styles.successIcon} />
                <Typography variant="h6" className={styles.resetSuccessMessage}>
                  Password Reset Successfully!
                </Typography>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeeProfile;

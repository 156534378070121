import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createClient } from '../services/api';
import { 
  TextField, 
  Button, 
  Typography, 
  Container, 
  Box, 
  Grid, 
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import styles from './CreateClient.module.scss';

const inputStyles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'var(--background-secondary)'
  }
};

const selectStyles = {
  backgroundColor: 'var(--background-secondary)'
};

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  // Remove all non-digits
  const cleaned = phoneNumberString.replace(/\D/g, '');
  // Limit to 10 digits
  const trimmed = cleaned.slice(0, 10);
  // Format as (XXX) XXX-XXXX
  const match = trimmed.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (!match) return trimmed;
  
  const parts = [match[1], match[2], match[3]].filter(Boolean);
  if (parts.length === 0) return '';
  if (parts.length === 1) return parts[0];
  if (parts.length === 2) return `(${parts[0]}) ${parts[1]}`;
  return `(${parts[0]}) ${parts[1]}-${parts[2]}`;
};

const CreateClient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    title: '',
    firstName: '',
    lastName: '',
    companyName: '',
    useCompanyName: false,
    phone: '',
    phoneType: 'Main',
    receivesTexts: false,
    email: '',
    emailType: 'Main',
    // Property Address
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    // Billing Address
    billingStreet1: '',
    billingStreet2: '',
    billingCity: '',
    billingState: '',
    billingZipCode: '',
    sameAsProperty: true
  });

  const [error, setError] = useState('');

  const handleChange = (field) => (event) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    
    // Apply phone formatting if this is the phone field
    if (field === 'phone') {
      value = formatPhoneNumber(value);
    }

    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    // If sameAsProperty is checked, copy property address to billing address
    if (field === 'sameAsProperty' && event.target.checked) {
      setFormData(prev => ({
        ...prev,
        billingStreet1: prev.street1,
        billingStreet2: prev.street2,
        billingCity: prev.city,
        billingState: prev.state,
        billingZipCode: prev.zipCode
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('Form data before submission:', formData);

      const clientData = {
        name: formData.useCompanyName ? formData.companyName : `${formData.firstName} ${formData.lastName}`.trim(),
        email: formData.email,
        phone: formData.phone,
        company: formData.companyName,
        address: formData.street1,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zipCode,  // This is what the backend expects
        notes: ''
      };

      console.log('Formatted client data being sent:', clientData);
      
      const response = await createClient(clientData);
      console.log('Server response:', response);

      // Update navigation logic to handle both invoice and estimate creation
      if (location.state?.from === 'invoice-creation') {
        navigate('/admin/invoices/create', {
          state: { 
            newClient: response,
            autoSelect: true,
            fromClientCreation: true
          },
          replace: true
        });
      } else if (location.state?.from === 'estimate-creation') {
        // Add this condition for estimate creation
        navigate('/admin/estimates/create', {
          state: { 
            newClient: response,
            autoSelect: true,
            fromClientCreation: true
          },
          replace: true
        });
      } else {
        navigate('/admin/clients');
      }
    } catch (err) {
      console.error('Error creating client:', err);
      setError(err.response?.data?.message || 'Failed to create client. Please try again.');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 600 }}>
        New Client
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          {/* Client Details Section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 1 }}>
              <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box component="span" sx={{ 
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%', 
                  bgcolor: '#f0f9ff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  👤
                </Box>
                Client details
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="First name"
                    value={formData.firstName}
                    onChange={handleChange('firstName')}
                    sx={inputStyles}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last name"
                    value={formData.lastName}
                    onChange={handleChange('lastName')}
                    sx={inputStyles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company name"
                    value={formData.companyName}
                    onChange={handleChange('companyName')}
                    sx={inputStyles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.useCompanyName}
                        onChange={handleChange('useCompanyName')}
                      />
                    }
                    label="Use company name as the primary name"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                    Contact Details
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <FormControl sx={{ width: 120 }}>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={formData.phoneType}
                        onChange={handleChange('phoneType')}
                        label="Type"
                        sx={selectStyles}
                      >
                        <MenuItem value="Main">Main</MenuItem>
                        <MenuItem value="Mobile">Mobile</MenuItem>
                        <MenuItem value="Work">Work</MenuItem>
                        <MenuItem value="Home">Home</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Phone number"
                      value={formData.phone}
                      onChange={handleChange('phone')}
                      sx={inputStyles}
                    />
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.receivesTexts}
                        onChange={handleChange('receivesTexts')}
                      />
                    }
                    label="Receives text messages"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <FormControl sx={{ width: 120 }}>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={formData.emailType}
                        onChange={handleChange('emailType')}
                        label="Type"
                        sx={selectStyles}
                      >
                        <MenuItem value="Main">Main</MenuItem>
                        <MenuItem value="Work">Work</MenuItem>
                        <MenuItem value="Personal">Personal</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Email address"
                      type="email"
                      value={formData.email}
                      onChange={handleChange('email')}
                      sx={inputStyles}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Property Details Section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 1 }}>
              <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box component="span" sx={{ 
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%', 
                  bgcolor: '#f0f9ff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  🏠
                </Box>
                Property details
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Street 1"
                    value={formData.street1}
                    onChange={handleChange('street1')}
                    sx={inputStyles}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="City"
                    value={formData.city}
                    onChange={handleChange('city')}
                    sx={inputStyles}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="State"
                    value={formData.state}
                    onChange={handleChange('state')}
                    sx={inputStyles}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="ZIP code"
                    value={formData.zipCode}
                    onChange={handleChange('zipCode')}
                    sx={inputStyles}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.sameAsProperty}
                        onChange={handleChange('sameAsProperty')}
                      />
                    }
                    label="Billing address is the same as property address"
                  />
                </Grid>

                {!formData.sameAsProperty && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                        Billing address
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Street 1"
                        value={formData.billingStreet1}
                        onChange={handleChange('billingStreet1')}
                        sx={inputStyles}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="City"
                        value={formData.billingCity}
                        onChange={handleChange('billingCity')}
                        sx={inputStyles}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        label="State"
                        value={formData.billingState}
                        onChange={handleChange('billingState')}
                        sx={inputStyles}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        label="ZIP code"
                        value={formData.billingZipCode}
                        onChange={handleChange('billingZipCode')}
                        sx={inputStyles}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              px: 4,
              py: 1.5,
              fontSize: '1rem'
            }}
          >
            Create Client
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateClient;

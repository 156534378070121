import React, { useEffect, useRef } from 'react';
import { Typography, Paper } from '@mui/material';
import { format, startOfWeek, addDays, isToday, isSameDay } from 'date-fns';
import styles from '../Calendar.module.scss';
import WeekEventCard from './WeekEventCard';

const WeekView = ({ events, selectedDate, onEventUpdated }) => {
  const startDate = startOfWeek(selectedDate);
  const weekDays = [...Array(7)].map((_, i) => addDays(startDate, i));
  const hours = [...Array(24)].map((_, i) => i);
  const gridRef = useRef(null);
  const currentTimeRef = useRef(null);

  // Helper function to get hours from a date object
  const getHours = (date) => date.getHours();

  // Helper function to get minutes from a date object
  const getMinutes = (date) => date.getMinutes();

  // Add the processEventsForDay function here
  const processEventsForDay = (dayEvents) => {
    const sortedEvents = [...dayEvents].sort((a, b) => {
      const aStart = new Date(a.time_slots[0].start_time);
      const bStart = new Date(b.time_slots[0].start_time);
      return aStart - bStart;
    });

    sortedEvents.forEach((event, index) => {
      const eventStart = new Date(event.time_slots[0].start_time);
      const eventEnd = new Date(event.time_slots[0].end_time);
      
      // Calculate duration in minutes
      const duration = (eventEnd - eventStart) / (1000 * 60);
      
      // Count overlapping events before this one
      const overlappingBefore = sortedEvents.slice(0, index).filter(prevEvent => {
        const prevStart = new Date(prevEvent.time_slots[0].start_time);
        const prevEnd = new Date(prevEvent.time_slots[0].end_time);
        return !(eventEnd <= prevStart || eventStart >= prevEnd);
      }).length;

      event.style = {
        width: '95%',
        left: '2.5%',
        zIndex: index + 1,
        transform: `translateY(${overlappingBefore * 4}px)`,
      };
      
      // Mark event as compact if duration is less than 45 minutes
      event.isCompact = duration < 45;
    });

    return sortedEvents;
  };

  useEffect(() => {
    const updateTimeIndicator = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const position = hours * 60 + minutes;

      if (currentTimeRef.current) {
        currentTimeRef.current.style.top = `${position}px`;
      }
    };

    const scrollToCurrentTime = () => {
      if (gridRef.current) {
        const now = new Date();
        const currentHour = now.getHours();
        const scrollPosition = Math.max(0, (currentHour - 1) * 60);
        gridRef.current.scrollTop = scrollPosition;
      }
    };

    updateTimeIndicator();
    scrollToCurrentTime();

    const interval = setInterval(updateTimeIndicator, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleDayCellClick = (day) => {
    // Add your day cell click handler here
    console.log('Day clicked:', format(day, 'yyyy-MM-dd'));
  };

  return (
    <Paper elevation={0} className={styles.weekView}>
      <div className={styles.weekHeader}>
        <div className={styles.timeGutter}></div>
        {weekDays.map((day, index) => (
          <div
            key={day.toString()}
            className={`${styles.dayColumn} ${isToday(day) ? styles.today : ''}`}
          >
            <Typography className={styles.dayName}>{format(day, 'EEE')}</Typography>
            <Typography className={styles.dayNumber}>
              {format(day, 'd')}
            </Typography>
            <Typography className={styles.monthName}>{format(day, 'MMM')}</Typography>
          </div>
        ))}
      </div>
      <div className={styles.weekGrid} ref={gridRef}>
        <div className={styles.timeGutter}>
          {hours.map((hour) => (
            <div key={hour} className={styles.timeSlot}>
              <Typography>{format(new Date().setHours(hour), 'ha')}</Typography>
            </div>
          ))}
        </div>
        {weekDays.map((day, index) => (
          <div
            key={day.toString()}
            className={`${styles.dayColumn} ${isToday(day) ? styles.today : ''}`}
          >
            {hours.map((hour) => (
              <div key={hour} className={styles.timeSlot} />
            ))}
            {events.filter((event) => {
              if (event.time_slots && event.time_slots[0]) {
                return isSameDay(new Date(event.time_slots[0].start_time), day);
              }
              return false;
            }).map((event) => {
              if (event.time_slots && event.time_slots[0]) {
                const startTime = new Date(event.time_slots[0].start_time);
                const endTime = new Date(event.time_slots[0].end_time);
                const startMinutes = startTime.getHours() * 60 + startTime.getMinutes();
                const duration = endTime.getHours() * 60 + endTime.getMinutes() - startMinutes;
                
                return (
                  <div
                    key={event.id}
                    className={styles.eventWrapper}
                    style={{
                      position: 'absolute',
                      top: `${startMinutes}px`,
                      height: `${duration}px`,
                      left: event.style.left,
                      width: event.style.width,
                      zIndex: event.style.zIndex,
                    }}
                  >
                    <WeekEventCard 
                      event={event}
                      onEventUpdated={onEventUpdated}
                      isCompact={event.isCompact}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        ))}
      </div>
      {isToday(selectedDate) && (
        <div
          ref={currentTimeRef}
          className={styles.currentTimeIndicator}
          style={{ zIndex: 5 }}
        >
          <div className={styles.timeMarker}></div>
          <div className={styles.currentTime}>
            {format(new Date(), 'h:mm a')}
          </div>
        </div>
      )}
    </Paper>
  );
};

export default WeekView; 
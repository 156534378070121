import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Collapse
} from '@mui/material';
import {
  User as UserIcon,
  Briefcase as ProjectIcon,
  Calculator as EstimateIcon,
  Receipt as InvoiceIcon,
  Calendar as EventIcon,
  MagnifyingGlass as SearchIcon,
  CaretRight as CaretIcon
} from "@phosphor-icons/react";

function TriggerSelector({ onSelect }) {
  const [expandedTrigger, setExpandedTrigger] = useState(null);
  const selectorRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onSelect?.(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSelect]);

  const handleActionSelect = (trigger, action) => {
    onSelect?.({ ...trigger, action });
  };

  const triggers = [
    { 
      id: 'client', 
      label: 'a client', 
      icon: <UserIcon size={24} weight="regular" />, 
      color: '#4a5df9',
      actions: [
        { id: 'created', label: 'is created' },
        { id: 'scheduled', label: 'is scheduled' }
      ]
    },
    { 
      id: 'project', 
      label: 'a project', 
      icon: <ProjectIcon size={24} weight="regular" />, 
      color: '#4a5df9',
      actions: [
        { id: 'created', label: 'is created' },
        { id: 'status', label: 'has status' }
      ]
    },
    { 
      id: 'event', 
      label: 'an event', 
      icon: <EventIcon size={24} weight="regular" />,
      color: '#4a5df9',
      actions: [
        { id: 'completed', label: 'is completed' },
        { id: 'invoiced', label: 'is invoiced' }
      ]
    },
    { 
      id: 'estimate', 
      label: 'an estimate', 
      icon: <EstimateIcon size={24} weight="regular" />, 
      color: '#4a5df9',
      actions: [
        { id: 'sent', label: 'is sent' },
        { id: 'approved', label: 'is approved' },
        { id: 'declined', label: 'is declined' },
        { id: 'won', label: 'is won' },
        { id: 'converted', label: 'is converted' }
      ]
    },
    { 
      id: 'invoice', 
      label: 'an invoice', 
      icon: <InvoiceIcon size={24} weight="regular" />, 
      color: '#4a5df9',
      actions: [
        { id: 'created', label: 'is created' },
        { id: 'sent', label: 'is sent' },
        { id: 'paid', label: 'is paid' },
        { id: 'overdue', label: 'is overdue' }
      ]
    },
  ];

  return (
    <Paper
      ref={selectorRef}
      elevation={2}
      sx={{
        width: 280,
        backgroundColor: 'white',
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid rgba(74, 93, 249, 0.1)',
      }}
    >
      <Box sx={{ p: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.06)' }}>
        <TextField
          fullWidth
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon 
                size={20} 
                style={{ 
                  marginRight: '8px',
                  color: 'rgba(0, 0, 0, 0.54)'
                }} 
              />
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
              '& fieldset': {
                border: '1px solid rgba(0, 0, 0, 0.06)',
              },
              '&:hover fieldset': {
                border: '1px solid rgba(74, 93, 249, 0.3)',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #4a5df9',
              },
            },
          }}
        />
      </Box>

      <List sx={{ py: 1 }}>
        {triggers.map((trigger) => (
          <React.Fragment key={trigger.id}>
            <ListItem
              button
              onClick={() => setExpandedTrigger(expandedTrigger === trigger.id ? null : trigger.id)}
              sx={{
                py: 1.5,
                px: 2,
                '&:hover': {
                  backgroundColor: 'rgba(74, 93, 249, 0.04)',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 40,
                  color: trigger.color,
                }}
              >
                {trigger.icon}
              </ListItemIcon>
              <ListItemText
                primary={trigger.label}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: '#1a1a1a',
                    fontSize: '0.95rem',
                    fontWeight: 400,
                  }
                }}
              />
              {trigger.actions && (
                <CaretIcon
                  size={20}
                  style={{
                    transform: expandedTrigger === trigger.id ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.2s ease-in-out',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                />
              )}
            </ListItem>
            
            {trigger.actions && (
              <Collapse in={expandedTrigger === trigger.id}>
                <List disablePadding>
                  {trigger.actions.map((action) => (
                    <ListItem
                      key={action.id}
                      button
                      onClick={() => handleActionSelect(trigger, action)}
                      sx={{
                        py: 1,
                        pl: 7,
                        pr: 2,
                        backgroundColor: 'rgba(74, 93, 249, 0.02)',
                        '&:hover': {
                          backgroundColor: 'rgba(74, 93, 249, 0.06)',
                        },
                      }}
                    >
                      <ListItemText
                        primary={action.label}
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#1a1a1a',
                            fontSize: '0.9rem',
                            fontWeight: 400,
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
}

export default TriggerSelector; 
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';
import { ArrowRight } from "@phosphor-icons/react";
import { getFinancialMetrics } from '../../services/api';
import CustomToggle from '../common/CustomToggle';

const TimelineWidget = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('all');
  const buttonRefs = useRef({});
  const containerRef = useRef(null);

  // Define options for CustomToggle
  const timeRangeOptions = [
    { value: 'today', label: 'Today' },
    { value: '7d', label: '7 Day' },
    { value: '30d', label: '30 Day' },
    { value: 'all', label: 'All' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFinancialMetrics();
        console.log('Raw Financial metrics response:', response);

        // Filter data based on time range
        const filteredData = filterDataByTimeRange(response, timeRange);
        
        // Calculate totals from filtered data
        const totals = {
          estimates: {
            count: filteredData.estimates?.length || 0,
            value: (filteredData.estimates || [])
              .reduce((sum, item) => sum + (Number(item.value) || 0), 0)
          },
          projects: {
            count: filteredData.projects?.length || 0,
            value: (filteredData.projects || [])
              .reduce((sum, item) => sum + (Number(item.value) || 0), 0)
          },
          invoices: {
            count: filteredData.invoices?.length || 0,
            value: (filteredData.invoices || [])
              .reduce((sum, item) => sum + (Number(item.value) || 0), 0)
          }
        };

        console.log('Calculated totals:', totals);
        setData(totals);
        setLoading(false);
      } catch (err) {
        console.error('Error details:', err);
        setError('Failed to load data');
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  const filterDataByTimeRange = (data, range) => {
    const now = new Date();
    const cutoffDate = new Date();

    switch (range) {
      case 'today':
        cutoffDate.setHours(0, 0, 0, 0); // Start of today
        break;
      case '7d':
        cutoffDate.setDate(now.getDate() - 7);
        break;
      case '30d':
        cutoffDate.setDate(now.getDate() - 30);
        break;
      default:
        return data; // Return all data for 'all' range
    }

    const filterByDate = (items) => {
      return items?.filter(item => {
        const itemDate = new Date(item.created_at || item.date || item.start_time);
        return itemDate >= cutoffDate;
      }) || [];
    };

    return {
      estimates: filterByDate(data.estimates),
      projects: filterByDate(data.projects),
      invoices: filterByDate(data.invoices)
    };
  };

  // Simplify the time range change handler
  const handleTimeRangeChange = useCallback((newRange) => {
    if (!newRange || newRange === timeRange) return;
    setTimeRange(newRange);
  }, [timeRange]);

  useEffect(() => {
    const button = buttonRefs.current['all'];
    const container = containerRef.current?.querySelector('[role="group"]');
    
    if (button && container) {
      const offset = button.offsetLeft;
      const width = button.offsetWidth;
      container.style.setProperty('--highlight-offset', `${offset}px`);
      container.style.setProperty('--highlight-width', `${width}px`);
    }
  }, []);

  const boxes = data ? [
    { 
      title: 'Estimates', 
      color: '#8884d8',
      count: data.estimates.count,
      value: data.estimates.value
    },
    { 
      title: 'Projects', 
      color: '#82ca9d',
      count: data.projects.count,
      value: data.projects.value
    },
    { 
      title: 'Invoices', 
      color: '#ffc658',
      count: data.invoices.count,
      value: data.invoices.value
    }
  ] : [];

  if (loading) {
    return (
      <Paper 
        elevation={0}
        sx={{ 
          height: '100%',
          background: 'var(--background-paper)',
          border: '1px solid var(--divider)',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={24} />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper 
        elevation={0}
        sx={{ 
          height: '100%',
          background: 'var(--background-paper)',
          border: '1px solid var(--divider)',
          borderRadius: 2,
        }}
      >
        <Typography color="error">{error}</Typography>
      </Paper>
    );
  }

  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: 3,
        background: 'var(--background-paper)',
        border: '1px solid var(--divider)',
        borderRadius: 2,
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 3
      }}>
        <Typography variant="h6">Activity Timeline</Typography>
        
        <CustomToggle
          options={timeRangeOptions}
          value={timeRange}
          onChange={handleTimeRangeChange}
        />
      </Box>
      
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        gap: 1.5
      }}>
        {boxes.map((box, index) => (
          <React.Fragment key={box.title}>
            <Paper
              elevation={0}
              sx={{
                flex: 1,
                p: 1.5,
                border: '1px solid var(--divider)',
                borderRadius: 2,
                background: 'var(--background-paper)',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '3px',
                  backgroundColor: box.color
                }
              }}
            >
              <Typography variant="h6" sx={{ mb: 0.5, fontSize: '1rem' }}>{box.title}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                  Total Count
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 500, fontSize: '1.5rem', lineHeight: 1.2 }}>
                  {box.count}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 0.25, fontSize: '0.75rem' }}>
                  Total Value
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 500, fontSize: '1.5rem', lineHeight: 1.2 }}>
                  ${box.value.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </Typography>
              </Box>
            </Paper>
            {index < boxes.length - 1 && (
              <ArrowRight size={20} weight="light" />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Paper>
  );
};

export default TimelineWidget; 
 
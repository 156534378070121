import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClients } from '../services/api';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Button, Box, Grid, IconButton, Chip, Avatar,
  TextField, InputAdornment, Tooltip, CircularProgress, TablePagination, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import { 
  Users as UsersIcon,
  Plus as PlusIcon,
  User as UserIcon,
  Envelope as EnvelopeIcon,
  Phone as PhoneIcon,
  MapPin as MapPinIcon,
  Folder as FolderIcon,
  MagnifyingGlass as MagnifyingGlassIcon,
  CaretLeft as CaretLeftIcon,
  CaretRight as CaretRightIcon,
  CaretUp as CaretUpIcon,
  CaretDown as CaretDownIcon
} from "@phosphor-icons/react";
import styled from '@emotion/styled';
import styles from './Clients.module.scss';
import { useTheme } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import SearchContainer from './common/SearchContainer';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: '#f4f4f4',
  borderRadius: '10px',
  padding: '3px',
  gap: '3px',
  position: 'relative',
  whiteSpace: 'nowrap',
  fontFamily: 'Urbanist, sans-serif',
  '& .MuiToggleButton-root': {
    minWidth: '80px',
    border: 'none',
    borderRadius: '7px',
    textTransform: 'capitalize',
    fontSize: '0.813rem',
    fontWeight: 500,
    padding: '5px 12px',
    letterSpacing: '0.2px',
    color: 'var(--text-secondary)',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: 'Urbanist, sans-serif',
    '&:hover': {
      backgroundColor: 'rgba(var(--primary-rgb), 0.08)',
    },
    '&.Mui-selected': {
      backgroundColor: '#ffffff',
      color: 'var(--text-primary)',
      fontWeight: 600,
      letterSpacing: '0.3px',
      boxShadow: '0 2px 4px rgba(145, 158, 171, 0.08)',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'No phone provided';
  // Remove all non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, '');
  // Format as (XXX) XXX-XXXX
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
};

const Clients = () => {
  const theme= useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalClients, setTotalClients] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ];

  useEffect(() => {
    fetchClients(1, rowsPerPage);
  }, []);

  const fetchClients = async (pageNum = 1, limit = rowsPerPage) => {
    try {
      setLoading(true);
      const data = await getClients(pageNum, searchTerm, limit);
      setClients(data.clients);
      setTotalClients(data.total);
      setFilteredTotal(data.total);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setError('Failed to fetch clients. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const paginatedClients = clients;

  const filteredClients = useMemo(() => {
    return clients.filter(client => {
      const searchLower = searchTerm.toLowerCase();
      return (
        client.name?.toLowerCase().includes(searchLower) ||
        client.company?.toLowerCase().includes(searchLower) ||
        client.email?.toLowerCase().includes(searchLower) ||
        client.phone?.includes(searchTerm)
      );
    });
  }, [clients, searchTerm]);

  const headerCells = [
    { 
      label: 'CLIENT INFO', 
      align: 'center',
      icon: <UserIcon size={20} weight="light" style={{ color: '#FF4842', marginRight: 8 }} />,
      sortable: true,
      field: 'name',
      sortDirection: sortField === 'name' ? sortDirection : null,
      onClick: () => handleSort('name')
    },
    { 
      label: 'EMAIL', 
      align: 'center',
      icon: <EnvelopeIcon size={20} weight="light" style={{ color: '#2065D1', marginRight: 8 }} />
    },
    { 
      label: 'PHONE', 
      align: 'center',
      icon: <PhoneIcon size={20} weight="light" style={{ color: '#FFC107', marginRight: 8 }} />
    },
    { 
      label: 'ADDRESS', 
      align: 'center',
      icon: <MapPinIcon size={20} weight="light" style={{ color: '#00AB55', marginRight: 8 }} />
    },
    { 
      label: 'PROJECTS', 
      align: 'center',
      icon: <FolderIcon size={20} weight="light" style={{ color: '#7635DC', marginRight: 8 }} />
    }
  ];

  const handleRowClick = (clientId) => {
    navigate(`/admin/clients/${clientId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchClients(1, newRowsPerPage);
  };

  const checkScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  }, []);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = 200;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [checkScroll]);

  const handleSort = (field) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortField(field);
    
    const sortedClients = [...clients].sort((a, b) => {
      if (!a[field] && !b[field]) return 0;
      if (!a[field]) return 1;
      if (!b[field]) return -1;
      
      const comparison = a[field].toLowerCase().localeCompare(b[field].toLowerCase());
      return isAsc ? -comparison : comparison;
    });
    
    setClients(sortedClients);
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <CircularProgress />
    </Box>
  );

  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <Typography color="error">{error}</Typography>
    </Box>
  );

  return (
    <div className={styles.pageContainer}>
      <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" className={styles.pageTitle}>
          Clients
        </Typography>
        <Button
          variant="contained"
          startIcon={<PlusIcon size={20} weight="bold" />}
          onClick={() => navigate('/admin/create-client')}
          sx={{
            backgroundColor: '#4a5df9',
            color: '#fff',
            fontFamily: 'Urbanist, sans-serif !important',
            letterSpacing: '1px',
            textTransform: 'none',
            fontWeight: 900,
            fontSize: '0.875rem',
            padding: '8px 20px',
            borderRadius: '8px',
            height: '40px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#3c50fa',
              boxShadow: 'none',
            },
          }}
          disableRipple
        >
          Create Client
        </Button>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box></Box>

        <Box display="flex" gap={2} alignItems="center">
          <div className={styles.sliderContainer}>
            {canScrollLeft && (
              <IconButton 
                className={styles.scrollButton}
                onClick={() => handleScroll('left')}
                size="small"
              >
                <CaretLeftIcon size={20} weight="bold" />
              </IconButton>
            )}
            
            <div 
              ref={containerRef} 
              className={styles.toggleContainer}
              onScroll={checkScroll}
            >
              <StyledToggleButtonGroup
                value={selectedStatus}
                exclusive
                onChange={(event, newStatus) => {
                  if (newStatus !== null) {
                    setSelectedStatus(newStatus);
                  }
                }}
                aria-label="status filter"
              >
                {statusOptions.map(status => (
                  <ToggleButton key={status.value} value={status.value} disableRipple>
                    {status.label}
                  </ToggleButton>
                ))}
              </StyledToggleButtonGroup>
            </div>

            {canScrollRight && (
              <IconButton 
                className={styles.scrollButton}
                onClick={() => handleScroll('right')}
                size="small"
              >
                <CaretRightIcon size={20} weight="bold" />
              </IconButton>
            )}
          </div>
        </Box>
      </Box>

      <Box mb={3}>
        <SearchContainer
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search clients..."
          width="20%"
        />
      </Box>

      <Paper elevation={0} className={styles.contentPaper}>
        <TableContainer className={styles.tableContainer}>
          <Table sx={{ border: 'none' }}>
            <TableHead>
              <TableRow>
                {headerCells.map((cell, index) => (
                  <TableCell 
                    key={index} 
                    align="center"
                    className={styles.tableHeaderCell}
                    onClick={cell.onClick}
                    style={{ 
                      cursor: cell.sortable ? 'pointer' : 'default',
                      backgroundColor: 'var(--background-secondary)',
                      borderBottom: '1px solid #e0e0e0 !important',
                      fontFamily: 'Urbanist, sans-serif',
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      color: 'var(--text-secondary)!important',
                      letterSpacing: '0.5px',
                      padding: '12px 16px',
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      {cell.icon}
                      <Typography 
                        sx={{ 
                          ml: 1,
                          fontFamily: 'Urbanist, sans-serif',
                          fontSize: '0.75rem',
                          fontWeight: 600,
                          color: 'var(--text-secondary)!important',
                          letterSpacing: '0.5px',
                        }}
                      >
                        {cell.label}
                      </Typography>
                      {cell.sortable && (
                        <Box display="flex" flexDirection="column" ml={1} sx={{ lineHeight: 0 }}>
                          <CaretUpIcon 
                            size={12}
                            weight="bold"
                            style={{ 
                              color: cell.sortDirection === 'asc' 
                                ? 'var(--primary-main)'
                                : 'var(--text-disabled)'
                            }} 
                          />
                          <CaretDownIcon 
                            size={12}
                            weight="bold"
                            style={{ 
                              marginTop: '-4px',
                              color: cell.sortDirection === 'desc' 
                                ? 'var(--primary-main)'
                                : 'var(--text-disabled)'
                            }} 
                          />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((client) => (
                  <TableRow 
                    key={client.id}
                    hover
                    onClick={() => handleRowClick(client.id)}
                    sx={{ 
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'var(--background-hover)',
                      },
                      '& .MuiTableCell-root': {
                        padding: '12px 16px',
                        borderBottom: '1px solid #e0e0e0',
                      }
                    }}
                  >
                    <TableCell align="center">
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="subtitle1" fontWeight="medium">
                          {client.name}
                        </Typography>
                        {client.company && client.company.trim() !== '' && (
                          <Typography variant="body2" color="text.secondary">
                            {client.company}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">{client.email || 'No email provided'}</TableCell>
                    <TableCell align="center">{formatPhoneNumber(client.phone)}</TableCell>
                    <TableCell align="center">{client.address || 'No address provided'}</TableCell>
                    <TableCell align="center">
                      <Chip 
                        label={`${client.projects.length} Projects`}
                        sx={{ 
                          backgroundColor: client.projects.length > 0 ? '#E3F2FD' : '#F4F6F8',
                          color: client.projects.length > 0 ? '#1565C0' : '#637381',
                          fontFamily: 'Urbanist, sans-serif',
                          borderRadius: '6px',
                          fontSize: '0.75rem',
                          fontWeight: 700,
                          letterSpacing: '0.5px',
                          textTransform: 'uppercase',
                          height: '24px',
                          '& .MuiChip-label': {
                            padding: '0 8px',
                          }
                        }}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={filteredTotal}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100, 250]}
          sx={{
            '.MuiTablePagination-select': {
              paddingTop: '0.5rem',
              color: 'var(--text-primary)',
            },
            '.MuiTablePagination-selectLabel': {
              color: 'var(--text-primary)',
            },
            '.MuiTablePagination-displayedRows': {
              color: 'var(--text-primary)',
            },
            '.MuiTablePagination-actions': {
              color: 'var(--text-primary)',
              '& .MuiIconButton-root': {
                color: 'var(--text-primary)',
              },
              '& .Mui-disabled': {
                color: 'var(--text-disabled)',
              }
            },
            '.MuiSelect-icon': {
              color: 'var(--text-primary)',
            }
          }}
        />

        {filteredClients.length === 0 && (
          <Box textAlign="center" py={4}>
            <Typography variant="h6" color="textSecondary">
              No clients found
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Try adjusting your search or create a new client
            </Typography>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default Clients;

import React, { useRef, useEffect, useState } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
} from '@mui/material';

function TimeSelector({ onSelect, includeBeforeStart = false }) {
  const selectorRef = useRef(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTiming, setSelectedTiming] = useState(null);

  const times = [
    { id: '10min', label: '10 minutes', value: 10 },
    { id: '15min', label: '15 minutes', value: 15 },
    { id: '30min', label: '30 minutes', value: 30 },
    { id: '1hour', label: '1 hour', value: 60 },
    { id: '2hours', label: '2 hours', value: 120 },
    { id: '1day', label: '1 day', value: 1440 },
    { id: '3days', label: '3 days', value: 4320 },     // 3 * 24 * 60
    { id: '1week', label: '1 week', value: 10080 },    // 7 * 24 * 60
    { id: '1month', label: '1 month', value: 43200 },  // 30 days
    { id: '3months', label: '3 months', value: 129600 }, // 90 days
    { id: '6months', label: '6 months', value: 259200 }, // 180 days
    { id: 'immediately', label: 'immediately', value: 0 }
  ];

  const timings = [
    { id: 'after', label: 'after' },
    { id: 'before_start', label: 'before start' }
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onSelect?.(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSelect]);

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    
    if (time.id === 'immediately') {
      onSelect?.({ 
        ...time, 
        timing: null,
        displayText: 'immediately' 
      });
    } else if (selectedTiming) {
      // If timing is already selected, send complete selection
      onSelect?.({ 
        ...time, 
        timing: selectedTiming,
        displayText: `${time.label} ${selectedTiming.label}`
      });
    }
  };

  const handleTimingSelect = (timing) => {
    setSelectedTiming(timing);
    
    if (selectedTime && selectedTime.id !== 'immediately') {
      // If time is already selected, send complete selection
      onSelect?.({ 
        ...selectedTime, 
        timing: timing,
        displayText: `${selectedTime.label} ${timing.label}`
      });
    }
  };

  return (
    <Paper
      ref={selectorRef}
      elevation={2}
      sx={{
        width: 280,
        maxHeight: '400px',
        backgroundColor: 'white',
        borderRadius: '12px',
        overflow: 'auto',
        border: '1px solid rgba(74, 93, 249, 0.1)',
        p: 2,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: 'rgba(0, 0, 0, 0.2)',
        }
      }}
    >
      <Typography
        sx={{
          color: 'rgba(26, 26, 26, 0.5)',
          fontSize: '0.875rem',
          mb: 1,
        }}
      >
        Choose Time
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mb: 2,
        }}
      >
        {times.map((time) => (
          <Button
            key={time.id}
            onClick={() => handleTimeSelect(time)}
            variant={selectedTime?.id === time.id ? "contained" : "outlined"}
            sx={{
              borderRadius: '16px',
              textTransform: 'none',
              px: 2,
              py: 0.5,
              backgroundColor: selectedTime?.id === time.id ? 'rgba(74, 93, 249, 0.1)' : 'transparent',
              borderColor: 'rgba(74, 93, 249, 0.3)',
              color: selectedTime?.id === time.id ? '#4a5df9' : 'rgba(26, 26, 26, 0.7)',
              '&:hover': {
                backgroundColor: 'rgba(74, 93, 249, 0.05)',
                borderColor: 'rgba(74, 93, 249, 0.5)',
              },
            }}
          >
            {time.label}
          </Button>
        ))}
      </Box>

      {selectedTime?.id !== 'immediately' && (
        <>
          <Typography
            sx={{
              color: 'rgba(26, 26, 26, 0.5)',
              fontSize: '0.875rem',
              mb: 1,
            }}
          >
            Choose Timing
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              mb: 2,
            }}
          >
            {timings.map((timing) => (
              <Button
                key={timing.id}
                onClick={() => handleTimingSelect(timing)}
                variant={selectedTiming?.id === timing.id ? "contained" : "outlined"}
                sx={{
                  borderRadius: '16px',
                  textTransform: 'none',
                  px: 2,
                  py: 0.5,
                  backgroundColor: selectedTiming?.id === timing.id ? 'rgba(74, 93, 249, 0.1)' : 'transparent',
                  borderColor: 'rgba(74, 93, 249, 0.3)',
                  color: selectedTiming?.id === timing.id ? '#4a5df9' : 'rgba(26, 26, 26, 0.7)',
                  '&:hover': {
                    backgroundColor: 'rgba(74, 93, 249, 0.05)',
                    borderColor: 'rgba(74, 93, 249, 0.5)',
                  },
                }}
              >
                {timing.label}
              </Button>
            ))}
          </Box>
        </>
      )}
    </Paper>
  );
}

export default TimeSelector; 
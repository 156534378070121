import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Paper, Typography, Box, Avatar, Chip, Tooltip, CircularProgress, AvatarGroup, Divider } from '@mui/material';
import { getAllProjects, getEstimates, getInvoices } from '../../services/api';
import { Link } from 'react-router-dom';
import { ArrowRight } from "@phosphor-icons/react";
import CustomToggle from '../common/CustomToggle';

// Define the view options for CustomToggle
const viewOptions = [
  { value: 'projects', label: 'Projects' },
  { value: 'estimates', label: 'Estimates' },
  { value: 'invoices', label: 'Invoices' }
];

function RecentActivitiesWidget() {
  const [activeView, setActiveView] = useState('projects');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Simplify the view change handler
  const handleViewChange = useCallback((newView) => {
    if (!newView || newView === activeView) return;
    setActiveView(newView);
  }, [activeView]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let response;
        switch (activeView) {
          case 'projects':
            response = await getAllProjects('?limit=5&include=client,employees');
            setData(response.slice(0, 5).map(project => ({
              id: project.id,
              title: project.name,
              clientName: project.client?.name || 'No Client',
              clientAddress: project.client?.address || 'No Address',
              total_value: project.total_value || 0,
              status: project.status,
              employees: project.employees || [],
              created_at: project.created_at,
              type: 'project'
            })));
            break;
          case 'estimates':
            console.log('Fetching estimates...');
            response = await getEstimates();
            console.log('Raw estimates response:', response);
            setData(response.slice(0, 5).map(estimate => ({
              id: estimate.id,
              title: `Estimate #${estimate.estimate_number}`,
              clientName: estimate.customer_details?.name || 'No Client',
              clientAddress: estimate.service_address || estimate.customer_details?.address || 'No Address',
              total_value: estimate.items?.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0) || 0,
              status: estimate.status,
              created_at: estimate.created_at,
              type: 'estimate',
              project_name: estimate.project_name
            })));
            break;
          case 'invoices':
            console.log('Fetching invoices...');
            response = await getInvoices();
            console.log('Raw invoices response:', response);
            setData(response.slice(0, 5).map(invoice => ({
              id: invoice.id,
              title: `Invoice #${invoice.invoice_number}`,
              clientName: invoice.customer_details?.name || 'No Client',
              clientAddress: invoice.customer_details?.address || 'No Address',
              total_value: invoice.items?.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0) || 0,
              status: invoice.status,
              due_date: invoice.due_date,
              created_at: invoice.created_at,
              type: 'invoice',
              project_name: invoice.project_name
            })));
            break;
        }
        setLoading(false);
      } catch (err) {
        console.error(`Error fetching ${activeView}:`, err);
        setError(`Failed to load ${activeView}`);
        setLoading(false);
      }
    };

    fetchData();
  }, [activeView]);

  const getTitleAndLink = () => {
    switch (activeView) {
      case 'projects':
        return { title: 'Recent Projects', link: '/admin/projects' };
      case 'estimates':
        return { title: 'Recent Estimates', link: '/admin/estimates' };
      case 'invoices':
        return { title: 'Recent Invoices', link: '/admin/invoices' };
      default:
        return { title: 'Recent Activities', link: '/' };
    }
  };

  const getStatusColor = (status, type) => {
    if (type === 'project') {
      const statusColors = {
        pending_scheduling: '#E65100',
        scheduled: '#1565C0',
        in_progress: '#2E7D32',
        pending_invoicing: '#7B1FA2',
        completed: '#303F9F'
      };
      return statusColors[status] || '#666666';
    }

    console.log(`Getting status color for ${type} with status: ${status}`);
    const otherStatusColors = {
      estimate: {
        draft: '#666666',
        sent: '#1565C0',
        accepted: '#2E7D32',
        rejected: '#C62828',
        completed: '#303F9F'
      },
      invoice: {
        draft: '#666666',
        sent: '#1565C0',
        paid: '#2E7D32',
        overdue: '#C62828',
        void: '#757575'
      }
    };
    return otherStatusColors[type]?.[status] || '#666666';
  };

  const getStatusBgColor = (status, type) => {
    if (type === 'project') {
      const statusBgColors = {
        pending_scheduling: '#FFF3E0',
        scheduled: '#E3F2FD',
        in_progress: '#E8F5E9',
        pending_invoicing: '#F3E5F5',
        completed: '#E8EAF6'
      };
      return statusBgColors[status] || '#f5f5f5';
    }

    console.log(`Getting background color for ${type} with status: ${status}`);
    const otherStatusBgColors = {
      estimate: {
        draft: '#F5F5F5',
        sent: '#E3F2FD',
        accepted: '#E8F5E9',
        rejected: '#FFEBEE'
      },
      invoice: {
        draft: '#F5F5F5',
        sent: '#E3F2FD',
        paid: '#E8F5E9',
        overdue: '#FFEBEE'
      }
    };
    return otherStatusBgColors[type]?.[status] || '#F5F5F5';
  };

  if (loading) {
    return (
      <Paper
        elevation={0}
        sx={{
          p: 2,
          height: '100%',
          border: '1px solid var(--divider)',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={24} />
      </Paper>
    );
  }

  const { title, link } = getTitleAndLink();

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        border: '1px solid var(--divider)',
        borderRadius: 2,
        bgcolor: 'var(--background-paper)'
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 3,
      }}>
        <Typography variant="h6">{title}</Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <CustomToggle
            options={viewOptions}
            value={activeView}
            onChange={handleViewChange}
          />
          <Link
            to={link}
            style={{
              textDecoration: 'none',
              color: 'var(--text-secondary)',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              fontSize: '0.875rem',
              fontWeight: 500
            }}
          >
            See all
            <ArrowRight weight="bold" style={{ marginLeft: '4px' }} />
          </Link>
        </Box>
      </Box>

      <Box>
        {data.map((item, index) => (
          <React.Fragment key={`${item.type}-${item.id}`}>
            <Link
              to={`/admin/${item.type}s/${item.id}`}
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  p: 1.5,
                  px: 3,
                  backgroundColor: 'var(--background-default)',
                  transition: 'background-color 0.2s ease',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'var(--background-secondary)'
                  },
                  cursor: 'pointer',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 8,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '4px',
                    height: '60%',
                    backgroundColor: getStatusColor(item.status, item.type),
                    borderRadius: '2px'
                  }
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      fontWeight: 600, 
                      mb: 0.25,
                      letterSpacing: '-0.01em'
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      display: 'block',
                      color: 'text.secondary',
                      mb: 0.25,
                      fontWeight: 500,
                      letterSpacing: '0.01em',
                      lineHeight: 1.3
                    }}
                  >
                    {item.clientName}
                  </Typography>
                  {item.type === 'project' && item.clientAddress && (
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: 'text.secondary',
                        letterSpacing: '0.01em'
                      }}
                    >
                      {item.clientAddress}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'flex-end',
                  gap: 1
                }}>
                  <Chip
                    label={`$${item.total_value.toLocaleString()}`}
                    size="small"
                    sx={{
                      backgroundColor: getStatusBgColor(item.status, item.type),
                      color: getStatusColor(item.status, item.type),
                      fontWeight: 600,
                      minWidth: '80px',
                      height: '24px',
                      '& .MuiChip-label': {
                        px: 1.5,
                        letterSpacing: '0.01em'
                      }
                    }}
                  />
                  
                  {item.type === 'project' && item.employees?.length > 0 && (
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5
                    }}>
                      <AvatarGroup
                        max={4}
                        sx={{
                          '& .MuiAvatar-root': {
                            width: 20,
                            height: 20,
                            fontSize: '0.7rem',
                            border: '1.5px solid var(--background-paper)'
                          }
                        }}
                      >
                        {item.employees.map((employee) => (
                          <Tooltip key={employee.id} title={employee.name || 'Unknown Employee'}>
                            <Avatar
                              sx={{
                                bgcolor: `hsl(${(employee.id * 90) % 360}, 70%, 50%)`
                              }}
                            >
                              {(employee.name || 'UE').slice(0, 2).toUpperCase()}
                            </Avatar>
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </Box>
                  )}
                </Box>
              </Box>
            </Link>
            {index < data.length - 1 && (
              <Divider 
                sx={{ 
                  borderColor: 'var(--divider)',
                  opacity: 0.3
                }} 
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Paper>
  );
}

export default RecentActivitiesWidget; 
import React from 'react';
import { Typography, Tooltip, Avatar } from '@mui/material';
import styles from './PipelineView.module.scss';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import { Phone, User } from '@phosphor-icons/react';
import { format } from 'date-fns';

const ProjectPipelineView = ({ projects }) => {
  const navigate = useNavigate();

  const stageOrder = [
    'Pending Scheduling',
    'Scheduled',
    'In Progress',
    'Pending Invoice',
    'Completed'
  ];

  // Group projects by stage and calculate total value
  const groupedProjects = projects.reduce((groups, project) => {
    const status = project.status?.toLowerCase() || 'pending_scheduling';
    const displayStatus = status.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
    if (!groups[displayStatus]) {
      groups[displayStatus] = {
        projects: [],
        totalValue: 0,
        count: 0
      };
    }
    groups[displayStatus].projects.push(project);
    groups[displayStatus].totalValue += project.total_value || 0;
    groups[displayStatus].count += 1;
    return groups;
  }, {});

  const handleProjectClick = (projectId, event) => {
    if (event.target.closest('button') || event.target.closest('a')) {
      return;
    }
    navigate(`/admin/projects/${projectId}`);
  };

  return (
    <div className={styles.pipelineWrapper}>
      <div className={styles.pipelineContainer}>
        {stageOrder.map((status, index) => (
          <div key={status} className={styles.pipelineStage}>
            <div className={styles.stageHeader}>
              <div className={styles.stageTitle}>
                {status}
              </div>
              <div className={styles.stageMetrics}>
                <span className={styles.stageCount}>
                  {groupedProjects[status]?.projects?.length || 0} projects
                </span>
                <span className={styles.stageValue}>
                  ${(groupedProjects[status]?.totalValue || 0).toLocaleString()}
                </span>
              </div>
            </div>
            <div className={styles.stageContent}>
              {(groupedProjects[status]?.projects || []).map((project) => (
                <div 
                  key={project.id} 
                  className={styles.projectCard}
                  onClick={(e) => handleProjectClick(project.id, e)}
                  role="button"
                  tabIndex={0}
                >
                  <div className={styles.cardHeader}>
                    <Typography className={styles.projectName}>
                      {project.name || 'Untitled Project'}
                    </Typography>
                    <Typography className={styles.dateText}>
                      {project.start_time 
                        ? format(new Date(project.start_time), 'MMM d, yyyy')
                        : 'No date'
                      }
                    </Typography>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.contactInfo}>
                      <div className={styles.infoItem}>
                        <User className={styles.infoIcon} weight="regular" />
                        <Typography className={styles.clientName}>
                          {project.client?.company || project.client?.name || 'No Client'}
                        </Typography>
                      </div>
                      {project.address && (
                        <div className={styles.infoItem}>
                          <LocationOnIcon className={styles.infoIcon} />
                          <span title={project.address}>
                            {project.address.length > 30 
                              ? `${project.address.substring(0, 30)}...` 
                              : project.address}
                          </span>
                        </div>
                      )}
                      {project.client?.phone && (
                        <div className={styles.infoItem}>
                          <Phone className={styles.infoIcon} weight="regular" />
                          <span>
                            {project.client.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                          </span>
                        </div>
                      )}
                      {project.client?.email && (
                        <div className={styles.infoItem}>
                          <EmailIcon className={styles.infoIcon} />
                          <span>{project.client.email}</span>
                        </div>
                      )}
                      {project.client?.website && (
                        <div className={styles.infoItem}>
                          <LanguageIcon className={styles.infoIcon} />
                          <span>{project.client.website}</span>
                        </div>
                      )}
                    </div>
                    <Typography className={styles.projectValue}>
                      ${project.total_value?.toLocaleString() || '0'}
                    </Typography>
                    {project.employees && project.employees.length > 0 && (
                      <div className={styles.employeeAvatars}>
                        {project.employees.slice(0, 3).map((employee) => (
                          <Tooltip key={employee.id} title={employee.name}>
                            <Avatar className={styles.employeeAvatar}>
                              {employee.name?.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        ))}
                        {project.employees.length > 3 && (
                          <Tooltip title={`${project.employees.length - 3} more`}>
                            <div className={styles.moreEmployees}>
                              +{project.employees.length - 3}
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectPipelineView; 
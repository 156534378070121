import React from 'react';
import styled from 'styled-components';

const BaseInput = ({ label, value, onChange, type = "text", name, ...props }) => {
  return (
    <StyledWrapper>
      <div className="inputGroup">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          required
          autoComplete="off"
          placeholder=" "
          {...props}
        />
        <label htmlFor={name}>{label}</label>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .inputGroup {
    font-family: 'Segoe UI', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 100%;
    position: relative;
    height: 45px;
  }

  .inputGroup input {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 8px;
    width: 100%;
    height: 45px;
    box-sizing: border-box;
  }

  .inputGroup label {
    font-size: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
  }

  .inputGroup input:focus ~ label,
  .inputGroup input:not(:placeholder-shown) ~ label {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    margin-left: 1.3em;
    padding: 0.4em;
    background-color: var(--background-primary);
    top: 0;
  }

  .inputGroup input:focus,
  .inputGroup input:not(:placeholder-shown) {
    border-color: rgb(150, 150, 200);
  }
`;

export default BaseInput; 
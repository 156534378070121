import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
  Menu,
  MenuItem,
  Tooltip,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InventoryIcon from '@mui/icons-material/Inventory';
import PercentIcon from '@mui/icons-material/Percent';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DescriptionIcon from '@mui/icons-material/Description';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SaveIcon from '@mui/icons-material/Save';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


const getTypeStyles = (type) => {
  switch(type) {
    case 'package':
      return {
        backgroundColor: 'rgba(76, 175, 80, 0.08)',
        color: '#4caf50',
        border: '1px solid #4caf50'
      };
    case 'inventory':
      return {
        backgroundColor: 'rgba(156, 39, 176, 0.08)',
        color: '#9c27b0',
        border: '1px solid #9c27b0'
      };
    case 'pricing':
      return {
        backgroundColor: 'rgba(255, 152, 0, 0.08)',
        color: '#ff9800',
        border: '1px solid #ff9800'
      };
    default: // saved
      return {
        backgroundColor: 'rgba(33, 150, 243, 0.08)',
        color: '#2196f3',
        border: '1px solid #2196f3'
      };
  }
};

const getTypeLabel = (type) => {
  switch(type) {
    case 'package':
      return 'Package';
    case 'inventory':
      return 'Inventory';
    case 'pricing':
      return 'Pricing Rule';
    default:
      return 'Saved Item';
  }
};

const SortableItem = ({ item, index, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id || `temp-id-${index}` });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'flex-start',
        bgcolor: 'white',
        borderRadius: '8px',
        mb: 2
      }}>
        <div {...attributes} {...listeners} style={{ padding: '16px', cursor: 'grab' }}>
          <DragIndicatorIcon />
        </div>
        
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ 
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                overflow: 'hidden',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '& .MuiInputBase-root': {
                  borderRadius: 0
                }
              }}>
                {(item.title?.trim() && !item.saved && !item.hideLibraryBanner) && (
                  <Box sx={{ 
                    borderLeft: '1px solid #2196f3',
                    borderRight: '1px solid #2196f3',
                    borderTop: '1px solid #2196f3',
                    borderRadius: '8px',
                    padding: '12px 20px',
                    paddingBottom: '20px',
                    backgroundColor: 'rgba(33, 150, 243, 0.04)',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    gap: 2,
                    position: 'absolute',
                    top: -52,
                    left: -1,
                    right: -1,
                    zIndex: 1,
                    marginBottom: '25px'
                  }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#2196f3',
                        fontFamily: 'Urbanist, sans-serif',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        marginTop: '4px'
                      }}
                    >
                      💡 Add to your library for quick access
                    </Typography>
                    <Button
                      onClick={() => {
                        console.log('Save button clicked for index:', index, 'Item:', item);
                        props.onSaveItem(index);
                      }}
                      size="small"
                      sx={{
                        color: '#2196f3',
                        backgroundColor: '#fff',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                        padding: '6px 16px',
                        boxShadow: 'none',
                        fontFamily: 'Urbanist, sans-serif',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        textTransform: 'none',
                        marginTop: '-4px',
                        '&:hover': {
                          backgroundColor: '#f8f9fa',
                          borderColor: '#e0e0e0',
                          boxShadow: 'none'
                        }
                      }}
                    >
                      Save Item
                    </Button>
                  </Box>
                )}
                <Autocomplete
                  freeSolo
                  options={props.savedItems || []}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') return option;
                    return option?.title || option?.name || '';
                  }}
                  value={item}
                  inputValue={item.title || ''}
                  onChange={(event, newValue) => {
                    if (newValue && typeof newValue === 'object') {
                      if (newValue.type === 'package' && newValue.items) {
                        newValue.items.forEach((packageItem, i) => {
                          props.onAutocompleteSelect(index + i, {
                            ...packageItem,
                            fromPackage: newValue.name,
                            packageId: newValue.id
                          });
                        });
                      } else {
                        props.onAutocompleteSelect(index, newValue);
                      }
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        width: '100%',
                        p: 1
                      }}>
                        {/* Main item header */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {option.title || option.name}
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            {/* Price for non-package items */}
                            {option.type !== 'package' && (
                              <Typography sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                border: '1px solid #4caf50',
                                borderRadius: '4px',
                                padding: '2px 6px',
                                color: '#666'
                              }}>
                                <AttachMoneyIcon sx={{ fontSize: 16, mr: -0.5 }} />
                                {option.unitPrice || option.unit_price || option.price || option.base_rate || 0}
                              </Typography>
                            )}
                            
                            {/* Type Badge */}
                            <Typography sx={{ 
                              padding: '2px 8px',
                              borderRadius: '4px',
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              ...getTypeStyles(option.type)
                            }}>
                              {getTypeLabel(option.type)}
                            </Typography>
                          </Box>
                        </Box>

                        {/* Description for all items */}
                        {option.description && (
                          <Typography variant="body2" sx={{ color: '#666', mt: 0.5 }}>
                            {option.description?.length > 120 
                              ? `${option.description.substring(0, 120)}...` 
                              : option.description}
                          </Typography>
                        )}

                        {/* Package items list */}
                        {option.type === 'package' && option.items && (
                          <Box sx={{ 
                            mt: 1,
                            ml: 2,
                            pl: 2,
                            borderLeft: '2px solid #4caf50'
                          }}>
                            {option.items.map((packageItem, idx) => (
                              <Box 
                                key={idx}
                                sx={{ 
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  py: 0.5,
                                  borderBottom: idx !== option.items.length - 1 ? '1px dashed #e0e0e0' : 'none'
                                }}
                              >
                                <Box>
                                  <Typography variant="body2" sx={{ color: '#666' }}>
                                    {packageItem.title || packageItem.name}
                                  </Typography>
                                  {packageItem.description && (
                                    <Typography variant="caption" sx={{ color: '#666', display: 'block' }}>
                                      {packageItem.description?.length > 80 
                                        ? `${packageItem.description.substring(0, 80)}...` 
                                        : packageItem.description}
                                    </Typography>
                                  )}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                  <Typography variant="caption" sx={{ color: '#666' }}>
                                    Qty: {packageItem.quantity || 1}
                                  </Typography>
                                  <Typography variant="caption" sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center',
                                    color: '#666'
                                  }}>
                                    <AttachMoneyIcon sx={{ fontSize: 14, mr: -0.5 }} />
                                    {packageItem.unit_price || packageItem.price || 0}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </li>
                  )}
                  onInputChange={(event, newValue, reason) => {
                    if (reason === 'input') {
                      props.onEdit({ 
                        index, 
                        field: 'title', 
                        value: newValue 
                      });
                    }
                  }}
                  filterOptions={(options, { inputValue }) => {
                    const searchText = inputValue.toLowerCase();
                    return options.filter(option => {
                      const title = (option.title || option.name || '').toLowerCase();
                      const description = (option.description || '').toLowerCase();
                      return title.includes(searchText) || description.includes(searchText);
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Title"
                    />
                  )}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="Description"
                  value={item.description || ''}
                  onChange={(e) => props.onEdit({
                    index,
                    field: 'description',
                    value: e.target.value
                  })}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderTop: '1px solid #e0e0e0',
                    }
                  }}
                />

                {(item.type || item.fromPackage) && (
                  <Box sx={{ 
                    mt: 1,
                    mx: 1,
                    mb: 1,
                    p: 1,
                    borderTop: '1px solid #e0e0e0',
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    borderRadius: '0 0 4px 4px'
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {item.type && (
                        <Typography sx={{ 
                          display: 'inline-block',
                          padding: '2px 8px',
                          borderRadius: '4px',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                          ...getTypeStyles(item.type)
                        }}>
                          {getTypeLabel(item.type)}
                        </Typography>
                      )}
                      
                      {item.fromPackage && (
                        <Typography sx={{ 
                          display: 'inline-flex',
                          alignItems: 'center',
                          padding: '2px 8px',
                          borderRadius: '4px',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                          backgroundColor: 'rgba(76, 175, 80, 0.08)',
                          color: '#4caf50',
                          border: '1px solid #4caf50'
                        }}>
                          <ListAltIcon sx={{ fontSize: 14, mr: 0.5 }} />
                          From: {item.fromPackage}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="number"
                    placeholder="Qty"
                    value={item.quantity ?? 1}
                    onChange={(e) => {
                      props.onEdit({
                        index,
                        field: 'quantity',
                        value: e.target.value === '' ? 1 : Number(e.target.value)
                      });
                    }}
                    inputProps={{
                      min: 0,
                      step: 1
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        '&:hover, &.Mui-focused': {
                          borderColor: '#2196f3'
                        }
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="number"
                    placeholder="Price"
                    value={item.unit_price || item.unitPrice || ''}
                    onChange={(e) => {
                      props.onEdit({
                        index,
                        field: 'unit_price',
                        value: e.target.value === '' ? 0 : Number(e.target.value)
                      });
                    }}
                    inputProps={{
                      min: 0,
                      step: 0.01
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        '&:hover, &.Mui-focused': {
                          borderColor: '#2196f3'
                        }
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    disabled
                    value={`$${((Number(item.quantity) || 1) * (Number(item.unit_price || item.unitPrice) || 0)).toFixed(2)}`}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: '#f5f5f5',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                </Typography>
                
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {/* Buttons together */}
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      onClick={() => props.onDelete(index)}
                      size="small"
                      sx={{
                        color: '#f44336',
                        backgroundColor: '#fff',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        whiteSpace: 'nowrap',
                        minWidth: 'auto',
                        padding: '6px 16px',
                        boxShadow: 'none',
                        fontFamily: 'Urbanist, sans-serif',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#f8f9fa',
                          borderColor: '#e0e0e0',
                          boxShadow: 'none'
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

const ActiveItemsList = ({ items = [], onEdit, onAddItem, onDelete, savedItems, onAutocompleteSelect, onSaveItem, setIsManageModalOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {items.map((item, index) => (
        <SortableItem 
          key={item.id || `temp-id-${index}`}
          item={item}
          index={index}
          onEdit={onEdit}
          onDelete={onDelete}
          onAutocompleteSelect={onAutocompleteSelect}
          onSaveItem={onSaveItem}
          savedItems={savedItems}
          setIsManageModalOpen={setIsManageModalOpen}
        />
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <IconButton onClick={handleMenuClick}>
          <AddIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            onAddItem();
            handleMenuClose();
          }}>
            <AddIcon sx={{ mr: 1 }} />
            Add Item
          </MenuItem>
          <MenuItem onClick={() => {
            setIsManageModalOpen(true);
            handleMenuClose();
          }}>
            <ListAltIcon sx={{ mr: 1 }} />
            Manage Saved Items
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default ActiveItemsList;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Avatar,
  AvatarGroup,
  Tooltip
} from '@mui/material';
import { CaretUp, CaretDown } from "@phosphor-icons/react";
import { getClientEvents, getEventEmployees } from '../../services/api';

const Events = ({ client }) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState('newest');
  const [eventEmployees, setEventEmployees] = useState({});

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (client?.id) {
          const data = await getClientEvents(client.id);
          const cleanedData = data.map(event => ({
            ...event,
            title: event.title.split(' (')[0]
          }));
          setEvents(cleanedData);
          
          const employeeData = {};
          
          for (const event of cleanedData) {
            try {
              const employees = await getEventEmployees(event.id);
              employeeData[event.id] = employees.employees;
            } catch (error) {
              console.error(`Error fetching employees for event ${event.id}:`, error);
              employeeData[event.id] = [];
            }
          }
          
          setEventEmployees(employeeData);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [client]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return '#10B981';
      case 'scheduled':
        return '#F59E0B';
      case 'cancelled':
        return '#EF4444';
      default:
        return '#9CA3AF';
    }
  };

  const handleRowClick = (eventId) => {
    navigate(`/admin/events/${eventId}`);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-default)' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>
                Event Title
              </TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>Date</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>Project</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500,
                textAlign: 'center'
              }}>Employees</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500,
                paddingLeft: 4,
                textAlign: 'center'
              }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>Loading...</TableCell>
              </TableRow>
            ) : events.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>No events found</TableCell>
              </TableRow>
            ) : (
              [...events]
                .sort((a, b) => {
                  const dateA = new Date(a.start);
                  const dateB = new Date(b.start);
                  return dateB - dateA;
                })
                .map((event) => (
                  <TableRow 
                    key={event.id}
                    hover
                    onClick={() => handleRowClick(event.id)}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      },
                      '& .MuiTableCell-root': {
                        color: 'var(--text-primary)',
                        borderBottom: '1px solid var(--divider)',
                        fontFamily: "'Urbanist', sans-serif"
                      }
                    }}
                  >
                    <TableCell>{event.title}</TableCell>
                    <TableCell>
                      {event.start.toLocaleDateString()}
                    </TableCell>
                    <TableCell>{event.project?.name || 'No Project'}</TableCell>
                    <TableCell sx={{ 
                      textAlign: 'center'
                    }}>
                      <AvatarGroup 
                        max={3}
                        sx={{
                          '& .MuiAvatar-root': {
                            width: 24,
                            height: 24,
                            fontSize: '0.75rem'
                          },
                          justifyContent: 'center'
                        }}
                      >
                        {eventEmployees[event.id]?.map((employee) => (
                          <Tooltip key={employee.id} title={employee.name}>
                            <Avatar 
                              alt={employee.name}
                              src={employee.avatar_url}
                            >
                              {employee.name.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </TableCell>
                    <TableCell sx={{ 
                      paddingLeft: 4,
                      textAlign: 'center'
                    }}>
                      <Chip 
                        label={event.status?.charAt(0).toUpperCase() + event.status?.slice(1) || 'Pending'}
                        sx={{
                          backgroundColor: `${getStatusColor(event.status)}20`,
                          color: getStatusColor(event.status),
                          fontWeight: 500,
                          fontSize: '0.75rem',
                          fontFamily: "'Urbanist', sans-serif",
                          minWidth: '100px'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Events; 
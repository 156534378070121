/* global google */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Grid, Typography, IconButton, Tabs, Tab, Avatar, Chip, Button, Paper } from '@mui/material';
import { getEmployees, getAllEvents, getBusinessDetails } from '../services/api';
import { 
  MapPin as MapPinIcon, 
  Calendar as CalendarIcon, 
  Clock as ClockIcon, 
  User as UserIcon,
  CaretLeft as CaretLeftIcon,
  CaretRight as CaretRightIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  Path as PathIcon,
  Phone as PhoneIcon,
  Envelope as EnvelopeIcon,
  X as XIcon,
  CaretDown as CaretDownIcon
} from "@phosphor-icons/react";
import { format, addDays, subDays, isSameDay, parseISO } from 'date-fns';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import '../styles/RouteRunner.css';

const containerStyle = {
  width: '100%',
  height: 'calc(100vh - 140px)'
};

const center = {
  lat: 26.1224, // Fort Lauderdale coordinates
  lng: -80.1373
};

const mapOptions = {
  // Add your map options here
};

const RouteRunner = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeTab, setActiveTab] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventsLoading, setEventsLoading] = useState(true);
  
  // New state for route creation
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showRouteCreation, setShowRouteCreation] = useState(true);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 26.1224,
    lng: -80.1373
  });

  // Add map loading state
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  // Add new state for segment routes
  const [segmentDirections, setSegmentDirections] = useState([]);
  const [selectedSegmentRoutes, setSelectedSegmentRoutes] = useState([0]);
  const [expandedSegment, setExpandedSegment] = useState(null);

  // Update to array of refs to handle multiple renderers
  const directionsRenderersRef = useRef([]);

  const getCoordinatesFromAddress = async (address) => {
    return new Promise((resolve) => {
      if (!window.google || !isMapLoaded) {
        console.log('Google Maps not loaded yet');
        resolve(null);
        return;
      }

      try {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK' && results[0]) {
            resolve({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
          } else {
            console.error('Geocoding failed:', status);
            resolve(null);
          }
        });
      } catch (error) {
        console.error('Error in geocoding:', error);
        resolve(null);
      }
    });
  };

  // Update the geocodeBusinessAddress function
  const geocodeBusinessAddress = async (details) => {
    if (!details?.address) return null;
    
    const fullAddress = `${details.address}, ${details.city}`;
    console.log('Geocoding business address:', fullAddress);
    
    return new Promise((resolve) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: fullAddress }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const coordinates = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          };
          console.log('Business coordinates found:', coordinates);
          resolve(coordinates);
        } else {
          console.error('Geocoding failed:', status);
          resolve(null);
        }
      });
    });
  };

  // Update the business details useEffect
  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const details = await getBusinessDetails();
        console.log('Fetched business details:', details);
        
        if (details?.address) {
          // Wait for map to be loaded before geocoding
          if (!isMapLoaded) {
            console.log('Waiting for map to load...');
            return;
          }

          const fullAddress = `${details.address}, ${details.city}`;
          console.log('Geocoding address:', fullAddress);
          
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: fullAddress }, (results, status) => {
            if (status === 'OK' && results[0]) {
              const coordinates = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              };
              console.log('Found coordinates:', coordinates);
              
              setBusinessDetails({
                ...details,
                latitude: coordinates.lat,
                longitude: coordinates.lng
              });
              setMapCenter(coordinates);
            } else {
              console.error('Geocoding failed:', status);
            }
          });
        }
      } catch (error) {
        console.error('Error fetching business data:', error);
      }
    };

    if (isMapLoaded) {
      fetchBusinessData();
    }
  }, [isMapLoaded]); // Add isMapLoaded as dependency

  // Separate useEffect for employees and events
  useEffect(() => {
    fetchEmployees();
    fetchEvents();
  }, [selectedDate]);

  const fetchEmployees = async () => {
    try {
      const data = await getEmployees();
      setEmployees(data.employees || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setLoading(false);
    }
  };

  const fetchEvents = async () => {
    try {
      setEventsLoading(true);
      console.log('Fetching all events');
      
      const data = await getAllEvents();
      console.log('Raw events data:', data);
      
      if (Array.isArray(data)) {
        const eventsWithCoordinates = await Promise.all(data.map(async event => {
          if (event.addresses && event.addresses.length > 0) {
            const coordinates = await getCoordinatesFromAddress(event.addresses[0]);
            return {
              ...event,
              addressString: event.addresses[0],
              latitude: coordinates?.lat || null,
              longitude: coordinates?.lng || null
            };
          }
          return {
            ...event,
            addressString: '',
            latitude: null,
            longitude: null
          };
        }));
        
        const todaysEvents = eventsWithCoordinates.filter(event => {
          if (event.time_slots && event.time_slots.length > 0) {
            return isSameDay(parseISO(event.time_slots[0].start_time), selectedDate);
          }
          return false;
        });
        
        console.log('Filtered events for selected date:', todaysEvents);
        setEvents(todaysEvents);
      } else {
        console.log('Invalid data format:', data);
        setEvents([]);
      }
      
      setEventsLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]);
      setEventsLoading(false);
    }
  };

  const handlePrevDay = () => {
    setSelectedDate(prevDate => subDays(prevDate, 1));
  };

  const handleNextDay = () => {
    setSelectedDate(prevDate => addDays(prevDate, 1));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEmployeeSelect = (employee) => {
    setShowRouteCreation(true);
    setSelectedEmployees(prev => {
      const isSelected = prev.some(e => e.id === employee.id);
      if (isSelected) {
        return prev.filter(e => e.id !== employee.id);
      } else {
        return [...prev, employee];
      }
    });
  };

  const handleEventSelect = (event) => {
    setShowRouteCreation(true);
    setSelectedEvents(prev => {
      const isSelected = prev.some(e => e.id === event.id);
      if (isSelected) {
        return prev.filter(e => e.id !== event.id);
      } else {
        return [...prev, event];
      }
    });
  };

  // This useEffect replaces handleCreateRoute
  useEffect(() => {
    if (!selectedEvents.length || !businessDetails?.latitude || !businessDetails?.longitude) return;

    // Clear previous directions
    setSegmentDirections([]);
    setSelectedSegmentRoutes(Array(selectedEvents.length).fill(0));
    setExpandedSegment(null);

    const startingPoint = {
      lat: Number(businessDetails.latitude),
      lng: Number(businessDetails.longitude)
    };

    // Calculate distances between points
    const calculateDistance = (point1, point2) => {
      const R = 6371; // Earth's radius in km
      const dLat = (point2.lat - point1.lat) * Math.PI / 180;
      const dLon = (point2.lng - point1.lng) * Math.PI / 180;
      const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(point1.lat * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
    };

    const directionsService = new window.google.maps.DirectionsService();

    // Function to get closest point from a starting point
    const getClosestPoint = (fromPoint, points) => {
      return points.sort((a, b) => {
        const distA = calculateDistance(fromPoint, {
          lat: Number(a.latitude),
          lng: Number(a.longitude)
        });
        const distB = calculateDistance(fromPoint, {
          lat: Number(b.latitude),
          lng: Number(b.longitude)
        });
        return distA - distB;
      })[0];
    };

    // Generate routes sequentially
    const generateNextRoute = (fromPoint, remainingEvents, routeIndex) => {
      if (remainingEvents.length === 0) return;

      const nextEvent = getClosestPoint(fromPoint, remainingEvents);
      const remainingAfterThis = remainingEvents.filter(event => event.id !== nextEvent.id);

      directionsService.route({
        origin: fromPoint,
        destination: {
          lat: Number(nextEvent.latitude),
          lng: Number(nextEvent.longitude)
        },
        travelMode: 'DRIVING',
        provideRouteAlternatives: true
      }, (response, status) => {
        if (status === 'OK') {
          setSegmentDirections(prev => {
            const newSegments = [...prev];
            newSegments[routeIndex] = response;
            return newSegments;
          });

          // Generate next route if there are remaining events
          if (remainingAfterThis.length > 0) {
            generateNextRoute(
              { lat: Number(nextEvent.latitude), lng: Number(nextEvent.longitude) },
              remainingAfterThis,
              routeIndex + 1
            );
          }
        }
      });
    };

    // Start the chain of route generation
    generateNextRoute(
      startingPoint,
      selectedEvents,
      0
    );

  }, [selectedEvents, businessDetails]);

  const handleCloseRouteCreation = () => {
    setShowRouteCreation(false);
    setSelectedEmployees([]);
    setSelectedEvents([]);
  };

  const renderRouteCreation = () => {
    if (!selectedEmployees.length && !selectedEvents.length) return null;

    return (
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          border: '1px solid var(--divider)',
          backgroundColor: '#fff',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Create New Route</Typography>
          <IconButton
            onClick={handleCloseRouteCreation}
            size="small"
            sx={{ 
              color: 'error.main',
              '&:hover': {
                backgroundColor: 'error.lighter'
              }
            }}
          >
            <XIcon size={18} weight="bold" />
          </IconButton>
        </Box>

        {/* Route Options */}
        {renderRouteOptions()}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Selected Employees</Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {selectedEmployees.map(employee => (
                <Chip
                  key={employee.id}
                  label={employee.name}
                  onDelete={() => handleEmployeeSelect(employee)}
                  avatar={<Avatar src={employee.avatar}>{employee.name?.charAt(0)}</Avatar>}
                  sx={{ backgroundColor: 'primary.lighter' }}
                />
              ))}
            </Box>
          </Box>

          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Selected Projects</Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {selectedEvents.map(event => (
                <Chip
                  key={event.id}
                  label={event.project?.name || event.title}
                  onDelete={() => handleEventSelect(event)}
                  sx={{ backgroundColor: 'primary.lighter' }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  };

  const renderEmployeeCard = (employee) => (
    <Box
      key={employee.id}
      onClick={() => handleEmployeeSelect(employee)}
      sx={{
        p: 1.5,
        mb: 1.5,
        backgroundColor: selectedEmployees.some(e => e.id === employee.id) 
          ? 'primary.lighter' 
          : '#f8fafc',
        borderRadius: '8px',
        border: '1px solid var(--divider)',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: selectedEmployees.some(e => e.id === employee.id)
            ? 'primary.lighter'
            : '#f1f5f9',
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mb: 1 }}>
        <Avatar 
          src={employee.avatar} 
          alt={employee.name}
          sx={{ width: 32, height: 32 }}
        >
          {employee.name?.charAt(0)}
        </Avatar>
        <Box>
          <Typography 
            variant="subtitle2"
            sx={{ fontWeight: 600, lineHeight: 1.2 }}
          >
            {employee.name}
          </Typography>
          <Typography 
            variant="caption"
            color="text.secondary"
            sx={{ display: 'block', lineHeight: 1.2 }}
          >
            {employee.position}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 0.5,
        pl: 0.5
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EnvelopeIcon size={14} weight="light" />
          <Typography variant="caption" color="text.secondary">
            {employee.email}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PhoneIcon size={14} weight="light" />
          <Typography variant="caption" color="text.secondary">
            {employee.phone}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <MapPinIcon size={14} weight="light" />
          <Typography variant="caption" color="text.secondary">
            {employee.address}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderProjectCard = (event) => (
    <Box
      key={event.id}
      onClick={() => handleEventSelect(event)}
      sx={{
        p: 1.5,
        mb: 1.5,
        backgroundColor: selectedEvents.some(e => e.id === event.id)
          ? 'primary.lighter'
          : '#f8fafc',
        borderRadius: '8px',
        border: '1px solid var(--divider)',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: selectedEvents.some(e => e.id === event.id)
            ? 'primary.lighter'
            : '#f1f5f9',
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        <Box>
          <Typography 
            variant="subtitle2"
            sx={{ fontWeight: 600, lineHeight: 1.2 }}
          >
            {event.project?.name || 'Untitled Project'}
          </Typography>
          <Typography 
            variant="caption"
            color="text.secondary"
          >
            {event.title}
          </Typography>
        </Box>
        {event.time_slots && event.time_slots[0] && (
          <Chip 
            label={format(parseISO(event.time_slots[0].start_time), 'h:mm a')}
            size="small"
            sx={{ 
              backgroundColor: 'primary.lighter',
              color: 'primary.main',
              fontWeight: 500,
              fontSize: '0.75rem'
            }}
          />
        )}
      </Box>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 0.5,
        pl: 0.5
      }}>
        {event.addresses && event.addresses[0] && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MapPinIcon size={14} weight="light" />
            <Typography variant="caption" color="text.secondary" sx={{ 
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px'
            }}>
              {event.addresses[0]}
            </Typography>
          </Box>
        )}
        {event.time_slots && event.time_slots[0] && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ClockIcon size={14} weight="light" />
            <Typography variant="caption" color="text.secondary">
              {format(parseISO(event.time_slots[0].start_time), 'h:mm a')} - {format(parseISO(event.time_slots[0].end_time), 'h:mm a')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  // Simplified marker icon function
  const getMarkerIcon = (color) => ({
    url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`
  });

  // Update the renderBusinessMarker function
  const renderBusinessMarker = () => {
    if (!businessDetails?.latitude || !businessDetails?.longitude) {
      console.log('Missing business coordinates:', businessDetails);
      return null;
    }
    
    const position = {
      lat: Number(businessDetails.latitude),
      lng: Number(businessDetails.longitude)
    };
    
    console.log('Rendering business marker at:', position);
    
    return (
      <Marker
        position={position}
        icon={{
          url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
          scaledSize: new window.google.maps.Size(32, 32),
          anchor: new window.google.maps.Point(16, 32)
        }}
        zIndex={1000}
      />
    );
  };

  // Update DirectionsRenderer options to show alternative routes
  const renderDirections = () => {
    if (!segmentDirections.length || !isMapLoaded) {
      return null;
    }

    return segmentDirections.map((segment, index) => {
      const routeIndex = selectedSegmentRoutes[index] || 0;
      
      if (!segment?.routes?.[routeIndex]) {
        return null;
      }

      return (
        <DirectionsRenderer
          key={`directions-${index}-${routeIndex}`}
          options={{
            directions: segment,
            routeIndex: routeIndex,
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#7635dc",
              strokeWeight: 5,
              strokeOpacity: 0.8
            }
          }}
          onLoad={renderer => {
            directionsRenderersRef.current.push(renderer);
          }}
        />
      );
    });
  };

  // Update route options display with collapsible segments
  const renderRouteOptions = () => {
    // Add safety check at the start
    if (!segmentDirections?.length) return null;

    // Calculate total time and distance across all selected routes
    const totalDuration = segmentDirections.reduce((total, segment, index) => {
      if (!segment?.routes?.[selectedSegmentRoutes[index]]?.legs?.[0]) return total;
      return total + segment.routes[selectedSegmentRoutes[index]].legs[0].duration.value;
    }, 0);

    const totalDistance = segmentDirections.reduce((total, segment, index) => {
      if (!segment?.routes?.[selectedSegmentRoutes[index]]?.legs?.[0]) return total;
      return total + segment.routes[selectedSegmentRoutes[index]].legs[0].distance.value;
    }, 0);

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Route Options</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {segmentDirections.map((segment, segmentIndex) => {
            // Add safety check for segment
            if (!segment?.routes) return null;

            const isExpanded = expandedSegment === segmentIndex;
            const selectedRoute = segment.routes[selectedSegmentRoutes[segmentIndex] || 0];
            const leg = selectedRoute?.legs?.[0];
            
            if (!leg) return null;

            return (
              <Box key={`segment-${segmentIndex}`}>
                <Box
                  onClick={() => setExpandedSegment(isExpanded ? null : segmentIndex)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    p: 2,
                    borderRadius: '8px',
                    backgroundColor: '#f8fafc',
                    '&:hover': { backgroundColor: '#f1f5f9' }
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      {`Starting Point → ${selectedEvents[segmentIndex]?.title || 'Destination'}`}
                    </Typography>
                    {leg && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                        <MapPinIcon size={14} weight="light" />
                        <Typography variant="caption" color="text.secondary">
                          {leg.end_address.split(',')[0]}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {leg && (
                      <Chip 
                        label={leg.duration.text}
                        size="small"
                        sx={{ 
                          backgroundColor: 'primary.lighter',
                          color: 'primary.main'
                        }}
                      />
                    )}
                    <CaretDownIcon 
                      size={16}
                      style={{ 
                        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease'
                      }}
                    />
                  </Box>
                </Box>

                {isExpanded && (
                  <Box sx={{ mt: 1, pl: 2 }}>
                    {segment.routes.map((route, routeIndex) => {
                      const leg = route.legs?.[0];
                      if (!leg) return null;
                      
                      return (
                        <Box
                          key={`${segmentIndex}-${routeIndex}`}
                          onClick={() => handleSegmentRouteSelect(segmentIndex, routeIndex)}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                            p: 2,
                            borderRadius: '8px',
                            backgroundColor: selectedSegmentRoutes[segmentIndex] === routeIndex ? 'primary.lighter' : '#fff',
                            '&:hover': { backgroundColor: selectedSegmentRoutes[segmentIndex] === routeIndex ? 'primary.lighter' : '#f8fafc' },
                            mb: 1
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                              Route {routeIndex + 1}
                            </Typography>
                            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                              Distance: {leg.distance.text}
                            </Typography>
                          </Box>
                          <Chip 
                            label={leg.duration.text}
                            size="small"
                            sx={{ 
                              backgroundColor: selectedSegmentRoutes[segmentIndex] === routeIndex ? 'primary.main' : 'primary.lighter',
                              color: selectedSegmentRoutes[segmentIndex] === routeIndex ? 'white' : 'primary.main'
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>

        {/* Total Time and Distance Summary */}
        {totalDuration > 0 && (
          <Box
            sx={{
              mt: 2,
              pt: 2,
              borderTop: '1px solid var(--divider)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="subtitle2">
              Total Trip
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="caption" color="text.secondary">
                {(totalDistance / 1000).toFixed(1)} km
              </Typography>
              <Chip 
                label={`${Math.round(totalDuration / 60)} mins`}
                size="small"
                sx={{ 
                  backgroundColor: 'primary.main',
                  color: 'white',
                  fontWeight: 500
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  // Update handleSegmentRouteSelect
  const handleSegmentRouteSelect = (segmentIndex, routeIndex) => {
    console.log(`Selecting route ${routeIndex} for segment ${segmentIndex}`);
    
    // Clear ALL existing renderers first
    directionsRenderersRef.current.forEach(renderer => {
      if (renderer) {
        renderer.setMap(null);
      }
    });
    directionsRenderersRef.current = [];
    
    // Update the route selection
    setSelectedSegmentRoutes(prev => {
      const newSelected = [...prev];
      newSelected[segmentIndex] = routeIndex;
      return newSelected;
    });

    // Force re-render of directions
    setSegmentDirections(prev => [...prev]);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} md={8}>
          <Box sx={{ 
            backgroundColor: '#fff',
            border: '1px solid var(--divider)',
            borderRadius: '8px',
            overflow: 'hidden',
            position: 'relative'
          }}>
            {showRouteCreation && (selectedEmployees.length > 0 || selectedEvents.length > 0) && (
              <Paper
                elevation={2}
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  width: '300px',
                  zIndex: 1000,
                  p: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  backdropFilter: 'blur(4px)',
                  borderRadius: '8px',
                  border: '1px solid var(--divider)'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Create New Route</Typography>
                  <IconButton 
                    onClick={handleCloseRouteCreation}
                    size="small"
                    sx={{ 
                      color: 'error.main',
                      '&:hover': {
                        backgroundColor: 'error.lighter'
                      }
                    }}
                  >
                    <XIcon size={18} weight="bold" />
                  </IconButton>
                </Box>


                {renderRouteOptions()}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>Selected Employees</Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                      {selectedEmployees.map(employee => (
                        <Chip
                          key={employee.id}
                          label={employee.name}
                          onDelete={() => handleEmployeeSelect(employee)}
                          avatar={<Avatar src={employee.avatar}>{employee.name?.charAt(0)}</Avatar>}
                          sx={{ backgroundColor: 'primary.lighter' }}
                        />
                      ))}
                    </Box>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>Selected Projects</Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                      {selectedEvents.map(event => (
                        <Chip
                          key={event.id}
                          label={event.project?.name || event.title}
                          onDelete={() => handleEventSelect(event)}
                          sx={{ backgroundColor: 'primary.lighter' }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )}

            <LoadScript 
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onLoad={() => setIsMapLoaded(true)}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={12}
                options={{
                  zoomControl: true,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                {isMapLoaded && (
                  <>
                    {renderBusinessMarker()}
                    {showRouteCreation && selectedEvents
                      .filter(event => event.latitude && event.longitude)
                      .map((event, index) => (
                        <Marker 
                          key={event.id} 
                          position={{
                            lat: Number(event.latitude),
                            lng: Number(event.longitude)
                          }}
                          label={{
                            text: `${index + 1}`,
                            color: "white",
                            fontWeight: "bold",
                          }}
                          icon={getMarkerIcon("purple")}
                          zIndex={999}
                        />
                      ))}
                    {renderDirections()}
                  </>
                )}
              </GoogleMap>
            </LoadScript>
          </Box>
        </Grid>

        {/* Right column - Route Details */}
        <Grid item xs={12} lg={3} md={4}>
          <Box sx={{ 
            width: { xs: '100%'},
            backgroundColor: '#fff',
            height: '100%',
            position: { md: 'sticky' },
            top: { md: 20 },
            mx: { xs: 'auto', md: 0 },
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            border: '1px solid var(--divider)',
          }}>
            {/* Date Navigation Header */}
            <Box sx={{
              p: 2,
              backgroundColor: '#f8fafc',
              borderBottom: '1px solid var(--divider)'
            }}>
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1
              }}>
                <IconButton onClick={handlePrevDay} size="small">
                  <CaretLeftIcon size={20} />
                </IconButton>
                <Typography variant="h6" sx={{ 
                  fontWeight: 600,
                  textAlign: 'center'
                }}>
                  {format(selectedDate, 'MMMM d, yyyy')}
                </Typography>
                <IconButton onClick={handleNextDay} size="small">
                  <CaretRightIcon size={20} />
                </IconButton>
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  textAlign: 'center',
                  color: 'text.secondary',
                  fontWeight: 500
                }}
              >
                {format(selectedDate, 'EEEE')}
              </Typography>
            </Box>

            {/* Tabs */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange}
                variant="fullWidth"
                TabIndicatorProps={{
                  children: <span className="MuiTabs-indicatorSpan" />,
                }}
                sx={{
                  minHeight: '48px',
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#7635dc',
                    height: '2px',
                    borderRadius: '3px 3px 0 0',
                    transition: 'all 0.2s ease'
                  },
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    fontSize: '0.85rem',
                    fontFamily: 'Urbanist',
                    fontWeight: 500,
                    color: 'var(--text-secondary)',
                    minHeight: 48,
                    minWidth: 'auto',
                    padding: '12px 8px',
                    gap: '4px',
                    position: 'relative',
                    flex: 1,
                    '& .MuiTouchRipple-root': {
                      display: 'none'
                    },
                    '& svg': {
                      marginBottom: '0 !important',
                      marginRight: '0 !important',
                      fontSize: '1.2rem'
                    },
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      width: 0,
                      height: '2px',
                      borderRadius: '3px 3px 0 0',
                      backgroundColor: '#7635dc',
                      opacity: 0.6,
                      transition: 'all 0.2s ease',
                      transform: 'translateX(-50%)',
                    },
                    '&:hover::after': {
                      width: '80%',
                    },
                    '&.Mui-selected': {
                      color: 'var(--primary-main)',
                      fontWeight: 600,
                      '&::after': {
                        opacity: 0,
                      },
                    },
                    '&:hover': {
                      color: 'var(--primary-main)',
                    }
                  },
                }}
              >
                <Tab 
                  icon={<BriefcaseIcon size={18} weight="light" />}
                  iconPosition="start"
                  label="Projects" 
                  disableRipple
                />
                <Tab 
                  icon={<UsersIcon size={18} weight="light" />}
                  iconPosition="start"
                  label="Employees" 
                  disableRipple
                />
                <Tab 
                  icon={<PathIcon size={18} weight="light" />}
                  iconPosition="start"
                  label="Routes" 
                  disableRipple
                />
              </Tabs>
            </Box>

            {/* Tab Content */}
            <Box sx={{ p: 3, flex: 1, overflowY: 'auto' }}>
              {activeTab === 0 && (
                <Box>
                  <Typography variant="h6" sx={{ mb: 2 }}>Projects for {format(selectedDate, 'MMM d')}</Typography>
                  {eventsLoading ? (
                    <Typography>Loading projects...</Typography>
                  ) : events.length > 0 ? (
                    events.map(event => renderProjectCard(event))
                  ) : (
                    <Typography color="text.secondary">No projects scheduled for this date</Typography>
                  )}
                </Box>
              )}
              {activeTab === 1 && (
                <Box>
                  <Typography variant="h6" sx={{ mb: 2 }}>Available Employees</Typography>
                  {loading ? (
                    <Typography>Loading employees...</Typography>
                  ) : employees.length > 0 ? (
                    employees.map(employee => renderEmployeeCard(employee))
                  ) : (
                    <Typography color="text.secondary">No employees found</Typography>
                  )}
                </Box>
              )}
              {activeTab === 2 && (
                <Box>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RouteRunner; 
import React from 'react';
import { Box, Grid, Typography, Button, Card, CardContent } from '@mui/material';
import { 
  Headset as HeadsetIcon,
  Phone as PhoneIcon,
  EnvelopeSimple as EmailIcon,
  Clock as AccessTimeIcon,
  ChatCircleDots as ChatIcon,
  Users as TeamIcon,
  Lightning as SpeedIcon,
  Question as FAQIcon,
} from "@phosphor-icons/react";

function AdminSettingsSupport() {
  return (
    <Box sx={{ p: 3 }}>
      {/* Hero Section */}
      <Box sx={{ 
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        mb: 4,
        height: '300px',
        backgroundImage: 'url("https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3")', // Support/customer service themed image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 4,
        }}>
          <Typography variant="h3" sx={{ 
            color: 'white', 
            fontWeight: 700,
            mb: 2,
            fontFamily: 'Urbanist'
          }}>
            24/7 Support
          </Typography>
          <Typography variant="h6" sx={{ 
            color: 'white',
            maxWidth: '600px',
            mb: 3,
            fontFamily: 'Urbanist'
          }}>
            Get expert help whenever you need it. Our dedicated support team is here to help you succeed.
          </Typography>
          <Button
            variant="contained"
            startIcon={<ChatIcon size={20} weight="light" />}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              backgroundColor: '#4a5df9',
              fontFamily: 'Urbanist, sans-serif',
              fontWeight: 600,
              fontSize: '0.875rem',
              padding: '12px 24px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3c50fa',
                boxShadow: 'none',
              },
              width: 'fit-content'
            }}
          >
            Start Live Chat
          </Button>
        </Box>
      </Box>

      {/* Support Options Grid */}
      <Grid container spacing={3}>
        {[
          {
            icon: <PhoneIcon size={32} weight="light" style={{ color: '#4a5df9' }} />,
            title: 'Phone Support',
            description: '1-800-SUPPORT (Available 24/7)',
            action: 'Call Now'
          },
          {
            icon: <EmailIcon size={32} weight="light" style={{ color: '#00AB55' }} />,
            title: 'Email Support',
            description: 'support@servtrackr.com',
            action: 'Send Email'
          },
          {
            icon: <ChatIcon size={32} weight="light" style={{ color: '#7635dc' }} />,
            title: 'Live Chat',
            description: 'Average response time: 2 minutes',
            action: 'Start Chat'
          },
          {
            icon: <FAQIcon size={32} weight="light" style={{ color: '#FF4842' }} />,
            title: 'Help Center',
            description: 'Browse our knowledge base',
            action: 'View Articles'
          }
        ].map((option, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card sx={{ 
              height: '100%',
              borderRadius: '12px',
              boxShadow: 'none',
              border: '1px solid var(--divider)'
            }}>
              <CardContent sx={{ p: 3 }}>
                {option.icon}
                <Typography variant="h6" sx={{ 
                  mt: 2, 
                  mb: 1,
                  fontFamily: 'Urbanist',
                  fontWeight: 600
                }}>
                  {option.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{
                  fontFamily: 'Urbanist',
                  mb: 2
                }}>
                  {option.description}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'Urbanist',
                    fontWeight: 600,
                    color: '#4a5df9',
                    p: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      opacity: 0.8
                    }
                  }}
                >
                  {option.action}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default AdminSettingsSupport;

import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, Divider, IconButton, Tooltip } from '@mui/material';
import { Calendar, Clock, CaretLeft, CaretRight, Users } from "@phosphor-icons/react";
import { getAllEvents } from '../../services/api';

const TodayWidget = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState({});
  
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
  
  const formattedDate = currentDate.toLocaleDateString('en-US', { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  });
  
  const formattedTime = new Date().toLocaleTimeString('en-US', { 
    hour: '2-digit', 
    minute: '2-digit'
  });

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        
        const response = await getAllEvents({
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0]
        });

        console.log('Raw events:', response);

        // Group events by date, only including those with time_slots
        const eventsByDate = {};
        response.forEach(event => {
          if (event.time_slots && event.time_slots.length > 0) {
            const timeSlot = event.time_slots[0]; // Use the first time slot
            const date = new Date(timeSlot.start_time);
            const day = date.getDate();
            
            if (!eventsByDate[day]) {
              eventsByDate[day] = [];
            }
            
            eventsByDate[day].push({
              ...event,
              title: event.title || `${timeSlot.start_time.split('T')[1].slice(0, 5)} - Scheduled Event`,
              description: event.description || 'No description provided',
              time: timeSlot.start_time
            });
          }
        });
        
        console.log('Grouped Events by Date:', eventsByDate);
        setEvents(eventsByDate);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [currentDate]);

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const renderCalendar = () => {
    const days = [];
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    
    // Add week days
    weekDays.forEach(day => {
      days.push(
        <Box 
          key={`weekday-${day}`}
          sx={{ 
            width: '100%',
            height: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'text.secondary',
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.02em',
            textTransform: 'uppercase'
          }}
        >
          {day.slice(0, 1)}
        </Box>
      );
    });

    // Add empty spaces for first week
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<Box key={`empty-${i}`} sx={{ width: '100%', height: '2.5rem' }} />);
    }

    // Add days
    for (let day = 1; day <= daysInMonth; day++) {
      const isToday = new Date().getDate() === day && 
                      new Date().getMonth() === currentDate.getMonth() &&
                      new Date().getFullYear() === currentDate.getFullYear();
      
      const dayEvents = events[day] || [];
      const hasEvents = dayEvents.length > 0;

      days.push(
        <Tooltip 
          key={`day-${day}`}
          title={
            hasEvents ? (
              <Box 
                sx={{ 
                  bgcolor: 'background.paper',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1.5,
                  p: 2,
                  minWidth: 300,
                  maxWidth: 400,
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                }}
              >
                {dayEvents.map((event, index) => {
                  const startTime = new Date(event.time_slots[0].start_time);
                  const endTime = new Date(event.time_slots[0].end_time);
                  const client = event.project?.client;
                  const employees = event.employees || [];
                  
                  return (
                    <Box 
                      key={index}
                      sx={{
                        '&:not(:last-child)': {
                          mb: 2,
                          pb: 2,
                          borderBottom: '1px solid',
                          borderColor: 'divider'
                        }
                      }}
                    >
                      {/* Event Title and Value */}
                      <Typography 
                        variant="subtitle1"
                        sx={{ 
                          color: 'text.primary',
                          fontWeight: 600,
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>{event.title || 'Untitled Event'}</span>
                        {event.value && (
                          <span style={{ color: 'var(--primary-main)' }}>
                            ${event.value}
                          </span>
                        )}
                      </Typography>

                      {/* Time Slot */}
                      <Typography 
                        variant="subtitle2"
                        sx={{ 
                          color: 'text.secondary',
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <Clock size={14} weight="bold" />
                        {startTime.toLocaleTimeString([], { 
                          hour: '2-digit', 
                          minute: '2-digit'
                        })}
                        {' - '}
                        {endTime.toLocaleTimeString([], { 
                          hour: '2-digit', 
                          minute: '2-digit'
                        })}
                      </Typography>

                      {/* Client Info */}
                      {client && (
                        <Typography 
                          variant="body2"
                          sx={{ 
                            color: 'text.secondary',
                            mb: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <Users size={14} weight="bold" />
                          {client.name}
                          {client.company && ` - ${client.company}`}
                        </Typography>
                      )}

                      {/* Employees */}
                      {employees.length > 0 && (
                        <Box sx={{ mt: 1 }}>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              color: 'text.secondary',
                              display: 'block',
                              mb: 0.5 
                            }}
                          >
                            Assigned to:
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {employees.map((employee, i) => (
                              <Box
                                key={i}
                                sx={{
                                  fontSize: '0.75rem',
                                  padding: '2px 8px',
                                  borderRadius: '12px',
                                  backgroundColor: 'action.selected',
                                  color: 'text.primary'
                                }}
                              >
                                {employee.name}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            ) : ''
          }
          arrow
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'background.paper',
                '& .MuiTooltip-arrow': {
                  color: 'background.paper',
                },
                p: 0
              }
            }
          }}
        >
          <Box 
            sx={{ 
              width: '100%',
              height: '2.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '2rem',
                height: '2rem',
                borderRadius: '50%',
                backgroundColor: isToday ? 'primary.main' : 'transparent',
                transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                zIndex: 0,
              },
              '&::after': hasEvents ? {
                content: '""',
                position: 'absolute',
                bottom: '2px',
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                backgroundColor: 'primary.main',
              } : {},
              '&:hover::before': {
                backgroundColor: isToday ? 'primary.dark' : 'action.hover',
              },
              '& > span': {
                position: 'relative',
                zIndex: 1,
                color: isToday ? 'white' : 'text.primary',
                fontSize: '0.875rem',
                fontWeight: isToday ? 600 : 400,
              },
              cursor: hasEvents ? 'pointer' : 'default'
            }}
          >
            <span>{day}</span>
          </Box>
        </Tooltip>
      );
    }

    return days;
  };

  return (
    <Paper 
      elevation={0}
      sx={{ 
        height: '100%',
        background: 'var(--background-paper)',
        borderRadius: 2
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* Date and Time Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
            <Calendar weight="light" size={24} />
            <Typography variant="h6" sx={{ fontWeight: 500, letterSpacing: '0.02em' }}>
              {formattedDate}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
            <Clock weight="light" size={24} />
            <Typography variant="h6" sx={{ fontWeight: 500, letterSpacing: '0.02em' }}>
              {formattedTime}
            </Typography>
          </Box>
        </Box>
        
        <Divider sx={{ opacity: 0.6 }} />
        
        {/* Calendar Navigation */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <IconButton onClick={handlePrevMonth} size="small">
            <CaretLeft weight="bold" />
          </IconButton>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 500,
              letterSpacing: '0.02em',
              color: 'text.primary'
            }}
          >
            {currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
          </Typography>
          <IconButton onClick={handleNextMonth} size="small">
            <CaretRight weight="bold" />
          </IconButton>
        </Box>

        {/* Calendar Grid */}
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(7, 1fr)',
          gap: 0.5,
          width: '100%',
          maxWidth: '100%',
          margin: '0 auto'
        }}>
          {renderCalendar()}
        </Box>
      </Box>
    </Paper>
  );
};

export default TodayWidget; 
import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ScheduleItem from './ScheduleItem';

const MultiDaySchedule = ({ project, setProject }) => {
  // Filter out inventory items
  const items = Array.isArray(project.items) 
    ? project.items.filter(item => item.type !== 'inventory')
    : [];
  
  // Debug log to verify items
  useEffect(() => {
    console.log('MultiDaySchedule filtered items:', items);
  }, [items]);

  const handleItemsChange = (newItems) => {
    console.log('handleItemsChange:', newItems);
    // Preserve inventory items when updating project
    const inventoryItems = project.items.filter(item => item.type === 'inventory');
    setProject({ 
      ...project, 
      items: [...inventoryItems, ...newItems]
    });
  };

  return (
    <Box sx={{ p: 3, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Multi-Day Schedule
      </Typography>
      
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid item xs={12} key={`item-${item.id || index}`}>
            <Box sx={{ 
              '& > div': {
                p: 2,
                mb: 1,
              }
            }}>
              <ScheduleItem 
                item={item}
                index={index}
                items={items}
                setItems={handleItemsChange}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MultiDaySchedule; 
 
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Switch,
  Chip,
  IconButton,
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Plus as PlusIcon,
  Pencil as EditIcon,
  Trash as TrashIcon,
  Lightning as TriggerIcon,
  ArrowRight as ActionIcon,
  CaretRight as ChevronRightIcon,
  Bell,
  Megaphone,
  ArrowClockwise,
  Phone,
  Gear
} from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';
import { getAutomations, toggleAutomation, deleteAutomation, getAutomationNotifications } from '../services/api';

function AdminSettingsAutomation() {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const [automations, setAutomations] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [activeCategory, setActiveCategory] = useState('');
  
  useEffect(() => {
    fetchAutomations();
    fetchNotifications();
  }, []);

  const fetchAutomations = async () => {
    try {
      const data = await getAutomations();
      setAutomations(data);
    } catch (error) {
      console.error('Error fetching automations:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch automations',
        severity: 'error'
      });
    }
  };

  const fetchNotifications = async () => {
    try {
      const data = await getAutomationNotifications();
      setNotifications(data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleToggleAutomation = async (id) => {
    try {
      await toggleAutomation(id);
      fetchAutomations();
      fetchNotifications();
      setSnackbar({
        open: true,
        message: 'Automation status updated successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error toggling automation:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update automation status',
        severity: 'error'
      });
    }
  };

  const handleDeleteAutomation = (id) => {
    deleteAutomation(id);
    fetchAutomations();
  };

  const getTypeColor = (type) => {
    const colors = {
      reminders: '#7635dc',    // Purple
      marketing: '#00a3bf',    // Teal
      'follow ups': '#ff6b6b', // Coral
      phone: '#4a5df9',        // Blue
      actions: '#ffa726',      // Orange
      default: '#666'
    };
    return colors[type.toLowerCase()] || colors.default;
  };

  const getCategoryIcon = (category) => {
    const icons = {
      reminders: <Bell size={18} weight="bold" />,
      marketing: <Megaphone size={18} weight="bold" />,
      'follow ups': <ArrowClockwise size={18} weight="bold" />,
      phone: <Phone size={18} weight="bold" />,
      actions: <TriggerIcon size={18} weight="bold" />
    };
    return icons[category.toLowerCase()] || <Gear size={18} weight="bold" />;
  };

  const formatText = (text) => {
    if (!text) return '';
    return text.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  };

  // Organize automations into categories based on their title
  const groupedAutomations = automations.reduce((acc, automation) => {
    let category = 'actions'; // default category
    const title = (automation.title || '').toLowerCase();
    
    console.log('Checking title:', title);

    // Reminders category (check first for ahead/before/immediately)
    if (title.includes('reminder') || 
        title.includes('ahead') || 
        title.includes('before') ||
        title.includes('immediately')) {
      category = 'reminders';
    }
    // Follow ups category
    else if (title.includes('days after') || 
        title.includes('day after') || 
        title.includes('follow') || 
        title.includes('check')) {
      category = 'follow ups';
    }
    // Marketing category
    else if (title.includes('email') || 
        title.includes('message') || 
        title.includes('notification')) {
      category = 'marketing';
    }
    // Phone category
    else if (title.includes('call') || title.includes('phone')) {
      category = 'phone';
    }

    console.log('Category selected:', category);

    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(automation);
    return acc;
  }, {});

  const categoryDescriptions = {
    reminders: "Schedule automated reminders for tasks and appointments",
    marketing: "Send automated emails and notifications",
    'follow ups': "Automate follow-up actions and status checks",
    phone: "Manage call-related automations",
    actions: "Other automated actions"
  };

  // Sort categories in preferred order
  const categoryOrder = ['reminders', 'marketing', 'follow ups', 'phone', 'actions'];
  const sortedCategories = Object.entries(groupedAutomations).sort((a, b) => {
    return categoryOrder.indexOf(a[0]) - categoryOrder.indexOf(b[0]);
  });

  useEffect(() => {
    const scrollElem = scrollContainerRef.current;
    if (!scrollElem) return;

    const handleScroll = () => {
      let closestCategory = null;
      let minDistance = Infinity;

      sortedCategories.forEach(([category]) => {
        const el = document.getElementById(category);
        if (el) {
          // Adjust offset to be more generous for detecting the active category
          const distance = Math.abs(el.getBoundingClientRect().top - 100);
          if (distance < minDistance) {
            minDistance = distance;
            closestCategory = category;
          }
        }
      });

      if (closestCategory) {
        setActiveCategory(closestCategory);
      }
    };

    scrollElem.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => scrollElem.removeEventListener('scroll', handleScroll);
  }, [sortedCategories]);

  const scrollToCategory = (category) => {
    const container = scrollContainerRef.current;
    const el = document.getElementById(category);
    if (!container || !el) return;
    // Offset by 50 if you have a top bar height
    container.scrollTo({ top: el.offsetTop - 50, behavior: 'smooth' });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%', // Changed from 100vh to 100% to fit parent container
      }}
    >
      {/* LEFT COLUMN (Sticky Nav) */}
      <Box
        sx={{
          width: 220,
          position: 'sticky',
          top: 0,
          height: '100%', // Changed from 100vh to 100%
          overflowY: 'auto', // optional scroll if left column is tall
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'var(--background-paper)',
          zIndex: 1,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            p: 2,
            color: 'text.secondary',
            fontSize: '0.75rem',
            fontWeight: 600,
            textTransform: 'uppercase',
          }}
        >
          Categories
        </Typography>

        {sortedCategories.map(([category]) => (
          <Box
            key={category}
            onClick={() => scrollToCategory(category)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              py: 1,
              px: 1.5,
              borderRadius: '8px',
              cursor: 'pointer',
              color: activeCategory === category ? 'text.primary' : 'text.secondary',
              backgroundColor: activeCategory === category
                ? 'rgba(0, 0, 0, 0.04)'
                : 'transparent',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: activeCategory === category
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'rgba(0, 0, 0, 0.02)',
              },
            }}
          >
            <Box
              sx={{
                width: 28,
                height: 28,
                borderRadius: '6px',
                backgroundColor: activeCategory === category
                  ? `${getTypeColor(category)}15`
                  : 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {React.cloneElement(getCategoryIcon(category), {
                style: { color: activeCategory === category ? getTypeColor(category) : 'inherit' },
                size: 16
              })}
            </Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: activeCategory === category ? 600 : 500,
                fontSize: '0.875rem'
              }}
            >
              {formatText(category)}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* RIGHT (Scrollable Content) */}
      <Box
        ref={scrollContainerRef}
        sx={{
          flex: 1,
          overflowY: 'auto',
          height: '100%', // Changed from maxHeight: 100vh
          px: 2,
          py: 2,
          pb: '500px', // Increased padding bottom to allow more scroll space
          '&::-webkit-scrollbar': { width: '8px' },
          '&::-webkit-scrollbar-track': { background: 'transparent' },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <Box sx={{ 
          mb: 5,
          px: 2,
          py: 2,
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
              Workflow Automations
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Automate your business processes with custom workflows
            </Typography>
          </Box>
          <Button
            variant="contained"
            startIcon={<PlusIcon weight="bold" />}
            onClick={() => navigate('/admin/create-automation')}
            disableRipple
            sx={{
              boxShadow: 'none',
              backgroundColor: "#4a5df9",
              '&:hover': {
                backgroundColor: '#3c50fa',
                boxShadow: 'none',
              },
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '0.875rem',
              padding: '6px 16px',
              borderRadius: '8px',
            }}
          >
            Create Automation
          </Button>
        </Box>

        {sortedCategories.map(([category, categoryAutomations]) => (
          <Box
            key={category}
            id={category}
            sx={{
              mb: 6,
              scrollMarginTop: '0px',
              // Add padding to the last category to ensure enough scroll space
              ...(category === 'actions' && {
                pb: '400px', // Extra padding for the last category
              }),
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5
                  }}
                >
                  <Box 
                    sx={{ 
                      width: 32,
                      height: 32,
                      borderRadius: '8px',
                      backgroundColor: `${getTypeColor(category)}15`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {React.cloneElement(getCategoryIcon(category), {
                      style: { color: getTypeColor(category) }
                    })}
                  </Box>
                  {formatText(category)}
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      px: 1.5,
                      py: 0.5,
                      borderRadius: '6px',
                      fontSize: '0.75rem'
                    }}
                  >
                    {categoryAutomations.length} {categoryAutomations.length === 1 ? 'automation' : 'automations'}
                  </Typography>
                </Typography>
              </Box>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ ml: 5.5 }}
              >
                {categoryDescriptions[category]}
              </Typography>
            </Box>

            <Grid container spacing={3}>
              {categoryAutomations.map((automation) => (
                <Grid item xs={12} md={6} lg={4} key={automation.id}>
                  <Paper
                    elevation={0}
                    sx={{
                      border: '1px solid rgba(0, 0, 0, 0.08)',
                      borderRadius: '16px',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease-in-out',
                      backgroundColor: 'white',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        borderColor: 'rgba(74, 93, 249, 0.2)',
                      },
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 2.5,
                        gap: 1
                      }}>
                        <Chip 
                          label={automation.action_type || 'Unknown'}
                          size="small"
                          sx={{ 
                            backgroundColor: `${getTypeColor(automation.action_type)}10`,
                            color: getTypeColor(automation.action_type),
                            fontWeight: 600,
                            borderRadius: '8px',
                            height: '24px',
                            '& .MuiChip-label': {
                              px: 1.5,
                            }
                          }}
                        />
                        <Switch
                          size="small"
                          checked={automation.active}
                          onChange={() => handleToggleAutomation(automation.id)}
                          sx={{ 
                            ml: 'auto',
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#4a5df9',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#4a5df9',
                            }
                          }}
                        />
                      </Box>

                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontWeight: 600, 
                          mb: 2,
                          fontSize: '1.1rem',
                          lineHeight: 1.3,
                          color: 'var(--text-primary)'
                        }}
                      >
                        {automation.title || 'Untitled Automation'}
                      </Typography>
                      
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1.5,
                        mb: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                        borderRadius: '12px',
                      }}>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          gap: 1
                        }}>
                          <Box sx={{
                            backgroundColor: 'rgba(74, 93, 249, 0.1)',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <TriggerIcon size={18} weight="bold" style={{ color: '#4a5df9' }} />
                          </Box>
                          <Typography variant="body2" sx={{ color: 'var(--text-secondary)', fontWeight: 500 }}>
                            When {formatText(automation.trigger_type)} {formatText(automation.trigger_action)}
                          </Typography>
                        </Box>
                        <ChevronRightIcon size={16} weight="bold" style={{ color: 'var(--text-secondary)' }} />
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          gap: 1
                        }}>
                          <Box sx={{
                            backgroundColor: 'rgba(74, 93, 249, 0.1)',
                            borderRadius: '8px',
                            p: 0.8,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <ActionIcon size={18} weight="bold" style={{ color: '#4a5df9' }} />
                          </Box>
                          <Typography variant="body2" sx={{ color: 'var(--text-secondary)', fontWeight: 500 }}>
                            {formatText(automation.action_type)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        gap: 1, 
                        mt: 2,
                        borderTop: '1px solid rgba(0, 0, 0, 0.06)',
                        pt: 2
                      }}>
                        <Tooltip title="Edit">
                          <IconButton 
                            size="small" 
                            onClick={() => navigate('/admin/create-automation', { 
                              state: { automation: automation }
                            })}
                            sx={{ 
                              color: 'text.secondary',
                              '&:hover': {
                                backgroundColor: 'rgba(74, 93, 249, 0.08)',
                                color: '#4a5df9'
                              }
                            }}
                          >
                            <EditIcon weight="bold" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton 
                            size="small" 
                            onClick={() => handleDeleteAutomation(automation.id)}
                            sx={{ 
                              color: 'error.main',
                              '&:hover': {
                                backgroundColor: 'rgba(211, 47, 47, 0.08)'
                              }
                            }}
                          >
                            <TrashIcon weight="bold" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        {/* Extra bottom space */}
        <Box sx={{ mb: 40 }} /> {/* Increased bottom margin */}
      </Box>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AdminSettingsAutomation; 
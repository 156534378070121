import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Select, 
  MenuItem, 
  FormControl,
  InputLabel,
  Grid,
  InputAdornment,
  Alert,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Checkbox,
  FormGroup,
  FormLabel,
} from '@mui/material';
import {
  User,
  Briefcase,
  LockSimple,
  Globe,
  SquaresFour,
  Calendar,
  Users,
  MagnifyingGlass,
  Calculator,
  Hammer,
  Receipt,
  UsersThree,
  Notebook,
  ChartLine,
  Gear,
} from "@phosphor-icons/react";
import { createEmployee } from '../services/api';
import { useNavigate } from 'react-router-dom';
import styles from './CreateEmployee.module.scss';

const inputStyles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: 'var(--background-paper)',
    border: '1px solid var(--divider)',
    transition: 'all 0.2s ease-in-out',
    '& fieldset': {
      border: '1px solid var(--divider)',
    },
    '&:hover': {
      '& fieldset': {
        borderColor: 'var(--primary-light)',
      }
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: 'var(--primary-main)',
        borderWidth: '1px',
      }
    }
  },
  '& .MuiInputLabel-root': {
    color: 'var(--text-secondary)',
    fontSize: '0.875rem',
    backgroundColor: 'var(--background-paper)',
    padding: '0 4px',
    '&.Mui-focused': {
      color: 'var(--primary-main)',
    }
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
    padding: '12px 14px',
    height: '24px',
    '&::placeholder': {
      color: 'var(--text-secondary)',
      opacity: 0.7,
    }
  }
};

const selectStyles = {
  ...inputStyles,
  '& .MuiSelect-select': {
    padding: '16px 14px',
    fontSize: '0.875rem',
    height: '24px',
  }
};

const CreateEmployee = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [position, setPosition] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('employee'); // Default role
  const [defaultRate, setDefaultRate] = useState('');
  const [error, setError] = useState('');
  const [permissions, setPermissions] = useState({
    dashboard: false,
    calendar: false,
    team: false,
    leads: false,
    estimates: false,
    projects: false,
    invoices: false,
    clients: false,
    priceBook: false,
    reports: false,
    settings: false,
  });
  const [language, setLanguage] = useState('en'); // Default language
  const navigate = useNavigate();

  const formatCurrency = (value) => {
    // Remove non-numeric characters except decimal point
    const numericValue = value.replace(/[^0-9.]/g, '');
    return numericValue;
  };

  const handleRateChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setDefaultRate(formattedValue);
  };

  const handlePermissionChange = (permission) => (event) => {
    setPermissions(prev => ({
      ...prev,
      [permission]: event.target.checked
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createEmployee({ 
        name, 
        email, 
        phone, 
        position, 
        address,
        password,
        role,
        default_rate: defaultRate,
        permissions,
        language
      });
      navigate('/employees');
    } catch (error) {
      console.error('Failed to create employee:', error);
      setError('Failed to create employee. Please try again.');
    }
  };

  const permissionItems = [
    { key: 'dashboard', label: 'Dashboard', icon: <SquaresFour size={20} weight="light" /> },
    { key: 'calendar', label: 'Calendar', icon: <Calendar size={20} weight="light" /> },
    { key: 'team', label: 'Team', icon: <Users size={20} weight="light" /> },
    { key: 'leads', label: 'Leads', icon: <MagnifyingGlass size={20} weight="light" /> },
    { key: 'estimates', label: 'Estimates', icon: <Calculator size={20} weight="light" /> },
    { key: 'projects', label: 'Projects', icon: <Hammer size={20} weight="light" /> },
    { key: 'invoices', label: 'Invoices', icon: <Receipt size={20} weight="light" /> },
    { key: 'clients', label: 'Clients', icon: <UsersThree size={20} weight="light" /> },
    { key: 'priceBook', label: 'Price Book', icon: <Notebook size={20} weight="light" /> },
    { key: 'reports', label: 'Reports', icon: <ChartLine size={20} weight="light" /> },
    { key: 'settings', label: 'Settings', icon: <Gear size={20} weight="light" /> },
  ];

  return (
    <Container maxWidth={false} sx={{ py: 4, px: { xs: 2, sm: 3, md: 4 } }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ 
        mb: 4, 
        fontWeight: 600,
        fontSize: { xs: '1.5rem', sm: '2rem' }
      }}>
        New Employee
      </Typography>

      <Grid container spacing={4}>
        {/* Personal Information Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            bgcolor: 'background.paper', 
            p: 3, 
            borderRadius: 2,
            height: '100%',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
          }}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1.5,
              color: 'var(--text-primary)'
            }}>
              <Box component="span" sx={{ 
                width: 42, 
                height: 42, 
                borderRadius: '12px', 
                bgcolor: 'var(--primary-lighter)',
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'var(--primary-main)'
              }}>
                <User size={24} weight="light" />
              </Box>
              Personal Information
            </Typography>
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <TextField
                  label="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  sx={inputStyles}
                  placeholder="Enter full name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  sx={inputStyles}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                  sx={inputStyles}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  multiline
                  rows={2}
                  sx={inputStyles}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Employment Details Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            bgcolor: 'background.paper', 
            p: 3, 
            borderRadius: 2,
            height: '100%',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
          }}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1.5,
              color: 'var(--text-primary)'
            }}>
              <Box component="span" sx={{ 
                width: 42, 
                height: 42, 
                borderRadius: '12px', 
                bgcolor: 'var(--primary-lighter)',
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'var(--primary-main)'
              }}>
                <Briefcase size={24} weight="light" />
              </Box>
              Employment Details
            </Typography>
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <TextField
                  label="Position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  fullWidth
                  sx={inputStyles}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Default Rate"
                  value={defaultRate}
                  onChange={handleRateChange}
                  fullWidth
                  sx={inputStyles}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  sx={inputStyles}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Permissions Section */}
        <Grid item xs={12}>
          <Box sx={{ 
            bgcolor: 'background.paper', 
            p: 3, 
            borderRadius: 2,
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
          }}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1.5,
              color: 'var(--text-primary)'
            }}>
              <Box component="span" sx={{ 
                width: 42, 
                height: 42, 
                borderRadius: '12px', 
                bgcolor: 'var(--primary-lighter)',
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'var(--primary-main)'
              }}>
                <LockSimple size={24} weight="light" />
              </Box>
              Permissions
            </Typography>

            <Box sx={{ mb: 2 }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" sx={{ 
                  mb: 2, 
                  color: 'text.primary',
                  fontSize: '0.875rem',
                  '&.Mui-focused': { color: 'text.primary' }
                }}>
                  Access Control
                </FormLabel>
                <FormGroup>
                  <Grid container spacing={2}>
                    {/* Role Selection */}
                    <Grid item xs={12}>
                      <Box sx={{ 
                        p: 2, 
                        bgcolor: 'var(--background-default)',
                        borderRadius: 1,
                        mb: 2
                      }}>
                        <Typography variant="subtitle2" sx={{ mb: 1.5, color: 'text.secondary' }}>
                          Role
                        </Typography>
                        <RadioGroup
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          row
                          sx={{ mb: 1 }}
                        >
                          <FormControlLabel 
                            value="employee" 
                            control={<Radio />} 
                            label="Employee"
                            sx={{ mr: 4 }}
                          />
                          <FormControlLabel 
                            value="admin" 
                            control={<Radio />} 
                            label="Admin" 
                          />
                        </RadioGroup>
                        <Typography variant="caption" color="text.secondary">
                          Admins automatically have access to all features
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Feature Permissions */}
                    <Grid item xs={12}>
                      <Box sx={{ 
                        p: 2, 
                        bgcolor: 'var(--background-default)',
                        borderRadius: 1,
                        mb: 2
                      }}>
                        <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.secondary' }}>
                          Feature Access
                        </Typography>
                        <Grid container spacing={2}>
                          {permissionItems.map(({ key, label, icon }) => (
                            <Grid item xs={12} sm={6} md={4} key={key}>
                              <FormControlLabel
                                control={
                                  <Checkbox 
                                    checked={permissions[key]} 
                                    onChange={handlePermissionChange(key)}
                                    disabled={role === 'admin'}
                                  />
                                }
                                label={
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box sx={{ 
                                      color: '#637381',
                                      display: 'flex',
                                      alignItems: 'center' 
                                    }}>
                                      {icon}
                                    </Box>
                                    <span>{label}</span>
                                  </Box>
                                }
                                sx={{
                                  width: '100%',
                                  margin: 0,
                                  p: 1,
                                  borderRadius: 1,
                                  '&:hover': {
                                    bgcolor: 'action.hover',
                                  },
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>

                    {/* Language Settings */}
                    <Grid item xs={12}>
                      <FormControl fullWidth sx={selectStyles}>
                        <InputLabel>Interface Language</InputLabel>
                        <Select
                          value={language}
                          onChange={(e) => setLanguage(e.target.value)}
                          label="Interface Language"
                        >
                          <MenuItem value="en">English</MenuItem>
                          <MenuItem value="es">Español</MenuItem>
                          <MenuItem value="fr">Français</MenuItem>
                          <MenuItem value="de">Deutsch</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          variant="contained"
          startIcon={<User size={20} weight="light" />}
          sx={{
            boxShadow: 'none',
            backgroundColor: '#3c50fa',
            '&:hover': {
              backgroundColor: '#4a5df9 !important',
              boxShadow: 'none !important',
            },
            '&.MuiButton-contained:hover': {
              backgroundColor: '#3c50fa',
              boxShadow: 'none',
            },
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '0.875rem',
            padding: '6px 16px',
            borderRadius: '8px'
          }}
        >
          Create Employee
        </Button>
      </Box>
    </Container>
  );
};

export default CreateEmployee;

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProjectDetails, updateProjectScopeOfWork, updateProjectDetails, getCoordinatesFromAddress, shareProjectWithClient } from '../services/api';
import styles from './ProjectDetails.module.scss';
import ScheduleUpdater from './ScheduleUpdater';
import Events from './Events';
import { Tabs, Tab, Box, Paper, IconButton, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Pagination } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Person as PersonIcon, Description as DescriptionIcon, EventNote as EventNoteIcon, Work as WorkIcon, Group as GroupIcon, Photo as PhotoIcon, AccessTime as AccessTimeIcon, AttachMoney as AttachMoneyIcon, ArrowBack as ArrowBackIcon, LocationOn as LocationOnIcon, DateRange as DateRangeIcon, MonetizationOn as MonetizationOnIcon } from '@mui/icons-material';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Chip } from '@mui/material';
import ProjectPhotos from './ProjectPhotos';
import Timesheets from './Timesheets';
import Financials from './Financials';
import { Map, Marker } from 'pigeon-maps';
import { blue, green, orange, red, purple, grey } from '@mui/material/colors';
import ShareIcon from '@mui/icons-material/Share';
import { shareProject, getClients } from '../services/api';
import {
  Typography,
  CircularProgress,
  Divider,
  ListItemIcon,
  InputAdornment,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Fade } from '@mui/material';
import ScheduleEventsModal from './ScheduleEventsModal';
import HomeIcon from '@mui/icons-material/Home';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import UpdateIcon from '@mui/icons-material/Update';
import ProjectDetailsContent from './ProjectDetailsContent';
import ProjectStatusBadge from './ProjectStatusBadge';
import TagsSection from './TagsSection';
import ProjectActionButton from './ProjectActionButton';
import { 
  House as HouseIcon,
  Calendar as CalendarIcon,
  ClockClockwise as TimesheetIcon,
  CurrencyCircleDollar as FinanceIcon,
  Image as ImageIcon,
  ListChecks as EventsIcon
} from '@phosphor-icons/react';


const ProjectDetails = () => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [scopeOfWork, setScopeOfWork] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [coordinates, setCoordinates] = useState([0, 0]);
  const { id } = useParams(); // Extract 'id' from route params
  const [openShareModal, setOpenShareModal] = useState(false);
  const [clientsData, setClientsData] = useState({ clients: [], total: 0, pages: 1, current_page: 1 });
  const [selectedClients, setSelectedClients] = useState([]);
  const [shareError, setShareError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [unscheduledEvents, setUnscheduledEvents] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  const fetchProject = async () => {
    try {
      setLoading(true);
      const response = await getProjectDetails(id);
      console.log("Fetched project details:", response.data);
      setProject(response.data);
      
      if (response.data.scope_of_work) {
        console.log("Setting scope of work with:", response.data.scope_of_work);
        setScopeOfWork(response.data.scope_of_work);
      }
      
      setError(null);
    } catch (err) {
      console.error('Error fetching project:', err);
      setError('Failed to fetch project details');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProject();
  }, [id]);

  const fetchClients = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await getClients(page, 20, project.id);  // Increased per_page to 20
      setClientsData(data);
      // Pre-select already assigned clients
      setSelectedClients(data.clients.filter(client => client.is_assigned).map(client => client.id));
    } catch (error) {
      console.error('Error fetching clients:', error);
      setShareError('Failed to fetch clients. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (openShareModal) {
      fetchClients();
    }
  }, [openShareModal]);

  const handleScheduleUpdate = (updatedSchedule) => {
    setProject(prevProject => ({
      ...prevProject,
      start_time: updatedSchedule.start_time,
      end_time: updatedSchedule.end_time
    }));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleScopeOfWorkChange = (content) => {
    setScopeOfWork(content);
  };

  const handleSaveScopeOfWork = async () => {
    try {
      await updateProjectScopeOfWork(project.id, scopeOfWork);
      setProject(prevProject => ({
        ...prevProject,
        scope_of_work: scopeOfWork
      }));
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating scope of work:', err);
      setError('Failed to update scope of work. Please try again.');
    }
  };

  const handleEmployeeUpdate = async (updatedEmployees) => {
    console.log("Updated employees:", updatedEmployees);
    await fetchProject(); // Re-fetch project details after employee update
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedDetails({
      description: project.description,
      address: project.address,
      start_time: project.start_time,
      end_time: project.end_time,
      total_value: project.total_value,
    });
  };

  const handleSave = async () => {
    try {
      // Create a copy of editedDetails without any conversion
      const detailsToSave = {
        ...editedDetails,
        start_time: editedDetails.start_time,
        end_time: editedDetails.end_time
      };

      console.log('Saving raw times:', detailsToSave); // Debug log
      const response = await updateProjectDetails(project.id, detailsToSave);
      
      if (response.success) {
        // Update local state with exact same times
        setProject(prevProject => ({
          ...prevProject,
          ...detailsToSave
        }));
        setIsEditing(false);
      } else {
        setError(`Failed to update project: ${response.message}`);
      }
    } catch (err) {
      console.error('Error updating project details:', err);
      setError(`Failed to update project details: ${err.message}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (project && project.address) {
      getCoordinatesFromAddress(project.address)
        .then(coords => setCoordinates(coords))
        .catch(err => console.error('Error getting coordinates:', err));
    }
  }, [project]);

  const getTabColor = (index) => {
    if (activeTab === index) {
      switch(index) {
        case 0: return blue[500];
        case 1: return green[500];
        case 2: return orange[500];
        case 3: return red[500];
        case 4: return purple[500];
        case 5: return blue[700];
        case 6: return green[700];
        default: return grey[500];
      }
    }
    return grey[500]; // default color for inactive tabs
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
    setSelectedClients([]);
    setShareError(null);
  };

  const handleClientToggle = (clientId) => {
    setSelectedClients(prev =>
      prev.includes(clientId)
        ? prev.filter(id => id !== clientId)
        : [...prev, clientId]
    );
  };

  const handleShareWithClients = async () => {
    setShareError(null);
    setIsLoading(true);
    try {
      for (const clientId of selectedClients) {
        await shareProject(project.id, clientId);
      }
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        handleCloseShareModal();
      }, 2000);
    } catch (error) {
      console.error('Error sharing project:', error);
      setShareError(error.message || 'Failed to share project');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    fetchClients(value);
  };

  const filteredClients = clientsData.clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (project?.events) {
      const unscheduled = project.events.filter(event => event.status === 'unscheduled');
      setUnscheduledEvents(unscheduled);
      setTimeSlots(unscheduled.map(() => ({ start_time: '', end_time: '' })));
    }
  }, [project]);

  const handleTimeSlotChange = (index, field, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index] = {
      ...newTimeSlots[index],
      [field]: value
    };
    setTimeSlots(newTimeSlots);
  };

  const handleScheduleSubmit = async () => {
    try {
      const eventPromises = unscheduledEvents.map((event, index) => 
        updateProjectDetails(project.id, {
          ...event,
          status: 'scheduled',
          time_slots: [{
            start_time: timeSlots[index].start_time,
            end_time: timeSlots[index].end_time
          }]
        })
      );

      await Promise.all(eventPromises);
      setShowScheduleModal(false);
      fetchProject();
    } catch (error) {
      console.error('Error scheduling events:', error);
      setError('Failed to schedule events');
    }
  };

  const renderDetailItem = (Icon, label, value, colorClass) => (
    <div className={`${styles.detailItem} ${styles[colorClass]}`}>
      <Icon className={styles.icon} />
      <Typography variant="subtitle1">
        <span className={styles.label}>{label}:</span>
        <span className={styles.value}>{value || 'N/A'}</span>
      </Typography>
    </div>
  );

  const handleStatusUpdate = async () => {
    console.log('Status update triggered');
    await fetchProject(); // Refresh the project data
  };

  useEffect(() => {
    if (project?.scope_of_work) {
      try {
        // Try to parse if it's JSON
        const notes = JSON.parse(project.scope_of_work);
        if (Array.isArray(notes)) {
          // If it's an array of notes, format them nicely
          const formattedNotes = notes.map(note => 
            `${note.content}\nAdded by ${note.user} on ${new Date(note.timestamp).toLocaleString()}`
          ).join('\n\n');
          setScopeOfWork(formattedNotes);
        } else {
          // If it's not an array, just set the content
          setScopeOfWork(project.scope_of_work);
        }
      } catch (e) {
        // If it's not JSON, just set the raw notes
        setScopeOfWork(project.scope_of_work);
      }
    } else {
      setScopeOfWork('');
    }
  }, [project]);

  const handleEventUpdated = async () => {
    await fetchProject();  // Refresh the entire project data
  };

  if (loading) return <div className={styles.message}>Loading...</div>;
  if (error) return <div className={styles.message}>Error: {error}</div>;
  if (!project) return <div className={styles.message}>Project not found.</div>;

  console.log("Current project state:", project);
  console.log("Employees in current project state:", project?.employees);

  const projectDetailsProps = {
    project,
    isEditing,
    editedDetails,
    handleInputChange,
    handleEdit,
    handleSave,
    setIsEditing,
    onProjectUpdated: fetchProject,
    scopeOfWork,
    setScopeOfWork,
    handleSaveScopeOfWork
  };

  const scheduleProps = {
    project,
    onUpdate: handleScheduleUpdate
  };

  return (
    <div className={styles.projectDetails}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <Link to="/admin/projects" className={styles.backLink}>
            <ArrowBackIcon /> Back to Projects
          </Link>
          <div className={styles.titleRow}>
            <Typography variant="h4" className={styles.projectTitle}>
              {project?.name || 'Loading...'}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <ProjectStatusBadge 
                project={project} 
                shouldRefresh={true}
              />
              <ProjectActionButton 
                project={project} 
                onStatusUpdate={handleStatusUpdate}
              />
            </Box>
          </div>
        </div>
      </div>

      <div className={styles.tabsWrapper}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            px: 2,
            '& .MuiTabs-indicator': {
              backgroundColor: '#7635dc',
              height: '2px',
              borderRadius: '3px 3px 0 0',
              transition: 'all 0.2s ease'
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              color: 'var(--text-secondary)',
              minHeight: 48,
              padding: '12px 24px',
              gap: '8px',
              position: 'relative',
              '& .MuiTouchRipple-root': {
                display: 'none'
              },
              '& svg': {
                marginBottom: '0 !important',
                marginRight: '0 !important'
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0,
                height: '2px',
                borderRadius: '3px 3px 0 0',
                backgroundColor: '#7635dc',
                opacity: 0.6,
                transition: 'all 0.2s ease',
                transform: 'translateX(-50%)',
              },
              '&:hover::after': {
                width: '80%',
              },
              '&.Mui-selected': {
                color: 'var(--primary-main)',
                fontWeight: 600,
                '&::after': {
                  opacity: 0,
                },
              },
              '&:hover': {
                color: 'var(--primary-main)',
              }
            },
          }}
        >
          <Tab 
            icon={<HouseIcon size={20} weight="light" />}
            iconPosition="start"
            label="Overview" 
            disableRipple
          />
          <Tab 
            icon={<CalendarIcon size={20} weight="light" />}
            iconPosition="start"
            label="Schedule" 
            disableRipple
          />
          <Tab 
            icon={<TimesheetIcon size={20} weight="light" />}
            iconPosition="start"
            label="Timesheets" 
            disableRipple
          />
          <Tab 
            icon={<ImageIcon size={20} weight="light" />}
            iconPosition="start"
            label="Photos" 
            disableRipple
          />
          <Tab 
            icon={<FinanceIcon size={20} weight="light" />}
            iconPosition="start"
            label="Financials" 
            disableRipple
          />
        </Tabs>
      </div>

      <div className={styles.contentWrapper}>
        {/* Tab Panels */}
        <Fade in={activeTab === 0} timeout={500}>
          <div className={styles.tabPanel} style={{ display: activeTab === 0 ? 'block' : 'none' }}>
            <ProjectDetailsContent {...projectDetailsProps} />
            

          </div>
        </Fade>

            {/* Schedule Tab */}
            <Fade in={activeTab === 1} timeout={500}>
              <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
                <div className={styles.scheduleUpdate}>
                  <ScheduleUpdater
                    projectId={project.id}
                    currentStartDate={project.start_time}
                    currentEndDate={project.end_time}
                    onUpdate={handleScheduleUpdate}
                  />
                  {unscheduledEvents.length > 0 && (
                    <Paper
                      sx={{
                        p: 2,
                        mt: 2,
                        backgroundColor: 'primary.light',
                        color: 'primary.contrastText',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={() => setShowScheduleModal(true)}
                    >
                      <Typography>
                        You have {unscheduledEvents.length} unscheduled event{unscheduledEvents.length !== 1 ? 's' : ''} - Click here to schedule them ⚡
                      </Typography>
                    </Paper>
                  )}
                </div>
              </div>
            </Fade>

        {/* Timesheets Tab */}
        <Fade in={activeTab === 2} timeout={500}>
          <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
            <div className={styles.projectTimesheets}>
              <Timesheets projectId={project.id} />
            </div>
          </div>
        </Fade>

        {/* Photos Tab */}
        <Fade in={activeTab === 3} timeout={500}>
          <div 
            className={styles.tabPanel} 
            style={{ 
              display: activeTab === 3 ? 'block' : 'none',
              height: '100%',
              overflow: 'hidden'
            }}
          >
            <div className={styles.projectPhotos}>
              <ProjectPhotos projectId={project.id} />
            </div>
          </div>
        </Fade>

        {/* Financials Tab */}
        <Fade in={activeTab === 4} timeout={500}>
          <div style={{ display: activeTab === 4 ? 'block' : 'none' }}>
            <div className={styles.projectFinancials}>
              <Financials projectId={project.id} />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default ProjectDetails;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@mui/material';
import { 
  ArrowLeft, 
  ArrowSquareOut,
  Buildings, 
  MapPin, 
  Package, 
  ClipboardText,
  User,
  EnvelopeSimple,
  Phone,
  Warning,
  CaretRight
} from '@phosphor-icons/react';
import { getEstimates, convertEstimateToProject } from '../services/api';
import { formatDate } from '../utils/dateUtils';

// Move ConfirmationModal outside the main component
const ConfirmationModal = ({ 
  open, 
  onClose, 
  estimate, 
  onConfirm, 
  loading, 
  calculateTotal 
}) => {
  if (!estimate) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        borderBottom: '1px solid #e0e0e0'
      }}>
        <ArrowSquareOut size={28} weight="light" color="#0066FF" />
        <Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
          Convert Estimate to Project
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <Box sx={{ mt: 3 }}>
          {/* Key Information Row */}
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(3, 1fr)', 
            gap: 4,
            mb: 4,
            p: 3,
            backgroundColor: '#f8f9fa',
            borderRadius: 2,
            border: '1px solid #e0e0e0'
          }}>
            {/* Client Info */}
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <User size={24} weight="light" color="#0066FF" />
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
                  Client
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 500, mb: 1 }}>
                {estimate.customer_details?.name || 'No Name'}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                <EnvelopeSimple size={16} weight="light" />
                {estimate.customer_details?.email}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Phone size={16} weight="light" />
                {estimate.customer_details?.phone}
              </Typography>
            </Box>

            {/* Address */}
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <MapPin size={24} weight="light" color="#0066FF" />
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
                  Service Location
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {estimate.service_address}
              </Typography>
            </Box>

            {/* Value */}
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Package size={24} weight="light" color="#0066FF" />
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
                  Total Value
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ fontWeight: 600, color: '#0066FF' }}>
                ${calculateTotal(estimate.items || []).toFixed(2)}
              </Typography>
            </Box>
          </Box>

          {/* Estimate Details */}
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <ClipboardText size={20} weight="light" color="#0066FF" />
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                Estimate #{estimate.estimate_number}
              </Typography>
            </Box>
          </Box>

          {/* Items Section */}
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Package size={20} weight="light" color="#0066FF" />
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                Items
              </Typography>
            </Box>
            {estimate.items?.map((item, index) => (
              <Paper key={index} sx={{ p: 2, mb: 2, backgroundColor: '#f8f9fa' }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Quantity: {item.quantity} × ${item.unitPrice} = ${(item.quantity * item.unitPrice).toFixed(2)}
                </Typography>
                {item.description && (
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                    {item.description}
                  </Typography>
                )}
              </Paper>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ 
        px: 3, 
        pb: 3, 
        pt: 2,
        gap: 2,
        borderTop: '1px solid #e0e0e0',
        backgroundColor: '#f8f9fa'
      }}>
        <Button 
          startIcon={<ArrowLeft weight="regular" />}
          onClick={onClose}
          sx={{ 
            textTransform: 'none',
            color: '#666666',
            px: 3,
            py: 1,
            borderRadius: 2,
            '&:hover': {
              backgroundColor: '#e0e0e0'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<ArrowSquareOut weight="regular" size={20} />}
          onClick={onConfirm}
          disabled={loading}
          sx={{
            textTransform: 'none',
            backgroundColor: '#0066FF',
            borderRadius: 2,
            px: 4,
            py: 1,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: '#0052CC',
              transform: 'translateY(-1px)',
              boxShadow: '0 4px 12px rgba(0,102,255,0.2)'
            },
            '&:disabled': {
              backgroundColor: '#e0e0e0',
              color: '#666666'
            }
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: '#fff' }} />
          ) : (
            'Convert to Project'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateProjectFromEstimate = () => {
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEstimates();
  }, []);

  const fetchEstimates = async () => {
    console.log('🔄 Fetching available estimates...');
    try {
      const data = await getEstimates();
      console.log('📥 Raw estimates data:', data);
      // Filter out estimates that are already converted to projects
      const availableEstimates = data.filter(estimate => !estimate.converted_to_project);
      console.log('✅ Available estimates for conversion:', availableEstimates);
      setEstimates(availableEstimates);
    } catch (err) {
      console.error('❌ Error fetching estimates:', err);
      setError('Failed to fetch estimates');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (estimate) => {
    setSelectedEstimate(estimate);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedEstimate(null);
    setOpenModal(false);
  };

  const handleConfirmConversion = async () => {
    if (!selectedEstimate) return;
    
    try {
      setLoading(true);
      const result = await convertEstimateToProject(selectedEstimate.id, {
        project_type: 'single_day',
        schedule_now: false,
        items: []
      });
      console.log('✅ Conversion successful:', result);
      setSuccess(true);
      handleCloseModal();
      setTimeout(() => {
        navigate(`/admin/projects/${result.project_id}`);
      }, 1500);
    } catch (err) {
      console.error('❌ Conversion failed:', err);
      setError('Failed to convert estimate to project');
    } finally {
      setLoading(false);
    }
  };

  const calculateTotal = (items) => {
    return items.reduce((sum, item) => {
      const itemTotal = (item.unitPrice || 0) * (item.quantity || 1);
      return sum + itemTotal;
    }, 0);
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box sx={{ 
        mb: 4, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        background: 'white',
        p: 3,
        borderRadius: 2,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ClipboardText size={32} weight="light" color="#0066FF" />
          <Typography variant="h4" component="h1" sx={{ fontWeight: 600 }}>
            Convert Estimate to Project
          </Typography>
        </Box>
        <Button 
          startIcon={<ArrowLeft weight="regular" />}
          onClick={() => navigate('/admin/create-project')}
          sx={{ 
            textTransform: 'none',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.04)'
            }
          }}
        >
          Back
        </Button>
      </Box>

      {estimates.length === 0 ? (
        <Paper 
          sx={{ 
            p: 4,
            textAlign: 'center',
            border: '2px dashed #e0e0e0',
            backgroundColor: 'transparent',
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Warning size={48} weight="light" color="#9e9e9e" />
          <Typography color="text.secondary">
            No estimates available for conversion
          </Typography>
        </Paper>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {estimates.map((estimate) => (
            <Paper
              key={estimate.id}
              sx={{
                p: 3,
                transition: 'all 0.2s ease-in-out',
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                '&:hover': {
                  borderColor: '#0066FF',
                  transform: 'translateY(-2px)',
                }
              }}
            >
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                width: '100%'
              }}>
                {/* Top Row - Estimate Number */}
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <ClipboardText size={24} weight="light" color="#0066FF" />
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Estimate #{estimate.estimate_number}
                  </Typography>
                </Box>

                {/* Bottom Row - Grid with Details and Button */}
                <Box sx={{ 
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr) auto',
                  gap: 3,
                  alignItems: 'center'
                }}>
                  {/* Client Name */}
                  <Box sx={{ 
                    p: 2, 
                    backgroundColor: '#f8f9fa',
                    borderRadius: 2,
                    border: '1px solid #e0e0e0'
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <User size={20} weight="light" color="#0066FF" />
                      <Typography variant="subtitle2" color="text.secondary">
                        Client
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {estimate.customer_details?.name || 'No Name'}
                    </Typography>
                  </Box>

                  {/* Created Date */}
                  <Box sx={{ 
                    p: 2, 
                    backgroundColor: '#f8f9fa',
                    borderRadius: 2,
                    border: '1px solid #e0e0e0'
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <MapPin size={20} weight="light" color="#0066FF" />
                      <Typography variant="subtitle2" color="text.secondary">
                        Created
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {formatDate(estimate.created_at)}
                    </Typography>
                  </Box>

                  {/* Total Value */}
                  <Box sx={{ 
                    p: 2, 
                    backgroundColor: '#f8f9fa',
                    borderRadius: 2,
                    border: '1px solid #e0e0e0'
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <Package size={20} weight="light" color="#0066FF" />
                      <Typography variant="subtitle2" color="text.secondary">
                        Total Value
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#0066FF' }}>
                      ${calculateTotal(estimate.items || []).toFixed(2)}
                    </Typography>
                  </Box>

                  {/* Convert Button */}
                  <Button
                    variant="contained"
                    startIcon={<ArrowSquareOut weight="regular" size={20} />}
                    onClick={() => handleOpenModal(estimate)}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#0066FF',
                      borderRadius: 2,
                      px: 4,
                      py: 1.5,
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      height: 'fit-content',
                      '&:hover': {
                        backgroundColor: '#0052CC',
                        transform: 'translateY(-1px)',
                        boxShadow: '0 4px 12px rgba(0,102,255,0.2)'
                      }
                    }}
                  >
                    Convert to Project
                  </Button>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      )}

      <ConfirmationModal 
        open={openModal}
        onClose={handleCloseModal}
        estimate={selectedEstimate}
        onConfirm={handleConfirmConversion}
        loading={loading}
        calculateTotal={calculateTotal}
      />
      
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={success}
        autoHideDuration={1500}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">
          Project created successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateProjectFromEstimate; 
import React, { useState, useEffect } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio, Paper, CircularProgress } from '@mui/material';
import { getClientProperties } from '../services/api';

// Add the same color constants
const primaryColor = '#4a5df9';
const primaryLightColor = '#6b7cfa';

const ClientPropertySelector = ({ selectedClient, onPropertySelect, project }) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      if (!selectedClient?.id) return;
      
      setLoading(true);
      try {
        const data = await getClientProperties(selectedClient.id);
        console.log('Client properties:', data);
        setProperties(data);
      } catch (error) {
        console.error('Error fetching properties:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [selectedClient]);

  const handlePropertyChange = (event) => {
    const propertyId = event.target.value;
    setSelectedPropertyId(propertyId);
    
    const selectedProperty = properties.find(p => p.id.toString() === propertyId);
    console.log('Selected property:', selectedProperty);
    
    if (selectedProperty) {
      onPropertySelect({
        service_address: selectedProperty.address,
        service_city: selectedProperty.city,
        service_state: selectedProperty.state,
        service_zip: selectedProperty.zip
      });
    }
  };

  if (!selectedClient) {
    return null;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
        Client Properties
      </Typography>
      
      {loading ? (
        <CircularProgress size={24} sx={{ color: primaryColor }} />
      ) : properties.length > 0 ? (
        <RadioGroup
          value={selectedPropertyId}
          onChange={handlePropertyChange}
        >
          {properties.map((property) => (
            <Box
              key={property.id}
              onClick={() => handlePropertyChange({ target: { value: property.id.toString() } })}
              sx={{ 
                p: 2, 
                mb: 1, 
                cursor: 'pointer',
                borderRadius: '8px',
                border: selectedPropertyId === property.id.toString() ? `1px solid ${primaryColor}` : '1px solid transparent',
                bgcolor: selectedPropertyId === property.id.toString() ? 'rgba(74, 93, 249, 0.04)' : 'transparent',
                '&:hover': {
                  bgcolor: 'rgba(74, 93, 249, 0.04)'
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio 
                  checked={selectedPropertyId === property.id.toString()}
                  value={property.id.toString()}
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    '&.Mui-checked': {
                      color: primaryColor,
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.MuiRadio-root': {
                      padding: '9px',
                    }
                  }}
                />
                <Typography variant="body2" color="text.primary">
                  {property.address}, {property.city}, {property.state} {property.zip}
                </Typography>
              </Box>
            </Box>
          ))}
        </RadioGroup>
      ) : (
        <Typography variant="body2" color="text.secondary">
          No properties found for this client
        </Typography>
      )}
    </Box>
  );
};

export default ClientPropertySelector; 
import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { format } from 'date-fns';
import styles from './WeekEventCard.module.scss';
import EditEvent from '../EditEvent';
import { useNavigate } from 'react-router-dom';

const WeekEventCard = ({ event, onEventUpdated, isCompact }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const cardRef = useRef(null);
  const detailsRef = useRef(null);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsOpen(!isOpen);
  };

  const handleEditClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsEditOpen(true);
  };

  const handleGoToProject = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (event.project?.id) {
      navigate(`/admin/projects/${event.project.id}`);
    }
  };

  // Close when clicking outside
  const handleClickOutside = (e) => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && detailsRef.current) {
      const rect = detailsRef.current.getBoundingClientRect();
      if (rect.right > window.innerWidth) {
        detailsRef.current.classList.add('rightOverflow');
      } else {
        detailsRef.current.classList.remove('rightOverflow');
      }
    }
  }, [isOpen]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'invoiced':
        return '#34D399';
      case 'pending_scheduling':
        return '#FBBF24';
      case 'scheduled':
        return '#60A5FA';
      default:
        return '#9CA3AF';
    }
  };

  const renderEventContent = () => {
    if (isCompact) {
      return (
        <div className={styles.eventContent}>
          <div className={styles.eventHeader}>
            <div className={styles.headerTop}>
              <div className={styles.titleTimeGroup}>
                <span className={styles.eventTitle}>{event.title}</span>
                <span className={styles.eventTime}>
                  {format(new Date(event.time_slots[0].start_time), 'h:mm a')}
                </span>
              </div>
              <span className={styles.statusBadge}>
                {event.status?.replace('_', ' ')}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.eventContent}>
        <div className={styles.eventHeader}>
          <div className={styles.headerTop}>
            <span className={styles.eventTime}>
              {format(new Date(event.time_slots[0].start_time), 'h:mm a')} - {' '}
              {format(new Date(event.time_slots[0].end_time), 'h:mm a')}
            </span>
            <span className={styles.statusBadge}>
              {event.status?.replace('_', ' ')}
            </span>
          </div>
          <span className={styles.eventTitle}>{event.title}</span>
          {event.project?.client?.name && (
            <div className={styles.clientSection}>
              <div className={styles.clientName}>
                {event.project.client.name}
              </div>
              {event.addresses?.[0] && (
                <div className={styles.address}>
                  {event.addresses[0]}
                </div>
              )}
            </div>
          )}
          {event.employees?.length > 0 && (
            <div className={styles.teamInfo}>
              Team: {event.employees.map(emp => emp.name).join(', ')}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        ref={cardRef}
        className={`${styles.eventCard} ${isCompact ? styles.compact : ''}`}
        onClick={handleClick}
        data-status={event.status?.toLowerCase()}
      >
        {renderEventContent()}
      </div>

      {isOpen && createPortal(
        <div 
          ref={detailsRef} 
          className={styles.eventDetails}
          style={{
            position: 'fixed',
            left: `${cardRef.current?.getBoundingClientRect().right + 10}px`,
            top: `${cardRef.current?.getBoundingClientRect().top}px`,
            maxHeight: '80vh',
            overflowY: 'auto'
          }}
        >
          <div className={styles.eventDetailHeader}>
            <div className={styles.headerInfo}>
              <span className={styles.detailTitle}>{event.title}</span>
              {event.project?.client?.name && (
                <div className={styles.clientInfo}>
                  <div>Client: {event.project.client.name}</div>
                  {event.addresses && event.addresses.length > 0 && (
                    <div className={styles.address}>
                      {event.addresses.map((address, index) => (
                        <div key={index}>{address}</div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div 
              className={styles.statusBadge}
              style={{ backgroundColor: getStatusColor(event.status) }}
            >
              {event.status?.replace('_', ' ')}
            </div>
          </div>

          <div className={styles.eventDetailBody}>
            {/* Project */}
            {event.project && (
              <p className={styles.detailProject}>
                <strong>Project:</strong> {event.project.name}
              </p>
            )}

            {/* Time */}
            <p className={styles.detailTime}>
              {event.time_slots && event.time_slots.length > 0 && (
                <>
                  <strong>Time:</strong>{' '}
                  {format(new Date(event.time_slots[0].start_time), 'EEE, MMM d, h:mm a')} -{' '}
                  {format(new Date(event.time_slots[0].end_time), 'h:mm a')}
                </>
              )}
            </p>

            {/* Employees */}
            {event.employees && event.employees.length > 0 && (
              <div className={styles.detailEmployees}>
                <strong>Team:</strong>
                <ul>
                  {event.employees.map((employee) => (
                    <li key={employee.id}>{employee.name}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Description */}
            {event.description && (
              <div className={styles.detailDescription}>
                <strong>Details:</strong>
                <pre>{event.description}</pre>
              </div>
            )}

            {/* Action Buttons */}
            <div className={styles.actionButtons}>
              <button 
                className={styles.editButton}
                onClick={handleEditClick}
              >
                Edit Event
              </button>
              {event.project?.id && (
                <button 
                  className={styles.projectButton}
                  onClick={handleGoToProject}
                >
                  Go to Project
                </button>
              )}
            </div>
          </div>
        </div>,
        document.body
      )}

      <EditEvent
        eventId={event.id}
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        onEventUpdated={() => {
          setIsEditOpen(false);
          setIsOpen(false);
          onEventUpdated();
        }}
      />
    </>
  );
};

export default WeekEventCard; 
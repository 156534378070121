import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import ActiveItemsList from './items/ActiveItemsList';
import SavedItemsModal from './items/SavedItemsModal';
import { createItem, getItems, deleteItem, getPriceBookItems, getPackages, getPricingRules, createPackage, createPricingRule, createPriceBookItem } from '../services/api';
import TotalSection from './items/TotalSection';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const ITEM_TYPES = {
  SAVED: 'saved',
  PACKAGE: 'package',
  PRICING: 'pricing',
  INVENTORY: 'inventory'
};

const SavedItemsManager = ({ onAddToEstimate, savedItems, setSavedItems, estimateItems, onRemoveItem }) => {
  const [unsavedItems, setUnsavedItems] = useState([]);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const fetchItems = async () => {
      try {
        // Fetch all item types
        const [savedItems, packages, pricingRules, inventoryItems] = await Promise.all([
          getItems(),
          getPackages(),
          getPricingRules(),
          getPriceBookItems()
        ]);

        // Format all items to maintain compatibility
        const formattedItems = [
          ...savedItems.map(item => ({ 
            ...item, 
            type: ITEM_TYPES.SAVED 
          })),
          ...packages.map(pkg => ({ 
            ...pkg,
            type: ITEM_TYPES.PACKAGE,
            title: pkg.name,
            description: pkg.description,
            unitPrice: pkg.total_price || 0,
            unit_price: pkg.total_price || 0,
          })),
          ...inventoryItems.map(item => ({
            ...item,
            type: ITEM_TYPES.INVENTORY,
            unitPrice: item.price,
            unit_price: item.price
          })),
          ...pricingRules.map(rule => ({
            ...rule,
            type: ITEM_TYPES.PRICING,
            title: rule.name,
            description: rule.description,
            unitPrice: rule.base_rate || 0,
            unit_price: rule.base_rate || 0
          }))
        ];

        setSavedItems(formattedItems);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
    fetchItems();
  }, [setSavedItems]);

  const handleEditItem = ({ index, field, value }) => {
    console.log('handleEditItem called with:', { index, field, value });
    const currentItem = estimateItems[index];
    
    // First, check if this is a library/saved item
    const isFromLibrary = !!(
      currentItem.fromAutocomplete || 
      currentItem.isFromLibrary || 
      currentItem.isSavedItem || 
      currentItem.saved || 
      currentItem.id
    );

    // Create base updated item with ALL flags explicitly set
    let updatedItem = {
      ...currentItem,
      // Set ALL flags consistently
      fromAutocomplete: isFromLibrary,
      isFromLibrary: isFromLibrary,
      isSavedItem: isFromLibrary,
      isNew: false,
      isEdited: true,
      hideLibraryBanner: false,  // Always show banner when edited
      saved: false,  // Reset saved state when edited
      
      // Add check flags
      id_check: !!currentItem.id,
      saved_check: true,  // Set to true to show banner
      autocomplete_check: isFromLibrary,
      banner_check: true  // Set to true to show banner
    };

    // Apply the edit
    switch (field) {
      case 'quantity':
        updatedItem.quantity = value === '' ? 1 : Number(value);
        break;
      case 'unit_price':
      case 'unitPrice':
      case 'price':
        const priceValue = value === '' ? 0 : Number(value);
        updatedItem.unit_price = priceValue;
        updatedItem.unitPrice = priceValue;
        break;
      default:
        updatedItem[field] = value;
    }

    // Update total if needed
    if (field === 'quantity' || field === 'unit_price' || field === 'unitPrice' || field === 'price') {
      updatedItem.total = (updatedItem.quantity || 1) * (updatedItem.unit_price || updatedItem.unitPrice || updatedItem.price || 0);
    }
    
    console.log('Final item with all flags:', updatedItem);
    onAddToEstimate(updatedItem, index);
  };

  const handleAddItem = (item = {}, index = null) => {
    // Only add an empty item if explicitly requested (no item passed)
    if (!item.title && !item.description && !item.quantity && !item.unitPrice && !item.type) {
      console.log('Adding new empty item');
      const emptyItem = {
        title: '',
        description: '',
        quantity: 1,
        unit_price: 0,
        unitPrice: 0,
        total: 0,
        isNew: true,
        isEdited: false,
        fromAutocomplete: false,
        saved: false,
        hideLibraryBanner: false
      };
      onAddToEstimate(emptyItem, index);
      return;
    }

    // Otherwise, process the item normally
    const quantity = Number(item.quantity) || 1;
    const price = Number(item.unitPrice || item.unit_price) || 0;

    const newItem = {
      ...item,
      title: item.title || '',
      description: item.description || '',
      quantity: quantity,
      unitPrice: price,
      unit_price: price,
      total: quantity * price,
      type: item.type || ITEM_TYPES.SAVED,
      id: item.id || null,
      isNew: item.isNew ?? true, // Keep isNew if provided, otherwise default to true
      fromAutocomplete: item.fromAutocomplete ?? false, // Keep fromAutocomplete if provided, otherwise default to false
      saved: item.saved ?? false,
      isEdited: item.isEdited ?? false,
      hideLibraryBanner: item.hideLibraryBanner ?? false
    };

    console.log('Adding item:', newItem);
    onAddToEstimate(newItem, index);
  };

  const handleAutocompleteSelect = (index, selectedItem) => {
    console.log('Autocomplete selected - Input:', selectedItem);

    // Create new item with appropriate flags for autocomplete selection
    const updatedItem = {
      // Core properties from selected item
      id: selectedItem.id,
      title: selectedItem.title || selectedItem.name || '',
      description: selectedItem.description || '',
      quantity: 1,
      unit_price: selectedItem.unit_price || selectedItem.unitPrice || selectedItem.price || 0,
      unitPrice: selectedItem.unit_price || selectedItem.unitPrice || selectedItem.price || 0,
      total: selectedItem.unit_price || selectedItem.unitPrice || selectedItem.price || 0,

      // Preserve type and original data
      type: selectedItem.type || ITEM_TYPES.SAVED,
      originalData: selectedItem, // Keep original data for reference

      // Set flags for a new item from autocomplete
      fromAutocomplete: true,
      isFromLibrary: true, // Assuming autocomplete items are from the library
      isSavedItem: selectedItem.type === ITEM_TYPES.SAVED, // Check if it's a saved item type
      isNew: false, // It's not a brand new item
      isEdited: false,
      hideLibraryBanner: true, // Hide the banner since it's from the library
      saved: selectedItem.type === ITEM_TYPES.SAVED, // It's saved if it's a saved item type

      // Add check flags (consider removing these if not needed)
      id_check: !!selectedItem.id, // Check if ID exists
      saved_check: selectedItem.type === ITEM_TYPES.SAVED,
      autocomplete_check: true,
      banner_check: false
    };

    console.log('Autocomplete - Final item:', updatedItem);
    onAddToEstimate(updatedItem, index);
  };

  const handleSaveItem = (index) => {
    setSelectedIndex(index);
    setIsManageModalOpen(true);
  };

  const handleDeleteSavedItem = async (itemId) => {
    try {
      await deleteItem(itemId);
      const updatedItems = await getItems();
      setSavedItems(updatedItems);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleDeleteItem = (index) => {
    if (onRemoveItem) {
      onRemoveItem(index);
    }
  };

  const handleDirectSave = async (index) => {
    console.log('Attempting to save item at index:', index);
    const itemToSave = estimateItems[index];
    console.log('Item to save:', itemToSave);

    try {
      let savedItem;
      
      // Use the appropriate API call based on item type
      switch (itemToSave.type) {
        case ITEM_TYPES.PACKAGE:
          savedItem = await createPackage({
            name: itemToSave.title,
            description: itemToSave.description,
            items: itemToSave.items?.map(item => ({
              type: item.type,
              item_id: item.id,
              quantity: Number(item.quantity) || 1,
              price: Number(item.unit_price || item.price) || 0,
              name: item.title || item.name
            })) || []
          });
          break;

        case ITEM_TYPES.PRICING:
          savedItem = await createPricingRule({
            name: itemToSave.title,
            description: itemToSave.description,
            serviceType: itemToSave.serviceType || 'default',
            calculationType: itemToSave.calculationType || 'fixed',
            baseRate: Number(itemToSave.unit_price || itemToSave.baseRate) || 0,
            fieldValues: itemToSave.fieldValues || {}
          });
          break;

        case ITEM_TYPES.INVENTORY:
          savedItem = await createPriceBookItem({
            title: itemToSave.title,
            description: itemToSave.description,
            price: Number(itemToSave.unit_price || itemToSave.price) || 0,
            quantity: Number(itemToSave.quantity) || 1,
            calculationType: itemToSave.calculationType || 'fixed'
          });
          break;

        default: // ITEM_TYPES.SAVED
          savedItem = await createItem({
            title: itemToSave.title,
            description: itemToSave.description,
            unit_price: Number(itemToSave.unit_price) || 0,
            quantity: Number(itemToSave.quantity) || 1
          });
      }

      console.log('Item saved successfully:', savedItem);

      // Refresh all items based on their types
      const [savedItems, packages, pricingRules, inventoryItems] = await Promise.all([
        getItems(),
        getPackages(),
        getPricingRules(),
        getPriceBookItems()
      ]);

      // Format and combine all items
      const formattedItems = [
        ...savedItems.map(item => ({ ...item, type: ITEM_TYPES.SAVED })),
        ...packages.map(pkg => ({ 
          ...pkg, 
          type: ITEM_TYPES.PACKAGE,
          title: pkg.name
        })),
        ...inventoryItems.map(item => ({ 
          ...item, 
          type: ITEM_TYPES.INVENTORY 
        })),
        ...pricingRules.map(rule => ({ 
          ...rule, 
          type: ITEM_TYPES.PRICING,
          title: rule.name
        }))
      ];

      setSavedItems(formattedItems);

      // Update the current item to show it's saved
      const updatedItem = {
        ...itemToSave,
        saved: true,
        id: savedItem.id,
        hideLibraryBanner: true
      };
      onAddToEstimate(updatedItem, index);

      setShowSuccessModal(true);
      setTimeout(() => setShowSuccessModal(false), 2000);

    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = estimateItems.findIndex(item => item.id === active.id);
      const newIndex = estimateItems.findIndex(item => item.id === over.id);
      
      const newItems = arrayMove(estimateItems, oldIndex, newIndex);
      newItems.forEach((item, index) => {
        onAddToEstimate(item, index);
      });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        mb: 3,
        px: 3,
        py: 2,
        borderBottom: '2px solid #e0e0e0',
        '& > div': {
          color: '#1a2027',
          fontWeight: 600,
          fontSize: '1.1rem',
          textTransform: 'uppercase',
          letterSpacing: '0.8px'
        }
      }}>
        <div style={{ flex: '1 1 50%' }}>Title/Description</div>
        <div style={{ 
          width: '100px', 
          textAlign: 'right', 
          paddingRight: '14px',
          marginRight: '70px'
        }}>Qty</div>
        <div style={{ 
          width: '100px', 
          textAlign: 'right', 
          paddingRight: '14px',
          marginRight: '60px'
        }}>Price</div>
        <div style={{ 
          width: '100px', 
          textAlign: 'right', 
          paddingRight: '14px',
          marginRight: '40px'
        }}>Total</div>
      </Box>

      <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext 
          items={estimateItems.map((item, index) => ({ ...item, id: item.id || `temp-id-${index}` }))} 
          strategy={verticalListSortingStrategy}
        >
          <ActiveItemsList
            items={estimateItems}
            onEdit={handleEditItem}
            onAddItem={handleAddItem}
            onDelete={handleDeleteItem}
            onSaveItem={handleDirectSave}
            savedItems={savedItems}
            onAutocompleteSelect={handleAutocompleteSelect}
            setIsManageModalOpen={setIsManageModalOpen}
          />
        </SortableContext>
      </DndContext>

      <TotalSection items={estimateItems} />

      <SavedItemsModal
        open={isManageModalOpen}
        onClose={() => setIsManageModalOpen(false)}
        savedItems={savedItems}
        itemToEdit={itemToEdit}
        setItemToEdit={setItemToEdit}
        onDelete={handleDeleteSavedItem}
      />

      <Dialog 
        open={showSuccessModal} 
        onClose={() => setShowSuccessModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => setShowSuccessModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ 
            backgroundColor: '#4caf50', 
            color: 'white',
            textAlign: 'center',
            pb: 2
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 1
            }}>
              <CheckCircleOutlineIcon 
                sx={{ 
                  fontSize: '48px',
                  animation: 'checkmark 0.5s ease-in-out',
                  '@keyframes checkmark': {
                    '0%': {
                      transform: 'scale(0)',
                      opacity: 0
                    },
                    '50%': {
                      transform: 'scale(1.2)',
                    },
                    '100%': {
                      transform: 'scale(1)',
                      opacity: 1
                    }
                  }
                }} 
              />
              Success
            </Box>
          </DialogTitle>
          <DialogContent sx={{ 
            mt: 2, 
            textAlign: 'center',
            color: '#666'
          }}>
            Item has been saved successfully.
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
};

export default SavedItemsManager;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import { CaretUp, CaretDown } from "@phosphor-icons/react";

const ClientDetailsProject = ({ client }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [sort, setSort] = useState('newest');

  useEffect(() => {
    if (client && client.projects) {
      const formattedProjects = client.projects.map(project => ({
        ...project,
        totalValue: parseFloat(project.total_value || 0),
        formattedValue: parseFloat(project.total_value || 0).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        }),
        laborCost: parseFloat(project.total_labor_cost || 0),
        formattedLaborCost: parseFloat(project.total_labor_cost || 0).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        }),
        totalHours: project.total_hours ? 
          (parseFloat(project.total_hours) / 3600).toFixed(2) : '0.00'
      }));

      setProjects(formattedProjects);
      setLoading(false);
    }
  }, [client]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending_scheduling':
        return '#E65100';
      case 'scheduled':
        return '#1565C0';
      case 'in_progress':
        return '#2E7D32';
      case 'pending_invoicing':
        return '#7B1FA2';
      case 'completed':
        return '#303F9F';
      default:
        return '#9CA3AF';
    }
  };

  const formatStatus = (status) => {
    if (!status) return 'Pending Scheduling';
    return status.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  return (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-default)' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>
                Project Name
              </TableCell>
              <TableCell 
                sx={{ 
                  color: 'var(--text-primary)', 
                  borderBottom: '1px solid var(--divider)',
                  cursor: 'pointer',
                  fontFamily: "'Urbanist', sans-serif",
                  fontWeight: 500
                }}
                onClick={() => setSort(sort === 'newest' ? 'oldest' : 'newest')}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Box component="span">Date</Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, lineHeight: 0 }}>
                    <CaretUp 
                      size={12}
                      weight="bold"
                      style={{ 
                        color: sort === 'oldest' ? 'var(--primary-main)' : 'var(--text-disabled)'
                      }} 
                    />
                    <CaretDown 
                      size={12}
                      weight="bold"
                      style={{ 
                        marginTop: '-4px',
                        color: sort === 'newest' ? 'var(--primary-main)' : 'var(--text-disabled)'
                      }} 
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }} align="right">Total Value</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }} align="right">Labor Cost</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }} align="right">Hours</TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500,
                paddingLeft: 4,
                textAlign: 'center'
              }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>Loading...</TableCell>
              </TableRow>
            ) : projects.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>No projects found</TableCell>
              </TableRow>
            ) : (
              [...projects]
                .sort((a, b) => {
                  const dateA = new Date(a.start_time || a.created_at);
                  const dateB = new Date(b.start_time || b.created_at);
                  return sort === 'newest' ? dateB - dateA : dateA - dateB;
                })
                .map((project) => (
                  <TableRow 
                    key={project.id}
                    hover
                    onClick={() => navigate(`/admin/projects/${project.id}`)}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      },
                      '& .MuiTableCell-root': {
                        color: 'var(--text-primary)',
                        borderBottom: '1px solid var(--divider)',
                        fontFamily: "'Urbanist', sans-serif"
                      }
                    }}
                  >
                    <TableCell>{project.name}</TableCell>
                    <TableCell>
                      {project.start_time ? new Date(project.start_time).toLocaleDateString() : 
                       project.created_at ? new Date(project.created_at).toLocaleDateString() : '-'}
                    </TableCell>
                    <TableCell align="right">{project.formattedValue}</TableCell>
                    <TableCell align="right">{project.formattedLaborCost}</TableCell>
                    <TableCell align="right">{project.totalHours}</TableCell>
                    <TableCell sx={{ 
                      paddingLeft: 4,
                      textAlign: 'center'
                    }}>
                      <Chip 
                        label={formatStatus(project.status)}
                        sx={{
                          backgroundColor: `${getStatusColor(project.status)}20`,
                          color: getStatusColor(project.status),
                          fontWeight: 500,
                          fontSize: '0.75rem',
                          fontFamily: "'Urbanist', sans-serif",
                          minWidth: '100px'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClientDetailsProject;
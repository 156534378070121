import React, { useState, useEffect } from 'react';
import { Paper, Typography, TextField, Button, Divider, Chip, Avatar, Grid, InputAdornment, ButtonGroup, Box, Menu, MenuItem, ListItemIcon, Stack, IconButton} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkIcon from '@mui/icons-material/Work';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SavingsIcon from '@mui/icons-material/Savings';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import styles from './ProjectDetailsContent.module.scss';
import { getProjectEvents, getProjectFinancials, getClients, updateProjectClient, getEventDetails } from '../services/api';
import PropertyMap from './PropertyMap';
import PropertyDetails from './PropertyDetails';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventDetails from './EventDetails';
import TagsSection from './TagsSection';
import { 
  Buildings, 
  EnvelopeSimple, 
  MapPin, 
  Phone 
} from '@phosphor-icons/react';
import { 
  Calendar as CalendarIcon,
  Clock as ClockIcon,
  MapPin as MapPinIcon,
  Users as UsersIcon
} from "@phosphor-icons/react";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const formatNumber = (value) => {
  if (!value && value !== 0) return '0';
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'N/A';
  // Remove all non-digits
  const cleaned = phoneNumber.replace(/\D/g, '');
  // Check if it's a valid 10-digit number
  if (cleaned.length !== 10) return phoneNumber;
  // Format as (XXX) XXX-XXXX
  return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
};

const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) return 'Not set';
  
  // Split into date and time parts
  const [datePart, timePart] = dateTimeString.split('T');
  
  // Format date
  const [year, month, day] = datePart.split('-');
  const formattedDate = `${month}/${day}/${year}`;
  
  // Format time
  const [hours, minutes] = timePart.slice(0, 5).split(':');
  const hour = parseInt(hours);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12;
  const formattedTime = `${hour12}:${minutes} ${ampm}`;
  
  return `${formattedDate}, ${formattedTime}`;
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/_/g, ' ');
};

const ProjectDetailsContent = ({ 
  project, 
  isEditing, 
  editedDetails, 
  handleInputChange, 
  handleEdit, 
  handleSave, 
  setIsEditing,
  onProjectUpdated,
  scopeOfWork,
  setScopeOfWork,
  handleSaveScopeOfWork
}) => {
  const [events, setEvents] = useState([]);
  const [financials, setFinancials] = useState({});
  const [clientDetails, setClientDetails] = useState(null);
  const [openClientDialog, setOpenClientDialog] = useState(false);
  const [availableClients, setAvailableClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      if (project?.id) {
        try {
          const eventData = await getProjectEvents(project.id);
          setEvents(eventData);
        } catch (error) {
          console.error('Failed to fetch events:', error);
        }
      }
    };

    fetchEvents();
  }, [project?.id]);

  useEffect(() => {
    const fetchFinancials = async () => {
      if (project?.id) {
        try {
          const data = await getProjectFinancials(project.id);
          setFinancials(data);
        } catch (error) {
          console.error('Failed to fetch financials:', error);
        }
      }
    };

    fetchFinancials();
  }, [project?.id]);

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (project?.id) {
        try {
          console.log("Fetching client details for Project:", project);
          
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No authentication token found');
          }

          // First get the project details to ensure we have the latest client_id
          const projectResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/projects/${project.id}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );

          if (!projectResponse.ok) {
            throw new Error('Failed to fetch project details');
          }

          const projectData = await projectResponse.json();
          console.log("Project data received:", projectData);
          
          const clientId = projectData.client_id;
          console.log("Retrieved client_id from project:", clientId);

          if (clientId) {
            // Now fetch the client details using the correct endpoint
            const clientResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/api/clients/${clientId}`,
              {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              }
            );
            
            if (!clientResponse.ok) {
              throw new Error('Failed to fetch client details');
            }

            const clientData = await clientResponse.json();
            console.log("Client details found:", clientData);
            setClientDetails(clientData);
          } else {
            console.log("No client associated with project");
            setClientDetails(null);
          }
        } catch (error) {
          console.error('Failed to fetch client details:', error);
          setClientDetails(null);
        }
      } else {
        setClientDetails(null);
      }
    };

    fetchClientDetails();
  }, [project?.id]);

  const formatTimeSlot = (timeSlot) => {
    try {
      // Log the incoming timeSlot for debugging
      console.log('Raw timeSlot:', timeSlot);

      // Format date to display in local format
      const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${month}/${day}/${year}`;
      };

      // Format time without any conversion
      const formatTime = (timeStr) => {
        const [hours, minutes] = timeStr.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes.slice(0,2)} ${ampm}`;
      };

      // Split the datetime strings
      const [startDate, startTime] = timeSlot.start_time.split('T');
      const [endDate, endTime] = timeSlot.end_time.split('T');

      return {
        date: formatDate(startDate),
        time: `${formatTime(startTime)} - ${formatTime(endTime)}`
      };
    } catch (error) {
      console.error('Error formatting time slot:', error);
      return { date: 'Invalid date', time: 'Invalid time' };
    }
  };

  const handleOpenClientDialog = async () => {
    setLoading(true);
    try {
      const response = await getClients(1, 100); // Get up to 100 clients
      setAvailableClients(response.clients);
      setOpenClientDialog(true);
    } catch (error) {
      console.error('Failed to fetch clients:', error);
    }
    setLoading(false);
  };

  const handleClientSelect = async (selectedClient) => {
    setError(null);
    setLoading(true);
    try {
      console.log('Attempting to associate client:', selectedClient.id, 'with project:', project.id);
      
      // Call the API to update the project's client
      await updateProjectClient(project.id, selectedClient.id);
      
      // After successful update, fetch the latest project details
      const token = localStorage.getItem('token');
      const projectResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/project/${project.id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!projectResponse.ok) {
        throw new Error('Failed to verify client association');
      }

      const updatedProject = await projectResponse.json();
      console.log('Updated project details:', updatedProject);

      // Verify the client association
      if (updatedProject.client_id !== selectedClient.id) {
        throw new Error('Client association verification failed');
      }

      // Set the client details
      setClientDetails(selectedClient);
      setOpenClientDialog(false);
      
      // Notify parent component
      if (typeof onProjectUpdated === 'function') {
        onProjectUpdated();
      }

      console.log('Client association successful');
    } catch (error) {
      console.error('Failed to associate client:', error);
      setError(error.message || 'Failed to associate client with project');
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeClick = (employeeId) => {
    navigate(`/admin/employees/${employeeId}`);
  };



  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemStyle = {
    fontSize: '1rem',
    color: '#333',
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  };


  const handleEventClick = async (eventId) => {
    try {
      setSelectedEventId(eventId);
      setIsEventDetailsOpen(true);
    } catch (error) {
      console.error('Error handling event click:', error);
    }
  };

  const refreshData = async () => {
    try {
      // Refresh project data
      if (onProjectUpdated) {
        await onProjectUpdated();
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  const handleTimeSlotChange = async (eventId, timeSlotIndex, field, value) => {
    try {
      const updatedTimeSlots = [...events.find(e => e.id === eventId).time_slots];
      updatedTimeSlots[timeSlotIndex] = {
        ...updatedTimeSlots[timeSlotIndex],
        [field]: value
      };

      await axios.patch(`${process.env.REACT_APP_API_URL}/api/events/${eventId}`, {
        time_slots: updatedTimeSlots
      });

      // Update local state
      setEvents(events.map(event => {
        if (event.id === eventId) {
          return { ...event, time_slots: updatedTimeSlots };
        }
        return event;
      }));

      await refreshData();
    } catch (error) {
      console.error('Failed to update time slot:', error);
    }
  };

  const handleEventDetailsClose = async () => {
    setIsEventDetailsOpen(false);
    setSelectedEventId(null);
    await refreshData();
  };

  return (
    <Box className={styles.container}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        mb: 3,
      }}>
      </Box>
      <Grid container spacing={0}>
        {/* Left Column (Main Content) */}
        <Grid item xs={12} md={9}>
          {/* Project Details */}
          <div className={styles.mainSection}>
            <div className={styles.content}>
              {/* Project Events */}
              <div className={styles.section}>
                <div className={`${styles.sectionHeader} ${styles.sectionHeaderEvents}`}>
                  <EventIcon className={styles.iconOrange} />
                  <Typography variant="subtitle1">Project Events</Typography>
                </div>
                
                <div className={styles.eventsContainer}>
                  {events.length > 0 ? (
                    events.map((event) => (
                      <div 
                        key={event.id} 
                        className={styles.eventItem}
                        onClick={() => handleEventClick(event.id)}
                      >
                        <div className={styles.eventHeader}>
                          <div className={styles.eventTitleSection} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className={styles.eventEmoji}>{event.emoji}</span>
                            <Typography variant="subtitle2" className={styles.eventTitle}>
                              {event.title}
                            </Typography>
                            <Chip
                              label={event.status?.charAt(0).toUpperCase() + event.status?.slice(1).replace(/_/g, ' ')}
                              size="small"
                              sx={{
                                ml: 1,
                                height: '20px',
                                backgroundColor: event.status === 'completed' ? '#E8F5E9' : 
                                                event.status === 'in_progress' ? '#E3F2FD' :
                                                event.status === 'pending' ? '#FFF3E0' : '#F5F5F5',
                                color: event.status === 'completed' ? '#2E7D32' :
                                       event.status === 'in_progress' ? '#1565C0' :
                                       event.status === 'pending' ? '#E65100' : '#757575',
                                '& .MuiChip-label': {
                                  px: 1,
                                  fontSize: '0.75rem',
                                  fontWeight: 500
                                }
                              }}
                            />
                          </div>
                          <Typography variant="body2" className={styles.eventValue}>
                            ${event.value}
                          </Typography>
                        </div>
                        
                        {event.description && (
                          <div className={styles.eventDescription}>
                            {event.description.split('\n').map((line, i) => (
                              <div key={i}>{line}</div>
                            ))}
                          </div>
                        )}

                        {/* Event Details Container */}
                        <div className={styles.eventDetailsGroup}>
                          {/* Time Slots */}
                          {event.time_slots?.map((timeSlot, index) => (
                            <div key={index} className={styles.timeSlot}>
                              <div className={styles.timeSlotText}>
                                <DateRangeIcon fontSize="small" />
                                {formatDateTime(timeSlot.start_time)}
                                <span className={styles.timeSlotDivider}>to</span>
                                <DateRangeIcon fontSize="small" />
                                {formatDateTime(timeSlot.end_time)}
                              </div>
                            </div>
                          ))}

                          {/* Employees */}
                          {event.employees?.length > 0 && (
                            <div className={styles.eventEmployees}>
                              <GroupIcon fontSize="small" />
                              <div className={styles.employeeChips}>
                                {event.employees.map((employee) => (
                                  <Chip
                                    key={employee.id}
                                    avatar={<Avatar alt={employee.name} src={employee.avatar_url}>{employee.name.charAt(0)}</Avatar>}
                                    label={employee.name}
                                    size="small"
                                    className={styles.employeeChip}
                                    onClick={() => handleEmployeeClick(employee.id)}
                                    clickable
                                    sx={{ cursor: 'pointer' }}
                                  />
                                ))}
                              </div>
                            </div>
                          )}

                          {/* Location */}
                          {event.addresses?.length > 0 && (
                            <div className={styles.eventLocation}>
                              <LocationOnIcon fontSize="small" />
                              <TextField
                                fullWidth
                                size="small"
                                value={event.addresses[0]}
                                placeholder="Enter location"
                                onChange={(e) => {
                                  const updatedAddresses = [e.target.value];
                                  axios.patch(`${process.env.REACT_APP_API_URL}/api/events/${event.id}`, {
                                    addresses: updatedAddresses
                                  });
                                  setEvents(events.map(evt => 
                                    evt.id === event.id ? { ...evt, addresses: updatedAddresses } : evt
                                  ));
                                }}
                                InputProps={{
                                  sx: {
                                    '&.MuiOutlinedInput-root': {
                                      '& fieldset': { border: 'none' },
                                      '&:hover fieldset': { border: 'none' },
                                      '&.Mui-focused fieldset': { border: 'none' },
                                      '& input': {
                                        color: 'var(--text-primary)',
                                        fontSize: '1rem',
                                        padding: '8px',
                                        fontFamily: 'inherit'
                                      }
                                    }
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <Typography className={styles.sectionContent}>
                      No events scheduled
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid>

        {/* Right Column (Client Info) */}
        <Grid item xs={12} md={3}>
          <Box sx={{
            width: '100%',
            maxWidth: { lg: 320 },
            p: 3,
            backgroundColor: '#fff',
            height: 'fit-content',
            position: { xs: 'static', md: 'sticky' },
            top: { md: 80 },
            borderLeft: { xs: 'none', md: '1px solid var(--divider)' },
            borderTop: { xs: '1px solid var(--divider)', md: 'none' },
            mt: { xs: 2, md: 0 },
            ml: { xs: 0, md: 3 },
            boxSizing: 'border-box',
            '& .MuiTypography-root': {
              fontFamily: "'Inter', sans-serif"
            },
            '&::-webkit-scrollbar': {
              width: '0px',
              background: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent'
            },
            '&:hover::-webkit-scrollbar-thumb': {
              background: 'transparent'
            }
          }}>
            {/* Contact Info Header */}
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: '0.875rem',
                fontWeight: 600,
                color: 'var(--text-primary)',
                mb: 2
              }}
            >
              Contact Info
            </Typography>

            {/* Client Info */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mb: 4
            }}>
              <Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  Name
                </Typography>
                <Typography sx={{ 
                  fontSize: '0.875rem',
                  color: 'var(--text-primary)'
                }}>
                  {clientDetails?.name || 'No name provided'}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  Company name
                </Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {clientDetails?.company || 'No company provided'}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  Phone
                </Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {formatPhoneNumber(clientDetails?.phone) || 'No phone provided'}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  Email
                </Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {clientDetails?.email || 'No email provided'}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  Parent Client
                </Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {clientDetails?.parent_client?.name || 'No parent client'}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  Lead Source
                </Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {clientDetails?.lead_source || 'No lead source'}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  Address
                </Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {clientDetails?.address || 'No address provided'}
                </Typography>
              </Box>
            </Box>

            {/* Tags Section */}
            <Box sx={{ mb: 3 }}>
              <TagsSection 
                projectId={project.id}
                tags={project.tags}
                onProjectUpdated={onProjectUpdated}
              />
            </Box>

            {/* Notes Section */}
            <Box>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 1.5
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    color: 'var(--text-primary)',
                    fontFamily: "'Inter', sans-serif"
                  }}
                >
                  Internal Notes
                </Typography>
                {!isEditing && (
                  <IconButton
                    onClick={() => {
                      setIsEditing(true);
                      setScopeOfWork(project?.scope_of_work || '');
                    }}
                    size="small"
                    sx={{ 
                      color: 'var(--text-secondary)',
                      '&:hover': { 
                        color: 'var(--text-primary)',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                      }
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
              
              {isEditing ? (
                <Box sx={{ 
                  border: '1px solid var(--divider)',
                  borderRadius: '8px',
                  overflow: 'hidden'
                }}>
                  <ReactQuill 
                    value={scopeOfWork} 
                    onChange={setScopeOfWork}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        ['clean']
                      ],
                    }}
                    sx={{
                      '& .ql-container': {
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        minHeight: '120px'
                      },
                      '& .ql-toolbar': {
                        borderBottom: '1px solid var(--divider)',
                        backgroundColor: '#f8fafc'
                      }
                    }}
                  />
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 1, 
                    p: 1.5,
                    borderTop: '1px solid var(--divider)',
                    backgroundColor: '#f8fafc'
                  }}>
                    <Button 
                      onClick={handleSaveScopeOfWork} 
                      variant="contained" 
                      size="small"
                      startIcon={<SaveIcon />}
                      sx={{ 
                        textTransform: 'none',
                        fontFamily: "'Inter', sans-serif"
                      }}
                    >
                      Save
                    </Button>
                    <Button 
                      onClick={() => setIsEditing(false)} 
                      variant="outlined" 
                      size="small"
                      startIcon={<CancelIcon />}
                      sx={{ 
                        textTransform: 'none',
                        fontFamily: "'Inter', sans-serif"
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ 
                  p: 1.5,
                  backgroundColor: '#f8fafc',
                  border: '1px solid var(--divider)',
                  borderRadius: '8px',
                  minHeight: '80px',
                  fontSize: '0.875rem',
                  color: 'var(--text-primary)',
                  '& p': { margin: 0 },
                  '& ul, & ol': { margin: '0.5em 0', paddingLeft: '1.5em' }
                }}>
                  {scopeOfWork || project?.scope_of_work ? (
                    <div dangerouslySetInnerHTML={{ 
                      __html: scopeOfWork || project?.scope_of_work 
                    }} />
                  ) : (
                    <Typography sx={{ 
                      color: 'var(--text-secondary)',
                      fontStyle: 'italic',
                      fontSize: '0.875rem'
                    }}>
                      No internal notes added
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Add the Client Selection Dialog */}
      <Dialog 
        open={openClientDialog} 
        onClose={() => setOpenClientDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select Client</DialogTitle>
        <DialogContent>
          <List>
            {availableClients.map((client) => (
              <ListItem 
                button 
                key={client.id}
                onClick={() => handleClientSelect(client)}
                className={styles.clientListItem}
              >
                <ListItemText
                  primary={client.name}
                  secondary={
                    <>
                      {client.email}
                      {client.phone && ` • ${formatPhoneNumber(client.phone)}`}
                      {client.company && `  ${client.company}`}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenClientDialog(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <EventDetails
        eventId={selectedEventId}
        open={isEventDetailsOpen}
        onClose={handleEventDetailsClose}
        onEventUpdated={() => {
          // Refresh events list after update
          const fetchEvents = async () => {
            if (project?.id) {
              try {
                const eventData = await getProjectEvents(project.id);
                setEvents(eventData);
              } catch (error) {
                console.error('Failed to fetch events:', error);
              }
            }
          };
          fetchEvents();
        }}
      />
    </Box>
  );
};

ProjectDetailsContent.propTypes = {
  project: PropTypes.object,
  isEditing: PropTypes.bool,
  editedDetails: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  setIsEditing: PropTypes.func,
  onProjectUpdated: PropTypes.func,
  scopeOfWork: PropTypes.string,
  setScopeOfWork: PropTypes.func,
  handleSaveScopeOfWork: PropTypes.func
};

export default ProjectDetailsContent;
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Menu, MenuItem, ListItemIcon, ListItemText, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, Chip, IconButton, Grid, Avatar, Divider, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import DefaultTemplate from '../templates/DefaultTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReceiptOutlined as ReceiptIcon } from '@mui/icons-material';
import { BuildOutlined as BuildIcon } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  AssignmentOutlined as AssignmentIcon,
  MonetizationOnOutlined as AttachMoneyIcon,
  EmailOutlined as EmailIcon,
  LocationOnOutlined as LocationOnIcon,
} from '@mui/icons-material';
import { getEstimate, createProject, createEvent, getPublicEstimate, convertEstimateToProject } from '../services/api';
import api from '../services/api';
import PropertyDetails from './PropertyDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import TagsSection from './TagsSection';
import html2pdf from 'html2pdf.js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFTemplate from '../templates/PDFTemplate';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateTime } from '../utils/dateUtils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { BusinessOutlined as BusinessIcon } from '@mui/icons-material';
import { PencilSimple, X, Check } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { 
  Buildings as BuildingsIcon,
  Phone as PhoneIcon,
  MapPin as MapPinIcon 
} from '@phosphor-icons/react';
import { 
  Pencil as PencilIcon,
  Eye as EyeIcon,
  FileArrowDown as FileArrowDownIcon,
  CalendarCheck as CalendarIcon,
  Briefcase as BriefcaseIcon,
  User as UserIcon,
  Signature as SignatureIcon,
  EnvelopeSimple as EnvelopeIcon
} from '@phosphor-icons/react';
import { Upload as UploadIcon } from '@phosphor-icons/react';
import { uploadEstimatePhoto } from '../services/api';

const ESTIMATE_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  CONVERTED: 'converted'
};

const STATUS_STYLES = {
  draft: {
    color: '#637381',
    backgroundColor: '#F4F6F8'
  },
  pending: {
    color: '#B76E00',
    backgroundColor: '#FFF5CC'
  },
  completed: {
    color: '#118D57',
    backgroundColor: '#E6F7ED'
  },
  converted: {
    color: '#006C9C',
    backgroundColor: '#E5F4FB'
  }
};

const getPrice = (item) => {
  return Number(item?.unit_price || item?.unitPrice || 0);
};

const getQuantity = (item) => {
  return Number(item?.quantity || 0);
};

const calculateItemTotal = (item) => {
  const price = getPrice(item);
  const quantity = getQuantity(item);
  return (price * quantity);
};

const getDisplayAddress = (estimate) => {
  if (estimate?.service_address) {
    return {
      address: estimate.service_address,
      city: estimate.service_city,
      state: estimate.service_state,
      zip: estimate.service_zip
    };
  }
  return {
    address: estimate?.customer_details?.address,
    city: estimate?.customer_details?.city,
    state: estimate?.customer_details?.state,
    zip: estimate?.customer_details?.zip_code
  };
};

const calculateSubtotal = (estimate) => {
  return estimate?.items?.reduce((sum, item) => {
    const price = getPrice(item);
    const quantity = getQuantity(item);
    return sum + (price * quantity);
  }, 0) || 0;
};

const calculateTotal = (estimate) => {
  return estimate?.items?.reduce((total, item) => {
    const itemTotal = item.total || (Number(item.unitPrice || 0) * Number(item.quantity || 0));
    return total + itemTotal;
  }, 0) || 0;
};

const formatNotes = (notesString) => {
  try {
    return JSON.parse(notesString);
  } catch {
    return notesString ? [{ content: notesString, timestamp: new Date().toISOString(), user: 'System' }] : [];
  }
};

const formatStatus = (status) => {
  if (!status) return 'Draft';
  
  switch (status.toLowerCase()) {
    case 'draft': return 'Draft';
    case 'pending': return 'Pending Approval';
    case 'completed': return 'Completed';
    case 'converted': return 'Converted';
    default: return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }
};

const AdminEstimateDetails = () => {
  const menuItemStyle = {
    py: 1.5,
    px: 2,
    mx: 1,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px'
    }
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showTimeSlotModal, setShowTimeSlotModal] = useState(false);
  const [convertedEvents, setConvertedEvents] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [showItemSelectionModal, setShowItemSelectionModal] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState({});
  const [groupCounter, setGroupCounter] = useState(1);
  const [includedItems, setIncludedItems] = useState([]);
  const [internalNotes, setInternalNotes] = useState('');
  const [editingNoteIndex, setEditingNoteIndex] = useState(null);
  const [editingNoteContent, setEditingNoteContent] = useState('');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signatureLink, setSignatureLink] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  const [showProjectTypeModal, setShowProjectTypeModal] = useState(false);
  const [projectType, setProjectType] = useState('single_day');
  const [conversionTimeSlot, setConversionTimeSlot] = useState({
    start_time: '',
    end_time: ''
  });
  const [scheduleNow, setScheduleNow] = useState(true);
  const [showUnsignedWarning, setShowUnsignedWarning] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [editedNotes, setEditedNotes] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [photoError, setPhotoError] = useState('');

  const handleClose = () => setAnchorEl(null);

  const handleShare = async () => {
    let publicLink = '';
    
    try {
      console.log('Starting share process...');
      const response = await api.post(`/estimates/${id}/generate-link`);
      console.log('API Response:', response.data);
      
      const publicUuid = response.data.public_uuid;
      if (!publicUuid) {
        throw new Error('No public UUID received');
      }

      publicLink = `${window.location.origin}/public/estimate/${publicUuid}`;
      console.log('Link to copy:', publicLink);

      // Try multiple clipboard methods
      try {
        // Method 1: Clipboard API
        await navigator.clipboard.writeText(publicLink);
      } catch (clipboardError) {
        console.log('Clipboard API failed, trying fallback method');
        
        // Method 2: execCommand with input element
        const input = document.createElement('input');
        input.value = publicLink;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999);
        
        const success = document.execCommand('copy');
        document.body.removeChild(input);
        
        if (!success) {
          throw new Error('Clipboard copy failed');
        }
      }

      console.log('Copy successful');
      setSuccessMessage('Link copied to clipboard! ⚡');
      setShowSuccess(true);
      handleClose();
      
    } catch (error) {
      console.error('Share error:', error);
      if (publicLink) {
        const userChoice = window.confirm(
          'Failed to copy automatically. Click OK to copy the link manually:\n' + publicLink
        );
        if (userChoice) {
          const input = document.createElement('input');
          input.value = publicLink;
          document.body.appendChild(input);
          input.select();
          input.setSelectionRange(0, 99999);
          try {
            document.execCommand('copy');
            setSuccessMessage('Link copied to clipboard! ⚡');
            setShowSuccess(true);
          } catch (e) {
            alert(`Please copy this link manually:\n${publicLink}`);
          }
          document.body.removeChild(input);
        }
      }
      setError('Failed to copy to clipboard');
    }
  };

  const handleGenerateBookingLink = async () => {
    try {
      const response = await api.post(`/estimates/${id}/generate-booking-link`);
      
      if (!response.data.booking_uuid) {
        throw new Error('No booking UUID received');
      }

      const bookingLink = `${window.location.origin}/estimate/book/${response.data.booking_uuid}`;
      
      // Create temporary input element
      const tempInput = document.createElement('input');
      tempInput.style.position = 'absolute';
      tempInput.style.left = '-9999px';
      tempInput.value = bookingLink;
      document.body.appendChild(tempInput);

      // Select and copy
      tempInput.select();
      tempInput.setSelectionRange(0, 99999); // For mobile devices
      
      try {
        document.execCommand('copy');
        setSuccessMessage('Booking link copied to clipboard! ⚡');
        setShowSuccess(true);
      } catch (err) {
        console.error('Fallback copy failed:', err);
        setError('Failed to copy link');
      } finally {
        document.body.removeChild(tempInput);
      }
      
      handleClose();
    } catch (error) {
      console.error('Error generating booking link:', error);
      setError('Failed to generate booking link');
    }
  };

  const handleDownloadClick = () => {
    setAnchorEl(null);
  };

  const handleEmailClient = () => {
    // Your email logic
    handleClose();
  };

  const handleConvertToProject = () => {
    if (!estimate?.client_signature) {
      setShowUnsignedWarning(true);
    } else {
      setShowItemSelectionModal(true);
    }
    handleClose();
  };

  const handleProjectTypeSubmit = async () => {
    try {
      setIsConverting(true);

      // Format the scope of work to include internal notes
      let combinedScope = estimate.scope_of_work || '';
      if (estimate.internal_notes) {
        try {
          const notes = JSON.parse(estimate.internal_notes);
          if (Array.isArray(notes)) {
            const formattedNotes = notes.map(note => 
              `- ${note.content}`
            ).join('\n');
            combinedScope = combinedScope ? 
              `${combinedScope}\n\nInternal Notes:\n${formattedNotes}` : 
              `Internal Notes:\n${formattedNotes}`;
          }
        } catch (e) {
          combinedScope = combinedScope ? 
            `${combinedScope}\n\nInternal Notes:\n${estimate.internal_notes}` : 
            `Internal Notes:\n${estimate.internal_notes}`;
        }
      }

      // Process grouped items
      let processedItems = [];
      if (Object.keys(selectedGroups).length > 0) {
        // Handle grouped items
        Object.entries(selectedGroups).forEach(([groupId, items]) => {
          // Calculate total value for the group
          const groupTotal = items.reduce((sum, item) => {
            const itemTotal = item.total || (Number(item.unitPrice || 0) * Number(item.quantity || 0));
            return sum + itemTotal;
          }, 0);

          // Combine item titles for the event title
          const combinedTitle = items.map(item => item.title).join(' + ');

          // Combine items in each group into a single item
          const combinedItem = {
            title: combinedTitle,
            description: items.map(item => `${item.title}: ${item.description || 'No description'}`).join('\n'),
            total: groupTotal,
            quantity: 1,
            unitPrice: groupTotal,
            isGroup: true,
            originalItems: items
          };
          processedItems.push(combinedItem);
        });
      }

      // Add ungrouped items
      const ungroupedItems = estimate.items.filter(item => 
        includedItems.includes(item.title) && 
        !Object.values(selectedGroups).flat().some(groupItem => 
          groupItem.title === item.title
        )
      );
      processedItems = [...processedItems, ...ungroupedItems];

      const conversionData = {
        project_type: projectType,
        items: processedItems, // Send modified items array instead of original estimate.items
        schedule_now: scheduleNow,
        scope_of_work: combinedScope,
        time_slots: (projectType === 'single_day' && scheduleNow) ? {
          start_time: conversionTimeSlot.start_time,
          end_time: conversionTimeSlot.end_time
        } : null
      };

      console.log('Converting estimate with data:', conversionData);
      const response = await convertEstimateToProject(estimate.id, conversionData);

      if (response.success) {
        setSuccessMessage('Estimate converted to project successfully!');
        setShowSuccess(true);
        navigate(`/admin/projects/${response.project_id}`);
      }
    } catch (error) {
      console.error('Error converting to project:', error);
      setError(error.message || 'Failed to convert estimate to project');
    } finally {
      setIsConverting(false);
      setShowProjectTypeModal(false);
    }
  };

  const handleItemSelection = (item) => {
    setIncludedItems(prev => {
      const isIncluded = prev.includes(item.title);
      if (isIncluded) {
        // Remove item from any group it's in
        const updatedGroups = { ...selectedGroups };
        Object.keys(updatedGroups).forEach(groupId => {
          updatedGroups[groupId] = updatedGroups[groupId].filter(i => i.title !== item.title);
        });
        setSelectedGroups(updatedGroups);
        return prev.filter(i => i !== item.title);
      }
      return [...prev, item.title];
    });
  };

  const handleGroupItems = (items) => {
    const groupId = `group-${groupCounter}`;
    setSelectedGroups(prev => ({
      ...prev,
      [groupId]: items
    }));
    setGroupCounter(prev => prev + 1);
  };

  const handleRemoveFromGroup = (groupId, itemTitle) => {
    setSelectedGroups(prev => ({
      ...prev,
      [groupId]: prev[groupId].filter(item => item.title !== itemTitle)
    }));
  };

  const handleRemoveGroup = (groupId) => {
    setSelectedGroups(prev => {
      const { [groupId]: removed, ...rest } = prev;
      return rest;
    });
  };

  const handleItemSelectionConfirm = () => {
    setShowItemSelectionModal(false);
    setShowProjectTypeModal(true);
  };

  const handleTimeSlotSubmit = async () => {
    try {
      console.log('Selected items:', includedItems);
      console.log('Time slots before submission:', timeSlots);

      // Format time slots for API
      const formattedTimeSlots = {};
      convertedEvents.forEach((event, index) => {
        // Only include if we have both start and end times
        if (timeSlots[index] && timeSlots[index].start_time && timeSlots[index].end_time) {
          formattedTimeSlots[event.title] = {
            start_time: new Date(timeSlots[index].start_time).toISOString(),
            end_time: new Date(timeSlots[index].end_time).toISOString()
          };
        }
      });

      console.log('Formatted time slots:', formattedTimeSlots);

      const response = await convertEstimateToProject(estimate.id, {
        selected_items: includedItems,
        time_slots: formattedTimeSlots
      });

      if (response.success) {
        setSuccessMessage('Project created successfully with scheduled events! ⚡');
        setShowSuccess(true);
        navigate(`/admin/projects/${response.project_id}`);
      }

      setShowTimeSlotModal(false);

    } catch (error) {
      console.error('Error converting to project:', error);
      setError(error.message || 'Failed to convert estimate to project');
    }
  };

  const handleTimeSlotChange = (field, value) => {
    // Store the raw datetime-local value without any conversion
    setConversionTimeSlot(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleClientNavigation = () => {
    if (estimate?.customer_details?.id) {
      navigate(`/admin/clients/${estimate.customer_details.id}`);
    }
    handleClose();
  };

  const handleNotesChange = async (event) => {
    const newNotes = event.target.value;
    setInternalNotes(newNotes);
    try {
      await api.patch(`/estimates/${id}`, { notes: newNotes });
    } catch (error) {
      console.error('Error saving notes:', error);
      setError('Failed to save notes');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/')
    );
    
    if (files.length > 0) {
      const event = { target: { files } };
      await handleFileUpload(event);
    }
  };

  const handleFileUpload = async (event) => {
    try {
      const files = Array.from(event.target.files || []);
      console.log('Files to upload:', files);
      
      if (files.length === 0) return;

      setIsLoadingPhotos(true);
      setPhotoError('');
      
      for (const file of files) {
        console.log('Processing file:', file.name);
        const formData = new FormData();
        formData.append('image', file);
        formData.append('estimateId', estimate.id);
        
        console.log('Uploading file with estimateId:', estimate.id);
        const response = await uploadEstimatePhoto(formData, estimate.id);
        console.log('Upload response:', response);
        
        if (response?.filename) {
          console.log('File uploaded successfully:', response.filename);
          await refreshEstimate();
        }
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setPhotoError('Failed to upload images');
    } finally {
      setIsLoadingPhotos(false);
    }
  };

  const refreshEstimate = async () => {
    try {
      const response = await getEstimate(id);
      setEstimate(response.data);
    } catch (error) {
      console.error('Error refreshing estimate:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      
      try {
        setLoading(true);
        const response = await getEstimate(id);
        const data = response.data;
        
        const formattedEstimate = {
          id: data.id,
          ...data,
          business_details: data.business_details || {},
          customer_details: data.customer_details || {},
          items: data.items || [],
          project_images: data.project_images || [],
          project_name: data.project_name || '',
          scope_of_work: data.scope_of_work || '',
          notes: data.notes || '',
          terms: data.terms || '',
          service_address: data.service_address || '',
          service_city: data.service_city || '',
          service_state: data.service_state || '',
          service_zip: data.service_zip || '',
          use_client_address: data.use_client_address ?? true,
          showAdditionalInfo: true,
          status: data.status || 'pending',
          created_at: data.created_at || new Date().toISOString(),
          expiration_date: data.expiration_date || '',
          estimate_number: data.estimate_number || '',
          total: data.total || 0,
          subtotal: data.subtotal || 0,
          tax: data.tax || 0,
          tax_rate: data.tax_rate || 0
        };
        
        setEstimate(formattedEstimate);
        setError(null);
      } catch (err) {
        console.error('FETCH ERROR:', err);
        setError(err.message || 'Failed to fetch estimate');
        setEstimate(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    console.log('Current State:', {
      loading,
      error,
      estimate: estimate ? 'Has Data' : 'No Data',
      id
    });
  }, [loading, error, estimate, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (estimate) {
      // Remove this line as we don't want to set the input value
      // setInternalNotes(estimate.internal_notes || '');
    }
  }, [estimate]);

  const handleSaveInternalNotes = async () => {
    if (!internalNotes.trim() || !id) return;
    
    try {
      let currentNotes = [];
      
      if (estimate.internal_notes) {
        try {
          currentNotes = JSON.parse(estimate.internal_notes);
          if (!Array.isArray(currentNotes)) {
            currentNotes = [{
              content: estimate.internal_notes,
              timestamp: new Date().toISOString(),
              user: 'Legacy'
            }];
          }
        } catch (e) {
          if (estimate.internal_notes.trim()) {
            currentNotes = [{
              content: estimate.internal_notes,
              timestamp: new Date().toISOString(),
              user: 'Legacy'
            }];
          }
        }
      }

      currentNotes.push({
        content: internalNotes,
        timestamp: new Date().toISOString(),
        user: 'Admin'
      });

      await api.patch(`/estimates/${id}/internal-notes`, {
        internal_notes: JSON.stringify(currentNotes)
      });
      
      setInternalNotes('');
      setSuccessMessage('Note added successfully');
      setShowSuccess(true);
      
      await refreshEstimate();
      
    } catch (error) {
      console.error('Error saving internal notes:', error);
      setError('Failed to save note');
    }
  };

  const handleEditNote = async (index, updatedContent) => {
    try {
      let currentNotes = JSON.parse(estimate.internal_notes);
      if (!Array.isArray(currentNotes)) {
        currentNotes = [currentNotes];
      }
      
      // Update the specific note's content while preserving timestamp and user
      currentNotes[currentNotes.length - 1 - index] = {
        ...currentNotes[currentNotes.length - 1 - index],
        content: updatedContent,
        edited: true,
        editedAt: new Date().toISOString()
      };

      const response = await api.patch(`/estimates/${id}/internal-notes`, {
        internal_notes: JSON.stringify(currentNotes)
      });
      
      setEstimate(prev => ({
        ...prev,
        internal_notes: JSON.stringify(response.data.internal_notes)
      }));
      
      setEditingNoteIndex(null);
      setEditingNoteContent('');
      setSuccessMessage('Note updated successfully');
      setShowSuccess(true);
    } catch (error) {
      console.error('Error updating note:', error);
      setError('Failed to update note');
    }
  };

  const handlePreviewView = () => {
    setShowPreviewModal(true);
    handleClose();
  };

  const handleCollectSignature = async () => {
    setShowSignatureModal(true);
    handleClose();
    
    try {
      console.log('Starting signature link generation...');
      const response = await api.post(`/estimates/${id}/generate-link`);
      console.log('API Response:', response.data);
      
      const publicUuid = response.data.public_uuid;
      if (!publicUuid) {
        throw new Error('No public UUID received');
      }

      const link = `${window.location.origin}/public/estimate/${publicUuid}`;
      setSignatureLink(link);
      
    } catch (error) {
      console.error('Signature link generation error:', error);
      setError('Failed to generate signature link');
      setShowSignatureModal(false);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(signatureLink);
      setSuccessMessage('Link copied to clipboard! ⚡');
      setShowSuccess(true);
      setShowSignatureModal(false);
    } catch (error) {
      console.error('Copy error:', error);
      setError('Failed to copy link');
    }
  };

  const handleOpenLink = () => {
    window.open(signatureLink, '_blank');
    setShowSignatureModal(false);
  };

  const handleEditEstimate = () => {
    handleClose();
    if (estimate?.id) {
      const editPath = `/admin/estimates/edit/${estimate.id}`;
      console.log('Navigating to:', editPath);
      navigate(editPath);
    } else {
      console.error('No estimate ID found');
      setError('Cannot edit estimate: ID not found');
    }
  };

  const renderPDFDownload = () => {
    // Force PDF regeneration by using a key
    const timestamp = new Date().getTime();
    
    return (
      <PDFDownloadLink
        key={timestamp}  // Add this to force regeneration
        document={
          <PDFTemplate 
            estimate={estimate} 
            key={timestamp}  // Add this too
          />
        }
        fileName={`estimate-${estimate?.estimate_number || 'draft'}.pdf`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        {({ blob, url, loading, error }) => {
          console.log('PDF Generation status:', { loading, error }); // Debug log
          return loading ? 'Generating PDF...' : 'Download PDF';
        }}
      </PDFDownloadLink>
    );
  };

  const handleUnsignedWarningResponse = (proceed) => {
    setShowUnsignedWarning(false);
    if (proceed) {
      setShowItemSelectionModal(true);
    }
  };

  const handleSaveNotes = async () => {
    try {
      const existingNotes = formatNotes(estimate?.internal_notes);
      const newNote = {
        content: editedNotes,
        timestamp: new Date().toISOString(),
        user: 'Admin' // Or however you get the current user
      };
      const updatedNotes = [...existingNotes, newNote];
      
      await api.patch(`/estimates/${estimate.id}`, {
        internal_notes: JSON.stringify(updatedNotes)
      });
      setIsEditingNotes(false);
      setEditedNotes('');
      // Refresh estimate data
      refreshEstimate();
    } catch (error) {
      console.error('Failed to save notes:', error);
      setError('Failed to save notes');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!estimate) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>No estimate found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      margin: '0 auto',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: '1px solid #eee',
        marginBottom: '20px'
      }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/admin/estimates')}
          sx={{ 
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Back to Estimates
        </Button>

        <Button
          variant="contained"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          disableRipple
          sx={{
            backgroundColor: '#4a5df9',
            '&:hover': {
              backgroundColor: '#3c50fa'
            },
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '1rem'
          }}
        >
          Actions ⚡
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            sx: {
              mt: 1,
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              borderRadius: '8px'
            }
          }}
        >
          <MenuItem 
            onClick={handleEditEstimate}
            sx={{ 
              color: 'primary.main',
              gap: 1,
              py: 1.5,
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.08)'
              }
            }}
          >
            <PencilIcon size={20} weight="regular" />
            Edit Estimate
          </MenuItem>

          <Divider />

          <MenuItem onClick={handlePreviewView} sx={menuItemStyle}>
            <ListItemIcon>
              <EyeIcon size={20} weight="regular" />
            </ListItemIcon>
            See Client View
          </MenuItem>
          <MenuItem 
            onClick={() => {
              console.log('Download clicked, estimate data:', estimate);
              handleClose();
            }}
            sx={menuItemStyle}
          >
            <ListItemIcon>
              <FileArrowDownIcon size={20} weight="regular" />
            </ListItemIcon>
            Download PDF
          </MenuItem>
          <MenuItem onClick={handleGenerateBookingLink} sx={menuItemStyle}>
            <ListItemIcon>
              <CalendarIcon size={20} weight="regular" />
            </ListItemIcon>
            Generate Booking Link
          </MenuItem>
          <MenuItem onClick={handleEmailClient} sx={menuItemStyle}>
            <ListItemIcon>
              <EnvelopeIcon size={20} weight="light" />
            </ListItemIcon>
            Email Client
          </MenuItem>
          <MenuItem 
            onClick={handleConvertToProject}
            sx={menuItemStyle}
          >
            <ListItemIcon>
              <BriefcaseIcon size={20} weight="regular" />
            </ListItemIcon>
            Convert to Project
          </MenuItem>
          <MenuItem onClick={handleClientNavigation} sx={menuItemStyle}>
            <ListItemIcon>
              <UserIcon size={20} weight="regular" />
            </ListItemIcon>
            View Client
          </MenuItem>
          <MenuItem onClick={handleCollectSignature} sx={menuItemStyle}>
            <ListItemIcon>
              <SignatureIcon size={20} weight="regular" />
            </ListItemIcon>
            Collect Signature
          </MenuItem>
        </Menu>
      </Box>

      <Grid container spacing={3} sx={{ p: 2 }}>
        {/* Left column - Estimate Items */}
        <Grid item xs={12} lg={9} md={8}>
          <Box sx={{ mb: 3 }}>

            {/* Headers */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ 
                  fontWeight: 600,
                  color: 'text.secondary',
                  fontFamily: "'Noto Sans', sans-serif",
                  textAlign: 'left'
                }}>
                  Title/Description
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'flex-end',
                  gap: 6
                }}>
                  <Typography sx={{ 
                    fontWeight: 600,
                    color: 'text.secondary',
                    fontFamily: "'Noto Sans', sans-serif",
                    textAlign: 'left',
                    marginLeft: '25%'
                  }}>
                    Quantity × Price
                  </Typography>
                  <Typography sx={{ 
                    fontWeight: 600,
                    color: 'text.secondary',
                    fontFamily: "'Noto Sans', sans-serif",
                    minWidth: '80px',
                    textAlign: 'right'
                  }}>
                    Total
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Items */}
            {estimate?.items?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  '&:not(:last-child)': {
                    borderBottom: '1px solid var(--divider)',
                    paddingBottom: '16px'
                  }
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ 
                      fontWeight: 500,
                      fontFamily: "'Noto Sans', sans-serif",
                      textAlign: 'left'
                    }}>
                      {item.title}
                    </Typography>
                    {item.description && (
                      <Typography variant="body2" sx={{ 
                        color: 'text.secondary',
                        mt: 0.5,
                        whiteSpace: 'pre-wrap',
                        paddingRight: '15px',
                        maxWidth: '600px',
                        lineHeight: '1.6',
                        fontFamily: "'Noto Sans', sans-serif"
                      }}>
                        {item.description.split('\n').map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            {i !== item.description.split('\n').length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: 6
                    }}>
                      <Typography variant="body2" sx={{ 
                        color: 'text.secondary',
                        fontFamily: "'Noto Sans', sans-serif",
                        textAlign: 'left',
                        marginLeft: '25%'
                      }}>
                        ${getPrice(item).toFixed(2)} × {getQuantity(item)}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ 
                        fontWeight: 600,
                        color: 'green',
                        fontFamily: "'Inter', sans-serif",
                        minWidth: '80px',
                        textAlign: 'right'
                      }}>
                        ${calculateItemTotal(item).toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}



            {/* Total Section */}
            <Box sx={{ 
              mt: 4,
              pt: 3,
              width: '100%'
            }}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 4,
                mb: 2
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 6
                }}>
                  <Typography color="text.secondary" sx={{ fontFamily: "'Noto Sans', sans-serif" }}>
                    Subtotal
                  </Typography>
                  <Typography sx={{ fontWeight: 500, fontFamily: "'Noto Sans', sans-serif", color: 'green' }}>
                    ${calculateSubtotal(estimate).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '2px solid var(--divider)',
                pt: 2
              }}>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 7.5
                }}>
                  <Typography sx={{ 
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    fontFamily: "'Noto Sans', sans-serif"
                  }}>
                    Total
                  </Typography>
                  <Typography sx={{ 
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    color: 'green',
                    fontFamily: "'Noto Sans', sans-serif"
                  }}>
                    ${calculateTotal(estimate).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* In the main/items column, after the items list */}

          <Box sx={{ mt: 4, pt: 4 }}>
            {/* Terms & Conditions Section */}
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontFamily: "'Noto Sans', sans-serif !important",
                  fontWeight: 400,
                  fontSize: '1rem',
                  color: 'var(--text-primary)',
                  mb: 2
                }}
              >
                Terms & Conditions
              </Typography>
              <Box
                sx={{
                  minHeight: '100px',
                }}
              >
                <Typography sx={{
                  color: '#64748B',
                  fontSize: '0.875rem',
                  fontStyle: estimate?.terms ? 'normal' : 'italic',
                  whiteSpace: 'pre-wrap'
                }}>
                  {estimate?.terms || 'No terms and conditions provided'}
                </Typography>
              </Box>
            </Box>

            {/* Client Signature Section */}
            <Box sx={{ mb: 4, ml: 'auto', maxWidth: '400px' }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontFamily: "'Noto Sans', sans-serif !important",
                  fontWeight: 400,
                  fontSize: '1rem',
                  color: 'var(--text-primary)',
                  mb: 2,
                  textAlign: 'right',
                  paddingRight: '150px'
                }}
              >
                Client Signature
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: 1.5
                }}
              >
                {estimate?.client_signature ? (
                  <>
                    <Box
                      component="img"
                      src={estimate.client_signature}
                      alt="Client Signature"
                      sx={{
                        maxWidth: '250px',
                        height: 'auto',
                        maxHeight: '80px',
                        objectFit: 'contain'
                      }}
                    />
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5
                    }}>
                      <Typography sx={{
                        color: '#1E293B',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        fontFamily: "'Inter', sans-serif",
                      }}>
                        {estimate?.customer_details?.name}
                      </Typography>
                      <Typography sx={{
                        color: '#64748B',
                        fontSize: '0.75rem',
                        fontFamily: "'Inter', sans-serif",
                      }}>
                        • Signed on {new Date(estimate.client_signed_at).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Typography sx={{
                    color: '#64748B',
                    fontSize: '0.875rem',
                    fontStyle: 'italic'
                  }}>
                    No signature provided
                  </Typography>
                )}
              </Box>
            </Box>

            {/* Project Photos Section */}
            <Box sx={{ mt: 4 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontFamily: "'Noto Sans', sans-serif !important",
                  fontWeight: 400,
                  fontSize: '1rem',
                  color: 'var(--text-primary)',
                  mb: 2
                }}
              >
                Project Photos
              </Typography>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                gap: 2,
                width: '100%'
              }}>
                {/* Upload Button */}
                <Box
                  sx={{
                    position: 'relative',
                    paddingTop: '75%',
                    borderRadius: '8px',
                    border: '2px dashed #E2E8F0',
                    backgroundColor: isDragging ? '#F8FAFC' : 'transparent',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#F8FAFC',
                      borderColor: '#CBD5E1'
                    }
                  }}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                    id="project-photo-input"
                  />
                  <label 
                    htmlFor="project-photo-input"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <UploadIcon 
                      size={32} 
                      weight="light" 
                      color="#64748B"
                    />
                    <Typography
                      sx={{
                        color: '#64748B',
                        fontSize: '0.875rem',
                        mt: 1
                      }}
                    >
                      {isLoadingPhotos ? 'Uploading...' : 'Upload Photos'}
                    </Typography>
                  </label>
                </Box>

                {/* Existing Photos */}
                {estimate?.project_images && estimate.project_images.length > 0 ? (
                  estimate.project_images.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: 'relative',
                        paddingTop: '75%',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #E2E8F0',
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_URL}/static/project_files/estimate_photos/${image}`}
                        alt={`Project photo ${index + 1}`}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  ))
                ) : null}
              </Box>
              {photoError && (
                <Typography color="error" sx={{ mt: 1, fontSize: '0.875rem' }}>
                  {photoError}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {/* Right column - Client Info */}
        <Grid item xs={12} lg={3} md={4} sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
          <Box sx={{
            width: '100%',
            p: 3,
            backgroundColor: '#fff',
            height: '100%',
            position: { xs: 'static', md: 'sticky' },
            top: { md: 80 },
            borderLeft: { xs: 'none', md: '1px solid var(--divider)' },
            borderTop: { xs: '1px solid var(--divider)', md: 'none' },
            mt: { xs: 2, md: 0 },
            maxWidth: '100%',
            overflowY: 'auto',
            maxHeight: { md: 'calc(100vh - 120px)' },
            mb: { xs: 4, md: 0 },
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            '&::-webkit-scrollbar': {
              width: '0px',
              background: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent'
            },
            '&:hover::-webkit-scrollbar-thumb': {
              background: 'transparent'
            }
          }}>
            {/* Header with Avatar and Status */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              mb: 3,
              gap: 2
            }}>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ 
                    color: '#2c3e50',
                    fontWeight: 600,
                    mb: 0.5,
                    fontFamily: 'Urbanist, sans-serif'
                  }}>
                    {estimate?.customer_details?.name || 'No Name'}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Box
                      sx={{
                        color: STATUS_STYLES[estimate?.status?.toLowerCase() || 'draft']?.color,
                        backgroundColor: STATUS_STYLES[estimate?.status?.toLowerCase() || 'draft']?.backgroundColor,
                        padding: '4px 8px',
                        borderRadius: '6px',
                        fontSize: '0.75rem',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        letterSpacing: '0.5px',
                        display: 'inline-block'
                      }}
                    >
                      {formatStatus(estimate?.status || 'Draft')}
                    </Box>
                  </Box>
                </Box>
                <Typography variant="body2" sx={{ 
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <ReceiptIcon sx={{ fontSize: '1rem' }} />
                  Estimate #{estimate?.estimate_number}
                </Typography>
                <Typography variant="body2" sx={{ 
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <BuildIcon sx={{ fontSize: '1rem' }} />
                  Project Name: {estimate?.project_name}
                </Typography>
                
              </Box>
            </Box>

            {/* Client Info */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 1.5,
                    borderRadius: '8px',
                  }}>
                    <BuildingsIcon size={20} weight="light" color="#3498db" />
                    <Box sx={{ minWidth: 0 }}>
                      <Typography variant="caption" color="text.secondary">
                        Company
                      </Typography>
                      <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {estimate?.customer_details?.company || 'No company provided'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 1.5,
                    borderRadius: '8px',
                  }}>
                    <PhoneIcon size={20} weight="light" color="#3498db" />
                    <Box sx={{ minWidth: 0 }}>
                      <Typography variant="caption" color="text.secondary">
                        Phone
                      </Typography>
                      <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {estimate?.customer_details?.phone || 'No phone provided'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 1.5,
                    borderRadius: '8px',
                  }}>
                    <EnvelopeIcon size={20} weight="light" color="#3498db" />
                    <Box sx={{ minWidth: 0 }}>
                      <Typography variant="caption" color="text.secondary">
                        Email
                      </Typography>
                      <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {estimate?.customer_details?.email || 'No email provided'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 1.5,
                    borderRadius: '8px',
                  }}>
                    <MapPinIcon size={20} weight="light" color="#3498db" />
                    <Box sx={{ minWidth: 0 }}>
                      <Typography variant="caption" color="text.secondary">
                        Address
                      </Typography>
                      <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {estimate?.customer_details?.address || 'No address provided'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Tags Section */}
            <TagsSection 
              entityType="estimates"
              entityId={estimate?.id}
            />

            {/* Estimate History Section */}
            <Box sx={{ mt: 4, minWidth: '100%' }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: 600,
                    fontSize: '1rem',
                    color: 'var(--text-primary)'
                  }}
                >
                  Estimate History
                </Typography>
              </Box>
              <Box sx={{
                p: 3,
                borderRadius: '8px',
                bgcolor: 'var(--background-paper)',
                border: '1px solid var(--divider)',
                minHeight: '100px',
                width: '100%',
                boxSizing: 'border-box',
                overflowWrap: 'break-word'
              }}>
                <Typography
                  sx={{
                    color: estimate?.history ? 'var(--text-primary)' : 'var(--text-secondary)',
                    fontStyle: estimate?.history ? 'normal' : 'italic',
                    wordBreak: 'break-word'
                  }}
                >
                  {estimate?.history || 'No history available'}
                </Typography>
              </Box>
            </Box>

            {/* Internal Notes Section */}
            <Box sx={{ mt: 4, minWidth: '100%' }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: 600,
                    fontSize: '1rem',
                    color: 'var(--text-primary)'
                  }}
                >
                  Internal Notes
                </Typography>
              </Box>
              <Box sx={{
                p: 2,
                borderRadius: '12px',
                bgcolor: '#F8FAFC',
                border: '1px solid #E2E8F0',
                minHeight: '100px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                width: '100%',
                boxSizing: 'border-box',
                overflowWrap: 'break-word'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 2,
                  wordBreak: 'break-word'
                }}>
                  {formatNotes(estimate?.internal_notes)?.map((note, index) => (
                    <Box key={index} sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 1,
                      p: 2,
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px',
                      border: '1px solid #E2E8F0',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-1px)',
                        backgroundColor: '#FAFAFA'
                      },
                      wordBreak: 'break-word'
                    }}>
                      <Typography sx={{ 
                        color: '#1E293B',
                        fontSize: '0.9rem',
                        lineHeight: 1.5,
                        fontFamily: "'Inter', sans-serif",
                        wordBreak: 'break-word'
                      }}>
                        {note.content}
                      </Typography>
                      <Box sx={{ 
                        display: 'flex', 
                        gap: 1.5,
                        alignItems: 'center',
                        mt: 1
                      }}>
                        <Typography sx={{ 
                          color: '#3B82F6',
                          fontSize: '0.75rem',
                          fontWeight: 600,
                          backgroundColor: '#EFF6FF',
                          padding: '2px 8px',
                          borderRadius: '12px',
                          fontFamily: "'Inter', sans-serif",
                        }}>
                          {note.user}
                        </Typography>
                        <Typography sx={{ 
                          color: '#64748B',
                          fontSize: '0.75rem',
                          fontFamily: "'Inter', sans-serif",
                        }}>
                          {new Date(note.timestamp).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  ))}

                  {isEditingNotes ? (
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      gap: 2,
                      p: 2,
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px',
                      border: '1px solid #3B82F6',
                      transition: 'all 0.2s ease-in-out'
                    }}>
                      <TextField
                        multiline
                        fullWidth
                        minRows={2}
                        value={editedNotes}
                        onChange={(e) => setEditedNotes(e.target.value)}
                        variant="outlined"
                        placeholder="Type your note here..."
                        autoFocus
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: '#FFFFFF',
                            borderRadius: '8px',
                            '& fieldset': {
                              border: 'none'
                            }
                          }
                        }}
                      />
                      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                        <IconButton
                          onClick={() => {
                            setIsEditingNotes(false);
                            setEditedNotes('');
                          }}
                          sx={{ 
                            color: '#EF4444',
                            backgroundColor: '#FEE2E2',
                            '&:hover': {
                              backgroundColor: '#FEE2E2',
                              opacity: 0.9
                            },
                            padding: '6px'
                          }}
                        >
                          <CloseIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                        <IconButton
                          onClick={handleSaveNotes}
                          sx={{ 
                            color: '#10B981',
                            backgroundColor: '#D1FAE5',
                            '&:hover': {
                              backgroundColor: '#D1FAE5',
                              opacity: 0.9
                            },
                            padding: '6px'
                          }}
                        >
                          <CheckIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <Box 
                      onClick={() => setIsEditingNotes(true)}
                      sx={{ 
                        p: 2,
                        backgroundColor: '#FFFFFF',
                        borderRadius: '8px',
                        border: '1px solid #E2E8F0',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: '#FAFAFA',
                          borderColor: '#CBD5E1'
                        }
                      }}
                    >
                      <Typography sx={{
                        color: '#64748B',
                        fontSize: '0.875rem',
                      }}>
                        Click to add a note...
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      <Dialog 
        open={showItemSelectionModal} 
        onClose={() => setShowItemSelectionModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Select Items to Include
          <IconButton
            aria-label="close"
            onClick={() => setShowItemSelectionModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {estimate?.items?.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={includedItems.includes(item.title)}
                    onChange={() => handleItemSelection(item)}
                  />
                }
                label={
                  <Box>
                    <Typography variant="subtitle1">{item.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </Box>
                }
              />
            ))}

            {/* Groups Display */}
            {Object.entries(selectedGroups).map(([groupId, items]) => (
              <Box key={groupId} sx={{ mt: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1">Group {groupId.split('-')[1]}</Typography>
                  <IconButton onClick={() => handleRemoveGroup(groupId)} size="small">
                    <CancelIcon />
                  </IconButton>
                </Box>
                {items.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.title}
                    onDelete={() => handleRemoveFromGroup(groupId, item.title)}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              const selectedItems = estimate.items.filter(item => 
                includedItems.includes(item.title)
              );
              handleGroupItems(selectedItems);
            }}
            disabled={includedItems.length === 0}
          >
            Group Selected
          </Button>
          <Button onClick={() => setShowItemSelectionModal(false)}>Cancel</Button>
          <Button 
            onClick={handleItemSelectionConfirm}
            variant="contained"
            disabled={includedItems.length === 0}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showTimeSlotModal}
        onClose={() => setShowTimeSlotModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Schedule Selected Items</DialogTitle>
        <DialogContent>
          {convertedEvents.map((event, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {event.title}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Start Time"
                    type="datetime-local"
                    fullWidth
                    value={conversionTimeSlot.start_time}
                    onChange={(e) => handleTimeSlotChange('start_time', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="End Time"
                    type="datetime-local"
                    fullWidth
                    value={conversionTimeSlot.end_time}
                    onChange={(e) => handleTimeSlotChange('end_time', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTimeSlotModal(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleTimeSlotSubmit}
            disabled={!timeSlots.every(slot => slot?.start_time && slot?.end_time)}
          >
            Schedule and Create Project
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showPreviewModal} 
        onClose={() => setShowPreviewModal(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            height: '90vh',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #edf2f7',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          px: 3,
          backgroundColor: '#f8fafc'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <VisibilityIcon sx={{ color: '#4a5568' }} />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Client Preview
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              startIcon={<DownloadIcon />}
              onClick={renderPDFDownload}
              sx={{
                backgroundColor: '#3182ce',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#2c5282'
                }
              }}
            >
              Download PDF
            </Button>
            <IconButton
              onClick={() => setShowPreviewModal(false)}
              size="small"
              sx={{ 
                color: '#718096',
                '&:hover': { 
                  color: '#2d3748',
                  backgroundColor: '#f7fafc'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ 
          p: 0, 
          flex: 1,
          overflow: 'auto',
          backgroundColor: '#f7fafc'
        }}>
          <Box sx={{ 
            maxWidth: '1000px', 
            margin: '0 auto',
            p: 4,
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
            my: 4
          }}>
            {estimate && Object.keys(estimate).length > 0 && (
              <DefaultTemplate 
                estimate={estimate}
                className="default-template"
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog 
        open={showSignatureModal} 
        onClose={() => setShowSignatureModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          Collect Signature
          <IconButton
            onClick={() => setShowSignatureModal(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Share this estimate with your client to collect their signature. Choose how you'd like to share:
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            justifyContent: 'center'
          }}>
            <Button
              variant="contained"
              onClick={handleCopyLink}
              startIcon={<ContentCopyIcon />}
              sx={{
                flex: 1,
                py: 2,
                backgroundColor: '#3182ce',
                '&:hover': {
                  backgroundColor: '#2c5282'
                }
              }}
            >
              Copy Link
            </Button>
            <Button
              variant="contained"
              onClick={handleOpenLink}
              startIcon={<OpenInNewIcon />}
              sx={{
                flex: 1,
                py: 2,
                backgroundColor: '#38a169',
                '&:hover': {
                  backgroundColor: '#2f855a'
                }
              }}
            >
              Open Link
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog 
        open={showProjectTypeModal} 
        onClose={() => setShowProjectTypeModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Convert to Project</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Project Type
            </Typography>
            <RadioGroup
              value={projectType}
              onChange={(e) => setProjectType(e.target.value)}
            >
              <FormControlLabel 
                value="single_day" 
                control={<Radio />} 
                label="Single Day Project" 
              />
              <FormControlLabel 
                value="multi_day" 
                control={<Radio />} 
                label="Multi-Day Project" 
              />
            </RadioGroup>

            {projectType === 'single_day' && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Scheduling
                </Typography>
                <RadioGroup
                  value={scheduleNow}
                  onChange={(e) => setScheduleNow(e.target.value === 'true')}
                >
                  <FormControlLabel 
                    value={true}
                    control={<Radio />} 
                    label="Schedule Now" 
                  />
                  <FormControlLabel 
                    value={false}
                    control={<Radio />} 
                    label="Schedule Later" 
                  />
                </RadioGroup>

                {scheduleNow && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Project Time
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Start Time"
                          type="datetime-local"
                          fullWidth
                          value={conversionTimeSlot.start_time}
                          onChange={(e) => handleTimeSlotChange('start_time', e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="End Time"
                          type="datetime-local"
                          fullWidth
                          value={conversionTimeSlot.end_time}
                          onChange={(e) => handleTimeSlotChange('end_time', e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowProjectTypeModal(false)}>Cancel</Button>
          <Button 
            onClick={handleProjectTypeSubmit}
            variant="contained"
            disabled={
              isConverting || 
              (projectType === 'single_day' && scheduleNow && 
               (!conversionTimeSlot.start_time || !conversionTimeSlot.end_time))
            }
          >
            {isConverting ? 'Converting...' : 'Convert to Project'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showUnsignedWarning}
        onClose={() => setShowUnsignedWarning(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          color: '#ef4444',
          fontFamily: 'Urbanist, sans-serif'
        }}>
          <WarningAmberIcon sx={{ color: '#ef4444' }} />
          Unsigned Estimate
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ 
            mb: 2,
            color: '#ef4444',
            fontFamily: 'Urbanist, sans-serif'
          }}>
            This estimate has not been signed by the client. Converting an unsigned estimate to a project may cause issues with:
          </Typography>
          <Box sx={{ pl: 2, mb: 2 }}>
            <Typography variant="body1" component="div" sx={{ 
              color: '#ef4444',
              fontFamily: 'Urbanist, sans-serif'
            }}>
              • Client approval documentation
            </Typography>
            <Typography variant="body1" component="div" sx={{ 
              color: '#ef4444',
              fontFamily: 'Urbanist, sans-serif'
            }}>
              • Payment collection
            </Typography>
            <Typography variant="body1" component="div" sx={{ 
              color: '#ef4444',
              fontFamily: 'Urbanist, sans-serif'
            }}>
              • Legal protection
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ 
            color: '#ef4444',
            fontFamily: 'Urbanist, sans-serif'
          }}>
            Are you sure you want to proceed with the conversion?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, gap: 1 }}>
          <Button 
            onClick={() => handleUnsignedWarningResponse(false)}
            variant="outlined"
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              color: '#ef4444',
              borderColor: '#ef4444',
              '&:hover': {
                borderColor: '#dc2626',
                backgroundColor: 'rgba(239, 68, 68, 0.04)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleUnsignedWarningResponse(true)}
            variant="contained"
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              backgroundColor: '#ef4444',
              '&:hover': {
                backgroundColor: '#dc2626',
              }
            }}
          >
            Convert Anyway
          </Button>
        </DialogActions>
      </Dialog>

      {/* Always render the template but hide it when not needed */}
      <Box sx={{ position: 'absolute', left: '-9999px', top: 0 }}>
        {estimate && Object.keys(estimate).length > 0 && (
          <DefaultTemplate 
            estimate={estimate}
            className="default-template"
          />
        )}
      </Box>
    </Box>
  );
};

export default AdminEstimateDetails;

import React from 'react';
import { Box, Typography, Grid, FormControlLabel, Checkbox } from '@mui/material';
import BaseSelect from './shared/BaseSelect';

const RecurrenceSettings = ({ recurrence, setRecurrence }) => {
  const frequencyOptions = [
    'Daily',
    'Weekly',
    'Bi-weekly',
    'Monthly'
  ];

  const durationOptions = [
    '1 month',
    '3 months',
    '6 months',
    '1 year'
  ];

  const handleRecurringToggle = (e) => {
    const isChecked = e.target.checked;
    setRecurrence(prev => ({
      ...prev,
      isRecurring: isChecked,
      // Only update frequency if it's being enabled
      frequency: isChecked ? (prev.frequency === 'One-time' ? 'Daily' : prev.frequency) : 'One-time'
    }));
  };

  const handleFrequencyChange = (e) => {
    setRecurrence(prev => ({
      ...prev,
      frequency: e.target.value
    }));
  };

  const handleDurationChange = (e) => {
    setRecurrence(prev => ({
      ...prev,
      duration: e.target.value
    }));
  };

  return (
    <Box sx={{ mb: 3 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={recurrence.isRecurring}
            onChange={handleRecurringToggle}
            sx={{
              color: 'rgb(100, 100, 100)',
              '&.Mui-checked': {
                color: 'rgb(150, 150, 200)',
              },
            }}
          />
        }
        label="Recurring Event"
        sx={{
          mb: 2,
          '& .MuiFormControlLabel-label': {
            fontFamily: 'Urbanist',
            color: 'rgb(100, 100, 100)',
          }
        }}
      />

      {recurrence.isRecurring && (
        <Box sx={{ p: 2, borderRadius: '8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BaseSelect
                label="Frequency"
                value={recurrence.frequency}
                onChange={handleFrequencyChange}
                options={frequencyOptions}
                name="frequency"
                style={{ height: '48px' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseSelect
                label="Duration"
                value={recurrence.duration}
                onChange={handleDurationChange}
                options={durationOptions}
                name="duration"
                style={{ height: '48px' }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default RecurrenceSettings; 
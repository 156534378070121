import React from 'react';
import {
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Button,
  Box,
  Typography
} from '@mui/material';
import { Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { commonStyles, serviceTypes, calculationTypes, calculationTypeFields, calculatePrice } from './pricingConstants';

const PricingRuleEditor = ({ rule, onSave, onChange, onCancel }) => {
  const currentRule = rule || {
    id: 'new',
    name: 'New Pricing Rule',
    description: '',
    serviceType: 'cleaning',
    calculationType: 'fixed',
    baseRate: 0,
    fields: calculationTypeFields.fixed,
    fieldValues: {},
    result: 0,
    isNew: true
  };

  const handleFieldChange = (field, value) => {
    const updatedRule = {
      ...currentRule,
      [field]: value,
    };

    if (field === 'calculationType') {
      updatedRule.fields = calculationTypeFields[value] || [];
      updatedRule.fieldValues = {};
      updatedRule.result = 0;
      
      if (value === 'area') {
        updatedRule.areaCalculationType = 'dimensions';
      } else {
        updatedRule.areaCalculationType = null;
      }
    }

    updatedRule.result = calculatePrice(updatedRule, updatedRule.fieldValues);
    
    onChange(updatedRule);
  };

  const handleFieldValueChange = (fieldName, value) => {
    const newFieldValues = {
      ...(currentRule.fieldValues || {}),
      [fieldName]: parseFloat(value) || 0
    };
    
    const updatedRule = {
      ...currentRule,
      fieldValues: newFieldValues,
      result: calculatePrice(currentRule, newFieldValues)
    };
    
    onChange(updatedRule);
  };

  const formatPrice = (price) => {
    return typeof price === 'number' ? price.toFixed(2) : '0.00';
  };

  return (
    <Card sx={{ ...commonStyles.card, mt: 2 }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Rule Name"
              value={currentRule.name || ''}
              onChange={(e) => handleFieldChange('name', e.target.value)}
              sx={commonStyles.textField}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              value={currentRule.description || ''}
              onChange={(e) => handleFieldChange('description', e.target.value)}
              multiline
              rows={2}
              sx={commonStyles.textField}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: 'var(--text-secondary)' }}>Service Type</InputLabel>
              <Select
                value={currentRule.serviceType || 'cleaning'}
                label="Service Type"
                onChange={(e) => handleFieldChange('serviceType', e.target.value)}
                sx={commonStyles.select}
              >
                {serviceTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: 'var(--text-secondary)' }}>Calculation Type</InputLabel>
              <Select
                value={currentRule.calculationType || 'fixed'}
                label="Calculation Type"
                onChange={(e) => handleFieldChange('calculationType', e.target.value)}
                sx={commonStyles.select}
              >
                {calculationTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {currentRule.calculationType === 'area' && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ color: 'var(--text-primary)', mb: 2 }}>
                Square Footage Rate
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Rate per Square Foot"
                    type="number"
                    value={currentRule.baseRate || ''}
                    onChange={(e) => handleFieldChange('baseRate', parseFloat(e.target.value) || 0)}
                    sx={commonStyles.textField}
                    InputProps={{
                      startAdornment: <Typography sx={{ mr: 1, color: 'var(--text-primary)' }}>$</Typography>
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ p: 2, bgcolor: 'rgba(0,0,0,0.05)', borderRadius: 1 }}>
                    <Typography variant="body1" sx={{ color: 'var(--text-primary)' }}>
                      This rate will be applied per square foot when the rule is used.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}

          {currentRule.calculationType !== 'area' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`Base Rate ${
                  currentRule.calculationType === 'hourly' ? '(per hour)' : 
                  currentRule.calculationType === 'unit' ? '(per unit)' : ''
                }`}
                type="number"
                value={currentRule.baseRate || ''}
                onChange={(e) => handleFieldChange('baseRate', parseFloat(e.target.value) || 0)}
                sx={commonStyles.textField}
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1, color: 'var(--text-primary)' }}>$</Typography>
                }}
              />
            </Grid>
          )}

          {currentRule.calculationType !== 'area' && currentRule.calculationType !== 'fixed' && (
            <Grid item xs={12}>
              {(currentRule.fields || []).map((field, index) => (
                <TextField
                  key={index}
                  fullWidth
                  label={field.label}
                  type={field.type}
                  value={currentRule.fieldValues?.[field.name] || ''}
                  onChange={(e) => handleFieldValueChange(field.name, e.target.value)}
                  sx={{ ...commonStyles.textField, mt: 2 }}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: 'rgba(0,0,0,0.05)', borderRadius: 1 }}>
              <Typography variant="h6" sx={{ color: 'var(--text-primary)' }}>
                {currentRule.calculationType === 'area' 
                  ? `Rate: $${formatPrice(currentRule.baseRate)}/sq ft`
                  : `Calculated Price: $${formatPrice(currentRule.result)}`
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<CancelIcon />}
            onClick={onCancel}
            sx={{
              backgroundColor: 'var(--error-main)',
              color: 'var(--text-primary)',
              '&:hover': {
                backgroundColor: 'var(--error-dark)',
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={() => onSave(currentRule)}
            sx={{
              backgroundColor: 'var(--primary-main)',
              color: 'var(--text-primary)',
              '&:hover': {
                backgroundColor: 'var(--primary-dark)',
              }
            }}
          >
            Save Rule
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PricingRuleEditor; 
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { 
  Buildings as BusinessIcon,
  User as OwnerIcon,
  MapPin as AddressIcon,
  Phone as PhoneIcon,
  Envelope as EmailIcon,
  FloppyDisk as SaveIcon
} from "@phosphor-icons/react";
import { useTheme } from '../ThemeContext';
import { getBusinessDetails, updateBusinessDetails } from '../services/api';

function AdminSettingsBusinessDetails() {
  const [businessProfile, setBusinessProfile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { darkMode } = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedProfile, setEditedProfile] = useState(null);

  useEffect(() => {
    fetchBusinessProfile();
  }, []);

  const fetchBusinessProfile = async () => {
    try {
      setLoading(true);
      const data = await getBusinessDetails();
      console.log('Fetched business profile:', data);
      if (data === null) {
        console.log('No business profile found, opening dialog');
        setOpenDialog(true);
      } else {
        setBusinessProfile(data);
      }
    } catch (error) {
      console.error('Error fetching business profile:', error);
      setError('Failed to fetch business profile');
    } finally {
      setLoading(false);
    }
  };

  const handleBusinessProfileChange = (event) => {
    const { name, value } = event.target;
    setBusinessProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const updatedProfile = await updateBusinessDetails(businessProfile);
      setBusinessProfile(updatedProfile);
      alert('Business profile updated successfully');
    } catch (error) {
      console.error('Error updating business profile:', error);
      alert('Error updating business profile');
    }
  };

  const handleEditClick = () => {
    setEditedProfile({ ...businessProfile });
    setOpenEditDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      const updatedProfile = await updateBusinessDetails(editedProfile);
      setBusinessProfile(updatedProfile);
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error updating business profile:', error);
    }
  };

  const handleCreateProfile = () => {
    const emptyProfile = {
      company_name: '',
      owner_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      phone: '',
      email: '',
    };
    setEditedProfile(emptyProfile);
    setOpenEditDialog(true);
  };

  const getFieldLabel = (key) => {
    const labels = {
      company_name: 'Company Name',
      owner_name: 'Owner Name',
      address: 'Address',
      city: 'City',
      state: 'State',
      zip_code: 'ZIP Code',
      phone: 'Phone',
      email: 'Email'
    };
    return labels[key] || key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography sx={{ fontFamily: 'Urbanist', color: 'var(--text-secondary)' }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography sx={{ fontFamily: 'Urbanist', color: 'var(--error)' }}>
          {error}
        </Typography>
      </Box>
    );
  }

  const getFieldIcon = (key) => {
    const icons = {
      company_name: <BusinessIcon size={22} weight="light" />,
      owner_name: <OwnerIcon size={22} weight="light" />,
      address: <AddressIcon size={22} weight="light" />,
      city: <AddressIcon size={22} weight="light" />,
      state: <AddressIcon size={22} weight="light" />,
      zip_code: <AddressIcon size={22} weight="light" />,
      phone: <PhoneIcon size={22} weight="light" />,
      email: <EmailIcon size={22} weight="light" />
    };
    return icons[key];
  };

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: 'var(--background-default)',
      p: 3,
      gap: 3
    }}>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--background-paper)',
            borderRadius: '16px',
            maxWidth: '500px',
            width: '100%'
          }
        }}
      >
        <DialogTitle sx={{ 
          fontFamily: 'Urbanist',
          fontWeight: 600,
          color: 'var(--text-primary)'
        }}>
          Create Business Profile
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ 
            fontFamily: 'Urbanist',
            color: 'var(--text-secondary)',
            mb: 2
          }}>
            No business profile found. Would you like to create one?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, borderTop: '1px solid var(--divider)' }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              color: 'var(--text-secondary)'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateProfile}
            variant="contained"
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              backgroundColor: '#4a5df9',
              '&:hover': {
                backgroundColor: '#3544b8'
              }
            }}
          >
            Create Profile
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog 
        open={openEditDialog} 
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            backgroundColor: 'var(--background-paper)',
          }
        }}
      >
        <DialogTitle sx={{ 
          p: 3,
          fontFamily: 'Urbanist',
          fontWeight: 600,
          borderBottom: '1px solid var(--divider)'
        }}>
          Edit Business Details
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 3,
            mt: 2
          }}>
            {Object.entries(editedProfile || {}).map(([key, value]) => {
              if (key !== 'logo') {
                return (
                  <Box key={key}>
                    <Typography sx={{
                      fontFamily: 'Urbanist',
                      fontSize: '0.875rem',
                      color: 'var(--text-secondary)',
                      mb: 1,
                      ml: 1
                    }}>
                      {getFieldLabel(key)}
                    </Typography>
                    <TextField
                      fullWidth
                      name={key}
                      value={value || ''}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setEditedProfile(prev => ({
                          ...prev,
                          [name]: value
                        }));
                      }}
                      variant="outlined"
                      placeholder={`Enter ${getFieldLabel(key).toLowerCase()}`}
                      InputProps={{
                        startAdornment: (
                          <Box sx={{ 
                            color: 'var(--text-secondary)',
                            mr: 1,
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            {getFieldIcon(key)}
                          </Box>
                        )
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '44px',
                          backgroundColor: 'var(--background-default)',
                          border: '1px solid var(--divider)',
                          borderRadius: '8px',
                          transition: 'all 0.2s ease-in-out',
                          '& fieldset': { border: 'none' },
                          '&:hover': {
                            borderColor: '#4a5df9',
                            backgroundColor: 'rgba(74, 93, 249, 0.04)'
                          },
                          '&.Mui-focused': {
                            borderColor: '#4a5df9',
                            backgroundColor: 'var(--background-default)',
                            boxShadow: '0 0 0 3px rgba(74, 93, 249, 0.1)'
                          }
                        },
                        '& input': {
                          fontFamily: 'Urbanist',
                          fontSize: '0.95rem',
                          color: 'var(--text-primary)',
                          padding: '12px 14px',
                          '&::placeholder': {
                            color: 'var(--text-secondary)',
                            opacity: 0.5
                          }
                        }
                      }}
                    />
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        </DialogContent>
        <DialogActions sx={{ 
          p: 3, 
          borderTop: '1px solid var(--divider)',
          gap: 2 
        }}>
          <Button
            variant="outlined"
            onClick={() => setOpenEditDialog(false)}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              color: 'var(--text-secondary)',
              borderColor: 'var(--divider)',
              '&:hover': {
                borderColor: 'var(--text-secondary)',
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveEdit}
            startIcon={<SaveIcon weight="light" />}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              backgroundColor: '#4a5df9',
              '&:hover': {
                backgroundColor: '#3544b8'
              }
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {businessProfile ? (
        <>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'var(--background-paper)',
            p: 3,
            borderRadius: '12px',
            border: '1px solid var(--divider)'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{
                width: 48,
                height: 48,
                borderRadius: '12px',
                backgroundColor: 'rgba(74, 93, 249, 0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <BusinessIcon size={28} weight="light" color="#4a5df9" />
              </Box>
              <Box>
                <Typography variant="h6" sx={{ 
                  fontFamily: 'Urbanist',
                  fontWeight: 600,
                  color: 'var(--text-primary)',
                  mb: 0.5
                }}>
                  Business Details
                </Typography>
                <Typography sx={{ 
                  fontFamily: 'Urbanist',
                  color: 'var(--text-secondary)',
                  fontSize: '0.875rem'
                }}>
                  Manage your business information
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            gap: 3,
            flex: 1,
            minHeight: 0
          }}>
            <Box sx={{
              flex: 1,
              backgroundColor: 'var(--background-paper)',
              borderRadius: '12px',
              border: '1px solid var(--divider)',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 3,
                borderBottom: '1px solid var(--divider)'
              }}>
                <Typography sx={{ 
                  fontFamily: 'Urbanist',
                  fontWeight: 600,
                  color: 'var(--text-primary)',
                }}>
                  Business Information
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleEditClick}
                  sx={{
                    textTransform: 'none',
                    fontFamily: 'Urbanist',
                    color: '#4a5df9',
                    borderColor: '#4a5df9',
                    '&:hover': {
                      borderColor: '#3544b8',
                      backgroundColor: 'rgba(74, 93, 249, 0.04)'
                    }
                  }}
                >
                  Edit Details
                </Button>
              </Box>

              <Box sx={{
                p: 3,
                overflow: 'auto',
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 3
              }}>
                {Object.entries(businessProfile).map(([key, value]) => {
                  if (key !== 'logo') {
                    return (
                      <Box key={key}>
                        <Typography sx={{
                          fontFamily: 'Urbanist',
                          fontSize: '0.875rem',
                          color: 'var(--text-secondary)',
                          mb: 1
                        }}>
                          {getFieldLabel(key)}
                        </Typography>
                        <Typography sx={{
                          fontFamily: 'Urbanist',
                          color: 'var(--text-primary)',
                          fontSize: '1rem'
                        }}>
                          {value || '-'}
                        </Typography>
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ 
          textAlign: 'center', 
          p: 6,
          backgroundColor: 'var(--background-paper)',
          borderRadius: '16px',
          maxWidth: '400px',
          margin: '40px auto'
        }}>
          <Typography sx={{ 
            fontFamily: 'Urbanist',
            color: 'var(--text-primary)',
            fontSize: '1.25rem',
            fontWeight: 600,
            mb: 2
          }}>
            Please create a business profile to get started.
          </Typography>
          <Button
            variant="contained"
            onClick={handleCreateProfile}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist',
              backgroundColor: '#4a5df9',
              borderRadius: '8px',
              px: 4,
              py: 1.5,
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: '#3544b8'
              }
            }}
          >
            Create Business Profile
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default AdminSettingsBusinessDetails;

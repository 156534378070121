import React from 'react';
import { Box, Typography, Paper, Grid, Divider } from '@mui/material';
import hardcodedLogo from './Updated logo (12).png';

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  // Strip all non-numeric characters
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  // Check if it's a valid 10-digit number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString; // Return original if can't format
};

const DefaultInvoiceTemplate = ({ businessDetails, customerDetails, invoice }) => {
  console.log('Template received props:', { businessDetails, customerDetails, invoice });
  const styles = {
    container: {
      width: '210mm',  // A4 width
      minHeight: '297mm', // A4 height
      margin: '0 auto',
      padding: '20mm', // Standard margin
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      '@media print': {
        width: '210mm',
        height: '297mm',
        margin: 0,
        padding: '20mm',
      }
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '40px',
    },
    invoiceInfo: {
      color: '#666',
      marginBottom: '40px',
    },
    addressSection: {
      marginBottom: '40px',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
      backgroundColor: '#fff',
    },
    tableHeader: {
      backgroundColor: '#f8f9ff',
      padding: '12px',
      textAlign: 'left',
      fontSize: '1.1rem',
      fontWeight: 600,
      color: '#2c3e50',
      textTransform: 'uppercase',
      borderBottom: '2px solid #eee'
    },
    tableCell: {
      padding: '12px',
      borderBottom: '1px solid #eee',
    },
    totalsSection: {
      marginTop: '20px',
      textAlign: 'right',
    },
    footer: {
      marginTop: '40px',
      padding: '20px',
      backgroundColor: '#fff9f6',
      borderRadius: '4px',
    }
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date instanceof Date && !isNaN(date) 
      ? date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      : 'N/A';
  };

  return (
    <Paper 
      sx={{
        ...styles.container,
        '& .MuiTypography-h4': {
          fontSize: '2rem',
          fontWeight: 600,
          color: '#2c3e50',
          marginBottom: '8px'
        },
        '& .MuiTypography-h6': {
          fontSize: '1.25rem',
          fontWeight: 500,
          color: '#2c3e50',
          marginBottom: '4px'
        }
      }}
    >
      {/* Header Section */}
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginBottom: "20px",
        '& > *': {
          minHeight: 'auto',
          lineHeight: 1.2
        }
      }}>
        {/* Logo container */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'flex-start',
          minHeight: '60px'
        }}>
          {invoice.logo ? (
            <img 
              src={invoice.logo}
              alt="Company Logo" 
              style={{ 
                maxWidth: '150px',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          ) : (
            <img 
              src={hardcodedLogo}
              alt="Default Logo" 
              style={{ 
                maxWidth: '150px',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          )}
        </Box>

        {/* Invoice Header Row */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
          {/* Left side - Invoice Title and Number */}
          <Box>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 500,
                fontSize: '2rem !important',
                lineHeight: 1.2
              }}
            >
              Invoice
            </Typography>
            <Typography 
              color="textSecondary"
              sx={{ fontSize: '1rem' }}
            >
              ID #{invoice.invoiceNumber}
            </Typography>
          </Box>

          {/* Right side - Dates */}
          <Box sx={{ 
            textAlign: 'right', 
            paddingTop: '14px',
            '& .MuiTypography-root': {
              fontSize: '1rem',
              lineHeight: 1.5
            }
          }}>
            <Typography color="textSecondary">Issue Date: {formatDate(invoice.issueDate)}</Typography>
            <Typography color="textSecondary">Due Date: {formatDate(invoice.dueDate)}</Typography>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Addresses Grid */}
      <Grid container spacing={4} sx={styles.addressSection}>
        {/* Bill From */}
        <Grid item xs={6}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="subtitle2" color="textSecondary">Bill from</Typography>
            <Typography variant="h6">{businessDetails?.company_name}</Typography>
            <Typography>{businessDetails?.address}</Typography>
            <Typography>
              {businessDetails?.city}, {businessDetails?.state} {businessDetails?.zip_code || businessDetails?.zipCode || businessDetails?.zip}
            </Typography>
            <Typography>{businessDetails?.email}</Typography>
            <Typography>{formatPhoneNumber(businessDetails?.phone)}</Typography>
          </Box>
        </Grid>

        {/* Bill To with Service Address */}
        <Grid item xs={6}>
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" color="textSecondary">Bill to</Typography>
            <Typography variant="h6">{customerDetails?.name}</Typography>
            <Typography>{customerDetails?.company}</Typography>
            <Typography>{customerDetails?.address}</Typography>
            <Typography>
              {customerDetails?.city}, {customerDetails?.state} {customerDetails?.zip_code || customerDetails?.zipCode || customerDetails?.zip}
            </Typography>
            <Typography>{customerDetails?.email}</Typography>
            <Typography>{formatPhoneNumber(customerDetails?.phone)}</Typography>
            
            {/* Service Address under Bill To - Falls back to billing address if no service address */}
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="subtitle2" color="textSecondary">Service Address</Typography>
              <Typography>
                {invoice.service_address?.address || customerDetails?.address}
              </Typography>
              <Typography>
                {[
                  invoice.service_address?.city || customerDetails?.city,
                  invoice.service_address?.state || customerDetails?.state,
                  invoice.service_address?.zip || invoice.service_address?.zip_code || invoice.service_address?.zipCode || 
                  customerDetails?.zip_code || customerDetails?.zipCode || customerDetails?.zip
                ].filter(Boolean).join(', ')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Items Table with top margin */}
      <table style={{
        ...styles.table, 
        paddingBottom: "40px",
        marginTop: "40px"
      }}>
        <thead>
          <tr>
            <th style={styles.tableHeader}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                Title/Description
              </Typography>
            </th>
            <th style={{...styles.tableHeader, textAlign: 'center'}}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                Quantity
              </Typography>
            </th>
            <th style={{...styles.tableHeader, textAlign: 'center'}}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                Unit Price
              </Typography>
            </th>
            <th style={{...styles.tableHeader, textAlign: 'right'}}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                Amount
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {invoice.items.map((item, index) => (
            <tr key={index}>
              <td style={styles.tableCell}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    fontWeight: 600,
                    fontSize: '1rem',
                    mb: 0.5,
                    color: '#2c3e50'  // Darker color for better visibility
                  }}
                >
                  {item.title}
                </Typography>
                <Typography 
                  variant="body1"  // Changed from body2
                  sx={{ 
                    whiteSpace: 'pre-line',
                    color: '#34495e',  // Slightly lighter than title but still visible
                    fontSize: '0.9rem',
                    ...item.descriptionStyle 
                  }}
                >
                  {item.description}
                </Typography>
              </td>
              <td style={{...styles.tableCell, textAlign: 'center', verticalAlign: 'top'}}>
                {item.quantity || '1'}
              </td>
              <td style={{...styles.tableCell, textAlign: 'center', verticalAlign: 'top'}}>
                ${item.total?.toFixed(2)}
              </td>
              <td style={{...styles.tableCell, textAlign: 'right', verticalAlign: 'top'}}>
                ${item.total?.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Totals */}
      <Box sx={styles.totalsSection}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
              <Typography>Subtotal</Typography>
              <Typography>${invoice.items.reduce((sum, item) => sum + item.total, 0).toFixed(2)}</Typography>
            </Box>
            {/* Only show tax if tax_rate exists and is not zero */}
            {invoice.tax_rate > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                <Typography>Tax ({invoice.tax_rate}%)</Typography>
                <Typography>
                  ${(invoice.items.reduce((sum, item) => sum + item.total, 0) * invoice.tax_rate / 100).toFixed(2)}
                </Typography>
              </Box>
            )}
            <Divider sx={{ my: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
              <Typography variant="h6">Total</Typography>
              <Typography variant="h6">
                ${(invoice.items.reduce((sum, item) => sum + item.total, 0) * (1 + (invoice.tax_rate || 0) / 100)).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default DefaultInvoiceTemplate;

import React from 'react';
import styled from 'styled-components';

const ExpandableInput = ({ label, value, onChange, name, minRows = 3, maxRows = 20, ...props }) => {
  return (
    <StyledWrapper>
      <div className="inputGroup">
        <textarea
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          required
          autoComplete="off"
          placeholder=" "
          rows={minRows}
          {...props}
        />
        <label htmlFor={name}>{label}</label>
        <div className="resizer" />
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .inputGroup {
    font-family: 'Urbanist', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 100%;
    position: relative;
  }

  .inputGroup textarea {
    font-family: 'Urbanist', sans-serif;
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 8px;
    width: 100%;
    min-height: 45px;
    box-sizing: border-box;
    resize: vertical;
    white-space: pre-wrap;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .inputGroup label {
    font-family: 'Urbanist', sans-serif;
    font-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%);
    padding: 0.4em;
    margin-left: 1.3em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
    background-color: var(--background-primary);
  }

  .inputGroup textarea:focus ~ label,
  .inputGroup textarea:not(:placeholder-shown) ~ label {
    transform: translateY(-50%) scale(.9);
  }

  .inputGroup textarea:focus,
  .inputGroup textarea:not(:placeholder-shown) {
    border-color: rgb(150, 150, 200);
  }

  .resizer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: ns-resize;
    pointer-events: none;
    &::after {
      content: '';
      position: absolute;
      bottom: 4px;
      right: 4px;
      width: 8px;
      height: 8px;
      border-right: 2px solid rgba(0, 0, 0, 0.3);
      border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    }
  }
`;

export default ExpandableInput; 
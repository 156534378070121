import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const primaryColor = '#4a5df9';
const primaryLightColor = '#6b7cfa';
const primaryDarkColor = '#3a4ac7';

const StepperContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(2),
  position: 'relative',
  padding: '0 8px'
}));

const StepConnector = styled(Box)(({ theme, active, completed }) => ({
  position: 'absolute',
  top: '39px',
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  height: '2px',
  backgroundColor: completed 
    ? primaryColor
    : active 
      ? primaryLightColor
      : theme.palette.grey[200],
  transition: 'background-color 0.3s ease'
}));

const StepCircle = styled(Box)(({ theme, active, completed }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: completed 
    ? primaryColor 
    : active 
      ? primaryLightColor
      : theme.palette.grey[100],
  border: `2px solid ${
    completed 
      ? primaryColor 
      : active 
        ? primaryColor
        : theme.palette.grey[300]
  }`,
  color: completed || active ? theme.palette.common.white : theme.palette.text.primary,
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: completed 
      ? primaryDarkColor 
      : primaryLightColor,
    transform: 'scale(1.05)'
  }
}));

const StepLabel = styled(Typography)(({ theme, active, completed }) => ({
  marginBottom: theme.spacing(1),
  fontSize: '0.75rem',
  color: theme.palette.text.primary,
  fontWeight: completed || active ? 600 : 400,
  textAlign: 'center',
  transition: 'all 0.3s ease',
  maxWidth: '120px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const CustomStepper = ({ steps, activeStep, onStepClick, className }) => {
  return (
    <StepperContainer className={className}>
      {steps.map((label, index) => {
        const isCompleted = index < activeStep;
        const isActive = index === activeStep;
        
        return (
          <Box
            key={label}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 1,
              position: 'relative',
              px: 1
            }}
          >
            <StepLabel
              active={isActive}
              completed={isCompleted}
            >
              {label}
            </StepLabel>
            
            {index !== 0 && (
              <StepConnector 
                active={isActive} 
                completed={isCompleted}
              />
            )}
            
            <StepCircle
              active={isActive}
              completed={isCompleted}
              onClick={() => onStepClick(index)}
            >
              {isCompleted ? (
                <CheckIcon sx={{ fontSize: 14 }} />
              ) : (
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: 'inherit',
                    fontSize: '0.7rem'
                  }}
                >
                  {index + 1}
                </Typography>
              )}
            </StepCircle>
          </Box>
        );
      })}
    </StepperContainer>
  );
};

export default CustomStepper; 
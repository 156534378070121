import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Tabs,
  Tab,
  Snackbar,
  Grid,
  Paper,
  Alert
} from '@mui/material';
import { 
  Package,
  Book,
  Wrench,
  Cube,
  Clock,
  Plus as Add,
  PencilSimple as Edit,
  Trash as Delete,
  QrCode as QrCode2,
  Storefront as Store,
  CurrencyDollar as AttachMoney,
  Money as PriceChange,
  TrendUp as TrendingUp,
  Tag as Label,
  BookOpen as MenuBook,
  Gear as HandymanOutlined,
  X as Close,
  Package as Inventory,
  TextT as TitleIcon,
  Hash as ModelIcon,
  Tag as BrandIcon,
  Storefront as DealerIcon,
  CurrencyDollar as PriceIcon,
  Package as UnitIcon,
  Calculator as CountIcon,
  DotsThree as ActionsIcon
} from "@phosphor-icons/react";
import SavedItemsManagerSettings from './settings/SavedItemsManagerSettings';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { getPriceBookItems, createPriceBookItem, updatePriceBookItem, deletePriceBookItem } from '../services/api';
import PricingConfigurator from './pricing/PricingConfigurator';
import PackagesManager from './packages/PackagesManager';

const ItemDetailsDialog = ({ item, open, onClose, onEdit }) => {
  if (!item) return null;

  const detailSections = [
    {
      label: 'Basic Information',
      fields: [
        { label: 'Title', value: item.title, icon: <MenuBook size={20} weight="light" /> },
        { label: 'Model Number', value: item.model_number || '-', icon: <QrCode2 size={20} weight="light" /> },
        { label: 'Brand', value: item.brand || '-', icon: <Label size={20} weight="light" /> },
      ]
    },
    {
      label: 'Pricing Details',
      fields: [
        { 
          label: 'Price', 
          value: `$${parseFloat(item.price).toFixed(2)}`,
          icon: <AttachMoney sx={{ color: '#66BB6A' }}/> 
        },
        { 
          label: 'Unit Cost', 
          value: `$${parseFloat(item.unit_cost).toFixed(2)}`,
          icon: <PriceChange sx={{ color: '#26A69A' }}/> 
        },
        { 
          label: 'Profit Margin', 
          value: `${(((item.price - item.unit_cost) / item.price) * 100).toFixed(1)}%`,
          icon: <TrendingUp sx={{ color: '#EC407A' }}/> 
        },
      ]
    },
    {
      label: 'Inventory Status',
      fields: [
        { 
          label: 'Current Stock', 
          value: item.count, 
          icon: <Inventory size={20} weight="light" /> 
        },
        { 
          label: 'Dealer', 
          value: item.dealer || '-',
          icon: <Store sx={{ color: '#FF7043' }}/> 
        }
      ]
    }
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundImage: 'none',
          borderRadius: '12px',
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          color: 'var(--text-primary)',
          padding: '20px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'var(--background-paper)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
          <Typography variant="h6" sx={{ fontFamily: 'Urbanist', fontWeight: 600 }}>
            Item Details
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            onClick={() => onEdit(item)}
            sx={{ 
              color: '#2196F3',
              '&:hover': {
                backgroundColor: 'rgba(33, 150, 243, 0.1)',
              }
            }}
          >
            <Edit />
          </IconButton>
          <IconButton 
            onClick={onClose}
            sx={{ 
              color: 'var(--text-secondary)',
              '&:hover': {
                backgroundColor: 'var(--action-hover)',
              }
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ 
        color: 'var(--text-primary)',
        padding: '24px'
      }}>
        {detailSections.map((section, index) => (
          <Box key={section.label} sx={{ mb: index !== detailSections.length - 1 ? 4 : 0 }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: 'var(--text-secondary)',
                fontWeight: 600,
                mb: 2,
                fontFamily: 'Urbanist',
              }}
            >
              {section.label}
            </Typography>
            <Grid container spacing={3}>
              {section.fields.map((field) => (
                <Grid item xs={12} sm={6} md={4} key={field.label}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      border: '1px solid var(--divider)',
                      borderRadius: '8px',
                      height: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
                      {field.icon}
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'var(--text-secondary)',
                          fontWeight: 500,
                          fontFamily: 'Urbanist',
                        }}
                      >
                        {field.label}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        color: 'var(--text-primary)',
                        fontWeight: 600,
                        fontFamily: 'Urbanist',
                      }}
                    >
                      {field.value}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        {item.description && (
          <Box sx={{ mt: 4 }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: 'var(--text-secondary)',
                fontWeight: 600,
                mb: 2,
                fontFamily: 'Urbanist',
              }}
            >
              Description
            </Typography>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                backgroundColor: 'var(--background-default)',
                border: '1px solid var(--divider)',
                borderRadius: '8px',
              }}
            >
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'var(--text-primary)',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'Urbanist',
                }}
              >
                {item.description || 'No description provided'}
              </Typography>
            </Paper>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

const PriceBook = () => {
  const [activeTab, setActiveTab] = useState('inventory');
  const [slideDirection, setSlideDirection] = useState('left');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [items, setItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [detailsOpen, setDetailsOpen] = useState(false);

  const inputStyle = {
    width: '100%',
    height: '45px',
    padding: '8px 12px',
    fontSize: '0.95rem',
    color: 'var(--text-primary)',
    backgroundColor: 'var(--background-paper)',
    border: '1px solid var(--divider)',
    borderRadius: '8px',
    outline: 'none',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: 'var(--primary-light)',
    },
    '&:focus': {
      borderColor: 'var(--primary-main)',
      borderWidth: '2px',
    },
  };

  const customInputStyle = {
    '& .MuiFilledInput-root': {
      backgroundColor: 'var(--background-paper)',
      border: '1px solid var(--divider)',
      borderRadius: '8px',
      padding: '8px 12px',
      
      // Remove all default MUI styling
      '&:before, &:after': {
        display: 'none'
      },
      
      // Clean hover state
      '&:hover': {
        backgroundColor: 'var(--background-paper)',
        border: '1px solid var(--primary-main)',
      },
      
      // Clean focused state
      '&.Mui-focused': {
        backgroundColor: 'var(--background-paper)',
        border: '2px solid var(--primary-main)',
      }
    },

    // Clean input styling
    '& .MuiFilledInput-input': {
      padding: '8px 0',
      backgroundColor: 'var(--background-paper)',
    },

    // Make the dollar signs prominent
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        fontSize: '1.25rem',
        fontWeight: 600,
        color: 'var(--text-primary)'
      }
    },

    // Remove label animations
    '& .MuiInputLabel-root': {
      position: 'static',
      transform: 'none',
      marginBottom: '8px'
    }
  };

  const [currentItem, setCurrentItem] = useState({
    title: '',
    model_number: '',
    brand: '',
    description: '',
    dealer: '',
    price: '',
    unit_cost: '',
    count: 0,
    name: '',
    id: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setIsEditing(true);
    setOpenDialog(true);
  };

  const handleSave = async () => {
    try {
      const itemData = {
        ...currentItem,
        name: currentItem.title,
        price: parseFloat(currentItem.price || 0),
        unit_cost: parseFloat(currentItem.unit_cost || 0),
        description: currentItem.description || ''
      };

      if (isEditing) {
        await updatePriceBookItem(selectedItem.id, itemData);
      } else {
        await createPriceBookItem(itemData);
      }
      
      // Refresh items list
      const response = await getPriceBookItems();
      const formattedItems = response.map(item => ({
        ...item,
        name: item.title,
        id: item.id,
        price: parseFloat(item.price || 0),
        unit_cost: parseFloat(item.unit_cost || 0),
        description: item.description || ''
      }));
      setItems(formattedItems);
      
      setOpenDialog(false);
      setSnackbarMessage(`Item ${isEditing ? 'updated' : 'created'} successfully`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving item:', error);
      setSnackbarMessage('Failed to save item');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const fetchItems = async () => {
    try {
      const data = await getPriceBookItems();
      setItems(data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleTabChange = (event, newValue) => {
    const tabOrder = ['inventory', 'priceBook', 'serviceItems', 'packages'];
    const oldIndex = tabOrder.indexOf(activeTab);
    const newIndex = tabOrder.indexOf(newValue);
    setSlideDirection(oldIndex < newIndex ? 'left' : 'right');
    setIsTransitioning(true);
    setActiveTab(newValue);
    
    // Reset transition state after animation completes
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300); // Match this with animation duration
  };

  const renderTabContent = () => {
    const content = (() => {
      switch (activeTab) {
        case 'inventory':
          return renderInventoryTab();
        case 'priceBook':
          return renderPriceBookTab();
        case 'serviceItems':
          return <SavedItemsManagerSettings />;
        case 'packages':
          return renderPackagesTab();
        default:
          return null;
      }
    })();

    return (
      <Box
        key={activeTab}
        sx={{
          animation: `${slideDirection === 'left' ? 'slideLeft' : 'slideRight'} 0.3s ease-out`,
          height: '100%',
          opacity: isTransitioning ? 0.3 : 1,
          transition: 'opacity 0.3s ease-out',
          '@keyframes slideLeft': {
            from: {
              opacity: 0,
              transform: 'translateX(20px)',
            },
            to: {
              opacity: 1,
              transform: 'translateX(0)',
            },
          },
          '@keyframes slideRight': {
            from: {
              opacity: 0,
              transform: 'translateX(-20px)',
            },
            to: {
              opacity: 1,
              transform: 'translateX(0)',
            },
          },
        }}
      >
        {content}
      </Box>
    );
  };

  const renderInventoryTab = () => {
    return (
      <Box sx={{ 
        p: 3,
        '& .MuiPaper-root': {
          boxShadow: 'none',
          backgroundColor: 'transparent'
        }
      }}>
        <div>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            gap: 2,
            paddingLeft: 3,
            paddingRight: 1,
            mb: 3,
            pb: 1
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'var(--text-primary)',
                fontFamily: 'Urbanist',
                fontWeight: 600 
              }}
            >
            </Typography>
            <Button
              variant="contained"
              startIcon={<Add size={20} weight="light" />}
              onClick={() => setOpenDialog(true)}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                backgroundColor: '#4a5df9',
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 600,
                fontSize: '0.875rem',
                padding: '6px 16px',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#3c50fa',
                  boxShadow: 'none',
                }
              }}
            >
              Add Item
            </Button>
          </Box>

          <TableContainer 
            component="div"
            sx={{ 
              backgroundColor: 'transparent',
              boxShadow: 'none',
              border: 'none',
              borderRadius: '12px',
              '& .MuiTable-root': {
                borderCollapse: 'separate',
                borderSpacing: '0',
              },
              overflowX: 'auto',
              width: '100%',
              '&::-webkit-scrollbar': {
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    { label: 'TITLE', icon: <TitleIcon size={20} weight="light" style={{ color: '#FF4842' }} /> },
                    { label: 'MODEL NUMBER', icon: <ModelIcon size={20} weight="light" style={{ color: '#00AB55' }} /> },
                    { label: 'BRAND', icon: <BrandIcon size={20} weight="light" style={{ color: '#1890FF' }} /> },
                    { label: 'DEALER', icon: <DealerIcon size={20} weight="light" style={{ color: '#7635dc' }} /> },
                    { label: 'PRICE', icon: <PriceIcon size={20} weight="light" style={{ color: '#FFC107' }} />, align: 'right' },
                    { label: 'UNIT COST', icon: <UnitIcon size={20} weight="light" style={{ color: '#00B8D9' }} />, align: 'right' },
                    { label: 'COUNT', icon: <CountIcon size={20} weight="light" style={{ color: '#FF4842' }} />, align: 'center' },
                    { label: 'ACTIONS', icon: <ActionsIcon size={20} weight="light" style={{ color: '#637381' }} />, align: 'center' }
                  ].map((cell, index) => (
                    <TableCell 
                      key={index}
                      align="center"
                      sx={{ 
                        backgroundColor: 'var(--background-secondary)',
                        borderBottom: '1px solid #e0e0e0',
                        fontFamily: 'Urbanist, sans-serif',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        color: 'var(--text-secondary)!important',
                        letterSpacing: '0.5px',
                        padding: '12px 16px',
                      }}
                    >
                      <Box display="flex" alignItems="center" justifyContent="center">
                        {cell.icon}
                        <Typography 
                          sx={{ 
                            ml: 1,
                            fontFamily: 'Urbanist, sans-serif',
                            fontSize: '0.75rem',
                            fontWeight: 600,
                            color: 'var(--text-secondary)!important',
                            letterSpacing: '0.5px',
                          }}
                        >
                          {cell.label}
                        </Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow 
                    key={item.id}
                    onClick={() => handleRowClick(item)}
                    sx={{ 
                      cursor: 'pointer',
                      '&:hover': { 
                        backgroundColor: 'var(--background-hover)'
                      },
                      '& td': {
                        borderBottom: '1px solid #e0e0e0',
                        color: 'var(--text-primary)',
                        padding: '16px',
                        fontSize: '0.875rem',
                        fontFamily: 'Urbanist',
                        fontWeight: 500,
                        textAlign: 'center'
                      }
                    }}
                  >
                    <TableCell align="center">{item.title}</TableCell>
                    <TableCell align="center">{item.model_number}</TableCell>
                    <TableCell align="center">{item.brand}</TableCell>
                    <TableCell align="center">{item.dealer}</TableCell>
                    <TableCell align="center">${item.price}</TableCell>
                    <TableCell align="center">${item.unit_cost}</TableCell>
                    <TableCell align="center">{item.count}</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(item);
                        }}
                        size="medium"
                        sx={{ 
                          color: '#2196F3',
                          padding: '6px',
                          '&:hover': {
                            backgroundColor: 'rgba(33, 150, 243, 0.1)',
                            color: '#1976D2'
                          }
                        }}
                      >
                        <Edit size={20} weight="light" style={{ color: '#2196F3' }} />
                      </IconButton>
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(item.id);
                        }}
                        size="medium"
                        sx={{ 
                          color: '#F44336',
                          ml: 1,
                          padding: '6px',
                          '&:hover': {
                            backgroundColor: 'rgba(244, 67, 54, 0.1)',
                            color: '#D32F2F'
                          }
                        }}
                      >
                        <Delete size={20} weight="light" style={{ color: '#F44336' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Add/Edit Dialog */}
        <Dialog 
          open={openDialog} 
          onClose={() => {
            setOpenDialog(false);
            setIsEditing(false);
            setCurrentItem({
              title: '',
              model_number: '',
              brand: '',
              description: '',
              dealer: '',
              price: '',
              unit_cost: '',
              count: 0,
            });
          }}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: 'var(--background-paper)',
              borderRadius: '12px',
              border: '1px solid var(--divider)',
            }
          }}
        >
          <DialogTitle sx={{ 
            fontFamily: 'Urbanist',
            fontWeight: 600,
            color: 'var(--text-primary)',
            borderBottom: '1px solid var(--divider)',
            padding: '24px',
            fontSize: '1.25rem'
          }}>
            {isEditing ? 'Edit Item' : 'Add New Item'}
          </DialogTitle>
          <DialogContent sx={{ 
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)',
            padding: '24px',
            paddingTop: '24px !important'  // Override MUI's default padding
          }}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}>
              <input
                type="text"
                name="title"
                placeholder="Title"
                value={currentItem.title}
                onChange={handleInputChange}
                style={inputStyle}
              />

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <input
                  type="text"
                  name="model_number"
                  placeholder="Model Number"
                  value={currentItem.model_number}
                  onChange={handleInputChange}
                  style={inputStyle}
                />
                <input
                  type="text"
                  name="brand"
                  placeholder="Brand"
                  value={currentItem.brand}
                  onChange={handleInputChange}
                  style={inputStyle}
                />
              </Box>

              <textarea
                name="description"
                placeholder="Description"
                value={currentItem.description}
                onChange={handleInputChange}
                style={{
                  ...inputStyle,
                  height: '90px',
                  resize: 'vertical',
                }}
              />

              <input
                type="text"
                name="dealer"
                placeholder="Dealer"
                value={currentItem.dealer}
                onChange={handleInputChange}
                style={inputStyle}
              />

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 2 }}>
                <Box sx={{ position: 'relative' }}>
                  <span style={{
                    position: 'absolute',
                    left: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'var(--text-primary)',
                    fontSize: '1rem',
                    fontWeight: 500,
                    zIndex: 1,
                  }}>$</span>
                  <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={currentItem.price}
                    onChange={handleInputChange}
                    style={{
                      ...inputStyle,
                      paddingLeft: '24px',
                    }}
                    min="0"
                    step="0.01"
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <span style={{
                    position: 'absolute',
                    left: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'var(--text-primary)',
                    fontSize: '1rem',
                    fontWeight: 500,
                    zIndex: 1,
                  }}>$</span>
                  <input
                    type="number"
                    name="unit_cost"
                    placeholder="Unit Cost"
                    value={currentItem.unit_cost}
                    onChange={handleInputChange}
                    style={{
                      ...inputStyle,
                      paddingLeft: '24px',
                    }}
                    min="0"
                    step="0.01"
                  />
                </Box>
                <input
                  type="number"
                  name="count"
                  placeholder="Count"
                  value={currentItem.count}
                  onChange={handleInputChange}
                  style={inputStyle}
                  min="0"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ 
            padding: '16px 24px',
            gap: 2
          }}>
            <Button 
              onClick={() => {
                setOpenDialog(false);
                setIsEditing(false);
                setCurrentItem({
                  title: '',
                  model_number: '',
                  brand: '',
                  description: '',
                  dealer: '',
                  price: '',
                  unit_cost: '',
                  count: 0,
                });
              }}
              disableRipple
              sx={{ 
                color: 'var(--text-secondary)',
                fontWeight: 600,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'var(--background-default)'
                }
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSave} 
              variant="contained"
              disableRipple
              sx={{
                backgroundColor: '#4a5df9',
                fontWeight: 600,
                textTransform: 'none',
                padding: '6px 20px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#3c50fa',
                }
              }}
            >
              {isEditing ? 'Save Changes' : 'Create Item'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Details Modal */}
        <ItemDetailsDialog
          item={selectedItem}
          open={detailsOpen}
          onClose={() => {
            setDetailsOpen(false);
            setSelectedItem(null);
          }}
          onEdit={(item) => {
            setDetailsOpen(false);
            handleEdit(item);
          }}
        />
      </Box>
    );
  };

  const renderPriceBookTab = () => {
    return (
      <Box sx={{ p: 3 }}>
        <PricingConfigurator />
      </Box>
    );
  };

  const renderPackagesTab = () => {
    return (
      <Box sx={{ p: 3 }}>
        <PackagesManager />
      </Box>
    );
  };

  const handleDelete = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        console.log('Deleting item with ID:', itemId);
        await deletePriceBookItem(itemId);
        
        await fetchItems();
        
        setSnackbarMessage('Item deleted successfully');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error deleting item:', error);
        setSnackbarMessage('Error deleting item: ' + (error.response?.data?.message || error.message));
        setSnackbarOpen(true);
      }
    }
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setDetailsOpen(true);
  };

  const styles = {
    headerContent: {
      maxWidth: '1400px',
      margin: '0 auto',
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '24px',
    },
    tabsWrapper: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    contentWrapper: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: 'var(--background-default)',
      padding: '24px',
      '& .MuiPaper-root': {
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    },
    slideContainer: {
      animation: `${slideDirection === 'left' ? 'slideLeft' : 'slideRight'} 0.3s ease-out`,
      height: '100%',
      '@keyframes slideLeft': {
        from: {
          opacity: 0,
          transform: 'translateX(50px)',
        },
        to: {
          opacity: 1,
          transform: 'translateX(0)',
        },
      },
      '@keyframes slideRight': {
        from: {
          opacity: 0,
          transform: 'translateX(-50px)',
        },
        to: {
          opacity: 1,
          transform: 'translateX(0)',
        },
      },
    }
  };

  // Add these color constants at the top of your file
  const tabColors = {
    inventory: '#2196F3',    // Blue
    priceBook: '#4CAF50',    // Green
    serviceItems: '#FF9800', // Orange
    packages: '#9C27B0'      // Purple
  };

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* Header Section */}
      <Box sx={{ 
        px: 3,
        py: 2,
        backgroundColor: 'var(--background-paper)',
      }}>
        <Box>
          <Typography 
            variant="h6" 
            sx={{ 
              fontSize: '1.5rem',
              fontWeight: 700,
              color: 'var(--text-primary)',
              mb: 1.5
            }}
          >
            Price Book Management
          </Typography>
        </Box>
      </Box>

      {/* Tabs Section */}
      <Box sx={{ 
        backgroundColor: 'var(--background-paper)',
        borderBottom: '1px solid #e0e0e0',
        borderTop: 'none',
      }}>
        <Tabs 
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            px: 2,
            '& .MuiTabs-indicator': {
              backgroundColor: '#7635dc',
              height: '2px',
              borderRadius: '3px 3px 0 0',
              transition: 'all 0.2s ease'
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              color: 'var(--text-secondary)',
              minHeight: 48,
              padding: '12px 24px',
              gap: '8px',
              position: 'relative',
              '& .MuiTouchRipple-root': {
                display: 'none'
              },
              '& svg': {
                marginBottom: '0 !important',
                marginRight: '0 !important'
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0,
                height: '2px',
                borderRadius: '3px 3px 0 0',
                backgroundColor: '#7635dc',
                opacity: 0.6,
                transition: 'all 0.2s ease',
                transform: 'translateX(-50%)',
              },
              '&:hover::after': {
                width: '80%',
              },
              '&.Mui-selected': {
                color: 'var(--primary-main)',
                fontWeight: 600,
                '&::after': {
                  opacity: 0,
                },
              },
              '&:hover': {
                color: 'var(--primary-main)',
              }
            },
          }}
        >
          <Tab 
            icon={<Inventory size={20} weight="light" />}
            iconPosition="start"
            label="Inventory" 
            value="inventory"
            disableRipple
          />
          <Tab 
            icon={<Book size={20} weight="light" />}
            iconPosition="start"
            label="Price Book" 
            value="priceBook"
            disableRipple
          />
          <Tab 
            icon={<Wrench size={20} weight="light" />}
            iconPosition="start"
            label="Service Items" 
            value="serviceItems"
            disableRipple
          />
          <Tab 
            icon={<Package size={20} weight="light" />}
            iconPosition="start"
            label="Packages" 
            value="packages"
            disableRipple
          />
        </Tabs>
      </Box>

      {/* Content Area with Animation */}
      <Box sx={styles.contentWrapper}>
        {renderTabContent()}
      </Box>

      {/* Dialogs remain unchanged */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default PriceBook; 
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Paper, 
  CircularProgress, 
  Button, 
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Chip,
  ListSubheader,
  Tabs,
  Tab,
  Backdrop,
  LinearProgress,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { createEmployeeRequest, getMyRequests, getEmployeeAvailability, updateEmployeeAvailability, getEmployeeDashboard, updateRecurringAvailability } from '../services/api';
import styles from './EmployeeAvailability.module.scss';
import AvailabilityCalendar from './shared/AvailabilityCalendar';
import RecurringAvailabilityModal from './shared/RecurringAvailabilityModal';
import {
  CheckCircle as ApproveIcon,
  Cancel as RejectIcon,
  AccessTime as PendingIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { 
  ClipboardText, 
  Calendar 
} from "@phosphor-icons/react";

const formatRequestType = (type) => {
  return type
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

const EmployeeAvailability = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const [requests, setRequests] = useState([]);
  const [error, setError] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [requestForm, setRequestForm] = useState({
    type: '',
    details: '',
    date: moment().format('YYYY-MM-DD'),
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [successMessage, setSuccessMessage] = useState('');
  const [currentAvailableDays, setCurrentAvailableDays] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);

  // Separate loading states for different data types
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [availabilityLoading, setAvailabilityLoading] = useState(true);

  const [slideDirection, setSlideDirection] = useState('left');
  const [isTransitioning, setIsTransitioning] = useState(false);

  const requestTypes = [
    { value: 'vacation', label: 'Vacation Time' },
    { value: 'sick_leave', label: 'Sick Leave' },
    { value: 'personal_day', label: 'Personal Day' },
    { value: 'medical_appointment', label: 'Medical Appointment' },
    { value: 'family_emergency', label: 'Family Emergency' },
    { value: 'bereavement', label: 'Bereavement Leave' },
    
    { value: 'schedule_change', label: 'Schedule Change' },
    { value: 'shift_swap', label: 'Shift Swap Request' },
    { value: 'leave_early', label: 'Leave Early Request' },
    { value: 'arrive_late', label: 'Late Arrival Notice' },
    { value: 'overtime_request', label: 'Overtime Request' },
    
    { value: 'training_request', label: 'Training/Course Request' },
    { value: 'equipment_request', label: 'Equipment Request' },
    { value: 'remote_work', label: 'Remote Work Request' },
    { value: 'travel_request', label: 'Travel Request' },
    
    { value: 'jury_duty', label: 'Jury Duty' },
    { value: 'military_leave', label: 'Military Leave' },
    { value: 'other', label: 'Other Request' }
  ];

  const fetchAvailability = async (id) => {
    if (!id) {
      console.error('No employee ID provided to fetchAvailability');
      return;
    }

    setAvailabilityLoading(true);
    try {
      const data = await getEmployeeAvailability(id);
      console.log('Fetched availability data:', data);
      
      // Make sure we're setting the data in the correct format
      const formattedDates = Array.isArray(data) ? data : [];
      setAvailabilityDates(formattedDates);
      
    } catch (error) {
      console.error('Error fetching availability:', error);
      setError('Failed to fetch availability');
    } finally {
      setAvailabilityLoading(false);
    }
  };

  const fetchRequests = async () => {
    setRequestsLoading(true);
    try {
      const data = await getMyRequests();
      setRequests(data.requests || []);
    } catch (error) {
      console.error('Error fetching requests:', error);
      setError('Failed to fetch requests');
    } finally {
      setRequestsLoading(false);
    }
  };

  const calculateAvailableDays = useCallback(() => {
    const days = new Set(
      availabilityDates.map(date => 
        moment(date.start_time).day()
      )
    );
    return Array.from(days).sort();
  }, [availabilityDates]);

  useEffect(() => {
    if (availabilityDates.length > 0) {
      const days = calculateAvailableDays();
      setCurrentAvailableDays(days);
    }
  }, [availabilityDates, calculateAvailableDays]);

  const handleRecurringAvailability = async (data) => {
    try {
      setIsUpdating(true);
      
      if (data.selectedDays.length > 0) {
        await updateRecurringAvailability(
          employeeId,
          data.selectedDays,
          data.startDate,
          data.endDate,
          true
        );
      }

      if (data.removedDays.length > 0) {
        await updateRecurringAvailability(
          employeeId,
          data.removedDays,
          data.startDate,
          data.endDate,
          false
        );
      }

      await fetchAvailability(employeeId);
      setShowRecurringModal(false);
    } catch (error) {
      console.error('Error updating recurring availability:', error);
      setError('Failed to update recurring availability');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    const oldIndex = activeTab;
    setSlideDirection(oldIndex < newValue ? 'left' : 'right');
    setIsTransitioning(true);
    setActiveTab(newValue);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  };

  const handleOpenRequestModal = () => {
    setOpenRequestModal(true);
  };

  const handleCloseRequestModal = () => {
    setOpenRequestModal(false);
    setRequestForm({
      type: '',
      details: '',
      date: moment().format('YYYY-MM-DD'),
    });
  };

  const handleRequestFormChange = (e) => {
    const { name, value } = e.target;
    console.log('Form change:', name, value);
    setRequestForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmitRequest = async () => {
    try {
      setSubmitLoading(true);
      await createEmployeeRequest(requestForm);
      await fetchRequests();
      handleCloseRequestModal();
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to submit request');
    } finally {
      setSubmitLoading(false);
    }
  };

  const getStatusChip = (status) => {
    const statusProps = {
      pending: { color: 'warning', icon: <PendingIcon />, label: 'Pending' },
      approved: { color: 'success', icon: <ApproveIcon />, label: 'Approved' },
      rejected: { color: 'error', icon: <RejectIcon />, label: 'Rejected' },
    };

    const { color, icon, label } = statusProps[status] || statusProps.pending;

    return (
      <Chip
        icon={icon}
        label={label}
        color={color}
        size="small"
        sx={{ fontWeight: 500 }}
      />
    );
  };

  const RequestModal = () => (
    <Dialog open={openRequestModal} onClose={handleCloseRequestModal} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Request</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 500 }}>
              Request Type
            </Typography>
            <TextField
              select
              fullWidth
              name="type"
              value={requestForm.type}
              onChange={handleRequestFormChange}
              required
            >
              <ListSubheader>Time Off</ListSubheader>
              {requestTypes.slice(0, 6).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {formatRequestType(option.value)}
                </MenuItem>
              ))}

              <ListSubheader>Schedule Changes</ListSubheader>
              {requestTypes.slice(6, 11).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {formatRequestType(option.value)}
                </MenuItem>
              ))}

              <ListSubheader>Administrative</ListSubheader>
              {requestTypes.slice(11, 15).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {formatRequestType(option.value)}
                </MenuItem>
              ))}

              <ListSubheader>Other</ListSubheader>
              {requestTypes.slice(15).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {formatRequestType(option.value)}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 500 }}>
              Date
            </Typography>
            <TextField
              fullWidth
              type="date"
              name="date"
              value={requestForm.date}
              onChange={handleRequestFormChange}
              required
            />
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 500 }}>
              Details
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="details"
              value={requestForm.details}
              onChange={handleRequestFormChange}
              placeholder="Please provide details about your request..."
              required
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 4, pt: 2, borderTop: '1px solid #e5e7eb', gap: 2 }}>
        <Button onClick={handleCloseRequestModal}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmitRequest}
          disabled={!requestForm.type || !requestForm.details || submitLoading}
        >
          {submitLoading ? 'Submitting...' : 'Submit Request'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const CalendarSection = () => (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ 
        mb: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center' 
      }}>
        <Button
          variant="outlined"
          onClick={() => setShowRecurringModal(true)}
        >
          Set Recurring Availability
        </Button>
        <Typography variant="body2" color="text.secondary">
          Click on dates to toggle your availability
        </Typography>
      </Box>
      
      <AvailabilityCalendar
        availabilityDates={availabilityDates}
        onDateClick={handleDateClick}
        readOnly={false}
      />
    </Box>
  );

  const handleDateClick = async (info) => {
    console.log('Date click received:', info);
    
    if (!employeeId) {
      console.error('No employee ID found');
      return;
    }

    try {
      await updateEmployeeAvailability(
        employeeId,
        info.date,
        info.is_available
      );
      
      // Show success message
      setSuccessMessage('Availability updated successfully!');
      // Clear message after 3 seconds
      setTimeout(() => setSuccessMessage(''), 3000);
      
      await fetchAvailability(employeeId);
      
    } catch (error) {
      console.error('Error updating availability:', error);
      setError('Failed to update availability');
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        // Get employee data from dashboard
        const dashboardData = await getEmployeeDashboard();
        console.log('Dashboard data received:', dashboardData);
        
        if (!dashboardData?.employee?.id) {
          throw new Error('No employee data found');
        }

        setEmployeeId(dashboardData.employee.id);
        
        // Now fetch availability with the employee ID
        await fetchAvailability(dashboardData.employee.id);
        await fetchRequests();
        
      } catch (error) {
        console.error('Error initializing data:', error);
        setError(error.message);
      }
    };

    initializeData();
  }, []);

  const isLoading = requestsLoading || availabilityLoading;

  console.log('Current state:', {
    employeeId,
    availabilityDates,
    isLoading: requestsLoading || availabilityLoading,
    error
  });

  const handleCloseRecurringModal = () => {
    setShowRecurringModal(false);
    setIsUpdating(false);
    setProgress({ current: 0, total: 0 });
  };

  const RequestDetailsModal = ({ request, onClose }) => {
    if (!request) return null;

    return (
      <Dialog open={Boolean(request)} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            backgroundColor: '#f8fafc',
            p: 2.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            Request Details
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: '#64748b',
              '&:hover': {
                backgroundColor: 'rgba(100, 116, 139, 0.1)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Request Type
              </Typography>
              <Typography variant="body1">
                {formatRequestType(request.type)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Status
              </Typography>
              {getStatusChip(request.status)}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Request Date
              </Typography>
              <Typography variant="body1">
                {new Date(request.date).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Details
              </Typography>
              <Typography variant="body1">
                {request.details}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const tabItems = [
    { icon: <ClipboardText size={20} weight="light" />, label: 'Requests' },
    { icon: <Calendar size={20} weight="light" />, label: 'Calendar' }
  ];

  if (isLoading) {
    return (
      <Paper elevation={3} className={styles.availabilityContainer}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '50vh',
          gap: 2
        }}>
          <CircularProgress />
          <Typography variant="body1" color="text.secondary">
            Loading your availability...
          </Typography>
          <LinearProgress 
            sx={{ width: '200px' }} 
            variant="indeterminate"
          />
        </Box>
      </Paper>
    );
  }

  return (
    <div className={styles.availabilityContainer}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        width: '100%',
        mb: 3,
        pb: 2,
        borderBottom: '1px solid var(--divider)'
      }}>
        <Typography variant="h4" sx={{ 
          fontWeight: 600, 
          color: 'var(--text-primary)',
          fontFamily: "'Noto Sans', sans-serif"
        }}>
          My Availability
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenRequestModal}
          sx={{
            boxShadow: 'none',
            backgroundColor: 'var(--primary-main)',
            '&:hover': {
              backgroundColor: 'var(--primary-dark)',
              boxShadow: 'none',
            },
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '0.875rem',
            padding: '6px 16px',
            borderRadius: '8px'
          }}
        >
          Create Request
        </Button>
      </Box>

      <Box sx={{ 
        width: '100%',
        bgcolor: 'var(--background-paper)',
        borderRadius: '12px',
        overflow: 'hidden',
      }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            px: 2,
            width: '100%',
            '& .MuiTabs-indicator': {
              backgroundColor: '#7635dc',
              height: '2px',
              borderRadius: '3px 3px 0 0',
              transition: 'all 0.2s ease'
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              color: 'var(--text-secondary)',
              minHeight: 48,
              padding: '12px 24px',
              gap: '8px',
              position: 'relative',
              '& .MuiTouchRipple-root': {
                display: 'none'
              },
              '& svg': {
                marginBottom: '0 !important',
                marginRight: '0 !important'
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0,
                height: '2px',
                borderRadius: '3px 3px 0 0',
                backgroundColor: '#7635dc',
                opacity: 0.6,
                transition: 'all 0.2s ease',
                transform: 'translateX(-50%)',
              },
              '&:hover::after': {
                width: '80%',
              },
              '&.Mui-selected': {
                color: 'var(--primary-main)',
                fontWeight: 600,
                '&::after': {
                  opacity: 0,
                },
              },
              '&:hover': {
                color: 'var(--primary-main)',
              }
            },
          }}
        >
          <Tab 
            icon={<ClipboardText size={20} weight="light" />}
            iconPosition="start"
            label="Requests" 
            disableRipple
          />
          <Tab 
            icon={<Calendar size={20} weight="light" />}
            iconPosition="start"
            label="Calendar"
            disableRipple
          />
        </Tabs>

        <Box sx={{ 
          marginTop: '1rem',
          flex: 1,
          width: '100%',
          overflowX: 'hidden',
          backgroundColor: 'var(--background-paper)',
        }}>
          {[
            <TableContainer 
              sx={{ 
                borderRadius: 2, 
                width: '100%',
                overflow: 'auto'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Request Date</TableCell>
                    <TableCell>Details</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests.map((request) => (
                    <TableRow key={request.id}>
                      <TableCell>{formatRequestType(request.type)}</TableCell>
                      <TableCell>{new Date(request.date).toLocaleDateString()}</TableCell>
                      <TableCell>{request.details}</TableCell>
                      <TableCell>{getStatusChip(request.status)}</TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                          <Tooltip title="View Details">
                            <IconButton
                              color="primary"
                              onClick={() => setSelectedRequest(request)}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>,
            <Box sx={{ width: '100%', p: 2 }}>
              <CalendarSection />
            </Box>
          ].map((content, index) => (
            <Box
              key={index}
              sx={{
                display: activeTab === index ? 'block' : 'none',
                width: '100%',
                height: '100%',
                animation: `${slideDirection === 'left' ? 'slideLeft' : 'slideRight'} 0.3s ease-out`,
                opacity: isTransitioning ? 0.3 : 1,
                transition: 'opacity 0.3s ease-out',
                '@keyframes slideLeft': {
                  from: {
                    opacity: 0,
                    transform: 'translateX(20px)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'translateX(0)',
                  },
                },
                '@keyframes slideRight': {
                  from: {
                    opacity: 0,
                    transform: 'translateX(-20px)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'translateX(0)',
                  },
                },
              }}
            >
              {content}
            </Box>
          ))}
        </Box>
      </Box>

      <RequestModal />
      
      <RecurringAvailabilityModal
        open={showRecurringModal}
        onClose={handleCloseRecurringModal}
        onSave={handleRecurringAvailability}
        initialSelectedDays={currentAvailableDays}
        isLoading={isUpdating}
        progress={progress}
      />

      <RequestDetailsModal 
        request={selectedRequest}
        onClose={() => setSelectedRequest(null)}
      />
    </div>
  );
};

export default EmployeeAvailability;

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import {
  PencilSimple as Edit,
  Trash as Delete,
  TextT as NameIcon,
  Article as DescriptionIcon,
  Wrench as ServiceIcon,
  Calculator as CalculationIcon,
  CurrencyDollar as RateIcon,
  DotsThree as ActionsIcon
} from "@phosphor-icons/react";
import { serviceTypes, calculationTypes } from './pricingConstants';

const formatCalculationDetails = (rule) => {
  if (rule.calculationType === 'area') {
    return `$${parseFloat(rule.baseRate).toFixed(2)}/sq ft`;
  }
  return `$${parseFloat(rule.baseRate).toFixed(2)}${
    rule.calculationType === 'hourly' ? '/hr' : 
    rule.calculationType === 'unit' ? '/unit' : ''
  }`;
};

const PricingRuleTable = ({ 
  rules, 
  onEdit, 
  onDelete 
}) => {
  return (
    <TableContainer 
      component="div"
      sx={{ 
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 'none',
        borderRadius: '12px',
        '& .MuiTable-root': {
          borderCollapse: 'separate',
          borderSpacing: '0',
        },
        overflowX: 'auto',
        width: '100%',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {[
              { label: 'NAME', icon: <NameIcon size={20} weight="light" style={{ color: '#FF4842' }} /> },
              { label: 'DESCRIPTION', icon: <DescriptionIcon size={20} weight="light" style={{ color: '#00AB55' }} /> },
              { label: 'SERVICE TYPE', icon: <ServiceIcon size={20} weight="light" style={{ color: '#1890FF' }} /> },
              { label: 'CALCULATION TYPE', icon: <CalculationIcon size={20} weight="light" style={{ color: '#7635dc' }} /> },
              { label: 'RATE', icon: <RateIcon size={20} weight="light" style={{ color: '#FFC107' }} /> },
              { label: 'ACTIONS', icon: <ActionsIcon size={20} weight="light" style={{ color: '#637381' }} />, align: 'center' }
            ].map((cell, index) => (
              <TableCell 
                key={index}
                align="center"
                sx={{ 
                  backgroundColor: 'var(--background-secondary)',
                  borderBottom: '1px solid #e0e0e0',
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: '0.75rem',
                  fontWeight: 600,
                  color: 'var(--text-secondary)!important',
                  letterSpacing: '0.5px',
                  padding: '12px 16px',
                }}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  {cell.icon}
                  <Typography 
                    sx={{ 
                      ml: 1,
                      fontFamily: 'Urbanist, sans-serif',
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      color: 'var(--text-secondary)!important',
                      letterSpacing: '0.5px',
                    }}
                  >
                    {cell.label}
                  </Typography>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rules.map((rule) => (
            <TableRow 
              key={rule.id || 'new'}
              sx={{ 
                cursor: 'pointer',
                '&:hover': { 
                  backgroundColor: 'var(--background-hover)'
                },
                '& td': {
                  borderBottom: '1px solid #e0e0e0',
                  color: 'var(--text-primary)',
                  padding: '16px',
                  fontSize: '0.875rem',
                  fontFamily: 'Urbanist',
                  fontWeight: 500,
                  textAlign: 'center'
                }
              }}
            >
              <TableCell align="center">{rule.name}</TableCell>
              <TableCell align="center">{rule.description || '-'}</TableCell>
              <TableCell align="center">
                {serviceTypes.find(t => t.value === rule.serviceType)?.label || rule.serviceType}
              </TableCell>
              <TableCell align="center">
                {calculationTypes.find(t => t.value === rule.calculationType)?.label || rule.calculationType}
              </TableCell>
              <TableCell align="center">{formatCalculationDetails(rule)}</TableCell>
              <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                <IconButton 
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(rule.id || 'new');
                  }}
                  size="medium"
                  sx={{ 
                    color: '#2196F3',
                    padding: '6px',
                    '&:hover': {
                      backgroundColor: 'rgba(33, 150, 243, 0.1)',
                      color: '#1976D2'
                    }
                  }}
                >
                  <Edit size={20} weight="light" style={{ color: '#2196F3' }} />
                </IconButton>
                <IconButton 
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(rule.id || 'new');
                  }}
                  size="medium"
                  sx={{ 
                    color: '#F44336',
                    ml: 1,
                    padding: '6px',
                    '&:hover': {
                      backgroundColor: 'rgba(244, 67, 54, 0.1)',
                      color: '#D32F2F'
                    }
                  }}
                >
                  <Delete size={20} weight="light" style={{ color: '#F44336' }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingRuleTable; 
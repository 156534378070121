import React, { useState, useEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button, IconButton, Tooltip, Menu, Checkbox
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getAdminTimesheets, exportTimesheetsCSV } from '../services/api';
import { 
  AccessTime, EventNote, AttachMoney, WorkOutline, 
  CalendarToday, HourglassEmpty, CheckCircleOutline, Person, Event
} from '@mui/icons-material';
import styles from './Timesheets.module.scss';
import { useNavigate } from 'react-router-dom';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { formatDateTime, getStartOfDay, getEndOfDay } from '../utils/dateUtils';
import NoTimesheetsBanner from './NoTimesheetsBanner';
import { 
  Clock as ClockIcon,
  ClockCounterClockwise as ClockOutIcon,
  Hourglass as HourglassIcon,
  Briefcase as BriefcaseIcon,
  User as UserIcon,
  Calendar as CalendarIcon,
  CurrencyDollar as CurrencyDollarIcon,
  Percent as PercentIcon,
  Calculator as CalculatorIcon,
  Eye as EyeIcon,
  Gear as SettingsIcon,
  Check as CheckIcon,
  ChartLine
} from "@phosphor-icons/react";
import TimesheetsGraph from './TimesheetsGraph';

const headerCells = [
  { icon: <ClockIcon size={20} weight="light" style={{ color: '#FF4842' }} />, label: 'CLOCK IN' },
  { icon: <ClockOutIcon size={20} weight="light" style={{ color: '#2065D1' }} />, label: 'CLOCK OUT' },
  { icon: <HourglassIcon size={20} weight="light" style={{ color: '#FFC107' }} />, label: 'HOURS WORKED' },
  { icon: <BriefcaseIcon size={20} weight="light" style={{ color: '#00AB55' }} />, label: 'PROJECT' },
  { icon: <UserIcon size={20} weight="light" style={{ color: '#7635DC' }} />, label: 'EMPLOYEE' },
  { icon: <CalendarIcon size={20} weight="light" style={{ color: '#16a085' }} />, label: 'EVENT' },
  { icon: <CurrencyDollarIcon size={20} weight="light" style={{ color: '#2ecc71' }} />, label: 'PAYMENT TYPE' },
  { icon: <PercentIcon size={20} weight="light" style={{ color: '#1abc9c' }} />, label: 'RATE/PERCENTAGE' },
  { icon: <CalculatorIcon size={20} weight="light" style={{ color: '#27ae60' }} />, label: 'CALCULATED PAYMENT' },
  { icon: <EyeIcon size={20} weight="light" style={{ color: '#607d8b' }} />, label: 'ACTIONS' }
];

const Timesheets = ({ projectId }) => {
  const [timesheets, setTimesheets] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectFilter, setProjectFilter] = useState('All');
  const [employeeFilter, setEmployeeFilter] = useState('All');
  const [eventFilter, setEventFilter] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projects, setProjects] = useState(['All']);
  const [employees, setEmployees] = useState(['All']);
  const [events, setEvents] = useState(['All']);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTimesheets();
  }, [projectId]);

  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      const data = await getAdminTimesheets();
      console.log('Raw response from server:', data);

      if (data && Array.isArray(data.timesheets)) {
        setTimesheets(data.timesheets);
        setSummary(data.summary);
        
        // Extract unique values for filters
        const uniqueProjects = [...new Set(data.timesheets.map(ts => 
          ts.event?.project_name || 'Unassigned'))];
        const uniqueEmployees = [...new Set(data.timesheets.map(ts => 
          ts.employee?.name || 'Unassigned'))];
        const uniqueEvents = [...new Set(data.timesheets.map(ts => 
          ts.event?.title || 'Unassigned'))];
        
        const projectsList = ['All', ...uniqueProjects];
        setProjects(projectsList);
        setEmployees(['All', ...uniqueEmployees]);
        setEvents(['All', ...uniqueEvents]);

        // If projectId exists, find and set the matching project filter
        if (projectId) {
          const matchingTimesheet = data.timesheets.find(ts => 
            String(ts.event?.project_id) === String(projectId)
          );
          if (matchingTimesheet?.event?.project_name) {
            const projectName = matchingTimesheet.event.project_name;
            // Verify the project name exists in our list before setting it
            if (projectsList.includes(projectName)) {
              console.log('Setting project filter to:', projectName);
              setProjectFilter(projectName);
            }
          }
        }
      } else {
        throw new Error('Invalid data received from server');
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching timesheets:', err);
      setError('Failed to fetch timesheets. Please try again later.');
      setTimesheets([]);
      setSummary(null);
    } finally {
      setLoading(false);
    }
  };

  const filteredTimesheets = timesheets.filter(timesheet => {
    const projectMatch = projectFilter === 'All' || timesheet.event.project_name === projectFilter;
    const employeeMatch = employeeFilter === 'All' || timesheet.employee.name === employeeFilter;
    const eventMatch = eventFilter === 'All' || timesheet.event.title === eventFilter;
    
    const clockInDate = new Date(timesheet.clock_in);
    const dateMatch = (!startDate || clockInDate >= getStartOfDay(startDate)) &&
                     (!endDate || clockInDate <= getEndOfDay(endDate));
    
    return projectMatch && employeeMatch && eventMatch && dateMatch;
  });

  const totalHoursWorked = filteredTimesheets.reduce((sum, ts) => sum + (ts.hours_worked || 0), 0);
  const totalCalculatedPayment = filteredTimesheets.reduce((sum, ts) => sum + (ts.calculated_payment || 0), 0);

  const handleViewDetails = (timesheetId) => {
    console.log('Attempting to navigate to timesheet:', timesheetId);
    console.log('Current location:', window.location.pathname);
    navigate(`/admin/timesheets/${timesheetId}`);
    console.log('Navigation completed');
  };

  const handleExport = async () => {
    try {
      console.log('Export button clicked');
      console.log('Filters:', {
        project: projectFilter,
        employee: employeeFilter,
        event: eventFilter,
        startDate,
        endDate
      });
      
      await exportTimesheetsCSV({
        project: projectFilter,
        employee: employeeFilter,
        event: eventFilter,
        startDate,
        endDate
      });
      console.log('Export completed');
    } catch (error) {
      console.error('Failed to export timesheets:', error);
    }
  };

  const hasProjectTimesheets = projectId ? 
    timesheets.some(ts => String(ts.event?.project_id) === String(projectId)) : 
    true;

  const getColumnWidth = (label) => {
    switch (label) {
      case 'CLOCK IN':
      case 'CLOCK OUT':
        return '12%';
      case 'HOURS WORKED':
        return '8%';
      case 'PROJECT':
      case 'EMPLOYEE':
      case 'EVENT':
        return '15%';
      case 'PAYMENT TYPE':
        return '10%';
      case 'RATE/PERCENTAGE':
        return '8%';
      case 'CALCULATED PAYMENT':
        return '10%';
      case 'ACTIONS':
        return '7%';
      default:
        return 'auto';
    }
  };

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;
  if (timesheets.length === 0) return <div className={styles.errorContainer}><Typography>No timesheets found.</Typography></div>;

  return (
    <div className={styles.timesheetsContainer}>

      {/* Move NoTimesheetsBanner here, right after the header */}
      {projectId && !hasProjectTimesheets && (
        <NoTimesheetsBanner 
          projectName={timesheets.find(ts => 
            String(ts.event?.project_id) === String(projectId)
          )?.event?.project_name || 'this project'} 
        />
      )}

      {/* Filters Box with Export Button */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        gap: 2,
        paddingLeft: 3,
        paddingRight: 1,
        mb: 3,
        pb: 1
      }}>
        {/* Export button only */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={handleExport}
            sx={{ 
              height: '40px',
              paddingLeft: 3,
              paddingRight: 3,
              backgroundColor: '#7635dc',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#6528c0',
                boxShadow: 'none'
              },
              textTransform: 'none',
              borderRadius: '8px',
              fontFamily: 'Urbanist, sans-serif',
              fontWeight: 600
            }}
          >
            Export CSV
          </Button>
        </Box>

        {/* Keep existing filters */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* Project Filter */}
          <FormControl sx={{ minWidth: 150 }}>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 1,
                color: 'var(--text-secondary)',
                fontFamily: 'Urbanist, sans-serif',
                fontSize: '0.75rem',
                fontWeight: 600,
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
              }}
            >
              Project
            </Typography>
            <Select
              value={projectFilter}
              onChange={(e) => setProjectFilter(e.target.value)}
              size="small"
              sx={{ 
                height: '40px',
                border: '1px solid #e0e0e0',
                backgroundColor: 'var(--background-paper)',
                borderRadius: '8px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e0e0e0',
                  borderWidth: '1px',
                  borderStyle: 'solid'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#b0b0b0'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4a5df9',
                  borderWidth: '1px'
                },
                '& .MuiSelect-select': {
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  padding: '8px 14px'
                },
                '& .MuiSelect-icon': {
                  color: 'var(--text-secondary)'
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'var(--background-paper)',
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                    '& .MuiMenuItem-root': {
                      fontFamily: 'Urbanist, sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--background-secondary)'
                      }
                    }
                  }
                }
              }}
            >
              {projects.map((project) => (
                <MenuItem key={project} value={project}>{project}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Employee Filter */}
          <FormControl sx={{ minWidth: 150 }}>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 1,
                color: 'var(--text-secondary)',
                fontFamily: 'Urbanist, sans-serif',
                fontSize: '0.75rem',
                fontWeight: 600,
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
              }}
            >
              Employee
            </Typography>
            <Select
              value={employeeFilter}
              onChange={(e) => setEmployeeFilter(e.target.value)}
              size="small"
              sx={{ 
                height: '40px',
                backgroundColor: 'var(--background-paper)',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e0e0e0',
                  borderWidth: '1px',
                  borderStyle: 'solid'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--border-hover)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4a5df9'
                },
                '& .MuiSelect-select': {
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  padding: '8px 14px'
                },
                '& .MuiSelect-icon': {
                  color: 'var(--text-secondary)'
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'var(--background-paper)',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                    '& .MuiMenuItem-root': {
                      fontFamily: 'Urbanist, sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--background-secondary)'
                      }
                    }
                  }
                }
              }}
            >
              {employees.map((emp) => (
                <MenuItem key={emp} value={emp}>{emp}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Event Filter */}
          <FormControl sx={{ minWidth: 150 }}>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 1,
                color: 'var(--text-secondary)',
                fontFamily: 'Urbanist, sans-serif',
                fontSize: '0.75rem',
                fontWeight: 600,
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
              }}
            >
              Event
            </Typography>
            <Select
              value={eventFilter}
              onChange={(e) => setEventFilter(e.target.value)}
              size="small"
              sx={{ 
                height: '40px',
                backgroundColor: 'var(--background-paper)',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e0e0e0',
                  borderWidth: '1px',
                  borderStyle: 'solid'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--border-hover)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4a5df9'
                },
                '& .MuiSelect-select': {
                  fontFamily: 'Urbanist, sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                  padding: '8px 14px'
                },
                '& .MuiSelect-icon': {
                  color: 'var(--text-secondary)'
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'var(--background-paper)',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                    '& .MuiMenuItem-root': {
                      fontFamily: 'Urbanist, sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--background-secondary)'
                      }
                    }
                  }
                }
              }}
            >
              {events.map((event) => (
                <MenuItem key={event} value={event}>{event}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Loading State */}
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress />
        </div>
      ) : error ? (
        <div className={styles.errorContainer}>
          <Typography color="error">{error}</Typography>
        </div>
      ) : timesheets.length === 0 ? (
        <div className={styles.errorContainer}>
          <Typography>No timesheets found.</Typography>
        </div>
      ) : (
        /* Table Content */
        <div className={styles.timesheetGrid}>
          <div className={styles.header}>
            {headerCells.map((cell, index) => (
              <div key={index} className={styles.headerCell}>
                {cell.icon}
                {cell.label}
              </div>
            ))}
          </div>
          
          {filteredTimesheets.map((timesheet) => (
            <div key={timesheet.id} className={styles.row}>
              <div className={styles.cell}>
                <div className={styles.cellValue}>
                  {formatDateTime(timesheet.clock_in)}
                </div>
              </div>
              <div className={styles.cell} data-label="CLOCK OUT">
                {formatDateTime(timesheet.clock_out)}
              </div>
              <div className={styles.cell} data-label="HOURS WORKED">
                {timesheet.hours_worked}
              </div>
              <div className={styles.cell} data-label="PROJECT">
                {timesheet.event?.project_name || '-'}
              </div>
              <div className={styles.cell} data-label="EMPLOYEE">
                {timesheet.employee?.name || '-'}
              </div>
              <div className={styles.cell} data-label="EVENT">
                {timesheet.event?.title || '-'}
              </div>
              <div className={styles.cell} data-label="PAYMENT TYPE">
                {timesheet.payment_type || '-'}
              </div>
              <div className={styles.cell} data-label="RATE/PERCENTAGE">
                {timesheet.rate || '-'}
              </div>
              <div className={styles.cell} data-label="CALCULATED PAYMENT">
                {timesheet.calculated_payment || '-'}
              </div>
              <div className={styles.cell} data-label="ACTIONS">
                <IconButton 
                  onClick={() => handleViewDetails(timesheet.id)}
                  size="small"
                  sx={{ color: 'var(--text-secondary)' }}
                >
                  <EyeIcon size={20} weight="light" />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Timesheets;

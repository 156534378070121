import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Stack,
  IconButton,
  Tooltip
} from '@mui/material';
import { CaretUp, CaretDown } from "@phosphor-icons/react";
import { getClientEstimates } from '../../services/api';

const Estimates = ({ client }) => {
  const navigate = useNavigate();
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState('newest');

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        if (client?.id) {
          const data = await getClientEstimates(client.id);
          setEstimates(data);
        }
      } catch (error) {
        console.error('Error fetching estimates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEstimates();
  }, [client]);

  const calculateTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const price = Number(item.unitPrice) || 0;
      return sum + (quantity * price);
    }, 0);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'draft':
        return '#9CA3AF';
      case 'pending_client':
        return '#F59E0B';
      case 'completed':
        return '#10B981';
      default:
        return '#9CA3AF';
    }
  };

  const formatStatus = (status) => {
    if (!status) return 'Draft';
    return status.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  const handleRowClick = (estimateId) => {
    navigate(`/admin/estimates/${estimateId}`);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-default)' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>
                Estimate #
              </TableCell>
              <TableCell 
                sx={{ 
                  color: 'var(--text-primary)', 
                  borderBottom: '1px solid var(--divider)',
                  cursor: 'pointer',
                  fontFamily: "'Urbanist', sans-serif",
                  fontWeight: 500
                }}
                onClick={() => setSort(sort === 'newest' ? 'oldest' : 'newest')}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Box component="span">Date</Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, lineHeight: 0 }}>
                    <CaretUp 
                      size={12}
                      weight="bold"
                      style={{ 
                        color: sort === 'oldest' ? 'var(--primary-main)' : 'var(--text-disabled)'
                      }} 
                    />
                    <CaretDown 
                      size={12}
                      weight="bold"
                      style={{ 
                        marginTop: '-4px',
                        color: sort === 'newest' ? 'var(--primary-main)' : 'var(--text-disabled)'
                      }} 
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }}>
                Project
              </TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500
              }} align="right">
                Amount
              </TableCell>
              <TableCell sx={{ 
                color: 'var(--text-primary)', 
                borderBottom: '1px solid var(--divider)',
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 500,
                paddingLeft: 4,
                textAlign: 'center'
              }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>Loading...</TableCell>
              </TableRow>
            ) : estimates.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ fontFamily: "'Urbanist', sans-serif" }}>No estimates found</TableCell>
              </TableRow>
            ) : (
              [...estimates]
                .sort((a, b) => {
                  const dateA = new Date(a.issue_date);
                  const dateB = new Date(b.issue_date);
                  return sort === 'newest' ? dateB - dateA : dateA - dateB;
                })
                .map((estimate) => (
                  <TableRow 
                    key={estimate.id}
                    hover
                    onClick={() => handleRowClick(estimate.id)}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      },
                      '& .MuiTableCell-root': {
                        color: 'var(--text-primary)',
                        borderBottom: '1px solid var(--divider)',
                        fontFamily: "'Urbanist', sans-serif"
                      }
                    }}
                  >
                    <TableCell>{estimate.estimate_number}</TableCell>
                    <TableCell>
                      {estimate.issue_date ? new Date(estimate.issue_date).toLocaleDateString() : '-'}
                    </TableCell>
                    <TableCell>{estimate.project_name || '-'}</TableCell>
                    <TableCell align="right">
                      ${calculateTotal(estimate.items).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                    <TableCell sx={{ 
                      paddingLeft: 4,
                      textAlign: 'center'
                    }}>
                      <Chip 
                        label={formatStatus(estimate.status)}
                        sx={{
                          backgroundColor: `${getStatusColor(estimate.status)}20`,
                          color: getStatusColor(estimate.status),
                          fontWeight: 500,
                          fontSize: '0.75rem',
                          fontFamily: "'Urbanist', sans-serif",
                          minWidth: '100px'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Estimates; 
import React, { useState, useEffect, useCallback, useRef, useMemo, useLayoutEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button, Menu, Chip, IconButton, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions,
  ToggleButton, ToggleButtonGroup
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  Files as FilesIcon,
  CurrencyDollar as CurrencyDollarIcon,
  Buildings as BuildingsIcon,
  Calendar as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Clock as ClockIcon,
  Article as ArticleIcon,
  Money as MoneyIcon,
  Timer as TimerIcon,
  Copy as CopyIcon,
  Trash as TrashIcon,
  CaretLeft as CaretLeftIcon,
  CaretRight as CaretRightIcon,
  CaretUp as CaretUpIcon,
  CaretDown as CaretDownIcon,
  Plus as PlusIcon,
  User as UserIcon,
  DotsThree as DotsThreeIcon,
  MagnifyingGlass as MagnifyingGlassIcon,
  ListChecks
} from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';
import styles from './AdminEstimates.module.scss';
import { getEstimates, getBusinessDetails, duplicateEstimate, deleteEstimate } from '../services/api';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import CustomToggle from './common/CustomToggle';
import SearchContainer from './common/SearchContainer';

const STATUS_STYLES = {
  'approved': {
    color: '#2E7D32',  // Green
    backgroundColor: '#E8F5E9'
  },
  'draft': {
    color: '#303F9F',  // Indigo
    backgroundColor: '#E8EAF6'
  },
  'pending': {
    color: '#E65100',  // Orange
    backgroundColor: '#FFF3E0'
  },
  'converted': {
    color: '#1565C0',  // Blue
    backgroundColor: '#E3F2FD'
  },
  'completed': {
    color: '#2E7D32',  // Green (same as approved)
    backgroundColor: '#E8F5E9'  // Light green background
  }
};

const slide = keyframes`
  0% {
    transform: translateX(var(--initial-offset));
    width: var(--initial-width);
  }
  100% {
    transform: translateX(var(--target-offset));
    width: var(--target-width);
  }
`;

const AdminEstimates = () => {
  const navigate = useNavigate();

  const [estimates, setEstimates] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientFilter, setClientFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clients, setClients] = useState(['All']);
  const [statuses] = useState(['All', 'Draft', 'Pending Approval', 'Approved', 'Converted']);
  const [estimate, setEstimate] = useState({
    businessDetails: {},
    customerDetails: {},
    estimateNumber: '',
    issueDate: new Date(),
    validUntil: new Date(new Date().setDate(new Date().getDate() + 30)),
    items: [],
    notes: '',
    logo: null,
    logoSize: 100,
    scopeOfWork: '',
    pictures: [],
    template: 'default',
  });
  const [activeFilter, setActiveFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const [sort, setSort] = useState('newest');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('All Customers');
  const [filter, setFilter] = useState('all');
  const [pendingEstimates, setPendingEstimates] = useState([]);
  const [draftEstimates, setDraftEstimates] = useState([]);
  const [pendingValue, setPendingValue] = useState(0);
  const [draftValue, setDraftValue] = useState(0);
  const [totalEstimatesValue, setTotalEstimatesValue] = useState(0);
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedEstimateId, setSelectedEstimateId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successAction, setSuccessAction] = useState('');
  const [customerFilter, setCustomerFilter] = useState('All');
  const [projectFilter, setProjectFilter] = useState('All');
  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);
  const [projectAnchorEl, setProjectAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [projectSort, setProjectSort] = useState(null); // null, 'asc', or 'desc'
  const [customerSort, setCustomerSort] = useState(null); // null, 'asc', or 'desc'
  const [statusSort, setStatusSort] = useState(null); // null, 'draft', 'pending', 'approved', 'converted'
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const containerRef = useRef(null);
  const [highlightWidth, setHighlightWidth] = useState(0);
  const buttonRefs = useRef({});
  const animationRef = useRef({ offset: 0, width: 0, animation: '' });

  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'draft', label: 'Draft' },
    { value: 'pending', label: 'Pending' },
    { value: 'completed', label: 'Completed' },
    { value: 'converted', label: 'Converted' }
  ];

  const checkScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  }, []);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = 200;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [checkScroll]);

  const fetchBusinessDetails = useCallback(async () => {
    console.log('Fetching business details...');
    try {
      const details = await getBusinessDetails();
      console.log('Fetched business details:', details);
      setEstimate(prevEstimate => ({
        ...prevEstimate,
        businessDetails: details
      }));
    } catch (error) {
      console.error('Error fetching business details:', error);
      // Fallback to empty object if fetch fails
      setEstimate(prevEstimate => ({
        ...prevEstimate,
        businessDetails: {}
      }));
    }
  }, []);

  useEffect(() => {
    fetchBusinessDetails();
  }, [fetchBusinessDetails]);

  const fetchEstimates = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getEstimates();
      console.log('Fetched estimates:', data);
      if (data && Array.isArray(data)) {
        setEstimates(data);
        const uniqueClients = [...new Set(data.map(est => 
          est.client?.company || 
          est.customerDetails?.company || 
          est.customer_details?.company ||
          est.customer?.company
        ).filter(Boolean))];
        setClients(['All', ...uniqueClients]);
      } else {
        console.log('Invalid data structure');
        setEstimates([]);
        setClients(['All']);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching estimates:', err);
      setError('Failed to fetch estimates. Please try again later.');
      setEstimates([]);
      setClients(['All']);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEstimates();
  }, [fetchEstimates]);

  useEffect(() => {
    if (estimates.length > 0) {
      const sorted = [...estimates].sort((a, b) => 
        new Date(b.issue_date) - new Date(a.issue_date)
      );
      setEstimates(sorted);
    }
  }, [estimates.length]);

  const calculateEstimateTotal = (items) => {
    if (!Array.isArray(items)) return 0;
    
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const unitPrice = Number(item.unit_price || item.unitPrice) || 0;
      const tax = Number(item.tax) || 0;
      
      const itemTotal = quantity * unitPrice * (1 + tax/100);
      return sum + itemTotal;
    }, 0);
  };

  const getClientName = (estimate) => {
    // First try to get company name
    const companyName = estimate.client?.company || 
        estimate.customerDetails?.company ||
        estimate.customer_details?.company ||
        estimate.customer?.company;

    // If no company name, try to get client/customer name
    const clientName = estimate.client?.name || 
        estimate.customerDetails?.name ||
        estimate.customer_details?.name ||
        estimate.customer?.name;

    // Return company name if it exists, otherwise return client name, or 'N/A' as last resort
    return companyName || clientName || 'N/A';
  };

  const filteredEstimates = useMemo(() => {
    return estimates.filter(estimate => {
      const matchesSearch = (estimate.project_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getClientName(estimate).toLowerCase().includes(searchTerm.toLowerCase()) ||
        estimate.estimate_number?.toString().includes(searchTerm));

      if (selectedStatus === 'all') return matchesSearch;
      return matchesSearch && estimate.status?.toLowerCase() === selectedStatus;
    });
  }, [estimates, searchTerm, selectedStatus]);

  const totalEstimateValue = filteredEstimates.reduce((sum, estimate) => 
    sum + calculateEstimateTotal(estimate.items), 0);
    
  const averageEstimateValue = filteredEstimates.length > 0 
    ? totalEstimateValue / filteredEstimates.length 
    : 0;

  const formatCurrency = (value) => {
    const numValue = parseFloat(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterMenuOpen(true);
  };

  useEffect(() => {
    if (estimates && Array.isArray(estimates)) {
      setPendingEstimates(estimates.filter(est => est.status === 'Pending'));
      setDraftEstimates(estimates.filter(est => est.status === 'Draft'));
    }
  }, [estimates]);

  useEffect(() => {
    if (estimates && Array.isArray(estimates)) {
      // Calculate pending value
      const pendingTotal = estimates
        .filter(est => est.status === 'Pending')
        .reduce((sum, est) => sum + calculateTotal(est), 0);
      setPendingValue(pendingTotal);

      // Calculate draft value
      const draftTotal = estimates
        .filter(est => est.status === 'Draft')
        .reduce((sum, est) => sum + calculateTotal(est), 0);
      setDraftValue(draftTotal);

      // Calculate total estimates value
      const total = estimates.reduce((sum, est) => sum + calculateTotal(est), 0);
      setTotalEstimatesValue(total);

      // Calculate average response time (example calculation)
      const avgTime = calculateAverageResponseTime(estimates);
      setAverageResponseTime(avgTime);
    }
  }, [estimates]);

  const calculateTotal = (estimate) => {
    if (!estimate.items || !Array.isArray(estimate.items)) return 0;
    return estimate.items.reduce((sum, item) => sum + (Number(item.total) || 0), 0);
  };

  const calculateAverageResponseTime = (estimates) => {
    const respondedEstimates = estimates.filter(est => est.response_date);
    if (!respondedEstimates.length) return 0;

    const totalDays = respondedEstimates.reduce((sum, est) => {
      const created = new Date(est.created_at);
      const responded = new Date(est.response_date);
      const days = Math.floor((responded - created) / (1000 * 60 * 60 * 24));
      return sum + days;
    }, 0);

    return Math.round(totalDays / respondedEstimates.length);
  };



  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    switch (status.toLowerCase()) {
      case 'completed': return 'success';
      case 'draft': return 'default';
      case 'pending': return 'warning';
      case 'converted': return 'info';
      default: return 'default';
    }
  };

  const formatStatus = (status) => {
    if (!status) return 'Draft';
    
    switch (status.toLowerCase()) {
      case 'draft': return 'Draft';
      case 'pending': return 'Pending Approval';
      case 'completed': return 'Completed';
      case 'converted': return 'Converted';
      default: return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }
  };

  const handleMenuClick = (event, estimateId) => {
    event.stopPropagation(); // Prevent row click
    setMenuAnchorEl(event.currentTarget);
    setSelectedEstimateId(estimateId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedEstimateId(null);
  };

  const handleDuplicate = async (event) => {
    event.stopPropagation();
    try {
      const response = await duplicateEstimate(selectedEstimateId);
      fetchEstimates();
      handleMenuClose();
      setSuccessMessage('Estimate duplicated successfully');
      setSuccessAction('duplicate');
      setShowSuccessModal(true);
      
      setTimeout(() => {
        setShowSuccessModal(false);
        navigate(`/admin/estimates/${response.estimate.id}`);
      }, 1500);
    } catch (error) {
      setError('Failed to duplicate estimate');
    }
  };

  const handleDelete = async (event) => {
    event.stopPropagation();
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteEstimate(selectedEstimateId);
      fetchEstimates();
      setShowDeleteModal(false);
      handleMenuClose();
      setSuccessMessage('Estimate deleted successfully');
      setSuccessAction('delete');
      setShowSuccessModal(true);
      
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 1500);
    } catch (error) {
      setError('Failed to delete estimate');
    }
  };

  const handleCustomerFilterClick = (event) => {
    setCustomerAnchorEl(event.currentTarget);
  };

  const handleProjectFilterClick = (event) => {
    setProjectAnchorEl(event.currentTarget);
  };

  const handleCustomerFilterClose = (value) => {
    if (value) setCustomerFilter(value);
    setCustomerAnchorEl(null);
  };

  const handleProjectFilterClose = (value) => {
    if (value) setProjectFilter(value);
    setProjectAnchorEl(null);
  };

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusClose = (status) => {
    if (status) setActiveFilter(status);
    setStatusAnchorEl(null);
  };

  const uniqueCustomers = ['All', ...new Set(estimates.map(est => getClientName(est)))].sort((a, b) => {
    if (a === 'All') return -1;  // Keep 'All' at the top
    if (b === 'All') return 1;   // Keep 'All' at the top
    return a.localeCompare(b);   // Sort rest alphabetically
  });

  const uniqueProjects = ['All', ...new Set(estimates.filter(est => est.project_name).map(est => est.project_name))].sort((a, b) => {
    if (a === 'All') return -1;  // Keep 'All' at the top
    if (b === 'All') return 1;   // Keep 'All' at the top
    return a.localeCompare(b);   // Sort rest alphabetically
  });

  // Update the useEffect for initial setup
  useEffect(() => {
    const button = buttonRefs.current['all']; // or whatever your initial value is
    const container = containerRef.current?.querySelector('[role="group"]');
    
    if (button && container) {
      const offset = button.offsetLeft;
      const width = button.offsetWidth;
      
      // Set initial values immediately
      container.style.setProperty('--highlight-offset', `${offset}px`);
      container.style.setProperty('--highlight-width', `${width}px`);
    }
  }, []); // Empty dependency array to run only once on mount

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  // Update the useEffect to set initial highlight position and size
  useEffect(() => {
    const button = buttonRefs.current[selectedStatus];
    const container = containerRef.current?.querySelector('[role="group"]');
    
    if (button && container) {
      const offset = button.offsetLeft;
      const width = button.offsetWidth;
      
      container.style.setProperty('--highlight-offset', `${offset}px`);
      container.style.setProperty('--highlight-width', `${width}px`);
    }
  }, [selectedStatus]);

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  const headerCells = [
    { 
      label: 'PROJECT', 
      align: 'center',
      icon: <BuildingsIcon size={20} weight="light" style={{ color: '#2065D1', marginRight: 8 }} />,
      sortable: true,
      onClick: () => {
        setProjectSort(projectSort === 'asc' ? 'desc' : 'asc');
        setCustomerSort(null);
        setSort(null);
      },
      sortDirection: projectSort
    },
    { 
      label: 'CUSTOMER', 
      align: 'center',
      icon: <UserIcon size={20} weight="light" style={{ color: '#FF4842', marginRight: 8 }} />,
      sortable: true,
      onClick: () => {
        setCustomerSort(customerSort === 'asc' ? 'desc' : 'asc');
        setProjectSort(null);
        setSort(null);
      },
      sortDirection: customerSort
    },
    { 
      label: 'NUMBER', 
      align: 'center',
      icon: <FilesIcon size={20} weight="light" style={{ color: '#2065D1', marginRight: 8 }} />
    },
    { 
      label: 'ISSUE DATE', 
      align: 'center',
      icon: <CalendarIcon size={20} weight="light" style={{ color: '#FFC107', marginRight: 8 }} />,
      sortable: true,
      onClick: () => {
        setSort(sort === 'newest' ? 'oldest' : 'newest');
        setProjectSort(null);
        setCustomerSort(null);
      },
      sortDirection: sort
    },
    { 
      label: 'TOTAL', 
      align: 'center',
      icon: <CurrencyDollarIcon size={20} weight="light" style={{ color: '#00AB55', marginRight: 8 }} />
    },
    { 
      label: 'STATUS', 
      align: 'center',
      icon: <CheckCircleIcon size={20} weight="light" style={{ color: '#00AB55', marginRight: 8 }} />,
      sortable: true,
      onClick: () => {
        // Cycle through status orders
        switch (statusSort) {
          case null:
            setStatusSort('draft');
            break;
          case 'draft':
            setStatusSort('pending');
            break;
          case 'pending':
            setStatusSort('completed');
            break;
          case 'completed':
            setStatusSort('converted');
            break;
          case 'converted':
            setStatusSort(null);
            break;
          default:
            setStatusSort(null);
        }
        setProjectSort(null);
        setCustomerSort(null);
        setSort(null);
      },
      sortDirection: statusSort
    },
    { 
      label: '', 
      align: 'center',
      icon: null
    },
  ];

  console.log('Rendered estimates:', estimates); // Keep this log for debugging

  const sortedEstimates = [...filteredEstimates].sort((a, b) => {
    // Status sorting
    if (statusSort) {
      const statusOrder = ['draft', 'pending', 'completed', 'converted'];
      const statusA = (a.status || '').toLowerCase();
      const statusB = (b.status || '').toLowerCase();
      
      // Get index from statusOrder array
      const indexA = statusOrder.indexOf(statusA);
      const indexB = statusOrder.indexOf(statusB);
      
      // Sort based on status order
      if (indexA !== indexB) {
        return indexA - indexB;
      }
    }
    
    // Project sorting
    if (projectSort) {
      const projectA = (a.project_name || '').toLowerCase();
      const projectB = (b.project_name || '').toLowerCase();
      if (!projectA && !projectB) return 0;
      if (!projectA) return 1;
      if (!projectB) return -1;
      return projectSort === 'asc' 
        ? projectA.localeCompare(projectB)
        : projectB.localeCompare(projectA);
    }
    
    // Customer sorting
    if (customerSort) {
      const clientA = getClientName(a).toLowerCase();
      const clientB = getClientName(b).toLowerCase();
      return customerSort === 'asc'
        ? clientA.localeCompare(clientB)
        : clientB.localeCompare(clientA);
    }

    // Date sorting
    const dateA = new Date(a.issue_date);
    const dateB = new Date(b.issue_date);
    return sort === 'newest' ? dateB - dateA : dateA - dateB;
  });

  const getStatusArrowColor = (status, direction) => {
    if (!status) return 'text.disabled';
    
    const colors = {
      draft: '#637381',      // gray
      pending: '#fda92d',    // yellow
      completed: '#54d62c',   // green
      converted: '#00b8d9'   // blue
    };

    return colors[status] || 'text.disabled';
  };

  const menuItems = [
    {
      label: 'Duplicate',
      icon: <CopyIcon size={20} weight="light" />,
      onClick: handleDuplicate
    },
    {
      label: 'Delete',
      icon: <TrashIcon size={20} weight="light" />,
      onClick: handleDelete
    }
  ];

  return (
    <div className={styles.estimatesContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h5" className={styles.title}>Estimates</Typography>
        <div className={styles.buttonGroup}>
          <span className={styles.separator}></span>
          <Button
            variant="contained"
            color="primary"
            disableRipple
            startIcon={<PlusIcon />}
            onClick={() => navigate('/admin/estimates/create')}
            className={styles.createButton}
            sx={{
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3c50fa !important',
                boxShadow: 'none !important',
              },
              '&.MuiButton-contained:hover': {
                backgroundColor: '#3c50fa',
                boxShadow: 'none',
              }
            }}
          >
            Create Estimate
          </Button>
        </div>
      </Box>

      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={3}>
        <Box display="flex" gap={2} alignItems="center">
          <div className={styles.sliderContainer}>
            <div
              ref={containerRef}
              className={styles.toggleContainer}
              onScroll={checkScroll}
            >
              <CustomToggle
                options={statusOptions}
                value={selectedStatus}
                onChange={handleStatusChange}
              />
            </div>
            {canScrollRight && (
              <IconButton 
                className={styles.scrollButton}
                onClick={() => handleScroll('right')}
                size="small"
              >
                <CaretRightIcon size={20} weight="bold" />
              </IconButton>
            )}
          </div>
        </Box>
      </Box>

      <Box mb={3}>
        <SearchContainer
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search estimates..."
        />
      </Box>

      <TableContainer className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headerCells.map((cell, index) => (
                <TableCell 
                  key={index} 
                  align="center"
                  className={styles.tableHeaderCell}
                  onClick={cell.onClick}
                  style={{ 
                    cursor: cell.sortable ? 'pointer' : 'default',
                    backgroundColor: 'var(--background-secondary)',
                    borderBottom: '1px solid #e0e0e0',
                    fontFamily: 'Urbanist, sans-serif',
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    color: 'var(--text-secondary)!important',
                    letterSpacing: '0.5px',
                    padding: '12px 16px',
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {cell.icon}
                    <Typography 
                      sx={{ 
                        ml: 1,
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        letterSpacing: '0.5px',
                        textTransform: 'uppercase',
                        color: 'var(--text-secondary)',
                        fontFamily: 'Urbanist, sans-serif'
                      }}
                    >
                      {cell.label}
                    </Typography>
                    {cell.sortable && (
                      <Box display="flex" flexDirection="column" ml={1} sx={{ lineHeight: 0 }}>
                        <CaretUpIcon 
                          size={12}
                          weight="bold"
                          style={{ 
                            color: cell.label === 'STATUS' 
                              ? getStatusArrowColor(statusSort, 'up')
                              : (cell.sortDirection === 'asc' || cell.sortDirection === 'oldest') 
                                ? 'var(--primary-main)'
                                : 'var(--text-disabled)'
                          }} 
                        />
                        <CaretDownIcon 
                          size={12}
                          weight="bold"
                          style={{ 
                            marginTop: '-4px',
                            color: cell.label === 'STATUS' 
                              ? getStatusArrowColor(statusSort, 'down')
                              : (cell.sortDirection === 'desc' || cell.sortDirection === 'newest') 
                                ? 'var(--primary-main)'
                                : 'var(--text-disabled)'
                          }} 
                        />
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEstimates.map((estimate) => {
              const clientName = getClientName(estimate);
              const projectName = estimate.project_name;

              return (
                <TableRow 
                  key={estimate.id} 
                  className={styles.tableRow} 
                  onClick={() => navigate(`/admin/estimates/${estimate.id}`)}
                  hover
                >
                  <TableCell className={styles.tableCell} align="center">
                    {projectName}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {clientName}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    #{estimate.estimate_number}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {new Date(estimate.issue_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    ${formatCurrency(calculateEstimateTotal(estimate.items))}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    <div 
                      className={styles.statusChip}
                      style={{
                        color: STATUS_STYLES[estimate.status?.toLowerCase() || 'draft']?.color,
                        backgroundColor: STATUS_STYLES[estimate.status?.toLowerCase() || 'draft']?.backgroundColor,
                        padding: '4px 8px',
                        borderRadius: '6px',
                        fontSize: '0.75rem',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        letterSpacing: '0.5px',
                        display: 'inline-block'
                      }}
                    >
                      {formatStatus(estimate.status || 'Draft')}
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    <IconButton
                      onClick={(e) => handleMenuClick(e, estimate.id)}
                      size="small"
                      sx={{ 
                        '&:hover': { 
                          backgroundColor: 'rgba(0, 0, 0, 0.04)' 
                        } 
                      }}
                    >
                      <DotsThreeIcon size={20} weight="bold" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '12px',
            maxWidth: '400px'
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          fontFamily: 'Urbanist, sans-serif',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#2c3e50'
        }}>
          Delete Estimate
        </DialogTitle>
        <DialogContent>
          <Typography 
            variant="body1" 
            sx={{ 
              mb: 2,
              fontFamily: 'Urbanist, sans-serif',
              color: '#475569'
            }}
          >
            Are you sure you want to delete this estimate? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: '16px' }}>
          <Button
            onClick={() => setShowDeleteModal(false)}
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              textTransform: 'none',
              color: '#64748b'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#dc2626'
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showSuccessModal} 
        onClose={() => {}}  // Disable closing on backdrop click
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: { borderRadius: '12px' }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <DialogTitle sx={{ 
            backgroundColor: successAction === 'delete' ? '#e11d48' : '#4caf50', 
            color: 'white',
            textAlign: 'center',
            pb: 2,
            fontFamily: 'Urbanist, sans-serif'
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 1
            }}>
              {successAction === 'delete' ? (
                <TrashIcon 
                  size={48}
                  weight="light"
                  style={{ 
                    animation: 'fadeInScale 0.5s ease-in-out',
                  }} 
                />
              ) : (
                <CopyIcon 
                  size={48}
                  weight="light"
                  style={{ 
                    animation: 'fadeInScale 0.5s ease-in-out',
                  }} 
                />
              )}
              {successMessage}
            </Box>
          </DialogTitle>
        </Box>
      </Dialog>

      <Menu
        anchorEl={customerAnchorEl}
        open={Boolean(customerAnchorEl)}
        onClose={() => handleCustomerFilterClose()}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: 300,
            width: 200,
            overflow: 'auto'
          }
        }}
      >
        {uniqueCustomers.map((customer) => (
          <MenuItem 
            key={customer}
            onClick={() => handleCustomerFilterClose(customer)}
            selected={customerFilter === customer}
          >
            {customer}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={projectAnchorEl}
        open={Boolean(projectAnchorEl)}
        onClose={() => handleProjectFilterClose()}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: 300,
            width: 200,
            overflow: 'auto'
          }
        }}
      >
        {uniqueProjects.map((project) => (
          <MenuItem 
            key={project}
            onClick={() => handleProjectFilterClose(project)}
            selected={projectFilter === project}
          >
            {project}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            mt: 1,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1,
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }
          }
        }}
      >
        {menuItems.map((item) => (
          <MenuItem 
            key={item.label}
            onClick={item.onClick}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default React.memo(AdminEstimates);
  
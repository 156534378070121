import React, { useState, useEffect, useRef } from 'react';
import { Badge, Popover, List, ListItem, ListItemText, Typography, Box, Fab, IconButton, ListItemIcon, Tooltip } from '@mui/material';
import { Bell } from "@phosphor-icons/react";
import { getNotifications, getUnreadNotificationCount, markNotificationAsRead, markAllNotificationsAsRead } from '../services/api';
import { formatDateTime } from '../utils/dateUtils';
import { useAuth } from '../components/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext';

const NotificationBell = ({ onNotificationsChange, onAnchorChange, isOpen }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    notifications: [],
    unreadCount: 0,
    previousCount: 0,
    currentTime: new Date()
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const intervalRef = useRef(null);
  const timeUpdateRef = useRef(null);
  const audioRef = useRef(new Audio('/sounds/notification.mp3'));
  const [isMuted, setIsMuted] = useState(false);
  const { darkMode } = useTheme();

  const fetchNotifications = async () => {
    try {
      const [notifs, count] = await Promise.all([
        getNotifications(),
        getUnreadNotificationCount()
      ]);

      setState(prev => ({
        ...prev,
        notifications: notifs,
        unreadCount: count,
        previousCount: prev.unreadCount
      }));

      // Play sound only if needed
      if (count > state.previousCount && !isMuted && !audioRef.current.hasPlayed) {
        audioRef.current.currentTime = 0;
        audioRef.current.playbackRate = 1.5;
        audioRef.current.hasPlayed = true;
        audioRef.current.play().catch(console.error);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const formatNotificationTime = (timestamp) => {
    try {
      const date = new Date(timestamp + 'Z');
      const diffInSeconds = Math.floor((state.currentTime - date) / 1000);
      
      // Less than a minute
      if (diffInSeconds < 60) {
        return 'Just now';
      }
      
      // Less than an hour
      if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      }
      
      // Less than a day
      if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      }
      
      // Less than a week
      if (diffInSeconds < 604800) {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
      }
      
      // More than a week, use the formatted date
      return formatDateTime(timestamp);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  useEffect(() => {
    if (isAuthenticated && !location.pathname.startsWith('/public/')) {
      fetchNotifications();
      
      // Set up polling with a single interval
      const interval = setInterval(() => {
        fetchNotifications();
        setState(prev => ({ ...prev, currentTime: new Date() }));
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [isAuthenticated, location.pathname]);

  useEffect(() => {
    audioRef.current.volume = 0.5;
    audioRef.current.preload = 'auto';
    audioRef.current.playing = false;
    audioRef.current.playbackRate = 1.5;
    
    const audio = audioRef.current;
    audio.load();
    
    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.playing = false;
    };
  }, []);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onAnchorChange?.(event.currentTarget);
    fetchNotifications();
    setState(prev => ({ ...prev, currentTime: new Date() }));
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
    onAnchorChange?.(null);
  };

  const fetchUnreadCount = async () => {
    try {
      const count = await getUnreadNotificationCount();
      setState(prev => ({
        ...prev,
        unreadCount: count,
        previousCount: prev.unreadCount
      }));
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      await markNotificationAsRead(notification.id);
      fetchNotifications();
      
      if (notification.link) {
        const cleanPath = notification.link.replace(/^\/+/, '').replace(/\/+/g, '/');
        navigate(`/${cleanPath}`);
        handleClose();
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleMarkAllAsRead = async (event) => {
    event.stopPropagation();
    try {
      await markAllNotificationsAsRead();
      fetchNotifications();
    } catch (error) {
      console.error('Error marking all as read:', error);
    }
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    onNotificationsChange?.({
      notifications: state.notifications,
      unreadCount: state.unreadCount
    });
  }, [state.notifications, state.unreadCount]);

  if (!isAuthenticated || 
      location.pathname === '/login' || 
      location.pathname.startsWith('/public/') || 
      location.pathname.startsWith('/estimate/book/')) {
    return null;
  }

  return (
    <>
      <Box
        onClick={handleClick}
        role="button"
        className="notification-bell-trigger"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        <Badge 
          badgeContent={state.unreadCount} 
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              fontSize: '0.65rem',
              height: '16px',
              minWidth: '16px',
              top: 2,
              right: 2,
            }
          }}
        >
          <Bell 
            size={22}
            weight="thin"
            style={{ 
              color: darkMode ? 'var(--text-primary)' : '#637381',
            }} 
          />
        </Badge>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        slotProps={{
          paper: {
            onClick: (e) => e.stopPropagation()
          }
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: 300,
            maxHeight: 400,
            overflow: 'auto',
            borderRadius: '8px',
            boxShadow: darkMode 
              ? '0 4px 20px rgba(0,0,0,0.3)' 
              : '0 4px 20px rgba(0,0,0,0.15)',
            backgroundColor: darkMode ? 'var(--background-paper)' : 'white',
            border: darkMode ? '1px solid var(--border-color)' : 'none',
            marginLeft: 1,
            left: `${isOpen ? '240px' : '50px'} !important`, // Force the left position
            transform: 'none !important', // Prevent MUI's transform
          }
        }}
      >
        <Box sx={{ width: 300, maxHeight: 400, overflow: 'auto' }}>
          <Box 
            sx={{ 
              p: 2, 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              borderBottom: 1, 
              borderColor: darkMode ? 'var(--border-color)' : 'divider',
              bgcolor: darkMode ? 'var(--background-paper)' : 'white'
            }}
          >
            <Typography 
              variant="h6"
              sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}
            >
              Notifications
            </Typography>
            {state.notifications.length > 0 && (
              <Typography
                variant="body2"
                sx={{ 
                  cursor: 'pointer', 
                  color: darkMode ? '#90caf9' : 'primary.main',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
                onClick={handleMarkAllAsRead}
              >
                Mark all as read
              </Typography>
            )}
          </Box>
          
          <List sx={{ bgcolor: darkMode ? 'var(--background-paper)' : 'white' }}>
            {state.notifications.length === 0 ? (
              <ListItem>
                <ListItemText 
                  primary={
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        textAlign: 'center', 
                        color: darkMode ? 'var(--text-secondary)' : 'text.secondary' 
                      }}
                    >
                      No notifications
                    </Typography>
                  } 
                />
              </ListItem>
            ) : (
              state.notifications.map((notification) => (
                <ListItem
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification)}
                  sx={{
                    cursor: 'pointer',
                    bgcolor: notification.read 
                      ? 'transparent' 
                      : darkMode ? 'rgba(255, 255, 255, 0.05)' : 'action.hover',
                    '&:hover': { 
                      bgcolor: darkMode ? 'var(--background-hover)' : 'action.selected',
                      transition: 'background-color 0.2s ease'
                    },
                    borderBottom: '1px solid',
                    borderColor: darkMode ? 'var(--border-color)' : 'divider'
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          fontWeight: notification.read ? 400 : 600,
                          color: darkMode ? 'var(--text-primary)' : 'inherit'
                        }}
                      >
                        {notification.title}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography 
                          variant="body2" 
                          component="span"
                          sx={{ 
                            display: 'block',
                            color: notification.read 
                              ? darkMode ? 'var(--text-secondary)' : 'text.secondary'
                              : darkMode ? 'var(--text-primary)' : 'text.primary'
                          }}
                        >
                          {notification.message}
                        </Typography>
                        <Typography 
                          variant="caption" 
                          display="block" 
                          sx={{ 
                            color: darkMode ? 'var(--text-secondary)' : 'text.secondary',
                            mt: 0.5
                          }}
                        >
                          {formatNotificationTime(notification.created_at)}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationBell; 
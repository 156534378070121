import React from 'react';
import { Paper, Box, Button } from '@mui/material';
import SectionHeader from './SectionHeader';
import BaseAutocomplete from './shared/BaseAutocomplete';
import AddIcon from '@mui/icons-material/Add';

const ClientInformationForm = ({ clients, selectedClient, handleClientSelect, goToCreateClient }) => {
  // Handle client selection with proper state updates
  const onClientSelect = (event, newValue) => {
    console.log('Selected client:', newValue);
    handleClientSelect(event, newValue);
  };

  return (
    <Paper sx={{boxShadow: 'none !important' }}>
      <Box sx={{ width: '100%' }}>
        <BaseAutocomplete
          options={clients}
          getOptionLabel={(option) => option?.name || option?.customer_name || ''}
          onChange={onClientSelect}
          value={selectedClient}
          label="Select Existing Client"
          clearable={true}
          PaperComponent={({ children, ...props }) => (
            <Paper {...props}>
              <div style={{ maxHeight: '40vh', overflow: 'auto' }}>
                {children}
              </div>
              <div
                style={{
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  padding: '8px',
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fff',
                  zIndex: 1000
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <Button
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={goToCreateClient}
                  onMouseDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  sx={{
                    color: 'primary.main',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.04)'
                    }
                  }}
                >
                  Add New Customer
                </Button>
              </div>
            </Paper>
          )}
        />
      </Box>
    </Paper>
  );
};

export default ClientInformationForm; 
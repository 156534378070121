import React, { useState } from 'react';
import { 
  SpeedDial, 
  SpeedDialAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button
} from '@mui/material';
import { 
  Plus as AddIcon,
  User as PersonIcon,
  Buildings as BusinessIcon,
  ClipboardText as AssignmentIcon,
  Receipt as RequestQuoteIcon,
  Question as HelpIcon,
  FileText as InvoiceIcon,
  Wrench as ProjectIcon,
  Users as ClientIcon,
  Gear as SettingsIcon
} from "@phosphor-icons/react";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext';

const CreateButton = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { darkMode } = useTheme();

  const actions = [
    { 
      icon: <HelpIcon size={24} weight="light" style={{ color: darkMode ? '#90caf9' : '#2196f3' }} />,
      name: 'Help Center', 
      onClick: () => window.open('https://servtrackr.featurebase.app', '_blank')
    },
    { 
      icon: <AssignmentIcon size={24} weight="light" style={{ color: darkMode ? '#90caf9' : '#2196f3' }} />,
      name: 'Create Project', 
      route: '/admin/projects/create' 
    },
    { 
      icon: <RequestQuoteIcon size={24} weight="light" style={{ color: darkMode ? '#66bb6a' : '#4caf50' }} />,
      name: 'Create Estimate', 
      route: '/admin/estimates/create' 
    },
    { 
      icon: <PersonIcon size={24} weight="light" style={{ color: darkMode ? '#ffb74d' : '#ff9800' }} />,
      name: 'Create Employee', 
      route: '/admin/create-employee' 
    },
    { 
      icon: <BusinessIcon size={24} weight="light" style={{ color: darkMode ? '#ef5350' : '#f44336' }} />,
      name: 'Create Client', 
      route: '/admin/create-client' 
    }
  ];

  return (
    <SpeedDial
      ariaLabel="Create Menu"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
        '& .MuiSpeedDial-fab': {
          bgcolor: darkMode ? 'var(--background-paper)' : 'white',
          '&:hover': {
            bgcolor: darkMode ? '#2f3a46' : '#f5f5f5',
          },
          border: darkMode ? '1px solid var(--border-color)' : 'none',
          boxShadow: darkMode ? '0 4px 20px rgba(0,0,0,0.3)' : '0 4px 20px rgba(0,0,0,0.15)',
        },
        '& .MuiSpeedDial-actions': {
          '& .MuiFab-root': {
            bgcolor: darkMode ? 'var(--background-paper)' : 'white',
            '&:hover': {
              bgcolor: darkMode ? '#2f3a46' : '#f5f5f5',
            },
          }
        }
      }}
      icon={<AddIcon size={24} weight="bold" style={{ color: darkMode ? 'var(--text-primary)' : '#1a2027' }} />}
      open={open}
      onClick={() => setOpen(!open)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      direction="up"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={action.onClick || (() => navigate(action.route))}
          FabProps={{
            sx: {
              bgcolor: darkMode ? 'var(--background-paper)' : 'white',
              '&:hover': {
                bgcolor: darkMode ? '#1e2732' : '#e0e0e0',
                transform: 'translateY(-1px)',
                transition: 'all 0.2s ease-in-out',
              },
              border: darkMode ? '1px solid var(--border-color)' : 'none',
              boxShadow: darkMode ? '0 4px 20px rgba(0,0,0,0.3)' : '0 4px 20px rgba(0,0,0,0.15)',
            }
          }}
          sx={{
            '& .MuiSpeedDialAction-staticTooltipLabel': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '120px',
              backgroundColor: darkMode ? 'var(--background-paper)' : 'white',
              color: darkMode ? 'var(--text-primary)' : '#1a2027',
              border: darkMode ? '1px solid var(--border-color)' : '1px solid #e0e0e0',
              fontSize: '0.8rem',
              padding: '4px 8px',
              borderRadius: '4px',
              boxShadow: '0 2px 8px rgb(0 0 0 / 15%)'
            }
          }}
        />
      ))}
    </SpeedDial>
  );
};

export default CreateButton;

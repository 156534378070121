import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import dayjs from 'dayjs';
import BaseInput from './shared/BaseInput';
import BaseSelect from './shared/BaseSelect';
import RecurrenceSettings from './RecurrenceSettings';

const SingleDaySchedule = ({ project, setProject }) => {
  // Initialize recurrence state with project's existing values or defaults
  const [recurrence, setRecurrence] = useState({
    isRecurring: project?.recurrence?.isRecurring || false,
    frequency: project?.recurrence?.frequency || 'One-time',
    duration: project?.recurrence?.duration || '1 month'
  });

  // Filter out inventory items
  const items = Array.isArray(project?.items) 
    ? project.items.filter(item => item.type !== 'inventory')
    : [];
  
  // Add debug logs
  console.log('SingleDaySchedule filtered items:', items);

  // Add debug logs
  console.log('Project times:', {
    start: project.start_time,
    end: project.end_time,
    startFormatted: project.start_time ? dayjs(project.start_time).format('h:mm A') : 'none',
    endFormatted: project.end_time ? dayjs(project.end_time).format('h:mm A') : 'none'
  });

  // Generate time options for dropdown
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute of ['00', '30']) {
        times.push(`${hour}:${minute} AM`);
      }
    }
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute of ['00', '30']) {
        times.push(`${hour}:${minute} PM`);
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();
  console.log('Time options:', timeOptions); // Debug time options

  // Format time for display
  const formatTime = (dateTime) => {
    if (!dateTime) {
      console.log('No datetime provided');
      return timeOptions[0];
    }
    try {
      const date = dayjs(dateTime);
      if (!date.isValid()) {
        console.log('Invalid date:', dateTime);
        return timeOptions[0];
      }
      const hour = date.hour() % 12 || 12;
      const minute = date.minute().toString().padStart(2, '0');
      const period = date.hour() >= 12 ? 'PM' : 'AM';
      const formatted = `${hour}:${minute} ${period}`;
      console.log('Formatted time:', formatted);
      return formatted;
    } catch (error) {
      console.error('Error formatting time:', error);
      return timeOptions[0];
    }
  };

  const handleDateChange = (value) => {
    const newDate = dayjs(value).isValid() ? value : null;
    console.log('handleDateChange - before update:', { newDate, items });
    
    const newDateTime = newDate ? dayjs(newDate).hour(9).minute(0).second(0) : null;
    const newEndDateTime = newDate ? dayjs(newDate).hour(17).minute(0).second(0) : null;
    
    setProject({
      ...project,
      start_time: newDateTime,
      end_time: newEndDateTime,
      items: items // Keep existing items, don't modify them here
    });
  };

  const handleTimeChange = (field, timeStr) => {
    if (!timeStr) return;
    console.log('handleTimeChange - before update:', { field, timeStr, items });
    
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    
    let hour = parseInt(hours);
    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;
    
    const currentDate = dayjs(field === 'start_time' ? project.start_time : project.end_time || new Date());
    const newDateTime = currentDate.hour(hour).minute(parseInt(minutes)).second(0);
    
    setProject({
      ...project,
      [field]: newDateTime,
      items: items // Keep existing items, don't modify them here
    });
  };

  // Add this function to handle recurrence updates
  const handleRecurrenceChange = (newRecurrence) => {
    setRecurrence(newRecurrence);
    // Carefully update project state preserving ALL existing values
    setProject({
      ...project,
      recurrence: newRecurrence,
      items: items,  // Explicitly preserve items
      start_time: project.start_time,  // Explicitly preserve times
      end_time: project.end_time
    });
  };

  return (
    <Box sx={{ p: 3, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Single Day Schedule
      </Typography>

      <Grid container spacing={3}>
        {/* Items Column */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" sx={{ 
              mb: 2,
              fontFamily: 'Urbanist',
              fontWeight: 500
            }}>
              Project Items
            </Typography>
            {items.map((item, index) => (
              <Box
                key={index}
                sx={{
                  p: 2,
                  mb: 2,
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  '&:last-child': {
                    mb: 0
                  }
                }}
              >
                <Typography 
                  variant="subtitle1" 
                  gutterBottom 
                  sx={{ 
                    fontFamily: 'Urbanist',
                    fontWeight: 500
                  }}
                >
                  {item.title || `Item ${index + 1}`}
                </Typography>
                <Typography variant="body2" sx={{ 
                  color: 'rgb(100, 100, 100)',
                  fontSize: '0.9rem'
                }}>
                  {item.description}
                </Typography>
                {item.quantity > 1 && (
                  <Typography variant="body2" sx={{ 
                    mt: 0.5,
                    color: 'rgb(100, 100, 100)',
                    fontSize: '0.9rem'
                  }}>
                    Quantity: {item.quantity}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Scheduling Controls Column */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1" sx={{ 
              mb: 2,
              fontFamily: 'Urbanist',
              fontWeight: 500
            }}>
              Schedule Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BaseInput
                  label="Date"
                  type="date"
                  value={project.start_time ? dayjs(project.start_time).format('YYYY-MM-DD') : ''}
                  onChange={(e) => handleDateChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <BaseSelect
                  label="Start Time"
                  value={formatTime(project.start_time)}
                  onChange={(e) => handleTimeChange('start_time', e.target.value)}
                  options={timeOptions}
                  name="start_time"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <BaseSelect
                  label="End Time"
                  value={formatTime(project.end_time)}
                  onChange={(e) => handleTimeChange('end_time', e.target.value)}
                  options={timeOptions}
                  name="end_time"
                />
              </Grid>
              
              <Grid item xs={12} sx={{ mt: 2 }}>
                <RecurrenceSettings 
                  recurrence={recurrence}
                  setRecurrence={handleRecurrenceChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleDaySchedule; 
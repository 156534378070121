import React, { useRef, useEffect, useCallback, useState } from 'react';
import './CustomToggle.css';

const CustomToggle = ({ options, value, onChange }) => {
  const buttonRefs = useRef({});
  const highlightRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const updateHighlight = useCallback((buttonValue) => {
    const button = buttonRefs.current[buttonValue];
    const highlight = highlightRef.current;
    
    if (button && highlight) {
      const rect = button.getBoundingClientRect();
      const width = rect.width;
      const left = button.offsetLeft;
      
      highlight.style.width = `${width}px`;
      highlight.style.transform = `translateX(${left}px)`;
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
      if (value) {
        requestAnimationFrame(() => updateHighlight(value));
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isVisible && value) {
      requestAnimationFrame(() => updateHighlight(value));
    }
  }, [value, updateHighlight, isVisible]);

  useEffect(() => {
    const handleResize = () => {
      if (isVisible && value) {
        requestAnimationFrame(() => updateHighlight(value));
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [value, updateHighlight, isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="custom-toggle-wrapper">
      <div className="custom-toggle-container">
        <div ref={highlightRef} className="custom-toggle-highlight" />
        <div className="custom-toggle-group">
          {options.map((option) => (
            <button
              key={option.value}
              ref={(el) => buttonRefs.current[option.value] = el}
              data-selected={value === option.value}
              onClick={() => onChange(option.value)}
              type="button"
              className="custom-toggle-button"
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomToggle; 
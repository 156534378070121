import React from 'react';
import { Paper } from '@mui/material';
import SectionHeader from './SectionHeader';
import BaseInput from './shared/BaseInput';

const ProjectTitleForm = ({ project, setProject }) => {
  return (
    <Paper sx={{ borderRadius: '12px', boxShadow: 'none !important' }}> 
      <BaseInput
        label="Project Title"
        name="title"
        value={project.title}
        onChange={(e) => setProject(prev => ({ ...prev, title: e.target.value }))}
      />
    </Paper>
  );
};

export default ProjectTitleForm; 
import React, { useRef, useEffect } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

function ActionSelector({ onSelect, showStatusSelector }) {
  const selectorRef = useRef(null);

  const PROJECT_STATUSES = {
    PENDING_SCHEDULING: { id: 'pending_scheduling', label: 'Pending Scheduling' },
    SCHEDULED: { id: 'scheduled', label: 'Scheduled' },
    IN_PROGRESS: { id: 'in_progress', label: 'In Progress' },
    PENDING_INVOICING: { id: 'pending_invoicing', label: 'Pending Invoicing' },
    COMPLETED: { id: 'completed', label: 'Completed' }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onSelect?.(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSelect]);

  if (showStatusSelector) {
    return (
      <Paper
        ref={selectorRef}
        elevation={2}
        sx={{
          width: 280,
          backgroundColor: 'white',
          borderRadius: '12px',
          overflow: 'hidden',
          border: '1px solid rgba(74, 93, 249, 0.1)',
        }}
      >
        <List sx={{ py: 1 }}>
          {Object.values(PROJECT_STATUSES).map((status) => (
            <ListItem
              key={status.id}
              button
              onClick={() => onSelect?.(status)}
              sx={{
                py: 1.5,
                px: 2,
                '&:hover': {
                  backgroundColor: 'rgba(74, 93, 249, 0.04)',
                },
              }}
            >
              <ListItemText
                primary={status.label}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: '#1a1a1a',
                    fontSize: '0.95rem',
                    fontWeight: 400,
                  }
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  }

  return null;
}

export default ActionSelector;

import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Typography, 
  Box, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button 
} from '@mui/material';

export const useWelcomeMessage = () => {
  const [greeting, setGreeting] = useState('');
  const [subGreeting, setSubGreeting] = useState('');
  const [userName, setUserName] = useState(localStorage.getItem('preferredName') || '');
  const [openDialog, setOpenDialog] = useState(!localStorage.getItem('preferredName'));
  const currentHour = new Date().getHours();

  const morningMessages = [
    'Good Morning',
    'Rise and Shine',
    'Look Who\'s Up Early',
    'Morning Champion',
    'Ready to Rock?',
    'Coffee Time ☕',
    'Another Day, Another Victory',
    'Morning Superstar',
    'Fresh Day Ahead',
    'Time to Conquer Today',
    'Morning Mission: Started',
    'Early Bird Status: Achieved',
    'Morning Game: Strong',
    'Productivity Mode: Activated',
    'Ready to Make Today Count?'
  ];

  const afternoonMessages = [
    'Good Afternoon',
    'Keeping the Momentum Going',
    'Afternoon Champion',
    'Still Going Strong',
    'Halfway There',
    'Post-Lunch Power Hour',
    'Afternoon Game Face: On',
    'Second Wind: Activated',
    'Crushing It Today',
    'Mid-Day Magic Time',
    'Afternoon Goals Loading...',
    'Still Winning Today',
    'Afternoon Energy Boost: Active',
    'Peak Performance Time',
    'Time to Shine Brighter'
  ];

  const eveningMessages = [
    'Good Evening',
    'Finishing Strong',
    'Almost There',
    'Evening Excellence',
    'Final Countdown',
    'Victory Lap Time',
    'Evening Boss Mode',
    'Wrapping Up Like a Pro',
    'Evening Productivity Peak',
    'Last Push of the Day',
    'Evening Champion Mode',
    'Closing Time Hero',
    'Final Sprint Energy',
    'Evening Goals: Loading',
    'Time to Seal the Deal'
  ];

  const specialMessages = [
    'Ready to Make Things Happen?',
    'Time to Show Off Those Skills',
    'Plot Twist: You\'re Awesome Today',
    'Mission Possible: Your Day',
    'Achievement Hunter Mode: On',
    'Today\'s Forecast: 100% Success',
    'Superhero Mode: Activated',
    'Level Up Time',
    'Boss Level: Unlocked',
    'Today\'s Main Character: You',
    'Epic Day Loading...',
    'Legendary Status: Pending',
    'Achievement Unlocked: Showed Up',
    'Player 1: Ready',
    'Insert Coin to Continue'
  ];

  const subGreetings = [
    "Here's what's happening with your projects today.",
    "Let's see what's cooking in your projects.",
    "Your project dashboard is looking good.",
    "Here's your daily project rundown.",
    "Your projects are waiting for you.",
    "Time to check in on your projects.",
    "Let's see what needs your attention today.",
    "Your project overview is ready to go.",
    "Here's your project snapshot for today.",
    "Ready to tackle your projects?",
    "Your project command center awaits.",
    "Let's make some progress today.",
    "Time to make things happen.",
    "Your projects are moving forward.",
    "Let's check in on your progress."
  ];

  useEffect(() => {
    const getRandomMessage = (messages) => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      return messages[randomIndex];
    };

    // Set both the greeting and subGreeting
    if (Math.random() < 0.1) {
      setGreeting(getRandomMessage(specialMessages));
    } else {
      let messages;
      if (currentHour < 12) {
        messages = morningMessages;
      } else if (currentHour < 18) {
        messages = afternoonMessages;
      } else {
        messages = eveningMessages;
      }
      setGreeting(getRandomMessage(messages));
    }
    
    // Set the sub-greeting
    setSubGreeting(getRandomMessage(subGreetings));

    // Show dialog if no name is stored
    if (!localStorage.getItem('preferredName')) {
      setOpenDialog(true);
    }
  }, [currentHour]);

  const handleNameSubmit = (newName) => {
    if (newName.trim()) {
      const trimmedName = newName.trim();
      localStorage.setItem('preferredName', trimmedName);
      setUserName(trimmedName);
    }
    setOpenDialog(false);
  };

  return { greeting, subGreeting, userName, openDialog, setOpenDialog, handleNameSubmit };
};

const WelcomeMessage = () => {
  const { 
    greeting, 
    subGreeting, 
    userName, 
    openDialog, 
    setOpenDialog, 
    handleNameSubmit 
  } = useWelcomeMessage();
  
  const [tempName, setTempName] = useState(userName || '');

  return (
    <>
      <Paper sx={{ 
        background: 'transparent',
        boxShadow: 'none',
        mb: 0,
        color: 'var(--text-primary)'
      }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            {greeting}, {userName}! 👋
          </Typography>
          <Typography variant="body1" color="var(--text-secondary)">
            {subGreeting}
          </Typography>
          <Button 
            size="small" 
            onClick={() => {
              setTempName(userName);
              setOpenDialog(true);
            }}
            sx={{ mt: 1, textTransform: 'none' }}
          >
            Change name
          </Button>
        </Box>
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => {
          // Only allow closing if a name is already set
          if (userName) {
            setOpenDialog(false);
            setTempName(userName);
          }
        }}
      >
        <DialogTitle>
          How would you like to be greeted?
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Preferred Name"
            type="text"
            fullWidth
            variant="outlined"
            value={tempName}
            onChange={(e) => setTempName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && tempName.trim()) {
                handleNameSubmit(tempName);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          {userName && ( // Only show Cancel if a name is already set
            <Button onClick={() => {
              setOpenDialog(false);
              setTempName(userName);
            }}>
              Cancel
            </Button>
          )}
          <Button 
            onClick={() => handleNameSubmit(tempName)}
            variant="contained"
            disabled={!tempName.trim()} // Disable if empty
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WelcomeMessage; 
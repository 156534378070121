import React, { useRef, useEffect, useState } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  IconButton,
  Modal,
  Button,
  TextField,
} from '@mui/material';
import { 
  Bell as NotificationIcon,
  ChatText as TextIcon,
  EnvelopeSimple as EmailIcon,
  Phone as PhoneIcon,
  X as CloseIcon,
  PencilSimple as EditIcon
} from "@phosphor-icons/react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BaseInput from './shared/BaseInput';

function TypeSelector({ onSelect }) {
  const selectorRef = useRef(null);
  const modalRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [editorContent, setEditorContent] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  useEffect(() => {
    function handleClickOutside(event) {
      // Close the type selector if clicking outside of both the selector and modal
      if (
        selectorRef.current && 
        !selectorRef.current.contains(event.target) &&
        (!modalRef.current || !modalRef.current.contains(event.target))
      ) {
        onSelect?.(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSelect]);

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setEditorContent(type.template);
    setEmailSubject(type.id === 'email' ? '' : '');
    setModalOpen(true);
    onSelect?.(type);
  };

  const handleCloseModal = (e) => {
    e?.stopPropagation();
    setModalOpen(false);
  };

  const types = [
    { 
      id: 'notification', 
      label: 'a notification', 
      icon: <NotificationIcon size={24} />,
      title: 'Platform Notification',
      template: 'Write your notification message here...'
    },
    { 
      id: 'text', 
      label: 'a text message', 
      icon: <TextIcon size={24} />,
      title: 'SMS Message',
      template: 'Write your SMS message here...'
    },
    { 
      id: 'email', 
      label: 'an email', 
      icon: <EmailIcon size={24} />,
      title: 'Email Message',
      template: 'Write your email content here...'
    },
    { 
      id: 'phone', 
      label: 'a phone call', 
      icon: <PhoneIcon size={24} />,
      title: 'Phone Call Script',
      template: 'Write your call script here...'
    }
  ];

  return (
    <>
      <Paper
        ref={selectorRef}
        elevation={2}
        sx={{
          width: 280,
          backgroundColor: 'white',
          borderRadius: '12px',
          overflow: 'hidden',
          border: '1px solid rgba(74, 93, 249, 0.1)',
        }}
      >
        <List sx={{ py: 1 }}>
          {types.map((type) => (
            <ListItem
              key={type.id}
              button
              onClick={() => handleTypeSelect(type)}
              sx={{
                py: 1.5,
                px: 2,
                '&:hover': {
                  backgroundColor: 'rgba(74, 93, 249, 0.04)',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: '#4a5df9' }}>
                {type.icon}
              </ListItemIcon>
              <ListItemText
                primary={type.label}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: '#1a1a1a',
                    fontSize: '0.95rem',
                    fontWeight: 400,
                  }
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent'
          }
        }}
        disableAutoFocus
        slotProps={{
          backdrop: {
            onClick: handleCloseModal
          }
        }}
      >
        <Box
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '800px',
            backgroundColor: 'var(--background-primary)',
            borderRadius: '12px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
            p: 3,
            outline: 'none',
          }}
        >
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {selectedType?.icon}
                <Typography variant="h6">
                  {selectedType?.title}
                </Typography>
              </Box>
              <IconButton 
                onClick={handleCloseModal}
                sx={{ color: 'var(--text-primary)' }}
              >
                <CloseIcon weight="bold" />
              </IconButton>
            </Box>

            {selectedType?.id === 'email' && (
              <BaseInput
                label="Subject"
                name="emailSubject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            )}

            <Box sx={{ 
              height: '400px',
              '& .quill': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              },
              '& .ql-container': {
                flex: 1,
                overflow: 'auto'
              },
              '& .ql-editor': {
                minHeight: '300px'
              }
            }}>
              <ReactQuill
                theme="snow"
                value={editorContent}
                onChange={setEditorContent}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    ['link'],
                    ['clean']
                  ]
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default TypeSelector; 
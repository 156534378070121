import React, { useState } from 'react';
import { format } from 'date-fns';
import styles from './MonthEventCard.module.scss';
import EditEvent from '../EditEvent';
import { useNavigate } from 'react-router-dom';

const MonthEventCard = ({ event, onEventUpdated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsOpen(!isOpen);
  };

  const handleEditClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsEditOpen(true);
  };

  const handleGoToProject = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (event.project?.id) {
      navigate(`/admin/projects/${event.project.id}`);
    }
  };

  // Close when clicking outside
  const handleClickOutside = (e) => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'invoiced':
        return '#34D399';
      case 'pending_scheduling':
        return '#FBBF24';
      case 'scheduled':
        return '#60A5FA';
      default:
        return '#9CA3AF';
    }
  };

  return (
    <>
      <div
        className={styles.eventCard}
        onClick={handleClick}
      >
        <span className={styles.eventTime}>
          {event.time_slots && event.time_slots.length > 0
            ? format(new Date(event.time_slots[0].start_time), 'h:mm a')
            : ''}
        </span>
        <span className={styles.eventTitle}>
          {event.title}
          {event.project?.client?.name && (
            <span className={styles.clientName}> • {event.project.client.name}</span>
          )}
        </span>

        {isOpen && (
          <div className={styles.eventDetails}>
            <div className={styles.eventDetailHeader}>
              <div className={styles.headerInfo}>
                <span className={styles.detailTitle}>{event.title}</span>
                {event.project?.client?.name && (
                  <div className={styles.clientInfo}>
                    <div>Client: {event.project.client.name}</div>
                    {event.addresses && event.addresses.length > 0 && (
                      <div className={styles.address}>
                        {event.addresses.map((address, index) => (
                          <div key={index}>{address}</div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div 
                className={styles.statusBadge}
                style={{ backgroundColor: getStatusColor(event.status) }}
              >
                {event.status?.replace('_', ' ')}
              </div>
            </div>

            <div className={styles.eventDetailBody}>
              {/* Project */}
              {event.project && (
                <p className={styles.detailProject}>
                  <strong>Project:</strong> {event.project.name}
                </p>
              )}

              {/* Time */}
              <p className={styles.detailTime}>
                {event.time_slots && event.time_slots.length > 0 && (
                  <>
                    <strong>Time:</strong>{' '}
                    {format(new Date(event.time_slots[0].start_time), 'EEE, MMM d, h:mm a')} -{' '}
                    {format(new Date(event.time_slots[0].end_time), 'h:mm a')}
                  </>
                )}
              </p>

              {/* Employees */}
              {event.employees && event.employees.length > 0 && (
                <div className={styles.detailEmployees}>
                  <strong>Team:</strong>
                  <ul>
                    {event.employees.map((employee) => (
                      <li key={employee.id}>{employee.name}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Description */}
              {event.description && (
                <div className={styles.detailDescription}>
                  <strong>Details:</strong>
                  <pre>{event.description}</pre>
                </div>
              )}

              {/* Action Buttons */}
              <div className={styles.actionButtons}>
                <button 
                  className={styles.editButton}
                  onClick={handleEditClick}
                >
                  Edit Event
                </button>
                {event.project?.id && (
                  <button 
                    className={styles.projectButton}
                    onClick={handleGoToProject}
                  >
                    Go to Project
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Edit Event Dialog */}
      <EditEvent
        eventId={event.id}
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        onEventUpdated={() => {
          setIsEditOpen(false);
          setIsOpen(false);
          onEventUpdated();
        }}
      />
    </>
  );
};

export default MonthEventCard;
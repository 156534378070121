import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapWidget = () => {
  // Fort Lauderdale coordinates - adjusted slightly west to show more ocean
  const center = {
    lat: 26.1224,
    lng: -80.2373  // Moved slightly west to show more ocean
  };

  const mapStyles = {
    height: "400px",
    width: "100%",
    borderRadius: '8px'
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    draggable: false,
    zoom: 10,  // Adjusted zoom level to show more ocean
    styles: [
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6c7686" }]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#e9e9e9" }]
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }]
      },
      {
        // Enhanced ocean styling
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          { color: "#bfd4f6" },  // Light blue color for water
          { saturation: 40 },
          { lightness: 40 }
        ]
      }
    ]
  };

  return (
    <Paper
      elevation={0}
      sx={{
        background: 'var(--background-paper)',
        border: '1px solid var(--divider)',
        borderRadius: 2,
        p: 2,
        height: '100%'
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>Active Employee Location</Typography>
      <Box sx={{ height: 400, width: '100%' }}>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={12.5}
            center={center}
            options={mapOptions}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </Box>
    </Paper>
  );
};

export default MapWidget; 
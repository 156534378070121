import React from 'react';
import { 
  Box, 
  Grid, 
  Typography, 
  Button, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { 
  CreditCard as PaymentIcon,
  CheckCircle as CheckIcon,
  Lightning as SpeedIcon,
  Shield as SecurityIcon,
  Bank as BankIcon,
  Wallet as WalletIcon,
} from "@phosphor-icons/react";

function AdminSettingsPayment() {
  return (
    <Box sx={{ p: 3 }}>
      {/* Hero Section */}
      <Box sx={{ 
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        mb: 4,
        height: '300px',
        backgroundImage: 'url("https://images.unsplash.com/photo-1556742502-ec7c0e9f34b1?ixlib=rb-4.0.3")', // Payment/finance themed image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 4,
        }}>
          <Typography variant="h3" sx={{ 
            color: 'white', 
            fontWeight: 700,
            mb: 2,
            fontFamily: 'Urbanist'
          }}>
            ServTrackr Pay
          </Typography>
          <Typography variant="h6" sx={{ 
            color: 'white',
            maxWidth: '600px',
            mb: 3,
            fontFamily: 'Urbanist'
          }}>
            Streamline your payments, boost your business growth, and get paid faster with our integrated payment solution.
          </Typography>
          <Button
            variant="contained"
            startIcon={<PaymentIcon size={20} weight="light" />}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              backgroundColor: '#4a5df9',
              fontFamily: 'Urbanist, sans-serif',
              fontWeight: 600,
              fontSize: '0.875rem',
              padding: '12px 24px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3c50fa',
                boxShadow: 'none',
              },
              width: 'fit-content'
            }}
          >
            Get Started with ServTrackr Pay
          </Button>
        </Box>
      </Box>

      {/* Features Grid */}
      <Grid container spacing={3}>
        {[
          {
            icon: <SpeedIcon size={32} weight="light" style={{ color: '#4a5df9' }} />,
            title: 'Fast Payments',
            description: 'Get paid within 24 hours of job completion'
          },
          {
            icon: <SecurityIcon size={32} weight="light" style={{ color: '#00AB55' }} />,
            title: 'Secure Transactions',
            description: 'Bank-level security and encryption for all payments'
          },
          {
            icon: <BankIcon size={32} weight="light" style={{ color: '#7635dc' }} />,
            title: 'Multiple Payment Methods',
            description: 'Accept credit cards, ACH, and digital wallets'
          },
          {
            icon: <WalletIcon size={32} weight="light" style={{ color: '#FF4842' }} />,
            title: 'Low Transaction Fees',
            description: 'Competitive rates starting at just 2.9% + $0.30'
          }
        ].map((feature, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card sx={{ 
              height: '100%',
              borderRadius: '12px',
              boxShadow: 'none',
              border: '1px solid var(--divider)'
            }}>
              <CardContent sx={{ p: 3 }}>
                {feature.icon}
                <Typography variant="h6" sx={{ 
                  mt: 2, 
                  mb: 1,
                  fontFamily: 'Urbanist',
                  fontWeight: 600
                }}>
                  {feature.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{
                  fontFamily: 'Urbanist'
                }}>
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Benefits Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ 
          mb: 3,
          fontFamily: 'Urbanist',
          fontWeight: 600
        }}>
          Why Choose ServTrackr Pay?
        </Typography>
        <List>
          {[
            'Seamless integration with your ServTrackr workflow',
            'Real-time payment tracking and reconciliation',
            'Automated payment reminders and invoicing',
            'Detailed transaction reporting and analytics',
            'Dedicated customer support team'
          ].map((benefit, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckIcon size={24} weight="light" style={{ color: '#00AB55' }} />
              </ListItemIcon>
              <ListItemText 
                primary={benefit}
                primaryTypographyProps={{
                  fontFamily: 'Urbanist',
                  fontSize: '1rem'
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default AdminSettingsPayment;

import React from 'react';
import Box from '@mui/material/Box';
import SearchBar from './SearchBar';

const TopBar = () => {
  return (
    <Box
      component="div"
      sx={{
        height: '64px',
        width: '100%',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1200,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
        background: 'transparent',
        pointerEvents: 'none',
      }}
    >
      <Box sx={{ flex: 1 }} />
      <Box sx={{ 
        position: 'fixed',
        top: '16px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
      }}>
        <SearchBar />
      </Box>
      <Box sx={{ 
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        top: '16px',
        marginLeft: '16px',
        pointerEvents: 'auto',
      }}>
      </Box>
    </Box>
  );
};

export default TopBar; 
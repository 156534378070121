import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Switch,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Container,
  MenuItem,
  Select,
  InputLabel,
  Tabs,
  Tab
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import NewClientForm from './NewClientForm.tsx';
import AddressAutocomplete from './AddressAutocomplete';
import { createProject, createClient, getClients, getItems } from '../services/api';
import dayjs from 'dayjs';
import ProjectItemsManager from './ProjectItemsManager';
import { DatePicker } from '@mui/x-date-pickers';
import styles from './CreateProjectPage.module.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Plus, FolderSimplePlus } from '@phosphor-icons/react';
import CustomStepper from './common/CustomStepper';
import BaseAutocomplete from './shared/BaseAutocomplete';
import BaseInput from './shared/BaseInput';
import CustomSwitch from './shared/CustomSwitch';
import AddIcon from '@mui/icons-material/Add';
import { Paper as MuiPaper, Switch as MuiSwitch } from '@mui/material';
import SavedItemsManager from './SavedItemsManager';
import ExpandableInput from './shared/ExpandableInput';
import BaseSelect from './shared/BaseSelect';
import Schedule from './Schedule';
import MultiDaySchedule from './MultiDaySchedule';
import RecurringSchedule from './RecurringSchedule';
import ProjectTitleForm from './ProjectTitleForm';
import ClientInformationForm from './ClientInformationForm';
import ScheduleTabs from './ScheduleTabs';
import SingleDaySchedule from './SingleDaySchedule';
import ClientPropertySelector from './ClientPropertySelector';
import ProjectEmployeeSelector from './ProjectEmployeeSelector';
const globalStyles = {
  WebkitTapHighlightColor: 'transparent',
  '*:focus': {
    outline: 'none !important',
    WebkitAppearance: 'none !important',
    boxShadow: 'none !important',
  },
  'input': {
    WebkitAppearance: 'none !important',
    color: 'var(--text-primary) !important',
    '&:focus': {
      outline: 'none !important',
      WebkitAppearance: 'none !important',
      boxShadow: 'none !important',
    }
  }
};

const inputStyle = {
  height: '45px',
  width: '100%',
  fontSize: '0.95rem',
  border: '1px solid var(--divider)',
  borderRadius: '4px',
  padding: '8px 12px',
  fontFamily: 'inherit',
  backgroundColor: 'var(--background-paper)',
  color: 'var(--text-primary)',
  transition: 'border-color 0.2s ease',
  WebkitTapHighlightColor: 'transparent !important',
  WebkitAppearance: 'none !important',
  '&:hover': {
    border: '1px solid var(--text-secondary)',
  },
  '&:focus': {
    border: '1px solid var(--primary-main) !important',
    outline: 'none !important',
    WebkitAppearance: 'none !important',
    boxShadow: 'none !important',
    '-webkit-box-shadow': 'none !important',
    '-moz-box-shadow': 'none !important'
  },
  '-webkit-user-select': 'text'
};

const datePickerStyle = {
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '45px',
    width: '100%',
    fontSize: '0.95rem',
    border: '1px solid var(--divider)',
    borderRadius: '4px',
    backgroundColor: 'var(--background-paper)',
    color: 'var(--text-primary)',
    transition: 'border-color 0.2s ease',
    WebkitTapHighlightColor: 'transparent !important',
    WebkitAppearance: 'none !important',
    padding: 0,
    boxShadow: 'none !important',
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      border: '1px solid var(--text-secondary)',
      boxShadow: 'none !important',
    },
    '&.Mui-focused': {
      border: '1px solid var(--primary-main) !important',
      outline: 'none !important',
      WebkitAppearance: 'none !important',
      boxShadow: 'none !important',
      backgroundColor: 'var(--background-paper)'
    },
    '& input': {
      height: '45px !important',
      padding: '8px 12px !important',
      fontSize: '0.95rem !important',
      fontFamily: 'inherit !important',
      WebkitAppearance: 'none !important',
      backgroundColor: 'transparent',
      color: 'var(--text-primary)',
      '&:focus': {
        outline: 'none !important',
        WebkitAppearance: 'none !important',
        boxShadow: 'none !important'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
    }
  },
};

// Initial project state
const initialProjectState = {
  title: '',
  client_id: null,
  items: [{
    title: '',
    description: '',
    quantity: 1,
    unit_price: 0,
    total: 0,
    isNew: true,
    fromAutocomplete: false,
    saved: false,
    isEdited: false,
    hideLibraryBanner: true,
    isFromLibrary: false,
    isSavedItem: false
  }],
  start_time: null,
  end_time: null,
  use_client_address: false,
  service_address: '',
  service_city: '',
  service_state: '',
  service_zip: '',
  customer_details: {
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    notes: '',
    isNewClient: true
  }
};

// Define steps constant
const steps = [
  'Client Details',
  'Project Items',
  'Schedule',
  'Location',
  'Assign Employees'
];

const CreateProjectPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [projectCreationType, setProjectCreationType] = useState(null);
  
  // Core states
  const [savedItems, setSavedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  
  // Add missing state definitions
  const [showError, setShowError] = useState(false);
  const [useTabs, setUseTabs] = useState(true);
  const [locationTab, setLocationTab] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  
  // Existing state definitions
  const [activeStep, setActiveStep] = useState(0);
  const [eventTab, setEventTab] = useState(0);
  const [project, setProject] = useState({
    title: '',
    start_time: dayjs().hour(9).minute(0).second(0), // Initialize with 9 AM today
    end_time: dayjs().hour(17).minute(0).second(0),  // Initialize with 5 PM today
    items: [],
    client_id: null,
    use_client_address: false,
    service_address: '',
    service_city: '',
    service_state: '',
    service_zip: '',
    customer_details: {
      name: '',
      company: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      notes: '',
      isNewClient: true
    }
  });

  // Initialize estimate state exactly like AdminCreateEstimateNoTabs
  const [estimateItems, setEstimateItems] = useState([{
    id: Date.now(),
    title: '',
    description: '',
    quantity: 1,
    unit_price: 0,
    unitPrice: 0,
    total: 0,
    isNew: true,
    fromAutocomplete: false,
    saved: false,
    isEdited: false,
    hideLibraryBanner: true,
    isFromLibrary: false,
    isSavedItem: false,
    type: 'SAVED'
  }]);

  // Add state for combined event details
  const [combinedEvent, setCombinedEvent] = useState({
    title: '',
    description: '',
    duration: 0,
    total: 0
  });

  // Add to existing state declarations
  const [scheduleLater, setScheduleLater] = useState(false);

  // Load saved items
  useEffect(() => {
    const loadSavedItems = async () => {
      try {
        const response = await getItems();
        console.log('API Response:', response);
        setSavedItems(Array.isArray(response) ? response : []);
      } catch (error) {
        console.error('Error loading saved items:', error);
        setSavedItems([]);
      }
    };
    loadSavedItems();
  }, []);

  // Debug logs for items
  useEffect(() => {
    console.log('Project Items:', project.items);
    console.log('Saved Items:', savedItems);
  }, [project.items, savedItems]);

  // Load clients
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await getClients();
        if (response && response.clients) {
          setClients(response.clients);
        } else {
          console.warn('No clients array in response');
          setClients([]);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClients([]);
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    if (location.state?.newClient && location.state?.autoSelect) {
      console.log('New client returned:', location.state.newClient);
      const returnedClient = location.state.newClient;
      
      setSelectedClient(returnedClient);
      setProject(prev => ({
        ...prev,
        client_id: returnedClient.id
      }));

      // Clear the location state
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state?.fromEstimateConversion && location.state.estimateData) {
      const estimateData = location.state.estimateData;
      setProject(prevProject => ({
        ...prevProject,
        title: estimateData.project_name || '',
        notes: estimateData.notes || '',
        client_id: estimateData.customer_details?.id || null,
        use_client_address: estimateData.use_client_address || false,
        serviceAddress: estimateData.service_address || '',
        start_time: dayjs().startOf('day').hour(9),
        end_time: dayjs().startOf('day').hour(17),
        event_type: 'single',
      }));
      setProject(prev => ({
        ...prev,
        items: estimateData.items.map(item => ({
          description: item.description,
          quantity: item.quantity,
          unit_price: item.unit_price,
        }))
      }));
      if (estimateData.customer_details) {
        setSelectedClient(estimateData.customer_details);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state?.from === 'client-creation') {
      const savedState = sessionStorage.getItem('projectFormState');
      if (savedState) {
        const { selectedClient, project: savedProject, activeStep: savedStep } = JSON.parse(savedState);
        if (savedProject) setProject(savedProject);
        if (selectedClient) setSelectedClient(selectedClient);
        if (savedStep) setActiveStep(savedStep);
        sessionStorage.removeItem('projectFormState');
      }
    }
  }, [location.state]);

  // Update combined event whenever items change or event type changes
  useEffect(() => {
    if (eventTab === 0) { // Single day event
      const totalAmount = estimateItems.reduce((sum, item) => sum + (item.total || 0), 0);
      
      // Format descriptions with labels and proper spacing
      const itemDescriptions = estimateItems
        .filter(item => item.title || item.description)
        .map(item => {
          const lines = [];
          if (item.title) {
            lines.push(`Item: ${item.title}`);
          }
          if (item.description) {
            lines.push(`Description: ${item.description}`);
          }
          if (item.quantity > 1) {
            lines.push(`Quantity: ${item.quantity}`);
          }
          return lines.join('\n');
        })
        .join('\n\n'); // Add extra line break between items

      setCombinedEvent({
        title: estimateItems
          .filter(item => item.title)
          .map(item => item.title)
          .join(', ') || 'New Project',
        description: itemDescriptions,
        total: totalAmount
      });
    }
  }, [eventTab, estimateItems]);

  // Add this to debug state updates
  useEffect(() => {
    console.log('Project Items:', project.items);
    console.log('Estimate Items:', estimateItems);
  }, [project.items, estimateItems]);

  // Update initial project state to match estimateItems
  useEffect(() => {
    if (estimateItems.length > 0) {
      setProject(prev => ({
        ...prev,
        items: estimateItems
      }));
    }
  }, [estimateItems]);

  const handleClientSelect = (event, newValue) => {
    console.log('Client selected:', newValue);
    setSelectedClient(newValue);
    setProject(prev => ({
      ...prev,
      client_id: newValue?.id || null,
      // If using client address, update service address fields
      ...(project.use_client_address ? {
        service_address: newValue?.address || '',
        service_city: newValue?.city || '',
        service_state: newValue?.state || '',
        service_zip: newValue?.zip || ''
      } : {})
    }));
  };

  const goToCreateClient = () => {
    // Navigate to create client page
    navigate('/clients/new');
  };

  const handleAddToEstimate = (item, index = null) => {
    console.log('handleAddToEstimate:', { item, index });
    if (index !== null) {
      setEstimateItems(prev => {
        const newItems = [...prev];
        newItems[index] = item;
        return newItems;
      });
    } else {
      setEstimateItems(prev => [...prev, item]);
    }
    // Also update project.items to keep in sync
    setProject(prev => ({
      ...prev,
      items: index !== null ? 
        prev.items.map((i, idx) => idx === index ? item : i) :
        [...prev.items, item]
    }));
  };

  const handleRemoveItem = (index) => {
    console.log('handleRemoveItem:', index);
    setEstimateItems(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await createProject(project);
      setShowSuccess(true);
      setTimeout(() => navigate('/projects'), 1500);
    } catch (error) {
      console.error('Error creating project:', error);
      setShowError(true);
      setError(error.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const canCreateProject = useMemo(() => {
    return (
      project.title &&
      selectedClient &&
      project.start_time &&
      project.end_time &&
      estimateItems.length > 0 &&
      estimateItems.some(item => item.title || item.description) // At least one item with content
    );
  }, [project, selectedClient, estimateItems]);

  const pickerStyles = {
    width: '100%',
    '& .MuiPickersPopper-root': {
      '& ::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    }
  };

  const handleSetNineToFive = () => {
    const selectedDate = project.start_time ? dayjs(project.start_time) : dayjs();
    const startTime = selectedDate.hour(9).minute(0).second(0);
    const endTime = selectedDate.hour(17).minute(0).second(0);

    console.log('Setting 9-5 times:', {
      start: startTime.format('YYYY-MM-DDTHH:mm:ss'),
      end: endTime.format('YYYY-MM-DDTHH:mm:ss')
    });

    setProject(prev => ({
      ...prev,
      start_time: startTime,
      end_time: endTime
    }));
  };

  const handleDateChange = (field, value) => {
    console.log('handleDateChange - BEFORE:', { field, value, project });
    const validDate = dayjs(value).isValid() ? value : null;

    setProject(prev => ({
      ...prev,
      [field]: validDate
    }));
  };

  const handleTimeChange = (field, timeStr, itemIndex = null) => {
    if (!timeStr) return;
    
    const date = dayjs(field === 'start_time' ? project.start_time : project.end_time || new Date());
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    
    let hour = parseInt(hours);
    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;
    
    const newDateTime = date.hour(hour).minute(parseInt(minutes)).second(0);
    
    setProject(prev => {
      const updatedProject = { ...prev };
      
      if (itemIndex !== null) {
        // Update specific item in items array
        const updatedItems = [...prev.items];
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          [field]: newDateTime
        };
        updatedProject.items = updatedItems;
      } else {
        // Update project-level dates
        updatedProject[field] = newDateTime;
      }

      return updatedProject;
    });
  };

  const handleEventTypeChange = (event) => {
    setProject(prev => ({ ...prev, event_type: event.target.value }));
  };

  const handleUseClientAddress = (event) => {
    setProject(prev => ({ ...prev, use_client_address: event.target.checked }));
  };

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  const handleTabToggle = (event) => {
    setUseTabs(event.target.checked);
    if (event.target.checked) {
      setActiveStep(0);
    }
  };

  const handleEventTabChange = (event, newValue) => {
    setEventTab(newValue);
    setProject(prev => ({ 
      ...prev, 
      event_type: ['single', 'multi'][newValue]
    }));
  };

  const handleLocationTabChange = (event, newValue) => {
    setLocationTab(newValue);
  };

  // Add console logs to debug the props being passed
  console.log('project:', project);
  console.log('savedItems:', savedItems);

  // Add error handling functions
  const handleError = (error) => {
    console.error(error);
    setShowError(true);
    setError(error.message || 'An error occurred');
  };

  const clearError = () => {
    setShowError(false);
    setError(null);
  };

  // Generate time options for dropdown
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 1; hour <= 12; hour++) {
      times.push(`${hour}:00 AM`, `${hour}:30 AM`);
    }
    for (let hour = 1; hour <= 12; hour++) {
      times.push(`${hour}:00 PM`, `${hour}:30 PM`);
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  // Convert time string to dayjs
  const combineDateAndTime = (date, timeStr) => {
    if (!date || !timeStr) return null;
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours);
    
    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;
    
    return dayjs(date).hour(hour).minute(parseInt(minutes));
  };

  // Extract time string from dayjs
  const getTimeString = (datetime) => {
    if (!datetime) return '';
    const hour = datetime.hour();
    const minute = datetime.minute();
    const period = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour % 12 || 12;
    return `${displayHour}:${minute.toString().padStart(2, '0')} ${period}`;
  };

  const renderScheduleContent = () => {
    if (eventTab === 0) {
      // Single day - filter out inventory items before scheduling
      const schedulableItems = estimateItems.filter(item => item.type !== 'inventory');
      console.log('Rendering SingleDaySchedule with filtered items:', schedulableItems);
      
      return (
        <SingleDaySchedule
          project={{
            ...project,
            items: schedulableItems // Pass only non-inventory items
          }}
          setProject={(updatedProject) => {
            console.log('SingleDaySchedule update received:', updatedProject);
            
            // Keep inventory items unchanged, update times only for non-inventory items
            const updatedItems = estimateItems.map(item => {
              if (item.type === 'inventory') return item;
              return {
                ...item,
                start_time: updatedProject.start_time,
                end_time: updatedProject.end_time
              };
            });
            
            // Update both states
            setEstimateItems(updatedItems);
            setProject(prev => ({
              ...prev,
              start_time: updatedProject.start_time,
              end_time: updatedProject.end_time,
              items: updatedItems,
              recurrence: updatedProject.recurrence || prev.recurrence
            }));
          }}
        />
      );
    } else if (eventTab === 1) {
      // Multi day - filter out inventory items
      const schedulableItems = estimateItems.filter(item => item.type !== 'inventory');
      return (
        <MultiDaySchedule
          project={{
            ...project,
            items: schedulableItems
          }}
          setProject={(updatedProject) => {
            // Merge scheduled items with inventory items
            const mergedItems = estimateItems.map(item => {
              if (item.type === 'inventory') return item;
              const updatedItem = updatedProject.items.find(i => i.title === item.title);
              return updatedItem || item;
            });
            
            setProject(prev => ({
              ...prev,
              items: mergedItems
            }));
            setEstimateItems(mergedItems);
          }}
        />
      );
    }
  };

  // Add this effect to keep project.items and estimateItems in sync
  useEffect(() => {
    setProject(prev => ({
      ...prev,
      items: estimateItems
    }));
  }, [estimateItems]);

  // Add debug logs
  useEffect(() => {
    console.log('Project state:', project);
    console.log('EstimateItems state:', estimateItems);
  }, [project, estimateItems]);

  // If no creation type is selected and not coming from estimate conversion
  if (!projectCreationType && !location.state?.fromEstimate) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 600 }}>
          Create Project
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          gap: 3,
          justifyContent: 'center',
          mt: 20
        }}>
          <Paper
            sx={{
              p: 3,
              cursor: 'pointer',
              width: '300px',
              textAlign: 'center',
              transition: 'all 0.2s ease-in-out',
              border: '2px dashed #e0e0e0',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
                borderColor: '#4a5df9',
                backgroundColor: 'rgba(74, 93, 249, 0.04)',
                transform: 'translateY(-4px)'
              }
            }}
            onClick={() => setProjectCreationType('new')}
          >
            <Plus 
              size={48} 
              weight="thin"
              style={{ 
                marginBottom: '16px',
                color: '#4a5df9'
              }} 
            />
            <Typography variant="h6" gutterBottom>
              Create New Project
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Start with a blank project and add items manually
            </Typography>
          </Paper>

          <Paper
            sx={{
              p: 3,
              cursor: 'pointer',
              width: '300px',
              textAlign: 'center',
              transition: 'all 0.2s ease-in-out',
              border: '2px dashed #e0e0e0',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
                borderColor: '#4a5df9',
                backgroundColor: 'rgba(74, 93, 249, 0.04)',
                transform: 'translateY(-4px)'
              }
            }}
            onClick={() => navigate('/admin/create-project-from-estimate')}
          >
            <FolderSimplePlus 
              size={48} 
              weight="thin"
              style={{ 
                marginBottom: '16px',
                color: '#4a5df9'
              }} 
            />
            <Typography variant="h6" gutterBottom>
              Create from Estimate
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Convert an existing estimate into a project
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  // Continue with the regular project creation form if:
  // 1. User selected 'new' project type
  // 2. User is coming from estimate conversion
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={globalStyles}>
        <Box sx={{ p: 3, maxWidth: 1400, margin: '0 auto' }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 4 
          }}>
            <Typography 
              variant="h4" 
              sx={{
                color: '#2c3e50',
                fontWeight: 600
              }}
            >
              Create New Project
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif !important',
                      color: '#666',
                      fontSize: '0.9rem',
                      marginRight: '12px'
                    }}
                  >
                    Tabbed View
                  </Typography>
                }
                control={
                  <CustomSwitch
                    checked={useTabs}
                    onChange={handleTabToggle}
                    name="tabToggle"
                  />
                }
                labelPlacement="start"
                sx={{
                  margin: 0,
                  '& .MuiFormControlLabel-label': {
                    fontWeight: 500
                  }
                }}
              />
            </Box>
          </Box>

          {useTabs && (
            <CustomStepper 
              steps={steps} 
              activeStep={activeStep} 
              onStepClick={handleStepClick}
              sx={{ pt: 3, pb: 5 }}
            />
          )}

          {useTabs ? (
            <>
              {activeStep === 0 && (
                <Paper sx={{ 
                  p: 3, 
                  mb: 3, 
                  borderRadius: '12px', 
                  boxShadow: 'none !important',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: '12%'
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    width: '450px',
                  }}>
                    <ProjectTitleForm project={project} setProject={setProject} />

                    <ClientInformationForm
                      clients={clients}
                      selectedClient={selectedClient}
                      handleClientSelect={handleClientSelect}
                      goToCreateClient={goToCreateClient}
                    />
                  </Box>
                </Paper>
              )}

              {activeStep === 1 && (
                <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: 'var(--text-primary)',
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      '&::before': {
                        content: '""',
                        width: '4px',
                        height: '24px',
                        backgroundColor: '#3498db',
                        marginRight: '12px',
                        borderRadius: '4px'
                      }
                    }}
                  >
                    Project Items
                  </Typography>
                  <SavedItemsManager 
                    estimateItems={estimateItems}
                    savedItems={savedItems}
                    setSavedItems={setSavedItems}
                    onAddToEstimate={handleAddToEstimate}
                    onRemoveItem={handleRemoveItem}
                  />
                </Paper>
              )}

              {activeStep === 2 && (
                <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>

                  <ScheduleTabs
                    project={project}
                    setProject={(updatedProject) => {
                      if (typeof updatedProject === 'function') {
                        setProject(updatedProject);
                      } else {
                        setProject(prev => ({ ...prev, ...updatedProject }));
                      }
                    }}
                    scheduleLater={scheduleLater}
                    setScheduleLater={setScheduleLater}
                    eventTab={eventTab}
                    handleEventTabChange={handleEventTabChange}
                  />
                </Paper>
              )}

              {activeStep === 3 && (
                <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
                  <Grid container spacing={3}>
                    {selectedClient && !project.use_client_address && (
                      <Grid item xs={12}>
                        <ClientPropertySelector
                          selectedClient={selectedClient}
                          onPropertySelect={(propertyAddress) => {
                            setProject(prev => ({
                              ...prev,
                              ...propertyAddress
                            }));
                          }}
                          project={project}
                        />
                      </Grid>
                    )}

                    {!project.use_client_address && (
                      <>
                        <Grid item xs={12}>
                          <BaseInput
                            label="Service Address"
                            name="serviceAddress"
                            value={project.service_address || ''}
                            onChange={(e) => setProject(prev => ({ ...prev, service_address: e.target.value }))}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <BaseInput
                            label="City"
                            name="serviceCity"
                            value={project.service_city || ''}
                            onChange={(e) => setProject(prev => ({ ...prev, service_city: e.target.value }))}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <BaseInput
                            label="State"
                            name="serviceState"
                            value={project.service_state || ''}
                            onChange={(e) => setProject(prev => ({ ...prev, service_state: e.target.value }))}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <BaseInput
                            label="ZIP Code"
                            name="serviceZip"
                            value={project.service_zip || ''}
                            onChange={(e) => setProject(prev => ({ ...prev, service_zip: e.target.value }))}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
              )}

              {activeStep === 4 && (
                  <Box sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
                    <ProjectEmployeeSelector
                      selectedEmployees={selectedEmployees}
                      onEmployeeSelect={setSelectedEmployees}
                      project={project}
                      eventTab={eventTab}
                    />
                </Box>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                  disabled={isLoading || activeStep === 0}
                  onClick={() => setActiveStep(prevStep => Math.max(0, prevStep - 1))}
                  sx={{
                    height: '45px',
                    textTransform: 'none',
                    fontSize: '0.95rem',
                    fontFamily: 'Urbanist',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    borderColor: '#ccc',
                    color: '#666',
                    '&:hover': {
                      borderColor: '#666',
                      backgroundColor: '#f5f5f5'
                    },
                    mr: 2
                  }}
                >
                  Back
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading || !canCreateProject}
                    onClick={handleSubmit}
                    sx={{
                      height: '45px',
                      textTransform: 'none',
                      fontSize: '0.95rem',
                      fontFamily: 'Urbanist',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      backgroundColor: '#4a5df9',
                      '&:hover': {
                        backgroundColor: '#3a4ac7'
                      }
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Create Project'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setActiveStep(prevStep => Math.min(steps.length - 1, prevStep + 1))}
                    sx={{
                      height: '45px',
                      textTransform: 'none',
                      fontSize: '0.95rem',
                      fontFamily: 'Urbanist',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      backgroundColor: '#4a5df9',
                      '&:hover': {
                        backgroundColor: '#3a4ac7'
                      }
                    }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </>
          ) : (
            <>
              <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: 'var(--text-primary)',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#27ae60',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Project Title
                </Typography>
                <BaseInput
                  label="Project Title"
                  name="title"
                  value={project.title}
                  onChange={(e) => setProject(prev => ({ ...prev, title: e.target.value }))}
                />
              </Paper>

              <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: 'var(--text-primary)',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#27ae60',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Client Information
                </Typography>
                <Box sx={{}}>
                  <Box sx={{ width: '100%' }}>
                    <BaseAutocomplete
                      options={clients}
                      getOptionLabel={(option) => option?.name || option?.customer_name || ''}
                      onChange={handleClientSelect}
                      value={selectedClient}
                      label="Select Existing Client"
                      clearable="true"
                      PaperComponent={({ children, ...props }) => (
                        <Paper {...props}>
                          <div style={{ maxHeight: '40vh', overflow: 'auto' }}>
                            {children}
                          </div>
                          <div
                            style={{
                              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                              padding: '8px',
                              position: 'sticky',
                              bottom: 0,
                              backgroundColor: '#fff',
                              zIndex: 1000
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            <Button
                              fullWidth
                              startIcon={<AddIcon />}
                              onClick={goToCreateClient}
                              onMouseDown={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                              sx={{
                                color: 'primary.main',
                                textTransform: 'none',
                                '&:hover': {
                                  backgroundColor: 'rgba(63, 81, 181, 0.04)'
                                }
                              }}
                            >
                              Add New Customer
                            </Button>
                          </div>
                        </Paper>
                      )}
                    />
                  </Box>
                </Box>
              </Paper>

              <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: 'var(--text-primary)',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#e74c3c',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Event Type
                </Typography>
                <Tabs 
                  value={eventTab} 
                  onChange={handleEventTabChange}
                  sx={{
                    mb: 3,
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontSize: '0.95rem',
                      fontFamily: 'Urbanist',
                      minHeight: '45px'
                    }
                  }}
                >
                  <Tab label="Single Day" />
                  <Tab label="Multi-Day" />
                </Tabs>

                <ScheduleTabs
                  project={project}
                  setProject={(updatedProject) => {
                    if (typeof updatedProject === 'function') {
                      setProject(updatedProject);
                    } else {
                      setProject(prev => ({ ...prev, ...updatedProject }));
                    }
                  }}
                  scheduleLater={scheduleLater}
                  setScheduleLater={setScheduleLater}
                  eventTab={eventTab}
                  handleEventTabChange={handleEventTabChange}
                />
              </Paper>

              <Paper sx={{ p: 3, mb: 3, borderRadius: '12px', boxShadow: 'none !important' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: 'var(--text-primary)',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#3498db',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Project Items
                </Typography>
                <SavedItemsManager 
                  items={project.items || []}
                  savedItems={savedItems || []}
                  onItemsChange={handleAddToEstimate}
                  onRemoveItem={handleRemoveItem}
                  showLibraryBanner={false}
                  defaultItem={{
                    title: '',
                    description: '',
                    quantity: 1,
                    unit_price: 0,
                    total: 0,
                    fromAutocomplete: false,
                    saved: false,
                    isEdited: false,
                    hideLibraryBanner: true,
                    isFromLibrary: false,
                    isSavedItem: false,
                  }}
                />
              </Paper>

              <Box sx={{ mt: 4, textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !canCreateProject}
                  onClick={handleSubmit}
                  sx={{
                    height: '45px',
                    textTransform: 'none',
                    fontSize: '0.95rem',
                    fontFamily: 'Urbanist',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    backgroundColor: '#4a5df9',
                    '&:hover': {
                      backgroundColor: '#3a4ac7'
                    }
                  }}
                >
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Create Project'}
                </Button>
              </Box>
            </>
          )}

          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={() => setShowError(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setShowError(false)}
              severity="error"
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showSuccess}
            autoHideDuration={1500}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setShowSuccess(false)}
          >
            <Alert severity="success">
              Project Created Successfully!
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default CreateProjectPage;

import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getEmployeeDashboard } from '../services/api';
import { Typography, CircularProgress } from '@mui/material';
import { 
  Briefcase as WorkIcon,
  CaretLeft as ChevronLeftIcon,
  CaretRight as ChevronRightIcon 
} from "@phosphor-icons/react";
import styles from './EmployeeSchedule.module.scss';
import { useNavigate } from 'react-router-dom';
import CustomToggle from './common/CustomToggle';

const localizer = momentLocalizer(moment);

const viewOptions = [
  { value: 'month', label: 'Month' },
  { value: 'week', label: 'Week' },
  { value: 'day', label: 'Day' },
  { value: 'agenda', label: 'Agenda' }
];

const EmployeeSchedule = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState('month');
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const data = await getEmployeeDashboard();
      const formattedEvents = data.assignedEvents.map((event) => ({
        id: event.id,
        title: event.title,
        start: new Date(event.time_slots[0].start_time),
        end: new Date(event.time_slots[0].end_time),
        allDay: false,
      }));
      setEvents(formattedEvents);
      setError(null);
    } catch (err) {
      setError('Failed to fetch schedule. Please try again later.');
    } finally {
      setLoading(false);
      setTimeout(() => setIsLoaded(true), 100);
    }
  };

  const handleSelectEvent = (event) => {
    navigate(`/employee/events/${event.id}`);
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleNavigate = (action) => {
    const newDate = new Date(date);
    switch(view) {
      case 'month':
        newDate.setMonth(date.getMonth() + (action === 'PREV' ? -1 : 1));
        break;
      case 'week':
        newDate.setDate(date.getDate() + (action === 'PREV' ? -7 : 7));
        break;
      case 'day':
        newDate.setDate(date.getDate() + (action === 'PREV' ? -1 : 1));
        break;
      default:
        break;
    }
    setDate(newDate);
  };

  const getHeaderText = () => {
    switch(view) {
      case 'month':
        return moment(date).format('MMMM YYYY');
      case 'week':
        return `Week of ${moment(date).format('MMMM D, YYYY')}`;
      case 'day':
        return moment(date).format('MMMM D, YYYY');
      case 'agenda':
        return 'Agenda';
      default:
        return '';
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: 'var(--background-secondary)',
        color: 'var(--text-primary)',
        borderRadius: '4px',
        border: 'none',
        padding: '4px 8px',
        fontSize: '0.875rem',
        fontFamily: '"Noto Sans", sans-serif',
        boxShadow: '0 2px 4px var(--shadow-color)',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        margin: '2px 0',
        height: 'auto',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 8px var(--shadow-color)',
        }
      }
    };
  };

  const EventComponent = ({ event }) => (
    <div className={styles.eventContainer}>
      <span className={styles.eventTitle}>{event.title}</span>
      <WorkIcon 
        size={16} 
        weight="light" 
        className={styles.eventIcon}
        style={{ color: '#ffffff' }}
      />
    </div>
  );

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  return (
    <div className={`${styles.scheduleContainer} ${isLoaded ? styles.fadeIn : ''}`}>
      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleTitle}>
          <WorkIcon size={24} weight="light" className={styles.scheduleIcon} />
          <Typography variant="h4">My Schedule</Typography>
        </div>
        <div className={styles.calendarControls}>
          <div className={styles.navigationControls}>
            <button onClick={() => handleNavigate('PREV')} className={styles.navButton}>
              <ChevronLeftIcon size={20} weight="bold" />
            </button>
            <Typography variant="h6" className={styles.dateText}>
              {getHeaderText()}
            </Typography>
            <button onClick={() => handleNavigate('NEXT')} className={styles.navButton}>
              <ChevronRightIcon size={20} weight="bold" />
            </button>
          </div>
          <div className={styles.viewToggle}>
            <CustomToggle
              options={viewOptions}
              value={view}
              onChange={handleViewChange}
            />
          </div>
        </div>
      </div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        className={styles.calendar}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
        view={view}
        date={date}
        onView={handleViewChange}
        onNavigate={(newDate) => setDate(newDate)}
        components={{
          event: EventComponent,
          toolbar: () => null,
        }}
        dayPropGetter={(date) => ({
          style: {
            backgroundColor: date.getDay() === 0 || date.getDay() === 6 ? 'var(--background-secondary)' : 'var(--background-paper)',
          },
        })}
      />
    </div>
  );
};

export default EmployeeSchedule;

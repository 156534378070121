import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getEmployeeDetails, updateEmployeeNotes, updateEmployeeRate, updateEmployeeDetails, getEmployeeAvailability, updateEmployeeAvailability, updateRecurringAvailability } from '../services/api';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Box, Grid, Button, Paper, IconButton, List, ListItem, ListItemIcon, ListItemText,
  Accordion, AccordionSummary, AccordionDetails, TextField, InputAdornment, Avatar, Snackbar, Alert,
  Backdrop, LinearProgress, FormGroup, 
  FormControlLabel, 
  Checkbox, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  Tabs,
  Tab
} from '@mui/material';
import { 
  User, 
  Envelope, 
  Phone as PhoneIcon,
  Briefcase, 
  CurrencyDollar,
  House,
  NotePencil,
  PencilSimple as Edit,
  Clock,
  CalendarCheck,
  Hourglass,
  Calendar as Event,
  CheckCircle,
  MagnifyingGlass,
  CaretDown,
  CalendarBlank,
  Article,
  Buildings,
  Money,
  Timer,
  CaretLeft as ArrowBack,
  ListBullets as ListAlt,
  Clock as Schedule,
  ShieldCheck
} from "@phosphor-icons/react";
import styles from './EmployeeDetails.module.scss';
import AvailabilityCalendar from './shared/AvailabilityCalendar';
import RecurringAvailabilityModal from './shared/RecurringAvailabilityModal';

const EmployeeDetails = () => {
  const location = useLocation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(() => {
    if (location.pathname.endsWith('/availability')) {
      return 'availability';
    }
    return 'details';
  });
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [notes, setNotes] = useState('');  // Add this line
  const [isEditingRate, setIsEditingRate] = useState(false);
  const [editedRate, setEditedRate] = useState('');
  const [rateError, setRateError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0 }); // Add this for progress tracking
  const [selectedRole, setSelectedRole] = useState('CUSTOM');
  const [permissions, setPermissions] = useState({
    isAdmin: false,
    canViewProjects: false,
    canEditProjects: false,
    canDeleteProjects: false,
    canManageEmployees: false,
    canViewClients: false,
    canEditClients: false,
    canManageInvoices: false,
    canAccessReports: false,
    canManageSettings: false,
    canViewTimesheets: false,
    canEditTimesheets: false,
    canApproveTimesheets: false,
  });
  const [currentAvailableDays, setCurrentAvailableDays] = useState([]);
  const [isNotesEdited, setIsNotesEdited] = useState(false);

  const ROLES = {
    ADMIN: {
      label: 'Administrator',
      description: 'Full access to all features and settings',
      permissions: {
        isAdmin: true,
        canViewSchedule: true,
        canEditSchedule: true,
        canManageSchedule: true,
        canViewProjects: true,
        canEditProjects: true,
        canDeleteProjects: true,
        canViewClients: true,
        canEditClients: true,
        canManageClients: true,
        canViewTimesheets: true,
        canEditTimesheets: true,
        canApproveTimesheets: true,
        canViewEstimates: true,
        canCreateEstimates: true,
        canManageEstimates: true,
        canViewInvoices: true,
        canCreateInvoices: true,
        canManageInvoices: true,
      }
    },
    MANAGER: {
      label: 'Manager',
      description: 'Can manage team and projects, but cannot access billing or system settings',
      permissions: {
        isAdmin: false,
        canViewSchedule: true,
        canEditSchedule: true,
        canManageSchedule: true,
        canViewProjects: true,
        canEditProjects: true,
        canDeleteProjects: false,
        canViewClients: true,
        canEditClients: true,
        canManageClients: false,
        canViewTimesheets: true,
        canEditTimesheets: true,
        canApproveTimesheets: true,
        canViewEstimates: true,
        canCreateEstimates: true,
        canManageEstimates: false,
        canViewInvoices: true,
        canCreateInvoices: false,
        canManageInvoices: false,
      }
    },
    EMPLOYEE: {
      label: 'Employee',
      description: 'Basic access to their own schedule and timesheets',
      permissions: {
        isAdmin: false,
        canViewSchedule: true,
        canEditSchedule: false,
        canManageSchedule: false,
        canViewProjects: true,
        canEditProjects: false,
        canDeleteProjects: false,
        canViewClients: false,
        canEditClients: false,
        canManageClients: false,
        canViewTimesheets: true,
        canEditTimesheets: true,
        canApproveTimesheets: false,
        canViewEstimates: false,
        canCreateEstimates: false,
        canManageEstimates: false,
        canViewInvoices: false,
        canCreateInvoices: false,
        canManageInvoices: false,
      }
    },
    CUSTOM: {
      label: 'Custom',
      description: 'Customized permissions',
      permissions: {} // Empty as it will use whatever is currently set
    }
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const data = await getEmployeeDetails(id);
        setEmployee(data);
        setNotes(data.notes || '');  // Add this line
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee details:', error);
        setError('Failed to fetch employee details');
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  useEffect(() => {
    if (employee?.id) {
      fetchEmployeeAvailability();
    }
  }, [employee?.id]);

  const fetchEmployeeAvailability = async () => {
    if (!employee?.id) return;
    
    try {
      console.log('Fetching availability for employee:', employee.id);
      const data = await getEmployeeAvailability(employee.id);
      console.log('Received availability data:', data);
      setAvailabilityDates(data);
    } catch (error) {
      console.error('Error fetching availability:', error);
    }
  };

  useEffect(() => {
    if (availabilityDates.length > 0) {
      // Get unique days from availability dates
      const uniqueDays = new Set(
        availabilityDates.map(date => new Date(date.start_time).getDay())
      );
      const sortedDays = Array.from(uniqueDays).sort((a, b) => a - b);
      console.log('Current available days:', sortedDays);
      setCurrentAvailableDays(sortedDays);
    } else {
      setCurrentAvailableDays([]);
    }
  }, [availabilityDates]);

  useEffect(() => {
    if (employee?.permissions) {
      setPermissions(employee.permissions);
      // Find matching role or set to CUSTOM
      const matchingRole = Object.entries(ROLES).find(([role, data]) => 
        JSON.stringify(data.permissions) === JSON.stringify(employee.permissions)
      );
      setSelectedRole(matchingRole ? matchingRole[0] : 'CUSTOM');
    }
  }, [employee]);

  if (loading) return <div className="loading"><CircularProgress /></div>;
  if (error) return <div className="error"><Typography color="error">{error}</Typography></div>;
  if (!employee) return <div className="error"><Typography>No employee data found</Typography></div>;

  const renderDetails = () => (
    <Box sx={{ p: 3 }}>
      {/* Header with Edit/Save Buttons */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3,
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontFamily: 'Urbanist',
            color: 'var(--text-primary)',
            fontWeight: 600,
          }}
        >
        </Typography>
        {!isEditing ? (
          <Button
            variant="outlined"
            startIcon={<Edit weight="light" size={20} />}
            onClick={handleEditDetails}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: 'Urbanist',
              padding: '8px 16px',
              borderColor: '#4a5df9',
              color: '#4a5df9',
              '&:hover': {
                borderColor: '#3a4cd9',
                backgroundColor: 'rgba(74, 93, 249, 0.04)'
              }
            }}
          >
            Edit Details
          </Button>
        ) : (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIsEditing(false)}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                fontFamily: 'Urbanist',
                padding: '8px 16px'
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveDetails}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                fontFamily: 'Urbanist',
                padding: '8px 16px',
                backgroundColor: '#4a5df9',
                '&:hover': {
                  backgroundColor: '#3a4cd9'
                }
              }}
            >
              Save Changes
            </Button>
          </Box>
        )}
      </Box>

      <Grid container spacing={3}>
        {/* Personal Information Card */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            backgroundColor: 'var(--background-paper)',
            borderRadius: '12px',
            p: 3,
            height: '100%',
          }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 3,
                fontWeight: 600,
                color: 'var(--text-primary)',
                fontFamily: 'Urbanist'
              }}
            >
              Personal Information
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {[
                { icon: User, label: 'Full Name', value: employee.name, field: 'name', color: '#4CAF50' },
                { icon: Envelope, label: 'Email Address', value: employee.email, field: 'email', color: '#2196F3' },
                { icon: PhoneIcon, label: 'Phone Number', value: employee.phone, field: 'phone', color: '#FFC107' }
              ].map((item) => (
                <Box key={item.field} sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                  <Box sx={{
                    p: 1,
                    borderRadius: '8px',
                    backgroundColor: `${item.color}10`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <item.icon weight="light" size={20} style={{ color: item.color }} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="caption" sx={{ color: 'var(--text-secondary)', display: 'block', mb: 0.5 }}>
                      {item.label}
                    </Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedDetails[item.field] || ''}
                        onChange={(e) => handleInputChange(item.field, e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'var(--background-default)',
                            '& fieldset': { borderColor: 'var(--divider)' },
                            '&:hover fieldset': { borderColor: 'var(--divider)' },
                            '&.Mui-focused fieldset': { borderColor: 'var(--primary-main)' }
                          }
                        }}
                      />
                    ) : (
                      <Typography variant="body1" sx={{ color: 'var(--text-primary)', fontWeight: 500 }}>
                        {item.value}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Work Information Card */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            backgroundColor: 'var(--background-paper)',
            borderRadius: '12px',
            p: 3,
            height: '100%',
          }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 3,
                fontWeight: 600,
                color: 'var(--text-primary)',
                fontFamily: 'Urbanist'
              }}
            >
              Work Information
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {[
                { icon: Briefcase, label: 'Position', value: employee.position, field: 'position', color: '#9C27B0' },
                { icon: CurrencyDollar, label: 'Default Rate', value: `$${employee.default_rate}/hr`, field: 'default_rate', color: '#4CAF50', isRate: true },
                { icon: House, label: 'Address', value: employee.address, field: 'address', color: '#E91E63' }
              ].map((item) => (
                <Box key={item.field} sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                  <Box sx={{
                    p: 1,
                    borderRadius: '8px',
                    backgroundColor: `${item.color}10`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <item.icon weight="light" size={20} style={{ color: item.color }} />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="caption" sx={{ color: 'var(--text-secondary)', display: 'block', mb: 0.5 }}>
                      {item.label}
                    </Typography>
                    {isEditing && !item.isRate ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedDetails[item.field] || ''}
                        onChange={(e) => handleInputChange(item.field, e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'var(--background-default)',
                            '& fieldset': { borderColor: 'var(--divider)' },
                            '&:hover fieldset': { borderColor: 'var(--divider)' },
                            '&.Mui-focused fieldset': { borderColor: 'var(--primary-main)' }
                          }
                        }}
                      />
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ color: 'var(--text-primary)', fontWeight: 500 }}>
                          {item.value}
                        </Typography>
                        {item.isRate && (
                          <IconButton 
                            size="small" 
                            onClick={() => {
                              setEditedRate(employee.default_rate.toString());
                              setIsEditingRate(true);
                            }}
                            sx={{ ml: 1, color: 'var(--text-secondary)' }}
                          >
                            <Edit weight="light" size={16} />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Notes Section */}
        <Grid item xs={12}>
          <Box sx={{ 
            backgroundColor: 'var(--background-paper)',
            borderRadius: '12px',
            p: 3,
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px'
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 3,
            }}>
              <Box sx={{
                width: 40,
                height: 40,
                borderRadius: '8px',
                backgroundColor: '#FF572210',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2
              }}>
                <NotePencil weight="light" size={20} style={{ color: '#FF5722' }} />
              </Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: 'var(--text-primary)',
                  fontFamily: 'Urbanist'
                }}
              >
                Notes
              </Typography>
            </Box>
            
            <Box sx={{
              p: 2,
              border: '1px solid var(--divider)',
              borderRadius: '12px',
              backgroundColor: 'var(--background-default)',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: 'var(--text-secondary)',
              },
              '&:focus-within': {
                borderColor: 'var(--primary-main)',
                boxShadow: '0 0 0 2px var(--primary-lighter)',
              }
            }}>
              <TextField
                multiline
                rows={4}
                fullWidth
                placeholder="Add notes about this employee..."
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  setIsNotesEdited(true);
                }}
                variant="standard"
                sx={{ 
                  '& .MuiInputBase-root': {
                    padding: 0,
                    '&::before, &::after': {
                      display: 'none'
                    }
                  },
                  '& .MuiInputBase-input': {
                    color: 'var(--text-primary)',
                    fontFamily: 'Urbanist',
                    fontSize: '0.95rem',
                    lineHeight: 1.6,
                    '&::placeholder': {
                      color: 'var(--text-secondary)',
                      opacity: 0.7
                    }
                  }
                }}
              />
            </Box>
            {isNotesEdited && (
              <Box sx={{ 
                mt: 2,
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <Button 
                  variant="contained" 
                  onClick={() => {
                    handleSaveNotes();
                    setIsNotesEdited(false);
                  }}
                  startIcon={<NotePencil weight="light" size={20} />}
                  sx={{ 
                    textTransform: 'none',
                    fontFamily: 'Urbanist',
                    backgroundColor: '#4a5df9',
                    borderRadius: '8px',
                    padding: '8px 16px',
                    '&:hover': {
                      backgroundColor: '#3a4cd9'
                    }
                  }}
                >
                  Save Notes
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const headerCells = [
    { icon: <Clock weight="light" size={20} style={{ color: '#4CAF50' }} />, label: 'CLOCK IN' },
    { icon: <CalendarCheck weight="light" size={20} style={{ color: '#2196F3' }} />, label: 'CLOCK OUT' },
    { icon: <Hourglass weight="light" size={20} style={{ color: '#FFC107' }} />, label: 'HOURS WORKED' },
    { icon: <Briefcase weight="light" size={20} style={{ color: '#9C27B0' }} />, label: 'PROJECT' },
    { icon: <Event weight="light" size={20} style={{ color: '#E91E63' }} />, label: 'EVENT' },
    { icon: <CheckCircle weight="light" size={20} style={{ color: '#009688' }} />, label: 'CALCULATED PAYMENT' },
  ];

  const renderTimesheets = () => {
    const validTimesheets = employee.recent_timesheets.filter(timesheet => 
      timesheet.calculated_payment !== null &&
      timesheet.clock_in &&
      timesheet.clock_out &&
      timesheet.hours_worked !== null &&
      timesheet.event &&
      timesheet.event.project_name &&
      timesheet.event.title
    );

    return (
      <Box sx={{ p: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headerCells.map((cell, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{ 
                      cursor: 'default',
                      backgroundColor: 'var(--background-secondary)',
                      borderBottom: '1px solid #e0e0e0 !important',
                      fontFamily: 'Urbanist, sans-serif',
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      color: 'var(--text-secondary)!important',
                      letterSpacing: '0.5px',
                      padding: '12px 16px',
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      {cell.icon}
                      <Typography 
                        sx={{ 
                          ml: 1,
                          fontFamily: 'Urbanist, sans-serif',
                          fontSize: '0.75rem',
                          fontWeight: 600,
                          color: 'var(--text-secondary)!important',
                          letterSpacing: '0.5px',
                        }}
                      >
                        {cell.label}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {validTimesheets.map((timesheet, index) => (
                <TableRow 
                  key={timesheet.id} 
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'var(--action-hover)'
                    }
                  }}
                >
                  <TableCell align="center">{new Date(timesheet.clock_in).toLocaleString()}</TableCell>
                  <TableCell align="center">{new Date(timesheet.clock_out).toLocaleString()}</TableCell>
                  <TableCell align="center">{timesheet.hours_worked.toFixed(2)}</TableCell>
                  <TableCell align="center">{timesheet.event.project_name}</TableCell>
                  <TableCell align="center">{timesheet.event.title}</TableCell>
                  <TableCell align="center">
                    ${timesheet.calculated_payment.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderEvents = () => {
    if (!employee.assigned_events || employee.assigned_events.length === 0) {
      return (
        <Typography variant="body1" sx={{ fontFamily: 'Urbanist, sans-serif', textAlign: 'center', mt: 2 }}>
          No events assigned to this employee.
        </Typography>
      );
    }

    const currentDate = new Date();
    const upcomingEvents = employee.assigned_events.filter(event => 
      new Date(event.time_slots[event.time_slots.length - 1].end_time) >= currentDate
    );
    const completedEvents = employee.assigned_events.filter(event => 
      new Date(event.time_slots[event.time_slots.length - 1].end_time) < currentDate
    );

    console.log("All assigned events:", employee.assigned_events);
    console.log("Upcoming events:", upcomingEvents);
    console.log("Completed events:", completedEvents);

    const renderEventList = (events, title) => (
      <div>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Urbanist, sans-serif', mb: 2 }}>
          <Event sx={{ verticalAlign: 'middle', mr: 1, color: '#4CAF50' }} />
          {title}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder={`Search ${title.toLowerCase()}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlass weight="light" size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {events
          .filter(event => event.title.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((event, index) => {
            console.log(`Event ${index}:`, event);
            
            // Find all timesheets for this event
            const eventTimesheets = employee.recent_timesheets.filter(ts => ts.event && ts.event.id === event.id);
            console.log(`Timesheets for event ${index}:`, eventTimesheets);

            // Calculate total payment and hours
            const totalPayment = eventTimesheets.reduce((sum, ts) => sum + (ts.calculated_payment || 0), 0);
            const totalHours = eventTimesheets.reduce((sum, ts) => sum + (ts.hours_worked || 0), 0);

            return (
              <Accordion key={event.id} sx={{ mb: 2,  }}>
                <AccordionSummary
                  expandIcon={<CaretDown weight="light" size={20} />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  sx={{ 
                    backgroundColor: 'rgba(0, 0, 0, 0.03)', 
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' } 
                  }}
                >
                  <Typography sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
                    <CalendarBlank weight="light" size={20} style={{ mr: 1, fontSize: 20, color: '#2196F3' }} />
                    {event.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                      <Article weight="light" size={20} style={{ mr: 1, fontSize: 20, color: '#FFC107' }} />
                      <strong>Description:</strong> {event.description}
                    </Typography>
                    {event.project && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <Buildings weight="light" size={20} style={{ mr: 1, fontSize: 20, color: '#9C27B0' }} />
                        <strong>Project:</strong> {event.project.name}
                      </Typography>
                    )}
                    {totalPayment > 0 && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <Money weight="light" size={20} style={{ mr: 1, fontSize: 20, color: '#4CAF50' }} />
                        <strong>Total Payment:</strong> ${totalPayment.toFixed(2)}
                      </Typography>
                    )}
                    {totalHours > 0 && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <Timer weight="light" size={20} style={{ mr: 1, fontSize: 20, color: '#FF9800' }} />
                        <strong>Total Hours Worked:</strong> {totalHours.toFixed(2)}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', mt: 1, mb: 0.5, fontWeight: 'medium' }}>
                      <Schedule sx={{ mr: 1, fontSize: 20, verticalAlign: 'middle', color: '#E91E63' }} />
                      Time Slots:
                    </Typography>
                    <List dense sx={{ pl: 2, mt: -1 }}>
                      {event.time_slots.map((slot, slotIndex) => (
                        <ListItem key={slotIndex} sx={{ py: 0.5 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <Schedule fontSize="small" sx={{ color: '#009688' }} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={`${new Date(slot.start_time).toLocaleString()} - ${new Date(slot.end_time).toLocaleString()}`}
                            primaryTypographyProps={{ variant: 'body2', fontFamily: 'Urbanist, sans-serif' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    );

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {renderEventList(upcomingEvents, "Upcoming Events")}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderEventList(completedEvents, "Completed Events")}
        </Grid>
      </Grid>
    );
  };

  const handleSaveNotes = async () => {
    try {
      await updateEmployeeNotes(id, notes);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
    } catch (error) {
      console.error('Error saving notes:', error);
      // You might want to show an error message here
    }
  };

  const handleRateUpdate = async () => {
    try {
      setRateError('');
      const numRate = parseFloat(editedRate);
      
      if (isNaN(numRate) || numRate < 0) {
        setRateError('Please enter a valid rate');
        return;
      }

      await updateEmployeeRate(id, numRate);
      setEmployee(prev => ({
        ...prev,
        default_rate: numRate
      }));
      setIsEditingRate(false);
    } catch (error) {
      setRateError('Failed to update rate');
      console.error('Error updating rate:', error);
    }
  };

  const handleEditDetails = () => {
    setIsEditing(true);
    setEditedDetails({
      name: employee.name,
      email: employee.email,
      phone: employee.phone,
      position: employee.position,
      address: employee.address,
    });
  };

  const handleSaveDetails = async () => {
    try {
      await updateEmployeeDetails(id, editedDetails);
      setEmployee(prev => ({
        ...prev,
        ...editedDetails
      }));
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating employee details:', error);
    }
  };

  const handleInputChange = (field, value) => {
    setEditedDetails(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAvailabilityChange = async (info) => {
    console.log('Received from calendar:', info); // Should have date and is_available

    try {
      // Use the data directly from the calendar
      await updateEmployeeAvailability(
        employee.id,
        info.date,           // Use this directly
        info.is_available    // Use this directly
      );

      // Refresh after successful update
      await fetchEmployeeAvailability();
    } catch (error) {
      console.error('Error updating availability:', error);
    }
  };

  const handleRecurringAvailability = async (data) => {
    try {
      setIsUpdating(true);
      
      if (data.selectedDays.length > 0) {
        await updateRecurringAvailability(
          employee.id,
          data.selectedDays,
          data.startDate,
          data.endDate,
          true
        );
      }

      if (data.removedDays.length > 0) {
        await updateRecurringAvailability(
          employee.id,
          data.removedDays,
          data.startDate,
          data.endDate,
          false
        );
      }

      await fetchEmployeeAvailability();
      setShowRecurringModal(false);
    } catch (error) {
      console.error('Error updating recurring availability:', error);
      setError('Failed to update recurring availability');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    if (role !== 'CUSTOM') {
      setPermissions(ROLES[role].permissions);
    }
  };

  const handlePermissionChange = (permission) => {
    setPermissions(prev => {
      const newPermissions = {
        ...prev,
        [permission]: !prev[permission]
      };
      // If permissions don't match any predefined role, set to CUSTOM
      const matchingRole = Object.entries(ROLES).find(([role, data]) => 
        JSON.stringify(data.permissions) === JSON.stringify(newPermissions)
      );
      if (!matchingRole) {
        setSelectedRole('CUSTOM');
      }
      return newPermissions;
    });
  };
  const handleSavePermissions = async () => {
    try {
      setIsUpdating(true);
      
      // Will implement API call once backend is ready
      // const response = await updateEmployeePermissions(employee.id, {
      //   role: selectedRole,
      //   permissions: permissions
      // });
  
      // Show success notification
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
  
      // Refresh employee details
      const updatedEmployee = await getEmployeeDetails(employee.id);
      setEmployee(updatedEmployee);
      
    } catch (error) {
      console.error('Error saving permissions:', error);
      setError('Failed to update permissions');
    } finally {
      setIsUpdating(false);
    }
  };

  const renderPermissions = () => (
    <Box sx={{ p: 3, backgroundColor: 'white', borderRadius: 2 }}>
      <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
        Permissions
      </Typography>

      {/* Role Selection */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
          Role
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedRole}
            onChange={(e) => handleRoleChange(e.target.value)}
          >
            {Object.entries(ROLES).map(([key, role]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {role.label}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {role.description}
                    </Typography>
                  </Box>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>

      {/* Grid Layout for Permission Groups */}
      <Grid container spacing={4}>
        {/* Schedule Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Schedule
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewSchedule} onChange={(e) => handlePermissionChange('canViewSchedule', e.target.checked)} />}
                label="View their own schedule"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditSchedule} onChange={(e) => handlePermissionChange('canEditSchedule', e.target.checked)} />}
                label="Edit their own schedule"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageSchedule} onChange={(e) => handlePermissionChange('canManageSchedule', e.target.checked)} />}
                label="Manage all schedules"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Project Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Projects
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewProjects} onChange={(e) => handlePermissionChange('canViewProjects', e.target.checked)} />}
                label="View projects"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditProjects} onChange={(e) => handlePermissionChange('canEditProjects', e.target.checked)} />}
                label="Edit projects"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canDeleteProjects} onChange={(e) => handlePermissionChange('canDeleteProjects', e.target.checked)} />}
                label="Delete projects"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Estimates Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Estimates
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewEstimates} onChange={(e) => handlePermissionChange('canViewEstimates', e.target.checked)} />}
                label="View estimates"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canCreateEstimates} onChange={(e) => handlePermissionChange('canCreateEstimates', e.target.checked)} />}
                label="Create estimates"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageEstimates} onChange={(e) => handlePermissionChange('canManageEstimates', e.target.checked)} />}
                label="Manage all estimates"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Invoice Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Invoices
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewInvoices} onChange={(e) => handlePermissionChange('canViewInvoices', e.target.checked)} />}
                label="View invoices"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canCreateInvoices} onChange={(e) => handlePermissionChange('canCreateInvoices', e.target.checked)} />}
                label="Create invoices"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageInvoices} onChange={(e) => handlePermissionChange('canManageInvoices', e.target.checked)} />}
                label="Manage all invoices"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Client Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Clients
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewClients} onChange={(e) => handlePermissionChange('canViewClients', e.target.checked)} />}
                label="View clients"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditClients} onChange={(e) => handlePermissionChange('canEditClients', e.target.checked)} />}
                label="Edit clients"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageClients} onChange={(e) => handlePermissionChange('canManageClients', e.target.checked)} />}
                label="Manage clients"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Timesheet Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Timesheets
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewTimesheets} onChange={(e) => handlePermissionChange('canViewTimesheets', e.target.checked)} />}
                label="View timesheets"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditTimesheets} onChange={(e) => handlePermissionChange('canEditTimesheets', e.target.checked)} />}
                label="Edit timesheets"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canApproveTimesheets} onChange={(e) => handlePermissionChange('canApproveTimesheets', e.target.checked)} />}
                label="Approve timesheets"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>

      {/* Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSavePermissions}
        >
          Save Permissions
        </Button>
      </Box>
    </Box>
  );

  const renderAvailability = () => (
    <Box sx={{ p: 3 }}>
      {/* Title and Description Container */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2 
      }}>
        <Typography variant="h6" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
          Employee Availability Calendar
        </Typography>
        <Typography variant="body2" color="var(--text-secondary)">
          Click on dates to toggle availability
        </Typography>
      </Box>

      {/* Button Container */}
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          mb: 3,
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 2
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              mb: 0.5,
              fontWeight: 500,
              color: 'var(--text-primary)'
            }}
          >
            Recurring Availability
          </Typography>
          <Typography 
            variant="body2" 
            color="var(--text-secondary)"
            sx={{ fontWeight: 400 }}
          >
            Set regular weekly availability pattern
          </Typography>
        </Box>
        <Button 
          variant="outlined"
          onClick={() => setShowRecurringModal(true)}
          startIcon={<Schedule sx={{ fontSize: 20 }} />}
          sx={{ 
            minWidth: 200,
            height: 42,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 500,
            border: '1px solid',
            borderColor: 'primary.main',
            color: 'primary.main',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'white',
              borderColor: 'primary.main',
            },
            transition: 'all 0.2s ease-in-out'
          }}
        >
          Set Weekly Schedule
        </Button>
      </Box>

      {/* Calendar */}
      <AvailabilityCalendar
        availabilityDates={availabilityDates}
        onDateClick={handleAvailabilityChange}
        readOnly={false}
      />

      {/* Modal */}
      <RecurringAvailabilityModal
        open={showRecurringModal}
        onClose={() => setShowRecurringModal(false)}
        onSave={handleRecurringAvailability}
        initialSelectedDays={currentAvailableDays}
        isLoading={isUpdating}
      />
    </Box>
  );

  const LoadingOverlay = () => (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
      open={isUpdating}
    >
      <Box
        sx={{
          bgcolor: 'var(--background-paper)',
          borderRadius: 2,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          maxWidth: 400,
          textAlign: 'center'
        }}
      >
        <CircularProgress 
          size={60}
          thickness={4}
          sx={{ color: 'primary.main' }}
        />
        <Typography variant="h6" color="text.primary">
          Setting Recurring Availability
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Processing {progress.current} of {progress.total} dates...
        </Typography>
        {progress.total > 0 && (
          <Box sx={{ width: '100%', mt: 1 }}>
            <LinearProgress 
              variant="determinate" 
              value={(progress.current / progress.total) * 100} 
            />
          </Box>
        )}
      </Box>
    </Backdrop>
  );

  // Add these styles to your component
  const styles = {
    permissionSection: {
      mb: 4,
      backgroundColor: 'var(--background-paper)',
    },
    
    sectionTitle: {
      fontWeight: 600,
      mb: 2,
    },
    sectionDescription: {
      color: 'text.secondary',
      mb: 3,
    },
    permissionGroup: {
      mb: 3,
    },
    permissionLabel: {
      fontWeight: 500,
    },
    permissionDescription: {
      color: 'text.secondary',
      fontSize: '0.875rem',
    }
  };

  const tabs = [
    { id: 'details', icon: User, label: 'Details' },
    { id: 'timesheets', icon: ListAlt, label: 'Timesheets' },
    { id: 'availability', icon: Schedule, label: 'Availability' },
    { id: 'permissions', icon: ShieldCheck, label: 'Permissions' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'details':
        return renderDetails();
      case 'availability':
        return renderAvailability();
      case 'permissions':
        return renderPermissions();
      default:
        return renderDetails();
    }
  };

  return (
    <div className={`${styles['employee-details']} ${styles['fade-in']}`}>
      <IconButton 
        onClick={() => navigate(-1)} 
        className={styles['back-button']}
        sx={{
          color: 'var(--text-primary)',
        }}
      >
        <ArrowBack />
      </IconButton>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
        <Avatar 
          src={employee.avatar_url || undefined} 
          alt={employee.name} 
          sx={{ width: 100, height: 100, mb: 2 }}
        >
          {employee.name ? employee.name.charAt(0) : ''}
        </Avatar>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
          {employee.name}
        </Typography>
      </Box>

      <Box sx={{ 
        backgroundColor: 'var(--background-paper)',
        borderBottom: '1px solid var(--divider)',
      }}>
        <Tabs 
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            px: 2,
            '& .MuiTabs-indicator': {
              backgroundColor: '#7635dc',
              height: '3px',
              borderRadius: '3px 3px 0 0'
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              color: 'var(--text-secondary)',
              minHeight: 48,
              padding: '12px 24px',
              gap: '8px',
              '& svg': {
                marginBottom: '0 !important',
                marginRight: '0 !important'
              },
              '&.Mui-selected': {
                color: 'var(--primary-main)',
                fontWeight: 600,
              },
              '&:hover': {
                color: 'var(--primary-main)',
                opacity: 0.8,
              },
            },
          }}
        >
          <Tab 
            icon={<User size={20} weight="light" />}
            iconPosition="start"
            label="Details" 
            value="details"
            disableRipple
          />
          <Tab 
            icon={<ListAlt size={20} weight="light" />}
            iconPosition="start"
            label="Timesheets" 
            value="timesheets"
            disableRipple
          />
          <Tab 
            icon={<Schedule size={20} weight="light" />}
            iconPosition="start"
            label="Availability" 
            value="availability"
            disableRipple
          />
          <Tab 
            icon={<ShieldCheck size={20} weight="light" />}
            iconPosition="start"
            label="Permissions" 
            value="permissions"
            disableRipple
          />
        </Tabs>
      </Box>

      <div key={activeTab} className={`${styles['tab-content']} ${styles['fade-up']} ${styles['color-primary']}`}>
        {activeTab === 'details' && renderDetails()}
        {activeTab === 'timesheets' && renderTimesheets()}
        {activeTab === 'availability' && renderAvailability()}
        {activeTab === 'permissions' && renderPermissions()}
      </div>

      {showNotification && (
        <Snackbar
          open={showNotification}
          autoHideDuration={3000}
          onClose={() => setShowNotification(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setShowNotification(false)} 
            severity="success" 
            sx={{ width: '100%' }}
          >
            Notes saved successfully!
          </Alert>
        </Snackbar>
      )}
      <LoadingOverlay />
    </div>
  );
};

export default EmployeeDetails;

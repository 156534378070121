import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import hardcodedLogo from './Updated logo (12).png';
import { Box, Typography, Divider, Grid } from '@mui/material';
import { formatDate, formatDateTime } from '../utils/dateUtils';

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  const cleaned = phoneNumberString.toString().replace(/[^0-9]/g, '');
  const last10Digits = cleaned.slice(-10);
  if (last10Digits.length === 10) {
    return `(${last10Digits.slice(0,3)}) ${last10Digits.slice(3,6)}-${last10Digits.slice(6)}`;
  }
  return phoneNumberString;
};

const DefaultTemplate = ({ estimate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (!estimate) {
    return <div>Loading...</div>;
  }

  const safeItems = (estimate.items || []).map(item => ({
    title: item.title || '',
    description: item.description || '',
    quantity: Number(item.quantity) || 0,
    unitPrice: Number(item.unit_price || item.unitPrice) || 0,
    tax: Number(item.tax) || 0
  }));

  const styles = {
    container: {
      width: '210mm',
      minHeight: '297mm',
      margin: '0 auto',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      padding: '20mm',
      '@media print': {
        width: '210mm',
        height: '297mm',
        margin: 0,
        padding: '20mm'
      }
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginBottom: '20px',
    },
    logo: {
      maxWidth: '150px',
      height: 'auto',
      objectFit: 'contain'
    },
    headerInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    businessInfo: {
      width: '200px'
    },
    estimateInfo: {
      textAlign: 'right',
      marginLeft: 'auto',
      width: '200px'
    },
    divider: {
      border: 'none',
      height: '2px',
      backgroundColor: '#eee',
      margin: '20px 0 30px 0'
    },
    addressGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '30px',
      marginBottom: '40px'
    },
    sectionTitle: {
      fontSize: '16px',
      color: '#2c3e50',
      marginBottom: '10px',
      fontWeight: 500
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
      backgroundColor: '#fff',
      pageBreakInside: 'avoid'
    },
    tableHeader: {
      backgroundColor: '#f8f9ff',
      padding: '12px',
      textAlign: 'left',
      fontSize: '1.1rem',
      fontWeight: 600,
      color: '#2c3e50',
      borderBottom: '2px solid #eee'
    },
    tableCell: {
      padding: '12px',
      borderBottom: '1px solid #eee',
      verticalAlign: 'top',
      pageBreakInside: 'avoid'
    },
    totalsSection: {
      marginTop: '20px',
      marginLeft: 'auto',
      width: '200px',
      pageBreakInside: 'avoid',
      '& > div': {
        pageBreakInside: 'avoid'
      }
    },
    totalRow: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px 0',
      borderBottom: '1px solid #eee'
    },
    totalRowBold: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '12px 0',
      fontWeight: 'bold',
      fontSize: '1.1em',
      color: '#2c3e50',
      borderTop: '2px solid #eee'
    },
    signatureSection: {
      marginTop: '40px',
      padding: '20px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      border: '1px solid #e0e0e0'
    },
    photosGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '20px',
      marginTop: '20px',
      pageBreakBefore: 'always'
    },
    photoCard: {
      border: '1px solid #eee',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      cursor: 'pointer',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'translateY(-2px)'
      }
    },
    photoImage: {
      width: '100%',
      height: '300px',
      objectFit: 'cover'
    },
    termsSection: {
      marginTop: '30px',
      padding: '20px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      border: '1px solid #e0e0e0'
    },

  };

  const calculateSubtotal = () => safeItems.reduce((sum, item) => 
    sum + (item.quantity * item.unitPrice), 0);

  const calculateTax = () => safeItems.reduce((sum, item) => 
    sum + (item.quantity * item.unitPrice * item.tax / 100), 0);

  const calculateTotal = () => calculateSubtotal() + calculateTax();

  const formatAddress = (details) => {
    if (!details) return null;
    
    const name = details.name || details.customer_name || '';
    const company = details.company || details.customer_company || '';
    const address = details.address || details.customer_address || '';
    const city = details.city || details.customer_city || '';
    const state = details.state || details.customer_state || '';
    const zip = details.zip || details.zipCode || details.zip_code || details.customer_zipCode || '';
    const phone = details.phone || details.customer_phone || '';
    const email = details.email || details.customer_email || '';
    
    return (
      <>
        {name && <p style={{ margin: '0 0 5px 0', fontWeight: '500' }}>{name}</p>}
        {company && <p style={{ margin: '0 0 5px 0' }}>{company}</p>}
        {address && <p style={{ margin: '0 0 5px 0' }}>{address}</p>}
        {(city || state || zip) && (
          <p style={{ margin: '0 0 5px 0' }}>
            {[city, state, zip].filter(Boolean).join(', ')}
          </p>
        )}
        {phone && <p style={{ margin: '0 0 5px 0', color: '#666' }}>Phone: {phone}</p>}
        {email && <p style={{ margin: '0 0 5px 0', color: '#666' }}>Email: {email}</p>}
      </>
    );
  };

  const imageUrls = estimate?.project_images?.map(image => ({
    src: image?.url 
      ? (image.url.startsWith('http') 
        ? image.url 
        : `${process.env.REACT_APP_API_URL}${image.url}`)
      : ''
  })) || [];

  return (
    <div className="default-template" style={styles.container}>
      {/* Header Section */}
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: "10px",
        '& > *': {
          minHeight: 'auto',
          lineHeight: 1
        }
      }}>
        {/* Logo container */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'flex-start',
          mb: 1
        }}>
          <img 
            src={hardcodedLogo}
            alt="Logo"
            style={{ 
              maxWidth: '120px',
              height: 'auto',
              objectFit: 'contain'
            }}
          />
        </Box>

        {/* Estimate Info */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
          <Box>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 500,
                fontSize: '1.5rem !important',
                lineHeight: 1
              }}
            >
              Estimate
            </Typography>
            <Typography 
              color="textSecondary"
              sx={{ fontSize: '0.9rem' }}
            >
              ID #{estimate?.estimate_number || 'N/A'}
            </Typography>
          </Box>

          <Box sx={{ 
            textAlign: 'right', 
            '& .MuiTypography-root': {
              fontSize: '0.9rem',
              lineHeight: 1.3
            }
          }}>
            <Typography color="textSecondary">Issue Date: {formatDate(estimate?.issue_date)}</Typography>
            <Typography color="textSecondary">Valid Until: {formatDate(estimate?.expiration_date)}</Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />

        {/* Bill From/To Grid */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ mb: 1, color: '#2c3e50', fontWeight: 600, fontSize: '0.9rem' }}>
              BILL FROM
            </Typography>
            <Box sx={{ '& .MuiTypography-root': { fontSize: '0.9rem', lineHeight: 1.3 } }}>
              <Typography sx={{ mb: 0.5 }}>{estimate.business_details.company_name}</Typography>
              <Typography sx={{ mb: 0.5 }}>{estimate.business_details.address}</Typography>
              <Typography sx={{ mb: 1 }}>
                {estimate.business_details.city}, {estimate.business_details.state}, {estimate.business_details.zip_code}
              </Typography>
              <Typography sx={{ color: '#666' }}>Phone: {formatPhoneNumber(estimate.business_details.phone)}</Typography>
              <Typography sx={{ color: '#666' }}>Email: {estimate.business_details.email}</Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="h6" sx={{ mb: 1, color: '#2c3e50', fontWeight: 600, fontSize: '0.9rem' }}>
                BILL TO
              </Typography>
              <Box sx={{ '& .MuiTypography-root': { fontSize: '0.9rem', lineHeight: 1.3 } }}>
                <Typography sx={{ mb: 0.5 }}>{estimate.customer_details.name}</Typography>
                {estimate.customer_details.company && (
                  <Typography sx={{ mb: 0.5 }}>{estimate.customer_details.company}</Typography>
                )}
                {estimate.customer_details.address && (
                  <Typography sx={{ mb: 0.5 }}>{estimate.customer_details.address}</Typography>
                )}
                {(estimate.customer_details.city || estimate.customer_details.state || 
                  estimate.customer_details.zip || estimate.customer_details.zipCode || 
                  estimate.customer_details.zip_code) && (
                  <Typography sx={{ mb: 0.5 }}>
                    {[
                      estimate.customer_details.city,
                      estimate.customer_details.state,
                      estimate.customer_details.zip || estimate.customer_details.zipCode || estimate.customer_details.zip_code
                    ].filter(Boolean).join(', ')}
                  </Typography>
                )}
                {estimate.customer_details.phone && (
                  <Typography sx={{ color: '#666' }}>
                    Phone: {formatPhoneNumber(estimate.customer_details.phone)}
                  </Typography>
                )}
                {estimate.customer_details.email && (
                  <Typography sx={{ color: '#666', mb: 2 }}>
                    Email: {estimate.customer_details.email}
                  </Typography>
                )}

                {/* Project Name */}
                <Typography variant="h6" sx={{ mb: 1, color: '#2c3e50', fontWeight: 600, fontSize: '0.9rem' }}>
                  PROJECT NAME
                </Typography>
                <Typography sx={{ mb: 2 }}>{estimate.project_name}</Typography>

                {/* Service Address Section */}
                <Typography variant="h6" sx={{ mb: 1, color: '#2c3e50', fontWeight: 600, fontSize: '0.9rem' }}>
                  PROJECT ADDRESS
                </Typography>
                <Typography sx={{ mb: 0.5 }}>{estimate.service_address}</Typography>
                <Typography sx={{ mb: 0.5 }}>
                  {[
                    estimate.service_city, 
                    estimate.service_state, 
                    estimate.service_zip || estimate.service_zipCode || estimate.service_zip_code
                  ].filter(Boolean).join(', ')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>



      {/* Items Table */}
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={{ ...styles.tableHeader, width: '60%' }}>Item</th>
            <th style={{ ...styles.tableHeader, textAlign: 'right' }}>Quantity</th>
            <th style={{ ...styles.tableHeader, textAlign: 'right' }}>Unit Price</th>
            <th style={{ ...styles.tableHeader, textAlign: 'right' }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {safeItems.map((item, index) => {
            const itemUnitPrice = Number(item.unitPrice) || 0;
            const itemQuantity = Number(item.quantity) || 0;
            const itemTax = Number(item.tax) || 0;
            const total = itemQuantity * itemUnitPrice * (1 + itemTax / 100);

            return (
              <tr key={index}>
                <td style={styles.tableCell}>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '1rem',
                      mb: 0.5,
                      color: '#2c3e50'
                    }}
                  >
                    {item.title}
                  </Typography>
                  {item.description && (
                    <div style={styles.itemDescription}>
                      {item.description.split('\n').map((line, i) => (
                        <div key={i} style={{ 
                          fontSize: '0.9rem',
                          color: '#34495e',
                        }}>
                          {line}
                        </div>
                      ))}
                    </div>
                  )}
                </td>
                <td style={{ ...styles.tableCell, textAlign: 'right' }}>{itemQuantity}</td>
                <td style={{ ...styles.tableCell, textAlign: 'right' }}>${itemUnitPrice.toFixed(2)}</td>
                <td style={{ ...styles.tableCell, textAlign: 'right' }}>${total.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Totals Section */}
      <Box sx={{ pageBreakInside: 'avoid' }}>
        <div style={styles.totalsSection}>
          <div style={styles.totalRow}>
            <span>Subtotal:</span>
            <span>${calculateSubtotal().toFixed(2)}</span>
          </div>
          <div style={styles.totalRow}>
            <span>Tax:</span>
            <span>${calculateTax().toFixed(2)}</span>
          </div>
          <div style={styles.totalRowBold}>
            <span>Total:</span>
            <span>${calculateTotal().toFixed(2)}</span>
          </div>
        </div>
      </Box>

      {/* Signature Section */}
      <div style={styles.signatureSection}>
        <p style={{ margin: '0 0 15px 0', fontWeight: '500' }}>Customer Signature:</p>
        {estimate?.client_signature ? (
          <>
            <img 
              src={estimate.client_signature} 
              alt="Client Signature" 
              style={{ maxWidth: '200px', marginBottom: '10px' }} 
            />
            <p style={{ margin: '5px 0' }}>Signed: {formatDateTime(estimate.client_signed_at)}</p>
            <p style={{ margin: '5px 0' }}>Name: {estimate.customer_details.name}</p>
          </>
        ) : (
          <>
            <p style={{ margin: '5px 0' }}>Name: {estimate.customer_details.name}</p>
            <p style={{ margin: '5px 0' }}>Date:</p>
          </>
        )}
      </div>

      {/* Project Photos Section */}
      {estimate?.project_images && estimate.project_images.length > 0 && (
        <div style={{ marginTop: '40px', pageBreakBefore: 'always' }}>
          <h3 style={styles.sectionTitle}>Project Photos</h3>
          <div style={styles.photosGrid}>
            {estimate.project_images.map((image, index) => {
              const imageUrl = image?.url 
                ? (image.url.startsWith('http') 
                  ? image.url 
                  : `${process.env.REACT_APP_API_URL}${image.url}`)
                : '';

              return (
                <div 
                  key={index} 
                  style={styles.photoCard}
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                  }}
                >
                  <img 
                    src={imageUrl}
                    alt={`Project Photo ${index + 1}`}
                    style={styles.photoImage}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Client Message */}
      {estimate.scope_of_work && (
        <div style={{ marginTop: '30px' }}>
          <h3 style={styles.sectionTitle}>Client Message</h3>
          <div 
            dangerouslySetInnerHTML={{ __html: estimate.scope_of_work }}
            style={{ lineHeight: '1.6' }}
          />
        </div>
      )}

      {/* Terms & Conditions */}
      {estimate.terms && (
        <div style={styles.termsSection}>
          <h3 style={styles.sectionTitle}>Terms & Conditions</h3>
          <div 
            dangerouslySetInnerHTML={{ __html: estimate.terms }}
            style={{ 
              lineHeight: '1.6',
              fontSize: '0.7rem',  // Match the line items text size
              '& p': {          // Style any paragraphs within the terms
                fontSize: '0.7rem',
                margin: '0.5em 0'
              },
              '& ul, & ol': {   // Style any lists within the terms
                fontSize: '0.7rem',
                margin: '0.5em 0',
                paddingLeft: '1.5em'
              }
            }}
          />
        </div>
      )}

      {/* Lightbox */}
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        index={photoIndex}
        slides={imageUrls}
        plugins={[Zoom]}
        carousel={{
          finite: imageUrls.length <= 1
        }}
        render={{
          buttonPrev: imageUrls.length <= 1 ? () => null : undefined,
          buttonNext: imageUrls.length <= 1 ? () => null : undefined
        }}
      />
    </div>
  );
};

export default DefaultTemplate;

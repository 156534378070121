import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from '@mui/material';
import { getClientServiceAddresses, addPropertyToClient, getClientProperties } from '../services/api';
import BaseInput from '../components/shared/BaseInput';
import { User, X } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

const ClientProperties = ({ client }) => {
  const [addresses, setAddresses] = useState([]);
  const [properties, setProperties] = useState([]);
  const [openAddPropertyDialog, setOpenAddPropertyDialog] = useState(false);
  const [newProperty, setNewProperty] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    // Add other fields from your Client model as needed
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [addressesData, propertiesData] = await Promise.all([
          getClientServiceAddresses(client.id),
          getClientProperties(client.id)
        ]);
        setAddresses(addressesData);
        setProperties(propertiesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (client?.id) {
      fetchData();
    }
  }, [client]);

  const handleOpenAddPropertyDialog = () => {
    setOpenAddPropertyDialog(true);
  };

  const handleCloseAddPropertyDialog = () => {
    setOpenAddPropertyDialog(false);
    setNewProperty({
      address: '',
      city: '',
      state: '',
      zip: '',
      // Reset other fields
    });
  };

  const handleAddPropertyConfirm = async () => {
    try {
      await addPropertyToClient(client.id, {
        property_address: newProperty.address,
        city: newProperty.city,
        state: newProperty.state,
        zip: newProperty.zip
      });
      // Refresh the properties list
      const updatedProperties = await getClientProperties(client.id);
      setProperties(updatedProperties);
      handleCloseAddPropertyDialog();
    } catch (error) {
      console.error('Failed to add property:', error);
      // Optionally display an error message to the user
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProperty({
      ...newProperty,
      [name]: value,
    });
  };

  const handlePropertyClick = (propertyId) => {
    navigate(`/admin/properties/${propertyId}`);
  };

  return (
    <Box
      sx={{
        mb: 4,
        px: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 600,
            color: 'var(--text-primary)',
          }}
        >
          Service Addresses
        </Typography>
        <Button
          variant="outlined"
          onClick={handleOpenAddPropertyDialog}
          disableRipple
          sx={{
            textTransform: 'none',
            fontWeight: 600,
            color: 'var(--text-secondary)',
            borderColor: 'var(--divider)',
            '&:hover': {
              borderColor: 'var(--text-primary)',
            },
          }}
        >
          Add Property
        </Button>
      </Box>

      {/* Add Property Dialog */}
      <Dialog
        open={openAddPropertyDialog}
        onClose={handleCloseAddPropertyDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontFamily: "'Urbanist', sans-serif",
            fontWeight: 600,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <User size={20} weight="bold" />
            Add Property to Client
          </Box>
          <IconButton
            onClick={handleCloseAddPropertyDialog}
            sx={{
              color: 'red',
              '&:hover': {
                color: 'red',
              },
            }}
          >
            <X size={20} weight="bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <BaseInput
            label="Address"
            name="address"
            value={newProperty.address}
            onChange={handleInputChange}
          />
          <BaseInput
            label="City"
            name="city"
            value={newProperty.city}
            onChange={handleInputChange}
          />
          <BaseInput
            label="State"
            name="state"
            value={newProperty.state}
            onChange={handleInputChange}
          />
          <BaseInput
            label="Zip"
            name="zip"
            value={newProperty.zip}
            onChange={handleInputChange}
          />
          {/* Add other input fields for relevant Client model properties */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAddPropertyDialog}
            sx={{
              textTransform: 'none',
              fontFamily: "'Urbanist', sans-serif",
              color: 'var(--text-secondary)',
              '&:hover': {
                bgcolor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddPropertyConfirm}
            variant="outlined"
            disableRipple
            sx={{
              textTransform: 'none',
              fontWeight: 600,
              color: 'var(--text-secondary)',
              borderColor: 'var(--divider)',
              '&:hover': {
                borderColor: 'var(--text-primary)',
                backgroundColor: 'var(--hover-overlay)',
              },
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Properties List */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
        {properties.map((property) => (
          <Box
            key={property.id}
            onClick={() => handlePropertyClick(property.id)}
            sx={{
              width: '100%',
              py: 2,
              borderBottom: '1px solid var(--divider)',
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 2fr 1fr',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'var(--hover-overlay)',
              },
            }}
          >
            <Typography sx={{ fontSize: '0.875rem', color: 'var(--text-primary)' }}>
              {property.address}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem', color: 'var(--text-primary)' }}>
              {property.city}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem', color: 'var(--text-primary)' }}>
              {property.state}
            </Typography>
            <Typography sx={{ fontSize: '0.875rem', color: 'var(--text-primary)' }}>
              {property.zip}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ClientProperties; 
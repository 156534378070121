import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmployees, generateEmployeeRegistrationLink, updateEmployeeDetails } from '../services/api';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Button, Box, Grid, IconButton, Tabs, Tab, useTheme,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import Timesheets from './Timesheets';
import styles from './Employees.module.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import EmployeeRequests from './EmployeeRequests';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import RouteRunner from './RouteRunner.js';
import EmployeeMap from './EmployeeMap.js';

import { 
  User as UserIcon,
  Envelope as EnvelopeIcon,
  Phone as PhoneIcon,
  MapPin as MapPinIcon,
  Buildings as BuildingsIcon,
  CurrencyDollar as CurrencyDollarIcon,
  UsersThree as UserList,
  ClockClockwise,
  Note,
  MapTrifold,
  Path
} from "@phosphor-icons/react";

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [registrationLink, setRegistrationLink] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [slideDirection, setSlideDirection] = useState('left');
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const data = await getEmployees();
      console.log('Fetched employees data:', data);
      setEmployees(Array.isArray(data.employees) ? data.employees : []);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching employees:', err);
      setError('Failed to fetch employees. Please try again later.');
      setLoading(false);
    }
  };

  const filteredEmployees = Array.isArray(employees) 
    ? employees.filter(employee =>
        employee.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const headerCells = [
    { 
      icon: <UserIcon size={20} weight="light" style={{ color: '#FF4842' }} />,
      label: 'NAME',
      color: '#FF4842'
    },
    { 
      icon: <EnvelopeIcon size={20} weight="light" style={{ color: '#2065D1' }} />,
      label: 'EMAIL',
      color: '#2065D1'
    },
    { 
      icon: <PhoneIcon size={20} weight="light" style={{ color: '#FFC107' }} />,
      label: 'PHONE',
      color: '#FFC107'
    },
    { 
      icon: <BuildingsIcon size={20} weight="light" style={{ color: '#00AB55' }} />,
      label: 'POSITION',
      color: '#00AB55'
    },
    { 
      icon: <MapPinIcon size={20} weight="light" style={{ color: '#7635DC' }} />,
      label: 'ADDRESS',
      color: '#7635DC'
    },
    { 
      icon: <CurrencyDollarIcon size={20} weight="light" style={{ color: '#27ae60' }} />,
      label: 'DEFAULT RATE',
      color: '#27ae60'
    }
  ];

  const handleRowClick = (employeeId) => {
    console.log(`Clicking employee with id: ${employeeId}`);
    navigate(`/admin/employees/${employeeId}`);
  };

  const handleTabChange = (event, newValue) => {
    const oldIndex = activeTab;
    setSlideDirection(oldIndex < newValue ? 'left' : 'right');
    setIsTransitioning(true);
    setActiveTab(newValue);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  };

  const handleGenerateRegistrationLink = async () => {
    try {
      const response = await generateEmployeeRegistrationLink();
      const link = `${window.location.origin}/public/employee-registration/${response.token}`;
      setRegistrationLink(link);
      setOpenLinkModal(true);
    } catch (error) {
      console.error('Error generating registration link:', error);
      alert('Failed to generate registration link');
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(registrationLink);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (error) {
      console.error('Error copying link:', error);
    }
  };

  const handleEmailLink = async () => {
    try {
      const mailtoLink = `mailto:${emailAddress}?subject=Employee Registration Link&body=Here is your registration link: ${registrationLink}`;
      window.location.href = mailtoLink;
      setEmailAddress('');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const center = {
    lat: 26.1224,
    lng: -80.1373
  };

  const mapOptions = {
    styles: [
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{"color": "#193341"}]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"color": "#2c5a71"}]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {"color": "#29768a"},
          {"lightness": -37}
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#406d80"}]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{"color": "#406d80"}]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {"visibility": "on"},
          {"color": "#3e606f"},
          {"weight": 2},
          {"gamma": 0.84}
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#ffffff"}]
      }
    ],
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
    mapTypeId: 'roadmap',
    zoom: 13,
    gestureHandling: 'cooperative',
    backgroundColor: '#193341',
    clickableIcons: false,
    fullscreenControlOptions: {
      position: 7
    },
    zoomControlOptions: {
      position: 7
    }
  };

  const mapContainerStyle = {
    width: '100%',
    height: '500px',
    borderRadius: '12px',
    marginBottom: '2rem'
  };

  return (
    <div className={styles.pageContainer}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" className={styles.pageTitle}>
          Team Management
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<LinkIcon />}
            onClick={handleGenerateRegistrationLink}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontWeight: 700,
              fontSize: '0.875rem',
              padding: '6px 16px',
              border: '1px solid #E0E0E0',
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderColor: '#E0E0E0'
              }
            }}
          >
            Generate Registration Link
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/admin/create-employee')}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              backgroundColor: '#4a5df9 !important',
              fontFamily: 'Urbanist, sans-serif',
              fontWeight: 700,
              fontSize: '0.875rem',
              padding: '6px 16px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#3c50fa !important',
                boxShadow: 'none !important',
              },
              '&.MuiButton-contained:hover': {
                backgroundColor: '#3c50fa !important',
                boxShadow: 'none',
              }
            }}
          >
            Create New User
          </Button>
        </Box>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            px: 2,
            '& .MuiTabs-indicator': {
              backgroundColor: '#7635dc',
              height: '2px',
              borderRadius: '3px 3px 0 0',
              transition: 'all 0.2s ease'
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              color: 'var(--text-secondary)',
              minHeight: 48,
              padding: '12px 24px',
              gap: '8px',
              position: 'relative',
              '& .MuiTouchRipple-root': {
                display: 'none'
              },
              '& svg': {
                marginBottom: '0 !important',
                marginRight: '0 !important'
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0,
                height: '2px',
                borderRadius: '3px 3px 0 0',
                backgroundColor: '#7635dc',
                opacity: 0.6,
                transition: 'all 0.2s ease',
                transform: 'translateX(-50%)',
              },
              '&:hover::after': {
                width: '80%',
              },
              '&.Mui-selected': {
                color: 'var(--primary-main)',
                fontWeight: 600,
                '&::after': {
                  opacity: 0,
                },
              },
              '&:hover': {
                color: 'var(--primary-main)',
              }
            },
          }}
        >
          <Tab 
            icon={<UserIcon size={20} weight="light" />}
            iconPosition="start"
            label="Directory" 
            disableRipple
          />
          <Tab 
            icon={<ClockClockwise size={20} weight="light" />}
            iconPosition="start"
            label="Timesheets" 
            disableRipple
          />
          <Tab 
            icon={<Note size={20} weight="light" />}
            iconPosition="start"
            label="Requests" 
            disableRipple
          />
          <Tab 
            icon={<Path size={20} weight="light" />}
            iconPosition="start"
            label="Route Runner" 
            disableRipple
          />
          <Tab 
            icon={<MapTrifold size={20} weight="light" />}
            iconPosition="start"
            label="Map" 
            disableRipple
          />
        </Tabs>
      </Box>

      <Box sx={{ 
        flex: 1,
        overflowY: 'auto',
        backgroundColor: 'var(--background-default)',
      }}>
        {[
          <div className={styles.employeesContainer}>
            {activeTab === 0 && (
              <>
                <Grid container justifyContent="space-between" alignItems="center" mb={4}>
                  <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        placeholder="Search employees..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{
                          width: '50px',
                          transition: 'all 0.5s ease',
                          '&:hover, &:focus-within': {
                            width: '300px',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            backgroundColor: 'var(--background-secondary)',
                            border: '1px solid var(--text-secondary)',
                            '&:hover': {
                              backgroundColor: 'var(--background-secondary)',
                              borderColor: 'var(--text-secondary)'
                            },
                            '&.Mui-focused': {
                              borderColor: '#2065D1',
                              boxShadow: '0 0 0 2px rgba(32, 101, 209, 0.08)'
                            },
                            '& fieldset': {
                              border: 'none'
                            }
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon 
                              sx={{ 
                                color: '#94a3b8', 
                                mr: 1,
                                transition: 'all 0.3s ease',
                              }} 
                            />
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Tooltip title={showMap ? "Hide Map" : "Show Map"}>
                      <IconButton 
                        onClick={() => setShowMap(!showMap)} 
                        sx={{
                          transform: showMap ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease',
                          color: 'var(--text-secondary)'
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                {showMap && (
                  <Box sx={{ 
                    p: 3,
                    borderRadius: '12px',
                    mb: 4,
                    border: '1px solid rgba(255, 255, 255, 0.08)'
                  }}>
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        options={mapOptions}
                      />
                    </LoadScript>
                  </Box>
                )}
              </>
            )}

            {filteredEmployees.length > 0 ? (
              <TableContainer 
                component={Paper} 
                className={styles.tableContainer}
                sx={{ 
                  backgroundColor: 'var(--background-paper)',
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: '12px',
                  '& .MuiTable-root': {
                    borderCollapse: 'separate',
                    borderSpacing: '0',
                  }
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {headerCells.map((cell, index) => (
                        <TableCell 
                          key={index} 
                          align="center"
                          className={styles.tableHeaderCell}
                          style={{ 
                            cursor: cell.sortable ? 'pointer' : 'default',
                            backgroundColor: 'var(--background-secondary)',
                            borderBottom: '1px solid #e0e0e0',
                            fontFamily: 'Urbanist, sans-serif',
                            fontSize: '0.75rem',
                            fontWeight: 600,
                            color: 'var(--text-secondary)!important',
                            letterSpacing: '0.5px',
                            padding: '12px 16px',
                          }}
                        >
                          <Box display="flex" alignItems="center" justifyContent="center">
                            {cell.icon}
                            <Typography 
                              sx={{ 
                                ml: 1,
                                fontSize: '0.75rem',
                                fontWeight: 600,
                                letterSpacing: '0.5px',
                                textTransform: 'uppercase',
                                color: 'var(--text-primary)',
                                fontFamily: 'Urbanist, sans-serif'
                              }}
                            >
                              {cell.label}
                            </Typography>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredEmployees.map((employee) => (
                      <TableRow 
                        key={employee.id} 
                        onClick={() => handleRowClick(employee.id)}
                        sx={{ 
                          cursor: 'pointer',
                          '&:hover': { 
                            backgroundColor: 'var(--background-hover)'
                          },
                          '& td': {
                            borderBottom: '1px solid #e0e0e0',
                            color: 'var(--text-primary)',
                            padding: '16px',
                            fontSize: '0.875rem',
                            fontFamily: 'Urbanist'
                          }
                        }}
                      >
                        <TableCell align="center">
                          <Typography>
                            {employee.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {employee.email || 'No email provided'}
                        </TableCell>
                        <TableCell align="center">
                          {employee.phone || 'No phone provided'}
                        </TableCell>
                        <TableCell align="center">
                          {employee.position || 'No position provided'}
                        </TableCell>
                        <TableCell align="center">
                          {employee.address || 'No address provided'}
                        </TableCell>
                        <TableCell align="center">
                          ${employee.default_rate}/hr
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No employees found.</Typography>
            )}
          </div>,
          <Timesheets />,
          <EmployeeRequests />,
          <RouteRunner />,
          <EmployeeMap />
        ].map((content, index) => (
          <Box
            key={index}
            sx={{
              display: activeTab === index ? 'block' : 'none',
              height: '100%',
              p: 3,
              animation: `${slideDirection === 'left' ? 'slideLeft' : 'slideRight'} 0.3s ease-out`,
              opacity: isTransitioning ? 0.3 : 1,
              transition: 'opacity 0.3s ease-out',
              '@keyframes slideLeft': {
                from: {
                  opacity: 0,
                  transform: 'translateX(20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateX(0)',
                },
              },
              '@keyframes slideRight': {
                from: {
                  opacity: 0,
                  transform: 'translateX(-20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateX(0)',
                },
              },
            }}
          >
            {content}
          </Box>
        ))}
      </Box>

      <Dialog 
        open={openLinkModal} 
        onClose={() => setOpenLinkModal(false)} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            '.MuiDialogTitle-root': {
              background: '#f8fafc',
              borderBottom: '1px solid #e2e8f0',
              py: 2.5,
            },
            '.MuiDialogContent-root': {
              p: 3,
            },
            '.MuiDialogActions-root': {
              px: 3,
              py: 2,
            }
          }
        }}
      >
        <DialogTitle sx={{ 
          fontSize: '1.25rem', 
          fontWeight: 600,
          fontFamily: 'Urbanist, sans-serif',
          color: '#1e293b',
          position: 'relative',
          pr: 6
        }}>
          Registration Link
          <IconButton
            onClick={() => setOpenLinkModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#ef4444',
              '&:hover': {
                backgroundColor: 'rgba(239, 68, 68, 0.1)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ mb: 1, color: '#64748b', fontWeight: 500 }}>
              Registration Link:
            </Typography>
            <TextField
              value={registrationLink}
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#f8fafc',
                  '&:hover': {
                    backgroundColor: '#f1f5f9'
                  }
                }
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Button
                    onClick={handleCopyLink}
                    startIcon={<ContentCopyIcon />}
                    color={copySuccess ? "success" : "primary"}
                    sx={{
                      borderRadius: '6px',
                      textTransform: 'none',
                      fontWeight: 500
                    }}
                  >
                    {copySuccess ? "Copied!" : "Copy"}
                  </Button>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ mb: 1, color: '#64748b', fontWeight: 500 }}>
              Send via Email:
            </Typography>
            <TextField
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Enter email address"
              type="email"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#f8fafc',
                  '&:hover': {
                    backgroundColor: '#f1f5f9'
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleEmailLink}
                    startIcon={<EmailIcon />}
                    disabled={!emailAddress}
                    sx={{
                      borderRadius: '6px',
                      textTransform: 'none',
                      fontWeight: 500
                    }}
                  >
                    Send
                  </Button>
                ),
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Employees;

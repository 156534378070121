import React, { useRef, useEffect } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  PaperPlaneTilt as SendIcon,
  Plus as CreateIcon,
  Bell as ReminderIcon,
  Calendar as EventIcon,
  Clock as TimeIcon,
  User as UserIcon
} from "@phosphor-icons/react";

function DoThisSelector({ onSelect, triggerType, triggerAction }) {
  const selectorRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        onSelect?.(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSelect]);

  const getCreateActionLabel = () => {
    if (triggerType === 'estimate' && triggerAction === 'approved') {
      return 'Create Project';
    }
    if (triggerType === 'event' && triggerAction === 'completed') {
      return 'Create Invoice';
    }
    return null;
  };

  const mainActions = [
    {
      id: 'send',
      label: 'Send',
      icon: <SendIcon size={24} weight="regular" />,
      color: '#4a5df9'
    },
    ...(getCreateActionLabel() ? [{
      id: 'create',
      label: getCreateActionLabel(),
      icon: <CreateIcon size={24} weight="regular" />,
      color: '#4a5df9'
    }] : [])
  ];

  return (
    <Paper
      ref={selectorRef}
      elevation={2}
      sx={{
        width: 280,
        backgroundColor: 'white',
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid rgba(74, 93, 249, 0.1)',
      }}
    >
      <List sx={{ py: 1 }}>
        {mainActions.map((action) => (
          <ListItem
            key={action.id}
            button
            onClick={() => onSelect?.(action)}
            sx={{
              py: 1.5,
              px: 2,
              '&:hover': {
                backgroundColor: 'rgba(74, 93, 249, 0.04)',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40, color: action.color }}>
              {action.icon}
            </ListItemIcon>
            <ListItemText
              primary={action.label}
              sx={{
                '& .MuiListItemText-primary': {
                  color: '#1a1a1a',
                  fontSize: '0.95rem',
                  fontWeight: 400,
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default DoThisSelector; 
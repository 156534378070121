import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import BaseInput from './shared/BaseInput';
import BaseSelect from './shared/BaseSelect';
import dayjs from 'dayjs';

const ScheduleItemDetails = ({ item, index, estimateItems, setEstimateItems }) => {
  // Generate time options for dropdown
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute of ['00', '30']) {
        times.push(`${hour}:${minute} AM`);
      }
    }
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute of ['00', '30']) {
        times.push(`${hour}:${minute} PM`);
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  // Format time for display
  const formatTime = (dateTime) => {
    if (!dateTime) return timeOptions[0]; // Default to first option instead of empty string
    try {
      const date = dayjs(dateTime);
      if (!date.isValid()) return timeOptions[0];
      const hour = date.hour() % 12 || 12;
      const minute = date.minute().toString().padStart(2, '0');
      const period = date.hour() >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${period}`;
    } catch (error) {
      console.error('Error formatting time:', error);
      return timeOptions[0];
    }
  };

  const handleDateChange = (field, value) => {
    if (!Array.isArray(estimateItems)) return;
    
    const newItems = [...estimateItems];
    const newDate = dayjs(value).isValid() ? value : null;
    
    // Set default times when date changes
    const defaultHour = field === 'start_time' ? 9 : 17;
    newItems[index] = {
      ...newItems[index],
      [field]: newDate ? dayjs(newDate).hour(defaultHour).minute(0).second(0) : null
    };
    
    console.log('Updated item times:', newItems[index]);
    setEstimateItems(newItems);
  };

  const handleTimeChange = (field, timeStr) => {
    if (!timeStr || !Array.isArray(estimateItems)) return;
    
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');
    
    let hour = parseInt(hours);
    if (period === 'PM' && hour !== 12) hour += 12;
    if (period === 'AM' && hour === 12) hour = 0;
    
    const currentDate = dayjs(field === 'start_time' ? item.start_time : item.end_time || new Date());
    const newDateTime = currentDate.hour(hour).minute(parseInt(minutes)).second(0);
    
    const newItems = [...estimateItems];
    newItems[index] = {
      ...newItems[index],
      [field]: newDateTime
    };
    setEstimateItems(newItems);
  };

  return (
    <Grid container spacing={1} sx={{ mt: 0.25 }}>
      <Grid item xs={12} md={6}>
        <Box sx={{ 
          pr: { md: 1 },
          borderRight: { md: '1px solid rgba(0, 0, 0, 0.12)' },
          height: '100%'
        }}>
          <Typography variant="body2" sx={{ 
            color: 'rgb(100, 100, 100)',
            whiteSpace: 'pre-wrap',
            fontSize: '0.9rem'
          }}>
            {item.description}
          </Typography>
          {item.quantity && (
            <Typography variant="body2" sx={{ 
              color: 'rgb(100, 100, 100)',
              mt: 0.5,
              fontSize: '0.9rem'
            }}>
              Quantity: {item.quantity}
            </Typography>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={0.75} sx={{ ml: 0.25 }}>
          <Grid item xs={6}>
            <BaseInput
              label="Start Date"
              type="date"
              value={item.start_time ? dayjs(item.start_time).format('YYYY-MM-DD') : ''}
              onChange={(e) => handleDateChange('start_time', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <BaseSelect
              label="Start Time"
              value={formatTime(item.start_time)}
              onChange={(e) => handleTimeChange('start_time', e.target.value)}
              options={timeOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <BaseInput
              label="End Date"
              type="date"
              value={item.end_time ? dayjs(item.end_time).format('YYYY-MM-DD') : ''}
              onChange={(e) => handleDateChange('end_time', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <BaseSelect
              label="End Time"
              value={formatTime(item.end_time)}
              onChange={(e) => handleTimeChange('end_time', e.target.value)}
              options={timeOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ 
              color: 'rgb(100, 100, 100)',
              fontFamily: 'Urbanist',
              mt: 0.25,
              fontSize: '0.9rem'
            }}>
              Duration: {item.end_time && item.start_time ? dayjs(item.end_time).diff(item.start_time, 'day') + 1 : 0} days
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleItemDetails; 
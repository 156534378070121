import axios from 'axios';
import dayjs from 'dayjs';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export const login = async (email, password) => {
  try {
    const response = await api.post('/login', { email, password });
    console.log('Login response:', response);
    return response;
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('Error request:', error.request);
    }
    throw error;
  }
};
export const getAdminDashboard = async () => {
  try {
    const response = await api.get('/admin_dashboard');
    return response.data;
  } catch (error) {
    console.error('Error fetching admin dashboard:', error);
    throw error;
  }
};
export const getProjects = (page = 1, perPage = 10) => 
  api.get(`/projects?page=${page}&per_page=${perPage}`);
export const createProject = async (projectData) => {
  try {
    console.log('API call starting with data:', projectData);
    
    // Make sure dates are properly formatted if they exist
    if (projectData.start_time) {
      projectData.start_time = dayjs(projectData.start_time).format('YYYY-MM-DDTHH:mm');
    }
    if (projectData.end_time) {
      projectData.end_time = dayjs(projectData.end_time).format('YYYY-MM-DDTHH:mm');
    }

    const response = await api.post('/create_project', projectData);
    
    console.log('Raw API response:', response);
    
    if (!response.data) {
      throw new Error('No data received from server');
    }
    
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response?.data || error.message);
    throw error;
  }
};
export const getProjectDetails = (id) => api.get(`/projects/${id}`);

// Error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API error:', error.response ? error.message : error.message);
    throw error;
  }
);

export const updateProjectSchedule = async (projectId, scheduleData) => {
  try {
    const response = await api.put(`/projects/${projectId}/schedule`, scheduleData);
    return response.data;
  } catch (error) {
    console.error('Error updating project schedule:', error);
    throw error;
  }
};

export const updateProjectScopeOfWork = async (projectId, scopeOfWork) => {
  const response = await api.put(`/projects/${projectId}/scope-of-work`, { scope_of_work: scopeOfWork });
  return response.data;
};

export const getEmployees = async () => {
  try {
    const response = await api.get('/employees');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch employees:', error);
    throw error;
  }
};

export const assignEmployeesToProject = async (projectId, employees) => {
  console.log('Calling assignEmployeesToProject with:', { projectId, employees });
  try {
    const response = await api.post(`/projects/${projectId}/assign_employees`, { employees });
    console.log('Assignment result:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error assigning employees to project:', error.response?.data || error.message);
    throw error;
  }
};

export const getProjectEmployees = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/employees`);
    return response.data;
  } catch (error) {
    console.error('Error fetching project employees:', error);
    throw error;
  }
};

export const assignEmployeesToEvent = async (eventId, employees) => {
  try {
    const response = await api.post(`/events/${eventId}/assign_employees`, { employees });
    return response.data;
  } catch (error) {
    console.error('Error assigning employees to event:', error);
    throw error;
  }
};

export const getEventEmployees = async (eventId) => {
  try {
    const response = await api.get(`/events/${eventId}/employees`);
    return response.data;
  } catch (error) {
    console.error('Error fetching event employees:', error);
    throw error;
  }
};

export const getProjectPhotos = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/photos`);
    console.log('Photos response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching photos:', error);
    throw error;
  }
};

export const uploadProjectPhoto = async (projectId, formData, onProgress) => {
  try {
    console.log('Uploading to project:', projectId);
    console.log('FormData:', formData);
    
    const response = await api.post(`/projects/${projectId}/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (onProgress) {
          onProgress(progressEvent);
        }
      }
    });
    
    console.log('Upload response:', response);
    return response.data;
  } catch (error) {
    console.error('Upload error:', error.response || error);
    throw error;
  }
};

export const getProjectTimesheets = async () => {
  try {
    const response = await api.get('/employee/timesheets');
    if (response.data && response.data.timesheets) {
      return response.data;
    } else {
      throw new Error('Invalid data structure received from server');
    }
  } catch (error) {
    console.error('Error fetching timesheets:', error);
    throw error;
  }
};

export const getProjectFinancials = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/financials`);
    console.log("Frontend API: Raw response:", response);
    console.log("Frontend API: Financial data received:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching project financials:", error);
    throw error;
  }
};

export const getMaterialCosts = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/material-costs`);
    console.log('Material costs response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching material costs:', error);
    throw error;
  }
};

export const updateProjectMaterialCost = async (projectId, materialCost) => {
  const response = await api.put(`/projects/${projectId}/material-cost`, { material_cost: materialCost });
  return response.data;
};

export const getMaterialCost = async (projectId, costId) => {
  console.log(`Fetching material cost for project ${projectId}, cost ${costId}`);
  try {
    const response = await api.get(`/projects/${projectId}/material-costs/${costId}`);
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const updateMaterialCost = async (projectId, costId, updatedCost) => {
  try {
    const response = await api.put(`/projects/${projectId}/material-costs/${costId}`, updatedCost);
    return response.data;
  } catch (error) {
    console.error('Error updating material cost:', error);
    throw error;
  }
};

export const updateProjectDetails = async (projectId, updatedDetails) => {
  try {
    const response = await api.put(`/projects/${projectId}`, updatedDetails);
    return response.data;
  } catch (error) {
    console.error('Error updating project:', error.response?.data || error.message);
    throw error;
  }
};

export const getCoordinatesFromAddress = async (address) => {
  try {
    const response = await api.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`);
    const data = response.data;
    if (data && data.length > 0) {
      return [parseFloat(data[0].lat), parseFloat(data[0].lon)];
    }
    throw new Error('Unable to get coordinates for the address');
  } catch (error) {
    console.error('Error getting coordinates:', error);
    throw error;
  }
};

export const createRecurringEvent = async (eventData) => {
  try {
    console.log('Creating recurring event:', eventData);
    const response = await api.post('/events/recurring', eventData);
    return response.data;
  } catch (error) {
    console.error('Error creating recurring event:', error);
    throw error;
  }
};

export const createEvent = async (eventData) => {
  try {
    // If event has recurring settings, generate all instances
    if (eventData.recurring_settings) {
      const recurringData = {
        ...eventData,
        recurring: {
          frequency: eventData.recurring_settings.frequency,
          interval: eventData.recurring_settings.interval,
          days_of_week: eventData.recurring_settings.daysOfWeek,
          end_date: eventData.recurring_settings.endDate,
          end_after_occurrences: eventData.recurring_settings.endAfterOccurrences,
        },
      };
      
      const response = await api.post('/events/recurring', recurringData);
      return response.data;
    }
    
    // Regular event creation
    const response = await api.post('/events', eventData);
    return response.data;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

export const getProjectEvents = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/events`);
    console.log('Received events:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching project events:', error);
    throw error;
  }
};

export const getServerTime = async () => {
  try {
    const response = await api.get('/server-time');
    return new Date(response.data);
  } catch (error) {
    console.error('Error fetching server time:', error);
    throw error;
  }
};

export const updateEvent = async (eventId, eventData) => {
  try {
    const response = await api.put(`/events/${eventId}`, eventData);
    return response.data;
  } catch (error) {
    console.error('Error updating event:', error);
    throw error;
  }
};

export const getEventDetails = async (eventId) => {
  try {
    console.log(`Fetching details for event ID: ${eventId}`);
    const response = await api.get(`/events/${eventId}`, {
      params: {
        include_project: true,
      }
    });
    console.log('Event details response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching event details:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteEvent = async (eventId) => {
  try {
    const response = await api.delete(`/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error;
  }
};

export const createEmployee = async (employeeData) => {
  try {
    const response = await api.post('/employee', employeeData);
    return response.data;
  } catch (error) {
    console.error('Error creating employee:', error);
    throw error;
  }
};

export const getEvents = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/events`);
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const getPayrollData = async () => {
  try {
    const response = await api.get('/payroll');
    return response.data;
  } catch (error) {
    console.error('Error fetching payroll data:', error);
    throw error;
  }
};

export const getEmployeeDashboard = async () => {
  try {
    const response = await api.get('/employee_dashboard');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee dashboard:', error);
    throw error;
  }
};

export const clockInToEvent = async (eventId) => {
  const response = await api.post(`/clock_in/${eventId}`);
  return response.data;
};

export const clockOutFromEvent = async () => {
  const response = await api.post('/clock_out');
  return response.data;
};


export const fetchClientProjects = async () => {
  try {
    console.log('Fetching client projects...');
    const response = await api.get('/client/projects');
    console.log('Client projects response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching client projects:', error);
    throw error;
  }
};

export const fetchClientProjectDetails = async (projectId) => {
  try {
    const response = await api.get(`/client/projects/${projectId}`);
    return response.data.project;
  } catch (error) {
    console.error('Error fetching client project details:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('Project not found');
    }
    throw new Error('Failed to fetch project details');
  }
};

export const shareProjectWithClient = async (projectId) => {
  try {
    const response = await api.post(`api/projects/${projectId}/share`);
    return response.data;
  } catch (error) {
    console.error('Error sharing project with client:', error);
    throw error;
  }
};

export const getClients = async (page = 1, searchTerm = '', perPage = 50) => {
  try {
    console.log('Calling getClients with:', { page, searchTerm, perPage });
    const response = await api.get('/clients', {
      params: {
        page,
        search: searchTerm,
        per_page: perPage
      }
    });
    console.log('Received clients:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getClients:', error);
    throw error;
  }
};

export const createClient = async (clientData) => {
  console.log('🌐 API createClient STARTING with:', clientData);
  console.log('🌐 Original zip_code:', clientData.zip_code); // Debug log
  
  // Explicitly construct the payload to ensure all fields are present
  const payload = {
    name: clientData.name,
    email: clientData.email,
    phone: clientData.phone || '',
    address: clientData.address || '',
    city: clientData.city || '',
    state: clientData.state || '',
    zip_code: clientData.zip_code || '',  // Make sure we're using zip_code consistently
    company: clientData.company || '',
    notes: clientData.notes || ''
  };

  console.log('🌐 Final payload zip_code:', payload.zip_code); // Debug log
  console.log('🌐 Full payload:', payload);

  try {
    const response = await api.post('/clients', payload);
    console.log('🌐 Raw API response:', response);
    
    if (response.data.client && response.data.client.zip_code === null) {
      console.warn('🌐 Warning: ZIP code is null in response');
    }

    return response.data.client;
  } catch (error) {
    console.error('🌐 API error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    throw error;
  }
};

export const getEmployeeTimesheets = async () => {
  try {
    const response = await api.get('/employee/timesheets');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee timesheets:', error);
    throw error;
  }
};

export const getTimesheetRate = async (timesheetId) => {
  try {
    const response = await api.get(`/timesheet/${timesheetId}/rate`);
    return response.data;
  } catch (error) {
    console.error('Error fetching timesheet rate:', error);
    throw error;
  }
};

export const getEmployeeName = async (employeeId) => {
  try {
    const response = await axios.get(`/api/employees/${employeeId}/name`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee name:', error);
    throw error;
  }
};

// Function to get employee availability
export const getEmployeeAvailability = async (employeeId) => {
  try {
    console.log('Fetching availability for employee:', employeeId);
    const response = await api.get(`/employee/${employeeId}/availability`);
    console.log('Availability response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee availability:', error);
    throw error;
  }
};

// Function to update employee availability
export const updateEmployeeAvailability = async (employeeId, date, isAvailable) => {
  try {
    const requestData = {
      date: date,
      is_available: isAvailable
    };

    console.log('Sending to API:', requestData);

    const response = await api.post(
      `/employee/${employeeId}/availability`, 
      requestData
    );
    
    return response.data;
  } catch (error) {
    console.error('API error:', error.response?.data || error);
    throw error;
  }
};

export const getEmployeeProfile = async () => {
  try {
    const response = await api.get('/employee/profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee profile:', error);
    throw error;
  }
};

export const resetPassword = async (newPassword) => {
  try {
    const response = await api.post('/employee/reset-password', { newPassword });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const getAdminTimesheets = async () => {
  try {
    const response = await api.get('/admin/timesheets');
    console.log('Admin timesheets response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching admin timesheets:', error);
    throw error;
  }
};

export const getAdminCalendarEvents = () => api.get('/admin/calendar');

export const getClientDetails = async (id) => {
  console.log("Calling getClientDetails for ID:", id);
  try {
    const response = await api.get(`/clients/${id}`);
    console.log("Received client data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in getClientDetails:", error);
    throw error;
  }
};

export const updateClient = async (id, clientData) => {
  console.log('🌐 API updateClient STARTING with:', { id, clientData });
  
  try {
    const response = await api.put(`/clients/${id}`, {
      name: clientData.name,
      email: clientData.email,
      phone: clientData.phone || '',
      company: clientData.company || '',
      address: clientData.address || '',
      notes: clientData.notes || '',
      parent_client: clientData.parent_client || null,
      parent_client_id: clientData.parent_client_id,
      lead_source: clientData.lead_source || null,
      city: clientData.city || '',
      state: clientData.state || '',
      zip_code: clientData.zip_code || ''
    });
    
    console.log('🌐 API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('🌐 API error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    throw error;
  }
};

export const shareProject = async (projectId, clientId) => {
  try {
    const response = await api.post(`/projects/${projectId}/share`, { client_id: clientId });
    return response.data;
  } catch (error) {
    console.error('Error sharing project:', error);
    throw error;
  }
};

export const getEmployeeEventDetails = async (eventId) => {
  try {
    const response = await api.get(`/employee/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching employee event details:', error);
    throw error;
  }
};

// Add this function to your api.js file
export const addMaterialCost = async (projectId, materialCost) => {
  try {
    const response = await api.post(`/projects/${projectId}/material-costs`, materialCost);
    return response.data; // This should be the newly added cost data
  } catch (error) {
    console.error('Error adding material cost:', error);
    throw error;
  }
};

export const deleteMaterialCost = async (projectId, costId) => {
  try {
    const response = await api.delete(`/projects/${projectId}/material-costs/${costId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting material cost:', error);
    throw error;
  }
};

export const fetchClientDashboard = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.get('/client/dashboard', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching client dashboard:', error);
    throw error;
  }
};

export const getEmployeeDetails = async (id) => {
  try {
    console.log(`Fetching employee details for ID: ${id}`);
    const response = await api.get(`/employee/${id}/details`);
    console.log('Employee details response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getEmployeeDetails:', error.response?.data || error.message);
    throw error;
  }
};

export const updateEmployeeNotes = async (employeeId, notes) => {
  try {
    const response = await api.put(`/employee/${employeeId}/notes`, { notes });
    return response.data;
  } catch (error) {
    console.error('Error updating employee notes:', error);
    throw error;
  }
};

export const getEstimates = async () => {
  console.log('Fetching estimates...');
  try {
    const response = await api.get('/estimates');
    console.log('Estimates received:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching estimates:', error);
    throw error;
  }
};


export const getItems = async () => {
  try {
    console.log('Fetching all items...');
    const response = await api.get('/items');
    console.log('All items:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const uploadLogo = async (formData) => {
  try {
    const response = await api.post('/upload_logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Upload Logo Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getLogos = async () => {
  const response = await api.get('/logos');
  return response.data;
};

export const deleteLogo = async (logoId) => {
  const response = await api.delete(`/logos/${logoId}`);
  return response.data;
};

// Replace the existing getBusinessDetails function
export const getBusinessDetails = async () => {
  try {
    const response = await api.get('/business_profile');
    if (response.data) {
      const { address, city, state, zip_code } = response.data;
      const fullAddress = `${address}, ${city}, ${state} ${zip_code}`;
      return { ...response.data, fullAddress };
    }
    return null;
  } catch (error) {
    if (error.response && error.response.status === 404 && error.response.data.code === 'PROFILE_NOT_FOUND') {
      console.log('No business profile found');
      return null;
    }
    console.error('Error fetching business details:', error);
    throw error;
  }
};

// Replace the existing updateBusinessDetails function
export const updateBusinessDetails = async (formData) => {
  try {
    const response = await api.put('/business_profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating business details:', error);
    throw error;
  }
};

export const createItem = async (itemData) => {
  try {
    console.log('Creating item with data:', itemData);
    const response = await api.post('/items', {
      title: itemData.title,
      description: itemData.description,
      quantity: itemData.quantity,
      unitPrice: itemData.unitPrice,
      tax: itemData.tax
    });
    return response.data;
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
};



export const deleteItem = async (itemId) => {
  try {
    console.log(`Attempting to delete item with ID: ${itemId}`);
    const response = await api.delete(`/items/${itemId}`);
    console.log('Delete item response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting item:', error.response?.data || error.message);
    throw error;
  }
};

export const createInvoice = async (invoiceData) => {
  try {
    const response = await api.post('/invoices', invoiceData);
    console.log('Invoice created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating invoice:', error.response?.data || error.message);
    throw error;
  }
};


export const getInvoices = async () => {
  try {
    const response = await api.get('/invoices');
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getInvoices:', error);
    throw error;
  }
};

export const getInvoiceById = async (id) => {
  try {
    const response = await api.get(`/invoices/${id}`);
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getInvoiceById:', error);
    throw error;
  }
};

// Estimates
export const createEstimate = async (estimateData) => {
  try {
    const response = await api.post('/estimates', estimateData);
    return response.data;
  } catch (error) {
    console.error('Error creating estimate:', error);
    throw error;
  }
};
export const getEstimate = (id) => api.get(`/estimates/${id}`);
export const updateEstimate = (id, estimateData) => api.put(`/estimates/${id}`, estimateData);
export const getAllEstimates = async () => {
  try {
    const response = await axios.get('/api/estimates');
    return response.data;
  } catch (error) {
    console.error('Error fetching estimates:', error);
    throw error;
  }
};
export const deleteEstimate = (id) => api.delete(`/estimates/${id}`);

// Add this new function to your api.js file
export const getAllProjectPhotos = () => {
  return axios.get('/api/project-photos', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const getPublicEstimate = async (uuid) => {
  console.log('API CALL STARTING for UUID:', uuid);
  try {
    const response = await api.get(`/public/estimates/${uuid}`);
    console.log('API RESPONSE:', response);
    return response;
  } catch (error) {
    console.error('API ERROR:', error);
    throw error;
  }
};

export const signEstimate = (uuid, signatureData) => {
  return axios.post(`/api/public/estimates/${uuid}/sign`, signatureData);
};

export const getPropertyDetails = async (address) => {
  try {
    console.log('Calling getPropertyDetails API for address:', address);
    
    // Get the JWT token
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await api.get('/property-details', {
      params: { address },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    
    console.log('Property details response:', response);
    return response.data;
    
  } catch (error) {
    console.error('Error in getPropertyDetails:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Request made but no response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
};

export const getTermsTemplates = () => api.get('/terms-templates');
export const createTermsTemplate = (data) => api.post('/terms-templates', data);
export const updateTermsTemplate = (id, data) => api.put(`/terms-templates/${id}`, data);
export const deleteTermsTemplate = (id) => api.delete(`/terms-templates/${id}`);


export const updateProjectClient = async (projectId, clientId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.put(`/projects/${projectId}/update_client`, {
      client_id: clientId
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating project client:', error);
    throw error;
  }
};

export const getAllEvents = async (filters = {}) => {
  try {
    // Build query parameters
    const params = new URLSearchParams();
    if (filters.projectId) params.append('project_id', filters.projectId);
    if (filters.startDate) params.append('start_date', filters.startDate);
    if (filters.endDate) params.append('end_date', filters.endDate);

    const response = await api.get('/events', { params });
    console.log('Received events:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching all events:', error);
    throw error;
  }
};

export const getAllProjects = async () => {
  try {
    const response = await api.get('/all_projects');
    console.log('All projects response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching all projects:', error);
    throw error;
  }
};

// Add this new function
export const getOneOffJobs = async () => {
  try {
    console.log('Fetching one-off jobs...'); // Debug log
    const token = localStorage.getItem('token');
    
    const response = await api.get('/events/one-off', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    
    console.log('One-off jobs response:', response.data); // Debug log
    return response.data;
  } catch (error) {
    console.error('Error fetching one-off jobs:', error);
    throw error;
  }
};

export const getRecurringJobs = async () => {
  try {
    console.log('Fetching recurring jobs...'); // Debug log
    const token = localStorage.getItem('token');
    
    const response = await api.get('/events/recurring', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    
    console.log('Recurring jobs response:', response.data); // Debug log
    return response.data;
  } catch (error) {
    console.error('Error fetching recurring jobs:', error);
    throw error;
  }
};

// Add this new function with the existing upload functions
export const uploadEstimatePhoto = async (formData) => {
  try {
    const response = await api.post('/upload/estimate-photos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Upload Photo Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


// Add this with your other API functions
export const updateProjectStatus = async (projectId, status) => {
  try {
    console.log(`Updating project ${projectId} status to: ${status}`);
    const response = await api.put(`/projects/${projectId}/status`, { status });
    console.log('Update project status response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating project status:', error);
    throw error;
  }
};

export const getProjectStatus = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/status`);
    return response.data;
  } catch (error) {
    console.error('Error getting project status:', error);
    throw error;
  }
};

// Add this with your other API functions
export const convertEstimateToProject = async (estimateId, data) => {
  try {
    console.log("Making API call with data:", data);
    const response = await api.post(`/estimates/${estimateId}/convert`, data);
    console.log("API response:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error in convertEstimateToProject:', error);
    throw error;
  }
};

export const createProjectInvoice = async (projectId, data) => {
  try {
    console.log('Making API call with data:', data);
    const response = await api.post(`/projects/${projectId}/create-invoice`, data);
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in createProjectInvoice:', error);
    throw error;
  }
};




export const generateInvoiceShareLink = async (invoiceId) => {
  try {
    console.log('Generating share link for invoice:', invoiceId);
    const response = await api.post(`/invoices/${invoiceId}/generate-share-link`, {
      is_password_protected: false
    });
    console.log('Share link response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error generating share link:', error.response?.data || error.message);
    throw error;
  }
};

export const getPublicInvoice = async (uuid, password = null) => {
  try {
    const response = await api.post(`/public/invoices/${uuid}`, {
      password: password
    });
    return response;
  } catch (error) {
    console.error('Error fetching public invoice:', error);
    throw error;
  }
};

export const getFinancialMetrics = async () => {
  try {
    const response = await api.get('/dashboard/financial-metrics');
    return response.data;
  } catch (error) {
    console.error('Error fetching financial metrics:', error);
    throw error;
  }
};

export const generateEmployeeRegistrationLink = async () => {
  try {
    const response = await api.post('/employee/generate-registration-link');
    return response.data;
  } catch (error) {
    console.error('Error generating registration link:', error);
    throw error;
  }
};

export const registerEmployee = async (data) => {
  try {
    const response = await api.post('/public/employee-registration', data);
    return response.data;
  } catch (error) {
    console.error('Error registering employee:', error);
    throw error;
  }
};

export const updateEmployeeRate = async (employeeId, newRate) => {
  try {
    const response = await api.patch(`/employees/${employeeId}/rate`, { default_rate: newRate });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEmployeeDetails = async (employeeId, updatedDetails) => {
  try {
    const response = await api.patch(`/employees/${employeeId}`, updatedDetails);
    return response.data;
  } catch (error) {
    console.error('Error updating employee details:', error);
    throw error;
  }
};

export const duplicateEstimate = async (estimateId) => {
  try {
    const response = await api.post(`/estimates/${estimateId}/duplicate`);
    return response.data;
  } catch (error) {
    console.error('Error duplicating estimate:', error);
    throw error;
  }
};

export const getTimesheet = async (id) => {
  try {
    console.log('API call starting for timesheet:', id);
    const response = await api.get(`/admin/timesheet/${id}`);
    console.log('API response received:', response);
    return response.data;
  } catch (error) {
    console.error('API call failed:', error.response || error);
    throw error;
  }
};

export const deleteTimesheet = async (id) => {
  try {
    const response = await api.delete(`/admin/timesheet/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting timesheet:', error);
    throw error;
  }
};

export const duplicateTimesheet = async (id) => {
  const response = await api.post(`/timesheets/${id}/duplicate`);
  return response.data;
};

export const updateTimesheet = async (id, data) => {
  try {
    console.log('Updating timesheet:', id, 'with data:', data);
    const response = await api.patch(`/admin/timesheet/${id}`, {
      payment_type: data.payment_type,
      rate: data.rate,
      calculated_payment: data.calculated_payment,
    });
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating timesheet:', error.response?.data || error);
    throw error;
  }
};

export const getEmployeeRequests = async () => {
  try {
    const response = await api.get('/employee-requests');
    return response.data;
  } catch (error) {
    console.error('Error fetching employee requests:', error);
    throw error;
  }
};

export const updateRequestStatus = async (requestId, status, responseNote = '') => {
  try {
    const response = await api.patch(`/employee-requests/${requestId}`, {
      status,
      response_note: responseNote
    });
    return response.data;
  } catch (error) {
    console.error('Error updating request status:', error);
    throw error;
  }
};

export const createEmployeeRequest = async (requestData) => {
  try {
    const response = await api.post('/employee-requests', requestData);
    return response.data;
  } catch (error) {
    console.error('Error creating employee request:', error);
    throw error;
  }
};

export const getMyRequests = async () => {
  try {
    const response = await api.get('/employee/my-requests');
    return response.data;
  } catch (error) {
    console.error('Error fetching my requests:', error);
    throw error;
  }
};

export const exportTimesheetsCSV = async (filters = {}) => {
  try {
    console.log('Starting export with filters:', filters);
    
    const queryParams = new URLSearchParams({
      project: filters.project || 'All',
      employee: filters.employee || 'All',
      event: filters.event || 'All',
      start_date: filters.startDate ? new Date(filters.startDate).toISOString() : '',
      end_date: filters.endDate ? new Date(filters.endDate).toISOString() : ''
    }).toString();

    console.log('Query params:', queryParams);

    const response = await api.get(`/admin/timesheets/export?${queryParams}`, {
      responseType: 'blob'
    });

    console.log('Response received:', response);

    // Create and trigger download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `timesheets-export-${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);

    return true;
  } catch (error) {
    console.error('Error in exportTimesheetsCSV:', error);
    throw error;
  }
};

export const getEntityTags = async (entityType, entityId) => {
  try {
    const response = await api.get(`/${entityType}/${entityId}/tags`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${entityType} tags:`, error);
    throw error;
  }
};

export const updateEntityTags = async (entityType, entityId, tagData) => {
  try {
    const response = await api.post(`/${entityType}/${entityId}/tags`, tagData);
    return response.data;
  } catch (error) {
    console.error(`Error updating ${entityType} tags:`, error);
    throw error;
  }
};

// Add this with your other API functions
export const createTag = async (tagData) => {
  try {
    const response = await api.post('/tags', tagData);
    return response.data;
  } catch (error) {
    console.error('Error creating tag:', error);
    throw error;
  }
};

export const getAllTags = async () => {
  try {
    const response = await api.get('/tags');
    return response.data;
  } catch (error) {
    console.error('Error fetching tags:', error);
    throw error;
  }
};

export const deleteTag = async (tagId) => {
  try {
    const response = await api.delete(`/tags/${tagId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting tag:', error);
    throw error;
  }
};

export const updateInvoice = async (id, invoiceData) => {
  try {
    console.log('Updating invoice:', id, 'with data:', invoiceData); // Debug log
    const response = await api.put(`/invoices/${id}`, invoiceData);
    console.log('Update response:', response.data); // Debug log
    return response.data;
  } catch (error) {
    console.error('Error updating invoice:', error.response?.data || error);
    throw error;
  }
};

export const updateRecurringAvailability = async (
  employeeId, 
  days, 
  startDate, 
  endDate, 
  isAvailable
) => {
  try {
    console.log('Sending recurring availability update:', {
    employeeId,
    days,
    startDate,
    endDate,
    isAvailable
  });

    const response = await api.post(
      `/employee/${employeeId}/recurring-availability`,
      {
        days: days,
        start_date: startDate,
        end_date: endDate,
        is_available: isAvailable
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error in updateRecurringAvailability:', error);
    throw error;
  }
};

export const updateProjectDates = async (projectId) => {
  try {
    console.log(`Calling updateProjectDates for project ${projectId}`);
    
    // Update project dates
    const datesResponse = await api.post(`/projects/${projectId}/update-dates`);
    console.log('Update project dates response:', datesResponse.data);
    
    // Update project status based on events
    if (datesResponse.data.start_time && datesResponse.data.end_time) {
      // If we have valid dates, it means we have at least one scheduled event
      const statusResponse = await updateProjectStatus(projectId, 'scheduled');
      console.log('Update project status response:', statusResponse);
    } else {
      // If no valid dates, check if we need to revert status
      const statusResponse = await updateProjectStatus(projectId, 'pending');
      console.log('Reverting project status response:', statusResponse);
    }
    
    return datesResponse.data;
  } catch (error) {
    console.error('Error updating project dates and status:', error);
    throw error;
  }
};

export const checkEventAvailability = async (eventId) => {
  try {
    // Add some debug logging
    console.log('Checking availability for event:', eventId);
    console.log('Current auth token:', localStorage.getItem('token'));

    const response = await api.get(`/events/${eventId}/availability`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    // More detailed error logging
    console.error('Error checking event availability:', error);
    console.error('Response:', error.response?.data);
    console.error('Status:', error.response?.status);
    throw error;
  }
};

export const sendTestNotification = async (notificationData) => {
  try {
    const response = await api.post('/notifications/test', notificationData);
    return response.data;
  } catch (error) {
    console.error('Error sending test notification:', error);
    throw error;
  }
};

// Add these functions to your existing api.js

// Get all notifications
export const getNotifications = async () => {
  try {
    const response = await api.get('/notifications');
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }
};

// Get unread notification count
export const getUnreadNotificationCount = async () => {
  try {
    const response = await api.get('/notifications/unread');
    return response.data.count;
  } catch (error) {
    console.error('Error fetching unread count:', error);
    throw error;
  }
};

// Mark a notification as read
export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await api.post(`/notifications/${notificationId}/read`);
    return response.data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

// Mark all notifications as read
export const markAllNotificationsAsRead = async () => {
  try {
    const response = await api.post('/notifications/read-all');
    return response.data;
  } catch (error) {
    console.error('Error marking all notifications as read:', error);
    throw error;
  }
};

export const searchClients = async (query) => {
  try {
    const response = await api.get(`/clients/search?search=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    console.error('Error searching clients:', error);
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const response = await api.post(`/delete_project/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};

export const updateEventStatus = async (eventId, status) => {
  try {
    const response = await api.put(`/events/${eventId}/status`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating event status:', error);
    throw error;
  }
};

export const searchGlobal = async (query) => {
  try {
    const response = await api.get('/search', {
      params: { q: query }
    });
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const vannaSearch = async (query) => {
  try {
    const response = await api.post('/search/query', { query });
    return response.data;
  } catch (error) {
    console.error('Vanna search error:', error);
    throw error;
  }
};

export const getVannaSuggestions = async (query) => {
  try {
    const response = await api.get(`/search/suggest?q=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    console.error('Vanna suggestions error:', error);
    throw error;
  }
};

export const getClientEvents = async (clientId, startDate, endDate) => {
  try {
    let url = `/clients/${clientId}/events`;
    const params = new URLSearchParams();
    
    if (startDate) params.append('start_date', startDate);
    if (endDate) params.append('end_date', endDate);
    
    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    const response = await api.get(url);
    return response.data.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
      title: `${event.title} (${event.project?.name || 'No Project'})`
    }));
  } catch (error) {
    console.error('Error fetching client events:', error);
    throw error;
  }
};

export const getClientEstimates = async (clientId) => {
  try {
    const response = await api.get(`/clients/${clientId}/estimates`);
    return response.data;
  } catch (error) {
    console.error('Error fetching client estimates:', error);
    throw error;
  }
};

export const getClientInvoices = async (clientId) => {
  try {
    const response = await api.get(`/clients/${clientId}/invoices`);
    return response.data;
  } catch (error) {
    console.error('Error fetching client invoices:', error);
    throw error;
  }
};

export const getPriceBookItems = async () => {
  try {
    const response = await api.get('/price-book/items');
    return response.data;
  } catch (error) {
    console.error('Error fetching price book items:', error);
    throw error;
  }
};

export const createPriceBookItem = async (itemData) => {
  try {
    const response = await api.post('/price-book/items', {
      ...itemData,
      is_area_based: itemData.calculationType === 'area'
    });
    return response.data;
  } catch (error) {
    console.error('Error creating price book item:', error);
    throw error;
  }
};

export const updatePriceBookItem = async (itemId, itemData) => {
  try {
    const response = await api.put(`/price-book/items/${itemId}`, itemData);
    return response.data;
  } catch (error) {
    console.error('Error updating price book item:', error);
    throw error;
  }
};

export const deletePriceBookItem = async (itemId) => {
  try {
    console.log('API call to delete item:', itemId);
    const response = await api.delete(`/price-book/items/${itemId}`);
    console.log('Delete response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in deletePriceBookItem:', error.response || error);
    throw error;
  }
};
// Fix the createPricingRule function
export const createPricingRule = async (ruleData) => {
  try {
    // Convert camelCase to snake_case for backend
    const apiData = {
      name: ruleData.name,
      description: ruleData.description || '',
      service_type: ruleData.serviceType,
      calculation_type: ruleData.calculationType,
      area_calculation_type: ruleData.areaCalculationType,
      base_rate: parseFloat(ruleData.baseRate) || 0,
      field_values: ruleData.fieldValues || {}
    };

    console.log('Sending create request with data:', apiData);
    const response = await api.post('/pricing-rules', apiData);
    return {
      ...response.data,
      // Convert back to camelCase for frontend
      serviceType: response.data.service_type,
      calculationType: response.data.calculation_type,
      areaCalculationType: response.data.area_calculation_type,
      baseRate: response.data.base_rate,
      fieldValues: response.data.field_values
    };
  } catch (error) {
    console.error('Create pricing rule error:', error);
    throw error;
  }
};

// Fix the updatePricingRule function
export const updatePricingRule = async (ruleData) => {
  try {
    // Convert camelCase to snake_case for backend
    const apiData = {
      name: ruleData.name,
      description: ruleData.description || '',
      service_type: ruleData.serviceType,
      calculation_type: ruleData.calculationType,
      area_calculation_type: ruleData.areaCalculationType,
      base_rate: parseFloat(ruleData.baseRate) || 0,
      field_values: ruleData.fieldValues || {}
    };

    console.log('Sending update request with data:', apiData);
    const response = await api.put(`/pricing-rules/${ruleData.id}`, apiData);
    return {
      ...response.data,
      // Convert back to camelCase for frontend
      serviceType: response.data.service_type,
      calculationType: response.data.calculation_type,
      areaCalculationType: response.data.area_calculation_type,
      baseRate: response.data.base_rate,
      fieldValues: response.data.field_values
    };
  } catch (error) {
    console.error('Update pricing rule error:', error);
    throw error;
  }
};

// Fix the deletePricingRule function
export const deletePricingRule = async (ruleId) => {
  try {
    // Use the api instance instead of axios directly and remove /api prefix
    const response = await api.delete(`/pricing-rules/${ruleId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting pricing rule:', error);
    throw error;
  }
};

// Add getPricingRules if it's missing
export const getPricingRules = async () => {
  try {
    const response = await api.get('/pricing-rules');
    // Convert snake_case to camelCase for frontend
    return response.data.map(rule => ({
      ...rule,
      serviceType: rule.service_type,
      calculationType: rule.calculation_type,
      areaCalculationType: rule.area_calculation_type,
      baseRate: rule.base_rate,
      fieldValues: rule.field_values
    }));
  } catch (error) {
    console.error('Error fetching pricing rules:', error);
    throw error;
  }
};


// Add these export functions to your api.js
export const getPackages = async () => {
  try {
    console.log('🚀 Fetching packages from /packages');
    const response = await api.get('/packages');
    console.log('📦 Raw response:', response.data);

    const transformedData = response.data.map(pkg => ({
      ...pkg,
      items: pkg.items.map(item => ({
        ...item,
        item: {
          id: item.item_id,
          name: item.name,
          title: item.name,
          price: parseFloat(item.price) || 0,
          baseRate: parseFloat(item.price) || 0
        }
      }))
    }));

    console.log('🔄 Transformed packages:', transformedData);
    return transformedData;
  } catch (error) {
    console.error('💥 Error fetching packages:', error);
    throw error;
  }
};

export const createPackage = async (packageData) => {
  try {
    // Ensure the data is properly formatted
    const apiData = {
      name: packageData.name,
      description: packageData.description || '',
      items: packageData.items.map(item => ({
        type: item.type,
        item_id: parseInt(item.item_id),
        quantity: parseInt(item.quantity) || 1,
        price: parseFloat(item.price) || 0,
        name: item.name || ''
      }))
    };

    console.log('API request data:', apiData);
    const response = await api.post('/packages', apiData);
    return response.data;
  } catch (error) {
    console.error('Error in createPackage:', error);
    console.error('Server response:', error.response?.data);
    throw error;
  }
};

export const updatePackage = async (packageId, packageData) => {
  try {
    console.log('Updating package:', packageId, packageData);
    const response = await api.put(`/packages/${packageId}`, packageData);
    return response.data;
  } catch (error) {
    console.error('Error updating package:', error);
    throw error;
  }
};

export const deletePackage = async (id) => {
  try {
    const response = await api.delete(`/packages/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting package:', error);
    throw error;
  }
};

export const getAllClients = async () => {
  try {
    const response = await api.get('/clients');
    return response.data;
  } catch (error) {
    console.error('Error fetching all clients:', error);
    throw error;
  }
};

export const getClientServiceAddresses = async (clientId) => {
  try {
    const response = await api.get(`/clients/${clientId}/service-addresses`);
    return response.data;
  } catch (error) {
    console.error('Error fetching client service addresses:', error);
    throw error;
  }
};

export const addPropertyToClient = async (clientId, propertyData) => {
  try {
    const response = await api.post(`/clients/${clientId}/add-property`, propertyData);
    return response.data;
  } catch (error) {
    console.error('Error adding property to client:', error);
    throw error;
  }
};

export const getClientProperties = async (clientId) => {
  try {
    const response = await api.get(`/clients/${clientId}/properties`);
    return response.data;
  } catch (error) {
    console.error('Error getting client properties:', error);
    throw error;
  }
};

export const getPropertyById = async (propertyId) => {
  try {
    console.log('Fetching property details for ID:', propertyId);
    const response = await api.get(`/properties/${propertyId}`);
    console.log('Property response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching property by ID:', error);
    throw error;
  }
};

export const createAutomation = async (automationData) => {
  try {
    const response = await api.post('/automations', automationData);
    // Fetch latest notifications after creating automation
    await getAutomationNotifications();
    return response.data;
  } catch (error) {
    console.error('Error creating automation:', error);
    throw error;
  }
};

export const getAutomations = async () => {
  try {
    const response = await api.get('/automations');
    return response.data;
  } catch (error) {
    console.error('Error fetching automations:', error);
    throw error;
  }
};

export const getAutomation = async (id) => {
  try {
    const response = await api.get(`/automations/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching automation:', error);
    throw error;
  }
};

export const updateAutomation = async (id, automationData) => {
  try {
    const payload = {
      title: automationData.title,
      trigger: {
        type: automationData.trigger.id,
        action: automationData.trigger.action.id
      },
      action: {
        type: automationData.action.id,
        recipient: automationData.recipient?.id,
        message_type: automationData.type?.id,
        timing: automationData.time?.id,
        schedule: {
          type: automationData.timeOption,
          start_time: automationData.customStartTime,
          end_time: automationData.customEndTime
        }
      }
    };

    const response = await api.put(`/automations/${id}`, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating automation:', error);
    throw error;
  }
};

export const toggleAutomation = async (id) => {
  try {
    const response = await api.post(`/automations/${id}/toggle`);
    // Fetch latest notifications after toggling
    await getAutomationNotifications();
    return response.data;
  } catch (error) {
    console.error('Error toggling automation:', error);
    throw error;
  }
};

export const deleteAutomation = async (id) => {
  try {
    const response = await api.delete(`/automations/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting automation:', error);
    throw error;
  }
};

// Automation Message Handlers
export const sendAutomationEmail = async (emailData) => {
  try {
    const response = await api.post('/automations/send-email', emailData);
    return response.data;
  } catch (error) {
    console.error('Error sending automation email:', error);
    throw error;
  }
};

export const sendAutomationSMS = async (smsData) => {
  try {
    const response = await api.post('/automations/send-sms', smsData);
    return response.data;
  } catch (error) {
    console.error('Error sending automation SMS:', error);
    throw error;
  }
};

export const sendVerification = async (verificationData) => {
  try {
    const response = await api.post('/automations/send-verification', verificationData);
    return response.data;
  } catch (error) {
    console.error('Error sending verification:', error);
    throw error;
  }
};

export const verifyCode = async (verificationData) => {
  try {
    const response = await api.post('/automations/verify-code', verificationData);
    return response.data;
  } catch (error) {
    console.error('Error verifying code:', error);
    throw error;
  }
};

// Trigger-specific handlers
export const handleClientCreation = async (clientId) => {
  try {
    const response = await api.post(`/automations/client/${clientId}/created`);
    return response.data;
  } catch (error) {
    console.error('Error handling client creation:', error);
    throw error;
  }
};

export const handleClientScheduled = async (clientId) => {
  try {
    const response = await api.post(`/automations/client/${clientId}/scheduled`);
    return response.data;
  } catch (error) {
    console.error('Error handling client scheduled:', error);
    throw error;
  }
};

export const handleProjectCreation = async (projectId) => {
  try {
    const response = await api.post(`/automations/project/${projectId}/created`);
    return response.data;
  } catch (error) {
    console.error('Error handling project creation:', error);
    throw error;
  }
};

export const handleProjectStatus = async (projectId, status) => {
  try {
    const response = await api.post(`/automations/project/${projectId}/status`, { status });
    return response.data;
  } catch (error) {
    console.error('Error handling project status:', error);
    throw error;
  }
};

export const handleEstimateAction = async (estimateId, action) => {
  try {
    const response = await api.post(`/automations/estimate/${estimateId}/${action}`);
    return response.data;
  } catch (error) {
    console.error(`Error handling estimate ${action}:`, error);
    throw error;
  }
};

export const processAutomationMessage = async (messageData) => {
  try {
    const response = await api.post('/automations/process-message', messageData);
    return response.data;
  } catch (error) {
    console.error('Error processing automation message:', error);
    throw error;
  }
};

// Add these functions to handle automation notifications
export const getAutomationNotifications = async () => {
  try {
    const response = await api.get('/notifications', {
      params: { type: 'automation' }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching automation notifications:', error);
    throw error;
  }
};

export const getAutomationDetails = async (id) => {
  try {
    const response = await api.get(`/automations/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching automation details:', error);
    throw error;
  }
};

export default api;


import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { 
  Buildings as BusinessIcon,
  CreditCard as PaymentIcon,
  FileText as DescriptionIcon,
  Headset as SupportIcon,
  Gear as AppSettingsIcon,
  Wallet as AccountBalanceWalletIcon,
  Bell as NotificationsIcon,
  Robot as AutomationIcon
} from "@phosphor-icons/react";
import { useTheme } from '../ThemeContext';
import { useLocation } from 'react-router-dom';

// Import components
import AdminSettingsBusinessDetails from './AdminSettingsBusinessDetails';
import AdminSettingsPayment from './AdminSettingsPayment';
import TermsTemplatesManager from './settings/TermsTemplatesManager';
import AdminSettingsSupport from './AdminSettingsSupport';
import AdminSettingsAppSettings from './AdminSettingsAppSettings';
import AdminSettingsMyMoney from './AdminSettingsMyMoney';
import AdminSettingsNotifications from './AdminSettingsNotifications';
import AdminSettingsAutomation from './AdminSettingsAutomation';

function AdminSettings() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 0);
  const [slideDirection, setSlideDirection] = useState('left');
  const [isTransitioning, setIsTransitioning] = useState(false);

  const menuItems = [
    { icon: <BusinessIcon size={20} weight="light" />, label: "Business Profile", component: AdminSettingsBusinessDetails },
    { icon: <PaymentIcon size={20} weight="light" />, label: "Payment", component: AdminSettingsPayment },
    { icon: <DescriptionIcon size={20} weight="light" />, label: "Terms & Conditions", component: TermsTemplatesManager },
    { icon: <SupportIcon size={20} weight="light" />, label: "Support", component: AdminSettingsSupport },
    { icon: <AppSettingsIcon size={20} weight="light" />, label: "App Settings", component: AdminSettingsAppSettings },
    { icon: <NotificationsIcon size={20} weight="light" />, label: "Notifications", component: AdminSettingsNotifications },
    { icon: <AutomationIcon size={20} weight="light" />, label: "Automations", component: AdminSettingsAutomation }
  ];

  const handleTabChange = (event, newValue) => {
    const oldIndex = activeTab;
    setSlideDirection(oldIndex < newValue ? 'left' : 'right');
    setIsTransitioning(true);
    setActiveTab(newValue);
    
    // Reset transition state after animation completes
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--background-default)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          px: 3,
          py: 2,
          backgroundColor: 'var(--background-paper)',
          flexShrink: 0
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: '1.5rem',
            fontWeight: 700,
            color: 'var(--text-primary)',
          }}
        >
          Admin Settings
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: 'var(--background-paper)',
          borderBottom: '1px solid #e0e0e0',
          flexShrink: 0,
        }}
      >
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
          }}
          sx={{
            px: 2,
            '& .MuiTabs-indicator': {
              backgroundColor: '#7635dc',
              height: '2px',
              borderRadius: '3px 3px 0 0',
              transition: 'all 0.2s ease'
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              color: 'var(--text-secondary)',
              minHeight: 48,
              padding: '12px 24px',
              gap: '8px',
              position: 'relative',
              '& .MuiTouchRipple-root': {
                display: 'none'
              },
              '& svg': {
                marginBottom: '0 !important',
                marginRight: '0 !important'
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0,
                height: '2px',
                borderRadius: '3px 3px 0 0',
                backgroundColor: '#7635dc',
                opacity: 0.6,
                transition: 'all 0.2s ease',
                transform: 'translateX(-50%)',
              },
              '&:hover::after': {
                width: '80%',
              },
              '&.Mui-selected': {
                color: 'var(--primary-main)',
                fontWeight: 600,
                '&::after': {
                  opacity: 0,
                },
              },
              '&:hover': {
                color: 'var(--primary-main)',
              }
            },
          }}
        >
          {menuItems.map((item, index) => (
            <Tab 
              key={index}
              icon={item.icon}
              iconPosition="start"
              label={item.label}
            />
          ))}
        </Tabs>
      </Box>

      <Box
        sx={{
          flex: 1,
          backgroundColor: 'var(--background-default)',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {menuItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: activeTab === index ? 'block' : 'none',
              height: '100%',
              animation: `${slideDirection === 'left' ? 'slideLeft' : 'slideRight'} 0.3s ease-out`,
              opacity: isTransitioning ? 0.3 : 1,
              transition: 'opacity 0.3s ease-out',
              '@keyframes slideLeft': {
                from: {
                  opacity: 0,
                  transform: 'translateX(20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateX(0)',
                },
              },
              '@keyframes slideRight': {
                from: {
                  opacity: 0,
                  transform: 'translateX(-20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateX(0)',
                },
              },
            }}
          >
            {React.createElement(item.component)}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default AdminSettings;

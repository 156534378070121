// src/components/ClientDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getClientDetails, updateClient, searchClients, getClientInvoices, addPropertyToClient, getClientProperties } from '../services/api';
import { 
  Box, Typography, Tabs, Tab, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar, Alert, Menu, DialogContentText
} from '@mui/material';
import Overview from './client/Overview';
import ClientDetailsProject from './client/ClientDetailsProject';
import Invoices from './client/Invoices';
import Documents from './client/Documents';
import ClientSidebar from './client/ClientSidebar';
import styles from './ClientDetails.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Estimates from './client/Estimates';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Email, Edit, MoreHoriz } from '@mui/icons-material';
import TagsSection from './TagsSection';
import {
  Calculator,  // for Estimates
  Hammer,      // for Projects
  Receipt as ReceiptIcon,  // Rename to avoid conflict
  DotsThreeOutline,
  PlusCircle
} from "@phosphor-icons/react";
import Badge from '@mui/material/Badge';
import Events from './client/Events';
import { Calendar } from "@phosphor-icons/react";
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { Receipt } from "@phosphor-icons/react";
import { PencilSimple, Check, X } from "@phosphor-icons/react";
import ClientProperties from './ClientProperties';

const STATUS_STYLES = {
  'paid': {
    color: '#10B981',  // Green
    backgroundColor: '#E8F5E9'
  },
  'due': {
    color: '#1565C0',  // Blue
    backgroundColor: '#E3F2FD'
  },
  'overdue': {
    color: '#EF4444',  // Red
    backgroundColor: '#FEE2E2'
  },
  'awaiting payment': {
    color: '#F59E0B',  // Orange
    backgroundColor: '#FEF3C7'
  },
  'draft': {
    color: '#6B7280',  // Gray
    backgroundColor: '#F3F4F6'
  },
  'void': {
    color: '#6B7280',  // Gray
    backgroundColor: '#F3F4F6'
  }
};

const ClientDetails = () => {
  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'var(--background-secondary)',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'var(--text-primary)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--primary-main)',
      },
      '& input, & textarea': {
        color: 'var(--text-primary)',
      }
    },
    '& .MuiInputLabel-root': {
      color: 'var(--text-secondary)',
      '&.Mui-focused': {
        color: 'var(--primary-main)',
      }
    },
    '& .MuiSelect-select': {
      color: 'var(--text-primary)',
      backgroundColor: 'var(--background-secondary)',
    }
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [slideDirection, setSlideDirection] = useState('left');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [overdueCount, setOverdueCount] = useState(0);
  const [parentClientSearchResults, setParentClientSearchResults] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [editedNotes, setEditedNotes] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState('');
  const [openAddToBalanceModal, setOpenAddToBalanceModal] = useState(false);
  const [clientProperties, setClientProperties] = useState([]);

  const fetchClientDetails = async () => {
    try {
      setLoading(true);
      const data = await getClientDetails(id);
      setClient(data);
      setError(null);
    } catch (err) {
      console.error('Failed to fetch client details:', err);
      setError(err.message || 'Failed to load client details');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientDetails();
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditClick = () => {
    setEditedClient({ ...client });
    setOpenEditDialog(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedClient(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      
      // Create a clean data object with only the fields we want to update
      const dataToSend = {
        name: editedClient?.name || '',
        email: editedClient?.email || '',
        phone: editedClient?.phone || '',
        company: editedClient?.company || '',
        address: editedClient?.address || '',
        notes: editedClient?.notes || '',
        lead_source: editedClient?.lead_source || null,
        parent_client_id: editedClient?.parent_client_id ? parseInt(editedClient.parent_client_id) : null,
        city: editedClient?.city || '',
        state: editedClient?.state || '',
        zip_code: editedClient?.zip_code || ''
      };

      console.log('Clean data being sent to API:', dataToSend);

      const response = await updateClient(id, dataToSend);
      console.log('API Response:', response);
      
      setOpenEditDialog(false);
      setShowSuccessAlert(true);
      await fetchClientDetails();
      
    } catch (error) {
      console.error('Failed to update client:', error);
      console.error('Error details:', error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setShowSuccessAlert(false);
  };

  const calculateOverdueInvoices = (clientData) => {
    if (!clientData?.invoices) return 0;
    const today = new Date();
    return clientData.invoices.filter(invoice => {
      if (invoice.status?.toLowerCase() === 'paid') return false;
      const dueDate = new Date(invoice.due_date);
      return dueDate < today;
    }).length;
  };

  useEffect(() => {
    if (client) {
      setOverdueCount(calculateOverdueInvoices(client));
    }
  }, [client]);

  const handleOverdueCountChange = (count) => {
    setOverdueCount(count);
  };

  const tabs = [
    { 
      label: 'Upcoming', 
      icon: <Calendar size={20} weight="light" color="#637381" />,
      component: Events 
    },
    { 
      label: 'Estimates', 
      icon: <Calculator size={20} weight="light" color="#637381" />,
      component: Estimates 
    },
    { 
      label: 'Projects', 
      icon: <Hammer size={20} weight="light" color="#637381" />,
      component: ClientDetailsProject 
    },
    { 
      label: (
        <Badge 
          badgeContent={overdueCount > 0 ? overdueCount : null} 
          color="error"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '& .MuiBadge-badge': {
              fontSize: '0.60rem',
              minWidth: '15px',
              height: '15px',
              borderRadius: '10px',
              right: -8,
              top: -2
            }
          }}
        >
          Invoices
        </Badge>
      ),
      icon: <ReceiptIcon size={20} weight="light" color="#637381" />,
      component: (props) => <Invoices {...props} onOverdueCountChange={handleOverdueCountChange} />
    }
  ];

  const ActiveComponent = tabs[activeTab].component;

  // Add this phone formatting function
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    
    // Remove all non-digits
    const phoneNumber = value.replace(/[^\d]/g, '');
    
    // Get length of the input
    const phoneNumberLength = phoneNumber.length;
    
    // Return if length is less than 4
    if (phoneNumberLength < 4) return phoneNumber;
    
    // Return formatted phone number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  // Add this handler for phone input
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setEditedClient(prev => ({
      ...prev,
      phone: formattedPhone
    }));
  };

  const handleParentClientSearch = async (searchQuery) => {
    try {
      console.log('Searching with query:', searchQuery); // Debug log
      if (searchQuery.length >= 2) {
        const results = await searchClients(searchQuery);
        console.log('Search results:', results); // Debug log
        
        // Filter out the current client
        const filteredResults = results.filter(c => c.id !== parseInt(id));
        console.log('Filtered results:', filteredResults); // Debug log
        
        setParentClientSearchResults(filteredResults);
      } else {
        setParentClientSearchResults([]);
      }
    } catch (error) {
      console.error('Failed to search clients:', error);
      setParentClientSearchResults([]);
    }
  };

  useEffect(() => {
    handleParentClientSearch(' '); // Load initial results with space
  }, []);

  // Add this function to handle parent client selection
  const handleParentClientChange = (event, newValue) => {
    console.log('Parent client change - Selected value:', newValue);
    
    // Update the editedClient state with the new parent client ID
    setEditedClient(prev => {
      const updated = {
        ...prev,
        parent_client_id: newValue ? parseInt(newValue.id) : null,
        // Also update the parent_client object for reference
        parent_client: newValue || null
      };
      console.log('Updated client data:', updated);
      return updated;
    });
  };

  // Update the useEffect for initial parent client data
  useEffect(() => {
    if (editedClient?.parent_client_id) {
      console.log('Loading parent client data for ID:', editedClient.parent_client_id);
      // Load the parent client data if it exists
      const loadParentClient = async () => {
        try {
          const client = await getClientDetails(editedClient.parent_client_id);
          setParentClientSearchResults([client]);
        } catch (error) {
          console.error('Failed to load parent client:', error);
        }
      };
      loadParentClient();
    }
  }, [editedClient?.parent_client_id]);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      if (client?.id) {
        try {
          const invoices = await getClientInvoices(client.id);
          // Filter to only show paid invoices
          setBillingHistory(invoices);
        } catch (error) {
          console.error('Error fetching billing history:', error);
        }
      }
    };

  fetchBillingHistory();
}, [client?.id]);

  const handleSaveNotes = async () => {
    try {
      const updatedClient = await updateClient(client.id, {
        ...client,
        notes: editedNotes
      });
      setIsEditingNotes(false);
      // Update the client data in parent component or refetch
      if (typeof fetchClientDetails === 'function') {
        await fetchClientDetails();
      }
    } catch (error) {
      console.error('Failed to save notes:', error);
    }
  };

  // Add this helper function at component level
  const calculateInvoiceAmount = (invoice) => {
    // If invoice has a direct amount, use it
    if (invoice.amount) {
      return Number(invoice.amount) || 0;
    }
    
    // If invoice has items, calculate from items
    if (invoice.items && Array.isArray(invoice.items)) {
      return invoice.items.reduce((sum, item) => {
        return sum + (Number(item.total) || 0);
      }, 0);
    }
    
    return 0;
  };

  const handleMoreActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setAnchorEl(null);
  };

  const handleAddToBalanceClick = () => {
    setOpenAddToBalanceModal(true);
  };

  const handleAddToBalanceModalClose = () => {
    setOpenAddToBalanceModal(false);
  };

  const handleAddToBalanceConfirm = () => {
    // Handle adding to balance
    setOpenAddToBalanceModal(false);
  };

  const handleAddProperty = async (propertyAddress) => {
    try {
      await addPropertyToClient(client.id, propertyAddress);
      // Refresh the client details to show the updated properties
      fetchClientDetails();
    } catch (error) {
      console.error('Failed to add property:', error);
    }
  };

  const fetchClientProperties = async () => {
    try {
      const properties = await getClientProperties(client.id);
      setClientProperties(properties);
    } catch (error) {
      console.error('Failed to fetch client properties:', error);
    }
  };

  useEffect(() => {
    if (client?.id) {
      fetchClientProperties();
    }
  }, [client?.id]);

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!client) return <div className={styles.error}>No client found</div>;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Top Action Bar - Now without client name */}
      <Box sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--divider)',
      }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/admin/clients')}
          sx={{ 
            color: 'var(--text-primary)',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }}
        >
          Back to Clients
        </Button>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            startIcon={<Email />}
            variant="contained"
            sx={{
              textTransform: 'none',
              backgroundColor: '#4A5AF9',
              boxShadow: 'none',
              fontWeight: 700,
              fontSize: '0.875rem',
              padding: '6px 16px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#3C50FA',
                boxShadow: 'none',
              }
            }}
          >
            Email
          </Button>
          <Button
            startIcon={<Edit />}
            variant="outlined"
            onClick={handleEditClick}
            sx={{
              textTransform: 'none',
              borderColor: '#E0E0E0',
              color: 'text.secondary',
              fontWeight: 500,
              fontSize: '0.875rem',
              padding: '6px 16px',
              borderRadius: '8px',
              '&:hover': {
                borderColor: 'text.primary',
                backgroundColor: 'transparent'
              }
            }}
          >
            Edit
          </Button>
          <Button
            aria-controls="more-actions-menu"
            aria-haspopup="true"
            onClick={handleMoreActionsClick}
            startIcon={<DotsThreeOutline size={20} weight="fill" />}
            variant="outlined"
            sx={{
              textTransform: 'none',
              borderColor: '#E0E0E0',
              color: 'text.secondary',
              fontWeight: 500,
              fontSize: '0.875rem',
              padding: '6px 16px',
              borderRadius: '8px',
              '&:hover': {
                borderColor: 'text.primary',
                backgroundColor: 'transparent'
              }
            }}
          >
            More Actions
          </Button>
          <Menu
            id="more-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMoreActionsClose}
          >
            <MenuItem onClick={handleAddToBalanceClick} sx={{
              color: 'var(--text-primary)',
              '&:hover': {
                backgroundColor: 'var(--hover-overlay)'
              }
            }}>
              <PlusCircle size={20} weight="light" style={{ marginRight: '8px' }} />
              Add to Balance
            </MenuItem>
            {/* Add more menu items here if needed */}
          </Menu>
        </Box>
      </Box>

      {/* Main Content Area */}
      <Box sx={{ display: 'flex', flex: 1 }}>
        {/* Left Content */}
        <Box sx={{ flex: 1, p: 3,}}>
          {/* Client Name Section */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            mb: 4 
          }}>
            <Box sx={{ 
              width: 48,
              height: 48,
              borderRadius: '50%', 
              bgcolor: 'var(--background-secondary)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2,
              color: 'var(--text-secondary)',
            }}>
              <Typography sx={{ fontSize: '1.25rem' }}>
                {client?.name?.charAt(0)}
              </Typography>
            </Box>
            <Typography sx={{ 
              color: 'var(--text-primary)',
              fontSize: '2rem',
              fontWeight: 500,
              fontFamily: "'Inter', sans-serif"
            }}>
              {client?.name}
            </Typography>
          </Box>

          {/* Add Properties section before tabs */}
          <ClientProperties client={client} />

          {/* Tabs Section */}
          <Box>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
        
              TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />,
              }}
              sx={{
                px: 2,
                '& .MuiTabs-flexContainer': {
                  borderBottom: '1px solid var(--divider)',
                },

                '& .MuiTabs-indicator': {
                  
                  backgroundColor: '#7635dc',
                  height: '2px',
                  width: '80%',
                  borderRadius: '3px 3px 0 0',
                  transition: 'all 0.2s ease'
                },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontSize: '0.95rem',
                  fontFamily: 'Urbanist',
                  fontWeight: 500,
                  color: 'var(--text-secondary)',
                  minHeight: 48,
                  padding: '12px 24px',
                  gap: '8px',
                  position: 'relative',
                  '& .MuiTouchRipple-root': {
                    display: 'none'
                  },
                  '& svg': {
                    marginBottom: '0 !important',
                    marginRight: '0 !important'
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    width: 0,
                    height: '2px',
                    borderRadius: '3px 3px 0 0',
                    backgroundColor: '#7635dc',
                    opacity: 0.6,
                    transition: 'all 0.2s ease',
                    transform: 'translateX(-50%)',
                  },
                  '&:hover::after': {
                    width: '80%',
                  },
                  '&.Mui-selected': {
                    color: 'var(--primary-main)',
                    fontWeight: 600,
                    '&::after': {
                      opacity: 0,
                    },
                  },
                  '&:hover': {
                    color: 'var(--primary-main)',
                  }
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab 
                  key={index}
                  icon={tab.icon}
                  iconPosition="start"
                  label={tab.label}
                  disableRipple
                />
              ))}
            </Tabs>

            {/* Tab Content with animations */}
            {tabs.map((tab, index) => {
              const TabComponent = tab.component;
              return (
                <Box
                  key={index}
                  sx={{
                    display: activeTab === index ? 'block' : 'none',
                    height: '100%',
                    animation: `${slideDirection === 'left' ? 'slideLeft' : 'slideRight'} 0.3s ease-out`,
                    opacity: isTransitioning ? 0.3 : 1,
                    transition: 'opacity 0.3s ease-out',
                    '@keyframes slideLeft': {
                      from: {
                        opacity: 0,
                        transform: 'translateX(20px)',
                      },
                      to: {
                        opacity: 1,
                        transform: 'translateX(0)',
                      },
                    },
                    '@keyframes slideRight': {
                      from: {
                        opacity: 0,
                        transform: 'translateX(-20px)',
                      },
                      to: {
                        opacity: 1,
                        transform: 'translateX(0)',
                      },
                    },
                  }}
                >
                  <TabComponent client={client} />
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* Right Sidebar */}
        <Box sx={{ 
          width: 320, 
          borderLeft: '1px solid var(--divider)',
          p: 3 
        }}>
          <Typography 
            variant="h6" 
            sx={{ 
              mb: 2,
              fontSize: '0.875rem',
              fontWeight: 600,
              color: 'var(--text-primary)',
              fontFamily: "'Inter', sans-serif"
            }}
          >
            Contact info
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
            <Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'var(--text-secondary)',
                  fontSize: '0.875rem',
                  display: 'block',
                  mb: 0.5
                }}
              >
                Company name
              </Typography>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {client?.company}
              </Typography>
            </Box>
            <Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'var(--text-secondary)',
                  fontSize: '0.875rem',
                  display: 'block',
                  mb: 0.5
                }}
              >
                Main
              </Typography>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {client?.phone ? formatPhoneNumber(client.phone) : '(305) 444-8333'}
              </Typography>
            </Box>
            <Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'var(--text-secondary)',
                  fontSize: '0.875rem',
                  display: 'block',
                  mb: 0.5
                }}
              >
                Main
              </Typography>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {client?.email}
              </Typography>
            </Box>
            <Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'var(--text-secondary)',
                  fontSize: '0.875rem',
                  display: 'block',
                  mb: 0.5
                }}
              >
                Parent Client
              </Typography>
              <Typography>
                {client?.parent_client?.name || (
                  <Button 
                    sx={{ 
                      color: 'var(--primary-main)', 
                      p: 0,
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'underline'
                      }
                    }}
                    onClick={handleEditClick}
                  >
                    Add
                  </Button>
                )}
              </Typography>
            </Box>
            <Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'var(--text-secondary)',
                  fontSize: '0.875rem',
                  display: 'block',
                  mb: 0.5
                }}
              >
                Lead Source
              </Typography>
              <Typography>
                {client?.lead_source || (
                  <Button 
                    sx={{ 
                      color: 'var(--primary-main)', 
                      p: 0,
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'underline'
                      }
                    }}
                    onClick={handleEditClick}
                  >
                    Add
                  </Button>
                )}
              </Typography>
            </Box>
          </Box>

          {/* Tags Section */}
          <TagsSection 
            entityType="client" 
            entityId={client?.id}
            onTagsUpdate={() => {
              fetchClientDetails();
            }}
          />

          <Box sx={{ mt: 4 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 3
            }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 600,
                  fontSize: '1rem',
                  color: 'var(--text-primary)'
                }}
              >
                Billing History
              </Typography>
              <Box sx={{ textAlign: 'right' }}>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'var(--text-secondary)',
                    display: 'block',
                    mb: 0.5,
                    fontSize: '0.75rem'
                  }}
                >
                  Total Due Balance
                </Typography>
                <Typography sx={{ 
                  fontWeight: 600,
                  color: billingHistory.reduce((sum, invoice) => {
                    if (!['paid', 'void'].includes(invoice.status?.toLowerCase())) {
                      return sum + calculateInvoiceAmount(invoice);
                    }
                    return sum;
                  }, 0) > 0 ? '#EF4444' : 'var(--text-primary)',
                  fontSize: '1.125rem',
                  fontFamily: "'Inter', sans-serif",
                }}>
                  ${billingHistory.reduce((sum, invoice) => {
                    if (!['paid', 'void'].includes(invoice.status?.toLowerCase())) {
                      return sum + calculateInvoiceAmount(invoice);
                    }
                    return sum;
                  }, 0).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </Typography>
              </Box>
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: 1
            }}>
              {billingHistory.map((invoice) => {
                const getStatusStyle = (status) => {
                  const statusKey = status?.toLowerCase() || 'draft';
                  return {
                    color: STATUS_STYLES[statusKey]?.color || STATUS_STYLES['draft'].color,
                    backgroundColor: STATUS_STYLES[statusKey]?.backgroundColor || STATUS_STYLES['draft'].backgroundColor
                  };
                };

                const statusStyle = getStatusStyle(invoice.status);

                return (
                  <Box
                    key={invoice.id}
                    onClick={() => navigate(`/admin/invoices/${invoice.id}`)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 2,
                      borderRadius: '8px',
                      bgcolor: 'var(--background-paper)',
                      border: '1px solid var(--divider)',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        bgcolor: 'var(--hover-overlay)',
                        cursor: 'pointer',
                        transform: 'translateY(-1px)',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '8px',
                        bgcolor: 'var(--background-secondary)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <Receipt 
                          size={20} 
                          weight="light"
                          style={{ color: 'var(--text-secondary)' }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={{ 
                          fontWeight: 500,
                          color: 'var(--text-primary)',
                          fontFamily: "'Inter', sans-serif",
                          fontSize: '0.875rem'
                        }}>
                          {invoice.invoice_number}
                        </Typography>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: 'var(--text-secondary)',
                            fontFamily: "'Inter', sans-serif",
                            display: 'block'
                          }}
                        >
                          {invoice.issue_date ? new Date(invoice.issue_date).toLocaleDateString() : '-'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography sx={{ 
                        fontWeight: 600,
                        color: 'var(--text-primary)',
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '0.875rem'
                      }}>
                        ${calculateInvoiceAmount(invoice).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: statusStyle.color,
                          backgroundColor: statusStyle.backgroundColor,
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: 500,
                          display: 'inline-block',
                          px: 1.5,
                          py: 0.5,
                          borderRadius: '4px',
                          fontSize: '0.75rem'
                        }}
                      >
                        {invoice.status?.charAt(0).toUpperCase() + invoice.status?.slice(1).toLowerCase() || 'Draft'}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 2 
            }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 600,
                  fontSize: '1rem',
                  color: 'var(--text-primary)'
                }}
              >
                Notes
              </Typography>
              {!isEditingNotes && (
                <IconButton 
                  onClick={() => {
                    setEditedNotes(client?.notes || '');
                    setIsEditingNotes(true);
                  }}
                  sx={{ 
                    color: 'var(--text-secondary)',
                    '&:hover': { color: 'var(--primary-main)' }
                  }}
                >
                  <PencilSimple size={18} />
                </IconButton>
              )}
            </Box>
            
            <Box
              sx={{
                p: 3,
                borderRadius: '8px',
                bgcolor: 'var(--background-paper)',
                border: '1px solid var(--divider)',
                minHeight: '100px'
              }}
            >
              {isEditingNotes ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <TextField
                    multiline
                    fullWidth
                    minRows={4}
                    value={editedNotes}
                    onChange={(e) => setEditedNotes(e.target.value)}
                    variant="outlined"
                    placeholder="Enter notes here..."
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'var(--background-default)',
                        '& fieldset': {
                          borderColor: 'var(--divider)'
                        },
                        '&:hover fieldset': {
                          borderColor: 'var(--primary-main)'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'var(--primary-main)'
                        }
                      }
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <IconButton 
                      onClick={() => setIsEditingNotes(false)}
                      sx={{ 
                        color: 'var(--error-main)',
                        '&:hover': { 
                          backgroundColor: 'var(--error-light)',
                          color: 'var(--error-dark)'
                        }
                      }}
                    >
                      <X size={20} />
                    </IconButton>
                    <IconButton 
                      onClick={handleSaveNotes}
                      sx={{ 
                        color: 'var(--success-main)',
                        '&:hover': { 
                          backgroundColor: 'var(--success-light)',
                          color: 'var(--success-dark)'
                        }
                      }}
                    >
                      <Check size={20} />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <Typography
                  sx={{
                    color: client?.notes ? 'var(--text-primary)' : 'var(--text-secondary)',
                    fontFamily: "'Inter', sans-serif",
                    fontSize: '0.875rem',
                    lineHeight: 1.6,
                    whiteSpace: 'pre-wrap',
                    fontStyle: client?.notes ? 'normal' : 'italic',
                    textAlign: client?.notes ? 'left' : 'center'
                  }}
                >
                  {client?.notes || 'No notes available'}
                </Typography>
              )}
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Properties</Typography>
            {clientProperties.map((property) => (
              <Box key={property.id} sx={{ mt: 2 }}>
                <Typography>{property.address}</Typography>
                {/* Display other property details */}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Add the Edit Dialog */}
      <Dialog 
        open={openEditDialog} 
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)',
            borderRadius: '12px',
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid var(--divider)',
          px: 3,
          py: 2
        }}>
          Edit Client
        </DialogTitle>
        <DialogContent sx={{ 
          p: 3,
          pt: '24px !important',
        }}>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 1fr)', 
            gap: 2,
            mb: 2 
          }}>
            {/* First column */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Name"
                name="name"
                value={editedClient?.name || ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyle }}
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                value={editedClient?.email || ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyle }}
              />
              <TextField
                label="Phone"
                name="phone"
                value={editedClient?.phone || ''}
                onChange={handlePhoneChange}
                variant="outlined"
                fullWidth
                placeholder="(555) 555-5555"
                inputProps={{ maxLength: 14 }}
                sx={{ ...textFieldStyle }}
              />
              <TextField
                label="Company"
                name="company"
                value={editedClient?.company || ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyle }}
              />
            </Box>

            {/* Second column */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Address"
                name="address"
                value={editedClient?.address || ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyle }}
              />
              <TextField
                label="Lead Source"
                name="lead_source"
                value={editedClient?.lead_source || ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                sx={{ ...textFieldStyle }}
              />
              <Autocomplete
                options={parentClientSearchResults}
                getOptionLabel={(option) => option?.name || ''}
                value={parentClientSearchResults.find(c => c.id === editedClient?.parent_client_id) || null}
                onChange={handleParentClientChange}
                onInputChange={(event, newInputValue) => {
                  console.log('Search input:', newInputValue);
                  handleParentClientSearch(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parent Client"
                    variant="outlined"
                    fullWidth
                    sx={{ ...textFieldStyle }}
                    placeholder="Type to search clients..."
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  console.log('Comparing option:', option, 'with value:', value);
                  return option?.id === value?.id;
                }}
                filterOptions={(x) => x}
                noOptionsText={parentClientSearchResults.length === 0 ? "Type to search clients" : "No clients found"}
                clearOnBlur={false}
                openOnFocus
                blurOnSelect
                loading={isLoading}
              />
            </Box>
          </Box>

          {/* Notes field spans both columns */}
          <TextField
            label="Notes"
            name="notes"
            value={editedClient?.notes || ''}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            sx={{ ...textFieldStyle }}
          />
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid var(--divider)',
          px: 3, 
          py: 2,
          gap: 1 
        }}>
          <Button 
            onClick={() => setOpenEditDialog(false)} 
            variant="outlined"
            startIcon={<CancelIcon />}
            sx={{ 
              borderColor: 'var(--divider)',
              color: 'var(--text-primary)',
              '&:hover': {
                borderColor: 'var(--text-primary)',
                backgroundColor: 'var(--hover-overlay)'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave} 
            variant="contained"
            color="primary" 
            startIcon={<SaveIcon />}
            disabled={isLoading}
            sx={{
              backgroundColor: '#4A5AF9',
              '&:hover': {
                backgroundColor: '#3C50FA',
              }
            }}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={showSuccessAlert} 
        autoHideDuration={3000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseAlert} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          Client updated successfully!
        </Alert>
      </Snackbar>

      {/* Add to Balance Modal */}
      <Dialog open={openAddToBalanceModal} onClose={handleAddToBalanceModalClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{
          borderBottom: '1px solid var(--divider)',
          px: 3,
          py: 2
        }}>Add to Balance</DialogTitle>
        <DialogContent sx={{
          p: 3,
          pt: '24px !important',
        }}>
          <DialogContentText>
            Enter the amount to add to the client's balance:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="balance-amount"
            label="Amount"
            type="number"
            fullWidth
            variant="outlined"
            value={balanceAmount}
            onChange={(e) => setBalanceAmount(e.target.value)}
            sx={{ ...textFieldStyle }}
          />
        </DialogContent>
        <DialogActions sx={{
          borderTop: '1px solid var(--divider)',
          px: 3,
          py: 2,
          gap: 1
        }}>
          <Button onClick={handleAddToBalanceModalClose} variant="outlined" sx={{
            borderColor: 'var(--divider)',
            color: 'var(--text-primary)',
            '&:hover': {
              borderColor: 'var(--text-primary)',
              backgroundColor: 'var(--hover-overlay)'
            }
          }}>
            Cancel
          </Button>
          <Button onClick={handleAddToBalanceConfirm} variant="contained" sx={{
            backgroundColor: '#4A5AF9',
            '&:hover': {
              backgroundColor: '#3C50FA',
            }
          }}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default ClientDetails;
